import React from 'react';
import { Accordion, Button, Card } from 'react-bootstrap';

const AccordionOne = ({ customStyle, FAQs }) => {
  return (
    <Accordion
      className={`rn-accordion-style ${customStyle}`}
      defaultActiveKey='0'
    >
      {FAQs !== undefined &&
        FAQs.map((item, index) => (
          <Card className='bg-[#0f0f11]'>
            <Card.Header >
              <Accordion.Toggle as={Button} variant='link' eventKey={index.toString()} >
                {item.questions}
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey={index.toString()} className='ps-3 pe-3' >
              <Card.Body className='ps-5 pe-5 text-align-justify'>
                {item.answers}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        ))}

    </Accordion>
  );
};
export default AccordionOne;
