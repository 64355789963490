
export const slickDot = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    arrows: true,
    responsive: [{
        breakpoint: 800,
        settings: {
            slidesToShow: 1,
        }
    },
    {
        breakpoint: 993,
        settings: {
            slidesToShow: 1,
        }
    },
    {
        breakpoint: 580,
        settings: {
            slidesToShow: 1,
        }
    },
    {
        breakpoint: 481,
        settings: {
            slidesToShow: 1,
        }
    }
    ]
};



// export const BannerActivation = {
//     infinite: true,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     dots: true,
//     arrows: true,
// };



// export const slickDot = {
//     infinite: true,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     dots: true,
//     arrows: true,
//     responsive: [{
//         breakpoint: 800,
//         settings: {
//             slidesToShow: 1,
//         }
//     },
//     {
//         breakpoint: 993,
//         settings: {
//             slidesToShow: 1,
//         }
//     },
//     {
//         breakpoint: 580,
//         settings: {
//             slidesToShow: 1,
//         }
//     },
//     {
//         breakpoint: 481,
//         settings: {
//             slidesToShow: 1,
//         }
//     }
// ]
// };



export const BannerActivation = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    arrows: true,
};

