import React from 'react';
import './SocialFooter.css';
import { FaFacebook, FaInstagram, FaLinkedin, FaTiktok, FaYoutube } from 'react-icons/fa';

function SocialFooter() {
    return (
        <div className="social-footer">
            <div className="max-container">
                <h2>Our Social Media Channels</h2>

                {/* Columns */}
                <div className="grid">
                    {/* Column 1: Zai Systems */}
                    <div className="column">
                        <h3>Zai Systems</h3>
                        <div className="social-icons">
                            <a href="https://www.facebook.com/zaisystems" target="_blank" rel="noopener noreferrer">
                                <FaFacebook size={30} style={{ color: '#1877F2' }} />
                            </a>
                            <a href="https://www.instagram.com/zaisystems?utm_medium=copy_link" target="_blank" rel="noopener noreferrer">
                                <FaInstagram size={30} style={{ color: '#C13584' }} />
                            </a>
                            <a href="https://www.linkedin.com/company/zai-systems-smc-private-limited/?viewAsMember=true" target="_blank" rel="noopener noreferrer">
                                <FaLinkedin size={30} style={{ color: '#0077B5' }} />
                            </a>
                            <a href="https://www.tiktok.com/@zai.systems.smc.pvt.ltd?_t=8rKtnpbd4Mh&_r=1" target="_blank" rel="noopener noreferrer">
                                <FaTiktok size={30} style={{ color: '#FF0000' }} />
                            </a>
                            <a href="https://www.youtube.com/@Zai-Systems" target="_blank" rel="noopener noreferrer">
                                <FaYoutube size={30} style={{ color: '#FF0000' }} />
                            </a>
                        </div>
                    </div>

                    {/* Column 2: Impulse */}
                    <div className="column">
                        <h3>Impulse HCM 360°</h3>
                        <div className="social-icons">
                            <a href="https://www.facebook.com/people/Impulse-HCM-360-Cloud-HR-Solutions/61563488881706/" target="_blank" rel="noopener noreferrer">
                                <FaFacebook size={30} style={{ color: '#1877F2' }} />
                            </a>
                            <a href="https://www.instagram.com/impulsehcm360/" target="_blank" rel="noopener noreferrer">
                                <FaInstagram size={30} style={{ color: '#C13584' }} />
                            </a>
                            <a href="https://www.linkedin.com/showcase/impulse-hcm-360%C2%B0-cloud-hr-solutions/?viewAsMember=true" target="_blank" rel="noopener noreferrer">
                                <FaLinkedin size={30} style={{ color: '#0077B5' }} />
                            </a>
                        </div>
                    </div>

                    {/* Column 3: Executive Insight */}
                    <div className="column">
                        <h3>Executive Insights with Owais Ahmad Khan</h3>
                        <div className="social-icons">
                            <a href="https://www.facebook.com/profile.php?id=61562936145715&mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer">
                                <FaFacebook size={30} style={{ color: '#1877F2' }} />
                            </a>
                            <a href="https://www.instagram.com/insightswithowais/" target="_blank" rel="noopener noreferrer">
                                <FaInstagram size={30} style={{ color: '#C13584' }} />
                            </a>
                            <a href="https://www.linkedin.com/showcase/executive-insights-with-owais-ahmad-khan/" target="_blank" rel="noopener noreferrer">
                                <FaLinkedin size={30} style={{ color: '#0077B5' }} />
                            </a>
                            <a href="https://www.tiktok.com/@insightswithowais?_t=8qd2JUlpx3J&_r=1" target="_blank" rel="noopener noreferrer">
                                <FaTiktok size={30} style={{ color: '#FF0000' }} />
                            </a>
                            <a href="https://youtube.com/@executiveinsightswithowaiskhan?si=jQBnczxN_P7CNQpU" target="_blank" rel="noopener noreferrer">
                                <FaYoutube size={30} style={{ color: '#FF0000' }} />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SocialFooter;
