import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import LazyLoad from 'react-lazyload';
import teamMemberOne from '../../assets/images/team/team-01.webp';
import teamMemberSecond from '../../assets/images/team/daniyal.jpeg';
import teamMember03 from '../../assets/images/team/m.ahmed.jpg';
// import teamMemberThird from '../../assets/images/team/team-29.webp';
// import locationIcon from '../../assets/images/team/location.svg';

const teamData = [
  {
    image: teamMemberOne,
    name: 'Huzaifa Ali',
    designation: 'Web Engineer',
    location: 'Pakistan',
    description:
      'Huzaifa Ali specializes in designing, developing, and testing web systems and software. He is proficient in using tools and languages such as HTML, CSS, JavaScript, React, Node.js, MongoDB, Git, and more. '
  },
  {
    image: teamMemberSecond,
    name: 'Daniyal Sohail',
    designation: 'Full Stack Developer',
    location: 'Pakistan',
    description:
      'Daniyal Sohail is a skilled Full Stack Developer with experience in JavaScript, React, Next.js, Node.js, and MongoDB. He focuses on creating interactive and responsive websites that provide a great user experience and work smoothly.'
  },
  {
    image: teamMember03,
    name: 'Samra Shafiq',
    designation: 'Technical Content Specialist',
    location: 'Pakistan',
    description:
      'Samra Shafiq is a talented Technical Content Specialist with expertise in creating engaging and informative content. She specializes in writing technical articles, documentation, and guides, making complex topics accessible to a wider audience.'

  }
];

const TeamOne = ({ column, teamStyle }) => {
  return (
    <div className='row row--15' sx={{ bgcolor: '#000' }}>
      {teamData.map((data, index) => (
        <div className={`${column} col`} key={index}>
          <div className={`rn-team ${teamStyle}`}>
            <ScrollAnimation
              animateIn='fadeInUp'
              animateOut='fadeInOut'
              animateOnce={true}
            >
              <div className='inner' sx={{ bgcolor: '#000' }}>
                <figure className='thumbnail'>
                  <LazyLoad>
                    <img src={data.image} alt={data.name} />
                  </LazyLoad>
                </figure>
                <figcaption className='content' sx={{ bgcolor: '#000' }}>
                  <h2 className='title'>{data.name}</h2>
                  <h3 className='subtitle theme-gradient'>{data.designation}</h3>
                  <p className='description'>{data.description}</p>
                </figcaption>
              </div>
            </ScrollAnimation>
          </div>
        </div>
      ))}
    </div>


  );
};

export default TeamOne;
