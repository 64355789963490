import React from 'react';
import { FiCheck } from 'react-icons/fi';
import VideoTwo from '../video/VideoTwo';

const PopupData = [
  {
    id: '01',
    image: './images/bg/bg-image-4.webp',
    popupLink: ['https://www.youtube.com/watch?v=Wu44C8tiE5E']
  }
];

const AboutFour = ({ image }) => {
  return (
    <div className='about-area about-style-4 rn-section-gap'>
      <div className='container'>
        <div className='row row--40 align-items-center'>
          <div className='col-lg-6 mt_md--40 mt_sm--40'>
            <div className='content'>
              <div className='inner'>
                <h3 className=' title title_2'>
                  Our Business Philosophy <br />
                  <strong>Corporate Business.</strong>
                </h3>
                <ul className='feature-list'>
                  <li>
                    <div className='icon'>
                      <FiCheck />
                    </div>
                    <div className='title-wrapper'>
                      <h4 className='title'>
                        A good traveler has no fixed plans
                      </h4>
                      <p className='text' style={{textAlign:'justify'}}>
                        At Zai Systems, we bring combined strategic IT services,
                        software solutions and business domain knowledge that
                        facilitates our business clients and build their trust
                        in us. The largest corporate business agency who is a
                        vast hub of technology bundled with cloud computing, web
                        and app development services, that all works for
                        betterment of your business growth.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className='icon'>
                      <FiCheck />
                    </div>
                    <div className='title-wrapper'>
                      <h4 className='title'>Who are we?</h4>
                      <p className='text' style={{textAlign:'justify'}}>
                        Zai Systems is the leading technology company that was
                        established in 2017. It excels in software development,
                        e-commerce, and cloud-based solutions. The company was
                        formed with the aim to provide end-to-end solutions to
                        the people. Zai Systems innovative and research-based
                        approach enables it to deliver excellence and financial
                        growth for its customers.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className='col-lg-6'>
            {PopupData.map((item) => (
              <div className='video-btn' key={item.id}>
                <VideoTwo imagename={`${image}`} galleryItem={item} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutFour;
