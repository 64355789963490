import React from 'react';
// import Lottie from 'react-lottie'; // Removed as it is not used
import SEO from '../common/SEO';
import Copyright from '../common/footer/Copyright';
import FooterTwo from '../common/footer/FooterTwo';
import HeaderOne from '../common/header/HeaderOne';
import BreadcrumbOne from '../elements/breadcrumb/BreadcrumbOne';
import AboutThree from '../elements/about/AboutThree';
import CalltoActionFive from '../elements/calltoaction/CalltoActionFive';
import Separator from '../elements/separator/Separator';

// FAQ Data (could be moved to an external JSON file)
const FAQs = [
  {
    questions: 'What is your experience?',
    answers:
      'We have more than 4 years of experience. Our company has worked with different multidimensional local and international companies including retails, technology, telecommunication companies, and many more. Our clients were satisfied with our best services and customer support.',
  },
  {
    questions: 'What technologies do you use?',
    answers:
      'We use different technology for our services. For back-end projects, we use Python, Django, and Node.js. For front-end projects, we use Angular.js and React.js.',
  },
  {
    questions: 'What are the benefits of managed services?',
    answers:
      'One of the biggest advantages of managed IT services is cost savings that businesses experience by making business IT support costs more predictable and manageable. A managed IT service approach also provides your businesses with comprehensive security, customized backup and recovery solutions, and state-of-the-art cloud storage and hosting.',
  },
  {
    questions: 'Do the Zai Systems provide afterhours emergency support?',
    answers: 'Yes, our customer support is available 24x7.',
  },
  {
    questions: 'What differentiates The Zai Systems? Why Should I Choose You?',
    answers:
      'Zai Systems is a reliable IT partner with a strong focus on service. We pride ourselves on having the ability to understand your business and your customers’ needs so that we can customize our service to you. We as a dedicated partner always willing to provide predictability of service, cost certainty, and responsiveness. Our organizational expertise, extensive senior talent, professional knowledge, and effective software systems will help you achieve your business goals with us.',
  },
];

const Business = () => {
  return (
    <>
      <SEO>
        <title>FAQs | ZAI SYSTEMS</title>
        <meta
          name="description"
          content="See Frequently Asked Questions about ZAI SYSTEMS business and services."
        />
        <meta
          name="robots"
          content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        />
        <link rel="canonical" href="https://www.zaisystems.com/faq-page" />
      </SEO>

      <main className="page-wrapper">
        <HeaderOne btnStyle="btn-small" HeaderSTyle="header-not-transparent" />

        {/* Breadcrumb Section */}
        <BreadcrumbOne
          title="FAQS"
          rootUrl="/"
          parentUrl="Home"
          currentUrl="FAQS"
        />

        {/* Separator */}
        <Separator />

        {/* About Section with FAQ */}
        <AboutThree FAQs={FAQs} />

        {/* Call to Action Section */}
        <div className="rwt-callto-action-area rn-section-gapBottom">
          <div className="wrapper">
            <CalltoActionFive />
          </div>
        </div>

        {/* Footer */}
        <FooterTwo />
        <Copyright />
      </main>
    </>
  );
};

export default Business;
