import { Divider, List, ListItem, Card, CardContent, Typography } from '@mui/material';
import React, { useEffect } from 'react';

const ScrollSection = ({ bgColor, textColor, title, listcarddetails }) => {
    useEffect(() => {
        const handleScroll = () => {
            const rightColumn = document.querySelector('.right-column');
            if (!rightColumn) return;

            const rightColumnHeight = rightColumn.scrollHeight;
            const rightColumnScrollTop = rightColumn.scrollTop;
            const rightColumnClientHeight = rightColumn.clientHeight;
            const windowHeight = window.innerHeight;

            if (rightColumnScrollTop + rightColumnClientHeight >= rightColumnHeight) {
                document.body.style.overflowY = 'scroll';
            } else {
                document.body.style.overflowY = 'hidden';
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            {listcarddetails !== undefined && (
                <div className={`${bgColor} py-5`}>
                    <div className="scroll-section container">
                        <div style={{ maxWidth: '100%', height: 'auto' }}>
                            <div className="col">
                                {title.map((item) => (
                                    <div key={item.id} className="d-flex justify-content-between align-items-center mb-4 gap-1">
                                        {/* Text Content */}
                                        <div className={`flex-1 text-start `} style={{ maxWidth: '50%' }}>
                                            <h2 className={`text-justify ${textColor} mb-3 scroll-h2`}>{item.title}</h2>
                                            <div className={`text-justify scroll-para ${textColor}`}>{item.para}</div>
                                        </div>

                                        {/* Image Content */}
                                        {item.img !== undefined && (
                                            <div className="ms-4">
                                                <img
                                                    className="mt-5 rounded-3 shadow-lg"
                                                    src={item.img}
                                                    alt="Content"
                                                    style={{ width: '100%', height: '300px' }}
                                                />
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>


                            {/* Right Column */}
                            <div className="row right-column">
                                {listcarddetails.map((item) => (
                                    <div key={item.id} className="col-md-6 mb-5">
                                        <Card
                                            className="mb-4 shadow-lg rounded-lg"
                                            style={{
                                                backgroundColor: '#1a1a1a', // Blackish color
                                                color: '#f0f0f0', // Light text color for contrast
                                            }}
                                        >
                                            <CardContent>
                                                <Typography variant="h4" className="scroll-h3 mb-3">
                                                    {item.title}
                                                </Typography>
                                                <Typography variant="body1" className="scroll-para">
                                                    {item.para}
                                                </Typography>

                                                {item.list !== undefined && (
                                                    <nav aria-label="secondary mailbox folders">
                                                        <List>
                                                            {item.list.map((listitem, key) => (
                                                                <ListItem disablePadding key={key}>
                                                                    <Card
                                                                        variant="outlined"
                                                                        className="my-2 p-3 shadow-sm rounded-md"
                                                                        style={{
                                                                            backgroundColor: '#2a2a2a', // Darker card color for nested items
                                                                            color: '#e0e0e0', // Lighter text color
                                                                        }}
                                                                    >
                                                                        <Typography variant="h2" className="mb-2">
                                                                            {listitem.title}
                                                                        </Typography>
                                                                        <Typography variant="h4">
                                                                            {listitem.text}
                                                                        </Typography>
                                                                    </Card>
                                                                </ListItem>
                                                            ))}
                                                        </List>
                                                    </nav>
                                                )}
                                            </CardContent>
                                        </Card>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default ScrollSection;
