import React from 'react';
import { Link } from 'react-router-dom';
import Layout from '../common/Layout';
import SEO from '../common/SEO';
import SectionTitle from '../elements/sectionTitle/SectionTitle';
// import Separator from '../elements/separator/Separator';
import AllSolutionsRe from '../elements/service/AllSolutions';
import HCM from '../../src/assets/images/hcmSection/HCM 360.png';
import LazyLoad from "react-lazyload";

const Service = () => {
  return (
    <>
      <SEO>
        <title>Impulse Human Capital Management (HCM) 360° - Zai Systems</title>
        <meta
          name='description'
          content='At ZAI SYSTEMS we offer Impulse HCM 360° services to you including payroll management, attendance tracking system, job portals and much more.'
        />
        <meta
          name='robots'
          content='index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1'
        />
        <link rel='canonical' href='https://zaisystems.com/impulse-hcm-360' />
      </SEO>
      <Layout>
        <div className='rwt-portfolio-details rn-section-gap '>
          <div className='container services-main-div'>
            <div className="row">
              <div className="top2 col-md-6">
                <h1 className="service-heading-one">
                  Impulse HCM 360°
                </h1>
                <p className='para-one'>
                  Zai Systems Impulse HCM 360° is an innovative and customized
                  Human Capital Management (HCM) software solution. It is
                  designed to streamline and automate your company’s various
                  HR roles. We offer a range of features and tools to help
                  businesses effectively manage their workforce, from
                  recruitment and onboarding to payroll, performance
                  management, and employee self-service.
                </p>
                <div className='read-more-btn mt--20'>
                  <Link className='btn-default' to='/req-a-demo'>
                    <span>Request a Demo</span>
                  </Link>
                </div>
              </div>
              <div className=" col-md-4">
                <LazyLoad><img alt="HCM 360°" src={HCM} /></LazyLoad>
              </div>
            </div>
          </div>
        </div>

        {/* End Slider Area  */}

        {/* Start Service Area  */}
        <div className='rn-service-area rn-section-gap-top'>
          <div className='container'>
            <div className='row'></div>
            <div className='page-heading'>
              <h2 style={{ textAlign: 'center' }}>Streamline Your Processes with Our Impulse HCM 360°</h2>
              <p className='text-center'>
                Do you want to enhance your HR processes? Get our HCM services
                for efficient management. We specialize in providing cloud
                computing services solutions for efficient HR management in
                Pakistan and the USA, all at competitive prices.
              </p>

              <p className='text-center'>
                Our specialized team ensures to provide a user-friendly
                interface and flexibility, making it accessible for companies of
                different sizes and industries. We also aim to enhance HR
                efficiency, reduce manual work, and improve overall human
                resource operations, leading to increased productivity and
                employee satisfaction.
              </p>
            </div>
          </div>
        </div>
        {/* End Service Area  */}

        <div className='main-content'>
          {/* Start Service Area  */}
          <div className='rn-service-area rn-section-gap'>
            <div className='container'>
              <div className='row'>
                <div className='col-lg-12'>
                  <SectionTitle
                    textAlign='text-center'
                    radiusRounded=''
                    subtitle='our services'
                    title=' What We Offer?'
                  />
                </div>
              </div>
              <AllSolutionsRe
                serviceStyle='service__style--1 bg-color-blackest radius mt--25'
                textAlign='text-center'
              />
            </div>
          </div>
          {/* End Service Area  */}
          {/* 
          <Separator />
          <br /> */}
          {/* End Service Area  */}
        </div>

        {/* <br />
        <br />
        <section className='container'>
          <div className='page-heading'>
            <span>For more information please request a demo</span>
            <br />
            <br />
            <div className='read-more-btn mt--40'>
              <Link className='btn-default' to='/req-a-demo'>
                <span>Request a Demo</span>
              </Link>
            </div>
            <br />
          </div>
        </section>

        <br />
        <br /> */}
      </Layout>
    </>
  );
};

export default Service;
