import React, { useState, useEffect } from "react"
import PortfolioData from "../../data/portfolio/PortfolioData.json"
import PortfolioItem from "./PortfolioItem"
// import { FaSpinner } from "react-icons/fa"
import LazyLoad from "react-lazyload";

const filters = [
  {
    id: 1,
    text: "All"
  },
  {
    id: 2,
    text: "javascript"
  },
  {
    id: 3,
    text: "react"
  },
  {
    id: 4,
    text: "angular"
  },
  {
    id: 5,
    text: "asp.net"
  },
  // {
  //   id: 4,
  //   text: "frontend"
  // },
  {
    id: 6,
    text: "python"
  },
  {
    id: 7,
    text: "java"
  },
  {
    id: 8,
    text: "wordpress"
  },

  {
    id: 9,
    text: "graphic-designing"
  },


]
const alldata = PortfolioData
const PortfolioOne = ({ Column }) => {
  const [getAllItems] = useState(alldata)
  const [dataVisibleCount, setDataVisibleCount] = useState(9)
  const [dataIncrement] = useState(6)
  const [noMorePost, setNoMorePost] = useState(false)
  const [activeFilter, setActiveFilter] = useState("")
  const [visibleItems, setVisibleItems] = useState([])

  useEffect(() => {
    setActiveFilter(filters[0].text.toLowerCase());
    setVisibleItems(getAllItems.filter((item) => item.id <= dataVisibleCount));
  }, [getAllItems, dataVisibleCount]);


  const handleChange = (e) => {
    e.preventDefault()
    setActiveFilter(e.target.textContent.toLowerCase())
    let tempData
    if (e.target.textContent.toLowerCase() === filters[0].text.toLowerCase()) {
      tempData = getAllItems.filter((data) => data.id <= dataVisibleCount)
    } else {
      tempData = getAllItems.filter(
        (data) =>
          data.category === e.target.textContent.toLowerCase() &&
          data.id <= dataVisibleCount
      )
    }
    setVisibleItems(tempData)
  }
  ///////////////////////////////////////////////////////////////////////////////////////////////////////
  const [textVisible, setTextVisible] = useState(true);

  const handleLoadmore = async (e) => {
    e.preventDefault();

    // Set the state to hide the text when the button is clicked
    setTextVisible(false);

    // Show the GIF when the button is clicked
    const gifElement = document.getElementById('gif');
    if (gifElement) {
      gifElement.style.display = 'inline';
    }

    // Simulate loading for 2 seconds
    await new Promise(resolve => setTimeout(resolve, 1000));

    let tempCount = dataVisibleCount + dataIncrement;
    if (dataVisibleCount >= getAllItems.length) {
      setNoMorePost(true);
    } else {
      setDataVisibleCount(tempCount);
      if (activeFilter === filters[0].text.toLowerCase()) {
        setVisibleItems(getAllItems.filter((data) => data.id <= tempCount));
      } else {
        setVisibleItems(
          getAllItems.filter(
            (data) => data.category === activeFilter && data.id <= tempCount
          )
        );
      }
    }
    // After loading, hide the GIF
    if (gifElement) {
      gifElement.style.display = 'none';
    }
    // Set the state to show the text again
    setTextVisible(true);
  };
  const gifUrl = 'https://www.superiorlawncareusa.com/wp-content/uploads/2020/05/loading-gif-png-5.gif';
  const gifStyle = {
    width: '30px',
    height: '30px',
    display: 'none',
  };
  return (
    <>
      <div className="row row--15">
        <div className="col-lg-12">
          <ul className="rwt-portfolio-filter filter-button-default liststyle mb--0">
            {filters.map((filter) => (
              <li className="list-item" key={filter.id}>
                <button
                  onClick={handleChange}
                  className={
                    filter.text.toLowerCase() === activeFilter ? "current" : " "
                  }
                >
                  {filter.text}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="row row--15">
        {visibleItems.map((item) => (
          <div key={item.id} className={Column}>
            <PortfolioItem portfolio={item} />
          </div>
        ))}
      </div>

      <div className="row row--15 mb-8">
        <div className="col-lg-12">
          <div className="rwt-load-more text-center">
            <button
              className="btn btn-default btn-large btn-icon "
              onClick={handleLoadmore}
              disabled={noMorePost ? "disabled" : null}
            >
              {noMorePost ? (
                "No Item Here"
              ) : (
                <span onClick={handleLoadmore}>
                  {textVisible && "Explore More Courses"}
                  <span className="icon">
                    <LazyLoad><img id="gif" src={gifUrl} alt="GIF" style={gifStyle} /></LazyLoad>
                  </span>
                </span>
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default PortfolioOne
