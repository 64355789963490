import React from 'react';
import bg from '../../assets/images/podcastBanner/bg.jpeg';
import '../../assets/css/Podcast.css';

function PodcastHero() {
    return (
        <div className="podcast-hero" data-aos="fade-in">
            <img
                src={bg}
                alt="Background Image"
            />
            <div className="hero">
                <div className="hero-content">
                    <h1>
                        Executive Insights with Owais Ahmad Khan
                    </h1>
                </div>
            </div>
        </div>
    );
}

export default PodcastHero;
