import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import teamMember01 from '../../assets/images/team/samra shafiq.jpg';
import teamMember02 from '../../assets/images/team/daniyal .jpg';
import teamMember03 from '../../assets/images/team/m.ahmed.jpg';
import teamMember04 from '../../assets/images/team/danish.jpg';
import teamMember05 from '../../assets/images/team/umar habib.jpg';
// import teamMember06 from '../../assets/images/team/huzaifa.webp';
import teamMember07 from '../../assets/images/team/hammad aslam.jpg';
import teamMember08 from '../../assets/images/team/hamza.jpg';
import teamMember09 from '../../assets/images/team/ahsan .jpg';
import teamMember10 from '../../assets/images/team/falak.jpg';
// import teamMember11 from '../../assets/images/team/samra.webp';
// import teamMember12 from '../../assets/images/team/asm.webp';

import LazyLoad from "react-lazyload";



const teamData = [
  {
    image: teamMember01,
  },
  {
    image: teamMember02,
  },
  {
    image: teamMember03,
  },

  {
    image: teamMember04,
  },
  {
    image: teamMember05,
  },

  {
    image: teamMember07,
  },
  {
    image: teamMember08,
  },
  {
    image: teamMember09,
  },
  {
    image: teamMember10,
  },


];

const TeamThree = ({ column, teamStyle }) => {
  return (
    <div className='row row--30'>
      {teamData.map((member, index) => (
        <div className={`${column}`} key={index}>
          <ScrollAnimation
            animateIn='fadeInUp'
            animateOut='fadeInOut'
            animateOnce={true}
          >
            <div className={`rn-team ${teamStyle}`}>
              <article className="relative flex flex-col justify-end overflow-hidden rounded-2xl shadow-lg px-4 pb-6 pt-16 h-96 w-full">
                <LazyLoad>
                  <img
                    src={member.image}
                    alt={member.name}
                    className="absolute inset-0 h-full w-full object-cover rounded-2xl"
                  />
                </LazyLoad>
                <div className="absolute inset-0 bg-gradient-to-t from-gray-900 via-gray-900/40"></div>

              </article>

            </div>
          </ScrollAnimation>
        </div>
      ))}
    </div>
  );
};

export default TeamThree;
