import HeaderOne from '../../common/header/HeaderOne'
import FooterTwo from '../../common/footer/FooterTwo'
import Copyright from '../../common/footer/Copyright'
import { CareerData } from '../../data/careerdata'
import React from 'react';
import side from './../../assets/images/CareerImages/career side.webp';
import SEO from '../../common/SEO'
import tick from './../../assets/images/servicePagesLogos/logos/tick final.webp'
import Separator from "../../elements/separator/Separator";
import LazyLoad from "react-lazyload";

const Career = () => {
    return (
        <>

            <SEO>
                <title>Launch Your Career with Benefits & Growth at Zai Systems</title>
                <meta
                    name='description'
                    content="Take your career to new heights at Zai Systems! Top-notch benefits, training, & growth opportunities. Explore IT openings now!"
                />
                <meta
                    name='robots'
                    content='index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1'
                />
                <link rel='canonical' href='https://www.zaisystems.com/career' />
            </SEO>
            <main className='page-wrapper'>

                <HeaderOne
                    btnStyle='btn-small btn-icon'
                    HeaderSTyle='header-transparent'
                />

                {/* Start Slider Area  */}
                <div
                    className='slider-area slider-style-1 variation-default height-600 bg_image career-one'
                    data-black-overlay='5'
                    style={{
                        backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/shade.webp)`
                    }}
                >
                    <div className='container career-one-content'>
                        <div className='row justify-content-center'>
                            <div className='col-lg-10'>
                                <div className='inner text-center'>
                                    <h1 className='title display-four career-h1'>
                                        Career Opportunities in Lahore, Pakistan
                                        <span className='theme-gradient'></span>
                                    </h1>
                                    <p className='description text-center ' >
                                        Welcome to an exciting journey with us! Explore our diverse career opportunities designed to ignite your passion and propel your professional growth. Whether you're seeking a full-time or part-time role, or an internship to kickstart your career, we have something for everyone. Apply now and join a team that values innovation, collaboration, and excellence.
                                    </p>
                                    <p className='description text-center' >
                                        Zai Systems offers a global stage for your ambition. With a presence in the  US, UK, Saudi Arabia, and Pakistan,
                                        our hybrid approach opens doors to unparalleled opportunities. Dream big
                                        and watch your aspirations take flight with our innovative solutions and committed support.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className=' row '>
                    <div className="col-md-3"></div>
                    <div className="col-md-6 career-one-bottom">
                        <p >Shaping The Future Workforce Landscape</p>
                    </div>
                    <div className="col-md-3"></div>
                </div>
                {/* End Slider Area  */}



                <div className='rwt-portfolio-details'>
                    <div className='container services-main-div'>
                        <div className='row'>
                            {CareerData.map(
                                ({
                                    listAud,
                                    listTemp,
                                    listService,
                                    listRoute,
                                }) => (
                                    <div className='col-lg-12 '>
                                        {/* career two */}
                                        <div >
                                            {listAud !== undefined &&
                                                listAud.map((i) => (
                                                    <div className=" row mt-5">
                                                        <div className="top2 career-top-content col-md-7" key={i.id}>
                                                            <h2 className="service-heading-one">
                                                                {i.title}
                                                            </h2>
                                                            <p className='para-one'>{i.para}</p>
                                                            <p className='para-one'>{i.para1}</p>
                                                            <p className='para-one career-p'>
                                                                {/* <LazyLoad > */}
                                                                <img src={tick} alt='tick-icon' loading="lazy" style={{ width: '21px', height: '21px', marginTop: '3px' }} />
                                                                {/* </LazyLoad> */}
                                                                &nbsp;&nbsp;
                                                                <span> {i.text}</span>
                                                            </p>
                                                            <p className='para-one career-p'>

                                                                {/* <LazyLoad> */}
                                                                <img src={tick} alt='tick-icon' loading="lazy" style={{ width: '21px', height: '21px', marginTop: '3px' }} />
                                                                {/* </LazyLoad> */}
                                                                &nbsp;&nbsp;
                                                                <span>  {i.text1}</span>
                                                            </p>
                                                            <p className='para-one career-p'>
                                                                {/* <LazyLoad>  */}
                                                                <img src={tick} alt='tick-icon' loading="lazy" style={{ width: '21px', height: '21px', marginTop: '3px' }} />
                                                                {/* </LazyLoad>  */}
                                                                &nbsp;&nbsp;
                                                                <span>  {i.text2}</span>
                                                            </p>
                                                            <p className='para-one  career-p'>
                                                                {/* <LazyLoad> */}
                                                                <img src={tick} alt='tick-icon' loading="lazy" style={{ width: '21px', height: '21px', marginTop: '3px' }} />
                                                                {/* </LazyLoad>  */}
                                                                &nbsp;&nbsp;
                                                                <span> {i.text3}</span>
                                                            </p>

                                                        </div>
                                                        <div className=" col-md-5 career-top-img">
                                                            {/* <LazyLoad> */}
                                                            <img alt="hero" src={side} width={"380px"} loading="lazy" className='career-side-img' />
                                                            {/* </LazyLoad> */}
                                                        </div>
                                                    </div>
                                                ))}
                                        </div>

                                        {/* career three cards benefits */}

                                        <div className="container mb-5">
                                            <div className="row row--15 service-wrapper">
                                                <div className="section-title text-center ">
                                                    {listService.map((i) => (
                                                        <>
                                                            <div className="animated fadeInUp" style={{ animationDuration: '1s' }}>
                                                                <h3 className="title w-600 mt-5">{i.title}</h3>
                                                            </div>
                                                            <div className="animated fadeInUp" style={{ animationDuration: '1s' }}>
                                                                <p className="description b1 text-center" style={{ fontSize: '18px' }}>
                                                                    {i.para}
                                                                </p>
                                                            </div>
                                                        </>))}
                                                </div>

                                                {/* career 1 */}
                                                {listRoute.map((i) => (
                                                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 ">
                                                        <div className="animated fadeInUp" style={{ animationDuration: '1s' }}>
                                                            <div className="service service__style--1 bg-color-blackest radius mt--20 text-center ">
                                                                <div className="icon w-25 m-auto p-3">
                                                                    <LazyLoad><img src={i.img} className="w-100" alt="User Experience Design" /></LazyLoad>
                                                                </div>
                                                                <div className="content">
                                                                    <h5 className="title w-600">
                                                                        <a href="/services#service">{i.title}</a>
                                                                    </h5>
                                                                    <p className="description  color-gray mb--0" style={{ textAlign: 'center' }}>{i.para}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                ))}

                                            </div>
                                        </div>
                                        <Separator />

                                        {/* career four */}
                                        <div className="row">
                                            <h4 className='text-center career-four'>View Current Job Openings</h4>
                                            <div style={{ height: '120vh', overflowY: 'auto' }}>
                                                {listTemp && listTemp.length > 0 ? (
                                                    listTemp.map((i, index) => (
                                                        <div key={index} className="row career-last bg-color-blackest radius">
                                                            <div className="col-md-4 mt-0 openings d-flex flex-column justify-content-center align-items-center">
                                                                <h5 className="subtitle text-center mt-2">
                                                                    <span className="full-time">Full Time / Part Time</span>
                                                                </h5>
                                                                <h5 className="text-center">
                                                                    {i.title}
                                                                    <br />
                                                                    <p className="text-center mb-0">(Job-Internship)</p>
                                                                </h5>
                                                                <p className="text-center">
                                                                    <i className="fa-solid fa-location-dot"></i>
                                                                    &nbsp; Lahore, Pakistan
                                                                </p>
                                                            </div>
                                                            <div className="col-md-6 openings">
                                                                <p className="text-center mb-0">{i.para}</p>

                                                                {/* Map through responsibilities */}
                                                                {i.responsibilities && i.responsibilities.length > 0 && (
                                                                    <div className="responsibilities mt-3">
                                                                        <ul>
                                                                            {i.responsibilities.map((responsibility, idx) => (
                                                                                <li key={idx}>{responsibility}</li>
                                                                            ))}
                                                                        </ul>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className="col-md-2 d-flex align-items-center justify-content-center text-center apply-btn openings">
                                                                <button
                                                                    className="btn-default career-btn"
                                                                    onClick={() => {
                                                                        window.location.href = "mailto:hr@zaisystems.com";
                                                                    }}
                                                                >
                                                                    Apply Now
                                                                </button>
                                                            </div>
                                                        </div>
                                                    ))
                                                ) : (
                                                    <p>No job openings available at the moment.</p>
                                                )}
                                            </div>

                                        </div>
                                    </div>

                                )
                            )}
                        </div>
                    </div>
                </div>

                <FooterTwo />
                <Copyright />
            </main >
        </>
    )
}

export default Career