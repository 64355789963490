// Banners service pages
import artificial from './../../src/assets/images/servicePagesBanners/ArtificialIntelligence.webp';
import bigData from './../../src/assets/images/servicePagesBanners/BigData.webp';
import blockchainDevelopers from './../../src/assets/images/servicePagesBanners/BlockchainDevelopers.webp';
import blockchainDevelopment from './../../src/assets/images/servicePagesBanners/blockchain development service.webp';
import community from './../../src/assets/images/servicePagesBanners/CommunityBuilding.webp';
import dedicated from './../../src/assets/images/servicePagesBanners/DedicatedDevelopers.webp';
import defi from './../../src/assets/images/servicePagesBanners/DefiDevelopment.webp';
import devops from './../../src/assets/images/servicePagesBanners/DevOps.webp';
import exchange from './../../src/assets/images/servicePagesBanners/ExchangeDevelopment.webp';
import fullstack from './../../src/assets/images/servicePagesBanners/FullStackDevelopers.webp';
import hyperledger from './../../src/assets/images/servicePagesBanners/HyperledgerDevelopment.webp';
import metaverse from './../../src/assets/images/servicePagesBanners/METAVERSE.png';
import nft from './../../src/assets/images/servicePagesBanners/NFT.png';
import natural from './../../src/assets/images/servicePagesBanners/NaturalLanguageProcessing.webp';
import near from './../../src/assets/images/servicePagesBanners/NearBlockchain.webp';
import nftDesign from './../../src/assets/images/servicePagesBanners/NftDesign.webp';
import nftMarketing from './../../src/assets/images/servicePagesBanners/NftMarketing.webp';
import nftMarketplace from './../../src/assets/images/servicePagesBanners/NftMarketplace.webp';
import nftMinting from './../../src/assets/images/servicePagesBanners/NftMinting.webp';
import offshore from './../../src/assets/images/servicePagesBanners/OffshoreDevelopers.webp';
import robotic from './../../src/assets/images/servicePagesBanners/RoboticProcessAutomation.webp';
import smartContract from './../../src/assets/images/servicePagesBanners/SmartContractDevelopment.webp';
import staff from './../../src/assets/images/servicePagesBanners/StaffAugmentation.png';
import wallet from './../../src/assets/images/servicePagesBanners/WalletDevelopment.webp';
import white from './../../src/assets/images/servicePagesBanners/WhitePaperDevelopment.webp';
import webApp from './../../src/assets/images/servicePagesBanners/WebApp.webp';
import userExperience from './../../src/assets/images/servicePagesBanners/UserExperienceDesign.webp';
import SQA from './../../src/assets/images/servicePagesBanners/SoftwareQualityAssurance.webp';
import SPM from './../../src/assets/images/servicePagesBanners/SoftwareProjectManagement.webp';
import productDev from './../../src/assets/images/servicePagesBanners/ProductDevelopment.webp';
import iotDev from './../../src/assets/images/servicePagesBanners/IoTDevelopment.webp';
import GraphicDesign from './../../src/assets/images/servicePagesBanners/GraphicDesigning.webp';
import Enterprise from './../../src/assets/images/servicePagesBanners/EnterpriseDevelopment.webp';
import DigitalMarketing from './../../src/assets/images/servicePagesBanners/DigitalMarketing.webp';
import CustomSoftware from './../../src/assets/images/servicePagesBanners/CustomSoftwareDevelopment.webp';
import ContentWriting from './../../src/assets/images/servicePagesBanners/ContentWriting.webp';
import appDev from './../../src/assets/images/servicePagesBanners/AppDevelopment1.webp';
import manCloud from './../../src/assets/images/servicePagesBanners/Managed Cloud Services-01.webp';
import ecomm from './../../src/assets/images/servicePagesBanners/E-commerce-01.webp'
import beSpoke from './../../src/assets/images/servicePagesBanners/Bespoke IT Solutions for Business-01.webp'
import dataAnaly from './../../src/assets/images/servicePagesBanners/Data Analytics-01.webp'
import businnessIntelli from './../../src/assets/images/servicePagesBanners/business intellengence-01.webp'
import infostrate from './../../src/assets/images/servicePagesBanners/Information Strategy f-01.webp'



// icons
import block1 from './../../src/assets/images/servicePagesIcons/services blockchain-developers/share-your-vision-well-find-the-talent.webp';
import block2 from './../../src/assets/images/servicePagesIcons/services blockchain-developers/from-a-pool-of-experts-we-surface-the-diamonds.webp';
import block3 from './../../src/assets/images/servicePagesIcons/services blockchain-developers/beyond-resumes-we-uncover-the-spark.webp';
import block4 from './../../src/assets/images/servicePagesIcons/services blockchain-developers/onboarding-with-confidence-integrating-for-success.webp';
import block5 from './../../src/assets/images/servicePagesIcons/services blockchain-developers/were-not-just-here-to-hire-were-here-to-help-you-thrive.webp';
import block6 from './../../src/assets/images/servicePagesIcons/services blockchain-developers/sustaining-excellence-nurturing-growth.webp';

import blockd1 from './../../src/assets/images/servicePagesIcons/servicesblockchain-development/requirement-evaluation.webp';
import blockd2 from './../../src/assets/images/servicePagesIcons/servicesblockchain-development/solution-design.webp';
import blockd3 from './../../src/assets/images/servicePagesIcons/servicesblockchain-development/building-implementation.webp';
import blockd4 from './../../src/assets/images/servicePagesIcons/servicesblockchain-development/maintenance-evolution.webp';
import blockd5 from './../../src/assets/images/servicePagesIcons/servicesblockchain-development/training-education.webp';
import blockd6 from './../../src/assets/images/servicePagesIcons/servicesblockchain-development/feedback-evaluation.webp';

import nftb1 from './../../src/assets/images/servicePagesIcons/servicesnft/project-discovery.webp';
import nftb2 from './../../src/assets/images/servicePagesIcons/servicesnft/design.webp';
import nftb3 from './../../src/assets/images/servicePagesIcons/servicesnft/development.webp';
import nftb4 from './../../src/assets/images/servicePagesIcons/servicesnft/deployment.webp';
import nftb5 from './../../src/assets/images/servicePagesIcons/servicesnft/maintenance.webp';
import nftb6 from './../../src/assets/images/servicePagesIcons/servicesnft/innovation.webp';

import fullstackb1 from './../../src/assets/images/servicePagesIcons/servicesfull-stack-developers/Requirements-in-Focus.webp';
import fullstackb2 from './../../src/assets/images/servicePagesIcons/servicesfull-stack-developers/Shortlisting-Best-Ones.webp';
import fullstackb3 from './../../src/assets/images/servicePagesIcons/servicesfull-stack-developers/Interviews-&-Selection.webp';
import fullstackb4 from './../../src/assets/images/servicePagesIcons/servicesfull-stack-developers/Onboarding-with-Care.webp';
import fullstackb5 from './../../src/assets/images/servicePagesIcons/servicesfull-stack-developers/Ongoing-Support.webp';
import fullstackb6 from './../../src/assets/images/servicePagesIcons/servicesfull-stack-developers/Growth-and-Feedback.webp';

import nftMintingb1 from './../../src/assets/images/servicePagesIcons/servicesnft-minting/project-analysis.webp';
import nftMintingb2 from './../../src/assets/images/servicePagesIcons/servicesnft-minting/requirement-gathering.webp';
import nftMintingb3 from './../../src/assets/images/servicePagesIcons/servicesnft-minting/smart-contract-development.webp';
import nftMintingb4 from './../../src/assets/images/servicePagesIcons/servicesnft-minting/ux-ui-development.webp';
import nftMintingb5 from './../../src/assets/images/servicePagesIcons/servicesnft-minting/API-Integrations.webp';
import nftMintingb6 from './../../src/assets/images/servicePagesIcons/servicesnft-minting/testing.webp';
import nftMintingb7 from './../../src/assets/images/servicePagesIcons/servicesnft-minting/Product-Deployment.webp';
import nftMintingb8 from './../../src/assets/images/servicePagesIcons/servicesnft-minting/Marketing-and-Launch-Strategy.webp';
import nftMintingb9 from './../../src/assets/images/servicePagesIcons/servicesnft-minting/Post-Launch-Support.webp';




/////////////////////////////////////////////////////////////////////////////////
import sketching from './../../src/assets/images/servicePagesIcons/servicesnft/design.webp';
import concept from './../../src/assets/images/servicePagesIcons/servicesnft-minting/project-analysis.webp';
import SoftwareSelection from './../../src/assets/images/servicePagesIcons/requirement-evaluation.webp';
import finalArt from './../../src/assets/images/servicePagesIcons/servicesblockchain-development/solution-design.webp';
import post from './../../src/assets/images/servicePagesIcons/servicesnft-minting/Post-Launch-Support.webp';
import minting from './../../src/assets/images/servicePagesIcons/servicesnft-minting/Product-Deployment.webp';

// logos common
import exchangeLogo from './../../src/assets/images/servicePagesLogos/blockchain development/exchange dev.webp';
import binanceLogo from './../../src/assets/images/servicePagesLogos/logos/binance.webp';
import cardanoLogo from './../../src/assets/images/servicePagesLogos/logos/cardano.webp';
import cordaLogo from './../../src/assets/images/servicePagesLogos/logos/corda.webp';
import cosmosLogo from './../../src/assets/images/servicePagesLogos/logos/cosmos.webp';
import etheriumLogo from './../../src/assets/images/servicePagesLogos/logos/etherium.webp';
import hyperLogo from './../../src/assets/images/servicePagesLogos/logos/hyper.webp';
import polygonLogo from './../../src/assets/images/servicePagesLogos/logos/polygon.webp';
import rippleLogo from './../../src/assets/images/servicePagesLogos/logos/ripple.webp';
import solanaLogo from './../../src/assets/images/servicePagesLogos/logos/solana.webp';
import stellarLogo from './../../src/assets/images/servicePagesLogos/logos/stellar.webp';
import thorchainLogo from './../../src/assets/images/servicePagesLogos/logos/thorchain.webp';
import privateLogo from './../../src/assets/images/servicePagesLogos/logos/privacy.webp';
import avalanche from './../../src/assets/images/servicePagesLogos/logos/Avalanche.webp';
import polkadot from './../../src/assets/images/servicePagesLogos/logos/Polkadot.webp';
import dedicatedTeam from './../../src/assets/images/servicePagesLogos/multiple-pages/dedicated team.webp';
import hire from './../../src/assets/images/servicePagesLogos/multiple-pages/hire dev.webp';
import project from './../../src/assets/images/servicePagesLogos/multiple-pages/project based.webp';


// logos
import blockchainDevOne from './../../src/assets/images/servicePagesLogos/blockchain development/zai web smart contract icon.webp';
import blockchainDevTwo from './../../src/assets/images/servicePagesLogos/blockchain development/wallet development.webp';
import blockchainDevThree from './../../src/assets/images/servicePagesLogos/blockchain development/white paper dev.webp';
import blockchainDevFive from './../../src/assets/images/servicePagesLogos/blockchain development/defi dev.webp';
import blockchainDevSix from './../../src/assets/images/servicePagesLogos/blockchain development/hyperledger dev.webp';
import blockchainDevSeven from './../../src/assets/images/servicePagesLogos/blockchain development/Blockchain Consulting Services.webp';
import blockchainDevEight from './../../src/assets/images/servicePagesLogos/blockchain development/Smart Contract Development Services.webp';
import blockchainDevNine from './../../src/assets/images/servicePagesLogos/blockchain development/DeFi Development Services.webp';
import blockchainDevTen from './../../src/assets/images/servicePagesLogos/blockchain development/Public and Private Blockchain Solutions.webp';

import smartOne from './../../src/assets/images/servicePagesLogos/Smart Contract Development/Increase efficiency and accuracy.webp';
import smartTwo from './../../src/assets/images/servicePagesLogos/Smart Contract Development/Enhance transparency and trust.webp';
import smartThree from './../../src/assets/images/servicePagesLogos/Smart Contract Development/Eliminate intermediaries.webp';
import smartFour from './../../src/assets/images/servicePagesLogos/Smart Contract Development/Reduce paperwork and manual processes.webp';
import smartFive from './../../src/assets/images/servicePagesLogos/Smart Contract Development/Decentralized Trading Made Secure.webp';
import smartSix from './../../src/assets/images/servicePagesLogos/Smart Contract Development/Empowering Startups and Organizations.webp';
import smartSeven from './../../src/assets/images/servicePagesLogos/Smart Contract Development/Tailored Solutions for Any Industry.webp';
import smartEight from './../../src/assets/images/servicePagesLogos/Smart Contract Development/Revolutionizing DeFi with Smart Contracts.webp';
import smartNine from './../../src/assets/images/servicePagesLogos/Smart Contract Development/DApp Development at its Core.webp';
import smartTen from './../../src/assets/images/servicePagesLogos/Smart Contract Development/Performance is Key.webp';
import smartEleven from './../../src/assets/images/servicePagesLogos/Smart Contract Development/optimizing-contractual-compliance.webp';
import smartTwelve from './../../src/assets/images/servicePagesLogos/Smart Contract Development/facilitate-faster-business-transactions.webp';


import walletOne from './../../src/assets/images/servicePagesLogos/wallet development/full node wallet.webp';
import walletTwo from './../../src/assets/images/servicePagesLogos/wallet development/mobile wallet dev.webp';
import walletThree from './../../src/assets/images/servicePagesLogos/wallet development/blockchain crypto.webp';
import walletFour from './../../src/assets/images/servicePagesLogos/wallet development/desktop wallet.webp';
import walletFive from './../../src/assets/images/servicePagesLogos/wallet development/custodial wallet.webp';
import walletSix from './../../src/assets/images/servicePagesLogos/wallet development/coin specific wallet.webp';
import walletSeven from './../../src/assets/images/servicePagesLogos/wallet development/Enterprise-grade Wallet Development.webp';
import walletEight from './../../src/assets/images/servicePagesLogos/wallet development/Customizable Token Creation.webp';
import walletNine from './../../src/assets/images/servicePagesLogos/wallet development/Decentralized Exchange (DEX) Development.webp';
import walletTen from './../../src/assets/images/servicePagesLogos/wallet development/hardware-wallet-development.webp';
import walletEleven from './../../src/assets/images/servicePagesLogos/wallet development/blockchain web wallet.webp';


import DefiOne from './../../src/assets/images/servicePagesLogos/defi development/Decentralized DApps.webp';
import DefiTwo from './../../src/assets/images/servicePagesLogos/defi development/High-Volume Tokens.webp';
import DefiThree from './../../src/assets/images/servicePagesLogos/defi development/Robust Smart Contracts.webp';
import DefiFour from './../../src/assets/images/servicePagesLogos/defi development/Independent Wallets.webp';
import DefiFive from './../../src/assets/images/servicePagesLogos/defi development/Seamless Trading.webp';
import DefiSix from './../../src/assets/images/servicePagesLogos/defi development/Peace of Mind Insurance.webp';
import DefiSeven from './../../src/assets/images/servicePagesLogos/defi development/Direct Crypto Banking.webp';
import DefiEight from './../../src/assets/images/servicePagesLogos/defi development/Personalized DeFi Solution.webp';
import DefiNine from './../../src/assets/images/servicePagesLogos/defi development/flexible-lending-and-borrowing.webp';

import whiteOne from './../../src/assets/images/servicePagesLogos/white paper development/crypto white paper.webp';
import whiteTwo from './../../src/assets/images/servicePagesLogos/white paper development/ico white paper.webp';
import whiteThree from './../../src/assets/images/servicePagesLogos/white paper development/nft white paper.webp';
import whiteFour from './../../src/assets/images/servicePagesLogos/white paper development/defi white paper.webp';
import whiteFive from './../../src/assets/images/servicePagesLogos/white paper development/sto white paper.webp';
import whiteSix from './../../src/assets/images/servicePagesLogos/white paper development/blockchain applications.webp';


import exchangeOne from './../../src/assets/images/servicePagesLogos/exchange development/mobile crypto.webp';
// import exchangeOne from'./../../src/assets/images/servicePagesLogos/exchange development/';
import exchangeThree from './../../src/assets/images/servicePagesLogos/exchange development/white label.webp';
import exchangeFour from './../../src/assets/images/servicePagesLogos/exchange development/centralized ex.webp';
import exchangeFive from './../../src/assets/images/servicePagesLogos/exchange development/decentralized ex.webp';
import exchangeSix from './../../src/assets/images/servicePagesLogos/exchange development/hybird ex.webp';
import exchangeSeven from './../../src/assets/images/servicePagesLogos/exchange development/p2p ex.webp';
import exchangeEight from './../../src/assets/images/servicePagesLogos/exchange development/bitcoin ex.webp';
import exchangeNine from './../../src/assets/images/servicePagesLogos/exchange development/crypto wallet.webp';

import HyperOne from './../../src/assets/images/servicePagesLogos/hyperledger development/hyperledger fabric dev.webp';
import HyperTwo from './../../src/assets/images/servicePagesLogos/hyperledger development/hyperledger besu dev.webp';
import HyperThree from './../../src/assets/images/servicePagesLogos/hyperledger development/hyperledger burrow dev.webp';
import HyperFour from './../../src/assets/images/servicePagesLogos/hyperledger development/hyperledger sawtooth dev.webp';
import HyperFive from './../../src/assets/images/servicePagesLogos/hyperledger development/hyperledger indy.webp';
import HyperSix from './../../src/assets/images/servicePagesLogos/hyperledger development/hyperledger iroha.webp';
import HyperSeven from './../../src/assets/images/servicePagesLogos/hyperledger development/Hyperledger Consulting.webp';
import HyperEight from './../../src/assets/images/servicePagesLogos/hyperledger development/User Experience & Technical Design.webp';
import HyperNine from './../../src/assets/images/servicePagesLogos/hyperledger development/Hyperledger App Development.webp';
import HyperTen from './../../src/assets/images/servicePagesLogos/hyperledger development/Chaincode Development.webp';
import HyperEleven from './../../src/assets/images/servicePagesLogos/hyperledger development/Maintenance & Support.webp';
import HyperTwelve from './../../src/assets/images/servicePagesLogos/hyperledger development/Migration & Upgrade.webp';

import nearOne from './../../src/assets/images/servicePagesLogos/Near Protocol Development/Bespoke dApps for All.webp';
import nearTwo from './../../src/assets/images/servicePagesLogos/Near Protocol Development/Frictionless Wallets.webp';
import nearThree from './../../src/assets/images/servicePagesLogos/Near Protocol Development/Thriving NFT Marketplaces.webp';
import nearFour from './../../src/assets/images/servicePagesLogos/Near Protocol Development/DeFi Solutions.webp';
import nearFive from './../../src/assets/images/servicePagesLogos/Near Protocol Development/dex on near.webp';
import nearSix from './../../src/assets/images/servicePagesLogos/Near Protocol Development/Uncompromising Security.webp';

import staffOne from './../../src/assets/images/servicePagesLogos/staff augementation/short term.png';
import staffTwo from './../../src/assets/images/servicePagesLogos/staff augementation/long term.png';
import staffThree from './../../src/assets/images/servicePagesLogos/staff augementation/commodity.png';
import staffFour from './../../src/assets/images/servicePagesLogos/staff augementation/skill based.png';
import staffFive from './../../src/assets/images/servicePagesLogos/staff augementation/highly skilled.png';
import staffSix from './../../src/assets/images/servicePagesLogos/staff augementation/blockchain developers.webp';
import staffSeven from './../../src/assets/images/servicePagesLogos/staff augementation/full stack dev.webp';
import staffEight from './../../src/assets/images/servicePagesLogos/staff augementation/dedicated.webp';
import staffNine from './../../src/assets/images/servicePagesLogos/staff augementation/offshore dev.webp';
import staffTen from './../../src/assets/images/servicePagesLogos/staff augementation/devops eng.webp';
import staffEleven from './../../src/assets/images/servicePagesLogos/staff augementation/hybrid-augmentation.png';
import staffTwelve from './../../src/assets/images/servicePagesLogos/staff augementation/ai-ml-engineers-harnessing-the-power-of-data.webp';


import blockchainOne from './../../src/assets/images/servicePagesLogos/blockchain developers/Foundational Fluency.webp';
import blockchainTwo from './../../src/assets/images/servicePagesLogos/blockchain developers/Competitive Edge.webp';
import blockchainThree from './../../src/assets/images/servicePagesLogos/blockchain developers/Language Masters.webp';
import blockchainFour from './../../src/assets/images/servicePagesLogos/blockchain developers/Smart Contract Architects.webp';
import blockchainFive from './../../src/assets/images/servicePagesLogos/blockchain developers/Cryptocurrency Connoisseurs.webp';
import blockchainSix from './../../src/assets/images/servicePagesLogos/blockchain developers/Future Foresight.webp';
import blockchainSeven from './../../src/assets/images/servicePagesLogos/blockchain developers/Expert Matchmaking.webp';
import blockchainEight from './../../src/assets/images/servicePagesLogos/blockchain developers/Deep Understanding.webp';
import blockchainNine from './../../src/assets/images/servicePagesLogos/blockchain developers/Beyond the Code.webp';
import blockchainTen from './../../src/assets/images/servicePagesLogos/blockchain developers/Distributed Minds.webp';

import nftOne from './../../src/assets/images/servicePagesLogos/nft/Concept & Strategy.png';
import nftTwo from './../../src/assets/images/servicePagesLogos/nft/NFT Art & Design Services.png';
import nftThree from './../../src/assets/images/servicePagesLogos/nft/Marketing & Community Building.png';
import nftFour from './../../src/assets/images/servicePagesLogos/nft/Minting & Deployment.png';
import nftFive from './../../src/assets/images/servicePagesLogos/nft/Game Item Development.png';
import nftSix from './../../src/assets/images/servicePagesLogos/nft/Metaverse Integration.png';
import nftSeven from './../../src/assets/images/servicePagesLogos/nft/Ongoing Support & Maintenance.png';
import nftEight from './../../src/assets/images/servicePagesLogos/nft/NFT Staking Platform Development.png';
import nftNine from './../../src/assets/images/servicePagesLogos/nft/NFT Exchange Services.png';
import nftTen from './../../src/assets/images/servicePagesLogos/nft/NFT Marketplace Development Services.webp';
import nftEleven from './../../src/assets/images/servicePagesLogos/nft/security-compliance.png';
import nftTwelve from './../../src/assets/images/servicePagesLogos/nft/innovation-future-proofing.png';
import nftThirteen from './../../src/assets/images/servicePagesLogos/nft/Our NFT Development Services.png';

import nftBOne from './../../src/assets/images/servicePagesLogos/nft/nft for music.webp';
import nftBTwo from './../../src/assets/images/servicePagesLogos/nft/NFT for Real Estate.webp';
import nftBThree from './../../src/assets/images/servicePagesLogos/nft/NFT for Automotive.webp';
import nftBFour from './../../src/assets/images/servicePagesLogos/nft/NFT for Supply Chain Management.webp';
import nftBFive from './../../src/assets/images/servicePagesLogos/nft/NFT for Sports Games.webp';
import nftBSix from './../../src/assets/images/servicePagesLogos/nft/NFT for Art Tokenization.webp';
import nftBSeven from './../../src/assets/images/servicePagesLogos/nft/NFT for the Fashion Industry.webp';
import nftBEight from './../../src/assets/images/servicePagesLogos/nft/NFT for Domains.webp';
import nftBNine from './../../src/assets/images/servicePagesLogos/nft/NFT for Architecture.webp';

import nftMarketOne from './../../src/assets/images/servicePagesLogos/nft marketplace/Peer-to-Peer NFT Exchange.jpg';
import nftMarketTwo from './../../src/assets/images/servicePagesLogos/nft marketplace/Filters.jpg';
import nftMarketThree from './../../src/assets/images/servicePagesLogos/nft marketplace/NFT Lending Platform Development.jpg';
import nftMarketFour from './../../src/assets/images/servicePagesLogos/nft marketplace/Bespoke NFT Marketplaces.jpg';
import nftMarketFive from './../../src/assets/images/servicePagesLogos/nft marketplace/Advanced Search.jpg';
import nftMarketSix from './../../src/assets/images/servicePagesLogos/nft marketplace/Bid and Buy.png';
import nftMarketSeven from './../../src/assets/images/servicePagesLogos/nft marketplace/Features of our NFT Marketplace.png';
import nftMarketEight from './../../src/assets/images/servicePagesLogos/nft marketplace/Listing Status.png';
import nftMarketNine from './../../src/assets/images/servicePagesLogos/nft marketplace/Create Listing.jpg';
import nftMarketTen from './../../src/assets/images/servicePagesLogos/nft marketplace/Wallet.jpg';
import nftMarketEleven from './../../src/assets/images/servicePagesLogos/nft marketplace/Front End.jpg';
import nftMarketTwelve from './../../src/assets/images/servicePagesLogos/nft marketplace/Ratings and Feedback.png';

import contentOne from './../../src/assets/images/servicePagesLogos/contentWriting/blog post.png';
import contentTwo from './../../src/assets/images/servicePagesLogos/contentWriting/city pages.png';
import contentThree from './../../src/assets/images/servicePagesLogos/contentWriting/prodct.png';
import contentFour from './../../src/assets/images/servicePagesLogos/contentWriting/seo.png';
import contentFive from './../../src/assets/images/servicePagesLogos/contentWriting/press.png';
import contentSix from './../../src/assets/images/servicePagesLogos/contentWriting/meta.png';
import contentSeven from './../../src/assets/images/servicePagesLogos/contentWriting/tweets.png';
import contentEight from './../../src/assets/images/servicePagesLogos/contentWriting/fb post.png';
import contentNine from './../../src/assets/images/servicePagesLogos/contentWriting/content writing.png';
import contentTen from './../../src/assets/images/servicePagesLogos/contentWriting/Effortless Matching.webp';
import contentEleven from './../../src/assets/images/servicePagesLogos/contentWriting/Speed Demon Turnarounds.webp';
import contentTwelve from './../../src/assets/images/servicePagesLogos/contentWriting/Quality that Shines.webp';
import contentThirteen from './../../src/assets/images/servicePagesLogos/contentWriting/Flexibility that Flexes.webp';
import contentFourteen from './../../src/assets/images/servicePagesLogos/contentWriting/affordable-pricing.webp';
import contentFifteen from './../../src/assets/images/servicePagesLogos/contentWriting/satisfaction-guaranteed.webp';


import digitalOne from './../../src/assets/images/servicePagesLogos/Digital Marketing/social media marketing.webp';
import digitalTwo from './../../src/assets/images/servicePagesLogos/Digital Marketing/internet-marketing.webp';
import digitalThree from './../../src/assets/images/servicePagesLogos/Digital Marketing/SEO Services.webp';
import digitalFour from './../../src/assets/images/servicePagesLogos/Digital Marketing/Website Development( Crafting Stunning and Functional WEBSITES).webp';
import digitalFive from './../../src/assets/images/servicePagesLogos/Digital Marketing/Online Advertising.webp';
import digitalSix from './../../src/assets/images/servicePagesLogos/Digital Marketing/Content MARKETING.webp';
import digitalSeven from './../../src/assets/images/servicePagesLogos/Digital Marketing/influence marketing.webp';
import digitalEight from './../../src/assets/images/servicePagesLogos/Digital Marketing/Email Marketing.webp';
import digitalNine from './../../src/assets/images/servicePagesLogos/Digital Marketing/SEO Services.webp';
import digitalTen from './../../src/assets/images/servicePagesLogos/Digital Marketing/Pay Per Click Management.webp';
import digitalEleven from './../../src/assets/images/servicePagesLogos/Digital Marketing/App Store Optimization.webp';
import digitalTwelve from './../../src/assets/images/servicePagesLogos/Digital Marketing/DigitalMarketing.webp';



import graphicOne from './../../src/assets/images/servicePagesLogos/graphic designing/LOGO DESIGNING.webp';
import graphicTwo from './../../src/assets/images/servicePagesLogos/graphic designing/Business Card Design-01.webp';
import graphicThree from './../../src/assets/images/servicePagesLogos/graphic designing/illustration & infographic.webp';
import graphicFour from './../../src/assets/images/servicePagesLogos/graphic designing/Infographics Design Services.webp';
import graphicFive from './../../src/assets/images/servicePagesLogos/graphic designing/Website Design & Development.webp';
import graphicSix from './../../src/assets/images/servicePagesLogos/graphic designing/Marketing Materials.webp';
import graphicSeven from './../../src/assets/images/servicePagesLogos/graphic designing/UIUX Design.webp';
import graphicEight from './../../src/assets/images/servicePagesLogos/graphic designing/PPT DESIGN.webp';
import graphicNine from './../../src/assets/images/servicePagesLogos/graphic designing/print merch & packing design.webp';
import graphicTen from './../../src/assets/images/servicePagesLogos/graphic designing/digital ads.webp';
import graphicEleven from './../../src/assets/images/servicePagesLogos/graphic designing/Branding & Identity Design.webp';
import graphicTwelve from './../../src/assets/images/servicePagesLogos/graphic designing/3D logo designing.webp';
import graphicThirteen from './../../src/assets/images/servicePagesLogos/graphic designing/Web Banner.webp';
import graphicForteen from './../../src/assets/images/servicePagesLogos/graphic designing/Billboard Design.webp';
import graphicFifteen from './../../src/assets/images/servicePagesLogos/graphic designing/Art & Illustration.webp';
import graphicEighteen from './../../src/assets/images/servicePagesLogos/graphic designing/DigitalMarketing.webp';




import enterpriseTwo from './../../src/assets/images/servicePagesLogos/enterprise app dev/Enterprise Application Development.webp';
import enterpriseFour from './../../src/assets/images/servicePagesLogos/enterprise app dev/Enterprise Mobility Services.png';
import enterpriseFive from './../../src/assets/images/servicePagesLogos/enterprise app dev/Enterprise Web App Development.png';
import enterpriseSix from './../../src/assets/images/servicePagesLogos/enterprise app dev/Enterprise Application Integration.webp';
import enterpriseSeven from './../../src/assets/images/servicePagesLogos/enterprise app dev/Legacy App Modernization.png';
import enterpriseEight from './../../src/assets/images/servicePagesLogos/enterprise app dev/Software Integration Services.png';
import enterpriseNine from './../../src/assets/images/servicePagesLogos/enterprise app dev/Business Intelligence Solutions.png';
import enterpriseTen from './../../src/assets/images/servicePagesLogos/enterprise app dev/Custom Business Software Development.webp';
import enterpriseEleven from './../../src/assets/images/servicePagesLogos/enterprise app dev/Advanced Business Software.webp';

import customOne from './../../src/assets/images/servicePagesLogos/CustomSoftware/web app.webp';
import customTwo from './../../src/assets/images/servicePagesLogos/CustomSoftware/Custom Web App Development.webp';
import customThree from './../../src/assets/images/servicePagesLogos/CustomSoftware/Enterprise Resource Planning (ERP) Systems.webp';
import customFour from './../../src/assets/images/servicePagesLogos/CustomSoftware/Human Resource Management Systems (HRMS).webp';
import customFive from './../../src/assets/images/servicePagesLogos/CustomSoftware/Content Management Systems (CMS).webp';
import customSix from './../../src/assets/images/servicePagesLogos/CustomSoftware/Electronic Health Records (EHR).webp';
import customSeven from './../../src/assets/images/servicePagesLogos/CustomSoftware/Customer Relationship Management Software (CRM).webp';
import customEight from './../../src/assets/images/servicePagesLogos/CustomSoftware/Supply Chain Management Software.webp';
import customNine from './../../src/assets/images/servicePagesLogos/CustomSoftware/Enterprise Asset Management (EAM).webp';
import customTen from './../../src/assets/images/servicePagesLogos/CustomSoftware/Custom Accounting Software.webp';
import customEleven from './../../src/assets/images/servicePagesLogos/CustomSoftware/Business Intelligence Software.webp';
import customTwelve from './../../src/assets/images/servicePagesLogos/CustomSoftware/Business Process Management (BPM).webp';



// progressive web app
import webAppOne from './../../src/assets/images/servicePagesLogos/web app development/enteprise web apps.webp';
import webAppTwo from './../../src/assets/images/servicePagesLogos/web app development/custom web.webp';
import webAppThree from './../../src/assets/images/servicePagesLogos/web app development/web portals.webp';
import webAppFour from './../../src/assets/images/servicePagesLogos/web app development/Agile.webp';
import webAppFive from './../../src/assets/images/servicePagesLogos/web app development/supply chain management.webp';
import webAppSix from './../../src/assets/images/servicePagesLogos/web app development/web application consulting.webp';
import webAppSeven from './../../src/assets/images/servicePagesLogos/web app development/planning.webp';
import webAppEight from './../../src/assets/images/servicePagesLogos/web app development/design.webp';
import webAppNine from './../../src/assets/images/servicePagesLogos/web app development/prototype.webp';
import webNine from './../../src/assets/images/servicePagesLogos/web app development/Technologies we use  (3).webp';
import webAppTen from './../../src/assets/images/servicePagesLogos/web app development/2.webp';
import webAppEleven from './../../src/assets/images/servicePagesLogos/web app development/process.webp';
import webAppTwelve from './../../src/assets/images/servicePagesLogos/web app development/Industries We Serve.webp';
// import webAppTen from './../../src/assets/images/servicePagesLogos/web app development/designer.webp';
// import webAppEleven from './../../src/assets/images/servicePagesLogos/web app development/backend dev.webp';
// import webAppThirteen from './../../src/assets/images/servicePagesLogos/web app development/product manager.webp';
// import webAppFourteen from './../../src/assets/images/servicePagesLogos/web app development/quality assurance.webp';

import mobileOne from './../../src/assets/images/servicePagesLogos/mobile app dev/Our-Development-Process.webp';
import mobileTwo from './../../src/assets/images/servicePagesLogos/mobile app dev/IOS-Mobile-App-Development.webp';
import mobileThree from './../../src/assets/images/servicePagesLogos/mobile app dev/Hybrid-mobile-App-Development.webp';
import mobileFour from './../../src/assets/images/servicePagesLogos/mobile app dev/Custom-Mobile-App-Development.webp';
import mobileFive from './../../src/assets/images/servicePagesLogos/mobile app dev/Testing-Quality-Assurance.webp';
import mobileSix from './../../src/assets/images/servicePagesLogos/mobile app dev/Enterprise-Mobile-App-Development.webp';
// import mobileSeven from './../../src/assets/images/servicePagesLogos/mobile app dev/WhatsApp_Image_2024-07-28_at_23.32.24_c24b17e9-removebg-preview.webp';
import mobileEight from './../../src/assets/images/servicePagesLogos/mobile app dev/full transparency.webp';
import mobileNine from './../../src/assets/images/servicePagesLogos/mobile app dev/timely delivery.webp';
import mobileTen from './../../src/assets/images/servicePagesLogos/mobile app dev/flutter-app.webp';
import mobileEleven from './../../src/assets/images/servicePagesLogos/mobile app dev/user-centric-design.webp';
import mobileTwelve from './../../src/assets/images/servicePagesLogos/mobile app dev/flexible-pricing.webp';
import mobilethirteen from './../../src/assets/images/servicePagesLogos/mobile app dev/2859c0b681bbac28edf335c93e403bbd-removebg-preview (1).webp';
import mobileForteen from './../../src/assets/images/servicePagesLogos/mobile app dev/58706ba417e0cb4be6217713a19eb3ac-removebg-preview.webp';
import mobileFifteen from './../../src/assets/images/servicePagesLogos/mobile app dev/flexible-pricing.webp';
import mobileSixteen from './../../src/assets/images/servicePagesLogos/mobile app dev/flexible-pricing.webp';
import mobilSeventeen from './../../src/assets/images/servicePagesLogos/mobile app dev/WhyUs.webp';
import mobileEighteen from './../../src/assets/images/servicePagesLogos/mobile app dev/WhyAreWeBest.webp';

import uiOne from './../../src/assets/images/servicePagesLogos/uiUx/Web Interface Design.webp';
import uiTwo from './../../src/assets/images/servicePagesLogos/uiUx/User Experience Design.webp';
import uiThree from './../../src/assets/images/servicePagesLogos/uiUx/UX Audit.webp';
import uiFour from './../../src/assets/images/servicePagesLogos/uiUx/Accessibility Compliance.webp';
import uiThirteen from './../../src/assets/images/servicePagesLogos/uiUx/Conversion Optimization.webp';
import uiFourteen from './../../src/assets/images/servicePagesLogos/uiUx/Content Strategy & Development.webp';
import uiFifteen from './../../src/assets/images/servicePagesLogos/uiUx/Interaction Design.webp';
import uiSixteen from './../../src/assets/images/servicePagesLogos/uiUx/User Research & Analysis.webp';
import uiSeventeen from './../../src/assets/images/servicePagesLogos/uiUx/Usability Testing & Optimization.webp';
import uiEighteen from './../../src/assets/images/servicePagesLogos/uiUx/Information Architecture & Navigation.webp';
import uiNineteen from './../../src/assets/images/servicePagesLogos/uiUx/Branding & Style Guide Development.webp';


import uiFive from './../../src/assets/images/servicePagesLogos/uiUx/Interaction Design.webp';
import uiEight from './../../src/assets/images/servicePagesLogos/uiUx/Website Design & Development.webp';
import uiNine from './../../src/assets/images/servicePagesLogos/uiUx/User Interface Prototyping.webp';
import uiSix from './../../src/assets/images/servicePagesLogos/uiUx/Mobile App UX Design.webp';
import uiSeven from './../../src/assets/images/servicePagesLogos/uiUx/Web UX Design.webp';
import uiTen from './../../src/assets/images/servicePagesLogos/uiUx/Information Architecture & Navigation.webp';
import uiEleven from './../../src/assets/images/servicePagesLogos/uiUx/uiux design.webp';
import uiTwelve from './../../src/assets/images/servicePagesLogos/uiUx/figma.webp';
import uiTwenty from './../../src/assets/images/servicePagesLogos/uiUx/balsamiq.webp';
import uiTwentyOne from './../../src/assets/images/servicePagesLogos/uiUx/design-audit-evaluation.webp';


import productOne from './../../src/assets/images/servicePagesLogos/Product Development/Custom Software Product Development.webp';
import productTwo from './../../src/assets/images/servicePagesLogos/Product Development/MVP Software Development.webp';
import productThree from './../../src/assets/images/servicePagesLogos/Product Development/Nearshore and Offshore Software Development services.webp';
import productFour from './../../src/assets/images/servicePagesLogos/Product Development/Agile Development services.webp';
import productFive from './../../src/assets/images/servicePagesLogos/Product Development/Software Design and Development Services.webp';
import productSix from './../../src/assets/images/servicePagesLogos/Product Development/Web Application Development Services.webp';
import productSeven from './../../src/assets/images/servicePagesLogos/Product Development/Software Project Management services.webp';
import productEight from './../../src/assets/images/servicePagesLogos/Product Development/Software Development Life Cycle (SDLC).webp';
import productNine from './../../src/assets/images/servicePagesLogos/Product Development/Software Development Methodologies.webp';
import productTen from './../../src/assets/images/servicePagesLogos/Product Development/UXUI Design and Idea Development Services.webp';
import productEleven from './../../src/assets/images/servicePagesLogos/Product Development/Customized Software Solutions.webp';


import iotOne from './../../src/assets/images/servicePagesLogos/iot dev/full cycle iot.webp';
import iotTwo from './../../src/assets/images/servicePagesLogos/iot dev/Our Comprehensive IoT Development Services.webp';
import iotThree from './../../src/assets/images/servicePagesLogos/iot dev/IoT Software Development Services.webp';
import iotFour from './../../src/assets/images/servicePagesLogos/iot dev/IoT Application Development Services.webp';
import iotFive from './../../src/assets/images/servicePagesLogos/iot dev/IoT Solutions.webp';
import iotSix from './../../src/assets/images/servicePagesLogos/iot dev/Smart Home Automation.webp';
import iotSeven from './../../src/assets/images/servicePagesLogos/iot dev/IoT Firmware Development.webp';
import iotEight from './../../src/assets/images/servicePagesLogos/iot dev/IoT Data Analytics.webp';
import iotNine from './../../src/assets/images/servicePagesLogos/iot dev/IoT Technologies and Platforms.webp';
import iotTen from './../../src/assets/images/servicePagesLogos/iot dev/IoT Application Development Process.webp';
import iotEleven from './../../src/assets/images/servicePagesLogos/iot dev/raspbian.webp';
import iotTwelve from './../../src/assets/images/servicePagesLogos/iot dev/open scada.webp';
import iotThirteen from './../../src/assets/images/servicePagesLogos/iot dev/node red.webp';
import iotFourteen from './../../src/assets/images/servicePagesLogos/iot dev/aws.webp';
import iotFifteen from './../../src/assets/images/servicePagesLogos/iot dev/mqt.webp';


import spmOne from './../../src/assets/images/servicePagesLogos/software project management/Stakeholder Communication and Management.webp';
import spmTwo from './../../src/assets/images/servicePagesLogos/software project management/Quality Assurance and Testing.webp';
import spmThree from './../../src/assets/images/servicePagesLogos/software project management/Agile Project Management.webp';
import spmFour from './../../src/assets/images/servicePagesLogos/software project management/Project Planning and Scope Definition.webp';
import spmEleven from './../../src/assets/images/servicePagesLogos/software project management/Risk Management and Mitigation.webp';
import spmTwelve from './../../src/assets/images/servicePagesLogos/software project management/Project Monitoring and Reporting.webp';
import spmThirteen from './../../src/assets/images/servicePagesLogos/software project management/Resource Management and Team Building.webp';
import spmFourteen from './../../src/assets/images/servicePagesLogos/software project management/change-management-and-support.webp';



import spmFive from './../../src/assets/images/servicePagesLogos/software project management/trello.webp';
import spmSix from './../../src/assets/images/servicePagesLogos/software project management/active collab.webp';
import spmSeven from './../../src/assets/images/servicePagesLogos/software project management/basecamp.webp';
import spmEight from './../../src/assets/images/servicePagesLogos/software project management/asana.webp';
import spmNine from './../../src/assets/images/servicePagesLogos/software project management/podio.webp';
import spmTen from './../../src/assets/images/servicePagesLogos/software project management/jirra.webp';

import sqaOne from './../../src/assets/images/servicePagesLogos/software quality assurance/Proactive Approach.png';
import sqaTwo from './../../src/assets/images/servicePagesLogos/software quality assurance/Customer-Centric Focus.png';
import sqaThree from './../../src/assets/images/servicePagesLogos/software quality assurance/Improved Safety and Security.png';
import sqaFour from './../../src/assets/images/servicePagesLogos/software quality assurance/Comprehensive Expertise.png';
import sqaFive from './../../src/assets/images/servicePagesLogos/software quality assurance/Efficiency and Cost Savings.png';
import sqaSix from './../../src/assets/images/servicePagesLogos/software quality assurance/Continuous Improvement.png';
import sqaSeven from './../../src/assets/images/servicePagesLogos/software quality assurance/ISTQB-Certified Expertise.webp';
import sqaEight from './../../src/assets/images/servicePagesLogos/software quality assurance/Domain-Specific Savvy.webp';
import sqaNine from './../../src/assets/images/servicePagesLogos/software quality assurance/qa automation.webp';
import sqaTen from './../../src/assets/images/servicePagesLogos/software quality assurance/performance testing.webp';
import sqaEleven from './../../src/assets/images/servicePagesLogos/software quality assurance/security testing.png';
import sqaTwelve from './../../src/assets/images/servicePagesLogos/software quality assurance/usability testing.webp';
import sqaThirteen from './../../src/assets/images/servicePagesLogos/software quality assurance/mobile app testing.webp';
import sqaFourteen from './../../src/assets/images/servicePagesLogos/software quality assurance/compatilibility testing.webp';
import sqaFifteen from './../../src/assets/images/servicePagesLogos/software quality assurance/quality-consulting.webp';


import metaOne from './../../src/assets/images/servicePagesLogos/metaverse/gaming.webp';
import metaTwo from './../../src/assets/images/servicePagesLogos/metaverse/events.webp';
import metaThree from './../../src/assets/images/servicePagesLogos/metaverse/learning.webp';
import metaFour from './../../src/assets/images/servicePagesLogos/metaverse/soft house.webp';
import metaFive from './../../src/assets/images/servicePagesLogos/metaverse/real estate.webp';
import metaSix from './../../src/assets/images/servicePagesLogos/metaverse/health care.webp';
import metaSeven from './../../src/assets/images/servicePagesLogos/metaverse/retail.webp';
import metaEight from './../../src/assets/images/servicePagesLogos/metaverse/social media.webp';
import metaNine from './../../src/assets/images/servicePagesLogos/metaverse/hospitality.webp';
import metaTen from './../../src/assets/images/servicePagesLogos/metaverse/Metaverse Strategy and Consulting.png';
import metaEleven from './../../src/assets/images/servicePagesLogos/metaverse/3D World Design and Development.png';
import metaTwelve from './../../src/assets/images/servicePagesLogos/metaverse/Avatar Design and Development.png';
import metaThirteen from './../../src/assets/images/servicePagesLogos/metaverse/VR AR Development.png';
import metaFourteen from './../../src/assets/images/servicePagesLogos/metaverse/Game Development.png';
import metaFifteen from './../../src/assets/images/servicePagesLogos/metaverse/NFT Integration.png';
import metaSixteen from './../../src/assets/images/servicePagesLogos/metaverse/uiux-design.png';
import metaSeventeen from './../../src/assets/images/servicePagesLogos/metaverse/analytics-and-optimization.png';




import artificialOne from './../../src/assets/images/servicePagesLogos/Artificial Intelligence/Custom AI Solution.webp';
import artificialTwo from './../../src/assets/images/servicePagesLogos/Artificial Intelligence/Generative AI Development.webp';
import artificialThree from './../../src/assets/images/servicePagesLogos/Artificial Intelligence/Machine Learning.webp';
import artificialFour from './../../src/assets/images/servicePagesLogos/Artificial Intelligence/AI Chatbot Development.webp';
import artificialFive from './../../src/assets/images/servicePagesLogos/Artificial Intelligence/NLP.webp';
import artificialSix from './../../src/assets/images/servicePagesLogos/Artificial Intelligence/Computer Vision & Image Recognition.webp';
import artificialSeven from './../../src/assets/images/servicePagesLogos/Artificial Intelligence/Data Science & Analytics.webp';
import artificialEight from './../../src/assets/images/servicePagesLogos/Artificial Intelligence/AI Strategy & Consulting.webp';
import artificialNine from './../../src/assets/images/servicePagesLogos/Artificial Intelligence/Model Training & Deployment.webp';


import roboticOne from './../../src/assets/images/servicePagesLogos/RoboticProcess/Cost-Effective.webp';
import roboticTwo from './../../src/assets/images/servicePagesLogos/RoboticProcess/Better Security.webp';
import roboticThree from './../../src/assets/images/servicePagesLogos/RoboticProcess/Increased Employee Productivity.webp';
import roboticFour from './../../src/assets/images/servicePagesLogos/RoboticProcess/Versatility.webp';
import roboticFive from './../../src/assets/images/servicePagesLogos/RoboticProcess/Faster Performance.webp';
import roboticSix from './../../src/assets/images/servicePagesLogos/RoboticProcess/scalability-to-match-your-growth.webp';


import bigdataOne from './../../src/assets/images/servicePagesLogos/big data/Data Visualization & Storytelling.webp';
import bigdataTwo from './../../src/assets/images/servicePagesLogos/big data/Data Architecture & Engineering.webp';
import bigdataThree from './../../src/assets/images/servicePagesLogos/big data/Data Ingestion & Integration.webp';
import bigdataFour from './../../src/assets/images/servicePagesLogos/big data/Data Analytics & Insights.webp';
import bigdataFive from './../../src/assets/images/servicePagesLogos/big data/Data Strategy & Consulting.webp';
import bigdataSix from './../../src/assets/images/servicePagesLogos/big data/24 7 Support & Maintenance.webp';
import bigdataSeven from './../../src/assets/images/servicePagesLogos/big data/Cloud-Based & On-Premise Solutions.webp';
import bigdataEight from './../../src/assets/images/servicePagesLogos/big data/data-governance-compliance.webp';
import bigdataNine from './../../src/assets/images/servicePagesLogos/big data/machine-learning-ai-optimization.webp';


import naturalOne from './../../src/assets/images/servicePagesLogos/Natural Language Processing/Enhance Customer Experience.webp';
import naturalTwo from './../../src/assets/images/servicePagesLogos/Natural Language Processing/Automating Content Creation.webp';
import naturalThree from './../../src/assets/images/servicePagesLogos/Natural Language Processing/Gain Market Intelligence.webp';
import naturalFour from './../../src/assets/images/servicePagesLogos/Natural Language Processing/Develop Innovative Products.webp';
import naturalFive from './../../src/assets/images/servicePagesLogos/Natural Language Processing/Boost Operational Efficiency.webp';
import naturalSix from './../../src/assets/images/servicePagesLogos/Natural Language Processing/Breaking Language Barriers.webp';
import naturalSeven from './../../src/assets/images/servicePagesLogos/Natural Language Processing/Boosting Customer Experience.webp';
import naturalEight from './../../src/assets/images/servicePagesLogos/Natural Language Processing/Unlocking Insights from Data.webp';
import naturalNine from './../../src/assets/images/servicePagesLogos/Natural Language Processing/optimizing-content-quality.webp';


import fullstackOne from './../../src/assets/images/servicePagesLogos/FullstackDeveloper/Deep Expertise.jpg';
import fullstackTwo from './../../src/assets/images/servicePagesLogos/FullstackDeveloper/Versatility.jpg';
import fullstackThree from './../../src/assets/images/servicePagesLogos/FullstackDeveloper/Faster Development.jpg';
import fullstackFour from './../../src/assets/images/servicePagesLogos/FullstackDeveloper/Cost-Effectiveness.jpg';
import fullstackFive from './../../src/assets/images/servicePagesLogos/FullstackDeveloper/Improved Communication.jpg';
import fullstackSix from './../../src/assets/images/servicePagesLogos/FullstackDeveloper/Better Quality.jpg';


import dedicatedOne from './../../src/assets/images/servicePagesLogos/Dedicated Developers/Skill Synergy.jpg';
import dedicatedTwo from './../../src/assets/images/servicePagesLogos/Dedicated Developers/Your Vision, Our Execution.jpg';
import dedicatedThree from './../../src/assets/images/servicePagesLogos/Dedicated Developers/Seasonal Support.jpg';
import dedicatedFour from './../../src/assets/images/servicePagesLogos/Dedicated Developers/Scale Up or Down.webp';
import dedicatedFive from './../../src/assets/images/servicePagesLogos/Dedicated Developers/Expert Advantage.jpg';
import dedicatedSix from './../../src/assets/images/servicePagesLogos/Dedicated Developers/Lean and Mean Teams.png';
import dedicatedSeven from './../../src/assets/images/servicePagesLogos/Dedicated Developers/Hit the Ground Running.jpg';
import dedicatedEight from './../../src/assets/images/servicePagesLogos/Dedicated Developers/Quality Guaranteed.png';
import dedicatedNine from './../../src/assets/images/servicePagesLogos/Dedicated Developers/Built for Excellence.jpg';
import dedicatedTen from './../../src/assets/images/servicePagesLogos/Dedicated Developers/Remote Management Made Simple.jpg';
import dedicatedEleven from './../../src/assets/images/servicePagesLogos/Dedicated Developers/Peace of Mind.webp';
import dedicatedTwelve from './../../src/assets/images/servicePagesLogos/Dedicated Developers/No Infrastructure Investments.jpg';
import dedicatedThirteen from './../../src/assets/images/servicePagesLogos/Dedicated Developers/Focus on What Matters.jpg';


import offshoreOne from './../../src/assets/images/servicePagesLogos/offshoreDeveloper/Expert Project Management.webp';
import offshoreTwo from './../../src/assets/images/servicePagesLogos/offshoreDeveloper/Rigorous Quality Assurance.webp';
import offshoreThree from './../../src/assets/images/servicePagesLogos/offshoreDeveloper/24 7 Support, Always There for You.webp';
import offshoreFour from './../../src/assets/images/servicePagesLogos/offshoreDeveloper/Agile Development for Continuous Improvement.webp';


import nftmintingOne from './../../src/assets/images/servicePagesLogos/nftMinting/Decentralized Ownership.jpg';
import nftmintingTwo from './../../src/assets/images/servicePagesLogos/nftMinting/Publishing Real-World Assets.jpg';
import nftmintingThree from './../../src/assets/images/servicePagesLogos/nftMinting/Trading of Unique Digital Assets.jpg';

import nftDesignOne from './../../src/assets/images/servicePagesLogos/nft-design/Stunning Visual Design.jpg';
import nftDesignTwo from './../../src/assets/images/servicePagesLogos/nft-design/Generational Art and Avatar Collections.png';
import nftDesignThree from './../../src/assets/images/servicePagesLogos/nft-design/Technical Implementation.webp';
import nftDesignFour from './../../src/assets/images/servicePagesLogos/nft-design/Strategic Rarity Implementation.jpg';
import nftDesignFive from './../../src/assets/images/servicePagesLogos/nft-design/Metadata Optimization.png';
import nftDesignSix from './../../src/assets/images/servicePagesLogos/nft-design/Marketing and Community Building.jpg';
import nftDesignSeven from './../../src/assets/images/servicePagesLogos/nft-design/Concept Development and Consultation.jpg';
import nftDesignEight from './../../src/assets/images/servicePagesLogos/nft-design/Captivating Attention.webp';
import nftDesignNine from './../../src/assets/images/servicePagesLogos/nft-design/Building Brand Identity.webp';
import nftDesignTen from './../../src/assets/images/servicePagesLogos/nft-design/Fostering Community.webp';
import nftDesignEleven from './../../src/assets/images/servicePagesLogos/nft-design/Enhancing Value and Rarity.webp';
import nftDesignTwelve from './../../src/assets/images/servicePagesLogos/nft-design/Unlocking Utility.webp';

import communityOne from './../../src/assets/images/servicePagesLogos/community building/Fueling Growth and Adoption.webp';
import communityTwo from './../../src/assets/images/servicePagesLogos/community building/Unlocking Collective Intelligence.webp';
import communityThree from './../../src/assets/images/servicePagesLogos/community building/Shaping the Narrative and Mitigating Risk.webp';
import communityFour from './../../src/assets/images/servicePagesLogos/community building/Securing a Sustainable Future.webp';
import communityFive from './../../src/assets/images/servicePagesLogos/community building/Strategy and Planning.webp';
import communitySix from './../../src/assets/images/servicePagesLogos/community building/Platform Selection.webp';
import communitySeven from './../../src/assets/images/servicePagesLogos/community building/Content Creation.webp';
import communityEight from './../../src/assets/images/servicePagesLogos/community building/Community Management.webp';
import communityNine from './../../src/assets/images/servicePagesLogos/community building/Engagement Tactics.webp';
import communityTen from './../../src/assets/images/servicePagesLogos/community building/Growth Marketing.webp';
import communityEleven from './../../src/assets/images/servicePagesLogos/community building/Data & Analytics.webp';
import communityTwelve from './../../src/assets/images/servicePagesLogos/community building/cultivating-a-robust-ecosystem.webp';
import communityThirteen from './../../src/assets/images/servicePagesLogos/community building/enhancing-user-experience.webp';
import communityFourteen from './../../src/assets/images/servicePagesLogos/community building/event-and-meetup-coordination.webp';
import communityFifteen from './../../src/assets/images/servicePagesLogos/community building/data-driven-community-insights.webp';


//smart contract devlopment
const smartContract1 = [
  {
    id: 1,
    title: 'Smart Contract Development Services',
    para: `Welcome to Zai Systems where we specialize in top-tier Smart Contract Development Services that are designed to power your decentralized applications dApps and transform your blockchain projects into secure efficient and cutting-edge digital assets. Our commitment to excellence lies in crafting innovative solutions that leverage the latest blockchain technologies ensuring that your business stays ahead of the competition in the evolving world of decentralized 
    finance DeFi NFTs and beyond. In this rapidly expanding digital landscape having robust smart contracts is crucial for securing trust automating processes and powering successful blockchain-based initiatives. At Zai Systems we provide comprehensive services that cover the entire lifecycle of smart contract development from initial conceptualization and design to security auditing and deployment. Our experienced team is dedicated to delivering solutions that can be seamlessly integrated into your existing infrastructure. `,
    img: smartContract,
  }
]

const smartContract4a = [
  {
    id: 1,
    title: 'Our Core Smart Contract Development Services  ',
    para: `At Zai Systems we pride ourselves on offering a complete portfolio of Smart Contract Development Services designed to address every facet of blockchain-based initiatives. Our end-to-end solutions are grounded in a deep
    understanding of distributed ledger technology cryptography and the needs of modern businesses. `,
  },

]
const smartContract4 = [
  {
    id: 1,
    title: 'Smart Contract Design and Architecture ',
    para: `Building a reliable and efficient smart contract starts with a robust design. Our experts meticulously analyze your project requirements define logical flows and choose the appropriate blockchain platform such as 
    Ethereum BNB Chain Solana or Polkadot. We focus on laying down the structure of the contract ensuring that all functions data structures and state variables adhere to best practices for clarity maintainability and performance. Whether it is creating a token standard implementing supply chain tracking or building a DeFi protocol we provide a solid architectural blueprint that guarantees scalability and reliability.`,
    img: smartOne
  },
  {
    id: 2,
    title: 'Smart Contract Development for Multiple Blockchains ',
    para: `Each blockchain ecosystem has its own specificities from consensus protocols and programming languages to transaction fees and throughput. Our seasoned developers are proficient in Solidity Rust Move and other languages used across popular blockchain networks. If you are looking to build on Ethereum we excel in using Solidity to create ERC-20 ERC-721 ERC-1155 tokens or custom contracts. For projects on Solana our Rust expertise ensures that your programs run seamlessly with minimal gas fees and high transaction speeds. By remaining platform-agnostic we 
    help you pick the chain that best meets your project goals user base and technical needs.`,
    img: smartTwo
  },
  {
    id: 3,
    title: 'NFT Development and Marketplace Integration Non-Fungible Tokens ',
    para: `NFTs have transformed the way digital art collectibles gaming assets and real-world items are tokenized and traded. Zai Systems can guide you in creating unique tokenized assets that comply with the latest NFT standards. We also offer development services for NFT marketplaces so you can list buy sell or trade collectibles in a secure and user-friendly environment. Our approach prioritizes security scalability and intuitive design so you can deliver
    an exceptional experience for your end users whether they are artists collectors gamers or investors.`,
    img: smartThree
  },
  {
    id: 4,
    title: 'DeFi Smart Contracts Decentralized Finance ',
    para: `DeFi represents a new frontier in the world of finance enabling permissionless lending borrowing staking yield farming and more through self-executing contracts. We specialize in creating DeFi protocols that are reliable transparent and secure ranging from automated market makers AMMs and decentralized exchanges DEXs to liquidity pools and stablecoin mechanisms. Our team ensures that your DeFi solution adheres to the highest standards of smart contract auditing so you 
    can deliver a trustless environment that fosters user confidence and sustainable growth.`,
    img: smartFour
  },
  {
    id: 5,
    title: 'Token Launch and Crowdfunding Platforms',
    para: `Initial Coin Offerings ICOs, Security Token Offerings STOs and other token sale events have revolutionized how projects raise capital in the blockchain ecosystem. At Zai Systems we can develop and deploy token sale contracts that manage fundraising processes automatically track investor contributions and ensure compliance with relevant regulations. Our token launch services cover everything from smart contract creation and audit to integration with user-friendly dashboards that display real-time metrics about the sale. 
    By focusing on transparency and security we help you build trust with potential backers and investors.`,
    img: smartEleven
  },
  {
    id: 6,
    title: 'Smart Contract Auditing and Security Testing',
    para: `Security is paramount in any blockchain-based project since vulnerabilities can lead to significant financial losses and reputational damage. Our smart contract auditing and security testing services involve a thorough review of the codebase identifying potential flaws such as integer overflows re-entrancy issues and logic errors. We use both manual code reviews and automated testing tools to ensure comprehensive coverage. Upon completion we provide actionable recommendations to strengthen your contracts and mitigate risks. Our in-depth approach goes beyond mere static 
    analysis focusing on the intricacies of each function and the overarching design of your contract.`,
    img: smartTwelve
  },
  {
    id: 6,
    title: 'Enterprise Blockchain Consulting and Strategy ',
    para: `Not all businesses are ready to dive headfirst into blockchain. That is why we offer consulting services aimed at educating decision-makers and clarifying how distributed ledger technology can benefit your operations. We conduct needs assessments design custom blockchain strategies and outline practical roadmaps for implementation. Our consultants bring real-world experience in diverse industries allowing them to propose tailored solutions that drive tangible results. Whether you are a supply chain company seeking traceability an enterprise exploring data integrity or a startup 
    aiming to disrupt traditional finance we provide valuable insights that align with your goals.`,
    img: smartTwelve
  },
  {
    id: 6,
    title: 'Maintenance and Upgrade Services  ',
    para: `Blockchain projects do not end after deployment. Networks evolve forks occur regulations change and new features may be necessary to stay competitive. Zai Systems offers ongoing maintenance and upgrade services to keep your smart contracts secure and functional over time. This includes monitoring network performance optimizing code for lower gas costs and integrating upgrades to adapt to new technological standards. By providing long-term support we ensure that your platform remains compliant robust and ready for 
    future challenges. Our dedicated team is always available to resolve any issues that arise safeguarding your investments for the long haul.`,
    img: smartTwelve
  },
  {
    id: 6,
    title: 'Customization and Third-Party Integration ',
    para: `Every blockchain project has unique requirements that may call for specialized integration with third-party services or existing IT infrastructures. We can incorporate external APIs or oracles into your smart contracts so you can pull real-time data from trusted sources or connect your dApps to legacy systems. Additionally we integrate payment gateways KYC AML solutions and compliance frameworks to streamline user onboarding and regulatory adherence. Our customization services ensure your 
    project is as flexible as possible without compromising performance or security.`,
    img: smartTwelve
  }
]
const smartContract8a = [
  {
    id: 1,
    title: `Zai Systems' Smart Contract Development Services`,
    para: `Whether you're developing a cutting-edge Decentralized Application (DApp), exploring the possibilities 
    of Decentralized Finance (DeFi), or seeking innovative solutions for your business, our team of blockchain 
    architects and skilled developers is here to guide you every step of the way.`,


  },

]
const smartContract8 = [

  {
    id: 1,
    title: 'Decentralized Trading Made Secure',
    para: `Design and deploy peer-to-peer trading platforms powered by intelligent smart contracts that automatically 
    execute trades with enhanced privacy and asset security.`,
    img: smartFive
  },
  {
    id: 2,
    title: 'Empowering Startups & Organizations',
    para: `Raise capital with confidence using our secure and optimized smart contracts for ICOs, IDOs, and other 
    crowdfunding initiatives.`,
    img: smartSix
  },
  {
    id: 3,
    title: 'Tailored Solutions for Any Industry',
    para: `From healthcare and finance to supply chain, insurance, and logistics, we leverage smart contracts to solve 
    your unique business challenges across diverse industries.`,
    img: smartSeven
  },
  {
    id: 4,
    title: 'DeFi Smart Contracts Innovation',
    para: `We create crypto smart contracts for insurance, transactions, invoices, asset auditing, and more. We are experts
     in Ethereum-based token development. Use our skills in ERC20, ERC721, and other standards to make custom tokens for your
      DeFi ecosystem.`,
    img: smartEight
  },

  {
    id: 5,
    title: 'DApp Development at its Core',
    para: `Embed the power of automation into your DApps with secure and efficient smart contracts, crafted by our experts to fit your
     application’s logic. From development to design and integration, we manage the DApp lifecycle, ensuring a seamless experience.`,
    img: smartNine
  },
  {
    id: 6,
    title: 'Performance is Key',
    para: `Squeeze every drop of efficiency out of your smart contracts. Our advanced optimization techniques,
     such as using fixed-size byte arrays and library utilization, and constant variables, minimize gas costs, ensuring smooth and 
     secure operation on any blockchain network.`,
    img: smartTen
  }

]
const smartContract5a = [
  {
    id: 1,
    title: 'Benefits of Smart Contracts with Zai Systems',
    para: `We specialize in developing precise and secure smart contracts tailored to your specific needs.
     By implementing our smart contract solutions, you can unlock a world of benefits, including:`,
  },

]
const smartContract5 = [
  {
    id: 1,
    title: 'Unmatched Autonomy',
    para: `Imagine self-executing agreements without intermediaries. Smart contracts have pre-set conditions that,
     once met, autonomously trigger actions like releasing funds or transferring ownership.  This autonomy streamlines
      workflows, eliminates delays, and empowers 
    you to control your business interactions.`,
  },
  {
    id: 2,
    title: 'Fort Knox-Level Security',
    para: `Smart contracts are built on the blockchain technology's 
   immutability and security. Transactions are stored on a tamper-proof distributed ledger, ensuring transparency and trust in interactions,
    allowing you to collaborate with confidence knowing
      that your agreements are always safe and secure.`,
  },
  {
    id: 3,
    title: 'Cost Savings that Boost Bottom Line',
    para: `Tired of hefty fees and middlemen taking a bite out of your profits? Smart contracts eliminate the need for any third-party involvement in transaction settlements and agreement enforcement. This translates to significant cost savings, freeing up valuable resources to invest in areas that truly drive your business forward.`,
  }
]
const smartContract6a = [
  {
    id: 1,
    title: 'Why Choose Zai Systems for Smart Contract Development Services?',
    para: `Zai Systems is your trusted partner for Smart Contract Development services. We have over 12 years of experience 
    in the IT industry, delivering innovative and high-quality solutions to our clients worldwide. We have successfully 
    completed over 50 Smart Contract projects, across various domains and industries. Some of the benefits of choosing us are:`,
  },

]
const smartContract6 = [
  {
    id: 1,
    title: 'Experienced and skilled developers',
    para: `Our team of 100+ Smart Contract developers possesses deep expertise in Blockchain technology. They employ
     best practices to craft secure, scalable, and efficient Smart Contracts.`,
  },
  {
    id: 2,
    title: 'Customized & cost-effective solutions',
    para: `We tailor our Smart Contract Development services to your specific needs and budget. We offer flexible and 
    transparent pricing models, ensuring you get the best value for your money.`,
  },
  {
    id: 3,
    title: 'Fast and reliable delivery',
    para: `We utilize agile methodologies and efficient processes to deliver Smart Contract solutions promptly and
     within scope. Our ongoing support ensures smooth and effective operation.`,
  }
]
// const smartContract13a = [
//   {
//     id: 1,
//     title: 'Our Smart Contract Development Workflow',
//     para: `We believe smart contracts are more than just lines of code; they're catalysts for transformation.
//      That's why we've crafted a meticulous workflow that takes your vision and translates it into a secure,
//       efficient, and impactful smart contract solution.`,
//   },

// ]
// const smartContract13 = [
//   {
//     id: 1,
//     title: 'Laying the Foundation: Requirement Gathering',
//     para: (
//       <>
//         <span class='text-white'>Problem Evaluation: </span> A thorough assessment of the problem domain identifies potential
//         challenges and opportunities, guiding optimal contract design.
//       </>
//     ),
//     para1: (
//       <>
//         <span class='text-white'>Deep Dive into Needs: </span> We begin by meticulously analyzing your specific requirements,
//         objectives, and constraints to ensure a tailored solution.
//       </>
//     ),

//     para2: (
//       <>
//         <span class='text-white'>Mapping the Roadmap: </span> We collaboratively define a clear roadmap with milestones,
//         ensuring alignment with your expectations and project completion within planned timelines.
//       </>
//     )
//   },
//   {
//     id: 2,
//     title: 'Blueprinting the Contract: Design and Development',
//     para: (
//       <>
//         <span class='text-white'>Structuring for Success: </span> We meticulously define the smart contract's architecture
//         and functionalities, ensuring a seamless and secure execution of terms.
//       </>
//     ),
//     para1: (
//       <>
//         <span class='text-white'>Visualizing Flow:  </span>Flow diagrams map out the contract's logical
//         progression, enhancing understanding and facilitating collaboration.
//       </>
//     ),
//     para2: (
//       <>
//         <span class='text-white'>Coding with Precision: </span> Our team of blockchain experts crafts the smart contract
//         using appropriate programming languages and tools, emphasizing security and best practices.
//       </>
//     )
//   },
//   {
//     id: 3,
//     title: 'Rigorous Testing and Deployment',
//     para: (
//       <>
//         <span class='text-white'>Testnet Trial: </span>Before launching on the main network, the contract is deployed on a testnet, a blockchain
//         replica used for experimentation and validation. This allows you to test and debug your contract without risking real funds.
//       </>
//     ),
//     para1: (
//       <>
//         <span class='text-white'>Thorough Testing: </span> Rigorous testing is conducted to verify its functionality, security,
//         and resilience against any potential vulnerabilities.
//       </>
//     ),
//     para2: (
//       <>
//         <span class='text-white'>Mainnet Launch: </span> Upon successful testing, the contract is deployed on the target
//         blockchain's mainnet, making it accessible and usable by all network participants.
//       </>
//     )
//   },
//   {
//     id: 4,
//     title: 'Additional Considerations',
//     para: (
//       <>
//         <span class='text-white'>Security Audits: </span> To further enhance confidence, independent security audits are
//         often conducted to identify and address any unforeseen vulnerabilities.
//       </>
//     ),
//     para1: (
//       <>
//         <span class='text-white'>Maintenance and Updates: </span> Ongoing monitoring and maintenance are crucial to ensure
//         the contract's continued performance and address any potential issues that may arise.
//       </>
//     )
//   },
//   {
//     id: 5,
//     title: 'Compliance & Legal Alignment',
//     para: (
//       <>
//         <span class='text-white'>Regulatory Review: </span> Before deployment, it’s essential to review the smart contract in the
//         context of relevant legal frameworks and regulations. This step ensures that the contract complies with all applicable laws and
//         industry standards, minimizing legal risks.
//       </>
//     ),
//     para1: (
//       <>
//         <span class='text-white'>Legal Consultation: </span>  Engaging with legal experts who specialize in blockchain and smart contract law can provide valuable insights.
//         They can help navigate the complex legal landscape and suggest modifications to ensure the contract’s terms are enforceable and compliant.
//       </>
//     ),
//     para2: (
//       <>
//         <span class='text-white'>Documentation and Transparency: </span>  Maintain comprehensive documentation of the smart contract’s functions,
//         terms, and conditions. This transparency aids in legal assessments and can be crucial for audits, dispute resolutions, and regulatory reporting.
//       </>
//     )
//   },
//   {
//     id: 6,
//     title: 'Compliance and Adaptability Review',
//     para: (
//       <>
//         <span class='text-white'>Legal Framework Alignment: </span> We conduct a comprehensive review of the smart contract against relevant legal
//         and regulatory frameworks to ensure full compliance with current laws and standards.
//       </>
//     ),
//     para1: (
//       <>
//         <span class='text-white'>Adaptability for Future Regulations: </span>  By designing the smart contract with flexibility in mind, we prepare
//         for potential changes in legislation, making it easier to adapt and update as necessary without compromising the integrity of the contract.
//       </>
//     ),
//     para2: (
//       <>
//         <span class='text-white'>Stakeholder Engagement: </span>  We engage with legal experts, regulatory bodies, and stakeholders to validate
//         the smart contract’s compliance and to foster trust and transparency in its operations.
//       </>
//     )
//   },
// ]

const SeoDataSmart = [
  {
    id: 1,
    title: `Smart Contract Development Company - Zai Systems`,
    content: `Hire the Top Smart Contract Development Company. We Build Secure & Efficient Smart Contracts for Any Blockchain Platform.`,
    link: `https://www.zaisystems.com/services/smart-contract-development`
  }
]
const ContractFAQs = [
  { questions: 'What is a smart contract?', answers: 'A smart contract is essentially a self-executing piece of code stored on a blockchain that automatically enforces the terms of an agreement once predetermined conditions are met. Smart contracts eliminate the need for intermediaries simplify transaction processes and reduce costs. They are widely used in DeFi NFT projects supply chain management and other industries to guarantee trust and transparency.' },
  { questions: 'Why do I need Smart Contract Development Services?', answers: 'Developing secure efficient and error-free smart contracts requires specialized expertise. A single vulnerability can lead to financial losses or reputational damage. Expert Smart Contract Development Services such as those offered by Zai Systems ensure that your contracts are built according to best practices thoroughly audited for security and optimized for performance and cost savings.' },
  { questions: 'Which blockchain platforms do you support?', answers: 'Zai Systems supports a range of platforms including Ethereum BNB Chain Solana Polkadot and more. We are proficient in multiple programming languages like Solidity Rust and Move. Our platform-agnostic approach allows us to choose the best blockchain ecosystem that aligns with your specific project requirements and budget constraints.' },
  { questions: 'What industries can benefit from smart contracts?', answers: 'Smart contracts have applications across numerous sectors including finance healthcare supply chain gaming real estate and digital identity verification. Any business process that requires trustless transactions automation and transparency can benefit from implementing smart contracts. Our services are customized to meet the unique needs of each industry ensuring a comprehensive solution that delivers measurable value.' },
  { questions: 'How long does it take to develop a smart contract?', answers: 'Development timelines vary based on complexity scope and the specific functionalities required. Simple ERC-20 tokens may be built and tested within a few days while more sophisticated DeFi platforms can take several weeks or months. During our initial consultation we estimate a realistic timeline based on project requirements so you have clear expectations from the outset.' },
  { questions: 'What about security and audits?', answers: 'Security is a top priority in all our projects. We offer end-to-end auditing services that identify and mitigate potential vulnerabilities such as re-entrancy issues integer overflows and logic errors. By employing both automated tools and manual code reviews we ensure a thorough assessment. After the audit we provide detailed reports and offer recommendations to strengthen your smart contracts.' },
  { questions: 'Can my smart contract be upgraded after deployment?', answers: 'Certain upgrade mechanisms like the proxy pattern can allow for modifications even after deployment though they must be implemented carefully to maintain immutability and user trust. Our developers can design upgradable contracts when necessary allowing for added functionalities or security patches in response to evolving needs or regulatory changes.' },
  { questions: 'How do you handle compliance and regulations?', answers: 'Regulatory considerations vary by jurisdiction and project type. We stay informed about global guidelines for digital assets token offerings and related financial regulations. If required we integrate KYC AML modules and other compliance features into your contracts. This approach helps you maintain a compliant project and avoid legal pitfalls.' },
  { questions: 'Do you provide post-deployment support?', answers: 'Yes. We offer ongoing maintenance and support services including feature enhancements bug fixes and performance optimizations. Our goal is to help your platform thrive post-launch with minimal disruptions. We also keep an eye on network upgrades or forks and can advise on strategies to ensure smooth transitions as the blockchain ecosystem evolves.' },
  { questions: 'How do I get started with Zai Systems?', answers: 'Getting started is simple. You can reach out to us via our website or scheduling a consultation call. During the initial discussion we will gather key details about your project goals budget and timeline. Our team then prepares a proposal outlining the scope of work project milestones and deliverables. Once aligned we kick off the development process ensuring a transparent and efficient collaboration from day one.' },
];


// wallet development

const wallet1 = [
  {
    id: 1,
    title: 'Cryptocurrency Wallet Development Solutions',
    para: `In the rapidly evolving landscape of cryptocurrency, a reliable and secure wallet is the cornerstone of your digital 
asset journey. At Zai Systems, we understand that your crypto is more than just an investment; it's a gateway to financial 
freedom and innovative possibilities. That's why we offer unrivaled cryptocurrency wallet development solutions designed 
to safeguard your holdings while empowering you to manage them with ease.`,
    img: wallet,
  }
]

const wallet2 = [
  {
    id: 1,
    title: 'Secure and Reliable Cryptocurrency Wallet Development Solutions',
    para: `With the explosive growth of blockchain technologies and digital assets, secure and user-friendly cryptocurrency wallets
     have become vital tools for navigating the decentralized landscape. At Zai Systems, we offer comprehensive end-to-end 
     wallet development solutions tailored to your specific needs.`,

  },

]
const wallet3 = [
  {
    id: 1,
    title: 'Cryptocurrency Wallet Development That Creates a Competitive Edge',
  },

]
const wallet4a = [
  {
    id: 1,
    title: 'Our Diverse Wallet Portfolio'

  },

]
const wallet4 = [
  {
    id: 1,
    title: `Full Node Wallet Development`,
    para: `Experience maximum security with our advanced full node wallets. These feature-rich solutions 
    independently verify transactions
     and offer unmatched control over your digital assets.`,
    img: walletOne
  },
  {
    id: 2,
    title: `Mobile Wallet Development`,
    para: `Zai Systems crafts secure and intuitive mobile wallets for both iOS and Android devices. Our expertise
     ensures convenient access to your funds and swift cryptocurrency transactions on the go.`,
    img: walletTwo
  },
  {
    id: 3,
    title: `Custom Blockchain-Powered Wallets`,
    para: `Let our team unleash your vision with custom integrations. We specialize in building wallets that seamlessly 
    integrate with plugins, third-party services, and other blockchain applications.`,
    img: walletThree
  },
  {
    id: 4,
    title: `Desktop Wallet Development`,
    para: `Prioritizing exceptional security, Zai Systems delivers customizable desktop wallets. These solutions offer enhanced
     control and protection for your valuable crypto assets.`,
    img: walletFour
  },
  {
    id: 6,
    title: `Custodial Wallet Applications`,
    para: `We understand the comfort of secure third-party management. Our custodial wallet development
     ensures convenient fund handling without the anxiety of key loss.`,
    img: walletFive
  },
  {
    id: 7,
    title: `Coin-Specific Wallets`,
    para: `Focus on the coins you love. Zai Systems offers dedicated Bitcoin, Ethereum, and other 
    cryptocurrency-specific wallets optimized for smooth and reliable operation.`,
    img: walletSix
  },

  {
    id: 9,
    title: `Customizable Token Creation`,
    para: `Design and launch your own branded cryptocurrency or utility token with our expert guidance and technical expertise.`,
    img: walletEight
  },


  {
    id: 10,
    title: `Smart Contract Development`,
    para: `Develop secure and efficient smart contracts to automate various aspects of your blockchain-based applications.`,
    img: blockchainDevOne
  },
  {
    id: 5,
    title: `Blockchain Web Wallets`,
    para: `Our web wallets let you store, send, and receive cryptocurrencies. We offer security, multi-currency, and scalability.`,
    img: walletEleven
  },
  {
    id: 8,
    title: `Enterprise-grade Wallet Development`,
    para: `Secure and scalable wallet solutions for businesses, from startups 
    to established financial institutions.`,
    img: walletSeven
  },
  {
    id: 11,
    title: `Decentralized Exchange Development`,
    para: `Create your own customized DEX platform to facilitate fast, secure and peer-to-peer trading of cryptocurrencies.`,
    img: walletNine
  },
  {
    id: 12,
    title: `Hardware Wallet Development`,
    para: `Zai Systems’ hardware wallets safeguard cryptocurrencies with superior security and convenient backups.`,
    img: walletTen
  }
]
const wallet7a = [
  {
    id: 1,
    title: `Beyond Crypto-Blockchain Wallets in Today's Business Landscape`,
    para: `While blockchain technology initially gained fame as the backbone of cryptocurrencies, its potential 
    extends far beyond digital coins. Today, blockchain offers innovative solutions across diverse industries, 
    revolutionizing how we approach data security, transparency, and efficiency..`,
    para1: `At Zai Systems, we recognize the power of blockchain wallets to not only secure crypto assets but also
     streamline various business operations. With the burgeoning number of cryptocurrency users and transactions 
     worldwide, reliable and secure wallets are more crucial than ever.`
  },

]

const wallet5a = [
  {
    id: 1,
    title: 'Zai Systems: Your Trusted Partner in Crypto Wallet Development',
    para: `Our team of seasoned blockchain experts specializes in crafting secure and feature-rich crypto wallets tailored 
    to your specific business needs. Our wallets provide seamless solutions for a range of benefits:`
  },

]
const wallet5 = [

  {
    id: 1,
    title: 'Enhanced Security',
    para: `Leverage industry-leading cryptography and secure key management protocols to safeguard your valuable digital assets.`
  },
  {
    id: 2,
    title: 'Frictionless Crypto Payments',
    para: `Integrate secure digital payments through public and private key infrastructure, enabling smooth and efficient transactions.`
  },
  {
    id: 3,
    title: 'Decentralized Control',
    para: `Maintain full autonomy over your funds, independently and free from centralized control or interference.`
  }
  ,
  {
    id: 4,
    title: 'Real-time Transparency',
    para: `Gain instant access to your cryptocurrency holdings and transaction history for enhanced financial clarity.`
  },
  {
    id: 5,
    title: 'Streamlined Trade and Exchange',
    para: `Facilitate the seamless buying, selling, and trading of various cryptocurrencies within your platform.`
  },
  {
    id: 6,
    title: 'Multi-currency Support',
    para: `Manage a diverse portfolio of digital assets with our wallets' comprehensive multi-currency functionality.`
  }
]
const wallet6a = [
  {
    id: 1,
    title: 'Our Feature-Packed Cryptocurrency Wallets for the Modern User',
    para: `We design and develop feature-rich wallets that surpass user expectations and give your business a competitive edge.`
  },

]
const wallet6 = [

  {
    id: 1,
    title: 'Unparalleled Security',
    para: (
      <>
        <span class='text-white'>Two-Factor Authentication: </span>Protect your valuable assets with an extra layer of
        security. Enable two-factor authentication for every login, ensuring only authorized users gain access.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Industry-Leading Encryption: </span>Your private keys are secured
        with military-grade encryption, keeping your cryptocurrency safe from even the most determined hackers.
      </>
    )
  },
  {
    id: 2,
    title: 'Blazing-Fast Transactions',
    para: (
      <>
        <span class='text-white'>Optimized Performance: </span>Our wallets are fast. Say goodbye to sluggish
        transactions and experience instant confirmation times, allowing you to trade and move your funds seamlessly.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Global Reach: </span>Transact with users worldwide without geographical limitations.
        Our wallets facilitate borderless transactions, empowering you to participate in the global crypto market easily.
      </>
    )
  },
  {
    id: 3,
    title: 'Effortless User Experience',
    para: (
      <>
        <span class='text-white'>Intuitive Interface: </span>Whether you're a seasoned crypto veteran or a curious newcomer,
        our user-friendly interface makes navigating your wallet a breeze in any situation with just a few clicks.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Customization Options: </span>Personalize your wallet experience to fit your needs and preferences. Choose
        from a range of themes, layouts, and features to make your wallet feel truly your own.
      </>
    )
  }
  ,
  {
    id: 4,
    title: 'Unmatched Versatility',
    para: (
      <>
        <span class='text-white'>Multi-Currency Support: </span>Don't be limited to one token. Our wallets support a
        vast array of cryptocurrencies, allowing you to manage your diverse portfolio in one convenient place.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Seamless Customization: </span>Need a specific feature or integration? Our skilled development
        team can tailor your wallet to your exact requirements, ensuring it perfectly complements your workflow.
      </>
    )
  },
  {
    id: 5,
    title: 'Cross-Platform Accessibility',
    para: (
      <>
        <span class='text-white'>Universal Compatibility: </span>Access your crypto anywhere, anytime. Our wallets are available
        on all major platforms, including Android, iOS, Windows, and macOS.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Desktop and Mobile Options: </span>Choose the platform that suits your needs. Whether you
        prefer the convenience of mobile device or the security of your desktop, we have the perfect wallet for you.
      </>
    )
  },
  {
    id: 6,
    title: 'Global Connectivity',
    para: (
      <>
        <span class='text-white'>Exchange Integrations: </span>Stay connected to the crypto ecosystem. Our developers
        seamlessly integrate your wallet with crypto exchanges, making buying, selling, and trading a breeze.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Enhanced Security Protocols: </span> Our wallet integration not only simplifies transactions
        but also fortifies your assets with advanced
        encryption and fraud detection systems.
      </>
    )
  }
]
const SeoDataWallet = [
  {
    id: 1,
    title: `Cryptocurrency Wallet Development Services- Zai Systems`,
    content: `Fortress Your Crypto: Secure & User-Friendly Cryptocurrency Wallet Development. Build trust & protect user assets with our custom, feature-rich wallet solutions. `,
    link: `https://www.zaisystems.com/services/wallet-development`
  }
]
// defi development
const defi1 = [
  {
    id: 1,
    title: 'Decentralized Finance Development Services',
    para: `At Zai Systems, we stand on the precipice of a financial revolution. We are a dedicated team of
     builders, architects, and visionaries spearheading the Decentralized Finance (DeFi) Development Services. `,
    para1: `DeFi represents a seismic shift in the way we interact with financial services. It transcends 
    the confines of traditional, centralized institutions, empowering individuals with unprecedented control
     over their finances. Imagine a world where borrowing, lending, trading, and investing occur seamlessly, 
     peer-to-peer, without the gatekeepers, bureaucracy, and limitations of the legacy system. This is the world
      DeFi promises, and Zai Systems is at the forefront of its construction. `,
    img: defi,
  }
]
const defi4a = [
  {
    id: 1,
    title: 'Dive Into the Benefits of Our Comprehensive DeFi Development Services',
    para: `Zai Systems empowers you to navigate the cutting-edge world of Decentralized Finance (DeFi) with secure, 
    reliable solutions designed to revolutionize your financial experience.`
  },

]
const defi4 = [
  {
    id: 1,
    title: 'Decentralized DApps',
    para: `Ditch centralized servers and experience the enhanced security and transparency of Zai's custom-built DeFi DApps.`,
    img: DefiOne
  },
  {
    id: 2,
    title: 'High-Volume Tokens',
    para: `Generate DeFi tokens primed for success, attracting users and fueling vibrant ecosystems within your platform.`,
    img: DefiTwo
  },
  {
    id: 3,
    title: 'Robust Smart Contracts',
    para: `Automate trust with Zai's smart contracts. Streamline transactions, eliminate intermediaries,
     and reduce disputes.`,
    img: DefiThree
  },
  {
    id: 4,
    title: 'Independent Wallets',
    para: `Control your funds with user-friendly DeFi wallets built by Zai. Ditch third-party reliance and enjoy 
    increased security and privacy.`,
    img: DefiFour
  },
  {
    id: 5,
    title: 'Seamless Trading',
    para: `Power your DeFi ecosystem with a bespoke decentralized exchange, enabling secure and frictionless trading 
    of digital assets.`,
    img: DefiFive
  },
  {
    id: 6,
    title: 'Peace of Mind Insurance',
    para: `Zai's DeFi insurance platforms go the extra mile, meticulously analyzing potential risks and ensuring your 
    financial safety net.`,
    img: DefiSix
  },
  {
    id: 7,
    title: 'Direct Crypto Banking',
    para: `Bypass institutions and enjoy peer-to-peer freedom. Zai’s mobile payments integrate with wallets, empowering transactions and monitoring.`,
    img: DefiSeven
  },
  {
    id: 8,
    title: 'Personalized DeFi Solution',
    para: `Let Zai fit a DeFi platform to your specific needs. Our experts prioritize your vision, crafting a custom 
    solution that perfectly complements your unique goals.`,
    img: DefiEight
  },
  {
    id: 9,
    title: 'Flexible Lending and Borrowing',
    para: ` Access a variety of lending and borrowing options with Zai’s DeFi platforms. 
    Earn interest on your assets, borrow at low rates, and leverage your collateral.`,
    img: DefiNine
  }
]


const defi12a = [

  {
    id: 1,
    title: 'Navigating the DeFi Landscape with Zai Systems',
    para: `At Zai Systems, we understand that choosing the right blockchain network for your DeFi project is crucial.
     Each network offers unique strengths and capabilities, and Zai is here to help you navigate the diverse landscape.`,
    para1: `Here's a breakdown of some of the prominent blockchains powering DeFi innovation:`
  }
]
const defi12 = [
  {
    id: 1,
    title: 'Ethereum',
    para: `The trailblazer of DeFi, Ethereum has decentralized countless financial services, including lending, 
    borrowing, trading, and more. Its vast ecosystem and active community make it a popular choice.`,
    img: etheriumLogo
  },
  {
    id: 2,
    title: 'Polygon',
    para: `Designed to enhance Ethereum's capabilities, Polygon provides a framework for building blockchains 
    that offer faster transactions and efficiency, unlocking a scalable DeFi experience.`,
    img: polygonLogo
  },
  {
    id: 3,
    title: 'Cardano',
    para: `With a focus on interoperability and long-term sustainability, Cardano prioritizes seamless cross-chain 
    communication and growth potential, making it a promising platform for future DeFi expansion.`,
    img: cardanoLogo
  },
  {
    id: 4,
    title: 'Solana',
    para: `Known for its impressive super speed and affordability, Solana offers lightning-fast transactions and 
    low fees, making it ideal for DeFi applications that demand high scalability.`,
    img: solanaLogo
  },
  {
    id: 5,
    title: 'Avalanche',
    para: `Combining speed, low costs, and programmability, Avalanche empowers developers to create innovative 
    DeFi solutions with a focus on user experience and affordability.`,
    img: avalanche
  },
  {
    id: 6,
    title: 'Polkadot',
    para: `Championing interoperability, Polkadot allows DeFi applications to seamlessly interact across 
    multiple blockchains, opening up a world of interconnected financial possibilities.`,
    img: polkadot
  }
]
// const defi5a = [
//   {
//     id: 1,
//     title: `Embrace Financial Liberation with Zai Systems' DeFi Solutions`,
//     para: `Traditional finance can feel like a maze - tangled with middlemen, paperwork, and limited access.
//      But at Zai Systems, we're building a brighter future with Decentralized Finance (DeFi). Here's how 
//      DeFi, powered by Zai, empowers you:`
//   },

// ]
// const defi5 = [
//   {
//     id: 1,
//     title: 'Cut the Cord',
//     para: `Ditch the gatekeepers! DeFi operates peer-to-peer, meaning no banks or brokers bog down your
//      transactions. Borrow, buy, or sell directly with other users, enjoying transparency and control.`
//   },
//   {
//     id: 2,
//     title: 'Open for Everyone',
//     para: `Your internet connection is your passport to the DeFi world. Unbanked? Underbanked? No problem. 
//     With Zai, anyone can participate in the financial ecosystem, unlocking opportunities.`
//   },
//   {
//     id: 3,
//     title: 'Profit Unleashed',
//     para: `DeFi isn't just about access; it's about opportunity. Smartly trade assets and generate profit 
//     through innovative mechanisms designed for your financial success.`
//   },

//   {
//     id: 5,
//     title: 'Frictionless Funding',
//     para: `Need a loan? Scrap the paperwork purgatory. DeFi opens doors to faster, easier access to funds
//      without the traditional hurdles.`
//   },
//   {
//     id: 6,
//     title: 'Yield & Grow',
//     para: `DeFi turns saving into adventure. Earn returns on assets through DeFi's yield-generating capabilities.
//      Watch wealth flourish with Zai.`
//   },
//   {
//     id: 7,
//     title: 'Transparency Reigns',
//     para: `In DeFi, everything is clear. Transactions are public and verifiable, fostering trust and confidence 
// in the financial system.`
//   },
//   {
//     id: 4,
//     title: 'Build Your Brilliance',
//     para: `Forget proprietary walls. DeFi products and apps are open-source and composable, meaning you 
//     can build upon them and create your own financial tools. Unleash your inner innovator with Zai Systems.`
//   },
//   {
//     id: 8,
//     title: 'Secure Your Assets ',
//     para: `DeFi gives you the power to protect your assets from theft, hacking, or censorship.
//     Zai’s DeFi platforms use cutting-edge encryption and blockchain technology to ensure your funds are always safe and accessible.`
//   },
//   {
//     id: 9,
//     title: 'Explore New Markets ',
//     para: `DeFi opens up a world of possibilities for trading and investing. Zai’s 
//   DeFi platforms connect you to diverse and dynamic markets, where you can discover new opportunities and maximize your returns.`
//   }
// ]
const defi6a = [
  {
    id: 1,
    title: 'Dive into the Diverse World of DeFi with Zai Systems',
    para: `The world of DeFi is vast and ever-evolving, and Zai Systems is your compass. We guide you through these 
    diverse categories, helping you discover the perfect DeFi solutions to empower your financial journey`
  },

]
const defi6 = [
  {
    id: 1,
    title: 'Exchanges',
    para: `Ditch the centralized middlemen! DeFi exchanges, like Uniswap and SushiSwap, enable peer-to-peer digital 
    asset trading with more control and transparency.`
  },
  {
    id: 2,
    title: 'Derivative',
    para: `DeFi derivatives, like Synthetix and Mirror Finance,
     let you hedge risks, leverage opportunities, and explore various asset classes, all without intermediaries.`

  },
  {
    id: 3,
    title: 'Stablecoins',
    para: `Embrace financial stability. DeFi stablecoins, like Tether and Dai, peg their value to real-world 
    assets, offering a secure haven in the volatile crypto world.`

  },
  {
    id: 4,
    title: 'Lottery',
    para: `Get a taste of fortune! DeFi lotteries, like PoolTogether and DAO Jones, inject a thrilling element 
    into the DeFi space, offering a chance to win big while contributing to community pools.`

  },

  {
    id: 6,
    title: 'Funds Management',
    para: `Invest like a pro, without the hefty fees. DeFi funds management platforms, like Yearn Finance and 
    AlphaZero, automate investment and wealth creation, making sophisticated tools accessible.`

  },
  {
    id: 8,
    title: 'Payments',
    para: `Ditch the plastic, embrace the future. DeFi payment solutions, like MakerDAO's DAI, enable seamless,
     borderless transactions without the limitations of traditional financial systems.`
  },
  {
    id: 7,
    title: 'Insurance',
    para: `Safeguard your future innovatively. DeFi insurance protocols, like Nexus Mutual and Etherisc, 
    provide innovative risk protection against hacks, smart contract failures, and more, offering peace in the digital realm.`
  },
  {
    id: 5,
    title: 'Lending & Borrowing',
    para: `Borrow without breaking a sweat. DeFi lending platforms, like Aave and Compound, empower you to borrow 
    and lend digital assets at competitive rates, unlocking greater control over your finances.`

  },
  {
    id: 9,
    title: 'Governance ',
    para: `Shape the future of DeFi with your voice. DeFi governance platforms, like MakerDAO and Compound, 
    enable you to participate in the decision-making process of the DeFi protocols, ensuring fairness and accountability.`

  }
]
const defi13a = [
  {
    id: 1,
    title: 'Why Zai Systems?',

  },

]
const defi13 = [

  {
    id: 2,
    title: 'Competitive edge for your brand',
    para: `Whether you're a startup or an established enterprise, we customize DeFi solutions that empower 
    your growth and give you a distinct advantage in the digital marketplace.`

  },
  {
    id: 3,
    title: 'Transparency built-in',
    para: `No more black boxes, no more hidden fees. Our DeFi platforms are built on trust and clarity,
     ensuring every transaction is readily auditable and traceable.`

  },
  {
    id: 5,
    title: 'Innovation driving your success',
    para: `We don't settle for the status quo. We constantly explore and implement the latest DeFi 
    advancements, ensuring your platform stays ahead of the curve.`

  },
  {
    id: 1,
    title: 'Expertise you can trust',
    para: `Our team boasts seasoned DeFi developers build robust,
     industry-specific solutions. We understand the nuances of lending, trading, and DeFi.`
  },
  {
    id: 4,
    title: 'Security at its core',
    para: `We prioritize safety for your funds and data. Our advanced security protocols avoid
     risks & prevent vulnerabilities, granting you peace of mind.`

  },
  {
    id: 6,
    title: 'Scalability for your growth',
    para: ` No matter your business size, our DeFi 
    platforms scale to meet your needs. We leverage cutting-edge technologies
    for performance and efficiency.`

  },
]
const SeoDataDefi = [
  {
    id: 1,
    title: `Defi Development Services - Zai Systems`,
    content: `Access Global Markets and reach a wider audience & unlock new funding opportunities with a borderless DeFi solution.`,
    link: `https://www.zaisystems.com/services/defi-development`
  }
]
// white paper development
const white1 = [
  {
    id: 1,
    title: 'White Paper Development Services',
    para: `Welcome to Zai Systems, your premier destination for comprehensive White Paper Development Services that elevate your brand to new heights in the competitive marketplace. In today's rapidly evolving digital environment, businesses need to showcase thought leadership, foster trust, and clearly articulate their expertise. One of the 
    most impactful ways to achieve these objectives is through professionally crafted white papers. At Zai Systems, we understand the power of an engaging, research-driven white paper to position you as an industry leader while generating high-quality leads and building long-term credibility.`,
    img: white,
  }
]

const white2a = [
  {
    id: 1,
    title: 'Our Comprehensive White Paper Development Services',
    para: `Whether you are a startup looking to break into your market, an established enterprise aiming to strengthen your authority, or a B2B company seeking a compelling narrative for your next product launch, our team is ready to provide the detailed content and strategic insight you require.`
  },

]
const white4 = [
  {
    id: 1,
    title: 'White Paper Concept and Strategy ',
    para: `One of the most common challenges that businesses face is defining the right strategy for their white papers. Our expert strategists at Zai Systems begin by thoroughly understanding your business objectives, target audience, and competitive landscape. We then collaborate with you to develop a clear concept that resonates with your prospects and aligns with your marketing goals. With our data-driven approach, we identify trending topics, relevant industry pain points, and thought-provoking angles that will help your white paper stand out in a crowded digital space. This initial phase sets 
    the foundation for a successful white paper that captures interest and drives targeted leads.`,
    img: roboticOne
  },
  {
    id: 2,
    title: 'Research-Driven Content Creation ',
    para: `A strong white paper is anchored in accurate, relevant, and compelling research. Our team of skilled researchers and writers dives deep into the latest market trends, scholarly articles, industry reports, and reputable data sources to gather the information that forms the backbone of your white paper. We specialize in transforming complex information into an accessible narrative, ensuring that the finished product appeals to both technical experts and lay readers. Through this approach, our white papers deliver
    real value, reinforcing your authority and enhancing your brand's reputation as a knowledge hub.`,
    img: roboticFour

  },
  {
    id: 3,
    title: 'Subject Matter Expertise ',
    para: `At Zai Systems, we have subject matter experts from a wide range of fields who work closely with our writing team. From technology to healthcare, finance to legal, and everything in between, our experts keep a close eye on the nuances and developments within their areas of expertise. This collaboration infuses the white paper with credibility and depth, allowing your target audience to fully appreciate the insights you are sharing. Our subject matter experts work hand in hand with our writers to validate facts, provide relevant examples, and ensure accuracy. 
    This synergy guarantees that your white paper is not only persuasive but also factually sound.`,
    img: roboticTwo

  },
  {
    id: 4,
    title: 'Professional Writing and Editing ',
    para: `The heart of any successful white paper lies in clear, concise, and engaging writing. At Zai Systems, our skilled writers draw upon their experience in technical writing, business communications, and creative storytelling to craft a document that is both informative and captivating. We understand the importance of a coherent structure that guides readers logically through your arguments, key points, and data. After writing, our team of editors meticulously reviews the content for clarity, accuracy, and grammatical precision. They refine the tone and style to ensure it reflects your brand identity and resonates with your audience. With our rigorous quality control 
    process, we guarantee a polished final product that represents your organization at its best.`,
    img: roboticThree

  },

  {
    id: 5,
    title: 'Visual Design And Layout ',
    para: `In the digital age, attention spans are shorter, and visual appeal plays a significant role in retaining an audience's interest. Our graphic designers are adept at transforming complex ideas into visually appealing graphics, charts, and illustrations. Through careful layout choices, colors, typography, and overall design elements, we ensure your white paper stands out while maintaining a professional look and feel. The final design is mobile-responsive and easy to navigate, which significantly boosts user engagement and encourages readers to explore every section
    of the white paper. By aligning the visuals with your branding, we reinforce recognition and enhance the impact of your messaging.`,
    img: roboticFive

  },

  {
    id: 6,
    title: 'Call-To-Action And Lead Generation ',
    para: `White papers are powerful tools for generating leads and driving conversions. At Zai Systems, we integrate strategic calls-to-action throughout the content to encourage your readers to connect with your sales or support teams. Whether your goal is to schedule a product demo, download additional resources, or sign up for a webinar, we carefully place prompts that feel natural and enticing. By optimizing the white paper for lead capture, we help you transform engaged readers into potential customers who are genuinely interested in your products or services. This holistic approach seamlessly merges content marketing with sales enablement, 
    maximizing the return on your investment in White Paper Development Services.`,
    img: roboticSix

  },
  {
    id: 6,
    title: 'Distribution and Promotion Strategies  ',
    para: `Your white paper is only as valuable as the audience it reaches. At Zai Systems, we go beyond the creation of your white paper by offering tailored distribution and promotion strategies. We help you identify the channels that will have the greatest impact, from your company website and social media platforms to industry-specific forums, email campaigns, and influencer networks. By implementing multi-channel marketing and leveraging SEO best practices, we make sure your white paper gains maximum visibility. This integrated approach increases 
    the likelihood of capturing the interest of decision-makers and thought leaders in your niche.`,
    img: roboticSix

  },
  {
    id: 6,
    title: 'Performance Analytics and Optimization  ',
    para: `We understand the importance of tracking performance metrics to evaluate the effectiveness of your white paper. Through advanced analytics, we monitor downloads, click-through rates, time on page, and other essential data points that highlight user engagement. Our marketing experts then analyze this data to recommend optimizations for future white papers or related content. This results-driven method allows you to refine your content marketing strategy, ensuring that each subsequent white paper yields even better outcomes. At Zai Systems, 
    we believe in continuous improvement, and we work closely with you to drive consistent, measurable growth.`,
    img: roboticSix

  }

]
const white8a = [
  {
    id: 1,
    title: 'Our Comprehensive White Paper Development Services',
    para: `Your innovative cryptocurrency project deserves a white paper that shines as brightly as your idea. 
    We deliver impactful white papers that not only explain your project's technical aspects but also capture 
    the imagination and inspire investor confidence.`,
    para1: `Dive into our specialized services:`
  }
]
const white8 = [

  {
    id: 1,
    title: 'ICO Whitepaper Writing',
    para: (
      <>
        <span class='text-white'>Raising Capital, Building Trust: </span>We craft convincing ICO white papers that attract
        investors and secure funding for your project's launch.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Roadmap to Success: </span>We map out your development roadmap, milestones, and token distribution
        strategy, demonstrating transparency and long-term commitment.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>Investment Magnet: </span>We present your token economics with clarity and
        transparency, showcasing its utility and potential for growth.
      </>
    ),
    img: whiteTwo
  },
  {
    id: 2,
    title: 'NFT Whitepaper Writing',
    para: (
      <>
        <span class='text-white'>Unlocking the Potential of Uniqueness: </span>We craft compelling NFT white
        papers that showcase the value and potential of your non-fungible tokens.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>A Story of Scarcity and Utility: </span>We explore your NFTs’ features and
        use cases, highlighting their role in community engagement and asset ownership.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>Building a Marketplace Legacy: </span>We envision your NFT marketplace’s future,
        outlining its architecture, governance, and expansion plans.
      </>
    ),
    img: whiteThree
  },
  {
    id: 3,
    title: 'DeFi Whitepaper Writing',
    para: (
      <>
        <span class='text-white'> Decentralized Revolution Defined: </span>We explain your DeFi protocol’s intricacies with
        precision, emphasizing its security, transparency, and value.
      </>
    ),
    para1: (
      <>
        <span class='text-white'> Investing in the Future of Finance: </span>We articulate your DeFi platform’s benefits,
        demonstrating its potential to disrupt traditional finance.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>Sustainable Success Strategies: </span>We present your token's role in fueling
        the ecosystem and outline measures to ensure long-term stability and growth.
      </>
    ),
    img: whiteFour
  },
  {
    id: 4,
    title: 'Cryptocurrency Whitepaper Writing',
    para: (
      <>
        <span class='text-white'>Expert Tech Writers: </span>Our team of passionate writers, fluent in the language
        of blockchain, dissects your concept and weaves it into a captivating narrative.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Crystal-Clear Explanations: </span>We demystify complex technical details, ensuring
        even non-technical readers understand your project's purpose and potential.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>Compelling Use Cases: </span>We highlight the real-world problems your cryptocurrency
        solves, showcasing its value proposition and market relevance.
      </>
    ),
    img: whiteOne
  },
  {
    id: 5,
    title: 'STO Whitepaper Writing',
    para: (
      <>
        <span class='text-white'>Bridging the Gap Between Blockchain and Regulation: </span>We guide security token
        offerings and ensure STO white paper strict compliance standards.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Building Investor Confidence: </span>We highlight the advantages of tokenized
        securities, emphasizing investor protection mechanisms and exit strategies.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>Unlocking New Investment Opportunities: </span>We showcase the potential of your
        STO to revolutionize fundraising and ownership in traditional assets.
      </>
    ),
    img: whiteFive
  },
  {
    id: 6,
    title: 'Blockchain Apps: A Whitepaper',
    para: (
      <>
        <span class='text-white'>Transforming Industries with Blockchain: </span>We demonstrate how your application uses
        blockchain technology to solve real-world problems and drive innovation.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>A Vision for the Future: </span>We map out the detailed roadmap for your application’s
        seamless integration with existing systems and its potential impact on various industries.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>Inspiring Collaboration and Trust: </span>We emphasize the transparency and security benefits of your
        blockchain solution, fostering trust and adoption.
      </>
    ),
    img: whiteSix
  }
]
const white5a = [
  {
    id: 1,
    title: 'Why White Papers Matter?'

  },

]
const white5 = [
  {
    id: 1,
    title: 'Establish Expertise',
    para: `A well-crafted white paper positions you as the thought leader in your field. 
    Demonstrate your in-depth knowledge, technical prowess, and innovative solutions.
   `
  },
  {
    id: 2,
    title: 'Attract Investors',
    para: `For groundbreaking projects, especially in blockchain or AI, a white paper is key. Showcase your vision, roadmap, and market potential clearly and authoritatively.`

  },
  {
    id: 3,
    title: 'Grow Your Audience',
    para: `Inform and educate your target audience. Generate interest, build trust, and drive 
    engagement with a white paper that resonates with their pain points and aspirations.`

  }
]
const white6a = [
  {
    id: 1,
    title: 'White Paper Writing Services in USA for Business Industry',
    para: `Do you want to generate leads, establish expertise, and drive impact through cutting-edge white papers?
     Look no further than Zai Systems! We are your premium white paper writing partner, helping businesses 
     in the USA leverage the power of this insightful content format.`,
    para1: `Here's how we bring your white paper development:`
  }

]
const white6 = [
  {
    id: 1,
    title: 'Attention-grabbing content',
    para: `We craft captivating narratives that hook readers from the first page. Our white
     papers are designed to hold your audience's attention and guide them through
      a persuasive journey.`
  },
  {
    id: 2,
    title: 'Impactful development',
    para: `We go beyond just writing. We offer comprehensive white paper development services, including
     research, data analysis, visual design, and strategic distribution.`
  },
  {
    id: 3,
    title: 'Expert guidance',
    para: `Our team of skilled writers and researchers possess deep industry knowledge. We 
    collaborate with you to understand your needs and tailor the white paper 
    to your specific goals.`

  },
  {
    id: 4,
    title: 'Sales-generating focus',
    para: `We understand the marketing power of white papers. Every aspect of our writing is tuned to
     convert interest into leads and ultimately, sales.`
  },
  {
    id: 5,
    title: 'Domain expertise',
    para: `Whether you're in tech, finance, healthcare, or another sector, we have expertise to 
    craft white papers that establish your brand as a leader.`

  },
  {
    id: 6,
    title: 'Innovative Thought Leadership',
    para: `Enhance your brand with Zai Systems’ white papers, crafted to inform and inspire, establishing you as a thought leader in your industry.`

  }
]
// // const white13a = [
// {
//   id: 1,
//     title: 'Benefits of White Paper Development Services: Engaging and Empowering with Zai Systems',
//       para: ` Zai Systems knows the power of a well-crafted white paper. Beyond just promoting a business, 
//     it strategically positions you as a leader in your field, drawing in potential clients and 
//     collaborators. Here's how:`
// },
// ]
// const  = [
//   {
//     id: 1,
//     title: 'Raising Awareness & Visibility',
//     para: (
//       <>
//         <span class='text-white'>Reach a targeted audience:</span> Zai Systems' white paper development
//         services help you focus on a specific audience, attracting qualified leads and boosting brand
//         recognition within your industry.
//       </>
//     ),
//     para1: (
//       <>
//         <span class='text-white'>Drive organic traffic: </span>Well-researched and optimized white papers
//         rank higher in search engines, drawing readers naturally to your website and content.
//       </>
//     ),
//     para2: (
//       <>
//         <span class='text-white'>Go beyond your website:</span> Share your white paper across channels
//         like social media, email marketing, and industry publications,  amplifying your reach and visibility.
//       </>
//     ),
//   },
//   {
//     id: 2,
//     title: 'Establishing Expertise & Authority',
//     para: (
//       <>
//         <span class='text-white'>Showcase your knowledge:</span> Zai Systems' skilled writers translate complex concepts into digestible
//         content, demonstrating your mastery of the subject matter and positioning you as a leader.
//       </>
//     ),
//     para1: (
//       <>
//         <span class='text-white'>Build trust and credibility: </span>High-quality white papers inspire confidence in your
//         brand and solutions, increasing prospects' willingness to engage with your business.
//       </>
//     ),
//     para2: (
//       <>
//         <span class='text-white'>Differentiate yourself from competitors: </span>Stand out from the crowd by offering valuable
//         insights and data-driven solutions through your white paper.
//       </>
//     )
//   },
//   {
//     id: 3,
//     title: 'Creating Legitimacy & Trust',
//     para: (
//       <>
//         <span class='text-white'> Present a professional image:</span> Zai Systems' white paper development services ensure
//         a polished and authoritative document, reflecting positively on your brand's credibility and trustworthiness.
//       </>
//     ),
//     para1: (
//       <>
//         <span class='text-white'>Increase perceived value:</span> A comprehensive white paper demonstrates your commitment
//         to research and development, making your solutions appear more valuable.
//       </>
//     ),
//     para2: (
//       <>
//         <span class='text-white'> Build stronger relationships:</span> By sharing valuable knowledge and insights, you build
//         trust and transparency with potential partners and collaborators.
//       </>
//     )

//   },
//   {
//     id: 4,
//     title: 'Sharing Technical Knowledge & Insights',
//     para: (
//       <>
//         <span class='text-white'>Educate your audience: </span>Provide valuable information and address complex
//         problems within your industry, establishing yourself as a trusted resource.
//       </>
//     ),
//     para1: (
//       <>
//         <span class='text-white'>Stay ahead of the curve: </span>Share your research and innovations, influencing
//         industry trends and positioning yourself at the forefront of your field.
//       </>
//     ),
//     para2: (
//       <>
//         <span class='text-white'>Attract top talent: </span>Showcase your company's technical expertise and innovation,
//         attracting skilled professionals eager to join your team.
//       </>
//     )

//   },

//   {
//     id: 5,
//     title: 'Boosting Engagement & Lead Generation',
//     para: (
//       <>
//         <span class='text-white'>Capture valuable leads: </span>By offering white papers in exchange for contact information,
//         you generate qualified leads with genuine interest in your solutions.
//       </>
//     ),
//     para1: (
//       <>
//         <span class='text-white'> Spark deeper conversations: </span>White papers enable discussions and interactions
//         with prospects, nurturing leads and build stronger relationships.
//       </>
//     ),
//     para2: (
//       <>
//         <span class='text-white'>Fuel your sales funnel: </span>Utilize your white paper to educate potential customers
//         and guide their decision-making in the sales process.
//       </>
//     )

//   },
//   {
//     id: 6,
//     title: 'Publicize Your Business and Solutions',
//     para: (
//       <>
//         <span class='text-white'> Gain media attention:</span> High-quality white papers can attract positive media coverage,
//         further expanding your reach and brand awareness among your potential target audience.
//       </>
//     ),
//     para1: (
//       <>
//         <span class='text-white'> Attract potential investors:</span>Showcase your business potential and innovative
//         ideas to investors through a well-structured white paper.
//       </>
//     ),
//     para2: (
//       <>
//         <span class='text-white'> Drive sales and growth:</span>Ultimately, well-written and informative white papers contribute to
//         increased sales and revenue for your business.
//       </>
//     )

//   }
// ]

const SeoDataWhite = [
  {
    id: 1,
    title: `White Paper Development Services - Zai Systems`,
    content: `Turn Ideas into Investment Magnets with Expert White Paper Development Services for Funding Success. Craft a compelling narrative & secure capital with our research-driven, investor-ready white papers.`,
    link: `https://www.zaisystems.com/services/white-paper-development`
  }
]
const WhiteFAQs = [
  { questions: 'What is a white paper and why do I need one?', answers: 'A white paper is an authoritative report or guide that concisely presents a complex problem and provides a solution, backed by data, research, or expert opinions. It is often used by businesses to educate their audience, showcase thought leadership, and attract potential customers. A well-crafted white paper can establish trust, highlight your expertise, and serve as a lead generation tool. If you want to deepen engagement with your customers and differentiate your brand in a competitive market, a white paper is an excellent asset.' },
  { questions: 'How long should a white paper be?', answers: ' There is no one-size-fits-all answer. The ideal length depends on the complexity of the topic and your target audiences expectations. Many white papers range from six to ten pages, but some can span 20 pages or more. At Zai Systems, we focus on delivering clear, concise content that covers the subject matter thoroughly. We work with you to determine the right length to address your topic without overwhelming readers.' },
  { questions: 'How does Zai Systems ensure the white paper remains on brand?', answers: 'We begin by learning about your brand identity, messaging guidelines, and target audience. We also review any previous marketing materials you have to ensure consistency. Throughout the writing and design process, we maintain open communication and seek approvals at key milestones. This collaborative approach guarantees that the final product is fully aligned with your brands tone, style, and visual elements.' },
  { questions: 'What industries does Zai Systems serve?', answers: ' Our team has experience working with diverse industries including technology, healthcare, finance, manufacturing, legal, real estate, and more. We pair our writers with subject matter experts to handle industry-specific nuances. No matter how specialized your field is, our comprehensive approach ensures that your white paper speaks authoritatively to your audience.' },
  { questions: 'Can I provide my own research and data for the white paper?', answers: 'Absolutely. We encourage our clients to share any existing research, case studies, or proprietary data that can enrich the content. Our researchers and writers will integrate your materials into the final white paper, giving credit where it is due and ensuring accurate references. If you need additional research or validation, our experts can help fill those gaps to present a well-rounded narrative.' },
  { questions: 'What is the typical turnaround time for a white paper?', answers: 'The timeline can vary based on factors like topic complexity, research scope, and design requirements. Generally, a high-quality white paper can take anywhere from a few weeks to a couple of months to complete. At Zai Systems, we work diligently to meet your deadlines while maintaining rigorous quality standards. During our initial consultation, we provide an estimated timeline and keep you updated on progress.' },
  { questions: 'How do you handle revisions?', answers: ' We believe in complete client satisfaction and offer multiple revision rounds to incorporate your feedback. Whether you have suggestions regarding the tone, data accuracy, or design elements, our team is committed to perfecting the final product. Our revision process is streamlined to ensure minimal delays while still giving you the opportunity to shape the white paper to your preferences.' },
  { questions: 'Do you offer design-only services?', answers: ' Yes. If you already have the content but need professional design to make it visually compelling, we offer a standalone design service. Our graphic design experts will collaborate with you to develop layouts, color schemes, and infographics that enhance readability and brand appeal. This can significantly boost reader engagement and make your existing content more memorable.' },
  { questions: 'How do you help with promotion?', answers: 'Creating a white paper is only part of the process. We offer robust distribution and promotion strategies to ensure your white paper reaches the right audience. This can include optimizing for search engines, placing calls-to-action in your marketing funnel, leveraging social media channels, and launching targeted email campaigns. Our ultimate goal is to help you maximize your return on investment by driving qualified leads and establishing your brand as a thought leader.' },
  { questions: '•	What are the costs involved?', answers: 'Zai Systems provides various service packages based on your specific requirements, from full-cycle white paper development to individual offerings like writing or design. Costs vary depending on factors like complexity, research needs, and design scope. During the initial consultation, we will discuss your goals and budget. We then recommend a customized package that gives you the best value without compromising on quality.' },
];


// exchange development
const exchange1 = [
  {
    id: 1,
    title: 'Cryptocurrency Exchange Development Services',
    para: `Zai Systems is a crypto exchange development company that is a global leader and growth-oriented 
    company. We provide various cryptocurrency exchange development services that guarantee a secure, reliable,
     and user-friendly platform for crypto traders. Our software solution ensures that your platform stands out
      in the crypto exchange market.`,
    para1: `Cryptocurrency exchange development services involve building a platform that allows customers to buy,
     sell, or swap cryptocurrencies or digital currencies for various assets, such as fiat money or other digital
      currencies. Customers can use payment methods such as credit cards, wire transfers, or other supported means
       to exchange cryptocurrencies. At our company, we are experts in providing cryptocurrency exchange development
        services to enable smooth crypto trading for our customers.`,
    img: exchange,
  }
]
const exchange4a = [
  {
    id: 1,
    title: 'Our Crypto Exchange Development Services',
    para: `We are a leading crypto exchange development company that offers a wide range of services 
    to help you launch and operate a successful cryptocurrency exchange platform.`,
  },

]
const exchange4 = [

  {
    id: 1,
    title: 'Hybrid Exchange Development',
    para: `We offer hybrid exchange solutions that combine the best of both centralized and 
    decentralized exchanges, offering you the optimal benefits of both models.`,
    img: exchangeSix
  },
  {
    id: 2,
    title: 'NFT Exchange Development',
    para: `We create premium NFT exchange platforms that allow users to buy and sell 
    non-fungible tokens in various domains such as games, sports, music, arts, 
    artifacts, etc.`,
    img: exchangeLogo
  },
  {
    id: 3,
    title: 'Centralized Exchange Development',
    para: `We develop centralized exchange platforms that integrate world-class features such as security,
     UI/UX, functionality, and scalability, to give you a competitive edge in the market.`,
    img: exchangeFour
  },
  {
    id: 4,
    title: 'White-Label Exchange Development',
    para: `We provide pre-tested and ready-to-launch white-label exchange solutions that can 
    be customized according to your requirements and preferences ensuring optimal performance and security features.`,
    img: exchangeThree
  },

  {
    id: 9,
    title: 'Cryptocurrency Wallet Development',
    para: `We work on developing perfect mobile and web wallets that can be used for fund management and dealing with
     major fiat and cryptocurrencies. We ensure that our wallets are secure, convenient, and easy to use.`,
    img: exchangeNine
  },
  {
    id: 6,
    title: 'Mobile Crypto Exchange Development',
    para: `We build smooth, secure, and user-friendly native mobile crypto exchange apps that enable users to send 
    and receive various cryptocurrencies with ease across multiple platforms and devices.`,
    img: exchangeOne
  },
  {
    id: 8,
    title: 'Bitcoin Exchange Development',
    para: `We deliver customized Bitcoin exchange software solutions that help you launch and run a successful Bitcoin 
    exchange platform. We use the latest technologies and best practices to ensure high performance and security.`,
    img: exchangeEight
  },
  {
    id: 7,
    title: 'P2P Exchange Development',
    para: `We craft P2P exchange platforms that provide a secure, interactive, and flexible trading environment for
     end-users. We use a smart contract-based escrow system to facilitate fast and safe transactions between the platform’s users.`,
    img: exchangeSeven
  },

  {
    id: 5,
    title: 'Decentralized Exchange Development',
    para: `We design decentralized exchange platforms that match traders directly without the involvement 
    of any third-party intermediaries. We use smart contracts and blockchain technology to ensure smart, 
    secure, and reliable trading.`,
    img: exchangeFive
  }
]
const exchange5a = [
  {
    id: 1,
    title: 'Our Crypto Exchange Development Process is Simple and Transparent',
    para: `We follow a six-step workflow that involves:`,
  },

]
const exchange5 = [
  {
    id: 1,
    title: 'Requirement Gathering',
    para: `We understand your business requirements, goals, and expectations. We analyze your target market,
     competitors, and regulatory compliance. We define the scope, timeline, and budget of the project.`,
  },
  {
    id: 2,
    title: 'Technical & Graphical Design',
    para: `We design the architecture, functionality, and user interface of your crypto exchange platform. We create 
    wireframes, mockups, and prototypes to give you a clear vision of how your platform will look and work.`,

  },
  {
    id: 3,
    title: 'Smart Contract Development ',
    para: `We develop and deploy smart contracts for your crypto exchange platform. We ensure that the smart contracts
     are secure, audited, and tested. We also integrate them with the blockchain network of your choice.`,

  },
  {
    id: 4,
    title: 'Quality Assurance ',
    para: `We perform rigorous testing and debugging of your crypto exchange platform. We ensure that
     your platform is free from any errors, bugs, or vulnerabilities. We also ensure that your platform meets the quality
      standards and expectations of your users and stakeholders.`,

  },
  {
    id: 5,
    title: 'Deployment',
    para: ` We deploy your crypto exchange platform on the server or cloud of your choice. We ensure that your
     platform is ready to go live and handle real-time transactions. We also provide you with the necessary documentation
      and training to operate your platform.`,

  },
  {
    id: 6,
    title: 'Support & Maintenance',
    para: ` We provide ongoing support and maintenance services for your crypto exchange 
    platform. We monitor your platform’s performance, security, and uptime. We also provide regular updates, 
    upgrades, and enhancements to keep your platform up to date and competitive.`,

  }
]
const exchange6a = [
  {
    id: 1,
    title: 'Why Choose Zai Systems for Crypto Exchange Development Services?',
    para: `At Zai Systems, we are committed to providing you with the best crypto exchange development services 
    in the market. We have a team of experienced and skilled developers who can create and launch your own 
    cryptocurrency exchange platform with the features and functionalities that you need. Here are some of the
     reasons why you should choose us for your crypto exchange development project:`,
  },

]
const exchange6 = [
  {
    id: 1,
    title: 'Resilient admin & user’s dashboard',
    para: `We provide you with a robust and user-friendly dashboard that allows you to manage and monitor your 
    crypto exchange platform with ease. You can access various features and functions such as user management,
     trading history, analytics, reports, settings, and more.`,
  },
  {
    id: 2,
    title: 'KYC and AML integration',
    para: `We ensure that your crypto exchange platform complies with the relevant regulations and standards of KYC 
    (Know Your Customer) and AML (Anti-Money Laundering). We integrate KYC and AML verification processes for 
    your users to ensure their identity and legitimacy.`,
  },
  {
    id: 3,
    title: 'Secure cryptocurrency transaction',
    para: `We use the latest encryption and security technologies to ensure that your crypto exchange platform’s reliability.
     We protect your platform from cyberattacks, hacks, or frauds. We also use smart contracts 
     and blockchain technology to ensure secure trading.`,

  },

  {
    id: 5,
    title: 'Premium UI/UX',
    para: `We design the user interface and user experience of your crypto exchange platform with the best practices and standards.
     We create a responsive, intuitive, and attractive design that enhances the usability and accessibility of your platform.
      We also ensure that your platform is compatible with various devices and browsers.`,

  },
  {
    id: 6,
    title: 'Payment gateway integration',
    para: `We integrate various payment gateways and methods for your crypto exchange platform that allow your users to deposit and
     withdraw funds with ease. We support multiple fiat and cryptocurrencies, such as USD, EUR, PKR, BTC, ETH, etc. We also 
     ensure that the payment transactions are fast, secure, and convenient.`,
  },
  {
    id: 8,
    title: 'Instant alert and notification',
    para: `We provide instant alerts and notifications for your crypto exchange platform that keep you updated on the latest 
    market trends, news, events, and activities. You can receive notifications via email, SMS, push, or in-app messages. You can
     also customize the notification settings according to your preferences.`,
  },
  {
    id: 7,
    title: ' Cryptocurrency transactions record',
    para: `
    We provide you with a record of all the cryptocurrency transactions that take place on your crypto exchange platform. You 
    can view and track the details of each transaction, such as the date, time, amount, sender, receiver, status, etc. You can 
    also export and download the transaction history for your reference and analysis.`,
  },
  {
    id: 9,
    title: ' User customization preference',
    para: `We provide you with the option to customize your crypto exchange platform according to your user preference. You can choose 
    the theme, color, logo, language, currency, and other features that suit your brand and vision. You can also request for 
    additional features and functionalities that you need for your platform.`,
  },
  {
    id: 4,
    title: 'Advanced Smart contract',
    para: `We develop and deploy customized smart contracts for your crypto exchange platform that automate the execution of transactions 
    and agreements. We ensure that the smart contracts are secure, audited, and tested. We also integrate them with the
    blockchain network of your choice seamlessly and securely.`,

  },
  {
    id: 10,
    title: 'Easy to Use Platform',
    para: `We provide you with an easy-to-use platform that does not require any technical knowledge or skills. You can easily register,
     login, verify, trade, and manage your crypto exchange platform with a few clicks. You can also access our 24/7 customer 
     support and help center for any queries or issues.`,

  },
  {
    id: 11,
    title: 'Two-factor authentication',
    para: `We provide you with a robust two-factor authentication feature that adds an extra layer of security to your 
    crypto exchange platform. You can enable this feature to verify your identity and access your platform with a secure
     combination of your password and a one-time code sent to your phone or email.`,

  },
  {
    id: 12,
    title: 'Support multicurrency exchange',
    para: `We provide you with a multicurrency exchange feature that allows you to trade various fiat and cryptocurrencies on
     your crypto exchange platform. You can exchange your currencies at the best market rates and with minimal fees. You can 
     also view and compare the prices and values of different currencies on your platform.`,

  }
]
const SeoDataExchange = [
  {
    id: 1,
    title: `Cryptocurrency Exchange Development Services - Zai Systems `,
    content: `Build Your Secure & Scalable Crypto Exchange by Expert Development Services for Growth. Attract traders, boost liquidity, & stand out in the market. We bring your crypto vision to life!`,
    link: `https://www.zaisystems.com/services/exchange-development`
  }
]
// hyperledger development
const hyperledger1 = [
  {
    id: 1,
    title: 'Hyperledger Blockchain Development Services',
    para: `Hyperledger is a collaborative project that aims to improve blockchain technologies for various sectors.
     We offer high-quality hyperledger development services that help businesses leverage the benefits of 
    blockchain technology.`,
    para1: `Hyperledger provides modular, secure, and scalable platforms, tools, and libraries for building enterprise-level
      blockchain applications. We can help you design, develop, and deploy customized blockchain solutions using Hyperledger frameworks`,
    img: hyperledger,
  }
]
const hyperledger2 = [
  {
    id: 1,
    title: 'Our Comprehensive Hyperledger Blockchain Development Services',
    para: `Zai Systems is a leading provider of hyperledger development services in the Pakistan and USA. We use
     blockchain technology to create and deploy hyperledger solutions that address complex challenges across various 
     industries. Our hyperledger solutions are secure, scalable, and customizable, allowing you to transform your
      business processes and models. Whether you need to develop smart contracts, integrate hyperledger frameworks, 
      or optimize your existing hyperledger infrastructure, our expert team can assist you from start to finish. `

  }

]

// const hyperledger4a = [
//   {
//     id: 1,
//     title: 'We Offer Hyperledger Development Services for the Following Platforms'
//   }

// ]
const hyperledger4 = [
  {
    id: 1,
    title: 'Hyperledger Fabric ',
    para: `Leverage the efficient, permissioned smart contracts and modular architecture of Hyperledger Fabric. 
    Zai Systems helps you establish private channels, ensuring performance, privacy, and scalability for your 
    industrial use cases.`,
    img: HyperOne
  },
  {
    id: 5,
    title: 'Hyperledger Indy ',
    para: `Build a future of interoperable, secure digital identities that transcend domains and 
    applications. Zai Systems helps you leverage Indy's standalone capabilities and cross-blockchain 
    compatibility to empower trust and transparency.`,
    img: HyperFive
  },
  {
    id: 3,
    title: 'Hyperledger Burrow ',
    para: `This single-binary blockchain distribution powered by the Byzantine Fault-Tolerant consensus algorithm
     is ideal for public permissioned use cases. We bring your agile projects to life, ensuring quick deployments 
     and efficient operation.`,
    img: HyperThree
  },
  {
    id: 4,
    title: 'Hyperledger Sawtooth ',
    para: `Separate your core system from the application domain for flexibility and customization. 
    Sawtooth's modular architecture, with versatile consensus mechanisms like PBFT and PoET, enables 
    innovative solutions across industries.`,
    img: HyperFour
  },
  {
    id: 2,
    title: 'Hyperledger Besu ',
    para: `Tap into the flexibility of public and private permissioned networks with Besu, the open-source Ethereum 
    client. We guide you through its diverse consensus mechanisms like Proof of Work and Proof of Authority, empowering 
    you to tailor network behavior.`,
    img: HyperTwo
  },

  {
    id: 6,
    title: 'Hyperledger Iroha ',
    para: `Streamline your projects with this easy-to-integrate blockchain system. Iroha's modular approach
     and YAC consensus algorithm make it ideal for projects requiring distributed ledgers, paving the way for 
     efficient collaboration and data sharing.`,
    img: HyperSix
  }

]
// const hyperledger8a = [
//   {
//     id: 1,
//     title: 'Zai Systems: Hyperledger Blockchain Design & Development',
//     para: `Zai Systems empowers businesses to unlock the transformative potential of blockchain through our
//      comprehensive Hyperledger Design & Development Process. We guide you every step of the way, from assessing
//       your unique challenges to delivering robust, scalable solutions.`
//   }

// ]
// const hyperledger8 = [
//   {
//     id: 1,
//     title: 'Hyperledger Consulting',
//     para: (
//       <>
//         <span class='text-white'>Expert analysis:</span> We assess your business needs and identify
//         opportunities where Hyperledger can provide a transformative edge.
//       </>
//     ),
//     para1: (
//       <>
//         <span class='text-white'>Framework selection:</span> We guide you in choosing the optimal Hyperledger framework
//         for your specific use case, ensuring maximum value and ROI.
//       </>
//     ),
//     para2: (
//       <>
//         <span class='text-white'>Benefit realization:</span> We clearly articulate the benefits your enterprise and
//         customers will reap from implementing a Hyperledger solution.
//       </>
//     ),
//     img: HyperSeven
//   },
//   {
//     id: 2,
//     title: 'User Experience & Technical Design',
//     para: (
//       <>
//         <span class='text-white'>Seamless user journeys:</span> Our UI/UX design experts craft intuitive and
//         user-friendly interfaces for your decentralized applications.
//       </>
//     ),
//     para1: (
//       <>
//         <span class='text-white'>Technical blueprints:</span> We define the technical architecture, including
//         system components, user stories, and database design.
//       </>
//     ),
//     para2: (
//       <>
//         <span class='text-white'>Future-proof solutions:</span> We ensure your Hyperledger applications are
//         scalable, secure, and adaptable to future growth.
//       </>
//     ),
//     img: HyperEight
//   },
//   {
//     id: 3,
//     title: 'Hyperledger App Development',
//     para: (
//       <>
//         <span class='text-white'>Proof of Concept & MVP:</span> We build and test prototypes to validate your
//         concept and ensure its feasibility before full-scale development.
//       </>
//     ),
//     para1: (
//       <>
//         <span class='text-white'>Industry-specific DApps:</span> Our developers build custom decentralized applications
//         for various industries, from supply chain and finance to healthcare and energy.
//       </>
//     ),
//     para2: (
//       <>
//         <span class='text-white'>Chaincode expertise: </span>We write secure and efficient chaincode, the backbone
//         of Hyperledger applications, for a wide range of enterprise needs.
//       </>
//     ),
//     img: HyperNine
//   },
//   {
//     id: 4,
//     title: 'Chaincode Development',
//     para: (
//       <>
//         <span class='text-white'>Robust smart contracts:</span> We craft custom chaincode, the equivalent of smart
//         contracts in Hyperledger, that are secure, reliable, and designed for your specific use cases.
//       </>
//     ),
//     para1: (
//       <>
//         <span class='text-white'>Complete security:</span> We prioritize security at every stage of development,
//         ensuring your data and transactions are protected.
//       </>
//     ),
//     para2: (
//       <>
//         <span class='text-white'>Enterprise-grade solutions:</span> We build chaincode that seamlessly integrates
//         with your existing systems and infrastructure.
//       </>
//     ),
//     img: HyperTen
//   },
//   {
//     id: 5,
//     title: 'Maintenance & Support',
//     para: (
//       <>
//         <span class='text-white'>Zero downtime:</span> Our experienced team provides ongoing maintenance
//         and support, guaranteeing the smooth operation of your Hyperledger applications.



//       </>
//     ),
//     para1: (
//       <>
//         <span class='text-white'>Performance optimization:</span> We monitor and optimize your
//         applications for peak performance and efficiency.
//       </>
//     ),
//     para2: (
//       <>
//         <span class='text-white'>Expert guidance:</span> We answer your questions
//         and provide support throughout the lifecycle of your Hyperledger solution.
//       </>
//     ),
//     img: HyperEleven
//   },

//   {
//     id: 6,
//     title: 'Migration & Upgrade',
//     para: (
//       <>
//         <span class='text-white'>Decentralized application enhancements: </span> We upgrade your existing
//         decentralized applications to leverage the latest Hyperledger features and functionalities.
//       </>
//     ),
//     para1: (
//       <>
//         <span class='text-white'>Seamless transition:</span> We migrate your applications to Hyperledger, ensuring
//         smoothness and efficiency.
//       </>
//     ),

//     para2: (
//       <>
//         <span class='text-white'> Future-proofing your investment:</span> We keep your Hyperledger solutions relevant
//         and adaptable to evolving industry needs.
//       </>
//     ),
//     img: HyperTwelve
//   }

// ]
// const hyperledger5a = [
//   {
//     id: 1,
//     title: 'Your Guide to Essential Tools and Technologies',
//     para: `Zai Systems, a leading innovator in blockchain development, empowers enterprises to harness the 
//     transformative power of Hyperledger technologies. By expertly wielding a comprehensive suite of tools,
//      we seamlessly integrate blockchain solutions into your business infrastructure, driving efficiency, 
//      transparency, and security across diverse industries.`,
//     para1: `Here's a closer look at the key Hyperledger tools we employ to deliver exceptional blockchain experiences:`

//   },

// ]
// const hyperledger5 = [
//   {
//     id: 1,
//     title: 'Hyperledger Caliper: Performance at Its Peak',
//     para: `Zai Systems leverages Caliper for performance benchmarking, ensuring ensuring optimal blockchain implementation.
//      We meticulously analyze performance indicators 
//     such as transaction throughput, latency, and resource utilization, identifying any bottlenecks and 
//     optimizing systems for peak performance.`
//   },
//   {
//     id: 2,
//     title: 'Hyperledger Explorer: Unveiling Blockchain Insights',
//     para: `Gain unparalleled visibility into your blockchain network with Hyperledger Explorer by Zai Systems. We help you explore blocks,
//      transactions, chaincodes, network information, and ledger data, empowering you to make decisions and maintain
//       a secure, transparent ecosystem.`

//   },
//   {
//     id: 3,
//     title: 'Hyperledger Composer: Accelerated Business Network Development',
//     para: `Zai Systems streamlines the creation of robust blockchain business networks and applications 
//     using Hyperledger Composer. We expedite the integration of existing business processes with blockchain 
//     solutions, fostering enhanced collaboration, efficiency, and automation across your enterprise.`

//   },
//   {
//     id: 4,
//     title: 'Hyperledger Quilt: Seamless Cross-Network Payments',
//     para: `Zai Systems leverages Quilt to facilitate seamless payments across diverse payment networks, bridging
//     the gap between fiat and cryptocurrency ecosystems. We enable secure value transfer across distributed and
//      non-distributed ledgers, unlocking new possibilities for financial innovation and global commerce.`

//   },
//   {
//     id: 5,
//     title: 'Hyperledger Cello: Blockchain as a Service (BaaS) Made Simple',
//     para: `Zai Systems simplifies blockchain deployment and management with Hyperledger Cello, empowering you
//      to create, operate, and scale BaaS solutions with ease. We provide a user-friendly operational console, 
//      enabling you to effortlessly manage blockchain resources and deliver blockchain-powered services effectively.`

//   },
//   {
//     id: 6,
//     title: 'Hyperledger Fabric: Enterprise-Grade Blockchain Platform',
//     para: ` Zai Systems harnesses the power of Fabric 
//     to build scalable, secure, and modular blockchain networks for enterprises. We leverage Fabric’s rich 
//     features such as smart contracts, channels, endorsement policies, and private data collections to customize
//      blockchain solutions for various use cases and industries.`

//   }
// ]

const hyperledger6a = [
  {
    id: 1,
    title: 'Why Zai Systems for your Hyperledger journey?',
    para: `Zai Systems isn't just a technology company; we're your trusted partner in harnessing the 
    transformative potential of Hyperledger. Our team of blockchain experts, with extensive experience 
    in diverse industries, brings your vision to life:`
  },

]
const hyperledger6 = [


  {
    id: 3,
    title: 'Empowered Manufacturing',
    para: `Optimize production processes, manage inventory seamlessly, and ensure product quality with 
    Hyperledger's secure data collaboration.`
  },
  {
    id: 4,
    title: 'Revolutionized Healthcare',
    para: `Securely store and share patient data, streamline clinical trials, and empower medical research 
    with Hyperledger's immutable ledger.`
  },
  {
    id: 5,
    title: 'Trusted Digital Identity',
    para: `Establish a secure, verifiable digital identity system for individuals and organizations,
     streamlining access and reducing fraud.`
  },
  {
    id: 1,
    title: 'Streamlined Finance',
    para: `Enhance financial processes with secure, auditable transactions. Automate payments, 
    settlements, and trade finance, reducing costs and risks.`
  },
  {
    id: 2,
    title: 'Transparent Supply Chains',
    para: `Gain end-to-end visibility with blockchain-powered supply chain. Track materials, products,
     and shipments in real-time, enhancing trust and efficiency.`
  },
  {
    id: 6,
    title: 'Innovative Education',
    para: `Transform the way students learn and teachers teach with Hyperledger’s innovative decentralized education platform.
    Secure, verifiable, and high quality learning.`
  }
]
const hyperledger13a = [
  {
    id: 1,
    title: 'The Zai Systems Difference',
  },

]
const hyperledger13 = [
  {
    id: 1,
    title: 'Industry-focused Expertise',
    para: `We understand your specific needs and challenges, crafting customized Hyperledger 
    solutions that seamlessly integrate with your existing systems.`
  },
  {
    id: 2,
    title: 'Agile Development',
    para: `We embrace an iterative approach, ensuring continuous feedback and rapid deployment,
     delivering value sooner to our clients and stakeholders.`

  },
  {
    id: 3,
    title: 'Ongoing Support',
    para: `We're invested in your success, providing comprehensive support and maintenance to ensure
     the smooth operation of your Hyperledger solutions.`

  },
  {
    id: 4,
    title: 'Security at the Core',
    para: `We prioritize data security and privacy, building robust Hyperledger applications that
     meet the highest compliance standards.`

  },
  {
    id: 5,
    title: 'Innovative Design',
    para: ` We leverage the latest technologies and best practices, designing 
    Hyperledger solutions that are scalable, reliable, and user-friendly.`

  },
  {
    id: 6,
    title: 'Collaborative Approach',
    para: `We work closely with you, understanding your vision and goals, and
     delivering Hyperledger solutions that exceed your expectations.`

  }
]
const SeoDataHyperledger = [
  {
    id: 1,
    title: `Hyperledger Development Services - Zai Systems`,
    content: `Master the Enterprise Fabric by our expert Hyperledger Development Services for any Industry. Leverage Fabric's modularity and permissioned networks. We build custom DApps from concept to deployment.`,
    link: `https://www.zaisystems.com/services/hyperledger-development`
  }
]
// artificial intelligence
const artificial1 = [
  {
    id: 1,
    title: 'Best AI Development Services company | Zai Systems',
    para: `Welcome to Zai Systems, your trusted partner in pioneering AI development services. We specialize in delivering leading-edge AI development services designed to transform your business. Our comprehensive suite of solutions includes custom AI development, machine learning, deep learning, AI chatbot development, natural language processing (NLP), data science & analytics, and more. We are dedicated to providing innovative and scalable AI solutions that drive growth and efficiency for businesses across various industries.`,
    img: artificial,
  }
]

const artificial3 = [
  {
    id: 1,
    title: `Automate your business processes and achieve growth `,
  },

]
const artificial4a = [
  {
    id: 1,
    title: 'Our comprehensive suite of AI development services:',

  },

]
const artificial4 = [
  {
    id: 1,
    title: 'Custom AI Solutions',
    para: `At Zai Systems, one of the best AI development services companies in Pakistan and worldwide, we understand that every business is unique, and so are its AI needs. Our Custom AI Solutions are designed to address your specific challenges and objectives. Whether you're looking to optimize operations, enhance customer experiences, or develop new products, our tailored solutions ensure that your AI technology aligns perfectly with your business goals.`,
    img: artificialOne
  },
  {
    id: 2,
    title: 'Generative AI Development',
    para: `Generative AI is transforming how we approach problem-solving and creativity. Our Generative AI Development services focus on creating sophisticated models that generate new content, ideas, or solutions based on existing data. From generating realistic images and videos to creating engaging text and simulations, our expertise in generative AI ensures that you stay ahead of the curve.`,
    list: [
      {
        id: 1,
        // title: 'Key Offerings in Generative AI Development:',
        para: `•	Content Creation: Automated generation of articles, reports, and marketing content.`,
      },
      {
        id: 2,
        // title: 'Key Offerings in Generative AI Development:',
        para: `•	Synthetic Media: Creation of lifelike images and videos.`,
      },
      {
        id: 3,
        // title: 'Key Offerings in Generative AI Development:',
        para: `•	Product Design: Innovative design concepts and prototypes.`,
      },
    ],
    img: artificialTwo,

  },
  {
    id: 3,
    title: 'Machine Learning',
    para: `Machine Learning (ML) is at the heart of modern AI. At Zai Systems, our Machine Learning services encompass the entire lifecycle, from data preprocessing to model deployment. We utilize advanced algorithms and methodologies to enable your systems to learn from data, improve over time, and make data-driven decisions.`,
    list: [
      {
        id: 1,
        // title: 'Key Offerings in Generative AI Development:',
        para: `•	Algorithm Development: Tailored algorithms to meet specific business requirements.`,
      },
      {
        id: 2,
        // title: 'Key Offerings in Generative AI Development:',
        para: `•	Model Training: Training models to enhance accuracy and efficiency.`,
      },
      {
        id: 3,
        // title: 'Key Offerings in Generative AI Development:',
        para: `•	Predictive Analytics: Utilizing historical data to forecast future trends.`,
      },
    ],
    img: artificialThree
  },
  {
    id: 4,
    title: 'AI Chatbot Development',
    para: `Enhance customer interactions and streamline processes with our AI Chatbot Development services. Our chatbots are designed to provide instant, accurate responses, and can be integrated into your website, mobile apps, or customer service platforms. They help reduce operational costs and improve customer satisfaction by offering 24/7 support.`,
    list: [
      {
        id: 1,
        // title: 'Key Offerings in Generative AI Development:',
        para: `•	Natural Language Understanding: Interpreting and responding to user queries in natural language.`,
      },
      {
        id: 2,
        // title: 'Key Offerings in Generative AI Development:',
        para: `•	Multi-Channel Integration: Seamless integration across various communication platforms.`,
      },
      {
        id: 3,
        // title: 'Key Offerings in Generative AI Development:',
        para: `•	Continuous Learning: Adapting and improving responses over time.`,
      },
    ],
    img: artificialFour

  },
  {
    id: 5,
    title: 'Deep Learning:',
    para: `Deep Learning is a subset of machine learning that utilizes neural networks to analyze and interpret complex data. Our Deep Learning services involve developing and deploying sophisticated models that can handle tasks such as image and speech recognition, natural language understanding, and more.`,
    list: [
      {
        id: 1,
        // title: 'Key Offerings in Generative AI Development:',
        para: `•	High Accuracy: Improved performance in tasks requiring complex data processing.`,
      },
      {
        id: 2,
        // title: 'Key Offerings in Generative AI Development:',
        para: `•	Feature Extraction: Automatic extraction of relevant features from raw data.`,
      },
      {
        id: 3,
        // title: 'Key Offerings in Generative AI Development:',
        para: `•	Scalability: Ability to handle large datasets efficiently.`,
      },
    ],
    img: artificialSix
  },
  {
    id: 6,
    title: 'Natural Language Processing (NLP)',
    para: `Natural Language Processing (NLP) is a critical component of AI that focuses on the interaction between computers and human language. Our NLP services include language translation, sentiment analysis, text summarization, and more. By leveraging NLP, we help you unlock valuable insights from textual data and enhance communication with your audience.`,
    list: [
      {
        id: 1,
        // title: 'Key Offerings in Generative AI Development:',
        para: `•	Text Analysis: Extracting meaningful information from unstructured text.`,
      },
      {
        id: 2,
        // title: 'Key Offerings in Generative AI Development:',
        para: `•	Language Translation: Accurate translation between different languages.`,
      },
      {
        id: 3,
        // title: 'Key Offerings in Generative AI Development:',
        para: `•	Sentiment Analysis: Understanding and interpreting emotions conveyed in text.`,
      },
    ],
    img: artificialFive
  },
  {
    id: 7,
    title: 'Data Science & Analytics',
    para: `In the realm of AI, data is the foundation of all intelligent systems. Our Data Science & Analytics services provide comprehensive solutions for collecting, analyzing, and interpreting data. We help you harness the power of your data to make informed decisions, uncover trends, and drive business growth.`,
    list: [
      {
        id: 1,
        para: `•	Data Acquisition: Gathering relevant data from various sources.`
      },
      {
        id: 2,
        para: `•	Data Cleaning: Ensuring data quality and consistency.`,
      },
      {
        id: 3,
        para: `•	Predictive Modeling: Creating models to forecast future outcomes.`,
      },
    ],
    img: artificialSeven
  },
  {
    id: 8,
    title: 'AI Strategy & Consulting',
    para: `Developing an effective AI strategy is crucial for leveraging AI technologies to their full potential. Our AI Strategy & Consulting services offer expert guidance on integrating AI into your business processes, identifying opportunities for AI implementation, and creating a roadmap for success. We work closely with you to ensure that your AI initiatives align with your overall business strategy.`,
    list: [
      {
        id: 1,
        para: `•	AI Roadmap Development: Strategic planning for AI adoption and integration.`
      },
      {
        id: 2,
        para: `•	Technology Assessment: Evaluating the suitability of different AI technologies for your needs.`,
      },
      {
        id: 3,
        para: `•	ROI Analysis: Assessing the potential return on investment from AI initiatives.`,
      },
    ],
    img: artificialEight
  },
  {
    id: 16,
    title: 'Model Training & Deployment',
    para: `Effective Model Training & Deployment is essential for ensuring that your AI models perform accurately and efficiently. We offer comprehensive services that include training models on your data, fine-tuning algorithms, and deploying models into production environments. Our approach ensures that your AI systems are robust, reliable, and ready for real-world applications.`,
    list: [
      {
        id: 1,
        para: `•	Custom Training: Tailoring models to your specific data and requirements.`
      },
      {
        id: 2,
        para: `•	Performance Tuning: Enhancing model performance and accuracy.`,
      },
      {
        id: 3,
        para: `•	Production Deployment: Integrating models into operational systems.`,
      },
    ],
    img: artificialNine
  }
]
const artificial5a = [
  {
    id: 1,
    title: `Achieve Business Growth with Zai Systems' AI Development Services`,
    para: `Zai Systems: Your AI Partner for Success`,
  },

]
const artificial5 = [
  {
    id: 1,
    title: 'Supercharged Efficiency',
    para: ` Free your team from tedious tasks. AI automates repetitive processes, freeing up human talent for higher-order
     thinking, creativity, and strategic decision-making. Witness a dramatic rise in productivity and efficiency, 
     allowing you to focus on what truly matters – driving business growth.`,
  },
  {
    id: 2,
    title: 'Personalized Customer Experiences',
    para: `Forget one-size-fits-all. Zai Systems' AI leverages granular data insights to tailor interactions with each customer, 
    delivering personalized recommendations and support. This fosters deeper customer relationships, boosting 
    loyalty and brand advocacy effortlessly.`,

  },
  {
    id: 3,
    title: 'Predictive Powerhouse',
    para: `Stay ahead of the curve with Zai Systems' AI-powered monitoring and analytics. Our solutions analyze  
    data in real-time, identifying potential issues, predicting trends, and recommending proactive 
    actions. Be prepared for anything and optimize your operations for future success.`,

  },
  {
    id: 4,
    title: 'Accelerated Innovation',
    para: `Zai Systems' AI streamlines development cycles, shortening timelines and accelerating project delivery. This 
    fast-paced environment allows you to quickly respond to market shifts and capitalize on new opportunities, 
    delivering a significant return on your investment.`,

  },
  {
    id: 5,
    title: 'Uncompromising Quality',
    para: `Embrace flawless execution with Zai Systems' AI solutions. Automation minimizes human error, ensuring consistent,
     high-quality results across all aspects of your business. Say goodbye to rework and hello to streamlined processes 
     that deliver exceptional value.`,
  },
  {
    id: 6,
    title: 'Competitive Advantage',
    para: `Gain an edge over your competitors with Zai Systems’ AI solutions. Our solutions enable you to leverage
     data-driven insights, automate processes, and deliver personalized experiences that set you apart.
      Lead your industry with Zai Systems’ AI.`,
  }
]
const artificial6a = [
  {
    id: 1,
    title: 'From Concept to Creation, We Guide You Every Step of the Way',
  },

]
const artificial6 = [
  {
    id: 1,
    title: 'AI Strategy & Consulting',
    para: `Our experienced consultants work closely with you to understand your unique business goals and challenges. 
    We collaboratively define your AI roadmap, identifying the most impactful applications within your specific 
    context.`,
  },
  {
    id: 2,
    title: 'Custom AI Development',
    para: `Our team of skilled AI engineers and data scientists can build bespoke AI solutions across various 
    domains, including machine learning, computer vision, natural language processing, and robotics.`,

  },
  {
    id: 3,
    title: 'Data Acquisition & Management',
    para: `
    We believe data is the fuel for AI. We help you acquire, curate, and manage your data effectively, ensuring 
    the quality and integrity needed for robust AI models. Our services are tailored to your needs.
    `,

  },
  {
    id: 4,
    title: 'Model Training & Deployment',
    para: `
    We leverage cutting-edge technologies and methodologies to train and deploy your AI models. We 
    ensure your models are optimized for performance and scalability, delivering real-time insights and 
    actionable results.
   `,

  },
  {
    id: 5,
    title: 'Integration & Maintenance',
    para: `
    Our expertise extends beyond development. We specialize in integrating your AI solutions seamlessly into your existing systems 
    and infrastructure, ensuring smooth operation and ongoing maintenance.`,
  },
  {
    id: 6,
    title: 'Evaluation & Improvement',
    para: `We deliver and improve your AI solutions. We monitor, evaluate, and enhance your AI models, meeting your needs
     and expectations. We provide feedback, suggestions, and support for your desired outcomes.`,
  }
]
const artificialestimonials = [
  {
    id: 1,
    para: `Zai Systems delivered a custom AI solution that revolutionized our customer service operations. The AI chatbot they developed has significantly improved our response times and customer satisfaction.`
  },
  {
    id: 2,
    para: `The deep learning models from Zai Systems provided us with unparalleled insights into our data. Their expertie in data science has been invaluable in driving our business forward.`
  },
  {
    id: 3,
    para: `Zai Systems’ generative AI development team helped us create innovative product designs. Their solutions have set us apart in the competitive retail market.`
  },
  {
    id: 4,
    para: `: The NLP tools developed by Zai Systems have streamlined our document processing and improved our ability to extract valuable insights from patient records.`
  },
  {
    id: 5,
    para: `Zai Systems provided exceptional AI strategy consulting, helping us implement a successful AI roadmap. Their guidance was crucial in achieving our business goals.`
  }
]
const artificialFAQs = [
  { questions: "What are AI development services?", answers: 'AI development services encompass a range of solutions designed to build, implement, and optimize artificial intelligence systems. These services include custom AI solutions, machine learning, deep learning, NLP, and more.' },
  { questions: 'How can AI benefit my business?', answers: 'AI can enhance efficiency, automate processes, provide data-driven insights, and improve customer interactions. By integrating AI into your business operations, you can gain a competitive edge and drive growth.' },
  { questions: 'What is the difference between machine learning and deep learning?', answers: 'Machine learning is a broad field that includes various algorithms and techniques for analyzing data. Deep learning is a subset of machine learning that uses neural networks with multiple layers to model complex patterns and relationships.' },
  { questions: 'What is NLP, and how is it used in AI?', answers: 'Natural Language Processing (NLP) enables machines to understand and interpret human language. It is used in AI for tasks such as text analysis, sentiment analysis, and language translation.' },
  { questions: 'How does data science contribute to AI development?', answers: 'Data science involves analyzing and interpreting complex datasets to extract valuable insights. It supports AI development by providing the data necessary for training models and making data-driven decisions.' },
  { questions: 'What is AI strategy consulting?', answers: 'AI strategy consulting involves working with experts to define AI goals, evaluate potential applications, and develop a roadmap for successful AI implementation. It ensures that AI initiatives align with business objectives.' },
  { questions: 'Why is data management important for AI?', answers: 'Data management ensures that data used for AI models is accurate, secure, and compliant. Proper data management is crucial for building reliable and effective AI systems.' },
  { questions: 'What are integration and maintenance services?', answers: 'Integration services involve incorporating AI solutions into existing systems, while maintenance services include ongoing support, updates, and troubleshooting to ensure optimal performance.' },
];

const SeoDataArtificial = [
  {
    id: 1,
    title: `AI Consulting and Development Services`,
    content: `We help businesses of all sizes harness the power of AI. Get custom solutions & expert support from local AI leaders.`,
    link: `https://www.zaisystems.com/services/artificial-intelligence`
  }
]
// robotic-process-automation
const robotic1 = [
  {
    id: 1,
    title: `Robotic Process Automation (RPA) Services`,
    para: `In a rapidly evolving digital world staying ahead requires embracing innovation at every turn. One of the most transformative technologies available to businesses today is Robotic Process Automation RPA. Zai Systems is at the forefront of providing cutting-edge RPA solutions that help companies streamline workflows reduce operational costs and drive unmatched efficiency. Our mission is clear. We aim to 
    empower organizations with the tools and expertise needed to unlock the full potential of automation.`,
    img: robotic,
  }
]

const robotic2 = [
  {
    id: 1,
    //   title: `What We Offer Comprehensive RPA Services `,
    //   para: `Over the years we have helped organizations across multiple sectors achieve operational efficiency and gain a competitive edge through strategic RPA initiatives.`,
  }
]
const robotic3 = [
  {
    id: 1,
    title: `Streamline your workflow with RPA `,
  },
]
const robotic4a = [
  {
    id: 1,
    title: `What We Offer Comprehensive RPA Services `,
    para: `Over the years we have helped organizations across multiple sectors achieve operational efficiency and gain a competitive edge through strategic RPA initiatives.`,
  },
]
const robotic4 = [
  {
    id: 1,
    title: 'Analysis and Process Discovery',
    para: `At the core of a successful RPA strategy is a thorough understanding of existing business processes. Zai Systems begins every engagement with an in-depth analysis of workflows task dependencies and possible bottlenecks. Our team uses advanced process discovery tools and interviews with key stakeholders to identify repetitive tasks prone to human error. This rigorous analysis allows us to accurately determine where automation can deliver the best results. From invoice processing in finance departments to customer onboarding tasks in call
    centers we map out opportunities for improvement and create a roadmap to guide the automation journey.`,
    img: roboticOne
  },
  {
    id: 2,
    title: 'Customization and RPA Design',
    para: `Once we have identified the right processes to automate our experts move on to designing customized RPA solutions. We recognize that no two organizations are alike. Therefore each automation workflow is meticulously crafted to align with the specific requirements of your business. We work with leading RPA platforms ensuring that the technology stack we use is scalable adaptable and secure. Our design phase includes creating user-friendly interfaces 
    rule-based configurations and thorough testing to confirm the solution works flawlessly before deployment.`,
    img: roboticFour

  },
  {
    id: 3,
    title: 'End-to-End Implementation',
    para: `Our end-to-end implementation services encompass everything needed to get your RPA bots up and running. This includes bot development system integration and user training. Our developers and integration specialists work hand in hand with your internal IT teams to ensure the new solution blends seamlessly into your existing infrastructure. Training sessions and workshops ensure that your employees are well-prepared to collaborate effectively with the digital workforce. We strive to make 
    the transition as smooth as possible minimizing disruptions and maximizing benefits from day one.`,
    img: roboticTwo

  },
  {
    id: 4,
    title: 'Governance and Compliance',
    para: `In an era of increasing regulatory scrutiny and complex data protection requirements effective governance is paramount. Zai Systems embeds robust security measures at every step of the RPA lifecycle. Our bots are designed to handle sensitive data responsibly using encryption secure access controls and audit trails. We implement compliance frameworks tailored to your industry and geographical location whether you operate under GDPR HIPAA or other regulations. 
    This ensures that your automated workflows remain both efficient and legally compliant.`,
    img: roboticThree

  },

  {
    id: 5,
    title: 'Ongoing Support and Optimization',
    para: ` Zai Systems understands that successful RPA is not a one-time deployment but a continuous journey of improvement. Our ongoing support services include monitoring bot performance troubleshooting issues and rolling out enhancements. We offer customized service level agreements that provide regular system health checks and updates. As your business grows so do your automation needs. We remain by your side suggesting upgrades and improvements that keep your RPA 
    solution at peak performance and aligned with evolving market conditions and internal requirements.`,
    img: roboticFive

  },

  {
    id: 6,
    title: 'Consulting and Advisory Services',
    para: `Beyond technical implementation we provide consulting and advisory services to help you make strategic decisions about the future of automation in your organization. Our consultants bring a wealth of industry-specific knowledge to guide you in scaling your RPA initiatives enterprise-wide. We advise on best practices for selecting the right processes to automate structuring your governance frameworks and measuring return on investment. This consultative approach ensures 
    that your organization not only adopts RPA solutions but does so in a way that aligns with long-`,
    img: roboticSix

  }

]
const robotic5a = [
  {
    id: 1,
    title: `Here's What Zai Systems' RPA Can Do For You`,
  },

]
const robotic5 = [
  {
    id: 1,
    title: 'Automate repetitive tasks',
    para: `Free your human workforce from monotonous, rule-based processes and let bots handle them with precision.`
  },
  {
    id: 2,
    title: 'Reduce errors',
    para: `Eliminate human error in clerical and administrative tasks, ensuring accuracy and consistency.`

  },
  {
    id: 3,
    title: 'Boost efficiency',
    para: `Streamline workflows, cut processing times, and get more done in less time by automating repetitive tasks.`

  },
  {
    id: 4,
    title: 'Improve collaboration',
    para: `Foster seamless communication and data exchange between humans and bots  with our intuitive platform.`

  },
  {
    id: 5,
    title: 'Cut costs',
    para: `Reduce operational expenses by automating tasks and minimizing human resource allocation for repetitive work.`

  },
  {
    id: 6,
    title: 'Increase revenue',
    para: `Improve process efficiency and customer satisfaction, leading to higher revenue and profitability.`

  }
]
const SeoDataRobotic = [
  {
    id: 1,
    title: `Robotic Process Automation Services- Zai Systems`,
    content: `Reduce costs & errors with Robotic Process Automation (RPA). We design & implement custom RPA solutions for any business.`,
    link: `https://www.zaisystems.com/services/robotic-process-automation`
  }
]
const RoboticsFAQs = [
  { questions: 'What is Robotic Process Automation?', answers: 'Robotic Process Automation refers to the use of software bots to perform repetitive rule-based tasks that were previously handled by humans. These bots mimic human actions such as clicking typing and extracting data from various systems. They can work around the clock reducing human errors and increasing overall efficiency.' },
  { questions: 'Which industries benefit the most from RPA?', answers: 'RPA has broad applicability and can benefit organizations in almost any sector that relies on repetitive tasks. The finance and banking industries often use it for invoice processing and fraud detection. Healthcare providers utilize it for patient data management while retailers use it to handle large volumes of orders and returns. Logistics companies improve shipment tracking and scheduling. Essentially if there is a repetitive workflow that follows a consistent set of rules RPA can likely help.' },
  { questions: 'Will RPA replace human jobs?', answers: 'RPA is designed to handle repetitive tasks that do not require creativity or critical thinking. By automating mundane processes human workers are free to focus on value-added tasks that require empathy innovation and complex decision-making. This shift can lead to job transformation rather than job loss. Many companies find that employees become more engaged and satisfied when they are no longer bogged down with tedious duties.' },
  { questions: 'How long does it take to implement an RPA solution?', answers: 'The timeline varies based on the complexity of the processes being automated the number of bots required and the existing IT infrastructure. Simple deployments can take a few weeks while more comprehensive enterprise-wide implementations may span several months. Zai Systems follows a structured approach involving process discovery solution design development and rollout to ensure a smooth and efficient implementation.' },
  { questions: 'Is RPA secure?', answers: 'Security is a top priority at Zai Systems. We implement rigorous security measures including data encryption and access controls. We also create detailed audit trails and activity logs. These precautions ensure that RPA deployments meet industry standards and regulatory requirements. Additionally we conduct ongoing security assessments to keep all automated processes safeguarded against emerging threats.' },
  { questions: 'What if my business processes change?', answers: 'RPA bots are designed for flexibility. If your processes undergo modifications we can adjust bot configurations to match the new requirements. Our ongoing support services include continuous monitoring and timely updates so your automation solution remains in sync with your evolving business environment.' },
  { questions: 'How do we measure the return on investment from RPA?', answers: 'We use key performance indicators such as reduction in processing time error rates cost savings and employee satisfaction scores to gauge the success of an RPA project. Before implementation we set measurable goals and benchmarks. After deployment we analyze performance data to see how well the solution is meeting or surpassing these targets. This allows you to see tangible evidence of the value provided by RPA.' },
  { questions: 'What kind of support does Zai Systems offer post-implementation?', answers: 'Zai Systems offers comprehensive maintenance and support services. We provide troubleshooting patch updates performance monitoring and regular system checks. Our goal is to ensure that your RPA environment operates at peak efficiency. We also offer training sessions and skill transfers so your internal team can manage and optimize the bots over time.' },
  { questions: 'Why should I choose Zai Systems over other RPA vendors?', answers: 'Zai Systems stands out because of our proven track record holistic approach and strong focus on compliance and security. We specialize in crafting custom solutions that align perfectly with your unique business objectives. Our dedication to long-term partnership ensures that you always have a trusted expert by your side for advice upgrades and ongoing support.' },
  { questions: 'How do I get started with Zai Systems RPA services?', answers: 'Simply reach out to our team to schedule a consultation. We will begin with a thorough analysis of your processes identify high-impact automation opportunities and design a roadmap to guide you through the journey. Our step-by-step approach and flexible engagement models let you move forward at a pace that fits your business needs.' },
];
// big data
const bigData1 = [
  {
    id: 1,
    title: 'Big Data Solution Services',
    para: `Imagine peering into the future of your business, not through a dim crystal ball, but through a high-powered
     telescope built from advanced analytics and machine learning. Zai Systems' Big Data Solutions Services are that telescope, 
     revealing hidden patterns and trends within your data to empower you with data-driven decisions that propel your 
     business forward.`,
    img: bigData,
  }
]

const bigData3 = [
  {
    id: 1,
    title: `
    Enhance the effective processes of your organization with Big Data `,
  },

]
const bigData4a = [
  {
    id: 1,
    title: 'Our Big Data Solution Services',
    para: `We are your trusted partner in navigating the big data landscape. We offer a comprehensive suite of big data
     solution services designed to empower your business with data-driven intelligence.`
  },

]
const bigData4 = [
  {
    id: 1,
    title: 'Data Visualization & Storytelling',
    para: `We transform complex data into compelling narratives, enhancing data communication and informing better decision-making 
    across all levels.`,
    img: bigdataOne

  },
  {
    id: 2,
    title: 'Data Architecture & Engineering',
    para: `Our experts design and build secure, scalable data platforms tailored to your specific needs, integrating seamlessly with your 
    existing infrastructure.`,
    img: bigdataTwo

  },
  {
    id: 3,
    title: 'Data Ingestion & Integration',
    para: `We break down data silos, extracting, transforming, and loading diverse data sources into a unified platform for holistic analysis.`,
    img: bigdataThree

  },
  {
    id: 4,
    title: 'Data Analytics & Insights',
    para: `We use advanced analytics techniques and machine learning algorithms to uncover hidden patterns, predict trends, and 
    generate actionable insights.`,
    img: bigdataFour

  },

  {
    id: 5,
    title: 'Data Strategy & Consulting',
    para: `We partner with you to define your unique data vision, roadmap, and governance model, ensuring your Big Data journey aligns
     with your business goals.`,
    img: bigdataFive
  },
  {
    id: 6,
    title: '24/7 Support & Maintenance',
    para: `Our dedicated team ensures the smooth operation of your Big Data solutions, monitoring performance and promptly
     addressing any technical issues.`,
    img: bigdataSix

  },
  {
    id: 7,
    title: 'Cloud-Based & On-Premise Solutions',
    para: `We offer flexible, customizable deployment options, catering precisely to your specific technology environment and budgetary constraints.`,
    img: bigdataSeven

  },
  {
    id: 8,
    title: 'Data Governance & Compliance',
    para: ` Our comprehensive data 
    governance services ensure that your data handling practices comply with international standards and regulations.`,
    img: bigdataEight

  },
  {
    id: 9,
    title: 'Machine Learning & AI Optimization',
    para: ` Our services optimize machine learning and AI to improve prediction,
     automate processes, and develop adaptive, intelligent systems for operational innovation.`,
    img: bigdataNine

  }

]
const bigData5a = [
  {
    id: 1,
    title: `Dive Deep into Big Data Advantages`,
    para: `At Zai Systems, we understand your challenges. We provide expert big data solutions and services to
     help you extract actionable insights, optimize operations, and make informed decisions that drive 
     real results.`
  },

]
const bigData5 = [
  {
    id: 1,
    title: 'Deeper Insights, Smarter Decisions',
    para: (
      <>
        <span class='text-white'>Predict customer behavior with clarity: </span> Understand who your customers are, what they desire, and how they respond to your offerings. Anticipate their needs and personalize your services accordingly.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Identify market shifts: </span> Stay ahead of the curve by spotting emerging trends and
        potential disruptions before they hit. Adapt your strategies with agility, seizing opportunities and leaving competitors in your wake.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>Optimize operations: </span>Root out inefficiencies that drain your resources. Uncover
        hidden connections between processes and data points to streamline workflows and maximize your output.
      </>
    )
  },
  {
    id: 2,
    title: 'Foresight Meets Agility',
    para: (
      <>
        <span class='text-white'>React proactively to market changes: </span>Anticipate demand fluctuations and adjust inventory, pricing, and marketing strategies. Ensure business continuity while maintaining a competitive edge.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Identify and capitalize on new opportunities: </span>As trends emerge, you'll be the first to grasp
        their potential. Leverage Big Data insights to develop innovative products, enter new markets, and capitalize on trends.

      </>
    ),
    para2: (
      <>
        <span class='text-white'>Become a data-driven leader: </span>Foster a culture of data-informed decision-making throughout your
        organization. Empower your teams with actionable insights and equip them to react with agility and confidence to any market shift.
      </>
    )

  },
  {
    id: 3,
    title: `Cost Efficiency at Scale`,
    para: (
      <>
        <span class='text-white'>Reduce operational expenses effectively: </span>Identify inefficiencies, eliminate wasteful spending, and streamline workflows. Allocate resources smartly to maximize output while minimizing costs.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Make smarter investments: </span>Prioritize spending based on data-driven insights. Invest wisely in high-impact initiatives
        with proven ROI and diligently avoid pouring resources into unproductive endeavors.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>Scale your business sustainably: </span>Build a data-driven foundation for growth. Optimize your infrastructure
        and processes to efficiently handle increasing data volumes and complexity without breaking the bank.
      </>
    )

  },
  {
    id: 4,
    title: `Unmatched Security and Reliability`,
    para: (
      <>
        <span class='text-white'>Protect your data with confidence: </span>Utilize advanced encryption, strict access control, and intrusion detection. Safeguard against unauthorized access and malicious threats at all times.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Enjoy seamless data access: </span>Our robust cloud infrastructure ensures your data is always accessible, whenever
        and wherever you need it. Collaborate on projects, analyze data, and make informed decisions in real-time, regardless of location.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>Minimize downtime and disruptions: </span>Our systems and disaster recovery plans ensure business
        continuity in unexpected circumstances. Your data is always accessible, and your operations remain uninterrupted.
      </>
    )

  },
  {
    id: 5,
    title: `Personalized Customer Journeys`,
    para: (
      <>
        <span class='text-white'> Target customers with precision: </span>Use demographics, interests, and behavior insights to craft tailored marketing campaigns. Deliver relevant messages that boost engagement and conversions.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Predict customer needs: </span>Go beyond simply reacting to customer queries. Anticipate
        their needs and proactively offer personalized recommendations, promotions, and support. Foster a sense of anticipation
        and delight that makes them feel valued and understood.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>Build lasting relationships: </span>Leverage data insights to build trust and loyalty with your
        customers. Personalize interactions, address their concerns proactively, and demonstrate understanding of their unique
        preferences.

      </>
    )

  },
  {
    id: 6,
    title: `Innovative Product Development `,
    para: (
      <>
        <span class='text-white'>Accelerate research and development: </span>Leverage predictive analytics to analyze patterns and forecast trends. Identify new opportunities, customer needs, and technological advancements.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Enhance Product Features: </span> By analyzing customer usage data, companies can refine product features and functionalities,
        ensuring they align with the evolving preferences and requirements of their target audience, thus enhancing user satisfaction.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>Customize User Experience: </span> Employ advanced machine learning algorithms to  seamlessly personalize the
        user experience, making your products significantly more intuitive and user-friendly.

      </>
    )

  }
]
const bigData6a = [
  {
    id: 1,
    title: `Why Choose Zai Systems?`,

  },

]
const bigData6 = [
  {
    id: 1,
    title: 'Deep Expertise',
    para: `Our team consists of seasoned big data professionals with extensive experience across various industries.`
  },
  {
    id: 2,
    title: 'Technology Agnostic',
    para: `We strategically leverage the best-fit technologies tailored for your specific needs, ensuring robust, future-proof solutions.`

  },
  {
    id: 3,
    title: `Agile & Collaborative`,
    para: `We adopt a flexible, adaptive, and collaborative approach, working intimately with you at every crucial stage.`

  },
  {
    id: 4,
    title: `Proven Results`,
    para: `We consistently deliver measurable value and tangible ROI, helping you achieve your strategic business goals.`

  },
  {
    id: 5,
    title: `Customer-Centric Solutions`,
    para: ` We prioritize your needs,
     crafting tailored solutions that align perfectly with your business objectives and customer expectations.`

  },
  {
    id: 6,
    title: `Innovative Mindset`,
    para: `Our culture of innovation empowers us with cutting-edge solutions, ensuring competitiveness
     in a rapidly evolving digital world.`

  }
]
const SeoDataBig = [
  {
    id: 1,
    title: `Big Data Solutions for Business- Zai Systems`,
    content: `Struggling with data overload? Big Data solutions to the rescue!   Transform raw data into actionable insights & gain a competitive edge.  Learn more now!`,
    link: `https://www.zaisystems.com/services/big-data`
  }
]
// natural language processing
const natural1 = [
  {
    id: 1,
    title: 'Natural Language Processing (NLP) Services ',
    para: `Zai Systems is at the forefront of Natural Language Processing (NLP) Services, shaping the future of how humans interact 
    with machines. We believe language is the most natural way to communicate, and our mission is to empower businesses with
     intelligent NLP solutions that understand, analyze, and generate human language with remarkable accuracy.`,
    img: natural,
  }
]

const natural4a = [
  {
    id: 1,
    title: 'Zai Systems and NLP',
    para: `We go beyond the basics of NLP. We leverage cutting-edge techniques and deep learning models to develop 
    intelligent solutions that empower businesses across various industries.`,
    para1: `Here's how Zai Systems' NLP services can transform your business:`
  },

]
const natural4 = [
  {
    id: 1,
    title: 'Enhance Customer Experience',
    para: `Build smarter chatbots that understand natural language, personalize marketing campaigns based on sentiment
     analysis, and analyze customer feedback to identify areas for improvement.`,
    img: naturalOne
  },

  {
    id: 2,
    title: 'Automating Content Creation',
    para: `Generate engaging product descriptions, write compelling marketing copy, or even summarize lengthy 
    documents – our NLP algorithms can do it all, saving time and resources while maintaining quality.`,
    img: naturalTwo

  },
  {
    id: 3,
    title: 'Gain Market Intelligence',
    para: `Analyze news and social media for emerging industry trends and effective competitor analysis, uncover consumer insights
     from online reviews, and identify risks and opportunities.`,
    img: naturalThree

  },
  {
    id: 4,
    title: 'Develop Innovative Products',
    para: `Power voice assistants, build AI-powered search engines, and craft engaging content that resonates with 
    your audience, all driven by advanced NLP insights.`,
    img: naturalFour

  },
  {
    id: 5,
    title: 'Boost Operational Efficiency',
    para: `Automate data entry and document processing, extract key insights from reports and emails, and streamline
     workflows with intelligent text classification.`,
    img: naturalFive

  },
  {
    id: 6,
    title: 'Breaking Language Barriers',
    para: `Need to translate content into multiple languages? Our NLP tools offer accurate 
    and nuanced translations, preserving the original meaning and tone.`,
    img: naturalSix

  },
  {
    id: 7,
    title: 'Boosting Customer Experience',
    para: `Imagine chatbots that understand natural language, answer questions accurately, and even hold engaging
     conversations. Our NLP solutions empower businesses to deliver personalized, efficient customer service,
      24/7.`,
    img: naturalSeven

  },
  {
    id: 8,
    title: 'Unlocking Insights from Data',
    para: `Textual data, from social media to customer reviews, holds a treasure trove of insights. Using NLP, 
    we can analyze vast amounts of text, extract key information, and identify trends, helping businesses 
    make data-driven decisions.`,
    img: naturalEight

  },
  {
    id: 9,
    title: 'Optimizing Content Quality ',
    para: `Whether you need to check grammar, spelling, or plagiarism, our NLP tools
     can help you improve the quality of your content. Our NLP tools can also suggest synonyms, paraphrases, and 
     alternative expressions to enhance your writing style.`,
    img: naturalNine

  }

]
// const natural5a = [
//   {
//     id: 1,
//     title: 'Advantages of NLP Services from Zai Systems',
//     para: `In today's data-driven landscape, unstructured text data holds a treasure trove of valuable insights 
//     waiting to be unlocked. That's where Zai Systems' Natural Language Processing (NLP) services come in, empowering 
//     businesses to transform words into actionable intelligence and gain a competitive edge. Here are just a few key 
//     advantages you can leverage with Zai Systems' NLP expertise:`
//   },

// ]
// const natural5 = [
//   {
//     id: 1,
//     title: 'Deep Dive into Data',
//     para: `No longer a daunting obstacle, unstructured documents, emails, and research papers become readily 
//     analyzable with NLP. Our advanced algorithms extract hidden patterns and trends, giving you a 
//     comprehensive understanding of customer sentiment, market dynamics, and hidden connections within
//      vast data sets.`
//   },
//   {
//     id: 2,
//     title: 'Empowering Your Workforce',
//     para: `Zai Systems' NLP solutions free your employees from tedious tasks like data entry and document processing. Imagine
//      your team having more time to focus on strategic initiatives and creative problem-solving. NLP automates workflows,
//       boosts efficiency, and allows your employees to shine where they truly excel. `
//   },
//   {
//     id: 3,
//     title: 'Slash Costs and Boost Efficiency',
//     para: `Streamlined processes lead to significant cost savings. NLP automates repetitive tasks, reduces human error, and 
//     improves decision-making, leading to increased productivity and cost-effectiveness across your organization. By 
//     optimizing resources, Zai Systems' NLP solutions ensure you get the most out of every penny.`

//   },
//   {
//     id: 4,
//     title: 'Become a Market Whisperer',
//     para: `Zai Systems' NLP solutions act as your window into customers’ hearts and minds. Analyze
//      product reviews, social media conversations, and survey responses to uncover their needs, desires,
//       and pain points. This understanding allows you to target marketing campaigns more effectively,
//        personalize customer experiences, and reduce churn, leaving you ahead of the curve.`

//   },
//   {
//     id: 5,
//     title: 'Customer Experience Revolution',
//     para: `Imagine chatbots that truly understand your customers' queries and respond with empathy and accuracy. NLP-powered 
//     chatbots revolutionize customer service, offering 24/7 support, resolving issues faster, and enhancing customer 
//     satisfaction. By analyzing customer feedback, we can identify improvement areas, ensuring every interaction 
//     is a positive one.`

//   },

//   {
//     id: 6,
//     title: 'Simplify Complex Processes',
//     para: ` Say goodbye to mountains of legal and contractual documents! Zai Systems' NLP powers intelligent chatbots that 
//     can scan volumes of information, extract key clauses, and answer complex questions instantly. This not only frees 
//     up your legal team for more critical tasks but also accelerates document review processes, ensuring faster turnaround
//      times and improved accuracy.`

//   }
// ]
const natural6a = [
  {
    id: 1,
    title: 'Why choose Zai Systems?',
    para: `We are more than just an NLP company. We are a team of passionate experts who understand the intricacies 
    of language and its power to transform businesses. Here what we offer:`
  },

]
const natural6 = [
  {
    id: 1,
    title: 'Deep expertise',
    para: `We have a proven record of deploying NLP solutions across diverse industries.`
  },
  {
    id: 2,
    title: 'Customizable solutions',
    para: `We tailor our NLP applications to your specific needs and challenges.`

  },
  {
    id: 3,
    title: 'Focus on results',
    para: `We prioritize ROI and ensure your NLP investment delivers tangible benefits.`

  },
  {
    id: 4,
    title: 'Commitment to innovation',
    para: `We constantly research and develop new NLP techniques to stay ahead of the curve. `
  },
  {
    id: 5,
    title: 'Scalable solutions ',
    para: `We design our NLP applications to handle large volumes of data and complex tasks.`
  },
  {
    id: 6,
    title: 'Ethical standards ',
    para: `We adhere to the highest ethical principles and respect the privacy and security of our clients.`
  }
]
const SeoDataNatural = [
  {
    id: 1,
    title: `Natural Language Processing - NLP Services - Zai Systems`,
    content: `Revolutionize your content marketing with NLP-driven topic analysis and audience insights. Optimize your content for maximum reach and engagement.`,
    link: `https://www.zaisystems.com/services/natural-language-processing`
  }
]
// staff-augmentation
const staff1 = [
  {
    id: 1,
    title: `Staff Augmentation Services`,
    para: `Does your company lack the specialized skills to tackle ambitious projects or navigate unforeseen challenges? Look no further than Zai Systems' staff augmentation services. We connect you with a network of highly qualified professionals across diverse industries – IT, 
    engineering, finance, marketing, and beyond – to bridge your talent gap and ignite your growth.`,
    img: staff,
  }
]
const staff9a = [
  {
    id: 1,
    title1: 'What Is Staff Augmentation?',
    para: `Staff augmentation is a flexible outsourcing strategy that allows businesses to hire skilled professionals on a short-term or long-term basis to meet specific project demands. This approach enables organizations to bridge skill gaps, reduce operational costs, and maintain full control over project execution while benefiting from the expertise of external resources. At Zai Systems, we simplify the staff augmentation process to help you focus on 
    achieving your business goals without the hassle of traditional hiring.`
  },

]
// const staff9 = [
//   {
//     id: 1,
//     para: (
//       <>
//         <span class='text-white'>Bridging the Gap, Short-Term or Long-Term:</span>
//       </>
//     )

//   },
//   {
//     id: 2,
//     para: (
//       <>
//         <span class='text-white'></span>
//       </>
//     )

//   },
//   {
//     id: 3,
//     para: (
//       <>
//         <span class='text-white'>Short-Term Blitz:</span> Need expertise for a quick sprint, an unexpected surge,
//         or a seasonal demand? Our rapid deployment model connects you with the perfect talent within days, ensuring
//         your projects stay on track without the burden of long-term commitments.
//       </>
//     )

//   },
//   {
//     id: 4,
//     para: (
//       <>
//         <span class='text-white'>Strategic Partnerships:</span> For ongoing initiatives and growing teams, we build
//         long-term collaborations with highly skilled professionals who seamlessly integrate into your existing workflow.
//         Imagine dedicated specialists joining your ranks, not just for weeks, but for months or even years, providing
//         consistent expertise and fueling sustainable growth.
//       </>
//     )

//   },
//   {
//     id: 5,
//     para: (
//       <>
//         <span class='text-white'></span>
//       </>
//     )

//   },
//   {
//     id: 6,
//     para: (
//       <>
//         <span class='text-white'>Beyond Skill Levels, Finding the Perfect Fit:</span>
//       </>
//     )

//   },
//   {
//     id: 7,
//     para: (
//       <>
//         <span class='text-white'></span>
//       </>
//     )

//   },
//   {
//     id: 8,
//     para: (
//       <>
//         <span class='text-white'>Commodity Powerhouse:</span> From administrative support to logistics coordination,
//         our network of reliable individuals tackles your essential day-to-day tasks, freeing up your core team to focus
//         on strategic initiatives.
//       </>
//     )

//   },
//   {
//     id: 9,
//     para: (
//       <>
//         <span class='text-white'>Skill Alchemy:</span> Need a web developer wizard or a marketing maestro? We tap into
//         our pool of talent with niche expertise to fill those crucial skill gaps, propelling your projects to new heights.
//       </>
//     )

//   },
//   {
//     id: 10,
//     para: (
//       <>
//         <span class='text-white'>Elite Expertise:</span> When only the best will do, Zai Systems delivers. We source highly
//         specialized professionals, boasting extensive experience and proven track records, to tackle your most complex
//         challenges and push the boundaries of innovation.
//       </>
//     )

//   }
// ]
const staff4a = [
  {
    id: 1,
    title: `Our Staff Augmentation Services`,
    // para: `We understand that companies have diverse needs for talent, and we cater to those needs with our
    //  comprehensive Staff Augmentation solutions. Here's a detailed breakdown of how we approach each category:`,
  }
]
const staff4 = [
  {
    id: 1,
    title: 'IT Staff Augmentation',
    para: (
      <>
        Our IT staff augmentation services provide access to highly skilled professionals who excel in fields such as software development, cybersecurity, cloud computing, data analytics, and more. Whether you need front-end developers, DevOps engineers, or IT support specialists, we’ve got you covered.
      </>
    ),
    img: staffOne
  },
  {
    id: 2,
    title: 'Technical Staffing Solutions',
    para: (
      <>
        From engineers to technicians, we provide technical talent to support industries like manufacturing, construction, energy, and telecommunications. Our experts have the technical acumen and hands-on experience to drive your projects forward.
      </>
    ),
    img: staffTwo
  },
  {
    id: 3,
    title: 'Project-Based Staffing',
    para: (
      <>
        For businesses that require temporary staff for specific projects, we offer project-based staffing solutions. These resources integrate seamlessly into your team to ensure timely project completion while maintaining high standards of quality.
      </>
    ),
    img: staffThree
  },
  {
    id: 4,
    title: 'Administrative Support',
    para: (
      <>
        Streamline your operations with our administrative support services. From virtual assistants to office administrators, our professionals bring efficiency, organization, and productivity to your business.
      </>
    ),
    img: staffFour
  },
  {
    id: 5,
    title: 'Remote Workforce Solutions',
    para: (
      <>
        Embrace the future of work with our remote workforce solutions. Our extensive network of remote professionals allows you to tap into global talent without geographic limitations, helping you reduce costs and improve operational agility.

      </>
    ),
    img: staffFive
  },
  {
    id: 6,
    title: 'Specialized Industry Experts',
    para: (
      <>
        Zai Systems connects you with industry-specific experts in healthcare, finance, education, retail, and other domains. Whether you need clinical researchers, financial analysts, or supply chain specialists, our tailored solutions ensure you get the expertise you need.
      </>
    ),
    img: staffEleven
  }

]
const staff8a = [
  {
    id: 1,
    title: `Why Choose Zai Systems for Staff Augmentation Services?`,
    para: `Partnering with Zai Systems means gaining access to a reliable and experienced team committed to your success. `
  },

]
const staff8 = [
  {
    id: 1,
    title: `Access to Top Talent`,
    para: (
      <>
        <span class='text-white'>We have a rigorous selection process to ensure you receive highly qualified and skilled professionals. Our extensive talent pool includes individuals with diverse expertise and experience across various industries.</span>

      </>
    ),
    para1: (
      <>
        <span class='text-white'>Wallet & Exchange Specialists:</span> We offer developers who craft user-friendly
        wallets and robust exchange platforms, facilitating seamless cryptocurrency transactions
        and secure asset management.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>Hyperledger & DeFi Champions: </span> Whether you're targeting enterprise solutions with Hyperledger
        or exploring Decentralized Finance (DeFi), our consultants navigate
        these complex ecosystems, bringing your blockchain vision to life.
      </>
    ),
    para3: (
      <>
        <span class='text-white'>Platform Agnostic Solutions: </span>  We cater to your platform preferences, with developers
        skilled in Ethereum, Hyperledger Fabric, Solana, and more, ensuring your application
        finds the perfect blockchain home.
      </>
    ),
    img: staffSix
  },
  {
    id: 2,
    title: `Cost-Effective Solutions`,
    para: (
      <>
        <span class='text-white'>Staff augmentation eliminates the costs associated with full-time hiring, such as benefits, training, and onboarding. By leveraging our services, you can significantly reduce operational expenses without compromising on quality.</span>
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Coding Ninjas across Diverse Tech Stacks: </span>From JavaScript and Python to Java and Ruby,
        our experts command a vast array of coding languages and frameworks, catering to your
        specific project needs.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>Modern UI/UX Champions: </span>We understand the importance of a captivating user experience.
        Our developers prioritize user-centric design principles to ensure your application is
        not only functional but also visually appealing and intuitive.
      </>
    ),
    img: staffSeven
  },
  {
    id: 3,
    title: `Scalability and Flexibility`,
    para: (
      <>
        <span class='text-white'>Whether you need to scale your workforce up or down, Zai Systems offers flexible solutions that adapt to your business requirements. This ensures you have the right resources when you need them most.</span>
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Skillset Synergy:</span> We meticulously and efficiently match your project requirements with the precise skillset
        of your dedicated developer, ensuring that we maximize efficiency and deliver optimal results.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>Seamless Integration & Communication:</span> Enjoy smooth, proactive, innovative,
        and responsive collaboration with your dedicated resource,
        ensuring clear communication, shared goals, and a collaborative problem-solving environment.
      </>
    ),
    img: staffEight
  },
  {
    id: 4,
    title: `Fast Deployment`,
    para: (
      <>
        <span class='text-white'>Time is critical in today’s competitive market. Our streamlined processes enable us to provide qualified professionals quickly, ensuring minimal disruption to your operations.</span>
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Cost-Effective Solutions: </span> Leverage the competitive rates offered by offshore developers,
        achieving significant cost savings without compromising on quality or expertise.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>Cultural and Time Zone Synergy: </span> We ensure seamless collaboration by carefully matching
        your project with geographically and culturally compatible developers, minimizing
        communication challenges and time zone conflicts.
      </>
    ),
    img: staffNine
  },
  {
    id: 5,
    title: `Customized Approach`,
    para: (
      <>
        <span class='text-white'>Every business is unique, and so are its staffing needs. We take the time to understand your goals, challenges, and requirements to provide tailor-made solutions that align with your objectives.</span>
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Infrastructure Experts: </span> Our DevOps specialists managing
        infrastructure needs, from cloud platforms to containerization technologies,
        ensuring applications run smoothly and securely.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>Continuous Improvement Champions: </span> We believe in fostering a culture of continuous
        improvement. Our DevOps engineers continuously monitor and optimize your systems,
        delivering ongoing performance enhancements.
      </>
    ),
    img: staffTen
  },
  {
    id: 5,
    title: `Transparent Communication `,
    para: (
      <>
        <span class='text-white'>We prioritize clear and open communication throughout our partnership. You’ll always know the status of your projects and resources, ensuring complete transparency and trust.</span>
      </>
    ),
    para1: (
      <>
        <span class='text-white'>AI & ML Solutions Builders: </span>Our experts design and implement AI & ML solutions transforming business processes, from chatbots and recommendations to vision and natural language processing.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>AI & ML Innovation Leaders: </span> Our talented engineers are always exploring new and emerging AI & ML technologies and trends, enabling you to leverage the latest innovations and stay ahead of the curve in industry.
      </>
    ),
    img: staffTwelve
  }
]
const staff5a = [
  {
    id: 1,
    title: 'Benefits for Your Business',
    para: `Our Staff Augmentation services are designed to empower you with the resources you need, exactly
     when you need them, allowing you to navigate changing priorities and tap into specialized skills without 
     the constraints of traditional hiring.
    `
  },

]
const staff5 = [
  {
    id: 1,
    title: 'Enhanced Agility',
    para: (
      <>
        <span class='text-white'>Adapt to Project Fluctuations: </span>
        Staff Augmentation enables you to scale your workforce up or down seamlessly,
        matching your resource needs to project demands. Hire temporary specialists. Reduce resources without incurring
        severance costs.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Embrace New Opportunities:</span> Seize unexpected opportunities without compromising your
        core team. Quickly recruit experts for niche projects, ensuring you can capitalize on
        emerging trends and market shifts without long-term commitments.
      </>
    )
  },
  {
    id: 2,
    title: `Cost Optimization`,
    para: (
      <>
        <span class='text-white'>Reduce Operational Burdens: </span>
        Slash recruitment, onboarding, and training costs. By leveraging
        pre-vetted talent pools, you eliminate the hassle and expense of traditional hiring, while
        gaining immediate access to qualified professionals.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Optimize Resource Allocation: </span>Focus your full-time employees on
        your core competencies. Augment
        your team with cost-effective specialists for specific tasks, ensuring optimal resource allocation
        and maximizing your budget efficiency.
      </>
    )
  },
  {
    id: 3,
    title: `Access to Top Talent`,
    para: (
      <>
        <span class='text-white'>Bridge the Skills Gap: </span>
        Find the precise expertise you need, even in the most
        competitive talent markets. Our extensive connects you with skilled
        professionals across diverse disciplines, giving you access to in-demand
        skillsets your team may lack.


      </>
    ),
    para1: (
      <>
        <span class='text-white'>Strengthen Your Competitive Edge:</span> Attract and retain top talent for short-term projects.
        Offer freelance or contractual opportunities to highly sought-after specialists, boosting
        your employer brand and attracting exceptional talent for specific initiatives.
      </>
    )

  },
  {
    id: 4,
    title: `Boosting Productivity`,
    para: (
      <>
        <span class='text-white'>Enhanced Focus: </span>
        Staff Augmentation specialists are laser-focused on your
        project, delivering dedicated attention and expertise. Unlike outsourced
        teams juggling multiple projects, our professionals bring singular focus,
        ensuring faster deliverables and higher quality work.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Streamlined Collaboration: </span>We ensure seamless integration of augmented talent
        into your existing team. Clear communication channels and established onboarding
        processes foster collaboration and knowledge sharing, accelerating project progress
        and maximizing team synergy.
      </>
    )

  },
  {
    id: 5,
    title: `Investing in Your Future`,
    para: (
      <>
        <span class='text-white'>Risk Mitigation: </span>
        Test the waters before diving in. Staff Augmentation is a flexible, cost-effective model that evaluates
        potential hires by working with them on short-term projects, minimizing risks and
        ensuring compatibility before committing to long-term contracts.


      </>
    ),
    para1: (
      <>
        <span class='text-white'>Continuous Learning: </span>Augmented team members can also help you identify and address potential
        blind spots. Augmented
        team members bring new ideas and expertise to the table, sparking creativity and enriching your
        internal knowledge base for future projects.
      </>
    ),
    para2: (
      <>
        <span class='text-white'></span>
      </>
    )
  },
  {
    id: 6,
    title: `Quality Assurance`,
    para: (
      <>
        <span class='text-white'>Testing and Debugging: </span>Our staff augmentation specialists are proficient in testing and debugging their
        code, ensuring that your software solutions are free of errors, bugs, and security vulnerabilities and meet your quality standards and expectations in a timely manner.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Feedback and Improvement: </span>Our staff augmentation specialists welcome feedback and suggestions,
        and constantly seek to optimize and enhance their software solutions.
        They are committed to delivering quality results that exceed your expectations and deadlines.
      </>
    )
  }
]


const SeoDataStaff = [
  {
    id: 1,
    title: `Staff Augmentation Services - Zai Systems`,
    content: `Bridge talent gaps instantly with our Staff Augmentation Services! Find skilled professionals on-demand, boost project efficiency, and scale your workforce agilely. `,
    link: `https://www.zaisystems.com/services/staff-augmentation`
  }
]
// blockchain-developers
const blockchain1 = [
  {
    id: 1,
    title: `Blockchain Development Services `,
    para: `Blockchain technology has rapidly evolved from a niche concept associated primarily with cryptocurrencies to a transformative solution poised to disrupt diverse sectors such as finance, supply chain management, healthcare, gaming, and more. At Zai Systems, we believe in the power of distributed ledgers and cryptographic protocols to drive new levels of trust, accountability, and cost-effectiveness. By focusing on innovation and robust development practices, we deliver solutions that enhance data security, streamline operations, and open up fresh opportunities for revenue growth.`,
    img: blockchainDevelopers,
  }
]
const blockchain4a = [
  {
    id: 1,
    title: `Our Services`,
    para: `As you explore the world of blockchain and all it has to offer, count on Zai Systems as your trusted technology partner. We stand ready to empower you with cost-effective blockchain development services that deliver unmatched value.`,
    // para1: `Here's why Zai Systems is your trusted partner for recruiting elite Blockchain developers:`
  },

]
const blockchain4 = [
  {
    id: 1,
    title: 'Blockchain Consulting and Strategy',
    para: `Before diving into implementation, it is crucial to set a solid foundation through comprehensive strategy and analysis. Our blockchain consulting service helps you identify how this technology can best serve your business objectives. We begin by learning about your industry, understanding your pain points, and aligning our strategies with your long-term goals. By analyzing 
    market trends, evaluating potential use cases, and conducting feasibility studies, we provide you with a clear roadmap. This enables a smooth transition into implementing blockchain solutions that can truly enhance your operations and competitive advantage.`,
    img: blockchainOne
  },
  {
    id: 2,
    title: 'Custom Blockchain Development',
    para: `Whether you need a private or public blockchain environment, our team can create customized blockchain solutions built to your exact specifications. Our expertise spans multiple blockchain platforms and protocols, including Ethereum, Hyperledger, Binance Smart Chain, and other emerging technologies. We ensure high performance and scalability by selecting the right consensus mechanisms, network architecture, and cryptographic algorithms. From designing secure peer-to-peer networks to creating customized token systems, 
    our developers craft solutions that make decentralized operations efficient and reliable.`,
    img: blockchainTwo
  },
  {
    id: 3,
    title: 'Smart Contract Development and Auditing',
    para: `Smart contracts are self-executing agreements that automatically enforce the rules embedded within their code. They are revolutionizing how businesses handle transactions, automate processes, and reduce overhead costs. At Zai Systems, our specialists create highly secure and optimized smart contracts for a variety of use cases, ranging from supply chain management to decentralized finance and beyond. We follow rigorous development best practices to minimize vulnerabilities. Additionally, our thorough auditing services detect and resolve 
    any potential weaknesses, ensuring the highest level of security before deployment.`,
    img: blockchainThree
  },
  {
    id: 4,
    title: 'Decentralized Application (dApp) Development',
    para: `As blockchain technology evolves, decentralized applications are emerging as a viable solution for businesses looking to achieve transparency, user-centric governance, and enhanced data security. Our team has extensive experience in developing dApps across various sectors, including healthcare, finance, gaming, and logistics. We blend user-friendly design with complex blockchain features, ensuring your dApp delivers both performance and an outstanding user experience. Our developers also integrate carefully tested smart contracts into your dApp, 
    enabling automated operations while maintaining full data integrity.`,
    img: blockchainFour
  },
  {
    id: 5,
    title: 'Enterprise Blockchain Solutions',
    para: `Large organizations require robust, scalable, and compliant solutions. We have the technical expertise and experience to design and implement enterprise-grade blockchain networks tailored to your business. From supply chain management systems that track products at every stage to financial platforms capable of processing high-volume transactions, our enterprise blockchain solutions are engineered for speed, security, and reliability. We integrate seamlessly with existing IT infrastructures, allowing companies 
    to easily adopt blockchain without disrupting their current operations.`,
    img: blockchainFive
  },
  {
    id: 6,
    title: 'Tokenization and Cryptocurrency Development',
    para: `Tokenizing real-world assets or creating cryptocurrencies can open new revenue streams, facilitate fractional ownership, and simplify transactions. Zai Systems assists in creating secure tokens for various use cases, including utility tokens, security tokens, and asset-backed tokens. We guide you from concept to creation, ensuring full compliance with relevant regulations and robust smart contract logic. If you are looking to establish your own cryptocurrency, our developers can handle everything from designing the underlying 
    architecture to implementing advanced cryptographic techniques.`,
    img: blockchainSix
  },
  {
    id: 7,
    title: 'Expert MatchmakingNFT and Metaverse Solutions',
    para: `Non-fungible tokens (NFTs) and virtual environments in the metaverse continue to gain traction in industries like art, gaming, and digital asset management. Our blockchain development experts can build NFT marketplaces, integrate NFT functionality into existing platforms, and develop immersive metaverse solutions. Whether you want to create a platform for digital collectibles or enable unique ownership rights, Zai Systems offers end-to-end development support that 
    helps you stand out in this rapidly growing ecosystem.`,
    img: blockchainSeven
  },
  {
    id: 8,
    title: 'DeFi Development',
    para: `Decentralized finance (DeFi) has revolutionized traditional financial services by removing intermediaries and introducing innovative products such as decentralized lending, yield farming, and automated market makers. At Zai Systems, we harness the power of smart contracts and blockchain interoperability to design secure DeFi protocols. By building trustless financial systems, we help you offer services that are open, transparent, and globally accessible. Our DeFi solutions maintain high security standards, with multiple layers of 
    testing and auditing to minimize risks and build stakeholder confidence.`,
    img: blockchainEight
  },
  {
    id: 9,
    title: 'Blockchain Integration and Migration',
    para: `Adopting blockchain technology does not have to mean starting from scratch. Our integration and migration services are aimed at businesses that wish to incorporate blockchain into their existing processes or upgrade from legacy systems. We thoroughly analyze your current workflows and identify points where blockchain technology can add the most value. By leveraging APIs and middleware, we ensure seamless data transfer, secure communication, and efficient synchronization with your existing databases. Whether you want to integrate blockchain for data authentication, user identity management, or supply chain tracking,
    we provide solutions that minimize disruption and maximize returns.`,
    img: blockchainNine
  },
  {
    id: 10,
    title: 'Distributed Minds',
    para: `They think creatively beyond the confines of traditional centralized rigid systems. Our developers embrace the
    innovative power of decentralization, designing solutions that are resilient, transparent, and empower autonomously
      users like never before. These solutions are secure, and accessible.`,
    img: blockchainTen
  },
  {
    id: 11,
    title: 'Maintenance and Support',
    para: `Blockchain networks require ongoing monitoring and maintenance to ensure peak performance. Our dedicated support team offers round-the-clock assistance to keep your decentralized ecosystem running smoothly. From regular software updates and security checks to performance optimization and troubleshooting, we handle all aspects of maintenance. This proactive approach ensures your blockchain solutions are always reliable, 
    compliant, and fully optimized for your evolving business needs.`,
    img: offshoreTwo
  }
]
const blockchain8a = [
  {
    id: 1,
    title: `Zai Systems: Masters of the Blockchain Multiverse`,
    para: `At Zai Systems, our developers aren't confined to a single Blockchain platform; they're explorers,
     navigating the vast and ever-expanding universe of this revolutionary technology. With expertise in a 
     diverse range of platforms, we can tailor the perfect solution to your unique needs and unleash the 
     boundless potential of Blockchain for your business.`
  },

]
const blockchain12a = [
  {
    id: 1,
    title: `Here's a Glimpse Into the Platforms We Conquer`

  },

]
const blockchain12 = [
  {
    id: 1,
    title: `Ethereum `,
    para: `The granddaddy of them all, Ethereum is the leader in smart contracts and 
    decentralized applications (dApps). Our developers are fluent in Solidity, the language of 
    Ethereum, and can build anything from secure financial instruments to supply 
    chain solutions.`,
    img: etheriumLogo
  },
  {
    id: 2,
    title: `Cosmos `,
    para: `This modular ecosystem of interconnected blockchains offers scalability 
    and interoperability. Our Cosmos experts can seamlessly integrate your project with a network
     of diverse blockchains, expanding your reach and unlocking new possibilities.`,
    img: cosmosLogo

  },
  {
    id: 3,
    title: `Hyperledger Fabric`,
    para: ` Designed for enterprise use, Hyperledger Fabric prioritizes privacy and permissioned
     access. Our developers leverage its modular architecture and robust security features to build
      secure and efficient solutions for businesses of all sizes.`,
    img: hyperLogo

  },
  {
    id: 4,
    title: `Stellar `,
    para: ` Focused on cross-border payments and inclusion, Stellar has fast
     transactions and low fees. Our Stellar developers can build secure and transparent payment systems
      that bridge the gap between traditional finance and the decentralized world.`,
    img: stellarLogo

  },
  {
    id: 5,
    title: `Polygon `,
    para: ` This scaling solution for Ethereum offers a thriving ecosystem of dApps and DeFi 
    protocols. Our Polygon developers can leverage its fast transactions and low fees to build scalable 
    and cost-effective solutions for your project.`,
    img: polygonLogo

  },
  {
    id: 11,
    title: `Binance Smart Chain`,
    para: ` This high-speed and cost-effective platform is a popular choice for dApps and DeFi protocols. Our 
    Binance Smart Chain developers can build scalable and affordable solutions for your project on this thriving ecosystem.`,
    img: binanceLogo

  }
  ,
  {
    id: 7,
    title: ` Cardano `,
    para: `This research-driven platform emphasizes security and sustainability. Our Cardano developers 
    can build robust and scalable solutions that are future-proof and environmentally conscious.`,
    img: cardanoLogo

  },
  {
    id: 8,
    title: `Ripple `,
    para: ` Focused on real-time payments and settlements, Ripple has fast and secure transactions.
     Our Ripple developers can build efficient and cost-effective payment solutions for businesses of all sizes.`,
    img: rippleLogo

  },
  {
    id: 9,
    title: `Solana `,
    para: ` This high-performance platform has fast transaction and low fees. Our Solana 
    developers can build scalable and cost-effective solutions for gaming, DeFi, and other high-throughput use cases.`,
    img: solanaLogo

  },
  {
    id: 10,
    title: `Thorchain `,
    para: `This decentralized liquidity network enables seamless cross-chain swaps between different blockchains.
     Our Thorchain developers can leverage its interoperability to unlock new opportunities for your project.`,
    img: thorchainLogo

  },
  {
    id: 6,
    title: ` Corda `,
    para: `Built for the financial industry, Corda excels in private and confidential transactions. Our 
    Corda developers can create secure and compliant solutions for trade finance, asset management, and
     other financial use cases.`,
    img: cordaLogo

  },
  {
    id: 12,
    title: `Avalanche `,
    para: `This scalable, decentralized platform supports multiple virtual machines and custom blockchains.
     Our Avalanche developers build fast, flexible solutions for various use cases and industries.`,
    img: avalanche

  }
]
// const blockchain10a = [
//   {
//     id: 1,
//     title: `We Craft the Perfect Team for You`,
//     para: `Finding the right Blockchain developer is more than just checking boxes; it's about building a strategic 
//     partnership that fuels your project's success. At Zai Systems, we understand this, and that's why we've 
//     meticulously crafted a hiring process that ensures you get the perfect fit, every time.`
//   },

// ]
// const blockchain10 = [
//   {
//     id: '1',
//     imageTwo: block1,
//     title: `Share your project's unique requirements. What platforms are you targeting? 
//     What challenges are you facing? What kind of magic do you need your developers to weave? The 
//     more details you provide, the better we can tailor our search to your specific needs.`,
//     head: `Step 1: Share Your Vision, We'll Find the Talent`

//   },
//   {
//     id: '2',
//     imageTwo: block2,
//     title: `Our vast network of qualified Blockchain developers is constantly being updated 
//     with the latest talent. Using our rigorous vetting process, we assess their skills, 
//     experience, and cultural fit to create a shortlist of candidates who perfectly align with your vision.`,
//     head: `Step 2: From a Pool of Experts, We Surface the Diamonds`

//   },
//   {
//     id: '3',
//     imageTwo: block3,
//     title: `In-depth interviews go beyond technical skills. We uncover the passion,
//      problem-solving skills, and collaboration that make a developer exceptional. We assess
//       their communication abilities, ensuring they can integrate into your team and 
//       become valuable assets.`,
//     head: `Step 3: Beyond Resumes, We Uncover the Spark`
//   },
//   {
//     id: '4',
//     imageTwo: block4,
//     title: `After finding your ideal match, we ensure smooth onboarding. We provide
//     training and support, helping your new developer quickly become an integral part 
//      of your team. They’re technically and culturally proficient, fostering collaboration and productivity.`,
//     head: `Step 4: Onboarding with Confidence, Integrating for Success`

//   },
//   {
//     id: '5',
//     imageTwo: block5,
//     title: `Our commitment goes beyond the initial placement. We offer ongoing block throughout
//      your project's lifecycle. We're just a phone call away to answer your questions, provide guidance,
//       and ensure your team has the resources they need to succeed.`,
//     head: `Step 5: We're Not Just Here to Hire, We're Here to Help You Thrive`
//   }
//   ,
//   {
//     id: '6',
//     imageTwo: block6,
//     title: ` We believe that the true measure of success is not just in the hiring, but in the continuous development
//      of your team. That’s why we offer personalized development plans for each developer, ensuring they stay
//       at the forefront of Blockchain technology and best practices.`,
//     head: `Step 6: Sustaining Excellence, Nurturing Growth`
//   }
// ]
const SeoDataBlockchain = [
  {
    id: 1,
    title: `Hire the Best Blockchain Developers - Zai Systems`,
    content: `Code the Future by Partnership with Award-Winning Blockchain Developers for Unrivaled Results. Experience & mastery at your fingertips. Build groundbreaking DApps with confidence.`,
    link: `https://www.zaisystems.com/services/blockchain-developers`
  }
]
// full-stack-developers
const fullstack1 = [
  {
    id: 1,
    title: `Full Stack Development Services`,
    para: `In today's fast-paced digital landscape, businesses of every size need robust and adaptable technological solutions to keep pace with customer expectations and emerging market trends. At Zai Systems, we understand the complexities and challenges faced by companies looking to develop feature-rich applications, scalable platforms, and engaging user experiences. That is why our full stack development services focus on delivering end-to-end, high-performance software solutions tailored to your unique business requirements. From conceptualization to deployment and beyond, our dedicated team of experts is ready to help you innovate, grow, and stay ahead of the competition.`,
    img: fullstack,
  }
]

const fullstack4a = [
  {
    id: 1,
    title: `Zai Systems Full Stack Development Services`,
    para: `Our comprehensive full stack development services are designed to help you build high-quality, scalable solutions across various platforms. Our wide-ranging expertise spans the entire software development lifecycle, ensuring that we can manage every aspect of your project with precision, professionalism, and efficiency. `,
  },

]
const fullstack4 = [
  {
    id: 1,
    title: 'Front-End Development',
    para: `Our front-end development approach focuses on creating visually appealing, user-friendly interfaces that promote intuitive interactions and drive user engagement. We leverage modern frameworks such as React, Angular, and Vue.js to build interactive web pages with seamless navigation and responsive design. By incorporating cutting-edge technologies and best practices, we ensure that your platform remains future-proof, offering superior performance, fast load times, and accessibility across devices and browsers. With user experience at the forefront of our process, we conduct thorough usability tests and gather feedback to refine and optimize your application's UI and UX until it meets the highest standards.`,
    img: fullstackOne
  },
  {
    id: 2,
    title: 'Back-End Development',
    para: `A robust back-end is the backbone of any digital platform. Our skilled back-end developers work with various programming languages and frameworks, including Node.js, Python, Ruby on Rails, Java, and .NET, to design and implement scalable, efficient, and secure server-side architectures. We craft clean, maintainable code that supports real-time data processing, complex logic execution, and smooth integrations with external services. We also prioritize data security, implementing encryption, role-based access control, and best-in-class authentication methods to protect sensitive information. Our back-end solutions are designed to handle high volumes of traffic, 
    ensuring that your platform can adapt to fluctuating user demands without compromising performance.`,
    img: fullstackTwo

  },

  {
    id: 3,
    title: 'Database Management',
    para: `Data is the cornerstone of modern business operations. Our database management expertise extends to both SQL and NoSQL databases, such as MySQL, PostgreSQL, MongoDB, and Cassandra. We meticulously design and optimize database schemas to ensure seamless data retrieval and storage, while focusing on performance, scalability, and data integrity. Our team also ensures that your database is configured for high availability and disaster recovery, reducing downtime and mitigating risks associated with data loss. By employing best practices in indexing, caching, and query optimization, we guarantee faster 
    response times and a better overall user experience for your customers.`,
    img: fullstackThree
  },
  {
    id: 4,
    title: 'DevOps and Cloud Integration',
    para: `The ability to rapidly deploy updates and improvements is essential in today's competitive environment. Our DevOps services streamline the software delivery process, allowing you to release new features, bug fixes, and security patches with speed and confidence. By automating build and deployment pipelines, implementing continuous integration and continuous delivery (CI/CD), and using infrastructure as code (IaC) tools, we significantly reduce time to market and the possibility of human error. Whether you prefer Amazon Web Services (AWS), Microsoft Azure, Google Cloud Platform (GCP), or private cloud solutions, our DevOps experts integrate your development workflow with the cloud seamlessly. We ensure that your environment is configured to handle peak loads and maintain 
    consistent performance, all while keeping operational costs in check.`,
    img: fullstackFour

  },
  {
    id: 5,
    title: 'Custom API Development and Integration',
    para: `In an interconnected digital world, seamless communication between different software systems is critical. Our custom API development and integration services enable your business to exchange data and functionalities securely and efficiently with third-party services, legacy systems, and partner applications. Whether you need RESTful APIs, GraphQL endpoints, or specialized integrations with payment gateways, CRM tools, or social media platforms, we have the expertise to create a cohesive ecosystem. By adopting reliable standards, secure tokens, and data exchange protocols, we ensure that your integrations remain stable, efficient, and easy to maintain as your business evolves.`,
    img: fullstackFive

  },
  {
    id: 6,
    title: 'Mobile App Development',
    para: `With the rapid growth of smartphone usage, mobile applications have become indispensable for businesses. Our full stack mobile app development services cover both iOS and Android platforms, using native languages such as Swift and Kotlin or cross-platform tools like React Native and Flutter. We design apps that align with your brand identity, offering seamless navigation, engaging interfaces, and top-notch performance. Our mobile development approach leverages device-specific capabilities, such as 
    push notifications and geolocation services, to deliver tailored user experiences. By focusing on app store guidelines, rigorous quality assurance, and efficient resource usage, we help you launch mobile applications that can quickly gain traction and generate higher user satisfaction.`,
    img: fullstackSix

  },
  {
    id: 6,
    title: 'Ecommerce Solutions',
    para: `If you are looking to establish or scale an online store, our ecommerce solutions are designed to help you succeed in a crowded market. We combine intuitive front-end interfaces with reliable back-end infrastructures to create robust ecommerce platforms capable of handling large volumes of products, users, and transactions. Whether you need integration with popular payment gateways, inventory management systems, or shipping providers, our team ensures smooth data flow and secure financial transactions. We also optimize for conversion, implementing features like intelligent product recommendations, one-click checkouts, and personalized user journeys. Our focus on performance, security, and scalability translates into a stable platform 
    that can grow with your business and meet customer expectations in an increasingly competitive marketplace.`,
    img: fullstackSix

  },
  {
    id: 6,
    title: 'Ongoing Maintenance and Support',
    para: `Technology is in constant flux, and maintaining a healthy application requires continuous monitoring, updating, and refinement. Our support services include performance tuning, bug fixing, security patching, and feature enhancements, ensuring that your platform remains reliable and 
    up to date long after its initial launch. We provide proactive maintenance to identify potential issues before they escalate, enabling your business to stay online, secure, and competitive. Our dedicated support team is always ready to address your concerns and incorporate new features or capabilities as your business evolves.`,
    img: fullstackSix

  }
]
const fullstack5a = [
  {
    id: 1,
    title: 'Zai Systems presents: The Essential Skills of a Full Stack Developer',
    para: `In the dynamic world of web development, full stack developers stand as versatile virtuosos, seamlessly
     weaving together the front-end and back-end components of websites and applications. Zai Systems recognizes
     the blend of technical skills and soft skills that empower these individuals to create exceptional digital experiences.`
  },

]
const fullstack5 = [
  {
    id: 1,
    title: `Technical Skills: Back-End Languages`,
    para: `Python's versatility and simplicity make it an invaluable asset for 
    back-end development and full-stack projects. `,
    para1: `Java's capabilities in application construction, maintenance, 
    and conceptualization are vital for full stack developers.`,
    para2: `Java is a powerful, high-performance, and scalable language that supports 
multiple frameworks and libraries`
  },
  {
    id: 2,
    title: `Web Fundamentals: HTML, CSS, and JavaScript`,
    para: `HTML and CSS form the cornerstone of web structure and visual appeal, 
    defining element placement and design aesthetics.`,
    para1: `JavaScript injects interactivity, animation, and engagement into web 
    pages, spanning both front-end and back-end functionalities.`,
    para2: `Proficiency in JavaScript frameworks like React, Angular, or Vue is 
    highly sought-after for efficient development.`
  },
  {
    id: 3,
    title: `Soft Skills: Successful Collaboration Strategies`,
    para: ` Troubleshooting software limitations and bugs demands a 
    solution-oriented approach and a keen ability to identify effective strategies. `,
    para1: ` Clear and concise communication with clients, fellow developers, 
    and management is crucial for project alignment and success. `,
    para2: `Full stack developers
     often bridge technical and non-technical roles, fostering understanding and 
     collaboration across departments.`

  }
]
// const fullstack10a = [
//   {
//     id: 1,
//     title: "Zai Systems' Full-Stack Developer Hiring Process",
//     para: `At Zai Systems, we understand the critical role skilled full-stack developers play in bringing your
//      web and app visions to life. That's why we've meticulously crafted a five-step hiring process designed to
//       identify and onboard the perfect talent for your project.`
//   },

// ]
// const fullstack10 = [
//   {
//     id: '1',
//     imageTwo: fullstackb1,
//     head: `Step 1: Requirements in Focus`,
//     title: `The journey begins with you. We take the time to understand your project's unique needs, goals,
//      and technical specifications. This in-depth conversation allows us to accurately assess the 
//      skillset and experience required for your ideal full-stack developer.`

//   },
//   {
//     id: '2',
//     imageTwo: fullstackb2,
//     head: `Step 2: Shortlisting Best Ones`,
//     title: `Leveraging our extensive network and rigorous screening methods, we find qualified candidates with the skills and fit you need.
//      We prefer those who succeeded in projects similar to yours, ensuring a strong foundation for a seamless collaboration.`

//   },
//   {
//     id: '3',
//     imageTwo: fullstackb3,
//     head: `Step 3: Interviews & Selection`,
//     title: `Our technical experts and project managers interview
//     the shortlisted candidates' skillsets, problem-solving abilities, and communication strengths.
//       We assess their coding prowess, understanding of your project's specific tech stack, and passion 
//       for exceeding expectations.`
//   },
//   {
//     id: '4',
//     imageTwo: fullstackb4,
//     head: `Step 4: Onboarding with Care`,
//     title: `Once the ideal candidate is chosen, we warmly welcome them to the Zai Systems family. A comprehensive 
//     onboarding program integrates them into your project and team. This includes 
//     technical training, project sessions, and introductions to key stakeholders, fostering collaboration and support.`

//   },
//   {
//     id: '5',
//     imageTwo: fullstackb5,
//     head: `Step 5: Ongoing Support`,
//     title: `Our commitment extends beyond simply placing the right developer on your team. We provide ongoing support 
//     throughout the project lifecycle, ensuring seamless communication, progress monitoring, and proactive problem-solving.
//      We're your dedicated partner, invested in your project's success every step of the way.`
//   },
//   {
//     id: '6',
//     imageTwo: fullstackb6,
//     head: `Step 6: Growth and Feedback`,
//     title: `After the full-stack developer has settled into their role, we establish a system of continuous professional
//      development and feedback. This involves regular performance reviews, strategic goal setting, and opportunities for skill
//       enhancement through workshops, courses, and industry-recognized certifications.`
//   }
// ]
const SeoDataFullstack = [
  {
    id: 1,
    title: `Hire the Best Full Stack Developers - Zai Systems`,
    content: `Hire our Best Full Stack Developers for Innovative Solutions. We don't just code, we strategize & partner with you. Achieve breakthrough results with our full-stack mastery.`,
    link: `https://www.zaisystems.com/services/full-stack-developers`
  }
]
// dedicated developers
const dedicated1 = [
  {
    id: 1,
    title: `Hire Our Best Dedicated Remote Developers`,
    para: `Tired of struggling to find the perfect remote developers for your projects? Zai Systems takes the pain
     out of hiring by hand-picking skilled, dedicated remote developers who perfectly match your needs. We
      specialize in connecting businesses with top-tier talent for both web and mobile projects.`,
    para1: (
      <>
        <span class='text-white'>Our mission is simple:</span> We bridge the gap between your business goals and the developers who can build scalable, robust solutions that drive results. We have a vast network of experienced professionals across various specializations, and we're committed to your project's success.
      </>
    ),
    img: dedicated,
  }
]
const dedicated4a = [
  {
    id: 1,
    title: `Why Zai Systems' Dedicated Developers Give Your Project the Edge?`,
    para: `Building your dream project deserves the best talent, and at Zai Systems, we believe dedicated 
    developers are the key to unlocking exceptional results. Here's why partnering with us can give your
     project a crucial edge:`
  },

]
const dedicated4 = [
  {
    id: 1,
    title: 'Skill Synergy',
    para: `Our network boasts developers with a kaleidoscope of technical expertise, from front-end masters
     to back-end wizards. This diversity of skillsets ensures your project benefits from multiple perspectives,
      leading to innovative solutions and robust architectures.`,
    img: dedicatedOne
  },
  {
    id: 2,
    title: 'Your Vision, Our Execution',
    para: `Partnering with Zai Systems preserves control. You remain the captain of your ship, setting
     the course and making key decisions. Our dedicated developers extend your team, seamlessly integrating 
     with your existing workflow and adhering to your vision and goals.`,
    img: dedicatedTwo

  },
  {
    id: 3,
    title: 'Seasonal Support',
    para: `Is your business subject to peak seasons? Hire dedicated developers on a temporary basis to handle the surge,
     then transition back to your in-house team when things slow down. This cost-effective approach ensures you
     have the right talent at the right time.`,
    img: dedicatedThree

  },
  {
    id: 2,
    title: 'Scale Up or Down',
    para: `Our dedicated teams quickly adapt to your evolving needs. Need extra firepower for a critical phase? We can seamlessly
     scale up your team. Project winding down? We gracefully scale down, saving you unnecessary costs and hassle.`,
    img: dedicatedFour

  },

  {
    id: 4,
    title: 'Expert Advantage',
    para: `Our developers are more than just skilled coders; they're passionate individuals with a proven track 
    record of success. Their expertise translates into high-quality solutions that give you a competitive edge 
    in the market.`,
    img: dedicatedFive

  },
  {
    id: 5,
    title: 'Lean and Mean Teams',
    para: `Forget bloated teams of inexperienced hands. We believe in focusing on quality over quantity. Our tightly-knit
     teams of experts deliver more efficient results, faster, leaving your competitors in the dust.`,
    img: dedicatedSix

  },

  {
    id: 8,
    title: 'Hit the Ground Running',
    para: `Forget months of training and onboarding. Our developers come pre-equipped with the skills and experience your 
    project needs. We seamlessly integrate them into your team, ensuring a quick start and minimal disruptions.`,
    img: dedicatedSeven

  },
  {
    id: 9,
    title: 'Quality Guaranteed',
    para: `No need to worry about cutting corners for speed. Our stringent vetting process guarantees you receive top-tier talent.
     This translates into high-quality code, efficient development cycles, and faster time to market.`,
    img: dedicatedEight

  },
  {
    id: 10,
    title: 'Built for Excellence',
    para: `Our dedicated teams pride themselves on delivering superior quality work. We have rigorous quality assurance processes 
    in place, ensuring your software is bug-free, performant, and meets the highest standards.`,
    img: dedicatedNine

  },

  {
    id: 7,
    title: 'Remote Management Made Simple',
    para: `We bridge the geographical gap with robust communication tools and project management platforms. Stay in constant touch 
    with your team, track progress, and provide feedback in real-time, ensuring your project stays on track.`,
    img: dedicatedTen

  },
  {
    id: 11,
    title: 'Peace of Mind',
    para: `With Zai Systems, you can focus on your core business while we handle the development burden. Knowing your project is
     in the hands of expert developers who are committed to quality gives you unparalleled peace of mind.`,
    img: dedicatedEleven

  },
  {
    id: 12,
    title: 'No Infrastructure Investments',
    para: `Forget the hassle of setting up workstations, purchasing software licenses, and managing IT infrastructure. Our dedicated 
    teams work remotely, alleviating you of these costly and time-consuming burdens.`,
    img: dedicatedTwelve

  },
  {
    id: 13,
    title: 'Focus on What Matters',
    para: `With Zai Systems handling the logistical hurdles, you can devote your resources to what truly matters - developing your 
    business and achieving your goals.`,
    img: dedicatedThirteen

  },
  {
    id: 14,
    title: 'Agile and Flexible Teams',
    para: `Our dedicated teams follow the agile methodology, ensuring your project
     adapts to changing requirements and feedback quickly and efficiently.`,
    img: dedicatedSix

  },
  {
    id: 15,
    title: 'Transparent and Trustworthy Partners',
    para: ` We value transparency and trust in our partnerships. We keep you informed 
    of every aspect of your project, from the scope and timeline to the budget. `,
    img: digitalFour

  }
]
const dedicated5a = [
  {
    id: 1,
    title: `Hiring Dedicated Developers with Confidence: Zai Systems' Guide`,
    para: `At Zai Systems, we understand the challenges of finding the perfect dedicated developers for your projects.
     That's why we've outlined a detailed guide to ensure you hire the right talent with confidence:`
  },

]
const dedicated5 = [
  {
    id: 1,
    title: 'Partner with the Right Outsource',
    para: (
      <>
        <span class='text-white'>Seek experience:</span> Choose a company with a proven track record of building successful
        dedicated teams. Zai Systems boasts years of experience matching businesses like yours with top-tier remote developers.
      </>
    )
    ,
    para1: (
      <>
        <span class='text-white'>Project portfolio review:</span> Scrutinize the company's past projects to assess their expertise
        in your specific domain (web, mobile, etc.) and the complexity of projects they've handled.Zai Systems' diverse portfolio showcases
        our ability to tackle a wide range of challenges.
      </>
    )
    ,
    para2: (
      <>
        <span class='text-white'>Cultural fit:</span> Look for a company with a collaborative and transparent work ethic that aligns with
        your own. At Zai Systems, we prioritize open communication and clear expectations to ensure seamless collaboration.
      </>
    )

  },
  {
    id: 2,
    title: `Assessing the Developer's Skillset`,
    para: (
      <>
        <span class='text-white'>Soft skills matter:</span>  Don't overlook soft skills like communication, problem-solving, and teamwork.
        At Zai Systems, we go beyond technical prowess and select developers who excel in collaborative environments.
      </>
    )
    ,
    para1: (
      <>
        <span class='text-white'>Technical proficiency: </span>Evaluate the developer's expertise in the required programming languages,
        frameworks, and technologies relevant to your project. Zai Systems maintains a rigorous vetting
        process to ensure our developers possess the latest skills and knowledge.
      </>
    )
    ,
    para2: (
      <>
        <span class='text-white'>Conduct in-depth interviews:</span> Tailor your interview questions to assess the candidates’
        understanding, experience, and approach Zai Systems
        facilitates personalized interviews to help you find the perfect match.
      </>
    )

  },
  {
    id: 3,
    title: 'Communication is Key',
    para: (
      <>
        <span class='text-white'>Invest in reliable tools:</span> Ensure seamless communication across continents
        with video conferencing tools, and instant messaging software. Zai
        Systems provides your team with tools and infrastructure for collaboration.



      </>
    )
    ,
    para1: (
      <>
        <span class='text-white'>Bridge the language gap:</span> If language barriers exist, consider hiring interpreters or translators to avoid
        misunderstandings and ensure effective communication. Zai Systems helps you overcome language barriers for productivity and collaboration.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>Foster Cultural Understanding:</span> In a global team, cultural differences can impact communication as
        much as language. Provide cultural competency training to help team members understand and respect each other’s communication styles.
      </>
    )

  },
  {
    id: 4,
    title: 'Project Readiness',
    para: (
      <>
        <span class='text-white'>Prepare essential documents:</span> Gather all project requirements, specifications,
        and deadlines to share with your dedicated team. Zai Systems guides you through this process to ensure a smooth
        project kickoff.
      </>
    )
    ,
    para1: (
      <>
        <span class='text-white'>Streamline onboarding: </span>Establish clear communication channels, introduce the team
        to project goals, workflows, and expectations.  Zai Systems ensures a structured onboarding process for developers
        to hit the ground running.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>Implement Robust Project Management Practices:</span> Utilize project management methodologies
        to track progress, manage tasks, and maintain timelines.
      </>
    )
  },
  {
    id: 5,
    title: 'Ensuring Long-Term Success',
    para: (
      <>
        <span class='text-white'>Regular Check-ins:</span> Schedule periodic meetings with your dedicated developers to discuss project
        progress, address any concerns, and make necessary adjustments. This helps maintain project momentum and ensures alignment.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Post-Project Support:</span> Plan for comprehensive post-project support and maintenance. This could involve retaining
        the developer for ongoing work or ensuring a smooth transition to your dedicated in-house team.
      </>
    )
    ,
    para2: (
      <>
        <span class='text-white'>Feedback Loop:</span> Create a system for continuous feedback between your team and the developers.
        This encourages open communication and allows for real-time improvements.
      </>
    )
  },
  {
    id: 6,
    title: 'Project Management and Adaptation',
    para: (
      <>
        <span class='text-white'>Adaptive Project Management:</span> Employ project management methodologies that allow for flexibility
        and adaptation to changes. Agile, Scrum, or Kanban can be effective in managing dedicated developers in dynamic environment.
      </>
    )
    ,
    para1: (
      <>
        <span class='text-white'>Risk Management:</span> Develop a proactive approach to identify potential risks early in the project.
        This includes setting up a risk management plan to mitigate issues that may arise during development.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>Sustainable Practices:</span> Promote sustainable work practices to prevent burnout and maintain
        high productivity. This includes reasonable work hours, regular breaks, and a healthy work-life balance.
      </>
    )
  }
]
const dedicated6a = [
  {
    id: 1,
    title: 'Our Dedicated Developer Hiring Process',
    para: `Finding the perfect developer for your project can feel like searching for a rare diamond in a vast field. 
    But at Zai Systems, we take the guesswork out of the equation with a meticulous and transparent hiring process 
    designed to connect you with the ideal dedicated developer, every time.`
  },

]
const dedicated6 = [
  {
    id: 1,
    title: `Step 1: Let's Get Acquainted`,
    para: (
      <>
        <span class='text-white'>Reach out to us:</span> Whether it's through our website contact form, a quick phone call, or an
        email, initiating contact is the first step to bringing your project to life. Let us know how we can help you achieve your goals.



      </>
    )
    ,
    para1: (
      <>
        <span class='text-white'>Project Briefing: </span>Tell us all about your vision! Share your project details, requirements,
        and desired timelines. We'll listen intently to understand your unique needs and challenges. Let’s collaborate!
      </>
    )

  },
  {
    id: 2,
    title: 'Step 2: Deep Dive into Your Requirements',
    para: (
      <>
        <span class='text-white'>Technical Specifications:</span> We analyze your project’s specifics, languages, frameworks,
        functions, and integrations. This ensures we find developers with the exact skills you need.
      </>
    )
    ,
    para1: (
      <>
        <span class='text-white'>Cultural Fit:</span> Beyond technical prowess, we value company culture for successful partnerships. We’ll find
        developers who fit your team by discussing work environment, communication, and workflows.
      </>
    )

  },
  {
    id: 3,
    title: 'Step 3: Unearthing Your Perfect Match',
    para: (
      <>
        <span class='text-white'>Global Talent Pool:</span> We use our global network of skilled developers.
        This wide reach ensures we find the ideal candidate, anywhere, who perfectly aligns with your project's needs.
      </>
    )
    ,
    para1: (
      <>
        <span class='text-white'>Rigorous Vetting:</span> Our developers undergo a comprehensive vetting process, including technical skill
        assessments, code reviews, and interviews. This ensures you receive the best, most qualified candidates.
      </>
    )

  },
  {
    id: 4,
    title: 'Step 4: Finding the Right Fit Through Interviews',
    para: (
      <>
        <span class='text-white'>Personalized Interviews:</span> We tailor interviews to your specific project requirements,
        allowing you to directly assess each candidate's skills, experience, and problem-solving approach.
      </>
    )
    ,
    para1: (
      <>
        <span class='text-white'>Collaborative Evaluation:</span> We involve you throughout the interview process, ensuring
        you have a say in selecting the developer who best aligns with your vision and team.
      </>
    )

  },
  {
    id: 5,
    title: 'Step 5: A Smooth Onboarding Begins Your Success Story',
    para: (
      <>
        <span class='text-white'>Seamless Integration:</span> We facilitate smooth onboarding, introducing your developer
        to your team, tools, and workflows. This ensures a quick start and minimizes disruptions.
      </>
    )
    ,
    para1: (
      <>
        <span class='text-white'>Ongoing Support:</span> We remain your dedicated partner, providing ongoing support
        and communication channels to ensure seamless teamwork.
      </>
    )
  },
  {
    id: 6,
    title: 'Step 6: Continuous Growth and Development',
    para: (
      <>
        <span class='text-white'>Professional Development:</span>
        Support developers’ growth by providing learning opportunities like workshops and conferences to stay up-to-date with the latest technologies and industry trends.
      </>
    )
    ,
    para1: (
      <>
        <span class='text-white'>Team Building:</span>  Foster community and teamwork with regular team-building
        activities from informal gatherings to structured retreats, promoting a collaborative and supportive work environment.
      </>
    )
  }
]

const SeoDataDedicated = [
  {
    id: 1,
    title: `Hire the Dedicated Developers - Zai Systems`,
    content: `Find Your Perfect Fit and Hire Top Dedicated Developers Now! ✨`,
    link: `https://www.zaisystems.com/services/dedicated-developers`
  }
]
// offshore-developers
const offshore1 = [
  {
    id: 1,
    title: `Offshore Development Services`,
    para: `We are a global leader in offshore development services dedicated to helping businesses thrive in today's fast-paced digital environment. Our expertise spans multiple industries and project types giving you the peace of mind that comes from working with a trusted team of professionals. Whether you need custom software solutions cloud-based applications mobile app development or a complete digital transformation strategy our offshore development services can help you achieve your business goals.`,
    // para1: `We recognize the immense value of skilled developers, and we're proud to offer access to a 
    // global pool of exceptional offshore talent. Our team of experts handpicks top-tier developers who 
    // possess the technical prowess, industry knowledge, and unwavering dedication to propel your project 
    // to success. `,
    img: offshore,
  }
]
const offshore2a = [
  {
    id: 1,
    title: `Our Offshore Development Services`,
    para: `Zai Systems takes pride in offering a comprehensive range of offshore development services that align with your unique business needs. We understand that every project is different which is why we tailor our solutions to match your specific objectives. `
  },
]

const offshore4 = [
  {
    id: 1,
    title: 'Custom Software Development',
    para: `Custom software development is one of our core competencies. Our offshore teams excel at designing and building solutions that reflect your business logic and objectives. We use agile methodologies to ensure rapid development cycles frequent feedback loops and continuous improvement. Whether you require a complex enterprise application or a simple yet powerful workflow tool our developers are adept at creating customized solutions that boost efficiency and productivity.`,
    para1: (
      <>
        <span class='text-white'>•	Scalable architectures that grow with your business. <br />
          •	Integration with existing systems ensuring seamless data flow. <br />
          •	Advanced security measures such as encryption and secure code practices.<br />
          •	Intuitive user interfaces backed by thorough UX research. <br />
          •	Continuous maintenance to keep your software up-to-date.
        </span>
      </>
    ),
    img: webAppOne
  },
  {
    id: 2,
    title: 'Web Application Development',
    para: `The digital world relies heavily on web-based technologies to engage users and streamline processes. Our web development teams build robust scalable and high-performing applications that enhance your business visibility and credibility. By leveraging the latest frameworks and programming languages we ensure that your application is responsive secure and capable of handling high traffic loads. This service covers everything from front-end design to back-end integration and performance tuning.`,
    para1: (
      <>
        <span class='text-white'>•	Full-stack development using frameworks like React Angular Node.js Django and Laravel. <br />
          •	Responsive design that delivers optimal user experiences across devices. <br />
          •	SEO-friendly architecture to enhance your online presence. <br />
          •	Server setup and deployment on cloud or on-premise servers.<br />
          •	Thorough testing and quality assurance to eliminate bugs and bottlenecks.
        </span>
      </>
    ),
    img: webAppTwo
  },
  {
    id: 3,
    title: 'Mobile Application Development',
    para: `In a mobile-first world a feature-rich and user-friendly mobile app can be a game-changer for your business. Zai Systems specializes in creating native and cross-platform mobile applications tailored to your requirements. Our teams stay up-to-date with the latest iOS and Android development practices ensuring that your app delivers exceptional performance security and user engagement.`,
    para1: (
      <>
        <span class='text-white'>
          <>
            <span class='text-white'>•	Native iOS and Android app development with Swift Kotlin and Java. <br />
              •	Cross-platform app development with frameworks like React Native and Flutter. <br />
              •	In-app analytics to track user behavior and optimize performance.<br />
              •	Integration with APIs and third-party services for expanded functionalities. <br />
              •	Regular updates and feature enhancements based on user feedback.
            </span>
          </>
          ),
        </span>
      </>
    ),
    img: webAppThree
  },
  {
    id: 4,
    title: 'Cloud Solutions and DevOps',
    para: `Modern businesses are increasingly relying on cloud infrastructures to achieve scalability and flexibility. Our offshore development services include cloud solutions that streamline your operations reduce costs and enhance collaboration. We specialize in DevOps practices ensuring automated testing continuous integration and continuous delivery for faster releases and stable environments.`,
    para1: (
      <>
        <span class='text-white'>•	Cloud strategy consulting to select the right platform (AWS Azure Google Cloud). <br />
          •	Infrastructure setup optimization and monitoring. <br />
          •	Implementation of CI CD pipelines for faster and more reliable deployments. <br />
          •	Containerization with Docker and Kubernetes for streamlined development. <br />
          •	Secure data management and disaster recovery planning.
        </span>
      </>
    ),
    img: webAppFour
  },
  {
    id: 5,
    title: 'Quality Assurance and Testing',
    para: `Quality assurance is crucial for any software project. At Zai Systems we follow rigorous testing procedures to identify and fix issues before deployment. Our QA specialists use industry-leading tools and methodologies to ensure your software or mobile app meets the highest standards of quality. We offer functional testing performance testing load testing and regression testing all aimed at delivering a flawless product to end-users.`,
    para1: (
      <>
        <span class='text-white'>•	Manual and automated testing to ensure comprehensive test coverage. <br />
          •	Performance testing to handle peak load scenarios. <br />
          •	Security and vulnerability assessments to protect against data breaches.<br />
          •	Cross-browser and cross-platform testing for consistent user experience. <br />
          •	Detailed test reports and analytics for continuous improvement.
        </span>
      </>
    ),
    img: webAppFive
  },
  {
    id: 6,
    title: 'Legacy System Modernization',
    para: `Outdated systems often hold businesses back from achieving optimal performance. Zai Systems offers legacy system modernization services to upgrade your existing applications without disrupting core operations. We help you transition to modern frameworks architectures and cloud platforms ensuring that your systems remain secure efficient and scalable.`,
    para1: (
      <>
        <span class='text-white'>•	Technical audit to identify system bottlenecks and modernization opportunities. <br />
          •	Phased migration to minimize disruptions.<br />
          •	Refactoring and re-architecting for better performance and maintainability. <br />
          •	Integration with new tools and services. <br />
          •	On-going support and training for seamless adoption.
        </span>
      </>
    ),
    img: webAppFive
  },
  {
    id: 7,
    title: 'IT Staff Augmentation',
    para: `Sometimes you may not need a full project team but just a few specialized professionals to augment your in-house staff. With our IT staff augmentation service you can handpick the talent you need from our global pool of skilled developers QA engineers project managers and more. This approach is flexible cost-effective and ideal for organizations that want to quickly scale up or fill specific skill gaps.`,
    para1: (
      <>
        <span class='text-white'>•	Diverse talent pool including front-end back-end and full-stack developers. <br />
          •	Flexible engagement models to suit short-term or long-term needs. <br />
          •	Rapid onboarding with minimal downtime. <br />
          •	Dedicated resources who work exclusively on your projects.<br />
          •	Transparent communication ensuring alignment with your internal teams.
        </span>
      </>
    ),
    img: webAppFive
  },

]

const offshore8a = [

  {
    id: 1,
    title: `Our Exceptional Offshore Development Process`,
    para: `We go beyond just writing code. We offer a comprehensive suite of services to ensure your project's success.`,
  },
]
const offshore8 = [
  {
    id: 1,
    title: `Expert Project Management`,
    para: `Our project managers handle the development process, ensuring smooth execution
     and timely delivery. You can focus on your core business.`,
    img: offshoreOne
  },

  {
    id: 2,
    title: `Rigorous Quality Assurance`,
    para: `Our meticulous testing procedures  leave no bug unturned. You'll receive a flawless, user-friendly software that
     exceeds expectations and delights your users.`,
    img: offshoreTwo
  },
  {
    id: 3,
    title: `24/7 Support, Always There for You`,
    para: `We're your always-on support team,  ready to address your concerns and provide guidance whenever you need it. Your
     peace of mind is our priority.`,
    img: offshoreThree
  },
  {
    id: 4,
    title: `Agile Development for Continuous Improvement`,
    para: `We embrace agile methodologies for rapid iteration and continuous improvement. You'll be involved every step 
    of the way, providing feedback and shaping the project to your exact vision.`,
    img: offshoreFour
  },
  {
    id: 5,
    title: `Cutting-Edge Technology, Future-Ready Solutions`,
    para: ` We use the latest tools and frameworks to deliver innovative and scalable solutions
     that meet your business needs and goals. You’ll get a future-ready software that can adapt to changing markets and demands.`,
    img: productOne
  },
  {
    id: 6,
    title: `Transparent Communication, Seamless Collaboration`,
    para: ` We keep you updated on the progress and status of your project through regular reports 
    and meetings. You’ll have full visibility and control over your project, and you can easily communicate with your dedicated team.`,
    img: digitalFour
  }
]
const offshore5a = [
  {
    id: 1,
    title: `Why Choose Zai Systems for Offshore Development`,
    para: `Partnering with the right offshore development provider can be a transformative experience for your business. At Zai Systems we are committed to offering unmatched service quality robust infrastructure and a culture of innovation. `

  },

]
const offshore5 = [
  {
    id: 1,
    title: `Global Talent and Expertise`,
    para: (
      `One of our biggest strengths is our ability to tap into a global network of talent. We carefully select developers QA engineers designers and project managers who excel in their respective domains. `
    ),

  },
  {
    id: 2,
    title: `Cost Efficiency without Compromising Quality`,
    para: (
      `Offshore development is often synonymous with cost savings but at Zai Systems we make sure you get more than just competitive rates. Our approach ensures that quality is never compromised. `
    ),

  },
  {
    id: 3,
    title: `Robust Infrastructure and Security`,
    para: (
      `We understand that security and reliable infrastructure are pivotal when outsourcing offshore development services. Our data centers are equipped with advanced firewalls encryption protocols and multi-layered security measures. `
    ),


  },
  {
    id: 4,
    title: `Agile Methodologies for Faster Delivery`,
    para: (
      `At Zai Systems we follow agile development methodologies to accelerate project timelines and reduce the risk of errors. Frequent sprints daily standups and iterative feedback loops keep everyone aligned and informed. `
    )

  },
  {
    id: 5,
    title: `Scalable Engagement Models`,
    para: (
      `Every business has different requirements which is why we offer customizable engagement models. Whether you need a dedicated team for an extended period or prefer a fixed-price project for a one-time initiative we have you covered. `
    ),


  },
  {
    id: 6,
    title: `Quality Assurance and Testing Excellence`,
    para: (
      `Quality is never an afterthought at Zai Systems. Our QA teams are integrated into every stage of the development process conducting tests and running automated scripts to catch bugs early. We believe that a bug-free product is essential for user satisfaction.`
    ),


  },
  {
    id: 7,
    title: `Post-launch Support and Maintenance`,
    para: (
      `Launching a product is just the beginning of its lifecycle. Our offshore development services include post-launch support and maintenance to ensure your product remains up-to-date and fully functional. `
    ),


  },
  {
    id: 6,
    title: `Proven Track Record`,
    para: (
      `With years of experience under our belt we have successfully delivered projects across industries including healthcare finance e-commerce manufacturing and education. Our client testimonials speak volumes about our ability to handle complex challenges and deliver exceptional results. `
    ),


  },
]

const offshoreFAQS = [
  { questions: 'What is offshore development?', answers: 'Offshore development involves hiring a team of developers or individual developers from another country to work on your project remotely. It is a cost-effective way to access specialized skills and scale your team quickly.' },
  { questions: '. How do I ensure quality while working with offshore developers?', answers: 'At Zai Systems, we implement strict quality assurance measures and provide continuous support to ensure the highest standards are maintained throughout the development process.' },
  { questions: 'What are the cost savings of offshore development? ', answers: 'By hiring offshore developers, you can save up to 60% on development costs compared to hiring in-house developers. The savings come from lower labor costs, reduced infrastructure expenses, and flexible pricing models' },
  { questions: '. How do I communicate with offshore developers?', answers: 'We use modern communication tools like Slack, Microsoft Teams, and Zoom to ensure seamless communication. Our developers also align with your preferred time zone for real-time updates.' },
  { questions: '. What if I need to scale my team?', answers: "Zai Systems provides flexible hiring options, allowing you to scale your team up or down based on your projects requirements." },

];
const SeoDataOffshore = [
  {
    id: 1,
    title: `Hire the Offshore Developers - Zai Systems`,
    content: `Hire Expert Offshore Developers for Any Project. Access a global pool of skilled programmers, from web & mobile to AI & blockchain. Start building now!`,
    link: `https://www.zaisystems.com/services/offshore-developers`
  }
]
// devops engineers
const devops1 = [
  {
    id: 1,
    title: `Best DevOps Services`,
    para: `Welcome to Zai Systems. We understand that in today's rapidly evolving digital world, businesses need to innovate and respond to market changes swiftly. This is why DevOps has become the cornerstone of modern software development and IT operations. By integrating development and operations teams, organizations can deliver products and services faster, more reliably, and with higher quality. Our mission at Zai Systems is to provide the best DevOps services that will help you stay ahead of the competition, scale effortlessly, and optimize your resources.`,
    img: devops,
  }
]

const devops2a = [
  {
    id: 1,
    title: `OUR DEVOPS SERVICES`,
    para: `At Zai Systems, we provide a full suite of DevOps services designed to transform your organization into a well-oiled machine. Whether you are just getting started on your DevOps journey or looking to optimize an existing pipeline, our team has the knowledge and tools you need. `
  },
]

const devops4a = [
  {
    id: 1,
    title: 'DevOps Assessment and Consulting',
    para: `Zai Systems begins by performing a thorough assessment of your current systems, workflows, and organizational culture. We identify bottlenecks, gaps, and opportunities for automation and improvement. Our consulting service includes detailed roadmaps that guide your transition to DevOps best practices and set the foundation for future success. Through careful analysis, we develop customized strategies that align with your goals and deliver quick wins without compromising long-term stability.`,
    img: webAppOne
  },
  {
    id: 2,
    title: 'Continuous Integration and Continuous Delivery (CI CD)',
    para: `Continuous Integration and Continuous Delivery lie at the heart of successful DevOps adoption. Our experts will set up CI CD pipelines that automate the build, test, and deployment processes. This approach reduces the time to market, lowers the risk of errors, and ensures that every code commit is automatically validated. We integrate leading technologies such as Jenkins, GitLab CI, CircleCI, and Azure Pipelines to maintain consistent quality and efficiency. The result is a more predictable and streamlined release cycle that keeps your business nimble and responsive.`,
    img: webAppTwo
  },
  {
    id: 3,
    title: 'Infrastructure as Code (IaC)',
    para: `Infrastructure as Code is one of the pivotal elements of DevOps success. By managing your IT infrastructure in a version-controlled environment, you can reduce drift, enforce standards, and rapidly deploy or roll back configurations. Our team at Zai Systems leverages tools like Terraform, AWS CloudFormation, and Ansible to create reproducible environments. This process makes onboarding new developers and scaling environments faster and more cost-effective, while also promoting consistency and security across the stack.`,
    img: webAppThree
  },
  {
    id: 4,
    title: 'Containerization and Orchestration',
    para: `Containerization and orchestration technologies have revolutionized the way modern applications are developed and deployed. We specialize in Docker, Kubernetes, and other container orchestration frameworks to ensure that your applications run consistently across different environments. By packaging your applications and dependencies together, you eliminate compatibility issues and achieve greater resource utilization. Zai Systems implements container strategies that help you reduce infrastructure overhead, enhance portability, and improve scalability. This approach results in streamlined deployments and better use of your computing resources.`,
    img: webAppFour
  },
  {
    id: 5,
    title: 'Cloud Deployment and Management',
    para: `Whether you are deploying to AWS, Azure, Google Cloud, or a hybrid environment, Zai Systems has you covered. We provide expertise in migrating applications to the cloud, setting up cloud-native architectures, and optimizing workloads for cost, performance, and security. Our engineers design highly available and fault-tolerant solutions that leverage the best features of each cloud provider. With our guidance, you can quickly spin up or down resources on demand, experiment with new features, and only pay for what you use.`,
    img: webAppFive
  },
  {
    id: 6,
    title: 'Monitoring and Logging',
    para: `Effective monitoring and logging are essential for proactive maintenance and rapid troubleshooting. We integrate advanced monitoring tools like Prometheus, Grafana, Datadog, and ELK stacks to give you real-time visibility into your systems. This helps you detect issues, track performance, and gather insights that drive informed decisions. By consolidating logs from various sources, you reduce the time spent sifting through data, increase your mean time to resolution, and empower your teams to focus on delivering new features and enhancements.`,
    img: webAppFive
  },
  {
    id: 7,
    title: 'Security and Compliance',
    para: `Zai Systems places a high priority on securing your applications and infrastructure. Our DevSecOps approach integrates security measures from the start, ensuring that vulnerabilities are identified and addressed early in the development lifecycle. We implement automated security scans, encryption, access management, and compliance checks as part of your CI CD workflows. This shifts security left and prevents costly breaches or violations of industry regulations. Our specialists tailor security solutions to the specific requirements of your industry, whether you are in finance, healthcare, e-commerce, or any other domain.`,
    img: webAppFive
  },
  {
    id: 8,
    title: 'Scalability and High Availability',
    para: `One of the greatest advantages of adopting a DevOps mindset is the ability to scale quickly without sacrificing stability or performance. We design architectures that can handle fluctuating loads, ensure minimal downtime, and allow you to expand your resources on the fly. Our team implements load balancing, auto-scaling, and disaster recovery strategies to ensure uninterrupted service. With Zai Systems by your side, you will be ready to handle increased demand during peak seasons, promotions, or unexpected user growth while maintaining top-notch performance`,
    img: webAppFive
  },

  {
    id: 10,
    title: 'DevOps Toolchain Integration',
    para: `The DevOps toolchain is vast and continually evolving. Zai Systems helps you integrate the right tools to ensure seamless collaboration, automation, and visibility. From source code management with Git to automated testing frameworks, artifact repositories, and advanced deployment tools, we harmonize every aspect of your development and operations ecosystem. Our experts are well-versed in the latest solutions and can recommend the most suitable ones for your specific requirements. This integration boosts productivity, helps you maintain high code quality, and accelerates your time to market`,
    img: webAppFive
  },
]


const devops5a = [
  {
    id: 1,
    title: 'WHY CHOOSE ZAI SYSTEMS',
    para: `With a proven track record and a global client base, we offer the highest standards of quality, security, and service.`,
  },

]
const devops5 = [
  {
    id: 1,
    title: 'Unmatched Technical Expertise',
    para: `Our team of DevOps engineers, software developers, and system architects has a wealth of experience in diverse industries. We stay current with the latest trends and best practices to ensure that your DevOps journey is well-informed and future-proof. `,


  },
  {
    id: 2,
    title: 'Custom Tailored Solutions',
    para: `We believe that every organization is unique. Rather than offering generic out-of-the-box DevOps services, Zai Systems invests time and effort to understand your specific goals, challenges, and requirements. `,


  },
  {
    id: 3,
    title: 'End-to-End Service Portfolio',
    para: `Zai Systems covers the entire software delivery lifecycle, from planning and development to deployment, monitoring, and scaling. Our holistic approach ensures that no aspect of your DevOps transformation is overlooked. `,

  },
  {
    id: 4,
    title: 'Transparent Communication',
    para: `Effective communication is paramount to any successful DevOps initiative. Zai Systems values transparency at every step. We keep you informed of project milestones, potential challenges, and evolving requirements. `,

  },
  {
    id: 5,
    title: 'Scalability and Flexibility',
    para: `Whether you are a startup with minimal infrastructure or a large enterprise operating on a global scale, our DevOps services can be customized to fit your size and complexity. We adapt to your growth trajectory, ensuring that as your demands increase, our solutions scale alongside you. `,

  },
  {
    id: 6,
    title: 'Global Reach and Client-Centric Focus',
    para: `With clients spanning multiple regions and industries, we have a wealth of experience in navigating different regulatory requirements, cultural nuances, and business practices. Our client-centric approach means we place your objectives at the forefront of our strategy.`,

  }
]
const devops6a = [
  {
    id: 1,
    title: 'Supercharge Your Development with DevOps Expertise',
    para: `In today's digital landscape, speed, agility, and flawless customer experiences are the cornerstones of success. Businesses 
    that cling to outdated IT practices risk drowning in inefficiencies and errors, ultimately leaving customers dissatisfied and 
    competitors pulling ahead. That's where Zai Systems' DevOps expertise comes in, offering a transformative approach to bridge 
    the gap between development and operations.`
  },

]
const devops6 = [
  {
    id: 1,
    title: 'Eradicating Inefficiency and Errors',
    para: (
      <>
        <span class='text-white'>Automate, automate, automate!</span> We automate processes with cutting-edge tools and
        scripts, minimizing human error and streamlining workflows. No clunky configuration updates or buggy code.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Modernize your infrastructure:</span> We bring your infrastructure up to speed, eliminating outdated
        systems. Embrace cloud, containerization, and other innovations to boost performance and reliability.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>Continuous improvement:</span> We constantly audit and refine your processes, identifying and
        eliminating bottlenecks. Your operations become a finely tuned machine, humming with efficiency.
      </>
    )
  },
  {
    id: 2,
    title: 'Fueling Collaboration and Innovation',
    para: (
      <>
        <span class='text-white'>Breaking down silos:</span> We foster a spirit of collaboration, tearing down the walls between
        development, operations, and QA. Teams work together seamlessly, ensuring smooth product delivery and rapid issue resolution.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Agile and responsive:</span> We embrace iterative development, allowing you to quickly adapt to changing market demands
        and deliver new features at lightning speed. Your customers get what they want, when they want it.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>Empowering creativity:</span> By automating routine tasks, we liberate your teams to focus on what truly
        matters. Unleash their creative potential and push the boundaries of what's possible.
      </>
    )
  },
  {
    id: 3,
    title: 'Customer Experience Excellence',
    para: (
      <>
        <span class='text-white'>Happy customers, happy business:</span> Our DevOps implementation is laser-focused on
        exceeding customer expectations. We minimize downtime, optimize performance, and ensure delightful products.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Building trust and loyalty:</span> By delivering high-quality experiences, you build lasting
        relationships with your customers. They become your biggest advocates, driving organic growth and brand loyalty.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>Maximizing ROI:</span> Streamlined operations, reduced errors, and happier customers translate into tangible
        results. With Zai Systems' DevOps, you get a powerful engine for growth and prosperity.
      </>
    )
  },
  {
    id: 4,
    title: 'Gaining the Competitive Edge',
    para: (
      <>
        <span class='text-white'>Become a lean, mean, innovation machine:</span> We help you streamline processes, reduce overhead
        costs, and minimize the human error factor. Your business becomes agile, adaptable, and ready to tackle any challenge.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Embrace the future of IT:</span> With Zai Systems, you don't just play catch-up, you become a leader in the digital
        landscape. We equip you with the latest tools and technologies, ensuring you're always ahead of the curve.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>Outshine the competition:</span> While your competitors struggle with outdated practices, you'll be delivering
        flawless experiences, iterating rapidly, and delighting your customers.
      </>
    )
  },
  {
    id: 5,
    title: `Optimized Resource Management`,
    para: (
      <>
        <span class='text-white'>Efficient Utilization of Resources: </span> Our DevOps practices ensure that your resources are used to their
        fullest potential, reducing waste and increasing efficiency. We optimize your infrastructure and human capital utilization.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Dynamic Scaling for Peak Performance:</span> We implement solutions that allow for dynamic scaling, ensuring that
        your systems can handle peak loads without unnecessary expenditure on idle resources during off-peak times.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>Eco-Friendly Operations:</span> Sustainability is key today. Our DevOps approach promotes eco-friendly
        operations by optimizing resource usage and a smaller carbon footprint for IT activities.
      </>
    )

  },
  {
    id: 6,
    title: `Future-Proofing Your Business`,
    para: (
      <>
        <span class='text-white'>Building Resilience into Systems: </span> We design systems that are not only robust but also resilient to change.
        This means your business can withstand disruptions and adapt to new challenges with ease.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Cultivating a Culture of Innovation:</span> At Zai Systems, we encourage a culture of innovation where new ideas
        are valued and explored. This ensures that your business stays ahead of technological trends and market shifts.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>Ensuring Longevity and Growth:</span> Our DevOps expertise ensures long-term success by building a foundation that supports growth and ensures longevity in the marketplace.
      </>
    )

  }
]

const SeoDataDevops = [
  {
    id: 1,
    title: `Hire the DevOps Engineers - Zai Systems`,
    content: `Supercharge Your IT Operations and hire reliable DevOps engineers today! | Automate, collaborate, innovate. We connect you with proven talent for DevOps success.`,
    link: `https://www.zaisystems.com/services/devops-engineers`
  }
]
// nft-marketplace
const nftMarketplace1 = [
  {
    id: 1,
    title: `NFTs Marketing Services for Explosive Growth`,
    para: `Welcome to Zai Systems, your ultimate partner in navigating the exciting and transformative world of NFT marketing. With the global NFT marketplace booming, standing out in the crowd is more critical than ever. Our specialized NFT marketing services are designed to ensure that your project gains the visibility, engagement, and conversions its needs for explosive growth. Whether you are launching your first NFT collection or scaling an existing portfolio, our expert team is here to help you succeed.`,
    // para1: `Our mission? To revolutionize asset tokenization by crafting the most effective and user-friendly
    //  NFT marketplaces in the world. We're not just building platforms, we're building communities, empowering 
    //  creators to monetize their work and collectors to discover the next big thing. Your vision, our expertise! `,
    img: nftMarketplace,
  }
]
const nftMarketplace4a = [
  {
    id: 1,
    title: 'Zai Systems’ NFT Marketplace Development Services',
  },

]
const nftMarketplace4 = [
  {
    id: 1,
    title: 'Peer-to-Peer NFT Exchange',
    para: `Tired of centralized platforms dictating your NFT experience? Zai Systems steps in, crafting innovative and customizable decentralized
     NFT marketplaces where you hold the reins. Our platforms leverage the power of smart contracts to facilitate a 
     seamless, secure, and transparent peer-to-peer (P2P) exchange of your precious non-fungible tokens. No middlemen,
      no hidden fees, just pure ownership and control.`,
    img: nftMarketOne
  },
  {
    id: 2,
    title: 'Filters',
    para: `Refine your search to a razor's edge with our granular filtering system. Whether you're a die-hard doge meme 
    collector or a connoisseur of abstract digital art, our filters let you curate your collection with laser-like 
    precision. Imagine sifting through thousands of NFTs to find only those with specific color palettes, animation 
    styles, or even hidden audio easter eggs – the possibilities are endless!`,
    img: nftMarketTwo
  },
  {
    id: 3,
    title: 'NFT Lending Platform Development',
    para: `Beyond buying and selling, Zai Systems empowers you to unlock the hidden potential of your NFTs through our 
    cutting-edge NFT lending platforms. Our platforms enable you to leverage your NFTs as collateral for borrowing
     loans, opening up a world of financial possibilities. Imagine using your valuable digital art to secure funding
      for your next big project, all without relinquishing ownership.`,
    img: nftMarketThree
  },
  {
    id: 4,
    title: 'Bespoke NFT Marketplaces',
    para: `At Zai Systems, we understand that one size doesn't fit all. That's why we go beyond the standard marketplace
     functionalities. We collaborate with you to design and build a platform tailored to your specific needs and vision.
      Whether you envision a niche marketplace for gaming assets, a community-driven art platform, or a comprehensive 
      solution for diverse NFTs, our skilled team brings your vision to life.`,
    img: nftMarketFour
  },
  {
    id: 5,
    title: 'Advanced Search',
    para: `Gone are the days of aimlessly scrolling through endless galleries. Our sophisticated search function 
    equips your users with the power of precision. Filter by artist, collection, rarity, attributes, price range, 
    and even specific keywords embedded within the NFT's description. Imagine, pinpointing that coveted cyberpunk 
    avatar with glowing neon eyes in mere seconds – pure magic, courtesy of Zai Systems.`,
    img: nftMarketFive
  },
  {
    id: 6,
    title: 'Bid and Buy',
    para: `The pulse-pounding excitement of an auction, the quiet satisfaction of a direct purchase – our platform 
    caters to every collector's thrill. Seamless bidding systems let you join the action in real-time, vying for that
     coveted piece with intuitive interface elements. And for those who prefer a swifter acquisition, our streamlined 
     buying options let you secure your desired NFT with a click, adding it to your collection in an instant.`,
    img: nftMarketSix
  },
  {
    id: 7,
    title: 'Features of our NFT Marketplace',
    para: `At Zai Systems, we craft NFT marketplaces beyond transaction platforms. We build vibrant 
    communities, empowered by cutting-edge features designed to navigate the exciting world of 
    non-fungible tokens. So, buckle up as we delve into the treasure trove that awaits within your Zai-built marketplace.`,
    img: nftMarketSeven
  },
  {
    id: 8,
    title: 'Listing Status',
    para: `Stay informed, stay ahead. Our intuitive listing status system provides real-time updates on every NFT, from 
    "Newly minted" to "Hot bid in progress" to "Sold out!" Users can chart their collecting journey with ease, knowing
     exactly where each gem stands in its marketplace voyage.`,
    img: nftMarketEight
  },
  {
    id: 9,
    title: 'Create Listing',
    para: `Not just a collector? Become a creator, minting and sharing your NFTs.
     Our listing tool guides you through the process, from uploading your digital
      masterpiece to defining its unique properties and setting a price. Unleash your artistic vision and let your NFTs
       embark on their journey.`,
    img: nftMarketNine
  },
  {
    id: 10,
    title: 'Wallet',
    para: `Rest assured, your precious NFTs are in safe hands. Our secure and integrated wallet system safeguards 
    your collection with advanced encryption and multi-factor authentication. Track your holdings, manage your funds,
     and make transactions with complete peace of mind, knowing your digital treasures are nestled within a fortress 
     of our making.`,
    img: nftMarketTen
  },
  {
    id: 11,
    title: 'Front End',
    para: `Your marketplace isn't just a platform, it's a portal to a vibrant community. We design  
    user-friendly front-end interfaces that captivate and engage your users. Imagine a sleek storefront adorned with
     curated collections, interactive elements, and personalized recommendations – a digital haven where NFT enthusiasts
      can gather, discover, and connect.`,
    img: nftMarketEleven
  },
  {
    id: 12,
    title: 'Ratings and Feedback',
    para: `Transparency and accountability are the cornerstones of any thriving marketplace. Our robust rating 
    and feedback system empowers users to share their experiences, fostering trust and guiding informed collecting
     decisions. Let your community flourish with open communication, building a space where reputation reigns supreme
     and mutual respect.`,
    img: nftMarketTwelve
  }
]
const nftMarketplace6a = [
  {
    id: 1,
    title: 'Delving Deeper into Zai Systems: Our Team for NFT Development',
    para: `Forget generic NFT platforms – Zai Systems crafts revolutionary solutions tailored to your vision. We're 
    not just developers, we're NFT enthusiasts who see their transformative potential. Partner with us, and we'll be 
    your compass every step of the way, navigating the exciting, intricate world of NFTs.`,
  },

]
const nftMarketplace6 = [
  {
    id: 1,
    title: 'Expert blockchain development',
    para: `Our team boasts profound expertise in a wide spectrum of blockchain technologies. We speak the language 
    of Ethereum, Solana, Polygon, and more, ensuring your platform rests on a rock-solid foundation. Whether you 
    seek lightning-fast transaction speeds, enhanced security, or niche functionalities, we'll match your needs 
    with the perfect blockchain infrastructure.`,
  },
  {
    id: 2,
    title: 'Customization galore',
    para: `One-size-fits-all? Not here! Every NFT project deserves a unique flavor, and we're
     the expert chefs. Forget generic platforms – we'll work with you 
     to cook up a bespoke marketplace or lending platform for your audience. From intuitive interfaces
      and streamlined workflows to cutting-edge features and captivating aesthetics, we'll craft a digital dining experience
      that satisfies user.`,
  },
  {
    id: 3,
    title: 'Security at its core',
    para: `Your trust is our sacred ingredient. That's why security permeates every facet of our creations. We implement 
    multi-layered security protocols to safeguard your users' precious NFTs. Think state-of-the-art smart contracts, 
    robust vulnerability testing, and meticulous access control measures – your digital assets will be nestled in a fortress
     of impenetrable security.`,

  },
  {
    id: 4,
    title: 'Scalability for growth',
    para: `We don't build sandcastles on the digital beach. We construct magnificent NFT fortresses capable of withstanding
    the tidal waves of growth. Our platforms are designed to dynamically scale as your user base and transaction volume surge.
     Imagine: millions of users seamlessly traversing your platform, buying, selling, and borrowing NFTs with lightning speed
      and unwavering stability. That's the Zai Systems promise.`,

  },
  {
    id: 5,
    title: 'Innovation at its best',
    para: ` We don’t settle for the status quo. We push the boundaries of what’s possible with
     NFTs, creating novel and groundbreaking solutions that set us apart from the rest. We leverage the latest technologies
      and tools to deliver cutting-edge NFT platforms that offer unparalleled user experience and value. Whether you 
    want to create, mint, trade, or lend NFTs, we’ll help you achieve your goals with innovation at its best.`,

  },
  {
    id: 6,
    title: 'Collaboration at its core',
    para: ` We don’t work for you, we work with you. We believe that collaboration is the key to 
    success, and we value your input and feedback throughout the development process. We listen to your needs, understand 
    your vision, and align our strategies with your objectives. We also communicate regularly, keeping you 
    updated on the progress and status of your project. We’re not just developers, we’re partners.`,

  }
]
// const nftMarketplace5a = [
//   {
//     id: 1,
//     title: 'Our NFT Marketplace Development Services for Different Sectors',
//     para: `Zai Systems is a US-based NFT art marketplace development company. We help blockchain entrepreneurs launch 
//     and manage their own NFT marketplaces with various features and functionalities. Whether you want to create a niche 
//     or a general NFT marketplace for music, real estate, automotive, infrastructure, sports games, art tokenization, 
//     fashion industry, architecture, or domains, we have the right solution for you:`,
//   },

// ]
// const nftMarketplace5 = [
//   {
//     id: 1,
//     title: 'NFT marketplace design',
//     para: `We design and build intuitive interfaces for your NFT marketplace, following best
//      practices and standards (ERC-721 and ERC-1155) for the creation of IPFS protocols and smart contracts.
//       You can customize your platform’s look to suit your brand identity and target audience.`,

//   },
//   {
//     id: 2,
//     title: 'NFT development',
//     para: `We develop and deploy NFTs for your digital assets, using the latest technologies and 
//     tools. You can mint, trade, and manage your NFTs on your platform, without technical hassle or coding.
//      You can also import your existing NFT collections from other platforms like OpenSea.`,

//   },
//   {
//     id: 3,
//     title: 'NFT smart contract audit',
//     para: `We offer smart contract development and audit services for your NFTs, ensuring that they are compliant, 
//     scalable, and error-free. We use Solidity, Truffle, Web3.js, and other frameworks to create and test them.
//      We also audit and review code for security and bugs.`,

//   },
//   {
//     id: 4,
//     title: 'NFT marketplace support and maintenance',
//     para: `We provide ongoing support and maintenance for your NFT marketplace, ensuring that it runs smoothly 
//     and efficiently. We monitor and optimize performance, security, and functionality, and resolve 
//     any issues or glitches. We also update and upgrade your platform with new features and enhancements, as needed.`,

//   },
//   {
//     id: 5,
//     title: 'NFT marketplace marketing and promotion',
//     para: ` We help you market and promote your NFT marketplace, reaching out to
//      potential buyers and sellers of your digital assets. We use various channels and strategies, 
//     such as social media, SEO, PPC, email marketing, influencer marketing, and more, to boost your platform’s visibility and reputation.`,

//   },
//   {
//     id: 6,
//     title: 'NFT marketplace integration and customization',
//     para: ` We integrate and customize your NFT marketplace with various third-party services and platforms, such as wallets, exchanges, payment gateways, analytics, and more, to 
//     enhance your platform’s functionality and user experience. We also offer custom solutions for your specific needs and preferences.`,

//   }
// ]
const SeoDataMarketplace = [
  {
    id: 1,
    title: `NFT Marketplace Development Services - Zai Systems`,
    content: `Struggling to Launch Your NFT Marketplace?  We Build Secure & Scalable Platforms for Seamless Trading. Attract creators & collectors with custom features. Get a free quote & join the NFT revolution!`,
    link: `https://www.zaisystems.com/services/nft-marketplace`
  }
]
// nft-minting
const nftMinting1 = [
  {
    id: 1,
    title: `NFT Minting Services`,
    para: `
     Zai Systems' NFT Minting Services is an innovative process that bridges the gap between the digital and the blockchain worlds. It's the catalyst 
    that transforms digital files, such as captivating artwork, mesmerizing music, and captivating collectibles, into irreplaceable 
    digital assets embedded within the secure and transparent realm of blockchain technology.`,
    para1: `At Zai Systems, we've meticulously cultivated a profound understanding of NFT minting, mastering the intricacies of the
     two most prominent blockchain platforms for this process: Binance Smart Chain and Ethereum. Our proficiency in these domains 
     empowers us to guide you seamlessly through the NFT minting journey, ensuring your digital creations embark on their blockchain
     voyage with confidence.`,
    img: nftMinting,
  }
]
// const nftMinting9a = [
//   {
//     id: 1,
//     title1: 'The Need for NFT Minting Services',
//     para: `In the ever-evolving landscape of Web 3.0, the ability to mint NFTs has become a crucial skill for creators, 
//     collectors, and entrepreneurs alike. While the surface-level benefits of NFT ownership are enticing – decentralization, 
//     unique digital assets, and potential financial gain – the need for dedicated minting services delves deeper, offering a 
//     spectrum of advantages that empower individuals and projects in meaningful ways.`
//   },

// ]
// const nftMinting9 = [
//   {
//     id: 1,
//     para: (
//       <>
//         <span class='text-white'>From the Creator's Perspective:</span>
//       </>
//     )

//   },
//   {
//     id: 5,
//     para: (
//       <>
//         <span class='text-white'></span>
//       </>
//     )

//   },
//   {
//     id: 2,
//     para: (
//       <>
//         <span class='text-white'>Seamless Workflow and Expertise:</span> Minting platforms like Zai Systems provide user-friendly
//         interfaces and intuitive tools that guide creators through the entire process, from uploading files to setting royalties and
//         smart contract parameters. This removes technical hurdles and allows creators to focus on what they do best – crafting
//         compelling digital assets.
//       </>
//     )

//   },
//   {
//     id: 3,
//     para: (
//       <>
//         <span class='text-white'>Reaching the Right Audience:</span> Zai Systems offers built-in marketing tools and access to a curated
//         network of collectors and enthusiasts, ensuring that your NFTs reach the right audience and maximize your potential for sales and recognition.
//       </>
//     )

//   },
//   {
//     id: 4,
//     para: (
//       <>
//         <span class='text-white'>Protecting Your Work and Reputation:</span> Zai Systems prioritizes security and transparency, ensuring
//         your NFTs are minted on secure blockchains and equipped with tamper-proof ownership records. This safeguards your work from plagiarism
//         and builds trust with potential buyers.
//       </>
//     )

//   },
//   {
//     id: 10,
//     para: (
//       <>
//         <span class='text-white'></span>
//       </>
//     )

//   },
//   {
//     id: 5,
//     para: (
//       <>
//         <span class='text-white'>For Collectors and Investors:</span>
//       </>
//     )

//   },
//   {
//     id: 6,
//     para: (
//       <>
//         <span class='text-white'></span>
//       </>
//     )

//   },
//   {
//     id: 7,
//     para: (
//       <>
//         <span class='text-white'>Curating a Unique Portfolio:</span> Zai Systems allows collectors to access a diverse range of NFTs
//         across various genres and industries. This facilitates the building of personalized collections and investment portfolios based
//         on individual interests and risk tolerance.
//       </>
//     )

//   },
//   {
//     id: 8,
//     para: (
//       <>
//         <span class='text-white'>Investing in the Future:</span> Zai Systems prioritizes projects with strong roadmaps and real-world
//         utility for their NFTs. This gives collectors the confidence to invest in assets that hold potential for long-term value beyond
//         mere speculation.
//       </>
//     )

//   },
//   {
//     id: 9,
//     para: (
//       <>
//         <span class='text-white'>Supporting Creators and Innovation:</span> By utilizing minting services, collectors contribute directly
//         to the growth of the NFT ecosystem and empower creators to continue pushing the boundaries of digital art and innovation.
//       </>
//     )

//   }
// ]
const nftMinting4a = [
  {
    id: 1,
    title: `Features of Our NFT Minting Platform`,
    para: `While the financial potential of NFTs is undeniable, the true need for minting services lies in their ability 
    to facilitate the democratization of ownership, the creation of new forms of value, and the fostering of a vibrant and engaged community.`

  },

]
const nftMinting4 = [
  {
    id: 1,
    title: 'Decentralized Ownership',
    para: `NFTs break away from traditional centralized systems, giving creators and collectors direct control over their 
    unique assets. This empowers individuals and fosters ownership and community within the vibrant NFT space, 
    unleashing their creativity and potential.`,
    img: nftmintingOne
  },
  {
    id: 2,
    title: 'Publishing Real-World Assets',
    para: `NFTs bridge the gap between the physical and digital worlds, allowing the tokenization of real-world assets like artwork,
     music, and even physical goods. This opens up new avenues for monetization, accessibility, and innovation.`,
    img: nftmintingTwo

  },
  {
    id: 3,
    title: 'Trading of Unique Digital Assets',
    para: `NFTs create a market for truly unique digital assets, fostering a vibrant ecosystem of collectors, traders, and creators. 
    This not only drives economic activity but also encourages the creation of high-quality and innovative digital content.`,
    img: nftmintingThree

  }

]
// const nftMinting5a = [
//   {
//     id: 1,
//     title: `Experience Seamless NFT Minting and Management`,
//     para: `At Zai Systems, we understand that navigating the world of NFTs requires a platform that's not just functional,
//      but intuitive and empowering. That's why we've built a comprehensive NFT minting platform packed with features designed
//       to streamline your experience, whether you're a seasoned creator, a curious collector, or an astute investor.
//     `
//   },

// ]
// const nftMinting5 = [
//   {
//     id: 1,
//     title: 'Your Personal Portal: The User Profile Page',
//     para: (
//       <>
//         <span class='text-white'>Stay in Control:</span> Manage your entire NFT journey from a single, secure profile. View all your minted NFTs,
//         track transaction history, and explore other collections you own, giving you a holistic overview of your digital assets.
//       </>
//     ),
//     para1: (
//       <>
//         <span class='text-white'>Personalize Your Experience:</span> Express yourself! Customize your profile with a captivating avatar and bio,
//         showcasing your unique identity within the Zai Systems community.
//       </>
//     )

//   },
//   {
//     id: 2,
//     title: 'Effortless Discovery: Search and Filters',
//     para: (
//       <>
//         <span class='text-white'>Find Your Treasure:</span> Utilize our search bar and filters to effortlessly navigate the
//         vast ocean of NFTs. Search by keyword, category, creator, or even specific attributes, ensuring you find the hidden gems that pique your interest.
//       </>
//     ),
//     para1: (
//       <>
//         <span class='text-white'>Stay on Top of the Trend:</span> Stay updated with our sections for recent and trending NFTs. Discover
//         what's hot in the market, spot rising stars, and invest wisely with real-time data.
//       </>
//     )

//   },
//   {
//     id: 3,
//     title: 'The Spotlight Awaits: Your NFT Storefront',
//     para: (
//       <>
//         <span class='text-white'>Showcase Your Masterpieces:</span> Make your profile a storefront, displaying your NFTs.
//         Upload captivating visuals, add detailed descriptions, and highlight unique attributes, attracting potential buyers
//         and investors.
//       </>
//     ),
//     para1: (
//       <>
//         <span class='text-white'>Guaranteed Traction, Maximum Impact:</span> Zai Systems values discoverability. We use our network and
//         tools to help your NFTs reach the right audience, attracting buyers.
//       </>
//     )

//   },
//   {
//     id: 4,
//     title: 'Beyond Fixed Prices: The Thrilling World of Auctions',
//     para: (
//       <>
//         <span class='text-white'>Spark Excitement, Drive Value:</span> Don't settle for a fixed price! Utilize our dynamic auction feature to create
//         a buzz around your NFTs. Watch as bids roll in, raising the stakes and potentially fetching you a premium price for your prized digital assets.
//       </>
//     ),
//     para1: (
//       <>
//         <span class='text-white'>Strategic Control, Optimal Results:</span> Set your reserve price, define auction duration, and choose from various
//         bidding formats to tailor the auction experience to your specific goals and preferences.
//       </>
//     )

//   },
//   {
//     id: 5,
//     title: 'Wallet Freedom: Universal Compatibility',
//     para: (
//       <>
//         <span class='text-white'>Seamless Transactions, Effortless Management: </span> Zai Systems believes in open ecosystems. Our platform
//         integrates with a wide range of popular wallets, giving you the freedom and flexibility to manage your NFTs on your preferred platform.
//       </>
//     ),
//     para1: (
//       <>
//         <span class='text-white'>Say Goodbye to Hurdles:</span> Focus on creating and collecting, not technical hassles. Our intuitive interface and
//         wallet compatibility ensure frictionless transactions and effortless management of your digital assets.
//       </>
//     )

//   },
//   {
//     id: 6,
//     title: `Empower Your Creativity: The Artist’s Dashboard`,
//     para: (
//       <>
//         <span class='text-white'>Unleash Your Potential: </span> Access tools for artists to easily create and mint NFTs. Our dashboard
//         supports multiple file formats and advanced minting settings, including royalties and blockchain protocol selection.
//       </>
//     ),
//     para1: (
//       <>
//         <span class='text-white'>Track Your Success:</span> Gain insights into how your NFTs are performing in the market with our
//         comprehensive analytics. Monitor views, likes, sales, and more to understand your audience and refine your strategy.
//       </>
//     )

//   }
// ]
const nftMinting6a = [
  {
    id: 1,
    title: 'Minting NFTs Across Different Sectors',
    para: `At Zai Systems, we believe that the potential for tokenization lies in every corner of the modern world, unlocking 
    innovative forms of ownership, value creation, and engagement across diverse sectors. Let's dive deeper into how NFTs are 
    revolutionizing various industries:`
  },

]
const nftMinting6 = [
  {
    id: 1,
    title: 'Music',
    para: (
      <>
        <span class='text-white'>Empowering creators:</span>Musicians create NFTs of music, control its sale and profit.
        Fans can own rare tracks, backstage passes, or voting rights,
        fostering deeper connections and loyalty.


      </>
    ),
    para1: (
      <>
        <span class='text-white'>Fractional ownership:</span> Rare recordings or unreleased demos can be fractionalized as NFTs, democratizing access
        for fans and providing new revenue streams for artists.

      </>
    )

  },
  {
    id: 2,
    title: 'Art',
    para: (
      <>
        <span class='text-white'>Verifiable authenticity:</span> NFTs provide indisputable proof of ownership for digital artwork,
        combating rampant plagiarism and protecting the value of artistic creations securely.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Interactive experiences:</span> Interactive NFTs transform static art into dynamic experiences,
        allowing collectors to unlock hidden content, influence narratives, or co-create.
      </>
    )

  },
  {
    id: 3,
    title: 'Real Estate',
    para: (
      <>
        <span class='text-white'>Fractional ownership:</span> Fractionally owned NFTs can make high-value properties accessible
        to a broader pool of investors, democratizing access to the real estate market.


      </>
    ),
    para1: (
      <>
        <span class='text-white'>Smart contracts:</span> NFTs can streamline complex real estate transactions with self-executing
        smart contracts, reducing paperwork and administrative burden.
      </>
    )

  },
  {
    id: 4,
    title: 'Fashion Industry',
    para: (
      <>
        <span class='text-white'>Digital wearables:</span> Luxury brands can create exclusive digital clothing items as
        NFTs, catering to the metaverse and fostering new forms of self-expression in virtual worlds.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Authenticity and provenance:</span> NFTs track the origin and ownership of luxury goods, combating
        counterfeiting and ensuring transparency throughout the supply chain.
      </>
    )

  },
  {
    id: 5,
    title: 'Automotive',
    para: (
      <>
        <span class='text-white'>Digital car collectibles:</span> Rare car models or limited-edition parts can be minted as
        NFTs, creating a market for exclusive automotive memorabili
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Enhanced ownership experiences:</span> NFTs can unlock unique benefits for car owners, such as
        exclusive club memberships, access to special events, or personalized maintenance services.
      </>
    )

  },
  {
    id: 6,
    title: 'Architecture',
    para: (
      <>
        <span class='text-white'>3D blueprints and designs:</span> Architectural plans and models, tokenized as NFTs, simplifying
        collaboration, protect property, and enable fractional ownership of iconic designs.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Interactive virtual spaces:</span>  Architecture firms can create NFT-powered models of their
        projects, allowing potential clients to virtually experience their designs before construction.
      </>
    )

  },
  {
    id: 7,
    title: 'Beyond the Conventional',
    para: (
      <>
        <span class='text-white'>Gaming: </span>In the dynamic world of gaming, virtual assets such as weapons, characters, and
        exclusive items have gained new value. Utilizing blockchain technology, these digital treasures
        can be minted as Non-Fungible Tokens (NFTs), granting players indisputable ownership rights.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Domain Names: </span>Top-level domain names , which are the highest segment of the internet’s
        domain name system hierarchy, can now be tokenized as non-fungible tokens (NFTs), potentially
        increasing their value and providing unique branding opportunities.
      </>
    )

  },
  {
    id: 8,
    title: `Education and Learning`,
    para: (
      <>
        <span class='text-white'>Certification and Credentials: </span> NFTs can represent educational achievements, providing a secure
        and immutable record of qualifications. They can be used to verify academic credentials, making them easily shareable and recognizable
        by institutions and employers worldwide.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Interactive Learning Materials:</span> Transform educational content into interactive NFTs that engage
        students in a novel way. These could include multimedia textbooks, virtual lab simulations, or historical artifacts that can be explored in 3D.
      </>
    )

  },
  {
    id: 9,
    title: `Healthcare and Wellness`,
    para: (
      <>
        <span class='text-white'>Medical Records and Data: </span> Securely store and share patient medical records as NFTs, ensuring privacy and control
        over personal health information. This can streamline healthcare delivery and empower patients with ownership of their medical data.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Wellness and Fitness Rewards:</span> Create NFT-based reward systems for wellness and fitness achievements.
        Users can earn NFTs for reaching health milestones, participating in challenges, or achieving personal bests, which can then be
        traded or redeemed for real-world benefits.
      </>
    )

  }

]
const nftMinting13a = [
  {
    id: 1,
    title: 'A Look at Leading Marketplaces with Zai Systems',
    para: `Choosing the right NFT minting platform can be a daunting task with so many options out there. 
    Each marketplace caters to different audiences, fees, and functionalities. To help you navigate this 
    diverse landscape, we'll take a closer look at some of the leading NFT minting platforms:`
  },

]
const nftMinting13 = [
  {
    id: 1,
    title: 'OpenSea',
    para: `The behemoth, OpenSea is a general marketplace boasting the largest user base and widest variety of NFTs.
     It's ideal for established creators and collectors, offering low fees for basic listings but higher gas fees on Ethereum.`

  },
  {
    id: 2,
    title: 'Axie Infinity Marketplace',
    para: `Built for the popular play-to-earn game Axie Infinity, this platform allows players to mint, trade, and battle their
     Axie creatures as NFTs. It's great for gamers and crypto enthusiasts familiar with the Axie ecosystem and its native token, AXS.`

  },
  {
    id: 3,
    title: 'Rarible',
    para: `A user-friendly and community-driven marketplace, Rarible offers low fees and supports multiple blockchains. It's a 
    good choice for creators new to NFTs and seeking an accessible platform with a strong community presence.`

  },
  {
    id: 4,
    title: 'Foundation',
    para: `Focused on high-quality art, Foundation features a curated selection of NFTs from established and emerging artists. It's renowned 
    for its rigorous application process and emphasis on artistic merit, making it a prestigious platform for creators seeking serious collectors.`

  },
  {
    id: 7,
    title: 'SuperRare',
    para: `Another curated platform for digital art, SuperRare features unique single-edition NFTs and fosters a close-knit community of 
    collectors and creators. It's ideal for artists seeking a platform focused on the artistic value of their work and collectors discerning
     exclusive digital art pieces.`

  },

  {
    id: 6,
    title: 'Binance NFT',
    para: `This platform leverages the power of the Binance exchange, allowing users to purchase and sell NFTs with their existing Binance 
    accounts. It's attractive for those already familiar with the Binance ecosystem and offers features like blind box sales and mystery mints.`

  },
  {
    id: 5,
    title: 'Nifty Gateway',
    para: `Partnering with celebrities and established brands, Nifty Gateway focuses on limited-edition drops and exclusive releases.
     It caters to collectors seeking curated, high-profile NFTs and is known for its sleek user interface and curated experiences.`

  },
  {
    id: 8,
    title: 'Decentraland Marketplace',
    para: `This platform is native to the Decentraland metaverse, allowing users to mint and trade virtual land parcels, wearables, and other
     in-game assets. It's a must-explore for those invested in the metaverse and its potential for digital ownership.`

  },
  {
    id: 9,
    title: 'Mintable',
    para: ` A versatile and easy-to-use platform, Mintable allows users to create, buy, and sell NFTs without any coding skills.
     It supports both Ethereum and Binance Smart Chain and offers gasless minting options.
     It’s suitable for beginners and experienced creators alike.`

  }
]
// const nftMinting10a = [
//   {
//     id: 1,
//     title: `Our Step-By-Step Process`,
//     para: `Our process for NFT minting development. `
//   },

// ]
// const nftMinting10 = [
//   {
//     id: '1',
//     imageTwo: nftMintingb1,
//     head: `Project Analysis`,
//     title: `We begin by immersing ourselves in your vision, understanding your project's scope, target audience, and desired functionalities.`,
//     title1: `We conduct a thorough analysis of your market, competitors, and industry trends, ensuring your platform is poised for success.`,
//     title2: `We collaborate closely with you to define project objectives, establish milestones, and set clear expectations throughout the development journey.`
//   }
//   ,
//   {
//     id: '2',
//     imageTwo: nftMintingb2,
//     head: `Requirement Gathering`,
//     title: `We gather detailed requirements regarding desired features, user flows, technical specifications, and design preferences for each project.`,
//     title1: `We prioritize security and regulatory compliance, ensuring your platform adheres to best practices and protects user data effectively.`,
//     title2: `We pay close attention to accessibility and inclusivity, aiming to create a platform that welcomes users of all abilities and backgrounds.`,
//   },
//   {
//     id: '3',
//     imageTwo: nftMintingb3,
//     head: `Smart Contract Development`,
//     title: `Our expert blockchain developers create robust and secure smart contracts that underpin the core functionality of your NFT minting platform.`,
//     title1: `We meticulously define ownership rules, transaction processes, royalty distribution, and other essential aspects within the contracts.`,
//     title2: `We implement rigorous testing and auditing procedures to guarantee the integrity and reliability of the smart contracts under various scenarios.`,
//   },
//   {
//     id: '4',
//     imageTwo: nftMintingb4,
//     head: `UX/UI Development`,
//     title: `We design intuitive and visually appealing user interfaces that prioritize user experience and guide users through the minting process.`,
//     title1: `We prioritize clarity and simplicity, ensuring a seamless experience across various devices and screen sizes.`,
//     title2: `We incorporate branding elements and visual cues with your project's identity, fostering brand recognition and user trust.`,

//   },
//   {
//     id: '5',
//     imageTwo: nftMintingb5,
//     head: `API Integrations`,
//     title: `We seamlessly integrate with essential third-party services through comprehensive APIs, including: `,
//     title1: `Blockchain networks (Ethereum,
//        Polygon, Flow, etc.) Payment gateways (Stripe, PayPal, etc.)`,
//     title2: ` Digital wallets (MetaMask, Coinbase Wallet, etc.) Storage
//         solutions (IPFS, File coin) Analytics and tracking tools.`
//   },
//   {
//     id: '6',
//     imageTwo: nftMintingb6,
//     head: `Testing`,
//     title: `We conduct comprehensive testing across various scenarios to ensure platform stability, security, and optimal performance.`,
//     title1: `We actively involve users in testing phases to gather valuable feedback and refine the overall user experience.`,
//     title2: `We implement continuous testing and monitoring to promptly address any potential issues or bugs.`,

//   },
//   {
//     id: '7',
//     imageTwo: nftMintingb7,
//     head: `Product Deployment`,
//     title: `We deploy your NFT minting platform onto a reliable hosting infrastructure, ensuring scalability and smooth operation.`,
//     title1: `We provide comprehensive documentation and training materials to guide you and your team in platform management.`,
//     title2: `We offer ongoing support and maintenance services, ensuring your platform remains up-to-date and secure over time.`,
//   },
//   {
//     id: '8',
//     imageTwo: nftMintingb8,
//     head: `Marketing and Launch Strategy`,
//     title: `We craft a compelling narrative around your NFT project, highlighting its uniqueness and value proposition
//     to captivate your audience.`,
//     title1: `We identify and collaborate with influencers, brands, and platforms to amplify your reach and establish
//      credibility in the NFT space.`,
//     title2: `We design and execute targeted marketing campaigns to generate buzz and drive engagement at launch.`,
//   },
//   {
//     id: '9',
//     imageTwo: nftMintingb9,
//     head: `Post-Launch Support`,
//     title: `We establish mechanisms to collect and analyze user feedback, enabling continuous improvement and user satisfaction.`,
//     title1: `We keep your platform at the forefront of innovation by implementing new features and staying aligned with market trends.`,
//     title2: `We help you nurture a vibrant community around your NFT project, fostering loyalty and encouraging user-generated content.`,
//   }
// ]

const SeoDataMinting = [
  {
    id: 1,
    title: `NFT Minting Services - Zai Systems`,
    content: `Powerful NFT Minting for Collectors & Creators ✨  Join the digital art revolution! We make minting your NFTs simple, secure, & profitable. Create & monetize your passion.`,
    link: `https://www.zaisystems.com/services/nft-minting`
  }
]
// nft marketing
const nftMarketing1 = [
  {
    id: 1,
    title: `NFTs Marketing Services for Explosive Growth`,
    para: `As the NFT sphere explodes with innovation, navigating the landscape can be daunting. But fear not, for
     Zai Systems stands firm as your trusted compass, guiding your project to dazzling heights through expert NFT marketing services.`,
    img: nftMarketing,
  }
]
const nftMarketing9a = [
  {
    id: 1,
    title1: 'Our NFT Marketing Services',
    para: `At Zai Systems, we understand the ever-evolving landscape of NFTs and the unique challenges of capturing 
    attention in this dynamic space. That's why we offer two targeted methodologies to propel your NFT project to success,
     ensuring you reach the right audience and maximize its value.`
  },

]
const nftMarketing9 = [
  {
    id: 1,
    para: (
      <>
        <span class='text-white'>1) NFT Community Building</span>
      </>
    )

  },
  {
    id: 2,
    para: (
      <>
        <span class='text-white'>Building a strong, engaged community is the backbone of any successful NFT project. At Zai Systems, we use cutting-edge techniques to foster a loyal and active audience for your NFTs.</span>
      </>
    )

  },
  {
    id: 3,
    para: (
      <>
        <span class='text-white'>Discord Management:</span>  Create and manage thriving communities on Discord, the go-to platform for NFT enthusiasts.
      </>
    )

  },
  {
    id: 4,
    para: (
      <>
        <span class='text-white'>Social Media Growth:</span>Boost your presence on platforms like Twitter, Instagram, and TikTok, where NFT discussions are trending
      </>
    )

  },
  {
    id: 5,
    para: (
      <>
        <span class='text-white'>Interactive Campaigns:</span> Conduct AMAs, giveaways, and interactive sessions to engage your audience effectively.
      </>
    )

  },
  {
    id: 8,
    para: (
      <>
        <span class='text-white'></span>
      </>
    )

  },
  {
    id: 9,
    para: (
      <>
        <span class='text-white'>2) Social Media Marketing</span>
      </>
    )

  },
  {
    id: 10,
    para: (
      <>
        <span class='text-white'></span>
      </>
    )

  },
  {
    id: 11,
    para: (
      <>
        <span class='text-white'>Content Creation:</span> Design visually stunning posts, videos, and reels tailored to your NFT collection.
      </>
    )
  },
  {
    id: 12,
    para: (
      <>
        <span class='text-white'>Influencer Marketing:</span> Partner with influential creators to amplify your reach.
      </>
    )
  },
  {
    id: 13,
    para: (
      <>
        <span class='text-white'>Paid Advertisements:</span>Launch targeted advertising campaigns to drive traffic and conversions.
      </>
    )
  },


  // three


  {
    id: 1,
    para: (
      <>
        <span class='text-white'>1) NFT SEO Services</span>
      </>
    )

  },
  {
    id: 2,
    para: (
      <>
        <span class='text-white'>Rank high in search results with our NFT SEO strategies. We optimize your website, marketplace listings, and blog content to attract organic traffic.</span>
      </>
    )

  },
  {
    id: 3,
    para: (
      <>
        <span class='text-white'>Keyword Research:</span>  Target high-ranking NFT keywords like "buy NFTs," "NFT marketplace," and "best NFT collections.
      </>
    )

  },
  {
    id: 4,
    para: (
      <>
        <span class='text-white'>On-Page Optimization:</span>Improve your site’s content, meta tags, and loading speed.
      </>
    )

  },
  {
    id: 5,
    para: (
      <>
        <span class='text-white'>Backlink Building:</span> Increase your site authority with strategic link-building campaigns.
      </>
    )

  },

  //four

  {
    id: 1,
    para: (
      <>
        <span class='text-white'>1) Content Marketing</span>
      </>
    )

  },
  {
    id: 2,
    para: (
      <>
        <span class='text-white'>Informative and engaging content is essential for building trust and credibility in the NFT space.</span>
      </>
    )

  },
  {
    id: 3,
    para: (
      <>
        <span class='text-white'>Blog Writing:</span>  Publish articles on topics like "How to Buy NFTs" or "The Future of NFTs.
      </>
    )

  },
  {
    id: 4,
    para: (
      <>
        <span class='text-white'>Video Production:</span>Create tutorial videos, explainer animations, and promotional trailers
      </>
    )

  },
  {
    id: 5,
    para: (
      <>
        <span class='text-white'>Whitepapers:</span> Establish authority with in-depth whitepapers and case studies
      </>
    )

  },



  // five
  {
    id: 1,
    para: (
      <>
        <span class='text-white'>1) Influencer Collaborations</span>
      </>
    )

  },
  {
    id: 2,
    para: (
      <>
        <span class='text-white'>Leverage the power of influencer marketing to create buzz for your NFTs. We work with top NFT influencers to showcase your project to their massive audiences.</span>
      </>
    )

  },
  {
    id: 3,
    para: (
      <>
        <span class='text-white'>Nano and Micro-Influencers:</span>  Target niche audiences with smaller but highly engaged communities.
      </>
    )

  },
  {
    id: 4,
    para: (
      <>
        <span class='text-white'>Celebrity Endorsements:</span>Partner with well-known personalities in the crypto and art world.
      </>
    )

  },


  //six
  {
    id: 1,
    para: (
      <>
        <span class='text-white'>1) NFT PR and Outreach</span>
      </>
    )

  },
  {
    id: 2,
    para: (
      <>
        <span class='text-white'>Get featured in leading NFT publications and media outlets. Our PR services ensure your project gets the attention it deserves.</span>
      </>
    )

  },
  {
    id: 3,
    para: (
      <>
        <span class='text-white'>Press Releases:</span>  Write and distribute compelling press releases.
      </>
    )

  },
  {
    id: 4,
    para: (
      <>
        <span class='text-white'>Media Partnerships:</span>Collaborate with NFT news platforms and podcasts.
      </>
    )

  },
  {
    id: 5,
    para: (
      <>
        <span class='text-white'>Thought Leadership:</span> Position yourself as an industry expert with guest posts and interviews.
      </>
    )

  },


  // seven
  {
    id: 1,
    para: (
      <>
        <span class='text-white'>1) NFT PR and Outreach</span>
      </>
    )

  },
  {
    id: 2,
    para: (
      <>
        <span class='text-white'>Get featured in leading NFT publications and media outlets. Our PR services ensure your project gets the attention it deserves.</span>
      </>
    )

  },
  {
    id: 3,
    para: (
      <>
        <span class='text-white'>Press Releases:</span>  Write and distribute compelling press releases.
      </>
    )

  },
  {
    id: 4,
    para: (
      <>
        <span class='text-white'>Media Partnerships:</span>Collaborate with NFT news platforms and podcasts.
      </>
    )

  },
  {
    id: 5,
    para: (
      <>
        <span class='text-white'>Thought Leadership:</span> Position yourself as an industry expert with guest posts and interviews.
      </>
    )

  },


  // eight
  {
    id: 1,
    para: (
      <>
        <span class='text-white'>1) NFT Marketplace Optimization</span>
      </>
    )

  },
  {
    id: 2,
    para: (
      <>
        <span class='text-white'>Maximize visibility on popular NFT marketplaces like OpenSea, Rarible, and Binance NFT.</span>
      </>
    )

  },
  {
    id: 3,
    para: (
      <>
        <span class='text-white'>Listing Optimization:</span>  Craft compelling titles, descriptions, and visuals.
      </>
    )

  },
  {
    id: 4,
    para: (
      <>
        <span class='text-white'>Pricing Strategies:</span>Use data-driven approaches to set competitive prices.
      </>
    )

  },
  {
    id: 5,
    para: (
      <>
        <span class='text-white'>Marketplace Promotions:</span>Run time-limited deals and promotions.
      </>
    )

  },


]

const nftMarketing5a = [
  {
    id: 1,
    title: `Why Choose Zai Systems for NFT Marketing?`,
    para: `At Zai Systems, we combine innovative strategies with deep industry expertise to offer top-notch NFT marketing solutions. Here's why we stand out:`
  },

]
const nftMarketing5 = [
  {
    id: 1,
    title: `Industry Expertise`,
    para: `Our team comprises seasoned marketing professionals with years of experience in the blockchain, cryptocurrency, and NFT sectors.`,

  },
  {
    id: 2,
    title: `Custom Strategies`,
    para: `We tailor every campaign to meet your unique goals, audience, and brand identity.`,

  },
  {
    id: 3,
    title: `Proven Results`,
    para: `We have a track record of helping NFT creators and brands achieve exponential growth.`,


  },
  {
    id: 3,
    title: `End-to-End Solutions`,
    para: `From strategy development to execution and analytics, we handle it all.`,


  }
]
const nftMarketing6a = [
  {
    id: 1,
    title: `Benefits of Hiring Zai Systems as NFT Marketing Agency`,
    para: `Zai Systems is a leading NFT marketing agency in Pakistan and USA that specializes in helping clients launch
    and grow their NFT projects. We have a team of marketing experts who have extensive experience and knowledge in the
     NFT space. They can assist you with every aspect of your NFT marketing strategy, from branding and design to 
     community building and promotion.`
  },

]
const nftMarketing6 = [
  {
    id: 1,
    title: `Captivate a New Audience`,
    para: `NFTs transcend traditional demographics, opening doors to tech-savvy collectors and crypto enthusiasts. Zai 
    Systems crafts targeted campaigns that resonate with this vibrant community, drawing them into your brand sphere.`
  },
  {
    id: 2,
    title: `Ignite Brand Radiance`,
    para: `NFTs are more than just digital assets; they're conversation starters. Zai Systems leverages their inherent 
    buzz to boost your brand. Imagine headlines with your name as limited-edition NFTs fuel
     organic reach and amplify awareness.`

  },
  {
    id: 3,
    title: `Generate Electrifying Traction`,
    para: `NFTs inject a dose of excitement into your project. Zai Systems masterfully orchestrates pre-launch drops 
    and exclusive community events, building anticipation and driving a wave of organic engagement that propels your brand forward.`

  },
  {
    id: 4,
    title: `Cultivate Community`,
    para: `NFTs aren't just commodities; they're keys to exclusive clubs. Zai Systems helps you craft NFTs that grant access to special
     perks, fostering a loyal community of brand advocates invested in your success.`

  },
  {
    id: 5,
    title: `Unlock New Revenue Streams`,
    para: `NFTs are more than marketing tools; they're monetization marvels. Zai Systems guides you in crafting NFTs that 
    generate recurring revenue through royalty structures, opening up a whole new world of financial possibilities.`

  },
  {
    id: 6,
    title: `Showcase Your Creativity`,
    para: `NFTs are a unique way to express your vision and values. Zai Systems helps you create
     NFTs that reflect your brand identity and story, using cutting-edge design and technology. Whether you want to mint art,
     music, gaming, or social impact NFTs.`

  }
]

const SeoDataMarketing = [
  {
    id: 1,
    title: `NFT Marketing Services - Zai Systems`,
    content: `Mint Your Hype by our NFT Marketing Services to Ignite Your Collection's Success! Reach engaged collectors & build thriving communities. `,
    link: `https://www.zaisystems.com/services/nft-marketing`
  }
]
// nft design
const nftDesign1 = [
  {
    id: 1,
    title: `NFT Design Creation Services `,
    para: `At Zai Systems, we're passionate about empowering individuals and businesses to navigate 
    the rapidly evolving world of NFTs. Our comprehensive NFT Design Creation Services empower you 
    to create unique, captivating, and highly marketable digital assets that resonate with your audience.`,
    img: nftDesign,
  }
]
const nftDesign4a = [
  {
    id: 1,
    title: 'We Offer Comprehensive NFT Design Services',
    para: `Our team boasts a deep understanding of the NFT landscape, ensuring your project aligns with
     current trends and market demands`
  }

]
const nftDesign4 = [

  {
    id: 1,
    title: 'Stunning Visual Design',
    para: `Our talented artists and designers create captivating artwork, animations, and 3D 
    models that perfectly embody your brand and concept. We offer expertise in various visual 
    styles, from 2D illustrations to immersive 3D experiences.`,
    img: nftDesignOne

  },
  {
    id: 2,
    title: 'Generational Art and Avatar Collections',
    para: ` We specialize in crafting cohesive and engaging NFT collections that leverage the
     power of generative algorithms. This create diverse and unique NFTs while 
     maintaining a consistent aesthetic.`,
    img: nftDesignTwo

  },
  {
    id: 3,
    title: 'Technical Implementation',
    para: ` Our skilled developers handle the minting process, ensuring your NFTs are securely deployed on the chosen 
    blockchain. We also create smart contracts with comprehensive features, such as royalties and unlockable content.`,
    img: nftDesignThree

  },
  {
    id: 4,
    title: 'Strategic Rarity Implementation',
    para: ` We carefully incorporate rarity traits and attributes into your NFTs to enhance their 
    desirability and value among collectors.`,
    img: nftDesignFour

  },

  {
    id: 5,
    title: 'Metadata Optimization',
    para: ` We craft detailed and engaging metadata to enhance the discoverability and appeal of your NFTs on marketplaces.`,
    img: nftDesignFive

  },
  {
    id: 6,
    title: 'Marketing and Community Building',
    para: ` We develop strategies to promote your NFTs, build a vibrant community, and generate buzz around your project.`,
    img: nftDesignSix

  }
  , {
    id: 7,
    title: 'Concept Development & Consultation',
    para: ` Our team collaborates closely with you to understand your vision, target market, and 
    overall goals for your NFT project. We provide expert guidance on choosing the optimal blockchain
     platform, NFT type, and pricing strategies.`,
    img: nftDesignSeven
  },
  {
    id: 8,
    title: 'Legal and Compliance Support',
    para: `Our team helps you navigate the complex and evolving legal and regulatory landscape of the NFT space. We ensure your
     NFTs comply with the relevant laws and standards, such as intellectual property rights, taxation, and anti-money laundering.`,
    img: dedicatedThree
  },
  {
    id: 9,
    title: 'Analytics and Optimization ',
    para: `Our team provides you with data-driven insights and recommendations to optimize your NFT
     project performance. We
     monitor and analyze key metrics, such as sales, traffic, engagement, and sentiment, and help you adjust your strategy accordingly.`,
    img: metaSeventeen
  }
]
// const nftDesign8a = [
//   {
//     id: 1,
//     title: 'The Power of NFTs Design with Zai Systems',
//     para: `It's the captivating visual language that breathes life into digital assets, igniting imagination,
//      sparking desire, and ultimately determining an NFT's success. At Zai Systems, we recognize the immense 
//      power of design, meticulously crafting NFTs that transcend mere trends and resonate with collectors on a deeper level.`
//   },
//   {
//     id: 2,
//     title: 'Beyond Aesthetics: The Strategic Value of NFT Design',
//     para: `While an NFT's artistic merit is undeniable, effective design goes far beyond superficial beauty. It's a strategic 
//     tool that unlocks a multitude of benefits for creators, investors, and the entire NFT ecosystem:`
//   }

// ]
// const nftDesign8 = [
//   {
//     id: 1,
//     title: 'Captivating Attention',
//     para: `In a crowded marketplace, eye-catching design is the key to standing out. Our team of skilled artists
//      and designers weave visual magic, infusing NFTs with captivating details, vibrant colors, and unique styles
//       that pique curiosity and compel viewers to engage.`,
//     img: nftDesignEight
//   },
//   {
//     id: 4,
//     title: 'Enhancing Value and Rarity',
//     para: `Strategic design plays a crucial role in establishing an NFT's value and perceived rarity. We incorporate 
//     subtle variations, hidden elements, and unique traits into your NFTs, making them coveted collectibles that stand 
//     out from the masses.`,
//     img: nftDesignEleven

//   },
//   {
//     id: 5,
//     title: 'Unlocking Utility',
//     para: `NFTs transcend mere aesthetics; they can hold real-world utility. We design NFTs that seamlessly integrate
//      with your project's goals, whether it's granting access to exclusive events, unlocking hidden content, or providing
//       voting rights in a DAO.`,
//     img: nftDesignTwelve

//   },
//   {
//     id: 2,
//     title: 'Building Brand Identity',
//     para: `NFTs are more than digital collectibles; they're extensions of personal brands or established 
//     businesses. We translate your brand essence into the NFT's visual language, ensuring 
//     consistency and building a strong visual identity that resonates with your target audience.`,
//     img: nftDesignNine

//   },
//   {
//     id: 3,
//     title: 'Fostering Community',
//     para: `A cohesive and thematically linked NFT collection fosters a sense of community and belonging among collectors.
//      We craft collections that tell a story, evoke emotions, and create a shared experience, binding your NFT holders 
//      together in a passionate community.`,
//     img: nftDesignTen

//   },
//   {
//     id: 6,
//     title: 'Creating Social Impact',
//     para: ` NFTs are a powerful way to raise awareness and funds for social causes. We design NFTs that 
//     align with your mission and values, whether it’s supporting environmental sustainability, human rights, or education. We 
//     also help you leverage the NFT’s potential to create positive change.`,
//     img: digitalEight

//   }
// ]

const nftDesign5a = [
  {
    id: 1,
    title: 'Your One-Stop Shop for Bespoke NFT Design Solutions Across Different Sectors',
    para: `We work closely with you throughout the process, ensuring your vision remains at the heart of every design decision.`,
    para1: `Our specialized NFT design services for:`
  },

]
const nftDesign5 = [
  {
    id: 1,
    title: 'Real Estate',
    para: (
      <>
        <span class='text-white'>Fractionalize ownership:</span>
        Tokenize properties or parts of buildings, enabling democratized investment opportunities.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Showcase luxury listings: </span>Create immersive 3D virtual tours and
        interactive property models that elevate your listings to a whole new level.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>Streamline transactions: </span> Utilize smart contracts to automate
        property transactions, ensuring transparency and security.
      </>
    )
  },
  {
    id: 2,
    title: 'Automotive',
    para: (
      <>
        <span class='text-white'>Revolutionize car ownership: </span>Tokenize car ownership, enabling
        fractional investments and peer-to-peer car sharing.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Design and sell virtual car parts: </span>Mint NFTs
        of stunning car customizations, opening new and exciting revenue streams.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>Enhance brand engagement:</span> Develop interactive NFTs that
        gamify the customer experience and foster community building.
      </>
    )

  },
  {
    id: 3,
    title: 'Infrastructure',
    para: (
      <>
        <span class='text-white'>Tokenize infrastructure projects: </span> Attract investors and facilitate fractional ownership
        of bridges, and other infrastructure assets.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Track project progress: </span>Utilize NFTs to create a transparent and immutable record of project development
        stages, building trust with investors.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>Facilitate maintenance and repairs:</span> Implement NFTs for equipment tracking and maintenance scheduling,
        optimizing operational efficiency.
      </>
    )

  },
  {
    id: 4,
    title: 'Sports Games',
    para: (
      <>
        <span class='text-white'>Tokenize player cards and memorabilia: </span> Create unique NFTs representing iconic moments, athletes, and sports gear,
        engaging fans and generating revenue.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Interactive fan experiences: </span>Develop AR/VR experiences powered by NFTs, allowing fans
        to participate in the game in exciting new ways.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>Decentralized governance: </span>Implement NFTs for voting rights and collaborative decision-making, giving
        fans a voice in the game's future.
      </>
    )

  },
  {
    id: 5,
    title: 'Art & Fashion',
    para: (
      <>
        <span class='text-white'>Elevate fashion experiences:</span> Design interactive NFTs that unlock exclusive content, personalized experiences,
        and behind-the-scenes access for fashion enthusiasts.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Democratize art ownership:</span> Make art accessible to a wider audience through
        fractional ownership of NFTs representing artworks.
      </>
    ),

    para2: (
      <>
        <span class='text-white'>Provenance and authenticity:</span> Utilize NFTs to track the ownership history and authenticate artwork and fashion
        items, ensuring transparency.
      </>
    )

  },
  {
    id: 6,
    title: 'Architecture',
    para: (
      <>
        <span class='text-white'>Tokenize architectural designs: </span>Create NFTs representing iconic buildings,
        architectural plans, or even entire cities, opening new avenues for investment and collaboration.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Interactive architectural models:</span> Develop 3D NFT models that allow users to explore and
        interact with architectural designs in immersive ways.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>Community-driven design:</span> Implement NFTs for collaborative design projects, empowering
        communities to contribute.
      </>
    )

  },
  {
    id: 7,
    title: 'Games',
    para: (
      <>
        <span class='text-white'>In-game assets and collectibles:</span> Design and mint unique NFTs representing virtual items,
        characters, and experiences within games, creating a thriving in-game economy.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Play-to-earn opportunities:</span> Utilize NFTs to reward players for their in-game achievements
        and contributions, fostering a sustainable and engaging gaming ecosystem.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>Decentralized game governance: </span>Implement blockchain-based NFTs for voting rights and decision-making within
        games, giving players democratic control over the game's direction.
      </>
    )

  },
  {
    id: 8,
    title: `Entertainment and Media `,
    para: (
      <>
        <span class='text-white'>Exclusive Content Access:</span> Create NFTs that serve as keys to exclusive content, such as
        early releases, premium episodes, or special editions, enhancing fan engagement and loyalty.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Fan-Driven Storytelling:</span> Enable fans to influence the direction of stories or character
        developments through NFT-based voting systems, creating a participatory narrative experience.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>Virtual Events and Performances:</span> Offer NFT tickets to virtual concerts, meet-and-greets,
        or performances, providing a unique and immersive experience for fans worldwide.
      </>
    )

  },
  {
    id: 9,
    title: `Healthcare and Wellness `,
    para: (
      <>
        <span class='text-white'>Secure Patient Records: </span>Tokenize patient health records to ensure enhanced privacy and robust security,
        giving patients control over who accesses their sensitive information.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Telemedicine Enhancements: </span>Integrate unique NFTs with telemedicine platforms to authenticate and
        secure virtual consultations, prescriptions, and expert medical advice.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>Medical Research Crowdfunding: </span>Use NFTs to represent stakes in medical research projects,
        allowing for transparent and fractional funding by the community.
      </>
    )

  }
]
const nftDesign6a = [
  {
    id: 1,
    title: 'Choosing the Right Software for Your NFT Design Project',
    para: `We understand the importance of finding the perfect tool to bring your vision to life. So, buckle up as 
    we explore some of the most popular NFT design software options, each catering to different skill levels and artistic styles:`
  },

]
const nftDesign6 = [
  {
    id: 1,
    title: 'Adobe Photoshop',
    para: (
      <>
        <span class='text-white'>The industry standard: </span>Photoshop is a powerful image editing software
        favored by professional designers for its extensive toolset and versatility.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Image manipulation and editing: </span>From photorealistic manipulations to intricate
        digital paintings, Photoshop offers endless possibilities for crafting stunning NFT visuals.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>Learning curve: </span>Mastering Photoshop requires dedication and time due to
        its complex interface and array of features.
      </>
    )
  },
  {
    id: 2,
    title: 'Canva',
    para: (
      <>
        <span class='text-white'>User-friendly design platform:</span>
        Canva boasts a drag-and-drop interface and pre-made templates, making it perfect for non-designers.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Simple yet effective:</span> Create eye-catching NFT illustrations, collages, and
        graphics in minutes without needing advanced artistic skills or expensive software.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>Limited customization:</span> While intuitive, Canva's limitations can hinder
        experienced artists seeking complete creative control.
      </>
    )

  },
  {
    id: 3,
    title: 'Adobe Illustrator',
    para: (
      <>
        <span class='text-white'>Vector graphics powerhouse:</span>Illustrator specializes in creating scalable vector graphics,
        ideal for logos, icons, and flat art NFTs.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Sharp and consistent artwork:</span>Vector graphics remain crisp and clear regardless of size,
        ensuring your NFTs look fantastic on any platform.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>Steeper learning curve than Canva:</span>Similar to Photoshop, Illustrator's advanced features
        require dedicated learning and practice.
      </>
    )

  },
  {
    id: 4,
    title: 'NIFTYmoji',
    para: (
      <>
        <span class='text-white'>Specifically designed for NFT creation: </span>
        NIFTYmoji provides pre-made templates and tools specifically
        geared towards generating unique and trendy NFT characters.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Easy-to-use interface: </span>No artistic expertise needed! NIFTYmoji's intuitive interface allows anyone
        to design and customize engaging NFT avatars.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>Limited artistic freedom: </span> NIFTYmoji's focus on pre-made elements
        restricts artistic freedom for experienced creators.
      </>
    )

  },
  {
    id: 5,
    title: '3D Max',
    para: (
      <>
        <span class='text-white'>3D modeling mastery: </span>
        Create stunning 3D models for your NFTs, perfect for
        designing characters, objects, and immersive virtual experiences.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Professional-grade software: </span> 3D Max is favored by animation and game design
        studios for its powerful rendering capabilities and advanced tools.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>Significant learning curve: </span> Mastering 3D modeling in 3D Max requires intensive
        training and a strong understanding of 3D concepts.
      </>
    )

  },
  {
    id: 6,
    title: 'Pixelchain',
    para: (
      <>
        <span class='text-white'>Pixel art perfection: </span>
        Create and design your own charming and nostalgic pixel art NFTs with Pixelchain's dedicated tools and amazing animation features.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Retro vibes and collectibles: </span> Create pixelated characters, landscapes,
        and objects using Pixelchain perfect for retro-inspired nostalgic-themed NFT collections.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>Not for all artistic styles: </span> Pixelchain's specific focus on pixel art
        may not suit creators seeking other artistic styles.
      </>
    )

  },
  {
    id: 7,
    title: 'After Effects',
    para: (
      <>
        <span class='text-white'>Motion graphics magic: </span>
        Bring your NFT designs to life with After Effects' powerful animation and motion graphics capabilities.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Interactive and animated NFTs: </span>
        Create captivating animated avatars, trailers, and promotional materials for your NFT project.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>Complex software: </span>
        After Effects' advanced features require significant learning and experience to utilize effectively.
      </>
    )

  },
  {
    id: 8,
    title: 'Krita',
    para: (
      <>
        <span class='text-white'>Free and open-source alternative: </span> Krita offers a powerful and user-friendly
        experience for digital painting and illustration, free of cost.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Wide range of brushes and tools: </span>Experiment with various brushes, textures,
        and effects to create unique and expressive NFT artwork.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>Community-driven learning: </span>Krita boasts a passionate user community and
        online resources for learning and troubleshooting.
      </>
    )
  },
  {
    id: 9,
    title: 'Inkscape',
    para: (
      <>
        <span class='text-white'>Free vector graphics editor: </span>
        Inkscape is a user-friendly Illustrator alternative for scalable vector graphics and NFTs.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Learning curve less steep than Illustrator: </span>Inkscape's interface and tools are
        accessible for beginners unlike Illustrator's complexity.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>Limited feature set: </span>Inkscape is effective for basic vector graphics, but may
        lack some advanced features of Illustrator.
      </>
    )

  },
  {
    id: 10,
    title: 'Art Weaver',
    para: (
      <>
        <span class='text-white'>Procedural Texture Master: </span>
        Unleash the power of procedural generation to create mesmerizing
        and organic textures for your NFTs. Art Weaver lets you experiment with algorithms and
        filters to craft unique patterns and surfaces.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Beyond Static Images: </span>Breathe life into your NFTs with Art Weaver's animation capabilities.
        Generate mesmerizing animated textures or create hypnotic looping visuals that captivate
        viewers and elevate your project's appeal.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>Learning Curve: </span>Patience is Key: While incredibly powerful, Art Weaver's procedural nature demands
        a different approach. Mastering its tools
        and understanding the underlying algorithms might require some dedication and exploration.
      </>
    )

  },
  {
    id: 11,
    title: 'Medi Bang Paint Pro',
    para: (
      <>
        <span class='text-white'>Free Powerhouse for Manga and Anime Lovers:</span> Unleash your inner manga artist with
        Medi Bang Paint Pro's vast library of brushes, tools, and pre-made assets specifically
        designed for creating anime and manga-style artwork.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Community-Driven and Cloud-Enabled: </span>Immerse yourself in a vibrant artist community
        and collaborate with cloud features. Store your projects online and
        access them from any device, making your creativity mobile.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>Not for All Styles:</span>While perfect for anime and manga aesthetics, Medi Bang Paint Pro's
        focus might not cater to creators seeking other artistic styles. Its interface and tools
        might feel less intuitive for artists accustomed to traditional painting software.
      </>
    )

  },
  {
    id: 12,
    title: 'GIMP',
    para: (
      <>
        <span class='text-white'>Free and Open-Source Champion: </span>
        GIMP stands tall as a powerful and completely free alternative to Photoshop.
        Its extensive toolset covers photo manipulation, painting, and image editing,
        offering impressive capabilities for NFT design.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Customization Galore: </span>Dive into the world of plugins and scripts to tailor
        GIMP to your specific needs. Extend its functionalities and create a personalized
        workspace that reflects your artistic workflow.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>Steep Learning Curve: </span>Navigating GIMP's complex interface and mastering its diverse
        features might require some time and dedication. Online resources and tutorials can be invaluable
        companions on your learning journey, especially for beginners.
      </>
    )

  }
]
// const nftDesign10a = [
//   {
//     id: 1,
//     title: `Our Step-by-Step Workflow for NFT Designing Services`
//   },

// ]
// const nftDesign10 = [
//   {
//     id: '1',
//     imageTwo: concept,
//     head: `Step 1: Concept & Idea Building`,
//     title: (
//       <>
//         <span class='text-white'>Understanding client goals and target audience: </span>
//         Explain how you gather information
//         about the client's vision, desired style, and target market using various methods.
//       </>
//     ),
//     title1: (
//       <>
//         <span class='text-white'>Brainstorming and mood board creation:  </span>The collaborative process of
//         generating ideas and creating visual references to guide the direction of the NFT.
//       </>
//     ),
//     title2: (
//       <>
//         <span class='text-white'>Concept refinement and finalization:  </span>Discuss how you narrow down the options
//         and finalize the core concept for the NFT before moving on to the next step.
//       </>
//     )


//   },
//   {
//     id: '2',
//     imageTwo: SoftwareSelection,
//     head: `Step 2: Software Selection`,
//     title: (
//       <>
//         <span class='text-white'>Technical and Artistic Requirements:</span>
//         Explain how you analyze the concept's complexity & choose the suitable software
//         based on its features and demands.
//       </>
//     ),
//     title1: (
//       <>
//         <span class='text-white'>Discussing alternative options and client input: </span>Highlight the process of proposing
//         different software options and incorporating the client's preferences in the final decision.
//       </>
//     ),
//     title2: (
//       <>
//         <span class='text-white'>Familiarize the client with the software: </span>Briefly explain the chosen software's
//         interface and basic functionalities to prepare the client for the sketching phase.
//       </>
//     )
//   },
//   {
//     id: '3',
//     imageTwo: sketching,
//     head: `Step 3: Sketching`,
//     title: (
//       <>
//         <span class='text-white'>Initial rough sketches and experimentation: </span>Describe the iterative process of creating
//         and refining rough sketches to explore different compositions, poses, and details.
//       </>
//     ),
//     title1: (
//       <>
//         <span class='text-white'>Client feedback and revisions:</span> Explain how you incorporate the client's feedback into
//         the sketches and iterate on the designs until they are satisfied.
//       </>
//     ),
//     title2: (
//       <>
//         <span class='text-white'>Finalizing the sketch for digital execution: </span> Discuss the process of selecting the final
//         sketch that best encapsulates the concept and preparing it for the digital art phase.
//       </>
//     )

//   },

//   {
//     id: '4',
//     imageTwo: finalArt,
//     head: `Step 4: Final Art`,
//     title: (
//       <>
//         <span class='text-white'>Digital sculpting, modeling, or animation: </span>If the concept
//         involves 3D elements, detail the sculpting, modeling, or animation
//         process used to create the digital assets.
//       </>
//     ),
//     title1: (
//       <>
//         <span class='text-white'>Refining details, applying textures, and lighting: </span> Explain how you add finer details,
//         textures, and lighting to the artwork to enhance its visual appeal and depth.
//       </>
//     ),
//     title2: (
//       <>
//         <span class='text-white'>Final adjustments and client approval: </span> Describe the final stage of polishing the artwork,
//         incorporating feedback, and obtaining the client's final approval before NFT minting.
//       </>
//     )

//   },

//   {
//     id: '5',
//     imageTwo: post,
//     head: `Step 5: Post and Asset Management `,
//     title: (
//       <>
//         <span class='text-white'>Quality Assurance:</span> We conduct thorough
//         quality checks on the final NFT artwork, ensuring that it meets all
//         technical specifications and artistic standards.
//       </>
//     ),
//     title1: (
//       <>
//         <span class='text-white'>Metadata Tagging:</span> We add detailed metadata
//         to your NFTs, including descriptions, tags, and attributes, which are crucial
//         for discoverability and provenance.
//       </>
//     ),
//     title2: (
//       <>
//         <span class='text-white'>Asset Storage Solutions:</span> We provide secure, reliable, and efficient storage
//         solutions for your NFTs, ensuring they are safe and retrievable at any time.
//       </>
//     )

//   },

//   {
//     id: '6',
//     imageTwo: minting,
//     head: `Step 6: Minting and Launching `,
//     title: (
//       <>
//         <span class='text-white'>Smart Contract Creation:</span> We write and deploy a smart contract to the blockchain,
//         which governs the rules around the ownership and transferability of your NFT.
//       </>
//     ),
//     title1: (
//       <>
//         <span class='text-white'>Minting Process:</span> We guide you through the minting process, where your digital
//         asset is created as an NFT on the blockchain, ensuring it is unique and tamper-proof.
//       </>
//     ),
//     title2: (
//       <>
//         <span class='text-white'>Launch Strategy:</span> We develop a comprehensive launch strategy to introduce your
//         NFT to the market, including timing, platforms, and promotional activities.
//       </>
//     )

//   }
// ]
const SeoDataDesign = [
  {
    id: 1,
    title: `Blockchain NFT Design Creation - Zai Systems`,
    content: `Craft Captivating NFTs That Sell by our Expert Design Creation Services for the Blockchain. Elevate your digital art & unlock new markets with custom, eye-catching NFTs. Get a free quote & join the crypto craze!`,
    link: `https://www.zaisystems.com/services/nft-design`
  }
]
// community building
const community1 = [
  {
    id: 1,
    title: `Web3 Community Building Services`,
    para: `At Zai Systems we are revolutionizing the way communities thrive in the Web3 space. As blockchain technology continues to reshape industries and redefine digital interactions having a vibrant engaged and loyal community is essential for success. Our Web3 Community Building Services are designed to help your project grow organically foster meaningful connections and establish your brand as a leader in the decentralized ecosystem.`,
    img: community,
  }
]
const community4a = [
  {
    id: 1,
    title: `Why You Need a Thriving Web3 Community?`,
    para: `In the rapidly evolving world of Web3, where projects are driven by decentralized 
    principles and community ownership, one thing is clear: you need a thriving community to succeed.`,
    para1: `But why? It's not just about having a cheering section on the sidelines. A robust Web3 community
     is much more than that. It's the lifeblood of your project, a vibrant ecosystem that fuels growth, 
     innovation, and resilience. Let's delve deeper into the four key reasons why a thriving Web3 community
      is essential for your success:`
  }
]
const community4 = [
  {
    id: 1,
    title: 'Fueling Growth and Adoption',
    para: (
      <>
        <span class='text-white'>Organic user acquisition: </span> Think of your community as a magnet. A passionate
        and engaged group naturally attracts new members through word-of-mouth recommendations, referrals, and shared
        enthusiasm. This organic growth is far more valuable than paid advertising, as it fosters trust and long-term
        commitment.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Increased retention and engagement:</span> Community members become invested in your
        project's success. They participate in governance decisions,  provide feedback, and actively use your platforms
        and services. This high level of engagement translates to lower churn and a loyal user base.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>Brand advocacy and marketing: </span>Your community becomes your biggest cheerleaders.
        They share your story,  amplify your message, and defend your project against negativity. This organic brand
        advocacy is invaluable,  fostering trust and credibility in the wider Web3 space.
      </>
    ),
    img: communityOne
  },
  {
    id: 2,
    title: 'Unlocking Collective Intelligence',
    para: (
      <>
        <span class='text-white'>A hive mind of innovation:</span> Web3 communities are melting pots of diverse
        talents, skills, and experiences. These minds collaborate innovatively.  Hackathons, co-creation initiatives,
        and open-source development  thrive in
        such environments, leading to groundbreaking ideas and solutions.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Real-time feedback and testing:</span> Your community acts as a living feedback
        loop. They'll be the first to point out flaws, suggest improvements, and test new features. This invaluable
        insight allows you to iterate quickly, adapt to changing needs, and build a product that truly resonates
        with your audience.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>Shared knowledge and learning: </span> Community members learn from each other,
        share best practices, and contribute to a growing pool of knowledge. This fosters a culture of
        continuous learning and growth, essential for navigating the ever-changing landscape of Web3.
      </>
    ),
    img: communityTwo
  },
  {
    id: 3,
    title: 'Shaping Narrative & Mitigating Risk',
    para: (
      <>
        <span class='text-white'>Building trust and transparency:</span> In a decentralized world, trust is paramount.
        A strong community allows you to establish open communication channels, build transparency, and address concerns
        head-on. This fosters trust and mitigates potential risks associated with misinformation and negative narratives.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Proactive community management: </span>Your community can be your reliable early warning system,
        identifying potential issues before they rapidly escalate. Through active engagement and conflict resolution, you can nip
        problems in the bud and maintain a positive,  collaborative environment for all members.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>Guiding the conversation and shaping perception: </span>When your community speaks in unison,
        their voice carries significant weight. You can empower your members to be ambassadors of your project, influencing the
        broader conversation and shaping how your brand is perceived in the Web3 world.
      </>
    ),
    img: communityThree
  },
  {
    id: 4,
    title: 'Securing a Sustainable Future',
    para: (
      <>
        <span class='text-white'>Long-term value and resilience: </span>Your community is a lasting asset, fostering
        growth and resilience through participation and service use, ensuring project stability.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Weathering storms and navigating challenges: </span> Community support and innovative
        solutions enhance resilience, fostering success in a dynamic market
      </>
    ),
    para2: (
      <>
        <span class='text-white'>Building a legacy and impact: </span> Your Web3 initiative transcends tech; it’s a
        force for good. An engaged community amplifies this, attracting allies for meaningful change.
      </>
    ),
    img: communityFour
  },
  {
    id: 5,
    title: 'Cultivating a Robust Ecosystem',
    para: (
      <>
        <span class='text-white'>Decentralized Governance:</span> Encourage community participation in decision-making
        processes, fostering a sense of ownership and democracy within the ecosystem.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Tokenomics and Incentives:</span> Design economic models that reward community members
        for their contributions, aligning individual success with the project’s growth.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>Sustainable Development:</span> Promote environmental and social responsibility within
        the community, ensuring the project’s operations contribute positively to the world.
      </>
    ),
    img: communityTwelve
  },
  {
    id: 6,
    title: 'Enhancing User Experience',
    para: (
      <>
        <span class='text-white'>Personalized Interactions:</span> Implement features that
        allow for customized user experiences, making each community member feel valued and heard.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Educational Resources:</span> Provide educational materials and
        workshops to help community members understand Web3 technologies, boosting engagement and contribution.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>User-Centric Design:</span> Continuously refine the platform’s
        design based on community feedback, ensuring an intuitive and accessible user interface.
      </>
    ),
    img: communityThirteen
  }

]
const community8a = [
  {
    id: 1,
    title: `Our Services, Your Blueprint for a Thriving Web3 Community`,
    para: `At Zai Systems, we understand that a successful Web3 community isn't a one-size-fits-all endeavor.
     It's a intricate tapestry woven from careful planning, strategic engagement, and ongoing optimization.
      That's why we offer a comprehensive suite of services that act as your blueprint for building a vibrant 
      and impactful community, tailored to your unique project and goals.`,
  }
]
const community8 = [
  {
    id: 1,
    title: 'Strategy and Planning',
    para: (
      <>
        <span class='text-white'>Unveiling Your Vision:</span> We help you define your community's purpose,  values,
        and long-term goals. What impact do you want to make? Who are you trying to reach? We work closely with you
        to articulate your vision and translate it into a concrete roadmap.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Pinpointing Your Ideal Audience: </span> Understanding your target community is crucial.
        We conduct in-depth research and analysis to identify demographics,  interests, and online behavior, ensuring your
        communication and outreach resonate with the right people.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>Charting Your Engagement Journey: </span> We craft a roadmap to engage your community,
        from onboarding to deep connections and participation. It outlines milestones, communication channels, and interaction expectations.
      </>
    ),
    img: communityFive
  },
  {
    id: 2,
    title: 'Platform Selection',
    para: (
      <>
        <span class='text-white'>Finding Your Digital Home: </span> Discord,  Telegram, DAOs... the Web3 landscape offers a
        variety of platforms. We guide you in selecting the ideal platform that best suits your community's needs, communication
        style, and desired level of decentralization.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Tailoring the User Experience: </span>We help you configure your chosen platform to optimize
        engagement and foster collaboration. This includes setting up channels, roles, bots, and features that encourage interaction,
        information sharing, and a sense of belonging.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>Integrating Seamlessly: </span>We ensure your platform integrates seamlessly with your existing
        tools and services, creating a streamlined and user-friendly experience for your loyal engaged community members
        across different channels.
      </>
    ),
    img: communitySix
  },
  {
    id: 3,
    title: 'Content Creation',
    para: (
      <>
        <span class='text-white'>Weaving Compelling Narratives: </span> Your story matters. We craft narratives capturing
        your project’s essence and resonating with your audience. Whether it's
        through blog posts, social media content, or captivating videos, we help you share your vision.

      </>
    ),
    para1: (
      <>
        <span class='text-white'>Empowering Education: </span>Knowledge is power. We create educational resources tailored to your community's needs, helping them navigate
        Web3 concepts, understand your technology, and actively participate in your project's development with confidence and enthusiasm.

      </>
    ),
    para2: (
      <>
        <span class='text-white'>Sparking Curiosity and Interaction:</span> We create interactive experiences that spark curiosity and encourage participation.
        Think AMAs, Q&A sessions, interactive quizzes, and community-driven content creation initiatives.

      </>
    ),
    img: communitySeven
  },
  {
    id: 4,
    title: 'Community Management',
    para: (
      <>
        <span class='text-white'>Cultivating Authentic Connections: </span>We foster genuine interactions among
        community members, facilitating conversations, building bridges, and creating belonging. This
        means actively listening, addressing concerns, and celebrating successes together.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Maintaining a Safe and Inclusive Space: </span>We establish guidelines and
        implement moderation tools to ensure a safe and inclusive environment for everyone. This includes proactively
        addressing negativity, resolving conflicts, and upholding your community's values.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>Empowering Champions: </span>We identify and cultivate community leaders who can
        contribute to growth and engagement. By providing training and support, we empower your members to become
        ambassadors of your project and take ownership of its success.
      </>
    ),
    img: communityEight
  },
  {
    id: 5,
    title: 'Engagement Tactics',
    para: (
      <>
        <span class='text-white'>Igniting the Spark: </span>From AMAs with industry experts to exciting contests
        and challenges, we implement creative engagement tactics to keep your community buzzing.
        This builds enthusiasm, involvement, and common goals.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Celebrating Achievements: </span>Highlighting milestones and rewarding positive contributions
        is vital. We implement recognition programs and incentive structures that motivate your community members and
        acknowledge their valuable participation.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>Keeping the Conversation Flowing: </span>We utilize tools and strategies
        to keep the conversation flowing, from hosting regular events and discussions to facilitating polls and feedback
        mechanisms. This ensures your community stays engaged and feels heard.
      </>
    ),
    img: communityNine
  },
  {
    id: 6,
    title: 'Growth Marketing',
    para: (
      <>
        <span class='text-white'>Expanding Your Reach: </span>We don't just build a community; we help it grow. We implement
        marketing campaigns to attract new members and expand your reach. This includes social media,
        influencer partnerships, and Web3 collaborations.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Nurturing Brand Awareness: </span>We craft a strong and consistent brand identity and messaging that
        resonates with your target audience. This helps elevate your community's presence within the Web3 space and attract
        like-minded individuals who share your bold vision.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>Optimizing for Future Growth: </span>We analyze data and track key metrics to understand
        what's working and what's not. Based on these insights, we continuously refine your marketing strategies and ensure
        your community continues to attract and engage new members.
      </>
    ),
    img: communityTen
  },
  {
    id: 7,
    title: 'Data & Analytics',
    para: (
      <>
        <span class='text-white'>Measuring What Matters: </span>Data is your friend. We track key metrics like engagement
        rates, member growth, and sentiment analysis to understand your community's health and identify areas for improvement.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Segmenting your audience: </span>Breaking down your community into distinct subgroups based
        on demographics, interests, or activity levels to tailor your engagement strategies and content to specific needs.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>Predictive analytics: </span>Utilizing data to anticipate future trends and proactively
        address potential issues before they arise, ensuring strategic decision-making.
      </>
    ),
    img: communityEleven
  },
  {
    id: 8,
    title: 'Event and Meetup Coordination ',
    para: (
      <>
        <span class='text-white'>Virtual Meetups:</span> Organize regular, inclusive online gatherings where community
        members can actively interact, share innovative ideas, collaborate, and form deeper personal connections.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>In-Person Events: </span>Plan and strategically host meetups, conferences, or workshops that provide
        valuable networking opportunities and actively foster a sense of strong community unity.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>Event Promotion: </span>Utilize social media, community channels, and partnerships
        to promote events and ensure high attendance and engagement.
      </>
    ),
    img: communityFourteen
  },
  {
    id: 9,
    title: 'Data-Driven Community Insights ',
    para: (
      <>
        <span class='text-white'>Community Analytics: </span>We track and analyze key community metrics such as active
        participation, member growth, and engagement patterns to understand the health and dynamics of your community.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Predictive Modeling: </span>We use predictive analytics to forecast community trends
        and behaviors, helping you stay ahead of the curve and make informed decisions about community management and growth.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>Customized Reporting: </span>Receive regular, customized reports that provide a clear
        overview of your community’s performance and highlighting successes.
      </>
    ),
    img: communityFifteen
  }

]
const community5a = [
  {
    id: 1,
    title: 'Partnering with You to Build Your Web3 Community',
    para: `At Zai Systems, we believe building a thriving Web3 community isn't just about providing you with a 
    set of tools and services. It's about forging a genuine partnership, a collaborative journey where we walk 
    alongside you, hand-in-hand, every step of the way. This partnership is built on three core pillars:
    `
  },

]
const community5 = [
  {
    id: 1,
    title: 'A Team of Web3 Enthusiasts, Not Just Experts',
    para: `We're not just a team of seasoned strategists and data crunchers. We're passionate advocates for the future
     of Web3, a vibrant collective that speaks your language, understands the nuances of the decentralized space, and 
     shares your excitement for shaping the future of this revolutionary technology. 
     This passion strengthens our devotion, making us exceed to know your project’s needs and vision.`,
    para1: `Imagine brainstorming engaging community initiatives over late-night blockchain discussions, celebrating milestones
     with virtual high fives in your dedicated Discord channel, and navigating challenges together with a team that truly empathizes
      with your vision. Zai Systems becomes an extension of your own, bringing not just expertise, but genuine enthusiasm.`
  },
  {
    id: 2,
    title: 'Transparency and Collaboration, Not Just Deliverables',
    para: `We believe in fostering an open and collaborative environment where communication flows freely and your voice is always
     heard. Forget about black-box strategies and opaque data reports. We work closely with you every step of the way, demystifying
      the data, sharing insights in easily digestible forms, and actively seeking your input on decisions that impact your loyal and engaged community.`,
    para1: `Think of regular workshops where we co-create content calendars, transparent dashboards that give you real-time visibility
     into community metrics, and open brainstorming sessions where your ideas find fertile ground for exploration. We believe your
      vision is the driving force behind your community, and we provide the tools and expertise to amplify it effectively.`
  },
  {
    id: 3,
    title: `Data-Driven Approach, Not Just Reactive Solutions`,
    para: `The Web3 landscape is ever-evolving, and your community needs to be agile and adaptable to thrive. Zai Systems utilizes a 
    data-driven approach, constantly monitoring key metrics and analyzing engagement patterns. This isn't just about reactive 
    problem-solving. We believe in proactive optimization, anticipating potential challenges and tailoring strategies based on 
    real-time insights.`,
    para1: `Imagine receiving early warnings of potential negativity in your community, identifying niche groups for content
     personalization, and fine-tuning engagement tactics based on member behavior, all before issues arise. Our data-driven 
     approach ensures your community remains vibrant and responsive, constantly evolving alongside the dynamic world of Web3.`
  }
]
const community6a = [
  {
    id: 1,
    title: `Enrich Your Website with Compelling Content`,
    para: `In addition to highlighting your services and partnership principles, consider enriching your website
     with these content elements to showcase your expertise, build trust, and attract potential clients:`
  },

]
const community6 = [
  {
    id: 1,
    title: `Case Studies`,
    para: ` Showcase your success stories! Feature case studies of past projects where you
     built thriving Web3 communities. Highlight challenges, strategies implemented, and
     outcomes. This provides concrete evidence of your capabilities and inspires clients with the
      possibilities for their own communities.`
  },
  {
    id: 2,
    title: `Web3 Community Insights`,
    para: ` Position yourselves as thought leaders in the Web3 space. Publish blog 
    articles on building and engaging Web3 communities. Explore topics like platform selection,
     content creation strategies, engagement tactics, and navigating common challenges. This establishes you as a resource 
     and builds trust with your audience.`
  },
  {
    id: 3,
    title: `Team Expertise `,
    para: `Put a face to the name! Introduce your team members through engaging profiles. Highlight their 
    individual expertise, passion for Web3, and unique contributions to Zai Systems. This fosters a sense of connection with
     potential clients and showcases the diverse skills and perspectives you bring to the table.`
  },
  {
    id: 4,
    title: `Client Testimonials `,
    para: `Let your clients speak for you! Feature positive testimonials from satisfied clients who have 
    benefited from your community building services. Share experiences, highlight the impact of your work, and showcase the 
    positive relationships you build with your partners.`
  },
  {
    id: 5,
    title: `Addressing Concerns `,
    para: `Address common Web3 community building concerns. Create a comprehensive 
    FAQs section on costs, timelines, platform selection, and potential challenges. This demonstrates 
    your transparency, builds trust, and positions you as a knowledgeable partner.`
  },
  {
    id: 6,
    title: `Web3 Community Resources`,
    para: `Provide your audience with valuable and relevant resources to help them learn more about Web3 and its potential.
     Share links to podcasts, videos, books, courses, newsletters, and events that cover topics such as blockchain, smart
      contracts, and cryptocurrencies. `
  }
]

const SeoDataCommunity = [
  {
    id: 1,
    title: `Community Services Building - Zai Systems`,
    content: `Empower Your Workforce and Invest in the Community Services Building. Elevate employee well-being with childcare, health resources, skills development & more. Attract & retain top talent!`,
    link: `https://www.zaisystems.com/services/community-building`
  }
]
// nft
const nft1 = [
  {
    id: 1,
    title: `NFT Token Development Company | Zai Systems`,
    para: `In the rapidly evolving world of blockchain technology, NFTs (Non-Fungible Tokens) have emerged as a groundbreaking way to represent ownership of unique digital assets. At Zai Systems, we specialize in delivering cutting-edge NFT token development services, helping businesses and creators harness the power of this innovative technology. As a leading NFT Token Development Company, we offer end-to-end solutions, from conceptualization to deployment, ensuring that your NFT project is not only successful but also future-proof.`,

    img: nft,
  }
]
const nft2 = [
  {
    id: 1,
    title: 'How NFTs are Powering Innovation and Creativity?',
    para: `As Zai Systems, we're at the forefront of the NFT revolution, witnessing firsthand the incredible potential this 
    technology unlocks. The rise of NFT marketplaces has ignited a digital renaissance, opening doors to entirely new ways
     of experiencing and interacting with art, gaming, and beyond.`
  },

]
const nft4a = [
  {
    id: 1,
    title: `Our NFT Development Services`,
    para: `At Zai Systems, we offer a wide range of NFT development services designed to support every aspect of your NFT project:`
  }

]
const nft4 = [
  {
    id: 1,
    title: 'Concept & Strategy Services',
    para: `Every successful NFT project starts with a solid concept and strategy. Our team works closely with you to understand your goals, target audience, and unique value proposition. We then develop a comprehensive strategy that aligns with your objectives and market trends.`,
    img: nftOne
  },
  {
    id: 2,
    title: 'NFT Art & Design Services',
    para: `Visual appeal is crucial for NFTs. Our talented designers specialize in creating stunning, unique digital artwork tailored to your vision. Whether you're launching a collection of digital art, music, or virtual assets, our NFT art & design services ensure your NFTs stand out in a crowded marketplace.
`,
    img: nftTwo
  },
  {
    id: 3,
    title: 'Smart Contract Development',
    para: `Smart contracts are the backbone of any NFT platform, automating processes such as minting, buying, selling, and royalties’ distribution. We specialize in smart contract development, ensuring they are secure, efficient, and tailored to your project's needs.
`,
    img: nftThree
  },

  {
    id: 4,
    title: 'Minting & Deployment Services',
    para: `Minting is the process of creating NFTs and recording them on the blockchain. At Zai Systems, we offer comprehensive minting & deployment services that cover everything from token creation to blockchain deployment. Our minting process is streamlined and secure, ensuring that your NFTs are ready for the market.
`,
    img: nftFour
  },
  {
    id: 5,
    title: 'NFT Marketplace Development Services',
    para: `An NFT marketplace is essential for buying, selling, and trading NFTs. We offer complete NFT marketplace development services, creating platforms that are user-friendly, secure, and scalable. Our marketplaces support a variety of NFT types, including art, collectibles, gaming assets, and more.
`,
    img: nftFive
  },
  {
    id: 6,
    title: 'Metaverse Integration',
    para: `The metaverse represents the next frontier of digital interaction, where virtual worlds and assets come to life. We provide metaverse integration services that connect your NFTs to popular virtual environments, enhancing their utility and value. Whether it's virtual real estate, gaming, or digital art galleries, we help you tap into the metaverse's immense potential.
`,
    img: nftSix
  },
  {
    id: 7,
    title: 'Marketing & Community Building',
    para: `Successful NFT projects require strong community support and effective marketing. Our marketing & community building services include social media campaigns, influencer partnerships, and community management to build a loyal following around your NFTs. We help you create buzz, engage your audience, and drive sales.
`,
    img: nftSeven
  },
  {
    id: 8,
    title: 'NFT Staking Platform Development',
    para: `Staking is an emerging trend in the NFT space, allowing holders to earn rewards by locking their assets. We develop NFT staking platforms that enable users to stake their NFTs and earn passive income, adding an additional layer of value to your project.
`,
    img: nftEight
  },
  {
    id: 9,
    title: 'NFT Exchange Services',
    para: `In addition to marketplace development, we offer NFT exchange services that facilitate the trading of NFTs between users. Our exchanges are designed for high performance, security, and ease of use, ensuring seamless transactions.
`,
    img: nftNine
  },
  {
    id: 10,
    title: 'Marketplace Design & Development',
    para: `We don't settle for cookie-cutter solutions. Instead, we design and develop custom NFT marketplaces tailored to 
    your specific needs. Imagine intuitive interfaces, frictionless user experiences, and features that perfectly 
    complement your unique vision.`,
    img: nftTen
  },
  {
    id: 11,
    title: 'Data Analytics & Insights Services',
    para: `Understanding the performance of your NFT project is crucial for success. Our data analytics & insights services provide you with detailed reports on user behavior, market trends, and sales performance. This data-driven approach allows you to make informed decisions and optimize your strategy.
`,
    img: nftEleven
  },
  {
    id: 12,
    title: 'Innovation & Future-Proofing Services ',
    para: `The NFT space is constantly evolving, and staying ahead of the curve is essential. Our innovation & future-proofing services ensure that your NFT project remains relevant and competitive. We continuously monitor the latest trends and technologies, implementing updates and improvements as needed.
`,
    img: nftTwelve
  }

]
const nfttestimonials = [
  {
    id: 1,
    para: `Zai Systems helped me bring my digital art to life with their NFT development services. Their expertise in blockchain and attention to detail impressed me. The NFT marketplace they built is secure and user-friendly, which has boosted my art sales significantly.`,
    name: "Samantha Green",
    title: "Digital Artist"
  },
  {
    id: 2,
    para: `Zai Systems transformed our virtual real estate platform with cutting-edge metaverse integration. Their innovative approach and dedication helped us unlock new opportunities in this rapidly growing space. Their team’s knowledge is exceptional.`,
    name: "Robert Langston",
    title: "Virtual Real Estate Developer"
  },
  {
    id: 3,
    para: `The NFT marketplace developed by Zai Systems for our gaming platform has been a massive success. It is easy to use, highly secure, and seamlessly integrates with our game’s economy, enhancing user engagement.`,
    name: "Emily Watson",
    title: "Game Development Lead"
  }
];

const nftServiceFAQs = [
  { questions: 'What is an NFT?', answers: 'An NFT (Non-Fungible Token) is a unique digital asset that represents ownership of a specific item, such as digital art, music, or virtual real estate, on the blockchain.' },
  { questions: 'How do NFTs work?', answers: 'NFTs are created using blockchain technology, which ensures their uniqueness and ownership. Each NFT is associated with a smart contract that governs its transfer, sale, and other functions.' },
  { questions: 'How can Zai Systems help with NFT development?', answers: 'Zai Systems offers end-to-end NFT development services, including concept development, smart contract creation, marketplace development, and marketing. We tailor our services to meet your specific needs.' },
  { questions: 'What is a smart contract?', answers: 'A smart contract is a self-executing contract with the terms of the agreement directly written into code. In the context of NFTs, smart contracts manage the creation, transfer, and sale of tokens.' },
  { questions: 'What is the metaverse?', answers: 'The metaverse is a collective virtual shared space that includes virtual reality, augmented reality, and other digital experiences. NFTs play a crucial role in the metaverse by representing ownership of virtual assets.' },
  { questions: 'What are the benefits of using NFTs for digital assets?', answers: 'NFTs offer numerous benefits, including provable ownership, scarcity, and the ability to monetize digital creations. They enable creators to sell their work directly to buyers, bypassing intermediaries and retaining more profits.' },
  { questions: 'What is NFT staking, and how can it benefit my project?', answers: 'NFT staking allows holders to lock up their NFTs in a platform to earn rewards or generate passive income. This feature can increase user engagement and provide additional value to your NFTs, making your project more attractive to collectors.' },
  { questions: 'What is the difference between an NFT marketplace and an NFT exchange?', answers: 'An NFT marketplace is a platform where users can buy, sell, and trade NFTs, often focused on specific types of digital assets. An NFT exchange, on the other hand, is a broader platform that allows for the trading of NFTs between users, often offering more sophisticated trading features and tools.' },
  { questions: 'Can Zai Systems integrate NFTs with existing platforms or applications?', answers: 'Yes, Zai Systems specializes in integrating NFTs with existing platforms, such as e-commerce websites, social media platforms, or gaming environments. This allows you to leverage NFTs within your current ecosystem, enhancing your offerings without starting from scratch.' },
  { questions: 'What is NFT minting, and why is it important?', answers: `NFT minting is the process of creating a unique digital asset and recording it on the blockchain. It is crucial because it establishes the asset's ownership, authenticity, and scarcity, making it possible to buy, sell, and trade the asset securely.` },
  { questions: 'How can NFTs be used in the gaming industry?', answers: `In the gaming industry, NFTs can represent in-game assets such as characters, skins, weapons, or virtual land. Players can own, trade, and monetize these assets, creating new opportunities for engagement and revenue within games.` },
  { questions: '16. How long does it take to develop an NFT project with Zai Systems?', answers: `The timeline for developing an NFT project with Zai Systems depends on the complexity and scope of the project. After an initial consultation, we provide a detailed project plan with milestones and a timeline to ensure timely delivery of your NFT solution.` },
];
const nft8a = [
  {
    id: 1,
    title: `Why Choose Zai Systems for NFT Token Development?`,
    para: `Zai Systems stands out as a premier NFT Token Development Company because of our commitment to innovation, security, and customer satisfaction. Whether you're an artist, a business, or an entrepreneur, our tailored NFT development services cater to your unique needs. Here's why you should partner with us:`,
    img: nftThirteen
  }


]
const nft8 = [
  {
    id: 1,
    title: `•	Expertise in Blockchain Technology`,
    para: `Our team comprises seasoned blockchain developers who have extensive experience in creating secure and scalable NFT platforms.`,
    img: nftBOne

  },
  {
    id: 2,
    title: `•	Customized Solutions`,
    para: `We understand that every project is unique, and we offer customized NFT solutions to meet your specific requirements.`,
    img: nftBTwo

  },
  {
    id: 3,
    title: `•	Comprehensive Services`,
    para: ` From NFT marketplace development to smart contract development, we provide a full suite of services to bring your vision to life.
`,
    img: nftBFour

  },
  {
    id: 4,
    title: `•	Security First`,
    para: `We prioritize security at every step, ensuring that your NFTs and associated transactions are protected against vulnerabilities.
`,
    img: nftBThree

  }
]
const nft6a = [
  {
    id: 1,
    title: 'Zai Systems: Building Thriving NFT Marketplaces',
    para: `At Zai Systems, we understand the transformative power of NFTs. But it's not just about creating unique tokens,
     it's about building entire ecosystems where these tokens can thrive. That's why we specialize in NFT Marketplace Development
      Services, tailoring platforms that meet the specific needs of your industry and community.`,
    para1: `Here's what sets Zai Systems' NFT Marketplaces apart:`
  },

]
const nft6 = [
  {
    id: 1,
    title: 'Uniquely Yours',
    para: ` We design marketplaces that showcase the uniqueness of NFTs. Every aspect, from the user interface to listing, 
    is crafted to highlight the individuality and value of each token.`
  },
  {
    id: 2,
    title: 'Seamless Transactions',
    para: `Security and trust are paramount. Our marketplaces leverage blockchain technology to ensure secure transactions,
     transparent ownership records, and complete traceability.`

  },
  {
    id: 3,
    title: 'Tailored Functionality',
    para: `We don't believe in one-size-fits-all solutions. Whether it's art, music, gaming, or any other industry, we build
     marketplaces with features specifically designed for your unique needs.`
  },
  {
    id: 4,
    title: 'Community Focus',
    para: `Fostering a vibrant community is key. We integrate social features, communication tools, and reputation systems
     to create a space where collectors, creators, and enthusiasts can connect and engage.`
  },
  {
    id: 5,
    title: 'Monetization Power',
    para: `We understand the importance of earning potential. Our marketplaces offer flexible revenue streams for 
    creators and sellers, including listing fees, royalties, and secondary market transactions.`
  },
  {
    id: 6,
    title: 'Scalability for Growth',
    para: `As your community and user base expand, your marketplace should too. We build platforms with scalability 
    in mind, ensuring they can adapt and evolve alongside your needs.`
  },
  {
    id: 7,
    title: 'Beyond the Basics',
    para: `We go beyond the core functionalities. Our team is constantly innovating, integrating cutting-edge features
     like fractional ownership, DAO governance, and gamified experiences to keep your marketplace ahead of the curve.`
  },
  {
    id: 8,
    title: 'Interoperability and Compatibility',
    para: `We ensure that your NFT marketplace is compatible with different blockchain networks, standards, and protocols.
     We also enable cross-chain interoperability, allowing users to access and trade NFTs from various platforms.`
  },
  {
    id: 9,
    title: 'User-Friendly and Intuitive',
    para: `We create marketplaces that are easy to use and navigate for both creators and collectors. We provide user-friendly
     interfaces, intuitive features, and clear guidance to make the NFT creation and trading process smooth and enjoyable.`
  }
]
const nft13a = [
  {
    id: 1,
    title: 'Your Gateway to the Booming NFT Marketplace Frontier',
    para: `Craft Your Vision, Mint the Future – Bespoke NFT Marketplaces Await`,

  },

]
const nft13 = [
  {
    id: 1,
    title: 'Fast Track Your NFT Dreams with OpenSea & Rarible Clones',
    para: `Launching an NFT marketplace can feel daunting, but Zai Systems' OpenSea and Rarible clones offer a rapid and 
    cost-effective solution. Imagine having a platform up and running in no time, equipped with familiar features 
    that collectors and creators already love. This minimizes the learning curve and lets you tap into an existing
    user base, giving you a head start in the competitive NFT arena. Think of it as building on a proven foundation.
     You can easily adapt and expand as your community grows, all while enjoying a budget-friendly
      entry point that frees up resources for marketing and community building.`,
  },
  {
    id: 2,
    title: 'Craft Your NFT Masterpiece with Bespoke Development',
    para: `Zai Systems’ custom development service is a canvas for your unique marketplace that reflects your vision. 
    We help you unleash your NFT dreams, crafting a platform tailored to your specific niche and target audience.
     Imagine integrating features that perfectly cater to your community, whether it’s fractional ownership for high-value 
     collectibles, advanced search filters for niche art collectors, or even community governance mechanisms for a fully 
     decentralized experience. Our skilled developers bring your vision to life, building a brand differentiator that sets 
     your marketplace apart and attracts users.`,

  },
  {
    id: 3,
    title: 'Content that Captivates and Converts',
    para: `Zai Systems' skilled 
    copywriters understand the power of words. We craft narratives that paint a vivid picture of your marketplace's 
    potential, highlighting the value proposition that makes it irresistible to collectors and creators alike. But it's 
    not just about grand narratives. We tailor our messaging to resonate with your ideal users, ensuring every word speaks 
    directly to their needs and desires. We optimize your website for search engines, so potential customers can easily find
     you amidst the NFT landscape. And, to top it off, we provide clear instructions and tutorials that make navigating your
      platform a breeze, keeping users engaged and satisfied.`,
  },
  {
    id: 4,
    title: 'NFT Ecosystem: Beyond Basics',
    para: `Zai Systems doesn't just build marketplaces, we build thriving ecosystems. We prioritize robust security measures and 
    adherence to relevant regulations, ensuring your users' trust and peace of mind. Our expert developers craft secure and 
    efficient smart contracts to power your transactions. And we don't abandon you after launch. We provide ongoing 
    support and maintenance to keep your platform running smoothly and securely, while data analytics tools empower you to make 
    data-driven decisions for continued growth.`,
  },
  {
    id: 5,
    title: 'Marketing and Community Building',
    para: ` 
    Zai Systems helps you spread the word about your NFT marketplace, attracting and retaining users with
    effective marketing strategies. We create engaging content and campaigns that showcase your unique 
    selling points, generate buzz, and drive traffic to your platform. We also help you build a loyal 
    and active community, fostering a sense of belonging and ownership among your users. We leverage social
     media, forums, newsletters, and other channels to communicate with your audience, solicit feedback,
      and reward participation.
    `,
  },
  {
    id: 6,
    title: 'Innovation and Future-Proofing',
    para: `Zai Systems keeps you ahead of the curve, ensuring your NFT marketplace stays relevant and competitive 
    in the fast-changing NFT space. We monitor the latest trends and developments, identifying new opportunities 
    and challenges for your platform. We also leverage cutting-edge technologies and features, such as AI, VR, AR, 
    and 3D, to enhance your user experience and offer new possibilities for your NFTs. We help you innovate and 
    future-proof your NFT marketplace, ensuring it remains a leader in the NFT frontier. Join us today and discover the power of NFTs.
   `,
  }
]
const nft10a = [
  {
    id: 1,
    title: `Zai Systems: Transforming Ideas into Blockchains with Vision and Precision`,
    para: `At Zai Systems, we believe in empowering creators and collectors alike. We harness the power of blockchain technology 
    to forge a path towards innovative NFT experiences, driven by meticulous planning and unwavering dedication. Our approach 
    culminates in a four-step roadmap designed to translate your vision into digital reality:`
  },

]
const nft10 = [
  {
    id: '1',
    imageTwo: nftb1,
    title: `The journey begins with introspection. We collaborate with you to meticulously understand your unique vision, the heart
     and soul of your NFT project. What story do you want to tell? What value will your NFTs offer? We delve deep into your goals, 
     target audience, and the potential utilities you envision for your creations. This collaborative excavation lays the foundation
      for a project that resonates with both purpose and passion.`,
    head: `Step 1: Project Discovery`
  },
  {
    id: '2',
    imageTwo: nftb2,
    title: `With unearthed treasures in hand, the canvas comes alive. Our skilled design team translates your vision into captivating
     visuals. We craft distinctive aesthetics, develop unique traits for your NFT collection, and meticulously craft a user interface
     that seamlessly guides collectors through their journey. From elegant minimalist lines to bold, immersive experiences, we ensure 
     your NFTs radiate your vision with captivating clarity.`,
    head: `Step 2: Design`

  },
  {
    id: '3',
    imageTwo: nftb3,
    title: `Security, functionality, and scalability are the cornerstones of our development process. We leverage cutting-edge
     blockchain technology to build secure and scalable smart contracts, the engines that power your NFT ecosystem. From minting
      to trading, every interaction is seamless, secure, transparent, efficient, and trustworthy, ensuring your collectors experience the confidence
       and freedom that blockchain promises.`,
    head: `Step 3: Development`

  },

  {
    id: '4',
    imageTwo: nftb4,
    title: `The moment arrives! We launch your NFT project with strategic precision, ensuring a smooth
     and impactful entry into the digital arena. We optimize
     your website, implement marketing strategies, and foster a community around your collection. Your 
     vision, now a masterpiece, evolves through support and engagement.`,
    head: `Step 4: Deployment`
  },

  {
    id: '5',
    imageTwo: nftb5,
    title: `We don’t just launch your NFT project and leave you hanging. We provide ongoing maintenance and 
    support to ensure your platform runs smoothly and securely. We monitor the performance, security, and 
    availability of your smart contracts, website, and marketplace. We also provide bug fixes, updates, and 
    enhancements as needed.`,
    head: `Step 5: Maintenance`
  },

  {
    id: '6',
    imageTwo: nftb6,
    title: `We are always looking for ways to improve and innovate your NFT project. We keep up with the latest trends,
     technologies, and standards. We explore new possibilities and opportunities for
      your collection, such as cross-chain, fractional, gamification, etc. We listen
       to your feedback and suggestions, and implement them.`,
    head: `Step 6: Innovation`
  }
]
const SeoDataNft = [
  {
    id: 1,
    title: `NFT Development Services - Zai Systems`,
    content: `Turn Your Passion into Profit with our Powerful NFT Development Services for Market Success. Boost engagement, build communities, & unlock new revenue streams. We bring your NFTs to life, from concept to marketplace.`,
    link: `https://www.zaisystems.com/services/nft`
  }
]
// metaverse
const metaverse1 = [
  {
    id: 1,
    title: `Metaverse Consulting Services`,
    para: `Our team of passionate developers and designers are in continuous effort to provide Metaverse Consulting Services, 
      and exploring new frontiers. We don't believe in one-size-fits-all. We work closely with you
      to understand your unique vision and tailor each Metaverse project to your specific needs and goals. From 
      conceptualization and design to development, deployment, and ongoing maintenance, we handle every aspect of 
      your Metaverse journey. We utilize the latest VR/AR tools, blockchain solutions, and spatial computing advancements
       to create truly groundbreaking experiences. We build with scalability and adaptability in mind, ensuring your
        Metaverse investment stays relevant as the technology evolves.`,
    img: metaverse,
  }
]

const metaverse4a = [
  {
    id: 1,
    title: `Dive into the Metaverse with Our Comprehensive Development Services`,
    para: `The Metaverse – a vast, interconnected network of 3D virtual worlds – holds immense potential for businesses and
     individuals alike. But navigating this uncharted territory can be daunting. That's where we come in. Our suite of 
     Metaverse development services equips you with everything you need to build a thriving virtual presence, from crafting 
     a winning strategy to designing captivating experiences.`
  },

]
const metaverse4 = [
  {
    id: 1,
    title: `Metaverse Strategy and Consulting`,
    para: `We become your Metaverse sherpa, guiding you through the evolving landscape. Our experts analyze your industry,
      audience, and goals to tailor a roadmap for success. We identify the most promising opportunities, whether
      it's hosting virtual events, creating interactive brand experiences, or launching NFT-based communities.`,
    img: metaTen
  },
  {
    id: 2,
    title: `3D World Design and Development`,
    para: `Imagine stepping into a breathtaking virtual world brimming with life and possibilities. We bring your vision to life, 
    crafting stunning 3D environments that capture your brand essence and resonate with your users. From lush landscapes to 
    bustling cityscapes, we build immersive spaces that draw users in and keep them coming back for more.`,
    img: metaEleven
  },
  {
    id: 3,
    title: `Avatar Design and Development`,
    para: `Let your users express themselves in the Metaverse with custom-designed avatars. We create avatars that mirror your brand 
    identity or offer endless personalization options, allowing users to shape their virtual persona. Whether it's sleek robots, 
    quirky cartoon characters, or photorealistic humans, we breathe life into digital identities.`,
    img: metaTwelve
  },
  {
    id: 4,
    title: `VR/AR Development`,
    para: `Blur the lines between reality and the virtual world with our expertise in VR and AR development. We integrate these 
    technologies seamlessly into your Metaverse, creating experiences that push the boundaries of immersion. Users can 
    attend virtual concerts, collaborate on projects in shared spaces, or explore augmented versions of the real world.`,
    img: metaThirteen
  },
  {
    id: 5,
    title: `Game Development`,
    para: `Infuse your Metaverse with engagement and excitement through captivating games and gamified experiences. We design games 
    that not only entertain, but also drive user interaction and promote desired behaviors. Whether it's competitive challenges, 
    collaborative quests, or educational minigames, we weave gameplay seamlessly into the fabric of your virtual world.`,
    img: metaFourteen
  },
  {
    id: 6,
    title: `NFT Integration`,
    para: `We can help you unlock new revenue streams and foster a sense of community ownership with NFT integration. Our 
    services include developing and implementing effective NFT strategies, creating unique virtual assets, building an NFT
     marketplace, and leveraging blockchain technology for secure transactions and providing expert consultation to ensure
      your success.`,
    img: metaFifteen
  },
  {
    id: 7,
    title: `Security and Privacy`,
    para: `User trust is paramount in the Metaverse. We prioritize data security and privacy, building your virtual world with robust
     security measures and transparent data practices. This ensures a safe and trustworthy space for users to interact, socialize,
      and conduct business.`,
    img: privateLogo
  },
  {
    id: 8,
    title: `UI/UX Design`,
    para: `We design intuitive and engaging user interfaces for the Metaverse. We apply user-centric design principles, ensuring 
    your virtual world is navigable, accessible, and pleasing. We also conduct user testing and feedback, ensuring your interface
     meets user needs.`,
    img: metaSixteen
  },
  {
    id: 9,
    title: `Analytics and Optimization `,
    para: `We optimize your Metaverse presence with data insights and best practices. We
     analyze metrics, such as user behavior, engagement, retention, and revenue. We also provide smart
      recommendations and solutions, ensuring your Metaverse is optimized for maximum impact.`,
    img: metaSeventeen
  }
]
const metaverse8a = [
  {
    id: 1,
    title: `Our Metaverse Consulting Services Across Industries`,
    para: `The metaverse – a realm of boundless possibilities – lies at the frontier of innovation, reshaping how we
     interact with the world around us. At Zai Systems, we're spearheading the charge, harnessing the metaverse's potential 
     to revolutionize various industries.`
  },

]
const metaverse8 = [
  {
    id: 1,
    title: `Gaming`,
    para: `Step into a new era where your NFT assets, like swords and armor, transcend pixels. Zai Systems creates metaverse solutions enhancing ownership and personalization.`,
    img: metaOne
  },
  {
    id: 8,
    title: 'Social Media & Entertainment',
    para: `Discover a metaverse where virtual concerts, global friendships, and gatherings thrive. Zai Systems builds platforms blending virtual and real-world interactions seamlessly`,
    img: metaEight

  },
  {
    id: 9,
    title: 'Hospitality ',
    para: `Transform hospitality with immersive metaverse solutions. Zai Systems enables guests to explore exotic locations, enjoy fine dining, and engage in unique activities at home.`,
    img: metaNine

  },
  {
    id: 2,
    title: 'Events ',
    para: `Host virtual events like conferences, concerts, and exhibitions globally. Zai Systems crafts engaging metaverse solutions ensuring real-time, borderless connections.`,
    img: metaTwo
  },
  {
    id: 3,
    title: 'Education & Learning ',
    para: `Education enters a new era with Zai Systems. Students explore landmarks, collaborate globally, and engage with interactive learning environments seamlessly.`,
    img: metaThree
  },
  {
    id: 5,
    title: `Real Estate`,
    para: `Invest in metaverse real estate with Zai Systems. Acquire digital properties, build dream spaces, and explore limitless opportunities for growth and ownership.`,
    img: metaFive
  },
  {
    id: 4,
    title: 'Software Development',
    para: `Zai Systems delivers cutting-edge software solutions. From blockchain systems to integrations, we empower businesses with scalable, metaverse-ready platforms.
     `,
    img: metaFour
  },

  {
    id: 6,
    title: 'Healthcare ',
    para: `Zai Systems redefines healthcare with AI avatars, immersive consultations, and advanced monitoring. Enjoy real-time, personalized virtual wellness experiences today.`,
    img: metaSix
  },
  {
    id: 7,
    title: 'Retail ',
    para: `Zai Systems partners with brands like Gucci and Adidas in the metaverse. Virtual try-ons, showrooms, and seamless shopping redefine the future of retail.`,
    img: metaSeven
  }

]
const metaverse5a = [
  {
    id: 1,
    title: `Why Choose Zai Systems for Your NFT Development Journey?`,
    para: `Zai Systems isn't just any Metaverse development company – they're experts in crafting high-quality NFTs from 
    concept to deployment. Here's what sets them apart:`,
  },

]
const metaverse5 = [

  {
    id: 1,
    title: `Building the Future of NFTs`,
    para: `Zai Systems is pioneering a new infrastructure that empowers anyone, regardless of coding experience, to build secure, 
    scalable, and flexible NFT applications. This opens doors for greater accessibility and innovation in the NFT space.`,

  },
  {
    id: 2,
    title: `Confidentiality Guaranteed`,
    para: `Zai Systems prioritizes your privacy and signs NDAs to protect your unique ideas. They have a 
    comprehensive privacy policy that outlines their commitment to protecting personal information provided 
    to them, offline or online.`,

  },
  {
    id: 3,
    title: `Expert Developers at the Helm `,
    para: `Their team boasts seasoned blockchain developers who intimately understand the intricacies of NFTs and Metaverse development.
     They're well-versed in various blockchain protocols, ready to tackle any technical challenge.`,

  },
  {
    id: 4,
    title: `Agile for Success`,
    para: `Their team employs an iterative development process, ensuring your NFT project evolves based on continuous feedback 
    and testing. This flexible approach minimizes risks and maximizes your chances of a thriving NFT launch.`,

  },
  {
    id: 5,
    title: `Creative and Customized`,
    para: `Zai Systems doesn’t use cookie-cutter solutions. They tailor their NFT services to industry, audience,
     and goals. They create NFTs that stand out and resonate with your users, whether it’s art, music, collectibles, or anything else.`,
  },
  {
    id: 6,
    title: `Support and Maintenance `,
    para: `Zai Systems doesn’t leave you hanging after your NFT launch. They maintain and secure them, updating you on NFT trends and developments.
     They also keep you updated on the latest trends and developments in the NFT space.`,
  }
]
const SeoDataMetaverse = [
  {
    id: 1,
    title: `Metaverse Development Services`,
    content: `Ready to conquer the Metaverse? Get a free consultation with our Metaverse development experts!`,
    link: `https://www.zaisystems.com/services/metaverse`
  }
]
// blockchain development
const blockchainDev1 = [
  {
    id: 1,
    title: `Blockchain Development Services | Zai Systems`,
    para: `As a leading blockchain development company, we offer comprehensive solutions that cater to various needs, from blockchain app development to enterprise blockchain solutions. Explore how our expertise can drive innovation and growth for your business.`,
    img: blockchainDevelopment,
  }
]

const blockchainDev2 = [
  {
    id: 1,
    // title: `Zai Systems' End-to-End Blockchain Development Services`,
    // para: `At Zai Systems, we understand the transformative power of blockchain technology. Our comprehensive
    //  suite of Enterprise Blockchain Development Services empowers businesses of all sizes.`,
    // para1: `Our team of seasoned blockchain developers possesses the knowledge and skill to guide you through 
    //  every step of your blockchain journey. We offer the full spectrum of services, from initial consulting
    //   and strategic planning to development, integration, testing, and ongoing maintenance.`
  },

]
const blockchainDev4a = [
  {
    id: 1,
    title: `Comprehensive Blockchain Development Services`,
  },

]
const blockchainDev4 = [
  {
    id: 1,
    title: 'Blockchain App Development',
    para: `The demand for blockchain app development is skyrocketing as businesses recognize the benefits of decentralization, transparency, and security. At Zai Systems, our team specializes in creating customized blockchain applications tailored to your specific needs. Our development process includes:`,
    list: [
      {
        id: 1,
        title: '•	Requirement Analysis',
        para: "We begin by understanding your business goals and the problem you're aiming to solve with blockchain technology."
      },
      {
        id: 2,
        title: '•	Design & Development',
        para: ' Our developers create intuitive user interfaces and robust backend systems, ensuring your app is both functional and user-friendly.'
      },
      {
        id: 3,
        title: '•	Testing & Deployment',
        para: ' Rigorous testing ensures that your blockchain app performs seamlessly. We manage the deployment process, ensuring a smooth launch.'
      },
      {
        id: 4,
        title: '•	Support & Maintenance',
        para: ' Post-launch, we offer ongoing support and maintenance to keep your app running smoothly and efficiently.'
      },
    ],
    img: blockchainDevOne
  },

  {
    id: 2,
    title: 'Blockchain Software Development',
    para: `Blockchain software development is crucial for businesses looking to integrate blockchain technology into their operations. Our services cover the entire development lifecycle:`,
    list: [
      {
        id: 1,
        title: '•	Custom Software Solutions',
        para: "Tailored to meet your unique business requirements, our solutions ensure seamless integration with existing systems."
      },
      {
        id: 2,
        title: '•	Security & Compliance',
        para: 'We implement robust security measures to protect your data and ensure compliance with industry regulations.'
      },
      {
        id: 3,
        title: '•	Scalability & Performance',
        para: 'Our software solutions are designed to scale with your business and perform efficiently under varying loads.'
      }
    ],
    img: blockchainDevFive
  },
  {
    id: 3,
    title: 'Enterprise Blockchain Solutions',
    para: `Large organizations face unique challenges when adopting blockchain technology. Our enterprise blockchain solutions are designed to address these challenges and deliver tangible business benefits. Key features include:`,
    list: [
      {
        id: 1,
        title: '•	Private Blockchains',
        para: " For enhanced privacy and control, we develop private blockchain networks that restrict access to authorized participants only."
      },
      {
        id: 2,
        title: '•	Interoperability',
        para: 'We ensure seamless integration with existing enterprise systems, facilitating smooth data exchange and operational efficiency.'
      },
      {
        id: 3,
        title: '•	Smart Contract Integration',
        para: ' Automate complex business processes with smart contracts that execute predefined rules without manual intervention.'
      }
    ],
    img: blockchainDevThree
  },
  {
    id: 4,
    title: 'Blockchain Technology Services',
    para: `Our blockchain technology services encompass a wide range of solutions, from foundational technology consulting to advanced development projects. We offer:`,
    list: [
      {
        id: 1,
        title: '•	Technology Assessment',
        para: " Evaluate the suitability of blockchain technology for your specific use case."
      },
      {
        id: 2,
        title: '•	Architecture Design',
        para: ' Design a robust blockchain architecture that aligns with your business objectives and technical requirements.'
      },
      {
        id: 3,
        title: '•	Implementation & Integration',
        para: ' Implement and integrate blockchain solutions with your existing technology stack.'
      }
    ],
    img: exchangeLogo
  }
]
const blockchainDev10a = [
  {
    id: 1,
    title: `Craft Your Blockchain Future with Zai Systems' Proven Development Process`,
    para: `At Zai Systems, we believe blockchain isn't just a buzzword - it's a powerful tool for building efficient, transparent, and 
    secure solutions that revolutionize businesses. But how do we translate your vision into reality? Our meticulous 4-step process ensures 
    a smooth journey from concept to execution:`
  },

]
const blockchainDev10 = [
  {
    id: '1',
    imageTwo: blockd1,
    title: `We dive deep into your needs and challenges. Our expert analysts closely collaborate with you to understand your 
    goals, industry landscape, and existing infrastructure. This comprehensive evaluation forms the solid foundation for your
     customized blockchain solution.`,
    head: `Step 1: Requirement Evaluation`
  },
  {
    id: '2',
    imageTwo: blockd2,
    title: `With a clear understanding of your requirements, our architects craft a solution. We explore the most suitable blockchain 
    platforms, consensus mechanisms, and smart contract functionalities. You'll be involved in this process, ensuring your 
    vision drives every design decision.`,
    head: `Step 2: Solution Design`

  },
  {
    id: '3',
    imageTwo: blockd3,
    title: `Our expert developers bring your blueprint to life. Utilizing cutting-edge blockchain technologies and development frameworks, we 
    build secure, scalable, and user-friendly applications. Rigorous testing and quality checks guarantee seamless integration with your existing
     systems.`,
    head: `Step 3: Building & Implementation`

  },
  {
    id: '4',
    imageTwo: blockd4,
    title: `We don't believe in just delivering and disappearing. Our dedicated team provides ongoing support to ensure your blockchain solution 
    thrives. We monitor performance, address updates, and offer strategic guidance to help you adapt to the ever-evolving blockchain landscape.`,
    head: `Step 4: Maintenance & Evolution`
  },
  {
    id: '5',
    imageTwo: blockd5,
    title: `We don’t just build blockchain solutions, we empower you to use them. Our experienced trainers provide education and guidance to
     help you master the blockchain technology. We cover everything from the basics to the best practices, ensuring you can manage and operate your blockchain solution.
   `,
    head: `Step 5: Training & Education`
  },
  {
    id: '6',
    imageTwo: blockd6,
    title: `
    We value your feedback and satisfaction. Our quality assurance team evaluates and surveys your blockchain solution’s impact and effectiveness.
     We collect and analyze your feedback
    to improve and optimize our blockchain services. We strive to exceed
       your expectations and deliver excellence.
   `,
    head: `Step 6: Feedback & Evaluation`
  }
]
const blockchainDev11a = [
  {
    id: 1,
    title: 'Blockchain Consulting Services',
    para: 'Navigating the blockchain landscape can be complex. Our blockchain consulting services provide expert guidance to help you make informed decisions. Our consulting process includes:',
    img: blockchainDevSeven
  }
]
const blockchainDev11 = [
  {
    list: [
      {
        id: 1,
        title: '•	Strategic Planning',
        text: ' Develop a strategic roadmap for blockchain adoption tailored to your business goals.'
      },
      {
        id: 2,
        title: '•	Feasibility Study',
        text: ' Assess the feasibility of blockchain solutions for your specific use case.'
      },
      {
        id: 3,
        title: '•	Proof of Concept',
        text: 'Create a proof of concept to demonstrate the potential benefits and functionality of proposed blockchain solutions.'
      },
    ]
  }
]
const blockchainDev12a = [
  {
    id: 1,
    title: 'Smart Contract Development Services',
    para: 'Smart contract development services are crucial for automating transactions and business processes on the blockchain. Our expertise includes:',
    img: blockchainDevEight
  }
]
const blockchainDev12 = [
  {
    list: [
      {
        id: 1,
        title: '•	Contract Design',
        text: ' Develop smart contracts that accurately reflect your business logic and requirements.'
      },
      {
        id: 2,
        title: '•	Security Audits',
        text: 'Conduct thorough security audits to identify and mitigate potential vulnerabilities.'
      },
      {
        id: 3,
        title: '•	Deployment & Management',
        text: ' Deploy smart contracts on the blockchain and manage their lifecycle to ensure optimal performance.'
      },
    ]
  }
]
const blockchainDev13a = [
  {
    id: 1,
    title: 'DeFi Development Services',
    para: 'Decentralized Finance (DeFi) is reshaping the financial industry with innovative solutions that operate without traditional intermediaries. Our DeFi development services include:',
    img: blockchainDevNine
  }
]
const blockchainDev13 = [
  {
    list: [
      {
        id: 1,
        title: '•	DeFi Protocol Development',
        text: ' Build and deploy decentralized protocols that facilitate peer-to-peer transactions and financial services.'
      },
      {
        id: 2,
        title: '•	Token Creation & Management',
        text: 'Create and manage tokens for use in DeFi applications, including utility tokens and governance tokens.'
      },
      {
        id: 3,
        title: '•	Integration & Interoperability',
        text: 'Ensure that DeFi applications integrate seamlessly with existing blockchain networks and financial systems.'
      },
    ]
  }
]
const blockchainDev14a = [
  {
    id: 1,
    title: 'Public and Private Blockchain Solutions',
    para: 'Choosing between public and private blockchain solutions depends on your specific needs for transparency, security, and control. At Zai Systems, we offer:',
    img: blockchainDevTen
  }
]
const blockchainDev14 = [
  {
    list: [
      {
        id: 1,
        title: '•	Public Blockchains',
        text: 'Ideal for applications requiring transparency and decentralization, such as cryptocurrencies and open-source platforms.'
      },
      {
        id: 2,
        title: '•	Private Blockchains',
        text: ' Suitable for enterprises needing controlled access and enhanced privacy, such as supply chain management and internal record-keeping.'
      }
    ]
  }
]
const BlockChainServiceFAQs = [
  { questions: 'What are blockchain development services?', answers: 'Blockchain development services encompass a range of solutions focused on creating, implementing, and managing blockchain technology. This includes developing decentralized applications (DApps), smart contracts, blockchain networks, and integrating blockchain with existing systems.' },
  { questions: 'How does blockchain app development benefit my business?', answers: 'Blockchain app development can provide numerous benefits, including enhanced security, transparency, and efficiency. It can streamline operations, reduce fraud, and enable decentralized applications that offer greater control and trust.' },
  { questions: 'What is the difference between public and private blockchains?', answers: 'Public blockchains are open and accessible to anyone, offering high transparency and decentralization. Private blockchains, on the other hand, are restricted to authorized participants and offer enhanced privacy and control, suitable for enterprise use.' },
  { questions: 'How can blockchain consulting services help my business?', answers: 'Blockchain consulting services provide expert guidance on the feasibility, strategy, and implementation of blockchain technology. Consultants help identify use cases, develop strategic roadmaps, and ensure that blockchain solutions align with your business goals.' },
  { questions: 'What industries benefit the most from blockchain technology?', answers: 'Blockchain technology benefits a wide range of industries, including financial services, supply chain management, healthcare, retail, real estate, government, education, energy, legal, and telecommunications.' },
  { questions: 'How does Zai Systems ensure the security of blockchain solutions?', answers: 'At Zai Systems, we prioritize security by implementing robust encryption methods, conducting thorough security audits, and adhering to best practices in blockchain technology. Our solutions are designed to protect data integrity and prevent unauthorized access.' },
  { questions: 'Can blockchain technology integrate with existing systems?', answers: 'Yes, blockchain technology can integrate with existing systems. We design and implement solutions that ensure seamless integration with your current technology stack, enhancing operational efficiency and data consistency.' },
  { questions: 'What is DeFi and how can it benefit my business?', answers: 'Decentralized Finance (DeFi) refers to financial services that operate on decentralized blockchain networks, removing intermediaries. DeFi can offer benefits such as lower transaction costs, increased accessibility, and greater control over financial assets.' },
  { questions: 'How long does it take to develop a blockchain solution?', answers: 'The development timeline for a blockchain solution varies depending on factors such as complexity, scope, and customization. Our team works closely with clients to establish realistic timelines and ensure timely delivery of high-quality solutions.' },
];
const blockchainDev8a = [
  {
    id: 1,
    title: 'Zai Systems: Mastering the Multiverse of Blockchain Platforms',
    para: `The blockchain landscape is vast and diverse, teeming with platforms each offering unique strengths and catering 
    to specific needs. At Zai Systems, we navigate this complexity with expert finesse, crafting tailored solutions 
    that leverage the best platform for your vision. Here's a deeper dive into the blockchain powerhouses we wield:`
  },

]
const blockchainDev8 = [

  {
    id: 1,
    title: `Thorchain `,
    img: thorchainLogo,
    para: ` Injecting liquidity into the crypto market is Thorchain's mission. We leverage this innovative protocol to connect
     different blockchain ecosystems and enable seamless asset swapping, opening doors for decentralized finance (DeFi) applications
      and cross-chain interoperability.`

  },
  {
    id: 2,
    title: `Hyperledger `,
    img: hyperLogo,
    para: `Hyperledger is a suite of interoperable frameworks and tools designed for enterprise 
    blockchain adoption. We wield Hyperledger Fabric to build private, permissioned blockchains with enhanced security and
     control, ideal for supply chain, digital identity, and confidential transactions.`

  },
  {
    id: 3,
    title: `Binance Smart Chain`,
    img: binanceLogo,
    para: ` Forget just trading - Binance Smart Chain enables building dApps within the crypto trading powerhouse. We harness
     its high transaction throughput, low fees, and interoperability with the Binance ecosystem to develop innovative financial 
     applications and dApps for a global audience.`

  },

  {
    id: 4,
    title: ` Cardano `,
    img: cardanoLogo,
    para: `Cardano's scientific rigor and emphasis on sustainability shine through its proof-of-stake consensus mechanism.
     We tap into Cardano's advanced features and vibrant community to create secure, energy-efficient blockchain solutions 
     for a greener future with social impact.`

  },
  {
    id: 5,
    title: ` Corda `,
    img: cordaLogo,
    para: ` Designed with the enterprise in mind, Corda prioritizes privacy, security, and compliance. We leverage
     Corda's private, permissioned network and built-in legal contracts to deliver blockchain solutions for financial
      institutions, healthcare, and other data-sensitive industries.`

  },
  {
    id: 6,
    title: `Polygon `,
    img: polygonLogo,
    para: `Seeking blazing-fast transactions and low fees? Polygon, a sidechain solution for Ethereum, delivers on both.
     Combining scalability, security, and compatibility with the Ethereum ecosystem, Polygon is a champion for building 
     high-performance dApps with mass appeal.`

  },

  {
    id: 7,
    title: `Ripple `,
    img: rippleLogo,
    para: `Beyond its association with cryptocurrency exchange, Ripple's payment protocol revolutionizes traditional banking. 
    We utilize Ripple's instant settlement and global reach to facilitate secure, efficient cross-border payments for businesses
     of all sizes.`

  },
  {
    id: 8,
    title: `Stellar `,
    img: stellarLogo,
    para: `Stellar bridges digital and fiat currencies with its open-source protocol. Its open-source protocol enables fast, secure, and affordable 
    cross-border transactions with low fees, ideal for remittance, financial inclusion, and other use cases.`

  },

  {
    id: 9,
    title: `Solana `,
    img: solanaLogo,
    para: ` When speed and scalability are paramount, Solana is the platform of choice. Its unique consensus mechanism enables
     lightning-fast transactions and near-instant finality, making it ideal for high-frequency trading, real-time gaming, and beyond.`

  },
  {
    id: 10,
    title: `Cosmos `,
    img: cosmosLogo,
    para: ` Interoperability is Cosmos' middle name. This framework empowers building interconnected, application-specific 
    blockchains that seamlessly communicate with each other. We utilize Cosmos' modularity and customizability to design bespoke
     blockchain solutions that integrate into existing ecosystems.`

  },
  {
    id: 11,
    title: `Private `,
    img: privateLogo,
    para: `Sharing data securely doesn’t require public blockchains. Private, a file-sharing platform built on blockchain 
    technology, empowers fast, secure, and transparent data exchange without compromising privacy. We integrate Private into
     solutions where data control, confidentiality, security, and efficiency are critical.`

  },
  {
    id: 12,
    title: `Ethereum `,
    img: etheriumLogo,
    para: `Ethereum reigns as the public blockchain leader. Its native currency, Ether (ETH),
     fuels a vibrant ecosystem of smart contracts and decentralized applications (dApps). We leverage Ethereum's proven track
      record, robust community, and rich developer tools to build secure, scalable solutions for diverse applications.`
  }
]

const blockchainDev5a = [
  {
    id: 1,
    title: 'Blockchain Consulting Services',
    para: 'Navigating the blockchain landscape can be complex. Our blockchain consulting services provide expert guidance to help you make informed decisions. Our consulting process includes:'
  },

]
const blockchainDev5 = [
  {
    id: 1,
    title: `Transparency and Trust`,
    para: `Blockchain's data structure ensures information stored on the network is immutable and decentralized. This transparency builds trust with your customers
      and fosters a secure ecosystem for transactions.`
  },
  {
    id: 2,
    title: `Cost Optimization`,
    para: `Say goodbye to exorbitant intermediary costs. Blockchain's peer-to-peer network eliminates the need for central
     authorities, significantly reducing transaction fees and streamlining your financial operations.`

  },
  {
    id: 3,
    title: `Enhanced Security`,
    para: `Sleep soundly knowing your data is safe. Blockchain's distributed ledger technology acts as a self-defending 
    fortress, making it virtually impossible for hackers to compromise or breach your information.`

  },
  {
    id: 4,
    title: `Streamlined Processes`,
    para: `Eliminate manual inefficiencies and human error. Blockchain's smart contracts automate pre-defined business 
    logic, ensuring accurate and frictionless execution of processes, every time.`
  },
  {
    id: 5,
    title: `Scalability for Growth`,
    para: `No more worrying about infrastructure limitations. Blockchain's inherent scalability empowers your brand to 
    accommodate explosive growth without compromising performance or security.`

  },
  {
    id: 6,
    title: `Innovation and Customization`,
    para: `
    Stand out from the crowd with Zai Systems’ blockchain solutions. We don’t just use existing platforms and frameworks; we also create custom solutions that suit your specific needs and goals. 
   `

  }
]
const blockchainDev6a = [
  {
    id: 1,
    title: 'What to Expect from Our Blockchain Developers?',
    para: `With years of experience under their belts and a passion for pushing boundaries, 
    they're equipped to turn your business vision into a secure, efficient, and scalable reality
     leveraging the power of blockchain technology.`
  }
]
const blockchainDev6 = [
  {
    id: 1,
    title: `Unwavering Expertise`,
    para: (
      <>
        <span class='text-white'>Mastering the Craft: </span>  Our developers are fluent in the language of blockchain. They possess a
        deep understanding of its intricate mechanisms, from consensus algorithms to smart contracts, ensuring they can flawlessly navigate
        the ever-evolving landscape.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Problem-Solvers at Heart: </span>  Challenges excite them. Whether you're aiming to revolutionize the financial
        sector or streamline complex supply chains, our developers thrive on unraveling intricate problems and crafting innovative and scalable solutions.
      </>
    )
  },
  {
    id: 2,
    title: `Client-Centric Approach`,
    para: (
      <>
        <span class='text-white'>Your Vision, Our Compass: </span> Our developers take the time to
        understand your unique needs, challenges, and aspirations. They become an extension of your team, working collaboratively to tailor
        blockchain solutions that perfectly align with your vision.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Open Communication, Seamless Collaboration: </span> Transparency reigns supreme. You'll always be kept in the loop,
        with clear communication throughout every stage of the development process. This fosters an environment of trust and ensures your project
        stays on track.
      </>
    )

  },
  {
    id: 3,
    title: `A Spectrum of Expertise`,
    para: (
      <>
        <span class='text-white'>Cryptocurrency Architects: </span>  Dive into the world of digital currencies with confidence and ease. Our developers are
        adept at building secure and compliant cryptocurrency platforms, enabling you to leverage the power of blockchain finance.
        Our services are fast, reliable, and affordable. </>
    ),
    para1: (
      <>
        <span class='text-white'>Blockchain Beyond the Buzzwords: </span> From smart contracts that automate workflows to decentralized
        applications that disrupt industries, our developers bring your blockchain imagination to life. They explore the full potential
        of the technology.
      </>
    )

  },
  {
    id: 4,
    title: `Results You Can Rely On`,
    para: (
      <>
        <span class='text-white'>Scalability for the Future: </span> We don't build for the present; we build for exponential growth. Our blockchain
        solutions are designed to scale seamlessly alongside your business, ensuring you have the infrastructure to thrive in the years to come.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Security Like Fort Knox: </span> Your data is precious. Our skilled developers prioritize robust security measures
        at every stage, from initial design to ongoing maintenance, safeguarding your information with the same vigilance as Fort Knox does.
      </>
    )
  },
  {
    id: 5,
    title: `Conscious Blockchain Development`,
    para: (
      <>
        <span class='text-white'>Innovation with Impact Cutting-Edge Solutions:</span> Our developers explore new technologies to enhance your blockchain experience.
        They leverage the latest tools and frameworks to deliver cutting-edge
        solutions that set you apart from the competition.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Social Responsibility: </span> Our developers are not only driven by innovation, but also by impact.
        They care about the social and environmental implications of their work, and strive to create
        blockchain solutions that can make a positive difference in the world.

      </>
    )
  },
  {
    id: 6,
    title: `Guaranteed Blockchain Solutions`,
    para: (
      <>
        <span class='text-white'>Quality Assurance Testing and Debugging: </span> Our developers don’t settle for anything less than perfection.
        They rigorously test and debug their code, ensuring that your
        blockchain solutions are free of errors, bugs, and potential vulnerabilities at all times.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Continuous Improvement:</span> Our developers are always eager to learn and improve.
        They welcome feedback and suggestions, and seek to optimize and
        enhance their blockchain solutions. They are committed to delivering quality
        results that exceed your expectations.

      </>
    )
  }
]
const SeoDataBlockchaindev = [
  {
    id: 1,
    title: `Blockchain Development Services`,
    content: `Streamline your business with innovative blockchain solutions. Experienced developers deliver fast, reliable results.`,
    link: `https://www.zaisystems.com/services/blockchain-development`
  }
]
// Near Protocol Development
const near1 = [
  {
    id: 1,
    title: `NEAR Protocol Development`,
    para: `
    At Zai Systems, we champion innovation, and when it comes to building the future of the web, few platforms 
    shine brighter than NEAR Protocol. This decentralized application platform isn't just another playground for
     developers; it's a rocket ship fueled by robust performance and mind-blowing scalability, ready to propel 
     your NEAR Protocol Development creations to the stratosphere.`,
    para1: `Imagine a world where transactions zip through the network in a mere one second, and your dApp handles
     a staggering 100,000 transactions per second with unwavering resilience. That's the reality NEAR Protocol unlocks,
      shattering the limitations of conventional blockchain platforms and ushering in a new era of possibilities.`,
    img: near,
  }
]
const near4a = [
  {
    id: 1,
    title: `NEAR Protocol Development Services`,
    para: `Ready to transform your BIG idea into a reality? Look no further than our comprehensive NEAR Protocol 
    development services. Our team of seasoned NEAR-savvy specialists empowers you to build cutting-edge decentralized 
    applications (dApps), high-security wallets, and thriving NFT marketplaces, all on the powerful NEAR blockchain.`
  },

]
const near4 = [
  {
    id: 1,
    title: `Bespoke dApps for All`,
    para: `Whether you're a budding startup or a seasoned enterprise, we craft custom dApps tailored 
    to your exact business needs and user demands. Scalability is built-in, ensuring your application
     smoothly adapts to growth.`,
    img: nearOne
  },
  {
    id: 2,
    title: `Frictionless Wallets`,
    para: `Our expertise enables us to design and deliver secure, efficient crypto wallets on the NEAR 
    protocol. Enjoy seamless crypto transactions, effortless lending and borrowing, and a user-friendly 
    experience for all.`,
    img: nearTwo
  },
  {
    id: 3,
    title: `Thriving NFT Marketplaces`,
    para: `Let us guide you in creating a feature-rich NFT marketplace that's both intuitive and engaging. We
     understand the nuances of the NFT landscape, ensuring your platform attracts both creators and 
     collectors, propelling your success.`,
    img: nearThree
  },
  {
    id: 4,
    title: `DeFi Solutions`,
    para: `Breathe life into your blockchain project with robust, operational smart contracts. Our team 
    specializes in crafting custom solutions that drive your DeFi goals, ensuring user-friendly experiences 
    and seamless functionality.`,
    img: nearFour
  },
  {
    id: 5,
    title: `DEX on NEAR`,
    para: `Stand out from the crowd with a bespoke decentralized crypto exchange platform. We possess the technical 
    prowess to develop unique, feature-packed DEX solutions designed to attract and retain a loyal user base.`,
    img: nearFive
  },
  {
    id: 6,
    title: `Uncompromising Security`,
    para: `Prioritize user trust and asset protection with a comprehensive smart contract audit. Our cybersecurity
     experts meticulously assess your blockchain product, identifying vulnerabilities and preventing potential breaches.`,
    img: nearSix
  }

]
// const near5a = [
//   {
//     id: 1,
//     title: `Dive into the NEAR Protocol: A Powerhouse of Advantages for All`,
//     para: `At Zai Systems, we're dedicated to exploring transformative technologies, and NEAR Protocol stands 
//     out as a shining example. This innovative blockchain platform boasts a unique blend of advantages that 
//     position it as a champion of ecological and technological progress. Here's a deeper look at NEAR's key strengths:`,
//   },

// ]
// const near5 = [
//   {
//     id: 1,
//     title: `Environmental Champion`,
//     para: `NEAR embraces sustainability as a core principle. Its sharded architecture minimizes energy 
//     consumption, reducing the carbon footprint compared to traditional blockchains. This
//      eco-friendly approach aligns with Zai Systems' vision of a responsible future, where technology
//       thrives in harmony with our planet.`,

//   },
//   {
//     id: 2,
//     title: `Blazing Speed`,
//     para: `Forget sluggish transactions! NEAR's sharding technology breaks down the network into smaller shards,
//      enabling parallel processing and lightning-fast speeds. Imagine transactions confirmed in mere milliseconds,
//       opening doors for real-time applications that were previously unimaginable.`,

//   },

//   {
//     id: 4,
//     title: `Scaling to Infinity`,
//     para: `No more bottlenecks! NEAR's sharding technology is inherently scalable, meaning it can seamlessly adapt to 
//     accommodate any surge in user activity. Whether it's millions of gamers or complex financial transactions,
//      NEAR can handle it all, paving the way for mass adoption and limitless possibilities.`,

//   },
//   {
//     id: 5,
//     title: `Financially Savvy`,
//     para: `NEAR understands the needs of both users and businesses. Its transaction fees are incredibly low, making 
//     it accessible to everyone. Additionally, NEAR features native mechanisms for generating passive income, like staking 
//     and participating in governance, rewarding users for their involvement.`,

//   },
//   {
//     id: 6,
//     title: `Decentralized Internet Architect`,
//     para: `NEAR empowers Web 3.0 by providing a foundation for building decentralized applications (dApps). Imagine a future
//      where you control your data, own your digital assets, and interact with the web without centralized 
//      intermediaries. NEAR makes this a reality, bringing us closer to a free and open internet.`,

//   },
//   {
//     id: 7,
//     title: `Security Fortress`,
//     para: `Security is paramount, and NEAR takes it seriously. Its sharded architecture ensures distributed validation, 
//     making it resilient against attacks. Additionally, NEAR's Proof-of-Stake consensus mechanism incentivizes network 
//     integrity, creating a robust and trustworthy platform.`,
//   },
//   {
//     id: 3,
//     title: `Developer's Dream`,
//     para: `Building on NEAR is a joyride. Its intuitive, human-readable language eliminates the need for complex 
//     coding, welcoming developers of all skill levels. Additionally, NEAR offers comprehensive tools and resources,
//      fostering a vibrant developer community and accelerating innovation in various domains.`,

//   },
//   {
//     id: 8,
//     title: `User-Centric Experience`,
//     para: ` NEAR puts users first, offering a seamless and intuitive experience. Its
//     user-friendly interface and account model make it easy to onboard and interact with the platform. Additionally,
//      NEAR supports cross-chain interoperability, enabling users to access a variety of services and assets across
//       different blockchains.`,

//   },
//   {
//     id: 9,
//     title: `Community-Driven Governance `,
//     para: `NEAR is a democratic platform, where users have a voice and a stake in its future.
//    Its open-source and transparent nature invites participation and collaboration from all stakeholders.
//     NEAR’s governance model allows users to propose and vote on network upgrades, ensuring alignment with the community’s 
//     needs and values.`,

//   },

// ]
const near6a = [
  {
    id: 1,
    title: `Why Zai Systems Should Be Your Go-To Partner for NEAR Protocol Development?`,
    para: `In the ever-evolving world of blockchain, choosing the right development partner is crucial. When it
     comes to building on the powerful NEAR Protocol, look no further than Zai Systems. Here's why we stand out 
     as your ideal NEAR development partner:`,
  },

]
const near6 = [
  {
    id: 1,
    title: `Unmatched Expertise`,
    para: `Our team boasts seasoned veterans with extensive experience in NEAR development. We possess
     in-depth knowledge of the protocol's nuances, complexities, and cutting-edge features, ensuring 
     your project leverages NEAR's full potential.`,
  },
  {
    id: 2,
    title: `Proven Track Record`,
    para: `We're not just talk, we walk the walk. Our portfolio speaks volumes, showcasing a range of 
    successful NEAR-based projects, from complex dApps to user-friendly wallets and thriving NFT marketplaces.
     Trust your vision to a team with a proven track record of success.`,

  },

  {
    id: 4,
    title: `Agile and Collaborative`,
    para: `We believe in transparency and open communication. We work closely with you throughout the 
    development process, ensuring your vision is translated into reality with meticulous attention 
    to your needs and preferences.`,

  },
  {
    id: 3,
    title: `Innovation at Heart`,
    para: `We don't settle for the ordinary. We're passionate about pushing boundaries and exploring new
     possibilities on the NEAR frontier. Partner with us and tap into our innovative spirit to create 
     groundbreaking solutions that set you apart.`,

  },
  {
    id: 5,
    title: `Dedicated Support`,
    para: `We're not just developers, we're your trusted partners in success. Our commitment doesn't end with
     development. We offer comprehensive post-launch support, ensuring your NEAR project operates smoothly
      and thrives in the long run.`,

  },
  {
    id: 6,
    title: `Competitive Edge`,
    para: `We value cost-effectiveness. Our competitive pricing structures and 
    flexible engagement models ensure the best value for your investment, empowering you to build 
    remarkable solutions without breaking the bank.`,

  },
  {
    id: 7,
    title: `Shared Values`,
    para: `At Zai Systems, we believe in the transformative power of blockchain technology. We're aligned
     with the NEAR Protocol's vision of a decentralized, sustainable, and user-centric future, ensuring our
      collaboration goes beyond just lines of code.`,

  },
  {
    id: 8,
    title: `Scalable and Secure `,
    para: `We understand the importance of scalability and security in blockchain development.
     That’s why we leverage NEAR’s sharding and proof-of-stake mechanisms to deliver fast, reliable, and cost-efficient
      solutions that can handle any level of demand and complexity.`,

  },
  {
    id: 9,
    title: `User-Friendly and Accessible`,
    para: ` We make blockchain accessible and enjoyable for everyone. We design 
    and develop user-friendly interfaces and experiences that make your NEAR project easy to use and understand. 
    We also ensure compatibility with various platforms and devices, reaching more users.`,

  }
]

const SeoDataNear = [
  {
    id: 1,
    title: `Near Protocol Development Services - Zai Systems`,
    content: `Our Expert Near Protocol Development Services streamline DeFi, DAOs, & dApps with our fast, low-fee blockchain expertise. Join the Near ecosystem, innovate confidently.`,
    link: `https://www.zaisystems.com/services/near-protocol-development`
  }
]

// web app development
const webApp1 = [
  {
    id: 1,
    title: `Premier Web App Development Services 2025 `,
    para: `Having a robust web presence is crucial for businesses to thrive. At Zai Systems, we one of the best web app development services company in Pakistan specialize in providing top-tier Web App Development Services that cater to the unique needs of our clients. Our expertise spans across various domains, including Progressive Web Applications (PWAs), custom web app development, and SaaS development services. Whether you're a startup looking to build your first web app or an established enterprise aiming to enhance your digital capabilities, Zai Systems is your go-to Web App Development Company.`,
    img: webApp,
  }
]
const webApp3 = [
  {
    id: 1,
    title: `Schedule A 7-Day Trial To Witness Our Quality Standards `,
  },

]
const webApp4a = [
  {
    id: 1,
    title: `Why Choose Zai Systems for Your Web App Development Needs?`,
    para: `Stay ahead of the competition with Zai Systems’ web app development services, featuring the latest technologies 
    that will enhance your customers' shopping experience.`
  },

]
const webApp4 = [
  {
    id: 1,
    title: 'Expertise in Progressive Web Applications (PWAs)',
    para: `Progressive Web Applications (PWAs) offer the perfect blend of web and mobile app experiences. Our team at Zai Systems excels in developing PWAs that are fast, reliable, and engaging, providing your users with a seamless experience across all devices. By leveraging modern web capabilities, we ensure that your PWA delivers high performance, offline functionality, and enhanced user engagement`,
    img: webAppOne
  },
  {
    id: 2,
    title: 'Custom Web App Development',
    para: `Every business is unique, and so are its requirements. At Zai Systems, we specialize in bespoke web application development, crafting custom solutions that align perfectly with your business goals. Our custom web app development services ensure that your application is built to meet your specific needs, providing you with a competitive edge in the market.`,
    img: webAppTwo
  },
  {
    id: 3,
    title: 'Seamless Integration with Existing Systems',
    para: `We understand the importance of seamless integration with your existing systems. Our team ensures that your new web application integrates smoothly with your current infrastructure, enhancing functionality without disrupting your operations. Whether it's integrating with your CRM, ERP, or other enterprise systems, Zai Systems has the expertise to make it happen.`,
    img: webAppThree
  },
  {
    id: 4,
    title: 'Agile Development Methodology',
    para: `Our agile development methodology ensures that your web application is developed efficiently and effectively. By following agile principles, we deliver incremental updates, allowing for continuous feedback and improvements. This approach not only ensures a high-quality end product but also reduces time-to-market, helping you stay ahead of the competition.`,
    img: webAppFour
  },
  {
    id: 5,
    title: 'Dedicated Support and Maintenance',
    para: `At Zai Systems, our commitment to your success doesn't end with the launch of your web application. We offer ongoing support and maintenance services to ensure that your application remains up-to-date, secure, and fully functional. Our dedicated support team is always ready to address any issues and implement updates, ensuring your application continues to perform optimally.`,
    img: webAppFive
  }
]
const webApp6a = [
  {
    id: 1,
    title: 'Supercharge Your Business with Zai Systems’ Web App Development Expertise',
    para: `As the leading and trusted web app development company in the U.S.A., the U.K., 
    and Canada, we offer a personalized package of value-added services.`
  },

]
const webApp6 = [
  {
    id: 1,
    title: 'Powerhouse of Expertise',
    para: `Our community has 100+ experienced developers with a 98% client satisfaction rate across diverse industries like
     healthcare, fintech, and more. Our developers are fluent in the latest programming languages, frameworks, databases, 
     and toolkits.`
  },
  {
    id: 2,
    title: `Unwavering Quality`,
    para: ` We build robust and reliable applications that prioritize performance and minimize bugs. Our intuitive design
     creates a seamless user experience for optimal engagement, protecting your brand reputation with a positive user journey.`

  },
  {
    id: 3,
    title: `Pocket-Friendly Development`,
    para: ` Partner with our dedicated product managers for guaranteed project success within 40% of your budget. We rigorously test and
     debug your app through exhaustive automated testing and manual security audits for a flawless launch. `

  },
  {
    id: 4,
    title: `Collaboration and Transparency`,
    para: `Gain visibility with daily meetings where our product managers keep you informed about your project's progress. We
     value your input and actively incorporate your feedback throughout the development process.`

  },
  {
    id: 5,
    title: `Uncompromising Confidentiality`,
    para: `We utilize cutting-edge technology and security protocols to make your data remains confidential and protected.
     Trust Zai Systems with your valuable information and focus on your business growth.`

  },
  {
    id: 6,
    title: `Scalable and Future-Proof Solutions`,
    para: `We build scalable and flexible web apps that handle growing traffic and business needs.
     We use the latest technologies and best practices to make your web app adaptable and resilient
     to changing market demands.`

  }
]
const webApp8a = [
  {
    id: 1,
    title: 'Comprehensive Web App Development Solutions',
    para: 'At Zai Systems, top notch IT services company in Lahore, Pakistan dealing worldwide,  we offer a wide range of web app development services designed to meet the diverse needs of our clients.:',
    img: webAppTen
  },

]
const webApp8 = [
  {
    id: 1,
    title: 'Custom Web App Development Services',
    para: `Tailored to your specific business requirements, ensuring a perfect fit for your operations`,
    img: webAppSeven

  },
  {
    id: 2,
    title: 'Web App Design Services',
    para: `Creating intuitive and visually appealing interfaces that enhance user experience.`,
    img: webAppEight

  },
  {
    id: 3,
    title: 'Web App Testing Services',
    para: ` Rigorous testing to ensure your web application is robust, secure, and bug-free.`,
    img: webAppNine

  },
  {
    id: 4,
    title: 'SaaS Development Services',
    para: ` Developing scalable and efficient SaaS applications that drive business growth.`,
    img: webAppNine

  },
  {
    id: 5,
    title: 'Backend & Node.js Development Services',
    para: `Building powerful and scalable backends using Node.js and other technologies.`,
    img: webAppNine

  },
  {
    id: 6,
    title: 'PHP Web Application Development Services',
    para: ` Leveraging PHP to create dynamic and efficient web applications.`,
    img: webAppNine

  },
  {
    id: 7,
    title: 'Front End Development Services',
    para: `Crafting responsive and interactive front-end experiences.`,
    img: webAppNine

  },
  {
    id: 8,
    title: 'CMS Development Services',
    para: `Customizing content management systems to streamline your content workflow.`,
    img: webAppNine

  }
  ,
  {
    id: 3,
    title: 'Web App Testing Services',
    para: ` Rigorous testing to ensure your web application is robust, secure, and bug-free.`,
    img: webAppNine

  },
  {
    id: 3,
    title: 'API Development & Integration Services',
    para: ` Seamlessly connecting your web applications with third-party services and systems for enhanced functionality..`,
    img: webAppNine

  }
]
// const webApp5a = [
//   {
//     id: 1,
//     title: 'Our Web App Development Process',
//     // para: `Avoid scope creep, delays, and unexpected changes by building a team of experts:`
//     img: webAppEleven
//   },

// ]
// const webApp5 = [
//   {
//     id: 1,
//     title: `Discovery and Planning`,
//     para: `The first step in our web app development process is understanding your business needs and goals. We conduct thorough research and analysis to identify the best approach for your project. This phase involves:`,
//     list: [
//       {
//         id: 1,
//         text: '•	Requirement gathering and analysis'
//       }, {
//         id: 2,
//         text: '•	Defining project scope and objectives'
//       }, {
//         id: 3,
//         text: '•	Creating a detailed project plan'
//       }
//     ]
//   },
//   {
//     id: 2,
//     title: `Design and Prototyping`,
//     para: `Once the project plan is in place, our design team gets to work on creating wireframes and prototypes.We focus on delivering a user - centric design that provides an intuitive and engaging experience.Our design process includes:`,
//     list: [
//       {
//         id: 1,
//         text: '•	Creating wireframes and mockups'
//       }, {
//         id: 2,
//         text: '•	Designing user interfaces(UI) and user experiences(UX)'
//       }, {
//         id: 3,
//         text: '•	Developing interactive prototypes for feedback'
//       }
//     ]
//   },
//   {
//     id: 3,
//     title: `Development and Coding`,
//     para: `With the design approved, our development team takes over to build your web application.We follow best coding practices and use the latest technologies to ensure a high - quality product.Our development phase includes:`,
//     list: [
//       {
//         id: 1,
//         text: '•	Front end development services'
//       },
//       {
//         id: 2,
//         text: '•	Backend & Node.js development services'
//       }, {
//         id: 3,
//         text: '•	Integration of APIs and third - party services'
//       }, {
//         id: 4,
//         text: '•	Custom web app development'
//       }
//     ]
//   },
//   {
//     id: 4,
//     title: `Testing and Quality Assurance`,
//     para: `Quality is paramount at Zai Systems.Our web app testing services involve rigorous testing to identify and fix any issues before launch.Our QA process includes:`,
//     list: [
//       {
//         id: 1,
//         text: '•	Functional testing'
//       },
//       {
//         id: 2,
//         text: '•	Performance testing'
//       }, {
//         id: 3,
//         text: '•	Security testing'
//       }, {
//         id: 4,
//         text: '•	Usability testing'
//       }
//     ]
//   },
//   {
//     id: 5,
//     title: `Deployment and Launch`,
//     para: `After thorough testing, we deploy your web application to the production environment.Our deployment process is smooth and efficient, ensuring minimal downtime.We also provide training and documentation to help you manage your new application.`
//   },
//   {
//     id: 5,
//     title: `Support and Maintenance`,
//     para: `Post - launch, we offer comprehensive support and maintenance services to keep your web application running smoothly.Our services include:`,
//     list: [
//       {
//         id: 1,
//         text: '•	Regular updates and upgrades'
//       },
//       {
//         id: 2,
//         text: '•	Performance monitoring and optimization'
//       }, {
//         id: 3,
//         text: '•	Issue resolution and troubleshooting'
//       }
//     ]

//   }
// ]
const webApp12a = [
  {
    id: 1,
    title: 'Technologies We Use',
    para: 'At Zai Systems, we leverage the latest technologies to deliver cutting-edge web applications.',
  }

]
const webApp12 = [
  {
    id: 1,
    title: `Frontend Technologies`,
    para: ` HTML5, CSS3, JavaScript, React, Angular, Vue.js`
  },
  {
    id: 2,
    title: `Backend Technologies`,
    para: `Node.js, PHP, Python, Ruby on Rails`
  },
  {
    id: 3,
    title: `Database Technologies`,
    para: `MySQL, Postgre SQL, MongoDB, Firebase`
  },
  {
    id: 4,
    title: `CMS Platforms`,
    para: ` WordPress, Drupal, Joomla`
  },
  {
    id: 5,
    title: `Cloud Platforms`,
    para: ` AWS, Google Cloud, Microsoft Azure`
  }
]
const webApp13a = [
  {
    id: 1,
    title: 'Industries We Serve',
    para: 'Our web app development services cater to a wide range of industries.',
    img: webAppTwelve
  }

]
const webApp13 = [
  {
    id: 1,
    // title: `Experienced Team`,
    para: `E - commerce`,
    img: mobileSix
  },
  {
    id: 2,
    // title: `Customer - Centric Approach`,
    para: `Healthcare`,
    img: mobileEight
  },
  {
    id: 3,
    // title: `Comprehensive Services`,
    para: `Finance`,
    img: mobileFive
  },
  {
    id: 4,
    // title: `Comprehensive Services`,
    para: `Education`,
    img: mobileFive
  },
  {
    id: 5,
    // title: `Comprehensive Services`,
    para: `Real Estate`,
    img: mobileFive
  },
  {
    id: 6,
    // title: `Comprehensive Services`,
    para: `Travel and Hospitality`,
    img: mobileFive
  }
]
const webApp14a = [
  {
    id: 1,
    title: 'Success Stories ',
    img: mobilSeventeen
  }
]
const webApp14 = [
  {
    id: 1,
    title: 'E-commerce Platform for a Leading Retailer ',
    para: 'We developed a scalable and feature-rich e-commerce platform for a leading retailer, enabling them to expand their online presence and reach a wider audience. Our solution included custom web app development, seamless integration with their existing systems, and robust web app testing services to ensure a smooth user experience.'
  },
  {
    id: 2,
    title: 'Healthcare Application for a Medical Provider',
    para: 'Zai Systems created a secure and compliant healthcare application for a medical provider, allowing them to streamline their operations and improve patient care. Our bespoke web application development services ensured that the application met all regulatory requirements while providing a user-friendly interface for both patients and healthcare professionals.'
  },
  {
    id: 3,
    title: 'SaaS Application for a Startup',
    para: 'We developed a SaaS application for a tech startup, providing them with a scalable and efficient solution to deliver their services to customers. Our comprehensive web app development services, including Backend & Node.js development services, ensured that the application was robust and capable of handling high traffic volumes.'
  }
]
const webApp15 = [
  {
    id: 1,
    title: 'Get Started with Zai Systems Today ',
    para: 'Ready to take your business to the next level with a powerful web application? Contact Zai Systems today to discuss your project and discover how our web app development services can help you achieve your business goals. Whether you need custom web app development, Progressive Web Applications, or SaaS development services, our team of experts is here to deliver the perfect solution for your needs.'
  }
]
const WebServiceFAQs = [
  { questions: 'What web app development services does Zai Systems offer?', answers: 'Zai Systems provides a comprehensive range of web app development services, including custom web app development, web app design, web app testing, SaaS development, backend and Node.js development, PHP web application development, front-end development, and CMS development services.' },
  { questions: 'Why should I choose Zai Systems for web app development?', answers: 'Zai Systems stands out for its expertise in custom web app development, progressive web applications (PWAs), seamless system integration, agile development methodology, and dedicated support and maintenance services. We ensure high-quality, user-friendly, and scalable web solutions tailored to your business needs.' },
  { questions: 'What is custom web app development, and how does Zai Systems approach it? ', answers: 'Custom web app development involves creating web applications tailored specifically to your business requirements. At Zai Systems, we conduct thorough research, design user-centric interfaces, develop robust applications, and ensure seamless integration with your existing systems.' },
  { questions: 'What are Progressive Web Applications (PWAs), and how can they benefit my business? ', answers: 'Progressive Web Applications (PWAs) combine the best features of web and mobile applications. They offer fast loading times, offline functionality, and an engaging user experience. Zai Systems excels in developing PWAs that enhance user engagement and deliver high performance across all devices.' },
  { questions: 'How does Zai Systems ensure the quality and security of web applications?', answers: "Quality and security are paramount at Zai Systems. We conduct rigorous testing, including functional, performance, security, and usability testing, to ensure your web application is robust, secure, and bug-free. Our ongoing support and maintenance services further guarantee the application's optimal performance." },
  { questions: 'Can Zai Systems integrate my new web application with existing systems?', answers: 'Yes, Zai Systems specializes in seamless integration of new web applications with your existing systems, such as CRM, ERP, and other enterprise systems. We ensure smooth integration to enhance functionality without disrupting your operations.' },
  { questions: 'What is the development process at Zai Systems for web applications?', answers: 'Our development process includes several stages: discovery and planning, design and prototyping, development and coding, testing and quality assurance, deployment and launch, and support and maintenance. This structured approach ensures a high-quality end product delivered efficiently.' },
  { questions: 'How can I get started with Zai Systems for my web app development project? ', answers: 'To get started with Zai Systems, contact us to discuss your project requirements. Our team of experts will work with you to understand your business goals and deliver a powerful web application tailored to your needs. Whether you need custom web app development, PWAs, or SaaS development services, Zai Systems is here to help.' },
];
const SeoDataWebapp = [
  {
    id: 1,
    title: `Custom Web App Development Services`,
    content: `Build Your Dream Web App: Custom Development for Growth & Success.Turn ideas into reality with expert developers.Get our experts Consultation!`,
    link: `https://www.zaisystems.com/services/web-app-development`
  }
]
// mobile app development
const mobileApp1 = [
  {
    id: 1,
    // title: `Mobile App Development Services`,                  old
    title: `Mobile App Development Services Company`,
    para: `Zai Systems is one of the best companies in Mobile App Development.`,
    img: appDev,
  }
]
const mobileApp3 = [
  {
    id: 1,
    title: `Start 7-Day Trial To Get First-hand Experience of Our Development Process `,
  },

]
const mobileApp4a = [
  {
    id: 1,
    // title: `Craft Your Dream App with Our Mobile App Development Services `,                  old
    title: `Our Mobile App Development Services`,
    para: `At Zai Systems, we pride ourselves on being one of the best leading mobile app development companies in Pakistan, 
    delivering top-notch application development services that cater to businesses of all sizes. 
    With our extensive expertise in Android and iOS platforms, we are recognized among the top mobile app development companies globally. 
    Our commitment to innovation, quality, and customer satisfaction has made us the go-to custom app development partner for many enterprises.
    `
  },

]
const mobileApp4 = [
  {
    id: 1,
    title: 'Android App Development Services',
    // title: 'Android App Development ',
    para: `Our Android mobile app development company specializes in creating feature-rich, 
    high-performance apps for the Android platform. Our developers are proficient in the latest 
    technologies and follow best practices to ensure your app stands out in the crowded marketplace.`,
    // para: `Build feature-rich native Android apps that engage users and
    // drive conversions. Our remote team of developers’ crafts experiences 
    // that seamlessly integrate with the Android ecosystem.`,
    img: mobileOne
  },

  {
    id: 2,
    title: 'iOS App Development Services',
    para: `As a mobile app development company for iOS, we develop elegant and intuitive apps that 
    provide a seamless user experience. Our iOS development team is well-versed in Swift and Objective-C, 
    ensuring your app is built to the highest standards.`,
    // title: 'iOS App Development ',
    // para: `Shine on the App Store with our talented iOS developers. We
    //  create user-friendly iPhone and iPad apps that delight users and 
    //  elevate your brand.`,
    img: mobileTwo
  },
  {
    id: 3,
    title: 'Cross-Platform App Development',
    para: `Our cross-platform app development services ensure that your app works flawlessly on both Android and iOS platforms. 
    Using technologies like Flutter and React Native, we create apps that offer a native-like experience 
    while reducing development time and costs.`,
    // title: 'Cross-Platform App Development ',
    // para: `Expand your reach without breaking the bank. Our cross-platform
    //  solutions utilize a single codebase for both iOS and Android, saving you time and money.`,
    img: mobileFive
  }
  ,
  {
    id: 4,
    title: 'Custom Mobile App Development',
    para: `We offer custom mobile app development tailored to your specific business needs. Whether you need 
    a simple app or a complex enterprise solution, our team has the expertise to deliver a product that meets your requirements.`,
    // title: 'React Native App Development ',
    // para: `Experience React Native for a single codebase that runs on
    //  both iOS and Android. Enjoy faster development times and consistent
    //   performance across platforms. Today.`,
    img: mobileFour

  },
  {
    id: 5,
    title: 'Hybrid Mobile App Development',
    // title: 'Hybrid App Development ',
    para: `As a hybrid mobile app development company, we leverage technologies like Angular and Flutter to create 
    apps that combine the best of both native and web applications. This approach ensures high performance and a 
    consistent user experience across platforms.`,
    // para: `Reach a wider audience quickly and efficiently with hybrid apps.
    //  These web-based apps deliver a native-like experience while offering
    //   cost-effectiveness and cross-platform reach.`,
    img: mobileThree


  },
  {
    id: 6,
    title: 'Enterprise Mobile App Development',
    para: `Our enterprise mobile app development services are designed to help businesses streamline their operations 
    and improve productivity. We develop secure and scalable apps that integrate seamlessly with your existing systems.`,
    img: mobileTwo
  },
  {
    id: 7,
    title: 'Mobile App Design Services',
    para: `A great app starts with a great design. Our mobile app design services focus on creating intuitive 
    and visually appealing interfaces that enhance user engagement and satisfaction.`,
    img: mobileFour
  },
  {
    id: 8,
    title: 'Native Mobile App Development',
    para: `Our native mobile app development services ensure that your app leverages the full potential of the 
    device’s capabilities. We develop high-performance apps that provide a superior user experience.`,
    // title: 'React Native App Development ',
    // para: `Experience React Native for a single codebase that runs on
    //  both iOS and Android. Enjoy faster development times and consistent
    //   performance across platforms. Today.`,
    img: mobileOne
  },
  {
    id: 8,
    title: 'Mobile App Prototyping',
    para: `Before we start the development process, we create detailed prototypes to give you a clear vision of the final product. Our mobile app prototyping services help you make informed decisions and ensure that the end product aligns with your expectations.`,
    // title: 'Flutter App Development',
    // para: `Create beautiful, fast apps with Flutter, Google’s UI toolkit. Our Flutter 
    // developers use one codebase to build native apps for iOS, Android, web, and desktop. `,
    img: mobileThree
  }
]
const mobileApp5a = [
  {
    id: 1,
    title: 'Our Process',
    para: `At Zai Systems, a leading application development company, we adhere to a structured process designed to ensure the success of your mobile app development process
    `,
    img: mobileForteen
  },

]
const mobileApp5 = [
  {
    id: 1,
    title: `Initial Consultation and Requirement Analysis`,
    para: `We begin by understanding your business needs and objectives. Our team conducts a detailed analysis to determine the ideal approach for your app project.`
    // title: `Unmatched Expertise`,
    // para: `Our developers master popular tech stacks like Elixir, Python, Ruby on Rails, Flutter, Ionic, and more to build your dream projects.`
  },
  {
    id: 2,
    title: `Design and Prototyping`,
    para: `After gathering your requirements, our designers craft precise wireframes and prototypes. This step helps visualize the app’s structure and functionality.`
    // title: `Quality Guaranteed`,
    // para: ` Say goodbye to buggy apps! We deliver high-performing, stable, and user-friendly mobile experiences that won't drain your battery.`

  },
  {
    id: 3,
    title: `Development`,
    para: `Our developers transform the designs into fully functional apps using modern tools and frameworks. We adopt agile practices for timely delivery and flexibility.`
    // title: `Value-Driven Solutions `,
    // para: `Save big with our comprehensive bundle: expert product managers, and rigorous software testing, all at a budget-friendly price.`

  },
  {
    id: 4,
    title: `Testing and Quality Assurance`,
    para: `We execute comprehensive testing to ensure the app is bug-free and performs flawlessly. Our team guarantees it meets the highest quality standards.`
    // title: `Complete Transparency`,
    // para: `Stay informed every step of the way. Your feedback is prioritized to ensure a robust and superior final product.`

  },
  {
    id: 5,
    title: `Deployment and Maintenance`,
    para: `Once the app is finalized, we handle deployment to app stores. We also offer support to keep your app updated and running efficiently.`
  },
  {
    id: 6,
    title: `User Training and Documentation`,
    para: `We provide training sessions and comprehensive guides to help your team manage and utilize the app effectively and independently.`
  }
]
const mobileApp8a = [
  {
    id: 1,
    title: 'Technologies We Excel In',
    para: '',
    img: mobilethirteen
  }

]
const mobileApp8 = [
  {
    id: 1,
    title: `Python Mobile App Development `,
    para: `Python is a versatile language that allows for rapid development and easy maintenance. Our Python mobile app development services ensure your app is scalable, secure, and robust.`,
    // title: `Industry-Proven Expertise`,
    // para: ` Our developers boast experience across diverse industries, crafting apps that resonate with users and drive commercial success.
    //  They've mastered the art of building apps that stay ahead of the curve, not just follow trends.`,
    img: mobileSix
  },
  {
    id: 2,
    title: `Angular Mobile App Development`,
    para: `Angular is a powerful framework for building dynamic mobile applications. Our developers are experts in Angular mobile app development, ensuring your app is fast, responsive, and user-friendly.`,
    //   title: `Transparency Under the Hood `,
    //   para: `Say goodbye to black boxes! We champion full transparency throughout the development journey. Daily collaborative
    //  meetings keep you in the loop, while your feedback seamlessly shapes the app's evolution.`,
    img: mobileEight
  },
  {
    id: 3,
    title: `Flutter App Development`,
    para: `Flutter allows the creation of beautiful, natively compiled applications from a single codebase. Our Flutter app development services ensure your app looks great and performs well on Android and iOS platforms.`,
    // title: `Tech-Savvy Experts`,
    // para: `Forget outdated tools – our developers are fluent in the latest technologies like Geo Tracking, AI/ML, Deep Analytics,
    //  and Cloud Computing. They wield these tools like magic, infusing your app with cutting-edge features and functionality.`,
    img: mobileOne
  },
  {
    id: 4,
    title: `React Native App Development`,
    para: `React Native enables the development of high-quality apps with a native look and feel. Our React Native app development services focus on creating apps that offer excellent performance and a smooth user experience.`,
    // title: `On-Time Every Time`,
    // para: `Need your app delivered on time, every time? Our team is built for precision. We use proven methods like WBS and 
    // PERT to ensure realistic timelines and flawless execution. Don't settle for delays – trust us to deliver your app 
    // exactly when you need it most.`,
    img: mobileNine
  },
  // {
  //   id: 5,
  //   title: `User-Centric Design `,
  //   para: `We don’t just build apps – we build experiences. Our designers put the user at the heart of every decision, creating
  //    intuitive and engaging interfaces that delight customers. We follow the best practices of UX/UI design, such as
  //     user research, wireframing, prototyping, and testing.`,
  //   img: mobileEleven
  // },
  // {
  //   id: 6,
  //   title: `Flexible Pricing, No Hidden Fees`,
  //   para: `We understand that every project is unique and has different budget constraints. That’s why we offer flexible pricing 
  //   options that suit your needs and goals. Whether you want a fixed-price contract, an hourly rate, or a hybrid model, we’ll work 
  //   with you to find the best solution. `,
  //   img: mobileTwelve
  // }
]
const mobileApp9a = [
  {
    id: 1,
    title: 'Why We Are the Best Mobile App Development Company',
    para: `With expertise in Android and iOS platforms, we offer end-to-end solutions tailored to your business needs, ensuring your app ranks high and outshines competitors in the digital market.`,
    img: mobilSeventeen
  }

]
const mobileApp9 = [
  {
    id: 1,
    title: `Expertise Across Industries`,
    para: `We have partnered with clients in industries like healthcare, finance, retail, and education. Our broad experience helps us tackle unique challenges and opportunities in each sector.`,
    img: mobileSix
  },
  {
    id: 2,
    title: `Angular Mobile App Development`,
    para: `At Zai Systems, we prioritize our clients and build lasting relationships. We focus on delivering solutions that truly enhance your business operations.`,
    img: mobileEight
  },
  {
    id: 3,
    title: `Innovative Solutions`,
    para: `Our team stays ahead by leveraging the latest trends and technologies. We provide cutting-edge mobile app solutions to help you stay competitive.`,
    img: mobileThree
  },
  {
    id: 4,
    title: `Transparent Communication `,
    para: `We maintain clear and open communication with clients throughout the project. You’ll always be informed, with no unexpected surprises along the way.`,
    img: mobileNine
  },
  {
    id: 5,
    title: `Affordable Pricing `,
    para: `Our mobile app development services are cost-effective and high-quality. Flexible pricing models are available to match various budgets and project needs.`,
    img: mobileEleven
  },
  {
    id: 6,
    title: `Mobile App Development Company USA`,
    para: `Zai Systems, based in the USA, is your trusted partner for app development. Whether for local or global projects, we create solutions tailored to your market.`,
    img: mobileTwelve
  }
]
const mobileApp10a = [
  {
    id: 1,
    title: 'Why Choose Zai Systems for Your Mobile App Development Needs?',
    para: `As a best-in-class mobile app development company, we understand the unique requirements of each business. Our dedicated team of developers, designers, and strategists work collaboratively to ensure that your mobile app not only meets but exceeds your expectations.
    `,
    img: mobileEighteen
  }

]

const mobileApp10 = [
  {
    id: 1,
    title: `Experienced Team`,
    para: `Our developers are experts in technologies like Python, Angular, Flutter, and React Native. This ensures we deliver reliable, scalable, and high-performing mobile solutions.`,
    img: mobileSix
  },
  {
    id: 2,
    title: `Customer-Centric Approach`,
    para: `We prioritize understanding your business goals to create apps that align perfectly with your vision and drive long-term success.`,
    img: mobileEight
  },
  {
    id: 3,
    title: `Comprehensive Services`,
    para: `From consultation and app prototyping to deployment and maintenance, we provide complete mobile app development services tailored to your needs.`,
    img: mobileFive
  },
  {
    id: 4,
    title: `Proven Track Record`,
    para: `We have successfully delivered numerous projects across industries, earning the trust of clients through exceptional results and unmatched expertise
`,
    img: mobileFive
  },
]
const testimonials = [
  {
    id: 1,
    para: `Zai Systems delivered a custom web app that has transformed our business operations. The app is fast, user-friendly, and precisely tailored to our needs. Their team exceeded our expectations with exceptional service and timely delivery.`,
    name: "Daniel Carter",
    title: "E-commerce Manager"
  },
  {
    id: 2,
    para: `Working with Zai Systems was a smooth experience. They developed a robust web app that streamlined our processes. The app is intuitive and perfectly aligned with our goals, boosting efficiency across teams.`,
    name: "Michelle Anderson",
    title: "Operations Head"
  },
  {
    id: 3,
    para: `The web application Zai Systems built has significantly enhanced productivity. Their developers understood our business needs and provided excellent insights. The final product is reliable and innovative.`,
    name: "Thomas Reed",
    title: "IT Project Manager"
  },
  {
    id: 4,
    para: `Zai Systems brought our vision to life with their web app development expertise. The result exceeded our expectations, and their team was supportive throughout the project. We highly recommend them.`,
    name: "Sophia Bennett",
    title: "Product Development Manager"
  },
  {
    id: 5,
    para: `From concept to execution, Zai Systems delivered an exceptional web app. Their creative solutions and timely delivery impressed us. The app’s performance and design have earned us great feedback.`,
    name: "Lucas Foster",
    title: "Business Development Manager"
  }
];

const SeoDataMobile = [
  {
    id: 1,
    title: `Custom Mobile App Development Services`,
    content: `Bring your ideas to life! With our unique and innovative Product design & development services for innovative brands.`,
    link: `https://www.zaisystems.com/services/mobile-app-development`
  }
]
// ui/ux design
const ui1 = [
  {
    id: 1,
    title: `UI/UX Design Services `,
    para: `At Zai Systems, we specialize in transforming digital experiences through exceptional UI/UX Design Services. Our team of seasoned designers and developers is committed to creating intuitive, engaging, and visually appealing web and mobile applications. Whether you're looking to refine your existing interface or build a new application from scratch, our expertise in Web Interface Design and User Experience Design ensures a seamless journey from concept to launch.
`,
    img: userExperience,
  }
]
const ui8a = [
  {
    id: 1,
    title: `Why Choose Zai Systems for Your UI/UX Design Needs?`,
    para: `In today’s competitive digital landscape, having an outstanding user interface (UI) and user experience (UX) can make all the difference. At Zai Systems, we understand that effective design is not just about aesthetics but also about creating functional, user-friendly solutions that drive engagement and satisfaction. `,
    img: uiEleven
  }

]
const ui8 = [
  {
    id: 1,
    title: 'Website Design & Development',
    para: `From sleek landing pages to intuitive dashboards, we design websites that are a joy
     to navigate and convert visitors into loyal customers.`,
    img: uiTwo
  },
  {
    id: 2,
    title: `Mobile App UI/UX Design`,
    para: `
    We craft intuitive and responsive mobile app interfaces that are not just visually stunning but also feel like an extension of your users' fingertips.
     `,
    img: uiOne
  },
  {
    id: 3,
    title: 'User Interface Prototyping',
    para: `Before a single line of code is written, we bring your vision to life with interactive prototypes, ensuring your 
    UI aligns perfectly with your user's needs.`,
    img: uiNine
  },

  {
    id: 4,
    title: 'Accessibility Compliance',
    para: `
    We build inclusive and equitable experiences that cater to users with diverse needs and abilities.
     `,
    img: uiFour
  },
  {
    id: 5,
    title: 'Conversion Optimization',
    para: `
    We design UIs that not only look good but also drive conversions, maximizing the return on your design investment.
     `,
    img: uiThirteen
  },
  {
    id: 6,
    title: 'Content Strategy & Development',
    para: `
    We craft compelling content that complements your design and resonates with your target audience.
     `,
    img: uiFourteen
  },
  {
    id: 7,
    title: 'Interaction Design',
    para: `
     We design seamless and immersive interactions that feel natural and delight your users, fostering deeper engagement with your brand.
     `,
    img: uiFifteen
  },

  {
    id: 8,
    title: 'User Research & Analysis',
    para: `
    We delve deep into your target audience's needs and pain points to design user-centric experiences that resonate and solve real problems.
     `,
    img: uiSixteen
  },
  {
    id: 9,
    title: 'Usability Testing & Optimization',
    para: `
    We put your design through rigorous testing to identify and refine any usability issues, ensuring a seamless user experience.
     `,
    img: uiSeventeen
  },
  {
    id: 10,
    title: 'Information Architecture & Navigation',
    para: `
    We craft intuitive information hierarchies and navigation systems that make it effortless for users to find what they need, when they need it.
     `,
    img: uiEighteen
  },
  {
    id: 11,
    title: 'Branding & Style Guide Development',
    para: `
    We create a distinctive and memorable brand identity that shines through every pixel, ensuring consistency and recognition across all your digital touchpoints.
     `,
    img: uiNineteen
  },
  {
    id: 12,
    title: 'Design Audit & Evaluation ',
    para: `We design from scratch and improve your existing UI/UX. We  
    audit and evaluate, identify strengths, weaknesses, and improvement opportunities.`,
    img: uiTwentyOne
  }

]
const ui4a = [
  {
    id: 1,
    title: 'Our UI/UX Design Services',
  },

]
const ui4 = [
  {
    id: 1,
    title: 'Web Interface Design',
    para: `Our Web Interface Design services are focused on creating visually appealing and highly functional web applications. We ensure that every element of your website or web app is designed with the user in mind, providing a seamless and enjoyable browsing experience.
`,
    list: [
      {
        id: 1,
        // title: 'Key Offerings in Generative AI Development:',
        para: `•<strong>Responsive Design</strong>: Ensuring your web interface looks and functions flawlessly across all devices and screen sizes.`,
      },
      {
        id: 2,
        // title: 'Key Offerings in Generative AI Development:',
        para: `•	Visual Design: Crafting attractive and on-brand designs that captivate users and enhance usability.`,
      },
      {
        id: 3,
        // title: 'Key Offerings in Generative AI Development:',
        para: `•	Interaction Design: Designing interactive elements that make the user journey intuitive and engaging.`,
      },
    ],
    img: uiOne
  },
  {
    id: 2,
    title: 'User Experience Design',
    para: `Effective User Experience Design is crucial for ensuring that users have a positive interaction with your digital products. Our team focuses on understanding user behavior and needs to create designs that improve usability and satisfaction.`,
    list: [
      {
        id: 1,
        // title: 'Key Offerings in Generative AI Development:',
        para: `•	UX Research: Conducting thorough research to understand user needs, behaviors, and pain points.`,
      },
      {
        id: 2,
        // title: 'Key Offerings in Generative AI Development:',
        para: `•	Information Architecture & Navigation: Structuring your content and navigation to ensure users can find what they need quickly and easily.`,
      },
      {
        id: 3,
        // title: 'Key Offerings in Generative AI Development:',
        para: `•	Prototyping: Developing prototypes to test and refine user flows and interactions before full-scale development.`,
      },
    ],
    img: uiTwo,
  },
  {
    id: 3,
    title: 'UX Audit Services',
    para: `Our UX Audit Services are designed to evaluate and enhance the usability of your existing digital products. We perform comprehensive audits to identify usability issues and recommend improvements.    `,
    list: [
      {
        id: 1,
        // title: 'Key Offerings in Generative AI Development:',
        para: `•	Heuristic Evaluation: Assessing your interface based on established usability principles.`,
      },
      {
        id: 2,
        // title: 'Key Offerings in Generative AI Development:',
        para: `•	User Testing: Conducting tests with real users to gather feedback on their experience.`,
      },
      {
        id: 3,
        // title: 'Key Offerings in Generative AI Development:',
        para: `•	Competitive Analysis: Comparing your product with competitors to identify strengths and areas for improvement.`,
      },
    ],
    img: uiThree

  },
  {
    id: 4,
    title: 'UI Development',
    para: `Effective UI Development is essential for bringing your design concepts to life. Our development team ensures that your UI designs are implemented with precision and functionality.`,
    list: [
      {
        id: 1,
        // title: 'Key Offerings in Generative AI Development:',
        para: `•	Front-End Development: Building responsive and interactive interfaces using the latest technologies.`,
      },
      {
        id: 2,
        // title: 'Key Offerings in Generative AI Development:',
        para: `•	Performance Optimization: Ensuring your UI performs well and loads quickly, enhancing the overall user experience.`,
      },
      {
        id: 3,
        // title: 'Key Offerings in Generative AI Development:',
        para: `•	Cross-Browser Compatibility: Testing and optimizing your UI across various browsers and devices.`,
      },
    ],
    img: uiEleven

  },
  {
    id: 5,
    title: 'Interaction Design',
    para: `Interaction Design focuses on how users interact with your digital products. We design interactive elements that are intuitive, engaging, and easy to use.`,
    list: [
      {
        id: 1,
        // title: 'Key Offerings in Generative AI Development:',
        para: `•	Interactive Prototyping: Creating prototypes to visualize and test interactive elements.`,
      },
      {
        id: 2,
        // title: 'Key Offerings in Generative AI Development:',
        para: `•	Microinteractions: Designing small, subtle interactions that enhance user engagement and satisfaction.`,
      },
      {
        id: 3,
        // title: 'Key Offerings in Generative AI Development:',
        para: `•	Feedback Mechanisms: Implementing feedback features to provide users with clear responses to their actions.`,
      },
    ],
    img: uiFive

  },
  {
    id: 6,
    title: 'Mobile App UX Design',
    para: `In the mobile-first era, Mobile App UX Design is critical for success. We design mobile experiences that are optimized for touch interfaces and small screens.`,
    list: [
      {
        id: 1,
        // title: 'Key Offerings in Generative AI Development:',
        para: `•	User Flow Design: Creating intuitive user flows that simplify navigation and task completion on mobile devices.`,
      },
      {
        id: 2,
        // title: 'Key Offerings in Generative AI Development:',
        para: `•	Screen Optimization: Designing for various screen sizes and resolutions to ensure a consistent experience.`,
      },
      {
        id: 3,
        // title: 'Key Offerings in Generative AI Development:',
        para: `•	Touch-Friendly Interfaces: Implementing touch-friendly elements that enhance usability on mobile devices.`,
      },
    ],
    img: uiSix

  },
  {
    id: 7,
    title: 'Web UX Design',
    para: `Web UX Design is essential for creating engaging and user-friendly web experiences. Our focus is on designing web interfaces that are easy to navigate and enjoyable to use.`,
    list: [
      {
        id: 1,
        // title: 'Key Offerings in Generative AI Development:',
        para: `•	User-Centric Design: Prioritizing user needs and preferences in the design process.`,
      },
      {
        id: 2,
        // title: 'Key Offerings in Generative AI Development:',
        para: `•	Content Organization: Structuring content in a way that makes it easy to find and understand.`,
      },
      {
        id: 3,
        // title: 'Key Offerings in Generative AI Development:',
        para: `•	Visual Hierarchy: Using design elements to guide users’ attention and actions effectively.`,
      },
    ],
    img: uiSeven

  },
  {
    id: 8,
    title: 'Website Design & Development',
    para: `Our comprehensive Website Design & Development services cover everything from initial design concepts to final implementation. We ensure that your website not only looks great but also performs well. `,
    list: [
      {
        id: 1,
        // title: 'Key Offerings in Generative AI Development:',
        para: `•	Custom Design: Creating unique, custom designs that reflect your brand and meet your specific needs.`,
      },
      {
        id: 2,
        // title: 'Key Offerings in Generative AI Development:',
        para: `•	Development: Building your website with the latest technologies and best practices.`,
      },
      {
        id: 3,
        // title: 'Key Offerings in Generative AI Development:',
        para: `•	Maintenance & Support: Providing ongoing support to ensure your website remains up-to-date and functional.`,
      },
    ],
    img: uiEight

  },
  {
    id: 9,
    title: 'User Interface Prototyping',
    para: `User Interface Prototyping is a crucial part of our design process, allowing us to visualize and test designs before development. `,
    list: [
      {
        id: 1,
        // title: 'Key Offerings in Generative AI Development:',
        para: `•	Low-Fidelity Prototypes: Creating basic wireframes to outline the structure and flow of your interface.`,
      },
      {
        id: 2,
        // title: 'Key Offerings in Generative AI Development:',
        para: `•	High-Fidelity Prototypes: Developing detailed, interactive prototypes that closely resemble the final product.`,
      },
      {
        id: 3,
        // title: 'Key Offerings in Generative AI Development:',
        para: `•	User Testing: Conducting tests with prototypes to gather feedback and make necessary adjustments.`,
      },
    ],
    img: uiNine

  },
  {
    id: 10,
    title: 'Information Architecture & Navigation',
    para: `Information Architecture & Navigation is vital for ensuring that users can easily find and access the information they need. `,
    list: [
      {
        id: 1,
        // title: 'Key Offerings in Generative AI Development:',
        para: `•	Site Mapping: Creating a map of your website’s structure to organize content effectively.`,
      },
      {
        id: 2,
        // title: 'Key Offerings in Generative AI Development:',
        para: `•	Navigation Design: Designing clear and intuitive navigation systems that guide users through your site.`,
      },
      {
        id: 3,
        // title: 'Key Offerings in Generative AI Development:',
        para: `•	Content Structuring: Organizing content in a way that makes it easy for users to locate and understand.`,
      },
    ],
    img: uiTen

  },
]

const ui12a = [
  {
    title: `Our toolbox is brimming with industry favorites`,
    // para:`With our top technologies, your success is guaranteed.`
  }
]
const ui12 = [
  {
    id: 1,
    title: 'Sketch ',
    img: uiFive,
    para: `The industry standard for vector design, perfect for crafting stunning and scalable user interfaces.`

  },
  {
    id: 2,
    title: 'Craft ',
    para: `Supercharge your design workflow with powerful plugins and integrations that boost productivity and creativity.`,
    img: uiEight

  },
  {
    id: 3,
    title: 'Proto.io ',
    para: `Dive deep into interaction design with robust prototyping features and code-free animation tools.`,
    img: uiNine

  },
  {
    id: 4,
    title: 'InVision Studio ',
    para: `Bring your designs to life with interactive prototypes, fostering seamless collaboration and stakeholder buy-in.`,
    img: uiSix

  },
  {
    id: 5,
    title: 'Axure RP',
    para: `Go beyond basic prototypes with advanced interaction design capabilities and powerful wireframing tools.`,
    img: uiSeven

  },

  {
    id: 6,
    title: 'Adobe XD ',
    para: `Design, prototype, and share – Adobe XD’s interface and integration with Adobe tools make it a workflow powerhouse.
    `,
    img: uiTen

  },
  {
    id: 7,
    title: 'Figma ',
    para: `Figma's collaborative features and web-based platform make it a game-changer for distributed teams.`,
    img: uiTwelve

  },
  {
    id: 8,
    title: 'Marvel',
    para: ` Turn static mockups into interactive prototypes with a few clicks, perfect for quick testing and iteration.
    `,
    img: uiEleven

  },
  {
    id: 9,
    title: 'Balsamiq',
    para: `Balsamiq lets you create wireframes and mockups quickly. Its sketch-like style helps you brainstorm and share ideas.
   `,
    img: uiTwenty

  }
]
const ui5a = [
  {
    id: 1,
    title: 'So, why choose Zai Systems?',
    // para: `We've helped many companies in the Pakistan and USA succeed with their mobile apps, and we do it by focusing on 6 key things:`
  },

]
const ui5 = [
  {
    id: 1,
    title: `We speak tech fluently`,
    para: `We understand the intricacies of each tool and leverage their strengths to craft optimal solutions for your unique needs.`
  },
  {
    id: 2,
    title: `We prioritize collaboration`,
    para: `We work hand-in-hand with you to ensure your innovative vision is translated flawlessly into pixels.`

  },
  {
    id: 3,
    title: `We are innovation driven`,
    para: `We stay at the forefront of UI/UX design trends and technologies, ensuring your experience is future-proof. `

  }

  //, {
  //   id: 6,
  //   title: `100% Confidentiality `,
  //   para: `Rest assured about your project confidentiality! Leveraging the latest technologies and tools to keep your data and other
  //    vital information safe is the top practice of our mobile app development team. `

  // }
]
const ui2 = [
  {
    id: 1,
    title: `Why Choose Zai Systems for Your UI/UX Design Needs?`,
    para: `In today’s competitive digital landscape, having an outstanding user interface (UI) and user experience (UX) can make all the difference. At Zai Systems, we understand that effective design is not just about aesthetics but also about creating functional, user-friendly solutions that drive engagement and satisfaction.`,
  },

]

const SeoDataUi = [
  {
    id: 1,
    title: `UI/UX Design Services`,
    content: `Streamline Your App & Website and eliminate confusion, optimize workflow, & boost ROI with our data-driven UI/UX design solutions. Learn more!`,
    link: `https://www.zaisystems.com/services/ui-ux-design`
  }
]



// enterprise app dev
const enterprise1 = [
  {
    id: 1,
    title: `Enterprise Software Development Services`,
    para: `In today's fast-paced digital world, businesses need robust and scalable enterprise software solutions to stay ahead of the competition. At Zai Systems, we offer front-line enterprise software development services in Pakistan and worldwide that are designed to meet the exclusive needs of your organization. Whether you're looking to enhance operational efficiency, improve customer engagement, or drive innovation, our expert team is here to help you achieve your business goals.`,
    img: Enterprise,
  }
]
const enterprise6a = [
  {
    id: 1,
    title: `Our Process for Crafting Your Perfect Enterprise Software Solution`,
    para: `At Zai Systems, we know your dream enterprise software isn't just a line item on a budget; it's a vital tool to transform your business and propel you forward. That's why we've crafted a process 
    that guides you every step of the way, from the initial spark of an idea to seamless implementation and beyond.`,
  },

]
const enterprise6 = [
  {
    id: 1,
    title: `Discovery & Planning`,
    para: `
    To build a strong partnership for complex projects, we collaborate closely with you to understand your business goals, and vision.
    `
  },
  {
    id: 2,
    title: `Design & Development`,
    para: `
    We translate your vision into a comprehensive design and development plan, utilizing the latest technologies and best practices.
   `
  },
  {
    id: 3,
    title: `Testing & Deployment`,
    para: `We rigorously test your software to ensure it meets all your requirements before seamless deployment.`
  },
  {
    id: 4,
    title: `Support & Maintenance`,
    para: `We remain your trusted partner, providing ongoing support and maintenance to ensure your software's continued success.`
  },
  {
    id: 5,
    title: `Security & Compliance`,
    para: `We take security and compliance seriously, ensuring your software meets the highest standards of 
     data protection and regulation.`
  },
  {
    id: 6,
    title: `Evaluation & Improvement`,
    para: `We monitor the performance and feedback of your software and suggest improvements and enhancements to keep it up to date.
    `
  },
]
const enterprise4a = [
  {
    id: 1,
    title: `Our Enterprise Software Development Services`,
    para: `At Zai Systems, one of the best it company in Pakistan, we offer a wide range of services designed to meet the diverse needs of our clients.`
  }
]
const enterprise4 = [
  {
    id: 1,
    title: 'Enterprise Application Development',
    para: `Our enterprise application development services are designed to create scalable and reliable applications that streamline business processes and improve productivity. We utilize the latest technologies and best practices to develop applications that meet the unique needs of your organization.`,
    img: enterpriseTwo
  },
  {
    id: 2,
    title: 'Enterprise Mobility Services',
    para: `In today's mobile-first world, having a strong mobile presence is essential. Our enterprise mobility services enable businesses to stay connected with their customers and employees, no matter where they are. We develop mobile applications that are not only user-friendly but also secure and scalable.`,
    img: enterpriseFour
  },
  {
    id: 3,
    title: 'Enterprise Web App Development',
    para: `Our enterprise web app development services focus on creating robust, high-performing web applications that are tailored to your business needs. We ensure that our web applications are scalable, secure, and easy to maintain.`,
    img: enterpriseFive
  },
  {
    id: 4,
    title: 'Enterprise Data Management',
    para: `Effective data management is crucial for making informed business decisions. Our enterprise data management services help you organize, store, and analyze your data to gain valuable insights and drive business growth.`,
    img: enterpriseNine
  },
  {
    id: 5,
    title: 'Enterprise Mobile App Development',
    para: `Mobile applications are essential for businesses looking to stay competitive. Our enterprise mobile app development services focus on creating mobile applications that are both innovative and functional, helping you engage with your customers and employees more effectively.`,
    img: enterpriseTen
  },
  {
    id: 6,
    title: 'Enterprise Application Integration',
    para: `Integrating various enterprise applications can be a complex task, but our enterprise application integration services make it seamless. We ensure that all your enterprise applications work together harmoniously, enhancing operational efficiency.`,
    img: enterpriseSix
  },
  {
    id: 7,
    title: 'Legacy App Modernization',
    para: `Modernizing legacy applications is essential for staying competitive in today's digital landscape. Our legacy app modernization services help you update your existing applications with the latest technologies, improving performance and extending their lifespan.`,
    img: enterpriseSeven
  },
  {
    id: 8,
    title: 'Software Integration Services',
    para: `Our software integration services ensure that your enterprise software systems work together seamlessly, improving efficiency and reducing operational costs. We specialize in integrating various software applications, both on-premise and in the cloud.`,
    img: enterpriseEight
  },
  {
    id: 9,
    title: 'Business Intelligence Solutions',
    para: `At Zai Systems, we empower businesses to make smarter decisions, drive innovation, and achieve their goals through cutting-edge Business Intelligence (BI) solutions. Our expertise in data analytics, reporting, and visualization enables companies to unlock the full potential of their data, turning it into actionable insights. Whether you are a startup or an established enterprise, Zai Systems delivers customized solutions tailored to your unique needs, helping you stay ahead in today’s competitive market.`,
    img: enterpriseNine
  },
  {
    id: 10,
    title: 'Custom Business Software Development',
    para: `At Zai Systems, we understand that every business is unique, and so are its software needs. Our custom business software development services are designed to create software solutions that are tailored to your specific business processes. Whether you need a CRM system, ERP solution, or any other business software, we have the expertise to deliver a solution that meets your needs.`,
    img: enterpriseTen
  },
  {
    id: 11,
    title: 'Advanced Business Software',
    para: `Our team is skilled in developing advanced business software that leverages the latest technologies to provide you with a competitive edge. We focus on creating software that is not only powerful but also user-friendly and scalable.`,
    img: enterpriseEleven
  },

]
const enterprise8a = [
  {
    id: 1,
    title: `Hire Zai Systems' Enterprise App Developers`,
    para: `Investing in enterprise software development offers numerous benefits`,
    img: enterpriseEleven
  },

]
const enterprise8 = [
  {
    id: 1,
    title: 'Increased Efficiency',
    para: `Custom enterprise software is designed to streamline business processes, reducing the time and effort required to complete tasks.`,
    img: project
  },
  {
    id: 2,
    title: 'Scalability',
    para: `As your business grows, your software can grow with you. Custom software solutions are scalable and can be easily updated to meet changing business needs.`,
    img: hire

  },
  {
    id: 3,
    title: 'Competitive Advantage',
    para: `Custom software gives you a competitive edge by providing features and functionalities that are tailored to your specific needs.`,
    img: dedicatedTeam

  },
  {
    id: 4,
    title: 'Better Decision Making ',
    para: `With features like business intelligence solutions, custom software provides you with the insights you need to make informed business decisions.`,
    img: dedicatedTeam

  }
]
const enterprise12a = [
  {
    id: 1,
    title: `Why Choose Zai Systems for Enterprise Software Development?`,
    para: `Zai Systems is a leading enterprise software development company with a proven track record of delivering high-quality, customized solutions to businesses across various industries. Our approach is centered around understanding your specific needs and providing tailored solutions that align with your business objectives.`
  },

]
const enterprise12 = [
  {
    id: 1,
    title: 'Customized Solutions:',
    para: `We don't believe in one-size-fits-all. Our custom enterprise software development services ensure that the solutions we create are tailored to your business processes, helping you achieve greater efficiency and productivity.`,
    img: project
  },
  {
    id: 2,
    title: 'Expert Team:',
    para: `: Our team of experienced developers, designers, and consultants work collaboratively to deliver top-notch solutions that drive business growth.`,
    img: hire

  },
  {
    id: 3,
    title: 'End-to-End Services: ',
    para: `From initial consultation to deployment and support, we provide comprehensive enterprise software consulting services to ensure the success of your project.`,
    img: dedicatedTeam

  }
]
const enterprise13a = [
  {
    id: 1,
    title: 'Industries we serve',
  }
]
const enterprise13b = [
  {
    id: 1,
    title: '•	Healthcare'
  },
  {
    id: 2,
    title: '•	Financial Services'
  },
  {
    id: 3,
    title: '•	Retail and E-commerce'
  },
  {
    id: 4,
    title: '•	Manufacturing'
  },
  {
    id: 5,
    title: '•	Logistics and Transportation'
  },
]
const enterprise13c = [
  {
    id: 1,
    title: '•	Education'
  },
  {
    id: 2,
    title: '•	Government'
  },
  {
    id: 3,
    title: '•	Telecommunications'
  },
  {
    id: 4,
    title: '•	Energy and Utilities',
  },
  {
    id: 5,
    title: 'Technology',
  }
]
const nft13b = [
  {
    id: 1,
    title: '•	Art & Entertainment'
  },
  {
    id: 2,
    title: '•	Gaming'
  },
  {
    id: 3,
    title: '•	Real Estate'
  },
  {
    id: 4,
    title: '•	Fashion & Luxury'
  },
  {
    id: 5,
    title: '•	Sports & Esports'
  },
  {
    id: 6,
    title: '•	Music & Audio'
  },
  {
    id: 7,
    title: '•	Collectibles & Memorabilia'
  },
  {
    id: 8,
    title: '•	Healthcare & Pharmaceuticals'
  },
  {
    id: 9,
    title: '• Finance & Banking'
  },
  {
    id: 10,
    title: '•	Education & E-Learning'
  },
]
const nft13c = [
  {
    id: 1,
    title: '•	Social Media & Influencers'
  },
  {
    id: 2,
    title: '•	Travel & Hospitality'
  },
  {
    id: 3,
    title: '•	Advertising & Marketing'
  },
  {
    id: 4,
    title: '•	Virtual Reality (VR) & Augmented Reality (AR)'
  },
  {
    id: 5,
    title: '•	Automotive & Mobility'
  },
  {
    id: 6,
    title: '•	Publishing & Media'
  },
  {
    id: 7,
    title: '•	Fine Art & Galleries'
  },
  {
    id: 8,
    title: '•	Auction Houses & Bidding Platforms'
  },
  {
    id: 9,
    title: '•	Non-Profit & Charitable Organizations'
  },
  {
    id: 10,
    title: '•	Government & Public Sector'
  },
]
const enterprisetestimonials = [
  {
    id: 1,
    para: `Zai Systems created custom enterprise software that streamlined our operations. Their team was highly professional, and the final product exceeded our expectations, enhancing workflow and efficiency.`,
    name: "Olivia Parker",
    title: "Chief Operating Officer"
  },
  {
    id: 2,
    para: `Their integration services were seamless and ensured a smooth transition for our business. Zai Systems provided excellent support and expertise during the process.`,
    name: "Matthew Collins",
    title: "IT Infrastructure Manager"
  },
  {
    id: 3,
    para: `The enterprise mobility services offered by Zai Systems have transformed our team’s efficiency. We can now access critical business data on the go, which has significantly improved response times.`,
    name: "Emma Taylor",
    title: "Operations Manager"
  },
  {
    id: 4,
    para: `Zai Systems modernized our legacy apps, giving them new life. The updates enhanced performance and extended their usefulness. Their team’s expertise was invaluable.`,
    name: "James Harris",
    title: "Technical Lead"
  },
  {
    id: 5,
    para: `The custom enterprise web app developed by Zai Systems has elevated our e-commerce platform. It is user-friendly and secure, which has boosted our sales and customer satisfaction.`,
    name: "Sophia Martin",
    title: "E-commerce Director"
  }
];

// const enterprise14 = [
//   {
//     id: 1,
//     title: 'Conclusion',
//     para: "In today's competitive business environment, having the right software can make all the difference. At Zai Systems, Top rated it company in Pakistan,  we are dedicated to providing top-quality enterprise software development services that help you achieve your business goals. Whether you need a custom CRM system, an ERP solution, or any other enterprise software, our team has the expertise to deliver a solution that meets your needs.",
//     para2: "Contact us today to learn more about how we can help your business grow with our custom enterprise software solutions."
//   }
// ]
const enterpriseFAQs = [
  { questions: 'What is enterprise software development?', answers: 'Enterprise software development involves creating software solutions designed to meet the specific needs of large organizations. These solutions can range from custom applications to complex integrations with existing systems.' },
  { questions: 'How does Zai Systems ensure the quality of its software?', answers: 'At Zai Systems, we follow a rigorous development process that includes thorough testing, quality assurance, and continuous feedback from our clients. This ensures that the software we deliver meets the highest standards of quality and performance.' },
  { questions: 'Can you modernize our existing legacy applications?', answers: 'Yes, we offer legacy app modernization services that update and optimize your existing software, improving performance and extending its lifespan.' },
  { questions: 'How long does it take to develop custom enterprise software?', answers: 'The timeline for developing custom enterprise software depends on the complexity of the project and your specific requirements. We work closely with our clients to establish a timeline that meets their needs.' },
  { questions: 'Do you offer software integration services?', answers: 'Yes, we provide software integration services to ensure that new applications work seamlessly with your existing systems, minimizing disruption and maximizing efficiency.' },
  { questions: 'What is enterprise application development?', answers: 'Enterprise application development involves creating robust, scalable applications that support the operations of large organizations. These applications are designed to handle complex processes and large volumes of data.' },
  { questions: 'Can you develop mobile applications for our enterprise?', answers: 'Yes, we offer enterprise mobile app development services that create custom applications aligned with your business goals. Our mobile apps are intuitive, secure, and designed to enhance productivity.' },
  { questions: 'What are enterprise mobility services?', answers: 'Enterprise mobility services involve developing mobile applications that allow your workforce to stay connected and productive, regardless of location. These services are essential for businesses that operate in a mobile-first environment.' },
];
const SeoDataEnterprise = [
  {
    id: 1,
    title: `Custom Enterprise Application Development Services`,
    content: `Gain a strategic advantage with custom enterprise applications. We build solutions that automate tasks, streamline workflows, & give you a data-driven edge over the competition. Be the leader, not a follower!`,
    link: `https://www.zaisystems.com/services/enterprise-application-development`
  }
]
// custom software dev
const customSoftware1 = [
  {
    id: 1,
    title: `Custom Software Development Services by Zai Systems`,
    para: `Welcome to Zai Systems, your premier custom software development company specializing in providing comprehensive custom software development services. We understand that every business is unique, and so are its software needs. Whether you require custom web app development, Enterprise Resource Planning (ERP) Systems, or business intelligence software, our team is dedicated to crafting solutions that perfectly align with your business objectives. With a focus on delivering high-quality, scalable, and secure custom software solutions, we are here to support your growth and success.`,
    img: CustomSoftware,
  }
]

const customSoftware3 = [
  {
    id: 1,
    title: `Ready to Get Started? `,
  },

]
const customSoftware4a = [
  {
    id: 1,
    title: `Our Custom Software Development Services`,
    para: `At Zai Systems, we provide a broad range of custom software development services to address diverse business needs.`
  },

]
const customSoftware4 = [
  {
    id: 1,
    title: 'Web App Development ',
    para: `Our team excels in developing Custom Business Applications that streamline operations and enhance productivity. These applications are designed to address specific challenges and workflows within your organization, providing you with tools tailored to your exact needs.`,
    list: [
      {
        id: 1,
        // title: 'Key Offerings in Generative AI Development:',
        para: `•	Workflow Automation: Automate repetitive tasks and optimize business processes.`,
      },
      {
        id: 2,
        // title: 'Key Offerings in Generative AI Development:',
        para: `•	Data Management: Centralize data for better analysis and decision-making.`,
      },
      {
        id: 3,
        // title: 'Key Offerings in Generative AI Development:',
        para: `•	User-Friendly Interfaces: Design intuitive interfaces to improve user experience.`,
      },
    ],
    img: customOne
  },

  {
    id: 2,
    title: 'Custom Web App Development',
    para: `Custom web app development is a cornerstone of our service offerings. We create robust web applications that provide seamless user experiences and are optimized for performance across all devices.`,
    list: [
      {
        id: 1,
        // title: 'Key Offerings in Generative AI Development:',
        para: `•	Responsive Design: Ensure your web applications work flawlessly on desktops, tablets, and smartphones.`,
      },
      {
        id: 2,
        // title: 'Key Offerings in Generative AI Development:',
        para: `•	Scalability: Build applications that grow with your business needs.`,
      },
      {
        id: 3,
        // title: 'Key Offerings in Generative AI Development:',
        para: `•	Security: Implement advanced security features to protect sensitive data.`,
      },
    ],
    img: customTwo
  },
  {
    id: 3,
    title: 'Enterprise Resource Planning (ERP) Systems',
    para: `An effective Enterprise Resource Planning (ERP) System integrates various functions into one comprehensive system. Our ERP solutions help you manage core business processes, including finance, HR, supply chain, and more.`,
    list: [
      {
        id: 1,
        // title: 'Key Offerings in Generative AI Development:',
        para: `•	Integrated Modules: Connect all aspects of your business for cohesive operation.`,
      },
      {
        id: 2,
        // title: 'Key Offerings in Generative AI Development:',
        para: `•	Real-Time Reporting: Access real-time data for informed decision-making.`,
      },
      {
        id: 3,
        // title: 'Key Offerings in Generative AI Development:',
        para: `•	Custom Features: Tailor the ERP system to your specific business processes.`,
      },
    ],
    img: customThree
  },
  {
    id: 4,
    title: 'Human Resource Management Systems (HRMS)',
    para: `Managing human resources efficiently is crucial for any organization. Our Human Resource Management Systems (HRMS) offer comprehensive features to handle employee data, payroll, recruitment, and more.`,
    list: [
      {
        id: 1,
        // title: 'Key Offerings in Generative AI Development:',
        para: `•	Employee Self-Service: Allow employees to manage their own information and requests.`,
      },
      {
        id: 2,
        // title: 'Key Offerings in Generative AI Development:',
        para: `•	Payroll Management: Streamline payroll processing and compliance.`,
      },
      {
        id: 3,
        // title: 'Key Offerings in Generative AI Development:',
        para: `•	Talent Management: Enhance recruitment, performance evaluation, and training.`,
      },
    ],
    img: customFour
  },
  {
    id: 5,
    title: 'Content Management Systems (CMS)',
    para: `Managing content effectively is key to engaging your audience. Our Content Management Systems (CMS) are designed to make content creation, editing, and management simple and efficient.`,
    list: [
      {
        id: 1,
        // title: 'Key Offerings in Generative AI Development:',
        para: `•	User-Friendly Interface: Empower non-technical users to manage content effortlessly.`,
      },
      {
        id: 2,
        // title: 'Key Offerings in Generative AI Development:',
        para: `•	Customizable Templates: Tailor the CMS to fit your branding and design needs.`,
      },
      {
        id: 3,
        // title: 'Key Offerings in Generative AI Development:',
        para: `•	SEO Integration: Optimize content for search engines to boost visibility.`,
      },
    ],
    img: customFive
  },
  {
    id: 6,
    title: 'Electronic Health Records (EHR)',
    para: `For healthcare providers, maintaining accurate and accessible patient records is essential. Our Electronic Health Records (EHR) solutions ensure compliance and enhance patient care.`,
    list: [
      {
        id: 1,
        // title: 'Key Offerings in Generative AI Development:',
        para: `•	Data Security: Protect sensitive patient information with robust security measures.       `,
      },
      {
        id: 2,
        // title: 'Key Offerings in Generative AI Development:',
        para: `•	Interoperability: Integrate EHR with other healthcare systems for seamless data sharing.`,
      },
      {
        id: 3,
        // title: 'Key Offerings in Generative AI Development:',
        para: `•	Patient Engagement: Improve patient access to their health information and care plans.`,
      },
    ],
    img: customSix
  },
  {
    id: 7,
    title: 'Customer Relationship Management Software (CRM)',
    para: `Effective management of customer relationships is vital for business growth. Our Customer Relationship Management (CRM) Software helps you track interactions, manage sales processes, and enhance customer satisfaction.`,
    list: [
      {
        id: 1,
        // title: 'Key Offerings in Generative AI Development:',
        para: `•	Lead Management: Track and nurture lead through the sales funnel.`,
      },
      {
        id: 2,
        // title: 'Key Offerings in Generative AI Development:',
        para: `•	Customer Insights: Gain valuable insights into customer behavior and preferences.`,
      },
      {
        id: 3,
        // title: 'Key Offerings in Generative AI Development:',
        para: `•	Automated Workflows: Streamline customer interactions and follow-ups.`,
      },
    ],
    img: customSeven
  },
  {
    id: 8,
    title: 'Supply Chain Management Software',
    para: `Efficient supply chain management is critical for optimizing operations and reducing costs. Our Supply Chain Management Software helps you manage inventory, logistics, and procurement processes seamlessly.`,
    list: [
      {
        id: 1,
        // title: 'Key Offerings in Generative AI Development:',
        para: `•	Inventory Control: Monitor stock levels and manage reorders effectively.`,
      },
      {
        id: 2,
        // title: 'Key Offerings in Generative AI Development:',
        para: `•	Logistics Management: Optimize transportation and distribution.`,
      },
      {
        id: 3,
        // title: 'Key Offerings in Generative AI Development:',
        para: `•	Supplier Management: Improve relationships with suppliers and streamline procurement.`,
      },
    ],
    img: customEight
  },
  {
    id: 9,
    title: 'Enterprise Asset Management (EAM)',
    para: `Managing enterprise assets effectively can lead to significant cost savings and improved operational efficiency. Our Enterprise Asset Management (EAM) solutions provide tools for tracking, maintaining, and optimizing assets.`,
    list: [
      {
        id: 1,
        // title: 'Key Offerings in Generative AI Development:',
        para: `•	Asset Tracking: Monitor the location and condition of assets in real-time.`,
      },
      {
        id: 2,
        // title: 'Key Offerings in Generative AI Development:',
        para: `•	Maintenance Management: Schedule and track maintenance activities to prevent downtime.`,
      },
      {
        id: 3,
        // title: 'Key Offerings in Generative AI Development:',
        para: `•	Lifecycle Management: Manage the entire lifecycle of assets from acquisition to disposal.`,
      },
    ],
    img: customNine
  },
  {
    id: 9,
    title: 'Custom Accounting Software',
    para: `Our Custom Accounting Software is designed to meet the specific financial management needs of your business. From bookkeeping to financial reporting, we provide solutions that ensure accuracy and compliance.`,
    list: [
      {
        id: 1,
        // title: 'Key Offerings in Generative AI Development:',
        para: `•	Financial Reporting: Generate detailed financial reports and statements.`,
      },
      {
        id: 2,
        // title: 'Key Offerings in Generative AI Development:',
        para: `•	Tax Compliance: Stay compliant with tax regulations and simplify tax reporting.`,
      },
      {
        id: 3,
        // title: 'Key Offerings in Generative AI Development:',
        para: `•	Expense Management: Track and manage business expenses efficiently.`,
      },
    ],
    img: customTen
  },
  {
    id: 10,
    title: 'Business Intelligence Software',
    para: `Harness the power of your data with our Business Intelligence Software. We provide solutions that offer deep insights into your business operations, helping you make data-driven decisions.`,
    list: [
      {
        id: 1,
        // title: 'Key Offerings in Generative AI Development:',
        para: `•	Data Visualization: Create interactive dashboards and reports.`,
      },
      {
        id: 2,
        // title: 'Key Offerings in Generative AI Development:',
        para: `•	Advanced Analytics: Perform complex analyses to uncover trends and insights.`,
      },
      {
        id: 3,
        // title: 'Key Offerings in Generative AI Development:',
        para: `•	Data Integration: Combine data from various sources for comprehensive analysis.`,
      },
    ],
    img: customEleven
  },
  {
    id: 10,
    title: 'Business Process Management (BPM)',
    para: `Optimize and automate your business processes with our Business Process Management (BPM) solutions. We help you design, execute, and monitor processes to improve efficiency and effectiveness.`,
    list: [
      {
        id: 1,
        // title: 'Key Offerings in Generative AI Development:',
        para: `•	Process Mapping: Visualize and document business processes.`,
      },
      {
        id: 2,
        // title: 'Key Offerings in Generative AI Development:',
        para: `•	Automation: Streamline repetitive tasks and workflows.`,
      },
      {
        id: 3,
        // title: 'Key Offerings in Generative AI Development:',
        para: `•	Performance Monitoring: Track process performance and identify areas for improvement.`,
      },
    ],
    img: customTwelve
  }

]
const customSoftware8a = [
  {
    id: 1,
    title: `Custom Software Solutions That Empower Enterprise Success`,
    para: `Tired of off-the-shelf software that hinders your growth? At Zai Systems, we craft bespoke enterprise solutions that automate workflows,
     improve performance, and simplify operations, propelling your business toward sustainable success. Our team of expert custom software developers
      understands the unique challenges and demands of today's dynamic market, building solutions that empower your enterprise to thrive in every aspect.`,
  },

]
const customSoftware8 = [
  {
    id: 1,
    title: ' Human Resource Management systems',
    para: `Streamline staffing, recruiting, and consulting with end-to-end HR software. Zai Systems boosts efficiency, collaboration, productivity, and resource utilization with custom solutions tailored to your organizational needs.`,
  },
  {
    id: 2,
    title: 'Content Management Systems (CMS)',
    para: `Manage website content effortlessly with a custom CMS. Update videos, product descriptions, imagery, and text without technical expertise, eliminating external reliance and maximizing content control for your business.`,
  },
  {
    id: 3,
    title: 'Electronic Health Records (EHR)',
    para: `Simplify data management and patient care with tailored EHR solutions. Reduce errors, expedite treatments, improve communication, and optimize operations, ensuring better outcomes for patients and staff alike.`,
  },
  {
    id: 4,
    title: 'Customer Relationship Management Software (CRM)',
    para: `Empower your sales team with a CRM tailored to your needs. Manage customer interactions, streamline processes, improve business connections, and increase revenue with efficient, data-driven tools.`,
  },
  {
    id: 5,
    title: 'Supply Chain Management Software',
    para: `Eliminate late shipments and reduce logistical errors with custom supply chain software. Automate processes, improve forecasting, and enhance communication for a more agile, cost-effective supply chain.`,
  },
  {
    id: 6,
    title: 'Enterprise Asset Management (EAM)',
    para: `Gain full control over operational assets with custom EAM solutions. Reduce downtime, optimize maintenance, and increase productivity by managing your assets’ lifecycles efficiently and effectively.`,
  },
  {
    id: 7,
    title: 'Custom Accounting Software',
    para: `Streamline transactions, track cash flow, and manage revenues with tailored accounting software. Generate real-time reports and empower data-driven decisions, ensuring impeccable financial management.`,
  },
  {
    id: 8,
    title: 'Business Process Management (BPM)',
    para: `Automate and optimize operations with custom BPM software. Whether for startups or enterprises, our solutions deliver the functionalities you need to enhance productivity and meet business objectives.`,
  },
  {
    id: 8,
    title: 'Business Intelligence Software',
    para: `Transform your decision-making with custom BI software. Analyze and visualize data from multiple sources, uncover hidden trends, and gain a competitive edge by leveraging actionable insights for business growth.`,
  }

]

const customSoftware6a = [
  {
    id: 1,
    title: 'Take Control and Supercharge Your Business',
    para: `More Than Just Developers, We're Your Partners in Growth:`
  },

]
const customSoftware6 = [
  {
    id: 1,
    title: 'Cost-Efficiency',
    para: 'Invest in the future, not fleeting features. Our custom software eliminates unnecessary functionalities and recurring costs, offering smart long-term value.'
  },
  {
    id: 2,
    title: 'Performance Powerhouse',
    para: `
    Boost employee productivity and unlock efficiency gains with tailored management tools and responsive 
    features designed to fit your unique workflow.
    `

  },
  {
    id: 3,
    title: 'Brand-Crafted Solutions',
    para: `
    We don't believe in cookie-cutter software. Your bespoke solutions are built with your brand identity 
    and future plans woven in, ensuring a unified experience.
   `

  },
  {
    id: 4,
    title: 'Full Control at Your Fingertips',
    para: `No more black boxes. You have complete control over features, updates, and licensing. Transparency
     and empowerment are our cornerstones.
   `

  },
  {
    id: 5,
    title: 'Automation Maestro',
    para: `
    Free your team from tedious tasks. We automate administrative and repetitive processes, 
    allowing your employees to focus on impactful work that drives results.
    `

  },
  {
    id: 6,
    title: 'Scalability for Growth',
    para: `
    Your business is on the rise, and your software should be too. Custom applications are
     built to adapt and expand with you, seamlessly scaling to meet your evolving needs.
   `

  },
  {
    id: 7,
    title: 'Integration Champion',
    para: `
    We break down siloed systems. Our software integrates seamlessly with existing programs, 
    streamlining your processes and giving you a competitive edge.
    `

  },
  {
    id: 8,
    title: 'Security Fortress',
    para: `
    Breathe easy with robust built-in security tools. We identify and mitigate potential vulnerabilities,
     ensuring your data and operations remain safe from harm.`

  },
  {
    id: 9,
    title: 'Customer Satisfaction',
    para: `Delight customers with software that meets their needs and exceeds expectations. We design 
    user-friendly, intuitive, and responsive software, ensuring a great experience.`

  }
]

const customSoftware5a = [
  {
    id: 1,
    title: 'Zai Systems Customizes Your Software, Streamlines Your Workflow',
    para: `Feeling overwhelmed by complex processes and clunky systems? Juggling multiple applications, 
    manual data entry, and inefficient workflows can drain your resources and stifle your business growth. `,
    para1: `We understand that every business is unique, with its own challenges and operational needs. That's 
    why we don't believe in one-size-fits-all solutions. Instead, we custom-made software that seamlessly integrates 
    with your existing infrastructure, streamlining your operations and boosting your efficiency.`
  },

]
const customSoftware5 = [
  {
    id: 1,
    title: 'Automated Workflows',
    para: `Eliminate tedious manual tasks and repetitive processes. We automate data entry, generate reports, 
    and trigger actions based on set criteria, freeing up your time for more strategic initiatives.`,
  },
  {
    id: 2,
    title: 'Centralized Data Management',
    para: `Say goodbye to data silos! Our data consolidation systems consolidate your information from
     various sources, providing you with a single source of truth and actionable insights. `,

  },
  {
    id: 3,
    title: 'Improved Collaboration',
    para: `Foster seamless communication and teamwork across departments. Our software facilitates real-time data sharing, 
    project management, and task delegation, keeping everyone on the same page.`,

  },
  {
    id: 4,
    title: 'Enhanced Customer Experience',
    para: `Deliver exceptional customer service with software. We develop software that automates interactions, 
    resolves issues efficiently, and gathers valuable customer feedback.`,
  },
  {
    id: 5,
    title: 'Reduced Costs & Errors',
    para: `Automate tasks, streamline processes, and minimize human intervention. This translates to fewer mistakes,
     improved accuracy, and ultimately, reduced operational costs.`,
  },
  {
    id: 6,
    title: 'Secure Systems',
    para: ` Protect your data and assets from cyber threats. Our software solutions are built with the latest
     security standards and best practices, ensuring compliance and reliability.`,
  }
]
const SeoDataCustom = [
  {
    id: 1,
    title: `Software Development and Consultation Services`,
    content: `Struggling with outdated software or failed projects? We're here to help! Our development & consulting services fix your tech woes & deliver solutions that work.`,
    link: `https://www.zaisystems.com/services/custom-software-development`
  }
]

// product development
const productDev1 = [
  {
    id: 1,
    title: `Best Software Product Development Services || Zai Systems`,
    para: `Welcome to Zai Systems, your trusted partner in front-line software product development. As one of the best software product development company, we specialize in delivering tailored solutions that drive innovation and accelerate your business growth. With a robust portfolio spanning custom software product development, MVP (Minimum Viable Product) software development, and a suite of software development services, Zai Systems stands at the forefront of the industry.`,
    img: productDev,
  }
]

const productDev4a = [
  {
    id: 1,
    para: `Our team of seasoned professionals encompasses software engineers, project managers, and UX/UI designers who collaborate seamlessly to bring your ideas to life. Whether you're a startup looking to build your first MVP or an enterprise seeking to enhance your software product suite, Zai Systems is equipped to deliver results.`
  },

]
const productDev4b = [
  {
    id: 1,
    para: `Our Comprehensive Software Product Development Services`
  },

]
const productDev4 = [

  {
    id: 4,
    title: 'Custom Software Product Development',
    para: `Our custom software product development services are tailored to address specific business challenges and opportunities. We follow a meticulous process that begins with understanding your requirements and culminates in the delivery of a scalable, secure, and high-performance software solution. Through agile development methodologies, we ensure flexibility and transparency at every stage of the project.`,
    img: productOne
  },
  {
    id: 2,
    title: 'MVP Software Development',
    para: `Launching a Minimum Viable Product (MVP) is crucial for startups and businesses aiming to validate their ideas in the market. Zai Systems excels in MVP development, helping you prioritize features, reduce time-to-market, and gather valuable user feedback. Our iterative approach ensures rapid iterations and continuous improvement based on real-world insights.`,
    img: productTwo
  },
  {
    id: 3,
    title: 'Nearshore and Offshore Software Development services',
    para: `With Zai Systems, you have access to both nearshore and offshore software development options. Whether you prefer the proximity and cultural alignment of nearshore development or the cost-efficiency of offshore teams, we have the expertise to seamlessly integrate with your operations and deliver outstanding results.
`,
    img: productThree
  },
  {
    id: 1,
    title: 'Agile Development services',
    para: `Embracing Agile methodologies allows us to adapt to evolving requirements and deliver software products efficiently. Our Agile development approach promotes collaboration, flexibility, and rapid response to change, ensuring that your project stays on track and delivers maximum business value.
`,
    img: productFour
  },
  {
    id: 5,
    title: 'Software Design and Development Services',
    para: `At the heart of every successful software product lies thoughtful design and robust development. Zai Systems combines creativity with technical excellence to craft intuitive user interfaces, scalable architectures, and seamless integrations. Our focus on usability and performance ensures that your software not only meets but exceeds expectations.
`,
    img: productFive
  },
  {
    id: 6,
    title: 'Web Application Development Services',
    para: `In an increasingly digital world, web applications play a pivotal role in business success. Zai Systems offers end-to-end web application development services, encompassing everything from frontend design to backend development and cloud deployment. Whether you need a responsive web app or a complex enterprise solution, we have the expertise to deliver.    `,
    img: productSix
  },
  {
    id: 7,
    title: 'Software Project Management services',
    para: `Effective project management is essential for delivering software products on time and within budget. Zai Systems employs best practices in software project management to oversee timelines, resources, and deliverables. Our proactive approach ensures that potential risks are mitigated early, enabling smooth project execution and client satisfaction.`,
    img: productSeven
  },
  {
    id: 8,
    title: 'Software Development Life Cycle (SDLC)',
    para: `We adhere to a structured Software Development Life Cycle (SDLC) to ensure systematic and efficient software development processes. From initial planning and analysis to testing, deployment, and maintenance, each phase is meticulously managed to guarantee quality and reliability in the final product.`,
    img: productEight
  },
  {
    id: 9,
    title: 'Software Development Methodologies',
    para: `Our proficiency in various software development methodologies, including Agile, Scrum, Kanban, and Waterfall, allows us to tailor our approach to your project's specific requirements. Whether you prioritize flexibility, predictability, or a hybrid approach, Zai Systems has the agility and experience to adapt.`,
    img: productNine
  },
  {
    id: 10,
    title: 'UX/UI Design and Idea Development Services',
    para: `Our UX/UI design and idea development services are focused on creating engaging, user-friendly interfaces that enhance the user experience. We work closely with you to understand your vision and translate it into a cohesive design that not only looks great but also functions seamlessly. Our idea development process ensures that your software product is innovative and aligned with market needs.`,
    img: productTen
  },
  {
    id: 11,
    title: 'Customized Software Solutions',
    para: `Zai Systems is one of the best software development companies specializes in creating customized software solutions that align perfectly with your business goals and operational needs. Whether you require a CRM system, ERP solution, e-commerce platform, or IoT application, our team has the expertise to deliver robust, scalable, and secure software tailored to your exact specifications.`,
    img: productEleven
  }

]
const productDev5a = [
  {
    id: 1,
    title: 'Craft Your Dream Software: A Rock-Solid Development Process',
    para: `From vision to reality, we're your trusted partner in building the software that takes your business to new heights.
     Our robust product development process guides you every step of the way, ensuring a launch that exceeds expectations.`
  },

]
const productDev5 = [
  {
    id: 1,
    title: 'Conceptualize Your Masterpiece',
    para: `We delve into your unique needs and market landscape, meticulously identifying essential features and functionalities. 
    We collaboratively define clear, measurable objectives to ensure your software delivers tangible value. We design a scalable 
    and adaptable foundation, ready to evolve with your changing needs.`
  },
  {
    id: 2,
    title: 'Brainstorming: Ignite the Spark',
    para: `We foster an environment of open exchange, where ideas collide and innovation thrives. We iterate rapidly through low-fidelity 
    prototypes, continuously refining the user experience and ensuring market fit. We actively integrate your insights at every stage,
     shaping the product you truly desire.`

  },
  {
    id: 3,
    title: 'Design that Dazzles',
    para: `Our design experts craft intuitive and user-friendly interfaces that are as beautiful as they are functional. We weave your brand narrative
     into the fabric of the software, creating a seamless and engaging experience. We ensure your product is inclusive and 
     accessible to everyone, regardless of ability.`

  },
  {
    id: 4,
    title: 'Development: Where Magic Happens',
    para: `We use agile methodologies for rapid development and delivery, ensuring continuous improvement and faster time-to-market. 
    We master the latest technologies to build robust, secure, and scalable software solutions. We test comprehensively at every stage 
    to deliver a flawless product.`

  },
  {
    id: 5,
    title: 'Success from Launch and Beyond',
    para: `We meticulously plan and execute your launch, ensuring maximum impact and user adoption. We're your dedicated partner, 
    providing ongoing maintenance and updates to keep your software thriving. We continuously analyze and optimize your 
    software, ensuring it remains relevant and delivers lasting value.`

  },
  {
    id: 6,
    title: 'We’ve Got Your Back',
    para: `We don’t just deliver software – we deliver peace of mind. Our support team assists you with any 
    issues or queries. We provide prompt and reliable solutions, ensuring your software runs smoothly and efficiently.
     We also offer training and guidance to help you make the most of your software.
   `

  }
]
const productDev111a = [
  {
    id: 1,
    title: 'Industries we serve',
  }
]
const productDev111b = [
  {
    id: 1,
    title: '1. Medical'
  },
  {
    id: 2,
    title: '2. Education'
  },
  {
    id: 3,
    title: '3. Finance'
  },
  {
    id: 4,
    title: '4. Retail'
  },
  {
    id: 5,
    title: '5. Insurance'
  },
]
const productDev111c = [
  {
    id: 1,
    title: '6. Transportation and Logistics'
  },
  {
    id: 2,
    title: '7. Telecommunications'
  },
  {
    id: 3,
    title: '8. Government and Public Sector'
  },
  {
    id: 4,
    title: '9. Energy and Utilities'
  },
  {
    id: 5,
    title: '10. Media and Entertainment'
  },
]
const productDev12a = [
  {
    id: 1,
    title: 'Visit our other services'
  }
]
const productDev12 = [
  {
    id: 1,
    title: 'Mobile App Developemnt'
  },
  {
    id: 2,
    title: 'Web App Development'
  },
  {
    id: 3,
    title: 'Search Engine Optimization'
  }
]
const iottestimonials = [
  {
    id: 1,
    para: `Zai Systems helped us launch a smart home product line with their IoT development expertise. Their innovative solutions brought our vision to life, enhancing customer satisfaction.`,
    name: "Ethan Williams",
    title: "Smart Home Product Manager"
  },
  {
    id: 2,
    para: `The IoT solutions provided by Zai Systems improved real-time health monitoring for our devices. Their team’s knowledge of IoT technologies was instrumental to the project’s success.`,
    name: "Isabella Thompson",
    title: "Healthcare Solutions Consultant"
  },
  {
    id: 3,
    para: `We partnered with Zai Systems for industrial IoT development. Their expertise in firmware and data analytics optimized our operations and reduced downtime significantly.`,
    name: "David Brown",
    title: "Industrial Automation Lead"
  },
  {
    id: 4,
    para: `Zai Systems’ IoT consulting transformed our agriculture processes. Their advanced IoT solutions have optimized efficiency and delivered outstanding results in our operations.`,
    name: "Emily Davis",
    title: "Agriculture Technology Specialist"
  },
  {
    id: 5,
    para: `Their IoT app and data analytics development improved our retail operations significantly. Zai Systems delivered a tailored solution that boosted customer engagement and streamlined processes.`,
    name: "William Wilson",
    title: "Retail Operations Manager"
  }
];

const productDev6a = [
  {
    id: 1,
    title: 'Ingredients of an Enriched Software Product Development Services',
    para: `Welcome to the secret sauce of our software product development process! Here's a detailed breakdown of the key ingredients
     that make our recipe a success:`
  },

]
const productDev6 = [
  {
    id: 1,
    title: 'Expert Developers',
    para: `
    Our team isn't just code monkeys; they're passionate engineers who thrive on tackling challenges. Their innovative minds wield cutting-edge technologies like magic to bring your vision to life, always keeping your business goals at the heart of every solution.
    `
  },
  {
    id: 2,
    title: 'Diverse Expertise',
    para: `
    Forget generic "best-of-the-best" claims. We celebrate the unique strengths and backgrounds of our developers. 
    This diversity translates into a wider range of technological expertise, allowing us to adapt to any project's
     specific and dynamic needs.
    `
  },
  {
    id: 3,
    title: 'Experimentation Embrace',
    para: `We don't settle for the status quo. Our team is fearless when it comes to exploring new technologies and approaches, constantly
     pushing the boundaries to deliver the most groundbreaking and successful, and innovative products.`

  },
  {
    id: 4,
    title: 'Tech Stack Agnosticism',
    para: `We don't lock ourselves into a single tech stack. Our developers are adept at mastering the latest and greatest technologies, allowing us to 
    tailor our approach to your specific project requirements, ensuring optimal performance and scalability.`

  },
  {
    id: 5,
    title: 'Global Accessibility',
    para: `We understand the world never sleeps, and neither do we. Our dedicated teams are available 24/7, bridging time zones and minimizing 
    response times to ensure seamless collaboration, no matter where you are located or what you need.`
  },
  {
    id: 6,
    title: 'Collaborative Spirit',
    para: `We value collective intelligence. Every stakeholder’s voice matters, and our development teams actively seek input from everyone involved. 
    This collaborative spirit ensures your product stays true to its original vision while incorporating valuable insights.`

  }
]
const productDev10a = [
  {
    id: 1,
    title: 'Why Choose Zai Systems? ',
    para: `Exceptional expertise, leading-edge innovation, and a commitment to quality and client satisfaction.`,
    img: mobileEighteen
  }

]

const productDev10 = [
  {
    id: 1,
    title: `Expertise: Skilled Professionals Delivering Excellence:`,
    para: `Our team consists of experienced software engineers dedicated to creating innovative and reliable solutions that exceed expectations`,
    img: mobileSix
  },
  {
    id: 2,
    title: `Innovation: Cutting-Edge Solutions for Modern Needs:`,
    para: `We harness the latest technologies and trends to craft forward-thinking software designed to solve today’s challenges.`,
    img: mobileEight
  },
  {
    id: 3,
    title: `Quality Assurance: Reliable Products, Every Time`,
    para: `Our rigorous testing and QA processes ensure every software product meets the highest standards of reliability and performance.`,
    img: mobileFive
  },
  {
    id: 4,
    title: `Client-Centric Approach: Partnerships Built on Trust`,
    para: `We focus on open communication, transparency, and collaboration to establish strong, lasting relationships with our clients.`,
    img: mobileFive
  },
  {
    id: 5,
    title: `Global Delivery Model:`,
    para: `Whether you're located locally or internationally, our global delivery model ensures seamless project execution and support.`,
    img: mobileFive
  }
]
const productDev11 = [
  {
    id: 1,
    title: `Get Started with Zai Systems Today`,
    para: `Transform your software product ideas into reality with Zai Systems. Contact us today to discuss your project requirements and discover how our software product development services can empower your business. Partner with us and experience innovation, efficiency, and excellence in every solution we deliver.`,
  }
]
const ProductServiceFAQs = [
  { questions: 'What types of software products can Zai Systems develop?', answers: 'We can develop a wide range of software products, including web applications, mobile apps, enterprise solutions, e-commerce platforms, CRM systems, ERP solutions, and IoT applications.' },
  { questions: 'How do you ensure the quality of your software products?', answers: 'We follow rigorous testing and quality assurance processes throughout the development lifecycle. This includes unit testing, integration testing, performance testing, and user acceptance testing to ensure the final product is reliable and meets your requirements.' },
  { questions: 'Can you help with the development of an MVP?', answers: 'Yes, we specialize in MVP development, helping startups and businesses validate their ideas in the market quickly. Our iterative approach ensures rapid development, allowing you to gather user feedback and make necessary improvements.' },
  { questions: 'What are the benefits of nearshore and offshore development?', answers: 'Nearshore development offers proximity and cultural alignment, which can enhance communication and collaboration. Offshore development provides cost-efficiency and access to a broader talent pool. We offer both options to meet your specific needs.' },
  { questions: 'What is the typical timeline for a software development project?', answers: 'The timeline for a software development project varies based on its complexity and scope. After understanding your requirements, we provide a detailed project plan with estimated timelines for each phase of development.' },
  { questions: 'Do you offer ongoing support and maintenance?', answers: 'Yes, we provide ongoing support and maintenance services to ensure your software product remains up-to-date and performs optimally. Our team is available to address any issues and implement updates as needed.' },
  { questions: 'How do you ensure the security of the software products you develop?', answers: 'We implement robust security measures throughout the development process, including secure coding practices, encryption, access controls, and regular security audits. Our goal is to deliver secure software that protects your data and user information.' },
  { questions: 'What industries do you serve?', answers: 'We serve a wide range of industries, including finance, healthcare, retail, education, manufacturing, and more. Our experience across various sectors enables us to deliver tailored solutions that meet industry-specific requirements.' },
];
const SeoDataProduct = [
  {
    id: 1,
    title: `Product design and development services`,
    content: `Turn ideas into profits. Streamline your product journey with end-to-end design & development.`,
    link: `https://www.zaisystems.com/services/product-development`
  }
]
// iot development
const iot1 = [
  {
    id: 1,
    title: `Internet of Things (IoT) Development Services | Zai Systems`,
    para: `Welcome to Zai Systems, a leading IoT development company dedicated to delivering innovative Internet of Things (IoT) development services. As a pioneer in IoT application development services, we provide comprehensive solutions that integrate advanced IoT technologies to create intelligent systems for a variety of applications. Our expertise spans IoT software development services, iot solutions, smart home automation, and more. Explore how our innovative approach can elevate your business to new heights through advanced IoT technologies and platforms. `,
    img: iotDev,
  }
]

const iot2 = [
  {
    id: 1,
    title: `What is the Internet of Things (IoT)?`,
    para: `The Internet of Things (IoT) is a transformative technology that connects everyday devices to the internet, enabling them to communicate, collect, and exchange data. From smart home devices to industrial machinery, IoT leverages sensors, software, and connectivity to enhance the functionality of physical objects. This connectivity fosters smarter decision-making, operational efficiency, and improved user experiences.`,
  },

]
const iot4a = [
  {
    id: 1,
    title: `Why Zai Systems is Your Top Choice for IoT Development`,

  },

]
const iot4 = [
  {
    id: 1,
    title: `Industry-Leading IoT Development Company`,
    para: `As a premier IoT development company, Zai Systems stands out with its commitment to delivering high-quality IoT development services. Our dedicated team combines technical expertise with innovative thinking to provide bespoke solutions that address your unique needs.`,
    img: iotNine
  },
  {
    id: 2,
    title: `Our Comprehensive IoT Development Services`,
    para: `At Zai Systems, our IoT development services cover the full spectrum of IoT solutions, ensuring that every aspect of your project is expertly managed. `,
    img: iotTwo
  },
  {
    id: 3,
    title: `IoT Application Development Services`,
    para: `Our iot application development services focus on creating robust, user-friendly applications that integrate seamlessly with IoT devices.`,
    list: [
      {
        id: 1,
        para: `•	Custom Application Development: Tailoring apps to your specific requirements, ensuring optimal performance and functionality.`,
      },
      {
        id: 2,
        para: `•	Cross-Platform Compatibility: Developing applications that work seamlessly across various devices and platforms.`,
      },
      {
        id: 3,
        para: `•	User Experience Design: Designing intuitive interfaces that enhance user engagement and satisfaction`,
      },
    ],
    img: iotFour
  },
  {
    id: 4,
    title: `IoT Software Development Services`,
    para: `We provide a range of IoT software development services designed to power your IoT devices and solutions. Our offerings include:`,
    list: [
      {
        id: 1,
        para: `•	IoT Firmware Development: Creating the essential firmware that ensures your devices operate efficiently and reliably.`,
      },
      {
        id: 2,
        para: `•	Cloud Integration: Connecting your IoT devices with cloud platforms for data storage and processing.`,
      },
      {
        id: 3,
        para: `•	Security Solutions: Implementing advanced security measures to protect your data and devices from potential threats.`,
      },
    ],
    img: iotThree
  },

  {
    id: 5,
    title: `IoT Solutions`,
    para: `Our comprehensive iot solutions cater to various industries, providing tailored approaches to meet diverse needs:`,
    list: [
      {
        id: 1,
        para: `•	Smart Home Automation: Transforming homes with automated systems for lighting, climate control, and security, enhancing comfort and efficiency.`,
      },
      {
        id: 2,
        para: `•	Industrial IoT: Improving manufacturing and industrial operations with real-time monitoring, predictive maintenance, and process optimization.`,
      },
      {
        id: 3,
        para: `•	Healthcare IoT: Enhancing patient care and operational efficiency through connected medical devices and health monitoring systems.`,
      },
    ],
    img: iotFive
  },
  {
    id: 6,
    title: `Smart Home Automation`,
    para: `Smart home automation is revolutionizing the way we live by integrating advanced technologies into everyday home systems.`,
    list: [
      {
        id: 1,
        para: `•	Automated Lighting: Control your home’s lighting through voice commands, mobile apps, or preset schedules.`,
      },
      {
        id: 2,
        para: `•	Climate Control Systems: Manage heating, ventilation, and air conditioning (HVAC) systems remotely to maintain optimal comfort and energy efficiency.`,
      },
      {
        id: 3,
        para: `•	Home Security: Monitor and manage security systems, including cameras, alarms, and smart locks, from anywhere.`,
      },
    ], img: iotSix
  },
  {
    id: 7,
    title: `IoT Firmware Development`,
    para: `IoT firmware development is crucial for the smooth operation of your IoT devices.`,
    list: [
      {
        id: 1,
        para: `•	Embedded Firmware Development: Designing and developing firmware for embedded systems to ensure optimal performance and functionality.`,
      },
      {
        id: 2,
        para: `•	Performance Optimization: Enhancing firmware to improve speed, efficiency, and responsiveness.`,
      },
      {
        id: 3,
        para: `•	Firmware Updates: Implementing over-the-air (OTA) updates to keep your devices current with the latest features and security patches.`,
      },
    ], img: iotSeven
  },
  {
    id: 8,
    title: `IoT Data Analytics`,
    para: `IoT data analytics involves the collection and analysis of data generated by IoT devices to derive actionable insights.:`,
    list: [
      {
        id: 1,
        para: `•	Data Collection: Gathering data from sensors and devices to provide a comprehensive view of operations and performance.`,
      },
      {
        id: 2,
        para: `•	Real-Time Analytics: Utilizing advanced tools to analyze data in real time, facilitating immediate decision-making.`,
      },
      {
        id: 3,
        para: `•	Predictive Analytics: Applying historical data to forecast future trends, helping you make informed strategic decisions.`,
      },
    ], img: iotEight
  },
  {
    id: 9,
    title: `IoT Technologies and Platforms`,
    para: `Understanding and leveraging IoT technologies and platforms is essential for developing effective IoT solutions. Our expertise includes:`,
    list: [
      {
        id: 1,
        para: `•	IoT Platforms: Utilizing leading platforms such as AWS IoT, Microsoft Azure IoT, and Google Cloud IoT to build scalable and secure solutions.`,
      },
      {
        id: 2,
        para: `•	Connectivity Protocols: Implementing communication protocols like MQTT, CoAP, and HTTP to ensure reliable connectivity and data transfer.`,
      },
      {
        id: 3,
        para: `•	Data Storage and Processing: Employing cloud services and edge computing to manage and process data efficiently.`,
      },
    ], img: iotNine
  },

]
const iot12a = [
  {
    id: 1,
    title: `Your Guide to Top IoT Development Tools and Technologies`,
    para: `The Internet of Things (IoT) is revolutionizing every aspect of our lives, from smart homes and connected cars to industrial 
    automation and healthcare. As a leading innovator in the field, Zai Systems is here to equip you with the knowledge and tools 
    you need to unlock the power of this transformative technology.`,
  }

]
const iot12 = [
  {
    id: 1,
    title: `Tessel `,
    para: `Tessel, an open-source development board, is a versatile and powerful platform for hobbyists and professionals 
    alike. With its modular design, extensive software libraries, Tessel makes it easy to prototype and build 
    innovative IoT applications, from weather stations to smart home devices.`,
    img: iotSeven
  },
  {
    id: 2,
    title: `Eclipse IoT `,
    para: `Eclipse IoT is a comprehensive open-source framework designed for building scalable and secure IoT solutions. It provides a wide
     range of tools and libraries for device connectivity, data management, and application development, making it ideal for complex 
     enterprise projects.`,
    img: iotEight

  },
  {
    id: 3,
    title: `Arduino `,
    para: `The undisputed king of the DIY electronics world, Arduino is a simple yet powerful microcontroller board that lets anyone
     bring their IoT ideas to life. With its intuitive programming language and vast community support, Arduino is perfect for beginners
      and experienced makers alike. `,
    img: iotNine

  },
  {
    id: 4,
    title: `IBM Watson `,
    para: `Watson is more than just a virtual assistant; it's a powerful AI platform that can be harnessed for a variety of IoT applications.
     Watson's cognitive capabilities allow you to analyze sensor data, extract insights, and make informed decisions in real-time, 
     opening up a world of possibilities for smart cities, predictive maintenance.`,
    img: iotTen

  },
  {
    id: 5,
    title: `Raspbian `,
    para: `This Linux-based operating system turns the Raspberry Pi, a credit card-sized computer, into a powerful IoT development platform.
     With its rich ecosystem of software and hardware add-ons, Raspbian empowers you to build everything from basic sensors to 
     sophisticated gateways and edge computing devices.`,
    img: iotEleven

  },
  {
    id: 6,
    title: `OpenSCADA `,
    para: `OpenSCADA is an innovative, open-source, and powerful platform for industrial automation and supervisory control. It provides a robust, 
    flexible, and scalable framework for controlling real-world systems, making it ideal for applications in manufacturing, 
    energy management, building automation, and more.`,
    img: iotTwelve

  },
  {
    id: 7,
    title: `Node-RED `,
    para: `This visual programming tool makes building IoT applications a breeze. With its intuitive drag-and-drop interface and extensive 
    library of nodes, Node-RED allows you to easily connect devices, process data, and create workflows without writing a single line of code.`,
    img: iotThirteen

  },
  {
    id: 8,
    title: `AWS IoT `,
    para: `This cloud-based platform provides a comprehensive suite of services and tools for IoT solutions.
     AWS IoT enables you to connect devices, store and analyze data, apply machine learning, 
    and use other AWS services, making it a complete IoT development solution.`,
    img: iotFourteen

  },
  {
    id: 9,
    title: `MQTT`,
    para: `This lightweight, open-source protocol enables efficient and reliable IoT communication.
     MQTT uses a publish-subscribe model, where devices can send and receive messages on topics of interest,
      reducing network bandwidth and power consumption.`,
    img: iotFifteen

  }
]
const iot9a = [
  {
    id: 1,
    title: 'Benefits of IoT for Businesses',
    para: 'Implementing IoT solutions can offer numerous advantages for businesses, including:',
    img: webNine
  }

]
const iot9 = [
  {
    id: 1,
    title: `Enhanced Efficiency:`,
    para: `Automating processes and enabling real-time monitoring to streamline operations.`
  },
  {
    id: 2,
    title: `Improved Decision-Making:`,
    para: `Utilizing data analytics to make informed decisions and optimize strategies.`
  },
  {
    id: 3,
    title: `Increased Customer Satisfaction`,
    para: `Offering innovative products and services that meet customer needs and preferences.`
  },
  {
    id: 4,
    title: `Cost Reduction`,
    para: `Minimizing operational costs through predictive maintenance and efficient resource management.`
  }
]

const IotServiceFAQs = [
  { questions: 'What are IoT development services?', answers: 'IoT development services involve creating and implementing solutions that integrate internet-connected devices to collect, exchange, and analyze data. This includes IoT application development services, IoT software development services, and more.' },
  { questions: 'How can IoT benefit my business?', answers: 'IoT can enhance your business by improving operational efficiency, enabling real-time data analysis, offering innovative products and services, and reducing operational costs through automation and predictive maintenance.' },
  { questions: 'What industries can benefit from IoT solutions?', answers: 'IoT solutions are versatile and can benefit various industries, including manufacturing, healthcare, retail, agriculture, and smart homes. Each sector can leverage IoT to improve efficiency, productivity, and customer experience.' },
  { questions: 'What is smart home automation?', answers: 'Smart home automation involves integrating technology into home systems to automate functions like lighting, climate control, and security. This enhances convenience, comfort, and energy efficiency for homeowners.' },
  { questions: 'What is IoT firmware development?', answers: 'IoT firmware development refers to creating the software that controls the hardware components of IoT devices. It ensures that devices operate correctly, efficiently, and securely.' },
  { questions: 'How does IoT data analytics work?', answers: 'IoT data analytics involves collecting data from IoT devices and using analytical tools to interpret this data. This enables real-time decision-making, trend forecasting, and operational improvements.' },
  { questions: 'What are the main benefits of IoT technologies?', answers: 'IoT technologies offer benefits such as enhanced operational efficiency, improved decision-making, increased customer satisfaction, and cost reduction through automation and data-driven insights..' },
  { questions: 'How do you ensure the security of IoT solutions?', answers: 'We implement advanced security measures including data encryption, secure communication protocols, and regular firmware updates to protect your IoT devices and data from potential threats.' },
];
const iot8a = [
  {
    id: 1,
    title: `The IoT Development Process at Zai Systems`,
    para: `Our IoT development process is designed to ensure the successful delivery of your project through a structured approach`,
    img: iotTen
  },

]
const iot8 = [
  {
    id: 1,
    title: `1. Consultation and Planning`,
    para: `We begin with IoT consulting to understand your business goals, requirements, and challenges. Our team works closely with you to define the project scope and develop a detailed plan.`,
    img: project
  },
  {
    id: 2,
    title: `2. Design and Prototyping`,
    para: `Our team creates detailed designs and prototypes for your IoT solution, ensuring that all elements meet your specifications and functional requirements.`,
    img: hire

  },
  {
    id: 3,
    title: `3. Development and Integration`,
    para: `We focus on building the IoT application, software, and firmware. We also handle the integration of your solution with existing systems and platforms.`,
    img: dedicatedTeam

  },
  {
    id: 4,
    title: `4. Testing and Quality Assurance`,
    para: `Rigorous testing and quality assurance processes ensure that your IoT solution is reliable, secure, and performs as expected.`,
    img: dedicatedTeam
  },
  {
    id: 4,
    title: `5. Deployment and Support`,
    para: `Once your solution is ready, we manage the deployment and provide ongoing support to address any issues and implement necessary updates.`,
    img: dedicatedTeam

  },

]
const SeoDataIot = [
  {
    id: 1,
    title: `Custom iot Development Services`,
    content: `From sensors to platforms, we craft custom IoT experiences that connect & impress.`,
    link: `https://www.zaisystems.com/services/iot-development`
  }
]
// QualityAssaurance
const QualityAssaurance1 = [
  {
    id: 1,
    title: `Full Cycle Software Quality Assurance Services`,
    para: `Zai Systems understands the importance of software quality at every stage of the development 
    process. From the initial planning phases to post deployment maintenance we offer a structured approach 
    that aligns with industry best practices and modern delivery models such as Agile and DevOps. 
    Our team is well versed in applying proven QA methodologies and tools including test automation 
    continuous integration bug tracking and risk management. This integrated approach guarantees seamless collaboration
     with development teams and reduces time to market while ensuring impeccable quality.`,
    img: SQA,
  }
]
const QualityAssaurance4a = [
  {
    id: 1,
    title: `Our Services - Full Cycle Software Quality Assurance`,
    para: `Whether your organization follows Waterfall Agile or hybrid methodologies our experts tailor QA processes to fit your workflow. At Zai Systems we believe in a client centric
     model that emphasizes transparency communication and measurable results.`,
    // para1: `Here's what sets us apart:`
  },

]
const QualityAssaurance4 = [

  {
    id: 1,
    title: 'Manual Testing',
    para: `Manual testing is the bedrock of all QA initiatives. While automation can speed up certain processes there is no substitute for the meticulous attention to detail that a skilled human tester provides. Our manual testing services focus on verifying every aspect of your application from usability to functionality. We design in depth test plans that align with your project requirements and user expectations. By simulating real world scenarios our manual testers detect subtle user 
    interface glitches workflow issues and functional inconsistencies that automated scripts might overlook.`,
    img: sqaOne
  },
  {
    id: 2,
    title: 'Automated Testing',
    para: `As development cycles become shorter and software complexity increases automated testing has emerged as an essential component of a modern QA strategy. Zai Systems specializes in test automation designed to maximize efficiency and reduce time to market. By automating repetitive test cases our QA engineers help you cut down on testing time minimize 
    human error and keep costs in check. We harness popular frameworks and tools like Selenium Cypress Appium and Jest among others to create reliable automated test suites. `,
    img: sqaTwo
  },
  {
    id: 3,
    title: 'Performance Testing',
    para: `Performance testing is crucial when user satisfaction and retention depend on your application being fast responsive and stable under various loads. Zai Systems conducts comprehensive performance testing to identify bottlenecks and help you fine tune your software for optimal speed and scalability. 
    Our services encompass load testing stress testing and scalability assessments ensuring your application can handle peak traffic without compromising performance. `,
    img: sqaThree
  },
  {
    id: 4,
    title: 'Security Testing',
    para: `In an era where data breaches and cyber threats have become increasingly prevalent safeguarding user data is paramount. Zai Systems integrates comprehensive security testing into your Full Cycle Software Quality Assurance strategy to protect your software from vulnerabilities that could lead to financial losses and reputational damage. Our team is skilled in identifying potential entry points and 
    simulating various attack scenarios to ensure your application is fortified against malicious exploits.`,
    img: sqaFour
  },
  {
    id: 5,
    title: 'Functional Testing',
    para: `Functional testing lies at the heart of any QA process. It verifies that every module feature and user flow within your application operates as intended. Our functional testing approach at Zai Systems involves creating detailed test cases that map to your business and functional requirements. These test cases are then executed 
    meticulously either manually or in conjunction with automated scripts to ensure nothing is overlooked. `,
    img: sqaFive
  },
  {
    id: 6,
    title: 'Usability Testing',
    para: `User centric design can make or break your applications success. Even the most feature rich software will struggle to gain traction if it is difficult to navigate or unintuitive to use. At Zai Systems our usability testing services focus on assessing how real users interact with your application. By studying user behavior feedback 
    and potential pain points we provide actionable insights to improve the overall user experience.`,
    img: sqaSix
  },
  {
    id: 6,
    title: 'Regression Testing',
    para: `Software development is often an iterative process where new features are continuously added and existing functionalities are refined. However the introduction of new code can sometimes break existing features leading to costly regressions. That is where regression testing comes into play. Our regression testing services at Zai Systems ensure that 
    previously validated functionalities remain intact after any code changes updates or deployments.`,
    img: sqaSix
  }

]
const QualityAssaurance8a = [
  {
    id: 1,
    title: `Our Quality Meets Innovation in Software Quality Assurance Services`,
    para: `Our robust suite of Software Testing and QA services is designed to anticipate and eliminate
     potential failures, saving you precious time and resources in the long run.`,
    para1: `Meet the Zai Systems Advantage:`,
    img: manCloud
  },

]
const QualityAssaurance8 = [
  {
    id: 1,
    title: 'STQB-Certified Expertise',
    para: `Zai Systems' QA professionals are ISTQB-certified, ensuring meticulous testing and top-tier proficiency in every project.
    `,
    img: sqaSeven
  },
  {
    id: 2,
    title: 'Domain-Specific Testing',
    para: `We tailor our testing strategies to your industry, ensuring flawless functionality and an exceptional user experience.
________________________________________
`,
    img: sqaEight
  },
  {
    id: 3,
    title: 'QA Automation',
    para: `Leverage advanced automation tools to accelerate testing cycles, reduce costs, and ensure comprehensive software coverage.`,
    img: sqaNine
  },
  {
    id: 4,
    title: 'Performance Testing',
    para: `Identify and resolve bottlenecks with industry-leading tools, delivering fast and reliable app performance.`,
    img: sqaTen
  },

  {
    id: 5,
    title: 'Security Testing',
    para: `Protect your app with in-depth risk assessments, penetration testing, and vulnerability analysis for robust security.`,
    img: sqaEleven
  }
  ,
  {
    id: 6,
    title: 'Usability Testing ',
    para: `Enhance user satisfaction with detailed UI analysis, ensuring intuitive, consistent, and error-free software.`,
    img: sqaTwelve
  },
  {
    id: 7,
    title: 'Mobile App Testing',
    para: `Ensure flawless performance, security, and scalability across all devices and operating systems.`,
    img: sqaThirteen
  },
  {
    id: 8,
    title: 'Compatibility Testing',
    para: `Guarantee seamless functionality across various platforms, browsers, and devices for an optimal user experience.`,
    img: sqaFourteen
  },
  {
    id: 9,
    title: 'Quality Consulting',
    para: `Optimize your processes with expert quality consulting, embedding excellence throughout your development lifecycle.`,
    img: sqaFifteen
  }
]
const QualityAssaurance12a = [
  {
    id: 1,
    title: 'Zai Systems: Shape Your QA Team, Your Way',
    para: `We offer a flexible and customizable approach to hiring top-notch QA engineers, tailor-made to
     your specific needs and project requirements.`,
    para1: `Choose the Collaboration Model that Empowers You:
     `
  },

]
const QualityAssaurance12 = [
  {
    id: 1,
    title: 'Project-Based Development Services ',
    para: `We define project scope, milestones, and deliverables with precision, ensuring clarity, alignment, and smooth project execution with no surprises.`,
    img: project
  },
  {
    id: 2,
    title: 'Hire Developers on an Hourly Basis',
    para: `Tap into a pool of expert developers with diverse skills. Easily scale your team up or down to match project demands and budgets.`,
    img: hire

  },
  {
    id: 3,
    title: 'Dedicated Team of Developers',
    para: `Assemble a team of dedicated developers for your project. Enjoy seamless collaboration, consistency, and a deep understanding of your goals.`,
    img: dedicatedTeam

  },
  {
    id: 4,
    title: 'Support and Maintenance Services',
    para: `Keep your project running smoothly with our support services. We resolve issues quickly, deliver updates, and ensure consistent performance.`,
    img: dedicatedTeam

  },

]
const QualityAssaurance5a = [
  {
    id: 1,
    title: 'Why Choose Zai Systems for Your QA Hiring Needs?',

  },

]
const QualityAssaurance5 = [
  {
    id: 1,
    title: 'Unmatched Talent Pool',
    para: `We vet and handpick our QA engineers, ensuring you
     receive access to skilled professionals with expertise across diverse
      testing methodologies and tools.`,
  },
  {
    id: 2,
    title: 'Seamless Integration',
    para: `Our onboarding process is designed for smooth integration into
     your existing team structure and workflow, minimizing disruption and maximizing productivity.`,

  },
  {
    id: 3,
    title: 'Scalability and Flexibility ',
    para: `Whether you need short-term support or a long-term partnership,
     we offer adaptable solutions that scale with your project requirements.`,

  },
  {
    id: 4,
    title: 'Cost-Effectiveness ',
    para: `Our diverse models allow you to optimize your budget by only paying for 
    the resources you need, when you need them.`,

  },
  {
    id: 5,
    title: 'Collaborative Communication',
    para: ` We prioritize open communication throughout the
     process, ensuring you stay involved every step of the way.`,

  },
  {
    id: 6,
    title: 'Quality Assurance',
    para: `Our QA engineers test and check quality throughout development,
     ensuring your software is bug-free, secure, and reliable.`,

  }

]
const SeoDataSQA = [
  {
    id: 1,
    title: `Software Quality Assurance Services`,
    content: ` Elevate Your Software by 24/7 Support and Automated & Manual Testing, Performance, Security.`,
    link: `https://www.zaisystems.com/services/software-quality-assurance`
  }
]
const SoftareQualitFAQs = [
  { questions: 'What is Full Cycle Software Quality Assurance and why is it important?', answers: 'Full Cycle Software Quality Assurance involves testing and quality measures throughout the entire software development lifecycle from planning and requirement analysis to post deployment support. This comprehensive approach ensures that defects are caught early user satisfaction is maintained and resources are used efficiently. With Full Cycle SQA issues are identified and resolved at each phase so that the final product is stable secure and high performing.' },
  { questions: 'How does Zai Systems integrate with Agile and DevOps processes?', answers: 'We have extensive experience working within Agile and DevOps frameworks. Our QA approach is highly collaborative meaning our team participates in daily stand ups sprint planning sessions and continuous integration pipelines. We work closely with development teams to provide instant feedback identify issues early and ensure that quality and speed go hand in hand throughout the release cycle.' },
  { questions: 'Can I outsource my entire QA process to Zai Systems?', answers: 'Absolutely. We offer comprehensive software QA outsourcing solutions that allow you to delegate your entire QA function to us. Our team will handle all aspects of quality assurance from manual and automated testing to performance and security assessments. This turnkey service is designed to free your internal teams and help you focus on core business objectives while we ensure your software meets the highest standards.' },
  { questions: 'What kind of tools does Zai Systems use for test automation?', answers: 'We use a broad range of tools for test automation depending on the projects technology stack and requirements. Commonly used tools include Selenium Cypress Appium and Jest among others. We also integrate these tools into version control and continuous integration systems like Jenkins Travis CI and GitLab CI ensuring that your automated tests run seamlessly as part of your build process.' },
  { questions: 'How do you ensure data security and confidentiality during testing?', answers: 'Data security and confidentiality are paramount to us. We adhere to strict non disclosure agreements and implement robust access control measures. Sensitive data is anonymized and secured following best practices outlined by GDPR HIPAA and other relevant regulations. Our security testing services also include vulnerability assessments to proactively protect your software from data breaches and cyber threats.' },
  { questions: 'What if I need ongoing testing services after my product launch?', answers: 'We offer post release support to continuously monitor the performance security and functionality of your software. We can arrange periodic regression testing performance evaluations and security audits to ensure your application remains stable and up to date. This way you can adapt quickly to user feedback or market changes and maintain a high quality user experience.' },
  { questions: 'Is manual testing still relevant when automation is so popular?', answers: 'Yes. Manual testing is crucial for exploratory testing usability checks and areas where human intuition is needed to identify issues. While test automation excels in repetitive tasks and regression scenarios there are subtleties in user experience and design that automated scripts may overlook. A balanced QA strategy typically includes both manual and automated testing for comprehensive coverage.' },
  { questions: 'How soon can you start testing my software?', answers: 'The ramp up time depends on the scope of your project and the complexity of your requirements. Typically once we finalize an agreement and understand your needs we can quickly assemble a dedicated QA team. They will set up the necessary tools and frameworks and then initiate the testing process. Our scalable model allows us to adapt to projects of varying sizes and timelines.' },
  { questions: 'Will you provide detailed reports on testing progress?', answers: 'Yes. Transparency is one of our core values. We share regular progress reports that detail defect trends test coverage metrics pass fail rates and other key performance indicators. This information helps you track your softwares health make informed decisions on releases and evaluate our performance as your QA partner.' },
  { questions: 'How do I get started with Zai Systems Full Cycle SQA services?', answers: 'Getting started is simple. Reach out to us with your project details and testing requirements. Our team will schedule an in depth consultation to understand your business goals current challenges and technical environment. We will then present a customized QA proposal outlining the scope timeline and costs. Once approved our QA engineers will begin the process of planning designing and executing tests in close coordination with your team.' },
];
// software-project-management
const projectMan1 = [
  {
    id: 1,
    title: `Best Software Project Management Services`,
    para: `In today's dynamic digital landscape, software project management has become an essential driver of business success. The rapid evolution of technology, the growing complexity of modern projects, and the need for continuous innovation demand a structured and efficient approach. At Zai Systems, we understand the complexities involved in delivering high-quality software solutions on time and within budget. Our holistic software project management services are 
    designed to help businesses navigate every stage of the project lifecycle, from initial planning `,
    img: SPM,
  }
]
const projectMan2 = [
  {
    id: 1,
    // title: `Our Comprehensive Suite of Software Project Management Services`,
    // para: `Our dedicated team of certified project managers, software developers, QA engineers, and business 
    // analysts collaborate seamlessly to ensure that every project meets your organizational objectives.
    // `

  }
]
const projectMan4a = [
  {
    id: 1,
    title: `Our Comprehensive Suite of Software Project Management Services`,
    para: `Our dedicated team of certified project managers, software developers, QA engineers, and business 
    analysts collaborate seamlessly to ensure that every project meets your organizational objectives.
    `
  },

]
const projectMan4 = [
  {
    id: 1,
    title: 'Software Project Planning and Strategy ',
    para: `We begin every engagement by understanding your business objectives, current processes, and long-term vision. Through a detailed discovery phase, we gather requirements, identify risks, and set clear milestones. Our experienced project managers then create a customized project plan, detailing tasks, timelines, resource needs, and risk mitigation strategies. This plan acts as a roadmap, guiding every decision and activity throughout the project lifecycle. Using industry-standard frameworks, we ensure that each phase of your project
     is organized and transparent, enabling you to track progress and anticipate any challenges in advance.`,
    img: spmOne
  },
  {
    id: 2,
    title: 'Agile Project Management ',
    para: `Agile methodologies have become the gold standard for modern software development due to their iterative and flexible nature. By embracing agile project management, Zai Systems ensures faster time-to-market, improved product quality, and greater adaptability to changing requirements. Our scrum masters, product owners, and development teams collaborate daily to prioritize tasks, perform regular sprints, and deliver incremental updates. This iterative approach allows you to see tangible results sooner and offer feedback throughout the process, ensuring that the final product aligns with your evolving needs. We utilize tools like Kanban 
    boards, sprint planning, and backlog grooming to keep your project organized, efficient, and transparent.`,
    img: spmTwo
  },
  {
    id: 3,
    title: 'Waterfall Project Management ',
    para: `While agile is ideal for many modern projects, some initiatives require a more structured and linear approach. For clients who prefer a traditional methodology, Zai Systems offers waterfall project management. In this model, we move through each phase sequentially, including requirements gathering, design, coding, testing, and deployment. The waterfall methodology is particularly effective for projects with well-defined scopes and minimal likelihood of change. Our project managers maintain strict adherence to the project plan, ensure 
    thorough documentation, and provide regular status updates to keep you informed of progress at all times.`,
    img: spmThree
  },
  {
    id: 4,
    title: 'Resource Allocation and Budget Management ',
    para: `One of the primary challenges in software project management is balancing resources and budgets effectively. We use advanced resource forecasting tools and budget monitoring techniques to allocate human and technical resources optimally. Through careful planning and tracking, we minimize the risk of cost overruns and ensure that tasks are matched to the right skill sets. Our project managers regularly analyze resource utilization, making adjustments as needed to maintain efficiency. By aligning resource allocation with project goals, we help you
     maximize return on investment and deliver your software solution without unnecessary delays or expenses.`,
    img: spmFour
  },

  {
    id: 5,
    title: 'Quality Assurance and Testing ',
    para: `Delivering a high-quality product is at the core of our software project management services. Our quality assurance team employs robust testing strategies throughout the development lifecycle, identifying bugs, performance bottlenecks, and security vulnerabilities early on. Through unit testing, integration testing, system testing, and user acceptance testing, we maintain stringent quality control. Our QA experts use automated testing frameworks wherever possible, allowing for faster feedback loops and more reliable results. By the time your software is ready 
    for deployment, you can trust that it meets the highest performance, security, and usability standards.`,
    img: spmEleven
  },

  {
    id: 6,
    title: 'Risk Assessment and Mitigation ',
    para: `Software projects inevitably carry risks, such as scope creep, resource constraints, or shifting market requirements. At Zai Systems, we proactively identify and address these risks from the very beginning. During the planning stage, we create a detailed risk register that highlights potential issues, their probability, impact, and contingency strategies. Throughout execution, our project managers closely monitor indicators and take pre-emptive actions if risks escalate. This vigilance helps us maintain project stability, 
    avoid last-minute surprises, and deliver a final product that meets or exceeds your expectations.`,
    img: spmTwelve
  },
  {
    id: 7,
    title: 'Ongoing Maintenance and Support ',
    para: `Software project management does not end with deployment. We understand that your product may require updates, patches, or new features as your business evolves. Zai Systems offers ongoing maintenance and support services to ensure your software remains secure, robust, and user-friendly over the long term. Whether you need performance optimizations, system integrations, or version upgrades, our dedicated support team is here to help. By providing continuous 
    improvement options, we enable you to keep pace with technological advancements and evolving customer demands.`,
    img: spmThirteen
  },
  {
    id: 8,
    title: 'Cloud-based Project Management Tools ',
    para: `At Zai Systems, we leverage leading cloud-based project management tools that allow all stakeholders to collaborate in real time. This ensures seamless communication, better visibility into task progress, and immediate access to project data. Our platform usage includes Gantt charts for scheduling, Kanban boards for task tracking, and integrated communication channels that reduce reliance on multiple disjointed tools. These solutions also enable remote teams to stay aligned, fostering a productive environment regardless of geographical location. With 
    cloud-based tools at the core of our operations, we maintain efficiency, transparency, and accountability.`,
    img: spmFourteen
  }
  ,
  {
    id: 9,
    title: 'Project Portfolio Management ',
    para: `For organizations managing multiple projects simultaneously, project portfolio management becomes a critical challenge. Our experts help you prioritize projects, allocate resources strategically, and synchronize timelines to maximize overall productivity. We evaluate project interdependencies, financial constraints, and strategic objectives to develop a cohesive portfolio roadmap. By centralizing data in intuitive dashboards, we enable decision-makers to quickly assess project health and make informed adjustments. With this level of oversight, 
    businesses can optimize resource usage and achieve a balanced pipeline of ongoing and future projects.`,
    img: spmThirteen
  }

]

const projectMan12a = [
  {
    id: 1,
    title: 'Zai Systems: Mastering the Project Maze with Powerful Tools',
    para: `At Zai Systems, we know navigating the complexities of software projects requires the right tools. That's why we 
    offer a diverse arsenal of software project management tools, tailor-made to fit your team's style and project needs.
     Whether you prefer visual boards, streamlined task lists, or feature-rich platforms, we have the perfect solution to
      empower your journey.`

  },

]
const projectMan12 = [
  {
    id: 1,
    title: 'Trello ',
    para: `Visualize your workflow with Trello's intuitive Kanban boards. Drag and drop tasks, manage progress, and collaborate
     seamlessly with your team in a clear and engaging way. Ideal for agile teams and visual thinkers.`,
    img: spmFive
  },
  {
    id: 2,
    title: 'ActiveCollab ',
    para: `Embrace a comprehensive project management suite with ActiveCollab. Manage tasks, track time, communicate with clients,
     and gain insightful reports, all within a unified platform. Perfect for teams seeking all-in-one power.`,
    img: spmSix

  },
  {
    id: 3,
    title: 'Basecamp ',
    para: ` Keep your team focused and on track with Basecamp's no-nonsense approach. Streamline communication, share files,
     and manage deadlines effectively in a clutter-free environment. Ideal for small teams and straightforward projects.
    `,
    img: spmSeven

  },
  {
    id: 4,
    title: 'Asana ',
    para: `Organize your team's workload with Asana's intuitive task management system. Assign tasks, set deadlines, track progress,
     and collaborate effortlessly through conversations and comments. Ideal for teams prioritizing clear structure and accountability.`,
    img: spmEight

  },
  {
    id: 5,
    title: 'Podio ',
    para: ` Craft custom workflows and project layouts with Podio's flexible platform. Build apps tailored to your needs, automate 
    processes, and gain valuable insights through powerful reporting tools. Perfect for teams seeking in-depth customization and 
    data-driven insights.`,
    img: spmNine

  },
  {
    id: 6,
    title: 'JIRA ',
    para: `Unleash the power of Agile with JIRA's industry-leading platform. Plan sprints, track backlog items, manage bugs, and 
    continuously improve your development process with robust Agile features. Ideal for teams committed to iterative development
     and constant optimization.`,
    img: spmTen

  }
]
const projectMan8a = [
  {
    id: 1,
    title: `Zai Systems: Shaping Your Software Vision, Tailored to Your Needs`,
    para: `At Zai Systems, we believe software development is a collaborative journey. It's not just about building code, 
    it's about bringing your vision to life, on your terms. That's why we offer a unique range of engagement models,
     allowing you to choose the perfect fit for your project and team.`,

  }
]
const projectMan8 = [
  {
    id: 1,
    title: `Hire Software Project Managers`,
    para: (
      <>
        <span class='text-white'> Expert Guidance: </span>  Our seasoned project managers lead the way,
        ensuring smooth execution, efficient resource allocation, and on-time delivery.
      </>
    ),
  },
  {
    id: 2,
    title: 'Project-Based ',
    para: (
      <>
        <span class='text-white'>Clear Scope, Defined Deliverables: </span>  We meticulously define project scope,
        milestones, and deliverables, ensuring everyone is on the same page.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Flexible Engagement: </span>  Choose the project duration, from short sprints
        to long-term engagements, depending on your needs.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>Cost Predictability: </span> Pay only for the work you need, with transparent pricing
        and clear invoicing.
      </>
    ),
    img: project
  },
  {
    id: 3,
    title: 'Hire Developers on Hourly Basis ',
    para: (
      <>
        <span class='text-white'>Scalable Workforce:  </span>Access a pool of talented developers with diverse skills,
        scaling your team up or down as your project demands.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Cost-Effective Solution: </span> Pay for the hours you need, ideal for short-term tasks
        or ongoing maintenance.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>Direct Collaboration: </span> Work directly with developers, providing real-time feedback
        and ensuring alignment with your vision.
      </>
    ),
    img: hire

  },
  {
    id: 4,
    title: 'Dedicated Team of Developers ',
    para: (
      <>
        <span class='text-white'>Build a Cohesive Unit: </span>  Assemble a team of developers dedicated to your project,
        fostering deep understanding and seamless collaboration.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Long-Term Commitment: </span>  Benefit from consistent expertise and continuity throughout
        your project lifecycle.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>Tailored to Your Needs: </span>  Select specific skills and experience to perfectly match
        your project requirements.
      </>
    ),
    img: dedicatedTeam

  }

]
const SeoDataSPM = [
  {
    id: 1,
    title: `Professional Services for Project Management Software`,
    content: `Boost Your Project ROI with Expert PM Software Services. Optimize workflows, automate tasks, and achieve project goals faster with our professional guidance and support. Free consultation!`,
    link: `https://www.zaisystems.com/services/software-project-management`
  }
]
const ProjectMangementFAQs = [
  { questions: 'What methodologies does Zai Systems specialize in?', answers: 'We specialize in both agile and waterfall methodologies, tailoring our approach based on each project requirements and constraints. Our team is proficient in scrum, Kanban, and other agile frameworks. For projects with well-defined scopes, we can implement a traditional waterfall model. By offering flexibility in our methodologies, we ensure that your project is managed in a way that best aligns with your specific goals and constraints.' },
  { questions: 'How do you ensure projects stay on schedule and on budget?', answers: 'We create detailed project plans that outline tasks, milestones, and resource allocation. Throughout execution, our project managers track progress using key performance indicators, risk assessments, and time-tracking tools. Should any deviations occur, we promptly re-align resources or adjust the project plan to keep everything on track. This proactive approach allows us to meet deadlines and control costs effectively.' },
  { questions: 'Can you manage remote or distributed teams?', answers: 'Yes, our cloud-based project management tools enable us to coordinate teams located around the world. We leverage real-time communication platforms, shared dashboards, and secure code repositories to keep everyone aligned. Daily check-ins, sprint reviews, and continuous integration ensure that location does not become a barrier to collaboration or productivity.' },
  { questions: 'Do you offer ongoing support and maintenance after the project goes live?', answers: 'Absolutely. We recognize that software often evolves even after it is deployed. Zai Systems provides ongoing support and maintenance services, handling bug fixes, security updates, feature enhancements, and performance optimizations. By offering these services, we help you adapt your software to ever-changing business needs and technical landscapes.' },
  { questions: 'How do you handle change requests or scope changes during a project?', answers: 'Change is inevitable in software development. We use agile principles to accommodate new requirements or adjust existing ones whenever possible. After assessing the impact on timelines, resources, and budget, we communicate the options to stakeholders and obtain approval before implementing changes. This transparent process ensures that you remain in control of your projects direction.' },
  { questions: 'Are your services suitable for startups and small businesses?', answers: 'Yes, we work with organizations of all sizes. Our scalable engagement models allow us to tailor our services to suit the budget and requirements of startups and small businesses. Whether you need a minimal viable product to enter the market quickly or a more complex solution, Zai Systems can provide the right level of support to help you succeed.' },
  { questions: 'How do you ensure quality in your software solutions?', answers: 'Our QA team conducts rigorous testing throughout the development lifecycle. This includes unit tests, integration tests, performance tests, and user acceptance tests. By identifying and resolving issues early, we maintain a high standard of quality and reduce the chances of costly fixes after deployment. Quality assurance is embedded in our process from day one.' },
  { questions: 'What industries do you serve?', answers: 'Zai Systems serves clients in various sectors, including finance, healthcare, e-commerce, manufacturing, and technology. Our experience across diverse domains enables us to apply best practices and proven strategies that align with your industrys compliance and operational requirements.' },
  { questions: 'Can I monitor project progress in real time?', answers: ' Yes, we grant our clients secure access to our project management tools where they can view tasks, deadlines, budgets, and performance metrics in real time. This level of transparency allows you to stay informed and quickly make decisions based on the latest project data.' },
  { questions: 'How do we get started?', answers: ' Simply reach out to us through our website or contact details. We will schedule an initial consultation to discuss your project needs, timeline, and budget. From there, our team will propose a customized solution and framework to set your software initiative on the path to success.' },
];
// digital-marketing-services
const digitalMarketing1 = [
  {
    id: 1,
    title: `Digital Marketing Services by Zai Systems: Your Path to Online Success`,
    para: `In today’s digital era, having a strong online presence is crucial for any business aiming to boom in a competitive market. At Zai Systems, we offer comprehensive digital marketing services designed to elevate your brand’s visibility and drive meaningful engagement across various online platforms. Our expert team is dedicated to creating personalized strategies that line up with your business goals, ensuring maximum impact and ROI.`,

    img: DigitalMarketing,
  }
]

const digitalMarketing4a = [
  {
    id: 1,
    title: `Leading Digital Marketing Services for Enhanced Online Visibility`,
    para: `Zai Systems specializes in a wide range of digital marketing services to help businesses grow and succeed in the digital landscape. Our services include social media marketing, internet marketing, SEO services, website development, online advertising, content marketing, email marketing, and influencer marketing. Each service is meticulously designed to address the unique needs of your business and drive measurable results.`
  },

]
const digitalMarketing4 = [
  {
    id: 1,
    title: `Social Media Marketing`,
    para: `With billions of active users on social media platforms, having a robust social media presence is essential for any brand. Our social media marketing strategies are created to boost your brand’s visibility and engage with your target audience effectively. We focus on creating compelling content, managing social media campaigns, and analyzing performance to ensure your brand stands out in the crowded social media space.`,
    img: digitalOne
  },
  {
    id: 2,
    title: `Internet Marketing`,
    para: `Internet marketing encompasses various tactics to enhance your brand’s online visibility. From search engine optimization (SEO) to online advertising and content marketing, our internet marketing strategies are designed to attract and convert your target audience. We employ data-driven approaches to optimize your online presence and drive traffic to your website.`,
    img: digitalTwo
  },
  {
    id: 3,
    title: `SEO Services`,
    para: `Our SEO services are designed to improve your website’s ranking on search engines, making it easier for potential customers to find you. We offer a full suite of SEO services, including on-page SEO services, off-page SEO services, local SEO services, e-commerce SEO services, and Shopify SEO services. Our team uses the latest techniques and best practices to ensure your website ranks higher and attracts quality traffic.`,
    list: [
      {
        id: 1,
        para: `•	On-Page SEO Services`,
      },
      {
        id: 2,
        para: `•	Off-Page SEO Services`,
      },
      {
        id: 3,
        para: `•	Local SEO Services`,
      },
      {
        id: 4,
        para: `•	E-Commerce SEO Services`,
      },
      {
        id: 5,
        para: `•	Shopify SEO Services`,
      },
    ],
    img: digitalThree
  },
  {
    id: 4,
    title: `Website Development: Crafting Stunning and Functional Websites`,
    para: `A well-designed website is the cornerstone of a successful online presence. Our website development services focus on creating visually appealing, user-friendly, and functional websites that effectively represent your brand and meet your business needs. We specialize in custom website development, responsive design, and user experience optimization to ensure your website performs at its best.`,
    img: digitalFour
  },
  {
    id: 5,
    title: `Online Advertising: Drive Targeted Traffic `,
    para: `Online advertising is a powerful way to reach your target audience and drive traffic to your website. We offer various advertising solutions, including pay-per-click (PPC) campaigns, display ads, and retargeting strategies. Our team leverages data and analytics to create effective ad campaigns that deliver results and maximize your advertising budget.`,
    img: digitalFive
  },
  {
    id: 6,
    title: `Content Marketing`,
    para: `Content marketing is essential for building brand authority and engaging your audience. Our content marketing strategies involve creating high-quality, relevant content that resonates with your target audience. We focus on developing blog posts, articles, infographics, and videos that drive traffic, foster engagement, and convert leads into customers.`,
    img: digitalSix
  },
  {
    id: 7,
    title: `Influencer Marketing`,
    para: `Influencer marketing allows you to tap into the audience of influential figures in your industry. We help you identify and collaborate with relevant influencers to promote your brand, products, or services. This approach enhances your brand’s credibility and extends your reach to a broader audience.`,
    img: digitalSeven
  },
  {
    id: 8,
    title: `Cold Email Marketing`,
    para: `Cold email marketing involves sending targeted emails to potential clients who have not previously interacted with your brand. Our approach focuses on crafting personalized and compelling messages that grab attention and drive engagement. We use strategic outreach to generate new leads and build relationships with potential customers.`,
    img: digitalEight
  },
  {
    id: 9,
    title: `Technical SEO Services`,
    para: `Technical SEO services focus on optimizing your website’s technical aspects to enhance its performance and search engine visibility. We address issues like site speed, mobile-friendliness, and crawlability to ensure your website meets search engine standards and provides a seamless user experience.`,
    img: digitalNine
  },
  {
    id: 10,
    title: `Pay Per Click Management`,
    para: `Our Pay Per Click (PPC) Management services are designed to maximize the effectiveness of your PPC campaigns. We manage ad budgets, conduct keyword research, and optimize ad copy to ensure your ads reach the right audience and deliver a high return on investment.`,
    img: digitalTen
  },
  {
    id: 11,
    title: `App Store Optimization`,
    para: `App Store Optimization (ASO) is crucial for increasing your app’s visibility and downloads. We optimize your app’s listing, including the title, description, and keywords, to improve its ranking in app stores and attract more users.`,
    img: digitalEleven
  },

]
const digitalMarketing8a = [
  {
    id: 1,
    title: `Boost your business with Zai Systems' top-rated B2B digital marketing services, designed to maximize reach, engagement, and growth `,
    img: digitalTwelve
  },

]




const digitalMarketing8 = [

  {
    id: 1,
    title: `Email Marketing: Attract and Nurture Loyal Subscribers`,
    para: `
   
Zai Systems helps you attract and nurture customers with irresistible lead magnets and automated email workflows. From welcome emails to cart reminders, we ensure engagement at every stage, building loyalty and boosting conversions effortlessly.
    `,
    img: digitalSix
  },
  {
    id: 2,
    title: `Content Marketing: SEO-Driven Content That Converts`,
    para: ` With Zai Systems, your content becomes a powerful conversion tool. From SEO-rich blogs to white papers, we create engaging materials that inform, rank high, and establish authority. Drive traffic and transform readers into loyal customers.
    `,
    img: digitalSeven
  },
  {
    id: 3,
    title: `Social Media Marketing: Build Community, Drive Conversions`,
    para: `
  Zai Systems transforms your social presence into a thriving community.With strategic calendars, engaging posts, and targeted ads, we turn followers into brand advocates while driving measurable results and boosting your online reputation.
    `,
    img: digitalEight
  },
  {
    id: 4,
    title: `PPC Advertising: Targeted Campaigns, Maximum ROI`,
    para: `
    Maximize ad spend with Zai Systems' PPC expertise. Our keyword-targeted campaigns attract clicks that convert. We constantly monitor and optimize, ensuring every dollar works harder to bring you quality leads and lasting results.
    `,
    img: digitalNine
  },
  {
    id: 5,
    title: `SEO Services: Climb the Search Rankings`,
    para: `Zai Systems gets you to the top of search results.We use strategic keyword targeting, technical fixes, and optimized content to boost visibility and drive organic traffic.Be found by the right audience and grow your business.`,
    img: digitalTen
  },
  {
    id: 6,
    title: `Web Design: Fast, Responsive, and Conversion - Focused`,
    para: `Your website is your digital hub.Zai Systems creates responsive, fast, and conversion - focused designs tailored to your brand.Impress visitors, engage prospects, and turn clicks into customers with our cutting - edge web solutions.`,
    img: digitalTwelve
  }

]

const digitalMarketingFAQs = [
  { questions: 'What are digital marketing services?', answers: 'Digital marketing services encompass various strategies and tactics used to promote a brand online. These include SEO, social media marketing, content marketing, online advertising, email marketing, and more.' },
  { questions: 'Why is SEO important for my business?', answers: 'SEO is crucial because it improves your website’s visibility in search engine results, making it easier for potential customers to find you. It helps drive organic traffic and can increase your brand’s credibility and authority.' },
  { questions: 'What is social media marketing?', answers: 'Social media marketing involves using social media platforms to promote your brand, engage with your audience, and drive traffic to your website. It includes creating and sharing content, managing social media campaigns, and analyzing performance.' },
  { questions: 'How does content marketing benefit my business?', answers: 'TContent marketing helps build brand authority and engage your target audience through valuable and relevant content. It drives traffic to your website, fosters customer loyalty, and improves your SEO efforts.' },
  { questions: 'What is the difference between on-page and off-page SEO?', answers: 'On-page SEO refers to optimizing individual web pages to improve search engine rankings, including meta tags, headers, and content. Off-page SEO focuses on external factors like backlinks and social signals to build your site’s authority.' },
  { questions: 'What are the benefits of email marketing?', answers: 'Email marketing is effective for nurturing leads, maintaining customer relationships, and driving conversions. It allows for personalized communication, segmenting audiences, and analyzing performance to improve results.' },
  { questions: 'What is influencer marketing?', answers: 'Influencer marketing involves collaborating with influential figures in your industry to promote your brand. It leverages their audience and credibility to increase your brand’s reach and engagement.' },
  { questions: 'How do you measure the success of digital marketing campaigns?', answers: 'Success is measured through various metrics, including website traffic, conversion rates, engagement levels, and return on investment (ROI). We use data and analytics tools to track performance and optimize campaigns.' },
  { questions: 'What is Pay Per Click (PPC) advertising?', answers: 'PPC advertising involves placing ads on search engines or other platforms where you pay each time someone clicks on your ad. It’s a way to drive targeted traffic to your website and achieve specific marketing goals.' },
  { questions: 'How does app store optimization (ASO) work?', answers: 'ASO involves optimizing your app’s listing in app stores to improve its visibility and attract more downloads. This includes optimizing the title, description, keywords, and visuals to rank higher in search results.' },
  { questions: 'Can you handle international digital marketing campaigns?', answers: 'Yes, we can manage international digital marketing campaigns, tailoring strategies to target different regions and languages. We ensure your campaigns are adapted to local markets and cultural nuances.' },
];
const digitalMarketing5a = [
  {
    id: 1,
    title: `Why You Should Choose Zai Systems to Skyrocket Your Brand`,

  },

]
const digitalMarketing5 = [
  {
    id: 1,
    title: 'Data-powered Precision',
    para: `We don't guess, we analyze and optimize. Our research-backed strategies hit the right audience, on the right
     platform, at the right time, every time. Think of it as digital marketing alchemy, turning data into gold.`
  },
  {
    id: 2,
    title: 'Industry Savvy',
    para: `Our team boasts over 12 years of experience navigating the digital landscape. We stay ahead of 
    the curve, mastering trends like Walmart Marketplace integration and Shopify SEO to keep your brand ahead of
     the pack.`

  },
  {
    id: 3,
    title: 'Tailored Frameworks',
    para: `We craft a custom digital marketing framework that perfectly complements your 
    brand personality and customer experience. We're not just building campaigns, we're building a digital fortress 
    around your brand.`

  },
  {
    id: 4,
    title: 'Personalization Power',
    para: `We treat your customers like individuals, not numbers. Our omnichannel personalization ensures every interaction 
    is relevant and engaging, boosting loyalty and driving revenue. Think of it as turning strangers into lifelong fans. `

  },
  {
    id: 5,
    title: 'Metrics that Matter',
    para: `We don't just set campaigns and walk away. We continuously monitor and evaluate, using data like a compass to 
    fine-tune your strategy and maximize results. No more wondering if your efforts are working – we show you, precisely.`

  },
  {
    id: 6,
    title: 'Competitive Edge',
    para: `We get it, budgets matter. Our customer-centric approach ensures you get the digital marketing firepower you need 
    without breaking the bank. And for agencies, we offer lucrative white label services for maximum ROI.`

  }
]
const digitalMarketing6a = [
  {
    id: 1,
    title: 'Why Your Business Needs Zai Systems?',
    para: `In today's hyper-connected world, the old marketing playbook is gathering dust. Consumers are digital natives, spending their time scrolling social feeds, searching for solutions online, and engaging with brands across multiple devices. To thrive in this digital landscape, your business needs a partner who speaks the language of the internet – a partner like Zai Systems.`,
    para1: `Here's why Zai Systems is your key to unlocking explosive digital growth:`
  },

]
const digitalMarketing6 = [
  {
    id: 1,
    title: 'Attract More Customers with Laser-Focused Precision',
    para: `Zai Systems utilizes sophisticated audience targeting techniques to pinpoint your ideal customers with laser-sharp
     accuracy. It’s like fishing in a specific pond, not the ocean, to catch the most valuable fish for your business.`
  },
  {
    id: 2,
    title: 'Achieve top rankings on search engines',
    para: `Our SEO experts transform your website into a search 
    engine magnet, propelling you to the top of the results page where potential customers reside, taking you one step closer to success with every optimized keyword and strategic backlink.`

  },
  {
    id: 3,
    title: 'Content that Captivates, Converts, and Connects',
    para: `Zai Systems crafts content that educates, entertains, 
    and builds trust with your audience. From blog posts that captivate to social media stories that spark conversations,
     we'll turn your brand into a magnet for engagement and loyalty.`

  },
  {
    id: 4,
    title: ' Data-Driven Decisions, Measurable Results',
    para: ` We don't rely on gut feelings. Every Zai Systems strategy is fueled by data, from website analytics to social 
    media insights. We constantly analyze, and optimize your campaigns, ensuring every dollar you invest 
    delivers a king's ransom.`

  },
  {
    id: 5,
    title: 'Streamlined Processes, Freed-Up Resources ',
    para: `Zai Systems automates tasks, streamlines 
    workflows, and frees your team to focus on what you do best – running your business. Think of it as a digital Swiss 
    army knife, tackling every marketing challenge with precision and efficiency. `

  },
  {
    id: 6,
    title: ' A Conversion Castle You Can Build On',
    para: `Zai Systems specializes in designing and implementing conversion-focused landing pages that optimize your 
    website’s user experience and nurture leads through targeted email sequences. The goal is to convert visitors into 
    customers.`

  },
  {
    id: 7,
    title: ' Social Media Mastery',
    para: `Zai Systems crafts engaging content, sparks 
    conversations, and builds thriving communities around your brand on every platform. It's like turning your 
    social media pages into a vibrant town square, where your brand is the mayor, everyone know you, and loyalty
    is deep.`

  },
  {
    id: 8,
    title: 'Thought Leadership that Elevates',
    para: `Forget blending in with the crowd. Zai Systems helps you establish your brand as a thought leader in your field.
    With high-quality white papers, blog posts, and webinars, we'll position you as the go-to source for expertise,
     attracting customers and building trust along the way.`

  },
  {
    id: 9,
    title: 'A Customer-Centric Approach',
    para: `Zai Systems takes the time to understand 
    your unique needs, goals, and challenges. We work collaboratively with you, ensuring every campaign is tailored to your 
    specific vision and market. Think of it as a hand-in-hand journey to digital success, with Zai Systems as your trusted guide.`

  }
]
const SeoDataDigital = [
  {
    id: 1,
    title: `B2B Digital Marketing Services`,
    content: `Tired of mediocre B2B digital marketing? We deliver measurable results, not empty promises. Optimize your spend & scale your business. Contact us today!`,
    link: `https://www.zaisystems.com/services/digital-marketing-services`
  }
]
// graphic-designing
const graphicDesigning1 = [
  {
    id: 1,
    title: `Zai Systems: Comprehensive Graphic Design Services`,
    para: `Welcome to Zai Systems, your one-stop solution for all your graphic design needs. Whether you're looking to create a brand identity from scratch or revamp your existing designs, our expert team is here to deliver top-notch, creative, and impactful graphic design services. With years of experience and a portfolio that speaks for itself, we are committed to helping businesses like yours stand out in a crowded marketplace. Our services are tailored to meet the specific needs of your business, ensuring that every design we create is not only visually appealing but also strategically effective.
`,
    img: GraphicDesign,
  }
]


const graphicDesigning4a = [
  {
    id: 1,
    title: `Our Complete Graphic Design Services`,
    para: `At Zai Systems, we understand the power of good design. Our Graphic Design Services are crafted to enhance your brand's visual identity and communicate your message effectively. We offer a wide range of graphic design services.`
  },

]
const graphicDesigning4 = [
  {
    id: 1,
    title: 'Logo Designing Services',
    para: `A logo is the face of your brand. It's often the first thing potential customers notice about your business. At Zai Systems, we specialize in Logo Designing Services that capture the essence of your brand. Our logos are designed to be memorable, versatile, and timeless, ensuring that your brand stands out in the marketplace. Whether you need a modern, minimalist logo or a more intricate design, our team has the expertise to bring your vision to life.
`,
    img: graphicOne
  },
  {
    id: 4,
    title: 'Business Card Design',
    para: `Your business card is more than just a contact card; it's a small piece of your brand that you leave with others. Our Business Card Design service focuses on creating unique, professional, and impactful business cards that leave a lasting impression. We use high-quality materials and innovative designs to ensure your business card stands out.
`,
    img: graphicTwo
  },
  {
    id: 10,
    title: 'Label Design',
    para: `Labels are a crucial aspect of product packaging and can significantly influence a customer's decision to purchase. Our Label Design services ensure that your products are presented in the best possible light. We create eye-catching, informative, and compliant labels that not only attract customers but also adhere to industry standards.
`,
    img: graphicThree
  },
  {
    id: 2,
    title: 'Infographics Design Services',
    para: `In today's fast-paced world, conveying complex information quickly and effectively is essential. Our Infographics Design Services are perfect for turning data and information into visually engaging graphics that are easy to understand. We combine creativity with strategic thinking to produce infographics that communicate your message clearly and effectively.
`,
    img: graphicFour
  },

  {
    id: 3,
    title: 'Website Design & Development',
    para: `Your website is often the first point of contact between your business and potential customers. At Zai Systems, we offer comprehensive Website Design & Development services that ensure your website is not only visually appealing but also user-friendly and optimized for conversions. From UX/UI design to responsive layouts, our team ensures that your website meets the highest standards of performance and aesthetics.`,
    img: graphicFive
  },

  {
    id: 5,
    title: 'Marketing Materials',
    para: `Effective marketing materials are essential for any business looking to promote its products or services. Our Marketing Materials design services include brochures, flyers, posters, and more. We create materials that are not only visually appealing but also aligned with your brand’s message and goals.
`,
    img: graphicSix
  },
  {
    id: 9,
    title: 'UX/UI Design',
    para: `User experience and user interface design are critical to the success of any digital product. Our UX/UI Design services focus on creating intuitive and engaging interfaces that provide a seamless experience for your users. We use the latest design trends and best practices to ensure that your digital products are both functional and visually appealing.
`,
    img: graphicSeven
  },
  {
    id: 7,
    title: 'PPT Design',
    para: `Presentations are a powerful tool for communicating your ideas. Our PPT Design services help you create professional, visually engaging presentations that capture your audience's attention. We focus on clear, concise, and impactful design, ensuring that your message is delivered effectively.
`,
    img: graphicEight
  },
  {
    id: 8,
    title: 'Print, Merch & Packaging Design ',
    para: `Get on-brand packaging for your products that speaks volumes about your quality and values. We design anything and 
    everything to make your brand shine in print, both online and offline, and leaves an unforgettable impression on customers.`,
    img: graphicNine
  },

  {
    id: 6,
    title: 'Landing Page Design',
    para: `A well-designed landing page is essential for converting visitors into customers. Our Landing Page Design services focus on creating high-converting landing pages that are visually appealing and strategically designed. We ensure that your landing page is optimized for both desktop and mobile users, providing a seamless experience across all devices.
`,
    img: graphicTen
  },
  {
    id: 11,
    title: 'Branding & Identity Design',
    para: `Your brand's identity is more than just a logo; it's the overall image that your company projects. Our Branding & Identity Design services help you create a cohesive and strong brand identity that resonates with your target audience. From logo design to brand guidelines, we cover every aspect of your brand’s visual identity.
`,
    img: graphicEleven
  },
  {
    id: 12,
    title: 'Web Banner Design',
    para: `Web banners are an essential part of online marketing. Our Web Banner Design services create eye-catching and effective banners that drive traffic and conversions. We design banners for various platforms, ensuring that they are optimized for each specific medium.
`,
    img: graphicThirteen
  },
  {
    id: 13,
    title: '3D Logo Designing',
    para: `If you're looking for something more dynamic and modern, our 3D Logo Designing services are perfect for you. We create stunning 3D logos that add depth and dimension to your brand identity. These logos are perfect for businesses looking to make a bold statement.`,
    img: graphicTwelve
  },
  {
    id: 14,
    title: 'Billboard Design',
    para: `Billboards are a powerful marketing tool that can reach a large audience. Our Billboard Design services ensure that your message is communicated effectively through bold and creative designs. We focus on clarity and impact, ensuring that your billboard grabs attention and leaves a lasting impression.
`,
    img: graphicForteen
  },
  {
    id: 15,
    title: 'Print Design',
    para: `From brochures to posters, print design is a crucial aspect of any marketing strategy. Our Print Design services cover a wide range of products, ensuring that your printed materials are of the highest quality and align with your brand’s visual identity.
`,
    img: graphicSix
  },
  {
    id: 16,
    title: 'Art & Illustration',
    para: `For businesses looking for a more artistic touch, our Art & Illustration services provide custom illustrations that add a unique and creative element to your brand. Whether you need illustrations for a book, website, or marketing materials, our talented artists can bring your vision to life.
`,
    img: graphicFifteen
  },
  {
    id: 17,
    title: 'Infographics & Data Visualization Services',
    para: `Data visualization is essential for making complex information more accessible. Our Infographics & Data Visualization services turn your data into compelling visual stories. We use a combination of creativity and technical expertise to create infographics that are both informative and visually engaging.
`,
    img: graphicFour
  },
  {
    id: 18,
    title: 'Motion Graphics Design',
    para: `Motion graphics are a powerful way to bring your brand to life. Our Motion Graphics Design services create dynamic and engaging animations that can be used in videos, websites, and other digital platforms. We focus on storytelling through motion, ensuring that your message is communicated effectively.
`,
    img: graphicTwelve
  },
  {
    id: 19,
    title: 'Digital Ads & Marketing Design',
    para: `In the digital age, online advertising is crucial for any business. Our Digital Ads & Marketing Design services create visually appealing and effective ads for various platforms, including social media, search engines, and display networks. We focus on creating ads that drive engagement and conversions.
`,
    img: graphicEighteen
  }

]
const graphicDesigning5a = [
  {
    id: 1,
    title: `Beyond Design: What Makes Zai Systems Your Difference Maker`,
    para: `In the vast landscape of design agencies, it's easy to get lost in a sea of promises. But at Zai Systems, we're
     not just another pixel pusher. We're your difference maker, the secret weapon that elevates your brand beyond the ordinary.`,

  },

]
// const graphicDesigning6a = [
//   {
//     id: 1,
//     title: `Why Choose Zai Systems?`,
//     para: `At Zai Systems, we believe that great design is more than just aesthetics; it's about creating a visual identity that resonates with your audience and drives results. Here’s why you should choose us for your graphic design needs:`
//   },

// ]
// const graphicDesigning6 = [
//   {
//     id: 1,
//     title: '•	Expertise:',
//     para: `Our team of experienced designers has a deep understanding of design principles and trends, ensuring that your designs are both modern and timeless.`,

//   },
//   {
//     id: 2,
//     title: '•	Customization:',
//     para: `We understand that every business is unique, and we tailor our services to meet your specific needs and goals.`,
//   },
//   {
//     id: 3,
//     title: '•	Quality: ',
//     para: `We are committed to delivering high-quality designs that meet your expectations and exceed industry standards.`,
//   },
//   {
//     id: 4,
//     title: '•	Timeliness:',
//     para: `We respect your time and ensure that all projects are completed on schedule without compromising on quality.`,
//   },
//   {
//     id: 5,
//     title: '•	Customer Service:',
//     para: `We pride ourselves on providing excellent customer service, and we are always here to assist you with any questions or concerns.`,
//   }
// ]
const graphicDesigning7 = [
  {
    id: 1,
    title: 'Get Started with Zai Systems Today',
    para: 'Ready to take your brand to the next level with professional graphic design services? Contact us today to discuss your project and see how we can help you achieve your business goals. Whether you need a new logo, a complete brand identity, or marketing materials, Zai Systems is here to provide you with top-notch design solutions that make a difference.'
  }
]
const graphicDesigningTestimonials = [
  {
    id: 1,
    para: `Zai Systems designed a stunning logo that perfectly captured our brand’s essence. Their creative approach and attention to detail were outstanding. Highly recommended.`,
    name: "Sophia Lewis",
    title: "Marketing Manager"
  },
  {
    id: 2,
    para: `The rebranding work Zai Systems did for us was excellent. Their designs are modern, professional, and have received great feedback from our customers.`,
    name: "Benjamin Clark",
    title: "Creative Director"
  },
  {
    id: 3,
    para: `Zai Systems delivered unique business card designs that aligned perfectly with our brand. Their creativity and professionalism are commendable.`,
    name: "Ava Robinson",
    title: "Entrepreneur"
  },
  {
    id: 4,
    para: `Our website redesign by Zai Systems is phenomenal. Their UX/UI designs improved our site’s functionality and engagement. It’s been a great collaboration.`,
    name: "Charlotte Walker",
    title: "Digital Strategist"
  },
  {
    id: 5,
    para: `The complete rebranding done by Zai Systems helped us stand out in a competitive industry. Their work exceeded expectations and resonated well with our audience.`,
    name: "Liam Scott",
    title: "Solar Solutions CEO"
  }
];

const graphicDesigningFAQs = [
  { questions: 'What is the typical turnaround time for a graphic design project?', answers: 'The turnaround time for a graphic design project varies depending on the complexity and scope of the project. Typically, logo designs can take 1-2 weeks, while more extensive projects like branding and website design may take 4-6 weeks.' },
  { questions: 'Can I request revisions to the designs?', answers: 'Yes, we offer a certain number of revisions for each project to ensure that you are completely satisfied with the final design. The number of revisions is agreed upon at the start of the project.' },
  { questions: 'What file formats will I receive upon project completion?', answers: 'We provide final designs in multiple formats, including JPEG, PNG, PDF, and vector formats such as AI or EPS. This ensures that you have the necessary files for both digital and print use.' },
  { questions: 'Do you offer custom design services for specific industries?', answers: `Absolutely! We tailor our design services to meet the unique needs of various industries. Our team has experience working across a wide range of sectors, ensuring that your design aligns with industry standards and your brand's identity.` },
  { questions: 'What is the cost of your graphic design services?', answers: 'The cost of our graphic design services varies based on the specific needs of each project. We offer competitive pricing and can provide a detailed quote after discussing your requirements.' },
  { questions: 'Can you help with rebranding my existing business?', answers: 'Yes, we offer comprehensive rebranding services that include logo redesign, new brand guidelines, and updated marketing materials. We work closely with you to ensure that your new brand identity reflects your business goals.' },
  { questions: 'Do you offer ongoing design support?', answers: 'Yes, we provide ongoing design support for clients who need regular updates or new materials. We can work on a retainer basis or as needed, depending on your requirements.' },
  { questions: 'How do you ensure the designs are original and not copied?', answers: 'We take intellectual property seriously and guarantee that all designs are original and created specifically for your brand. Our designers follow strict guidelines to ensure all work is unique.' },
  { questions: `What if I'm not satisfied with the final design?`, answers: `Your satisfaction is our top priority. If you're not happy with the final design, we will work with you to make adjustments or provide alternative concepts until you are fully satisfied.` },
  { questions: 'Do you offer digital marketing services in addition to graphic design?', answers: 'While our primary focus is on graphic design, we also offer complementary services such as digital ads design, social media graphics, and website design & development, which are crucial for successful digital marketing campaigns.' },
];

const SeoDataGraphic = [
  {
    id: 1,
    title: `Graphic Design and Branding Services`,
    content: `Stand Out from the Crowd by Unforgettable logos, packaging, & website design. Our branding & graphic design services build a powerful visual identity that resonates.`,
    link: `https://www.zaisystems.com/services/graphic-designing`
  }
]
// content writing
const contentWriting1 = [
  {
    id: 1,
    title: `Content Writing Services by Zai Systems`,
    para: `In today's digital age, compelling and well-structured content is pivotal for any successful online strategy. At Zai Systems, we provide Professional Content Writing services that cater to diverse needs and help businesses achieve their digital marketing goals. From web content writing services to SEO optimized content writing, our team of experienced writers ensures that your content stands out and engages your audience effectively.`,
    img: ContentWriting,
  }
]
const contentWriting4a = [
  {
    id: 1,
    title: `Why Choose Our Content Writing Services?`,
  },

]
const contentWriting4 = [
  {
    id: 1,
    title: 'SEO Optimized Content Writing',
    para: `In the competitive landscape of digital marketing, SEO plays a crucial role in enhancing visibility and driving traffic. Our SEO optimized content writing services are designed to improve your website’s ranking on search engines, making sure your content is not only engaging but also discoverable. We employ advanced SEO strategies to ensure that your content meets the latest search engine algorithms, helping you attract more organic traffic.`,
    img: contentOne
  },
  {
    id: 2,
    title: 'Copywriting Services',
    para: `Effective communication is key to capturing your audience's attention. Our copywriting services focus on crafting persuasive and impactful content that resonates with your target audience. Whether it’s for your website, advertisements, or promotional materials, our copywriters create messages that drive action and build brand awareness.`,
    img: contentTwo
  },
  {
    id: 3,
    title: 'Blog Writing Services',
    para: `A well-maintained blog can be a powerful tool for driving traffic and establishing authority in your industry. Our blog writing services include creating engaging and informative posts that provide value to your readers and encourage them to return for more. We also offer SEO blog writing services to ensure that your blog posts are optimized for search engines, helping you rank higher and reach a broader audience.`,
    img: contentFour
  },
  {
    id: 4,
    title: 'Content Creation Services',
    para: `Beyond writing, our content creation services encompass a range of formats including infographics, videos, and social media content. We understand the importance of varied content types in engaging your audience across different platforms. Our team works to create content that aligns with your brand voice and enhances your online presence.`,
    img: contentThree
  },

  {
    id: 5,
    title: 'Article Writing',
    para: `Articles are a fundamental aspect of content marketing that helps in establishing credibility and providing in-depth information on topics relevant to your industry. Our article writing services focus on delivering well-researched and meticulously crafted articles that engage readers and position your brand as an industry leader.`,
    img: contentNine
  },
  {
    id: 6,
    title: 'Product Description Writing',
    para: `Effective product descriptions are essential for converting visitors into customers. Our product description writing services ensure that each product is presented in the best light, highlighting its features and benefits in a compelling manner. We craft descriptions that not only inform but also persuade potential buyers.`,
    img: contentSix
  },
  {
    id: 7,
    title: 'Press Release Writing Services',
    para: `When you have newsworthy information to share, our press release writing services ensure that your announcements reach the right audience in a professional and impactful way. We craft press releases that capture attention, convey your message clearly, and enhance your media coverage.`,
    img: contentSeven
  },
  {
    id: 8,
    title: 'Ad Copy Writing',
    para: `The effectiveness of your advertisements often hinges on the quality of your ad copy. Our ad copy writing services are designed to create persuasive and memorable ads that drive conversions and increase brand visibility. We focus on crafting compelling messages that resonate with your target audience.`,
    img: contentEight
  },
  {
    id: 9,
    title: 'Meta Writing',
    para: `Effective meta descriptions and titles are crucial for improving click-through rates and enhancing SEO. Our meta writing services ensure that your meta tags are optimized and aligned with best practices, helping your web pages perform better in search results.`,
    img: contentFive
  },
  {
    id: 9,
    title: 'Agency Writing',
    para: `As a leading content writing agency, we offer comprehensive solutions tailored to your specific needs. Our agency writing services cover a wide range of content types, from marketing materials to technical documents, ensuring that every piece of content meets the highest standards of quality.
`,
    img: contentFive
  }
]
const contentWriting5a = [
  {
    id: 1,
    title: 'How It Works?',
  },

]
const contentWriting5 = [
  {
    id: 1,
    title: `Effortless Content Creation`,
    para: `Zai Systems modernizes content creation, making it quick, accessible, and cost-effective. Our process is designed to 
    empower you with high-quality content, simplifying the journey from conception to publication.`
  },
  {
    id: 2,
    title: `Seamless Order Placement`,
    para: `Zai Systems utilizes a streamlined, one-page interface that guides you through essential content requirements. Specify 
    your desired voice tone, target keywords, and any additional guidelines with ease.`

  },
  {
    id: 3,
    title: `Expert Writer Matching`,
    para: ` Our pool of qualified writers is hand-picked for their skills and expertise. Once you place your order, a writer
     perfectly suited to your project will claim it and begin crafting your content based on your precise instructions. `

  },
  {
    id: 4,
    title: `Collaborative Refinement`,
    para: `Never settle for less than perfect! Zai Systems fosters open communication. Utilize instant chat or request 
    revisions to ensure your content aligns perfectly with your vision. We're committed to delivering exactly what you need.`
  },
  {
    id: 5,
    title: `Audience Engagement & Growth`,
    para: `Zai Systems helps you captivate your audience with compelling content. Publish amazing work that resonates,
     driving recommendations, social shares, and organic search traffic. Watch your customer base and online presence flourish!`
  },
  {
    id: 6,
    title: `Secure and Reliable `,
    para: `Zai Systems values your privacy and security. We use the latest encryption and authentication technologies to 
    protect your data and transactions. We also backup your content regularly, ensuring you never lose your work. `
  }
]
const contentWriting6a = [
  {
    id: 1,
    title: 'Zai Systems Approach: Content Marketing Success in Simple Steps',
  },

]
const contentWriting6 = [
  {
    id: 1,
    title: `Rule the Search Engines`,
    para: `
    Publish high-quality, informative, and engaging content regularly. Google loves this, and your website will climb the ranks. 
    Ditch thin or poor-quality content. Google frowns upon it and it hurts your ranking. Let our content writing expertise craft 
    content that Google craves, boosting your visibility and organic traffic.
    `
  },
  {
    id: 2,
    title: `Ignite Your Social Media Bonfire`,
    para: `
    Share engaging, valuable content that people can't resist sharing. Tweets will fly, Likes will cascade. Power your social 
    media with high-quality blog posts that resonate with your audience. Watch your fan base grow like wildfire. Execute this 
    strategy consistently each week. Consistency is key.
    `
  },
  {
    id: 3,
    title: `Conquer Content Marketing Everest`,
    para: `Don't stick to just articles. Zai Systems offers a diverse range of content types, including eBooks, white papers, and 
    social media posts, to keep your audience engaged. From articles and blog posts to eBooks and white papers, our writers 
    have the expertise to craft any type of written content you need to conquer content marketing.`

  }
]
const contentWriting8a = [
  {
    id: 1,
    title: `Our Process`

  },

]
const contentWriting8 = [
  {
    id: 1,
    title: '1.	Consultation',
    para: `We begin by understanding your needs to develop a tailored content strategy..
`,
    img: contentTen
  },
  {
    id: 2,
    title: '2.	Research',
    para: `Our experts conduct in-depth research to gather insights and valuable data.`,
    img: contentEleven
  },
  {
    id: 3,
    title: '3.	Writing',
    para: `We craft SEO-optimized content designed to engage and boost your rankings.
`,
    img: contentTwelve
  },
  {
    id: 4,
    title: '4.	Editing',
    para: `Our team ensures every piece is polished for clarity, quality, and consistency.`,
    img: contentThirteen
  },
  {
    id: 5,
    title: '5.	Delivery',
    para: `We deliver high-quality content on time, aligned with your specific goals.
`,
    img: contentFourteen
  },
  {
    id: 6,
    title: '6. Feedback and Revisions',
    para: `We make necessary revisions based on your feedback to achieve perfection.
`,
    img: contentTen
  },
]
const Contenttestimonials = [
  {
    id: 1,
    para: `The SEO content from Zai Systems improved our website’s rankings and attracted more visitors. Their well-researched and engaging articles are a valuable asset.`,
    name: "Grace Hall",
    title: "Content Marketing Manager"
  },
  {
    id: 2,
    para: `Zai Systems’ press releases are impactful and have gained us great media coverage. Their professionalism and creativity are unmatched.`,
    name: "Mason Young",
    title: "PR Specialist"
  },
  {
    id: 3,
    para: `Their blog writing services are exceptional. Zai Systems consistently delivers high-quality, engaging content that aligns with our brand’s voice.`,
    name: "Lily Wright",
    title: "Editorial Lead"
  },
  {
    id: 4,
    para: `Zai Systems’ SEO blogs transformed our website traffic. Their content strategy is insightful and results-driven. We’ve seen remarkable improvements.`,
    name: "Noah Mitchell",
    title: "SEO Strategist"
  },
  {
    id: 5,
    para: `The press releases and marketing content created by Zai Systems effectively boosted our brand visibility. Their writing style captures attention and delivers the message powerfully.`,
    name: "Harper King",
    title: "Media Relations Manager"
  }
];

const ContentServiceFAQs = [
  { questions: 'What is content writing', answers: 'Content writing involves creating written material for various platforms, including websites, blogs, social media, and marketing collateral. It aims to inform, engage, and convert readers.' },
  { questions: 'How does Zai Systems ensure the quality of content?', answers: 'We ensure quality by employing experienced writers, conducting thorough research, and adhering to strict editorial guidelines. Our content undergoes multiple rounds of editing and review to meet high standards.' },
  { questions: 'How does SEO-optimized content benefit my business?', answers: 'SEO-optimized content improves your website’s visibility on search engines, driving more organic traffic, enhancing user engagement, and increasing conversion rates.' },
  { questions: 'What is the difference between copywriting and content writing?', answers: 'Copywriting focuses on creating persuasive content aimed at driving specific actions, such as making a purchase. Content writing, on the other hand, provides valuable information and engages readers without necessarily driving immediate sales.' },
  { questions: 'How do you price your content writing services?', answers: 'Pricing is based on the type of content, length, and complexity. Contact us for a detailed quote.' },
  { questions: 'Can you help with  and planning?', answers: 'Yes, we offer content strategy and planning services to help you develop a comprehensive content plan.' },
  { questions: 'How do you handle sensitive or confidential information?', answers: 'We prioritize confidentiality and ensure that all sensitive information is handled with the utmost care.' },
  { questions: 'What makes Zai Systems’ content writing services unique?', answers: 'Our focus on quality, SEO optimization, and tailored solutions sets us apart and ensures your content delivers results.' },
];
const SeoDataContent = [
  {
    id: 1,
    title: `SEO content writing services`,
    content: `Boost Rankings & Traffic with SEO-Fueled Content. Our expert writers craft engaging articles, blogs, & website copy that drives organic search success. Get a free quote & see the difference!`,
    link: `https://www.zaisystems.com/services/content-writing`
  }
]


//managed-cloud-services
const cloud1 = [
  {
    id: 1,
    title: `Managed Cloud Services`,
    para: `In today’s fast-paced digital world, businesses need agile, scalable, and secure cloud solutions to thrive. At Zai Systems, we specialize in delivering top-tier managed cloud services tailored to your unique needs. Whether you’re a small business looking to scale or a large enterprise optimizing operations, we’ve got you covered. Discover how Zai Systems can simplify your cloud journey and unlock your business’s full potential.`,
    img: manCloud,
  }
]
const cloud5a = [
  {
    id: 1,
    title: `A Closer Look at Our Managed Cloud Services`,
    para: `We offer comprehensive Managed Cloud Services designed to empower your business
     with the flexibility, scalability, and security of the cloud, without the burden of
      managing it yourself.`,
    para1: `Our Services Portfolio:`
  },

]
const cloud5 = [
  {
    id: 1,
    title: 'Cloud Migration',
    para: `Migrating your infrastructure and applications to the cloud doesn't have to be a storm.
     Our expert team steers you through this process, ensuring a smooth and secure transition with 
     minimal downtime or disruption. We:`,
    para1: `Conduct a thorough evaluation of your existing environment and cloud readiness using best practices.`,
    para2: `Develop a customized migration plan that aligns with your budget, timeline, and desired outcomes. Evaluate the risks and benefits.`,
    para3: `Utilize advanced tools and methodologies to facilitate efficient data transfer and application refactoring.`,
    para4: `Provide ongoing support and guidance  throughout the migration process, ensuring seamless integration 
    with your workflows. `
  },
  {
    id: 2,
    title: 'Cloud Infrastructure Management',
    para: `Imagine a conductor meticulously guiding a symphony of cloud resources. That's what our Cloud Infrastructure
     Management team does for you. We take the reins of your entire cloud environment, handling:`,
    para1: `Provisioning and configuration of servers, storage, networks, and other cloud resources.`,
    para2: `Continuous monitoring and optimization for performance, security, and cost-effectiveness of cloud resources. `,
    para3: `Automated patching and updates to ensure your cloud infrastructure remains secure and compliant.`,
    para4: `24/7/365 support and proactive troubleshooting to address any issues before they impact your operations. `
  },
  {
    id: 3,
    title: 'Cloud Security Services',
    para: `In today's digital landscape, security is paramount. Our Cloud Security Services build an impenetrable fortress around
     your data and applications, shielding them from evolving cyber threats. We: `,
    para1: `Conduct comprehensive security assessments to identify vulnerabilities and potential risks. Implement appropriate mitigation measures.`,
    para2: `Implement robust security controls such as firewalls, intrusion detection systems, and data encryption. `,
    para3: `Regularly monitor and update your security posture to adapt to evolving threats and vulnerabilities.`,
    para4: `Provide security awareness training to empower your employees to safeguard your cloud environment. `
  },
  {
    id: 4,
    title: 'Cloud Application Management',
    para: `We manage your cloud applications for optimal performance, uptime, and user experience. Our Cloud Application Management covers:`,
    para1: `Automated deployment and scaling to meet fluctuating demand and optimize resource utilization.`,
    para2: `Performance monitoring and optimization to ensure your applications run smoothly and efficiently. `,
    para3: `High availability and disaster recovery solutions to minimize downtime and safeguard against unforeseen disruptions.`,
    para4: `Ongoing maintenance and updates to keep your applications current, secure, and scalable. `
  },
  {
    id: 5,
    title: 'DevOps and Automation',
    para: `Break down the silos between development and operations with our DevOps and Automation expertise. We empower you with:`,
    para1: `Continuous integration/continuous delivery (CI/CD) pipelines that automate your software
     development lifecycle, and bring updates.`,
    para2: `Infrastructure as code (IaC) practices that codify your cloud infrastructure, 
    enabling rapid provisioning and configuration changes. `,
    para3: `GitOps workflows for version control and deployment management, ensuring consistency and collaboration.`,
    para4: `Expert guidance and training to help you build and implement a robust DevOps culture. `
  },
  {
    id: 6,
    title: 'Cloud Cost Optimization',
    para: `Our Cloud Cost Optimization service ensures
     that you get the most value out of your cloud investment. We:`,
    para1: `Perform detailed cost analysis to identify and eliminate wasteful spending, optimizing operational financial efficiency.`,
    para2: `Implement best practices for resource allocation to optimize costs effectively without compromising system performance.`,
    para3: `Offer tailored recommendations for reserved instances and comprehensive savings plans to significantly reduce long-term expenses.`,
    para4: `Provide regular reporting and insights on spending trends for accurate forecasting.`
  }
]
const cloud6a = [
  {
    id: 1,
    title: `Explore Zai Systems' Managed Cloud Expertise`

  }

]
const cloud6 = [
  {
    id: 1,
    title: 'Experienced Cloud Architects',
    para: (
      <>
        <span class='text-white'>Beyond Certifications:</span> While certifications are essential, our
        cloud architects boast years of real-world experience in designing, building, and managing complex
        cloud environments ON AWS, Azure, GCP.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Specialization & Depth:</span> We have dedicated teams specializing in
        specific platforms, ensuring you receive deep expertise tailored to your chosen cloud provider.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>Continuous Learning:</span> Our architects keep pace with
        the cloud landscape, attending industry events, acquiring certifications, and mastering
        cutting-edge technologies to benefit your journey.
      </>
    )
  }
  ,
  {
    id: 2,
    title: 'Proven Methodology',
    para: (
      <>
        <span class='text-white'>Zai Cloud Framework:</span> We use Zai Cloud Framework,
        a structured approach that optimizes every stage of your cloud journey, from initial assessment and
        migration to ongoing management and optimization.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Agility & Adaptability: </span>Our framework is flexible and adaptable, adjusting
        to your unique business needs and cloud environment while ensuring consistent delivery of high-quality service.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>Continuous Improvement:</span> We embrace a culture of continuous improvement, regularly
        reevaluating and refining our processes to ensure you always receive the best possible cloud experience.
      </>
    )
  },
  {
    id: 3,
    title: 'Transparency & Collaboration',
    para: (
      <>
        <span class='text-white'>Dedicated Cloud Success Manager: </span>You'll have a single point of contact – your
        dedicated Cloud Success Manager – who acts as your advocate, understands your needs, and provides personalized support.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Open Communication: </span>We believe in clear and consistent communication, keeping you
        informed every step of the way through regular reports, meetings, and proactive updates.
      </>
    ),

    para2: (
      <>
        <span class='text-white'>Collaborative Workshops: </span>We conduct workshops to understand your business
        goals, cloud vision, and risk tolerance, aligning our services with your strategic objectives.
      </>
    )
  },
  {
    id: 4,
    title: 'Scalability & Flexibility',
    para: (
      <>
        <span class='text-white'>Elastic Infrastructure: </span>We design your cloud infrastructure to scale seamlessly as
        your business grows, accommodating fluctuating workloads and future expansion without disrupting operations.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Pay-as-you-go Model: </span>You only pay for the cloud resources you use, giving you
        complete control over your cloud expenses and avoiding unnecessary overhead.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>Hybrid & Multi-Cloud Options:</span> We support hybrid and multi-cloud environments,
        leveraging different platforms for optimal performance and cost efficiency.
      </>
    )
  },
  {
    id: 5,
    title: 'Cost Optimization',
    para: (
      <>
        <span class='text-white'>Resource Optimization Tools:</span> We employ advanced tools and automation to
        continuously monitor and optimize your cloud resource usage, eliminating resource waste and maximizing your ROI.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Rightsizing Recommendations:</span> Our experts analyze your usage patterns and suggest
        rightsizing strategies for cost-effective cloud resources.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>Cost Transparency & Reporting:</span> We provide detailed cost reports to give you complete
        visibility into your cloud spending and identify potential areas for further optimization.
      </>
    )
  },
  {
    id: 6,
    title: `Security and Compliance `,
    para: (
      <>
        <span class='text-white'>Continuous Monitoring:</span> Employ advanced continuous monitoring
        tools to proactively detect and swiftly respond to threats in real time,
        ensuring your cloud environment remains secure around the clock.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Compliance Audits:</span> Regularly conduct thorough compliance audits to ensure your cloud
        infrastructure consistently meets all necessary industry regulations and standards.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>Robust Security Protocols: </span>Implement state-of-the-art security measures, including
        firewalls, encryption, and intrusion detection systems to safeguard your data.
      </>
    )

  }
]

const SeoDataCloud = [
  {
    id: 1,
    title: `Managed Cloud Services - Zai Systems`,
    content: `Experience seamless cloud migration and ongoing management with Zai Systems. Get a free consultation for our services today! `,
    link: `https://www.zaisystems.com/services/managed-cloud-services`
  }
]

//ecommerce
const ecommerce1 = [
  {
    id: 1,
    title: `E-commerce Solution Providers`,
    para: `At Zai Systems, we specialize in empowering businesses to thrive in the ever-evolving digital landscape. As your dedicated e-commerce solution providers, we offer a seamless blend of innovative technology, data-driven strategies, and unmatched customer support to help you build, manage, and scale your online store effortlessly. Whether you’re launching a new venture or optimizing your existing platform, we’re here to help you achieve unparalleled success in the e-commerce industry.`,
    img: ecomm,
  }
]
const ecommerce5a = [
  {
    id: 1,
    title: `Our Comprehensive E-commerce Services`,
    para: `
    Our solutions are built to scale with your business, supporting your growth aspirations both now and in the future.
    `

  }

]
const ecommerce5 = [
  {
    id: 1,
    title: 'Website Development',
    para: `We craft bespoke e-commerce platforms that are visually stunning, user-friendly, secure, and scalable.
     From WordPress and Magento to custom solutions, we have the expertise to realize your vision. Here’s how we
      make website shine:`,
    para1: (
      <>
        <span class='text-white'>Intuitive Design:</span> We create user interfaces that are easy to navigate and understand, ensuring a seamless shopping experience for your customers.

      </>
    ),
    para2: (
      <>
        <span class='text-white'>Mobile-First Approach: </span> In today's mobile-driven world, we prioritize responsive
        design that looks and functions flawlessly on all devices, regardless of screen size.

      </>
    ),
    para3: (
      <>
        <span class='text-white'>Performance Optimization: </span> We optimize your website for speed and efficiency,
        ensuring fast loading times and a smooth browsing experience for your visitors.

      </>
    ),
    para4: (
      <>
        <span class='text-white'>Content Management System (CMS) Integration: </span> We provide you with a user-friendly CMS to easily manage your website content, products, and orders.

      </>
    ),
    para5: (
      <>
        <span class='text-white'>Search Engine Optimization (SEO): </span> We optimize your website for search engines so your target audience can find you online.

      </>
    )
  },
  {
    id: 2,
    title: 'Digital Marketing',
    para: `We don't just build your website; we drive traffic to it. Our data-driven digital marketing strategies, 
    including SEO, PPC, and social media marketing, ensure your
     target audience finds you and converts. Here's how we bring you customers:`,
    para1: (
      <>
        <span class='text-white'>Search Engine Optimization (SEO):</span> We optimize your website and content for
        relevant keywords to improve your organic search ranking and attract qualified leads.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>Pay-Per-Click (PPC) Advertising:</span> We run targeted PPC campaigns on platforms
        like Google Ads to reach your ideal customers with laser-focused precision.
      </>
    ),
    para3: (
      <>
        <span class='text-white'>Social Media Marketing: </span>We develop engaging social media content and campaigns
        to build your brand awareness, drive traffic to your website, and increase sales.
      </>
    ),
    para4: (
      <>
        <span class='text-white'>Email Marketing: </span>We create effective email marketing campaigns to nurture leads,
        engage customers, and promote your products and services.
      </>
    ),
    para5: (
      <>
        <span class='text-white'>Influencer Marketing: </span>We partner with relevant influencers to reach a wider
        audience and build trust in your brand.
      </>
    )
  },
  {
    id: 3,
    title: 'E-commerce Management',
    para: ` Managing an online store can be overwhelming. We take the burden off your shoulders with order fulfillment,
     inventory management, customer support, and payment processing solutions. Here's how we make your life easier:
    `,
    para1: (
      <>
        <span class='text-white'>Order Fulfillment: </span>We handle the entire order fulfillment process, from picking
        and packing orders to shipping and delivery in a timely manner.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>Inventory Management: </span>We manage your inventory levels to ensure you always have
        enough stock to meet customer demand and avoid stockouts.
      </>
    ),
    para3: (
      <>
        <span class='text-white'>Customer Support: </span>We provide friendly and efficient customer support to answer
        questions, resolve issues, and ensure customer satisfaction.
      </>
    ),
    para4: (
      <>
        <span class='text-white'>Payment Processing: </span>We integrate secure payment gateways to make it easy and convenient for
        your customers to pay for their purchases online.
      </>
    ),
    para5: (
      <>
        <span class='text-white'>Returns & Refunds: </span>We manage your returns and refunds process to ensure a
        smooth and hassle-free experience for your customers.
      </>
    )
  },
  {
    id: 4,
    title: 'Data Analytics'
    // ,para: `Knowledge is power, especially in e-commerce. We provide insightful data analytics that reveal customer 
    // behavior, track key metrics, and inform strategic decisions for improved performance. Here's how we turn data
    // into insights:
    // `
    ,
    para1: (
      <>
        <span class='text-white'>Customer Conversion Analysis:</span> We analyze your customer journey to identify
        conversion bottlenecks and optimize your sales funnel effectively.
      </>
    ), para2: (
      <>
        <span class='text-white'>Website Traffic Analysis:</span> We track your website traffic to understand how
        customers are finding you and interacting with your website.
      </>
    ),
    para3: (
      <>
        <span class='text-white'>Product Performance Analysis: </span>We track the performance of your products to
        see what's selling well and what needs improvement continuously.
      </>
    ),
    para4: (
      <>
        <span class='text-white'>Marketing Campaign Analysis: </span>We measure the effectiveness of your marketing
        campaigns to optimize your spending and maximize ROI.
      </>
    ),
    para5: (
      <>
        <span class='text-white'>Customizable Dashboards:</span> We provide you with customizable dashboards to
        easily access and visualize your data.
      </>
    )
  },
  {
    id: 5,
    title: 'Security & Compliance',
    para: ``,
    para1: (
      <>
        <span class='text-white'>Secure Hosting: </span>We host your website on secure servers with advanced security
        measures like firewalls, intrusion detection systems, and malware protection.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>Regular Backups: </span>We take regular backups of your website and data to ensure
        you can recover in case of any accidents or cyberattacks.
      </>
    ),
    para3: (
      <>
        <span class='text-white'>Security Audits & Penetration Testing: </span>We conduct regular security audits and
        penetration testing to identify and address any vulnerabilities in your systems.

      </>
    ),
    para4: (
      <>
        <span class='text-white'>Employee Training: </span>We train your employees on security best practices to protect
        your business from human error.
      </>
    ),
    para5: (
      <>
        <span class='text-white'>Data Privacy Compliance: </span>We help you comply with data privacy regulations like
        GDPR and CCPA to protect your customers' data.
      </>
    )
  },
  {
    id: 6,
    title: 'Analytics and Reporting  ',
    para: ``,
    para1: (
      <>
        <span class='text-white'>Real-Time Analytics:</span> Track user behavior, sales trends, and website performance
        in real time to swiftly and effectively adapt to your customers’ evolving needs.
      </>
    ),

    para2: (
      <>
        <span class='text-white'>Customer Insights:</span> Gain a deeper understanding of your customers’ preferences and
        buying habits to tailor your marketing and sales efforts.
      </>
    ),
    para3: (
      <>
        <span class='text-white'>Custom Reporting:</span> Generate custom reports that focus on the metrics that matter
        most to your business, from conversion rates to average order value.
      </>
    ),
    para4: (
      <>
        <span class='text-white'>Market Analysis:</span> Stay ahead of the competition with comprehensive market analysis
        that helps you identify opportunities and threats.
      </>
    ),
    para5: (
      <>
        <span class='text-white'>ROI Tracking:</span> Measure the return on investment for your marketing campaigns to
        ensure you’re allocating your budget effectively.
      </>
    )
  }
]
const ecommerce6a = [
  {
    id: 1,
    title: `Zai Systems' Cutting-Edge Tools`,
    para: `We offer a powerful suite of tools designed to automate tedious tasks, personalize the customer
     experience, and deliver actionable insights from your data.`

  }

]
const ecommerce6 = [
  {
    id: 1,
    title: 'E-commerce Automation',
    para: ``,
    para1: (
      <>
        <span class='text-white'>Order Processing: </span>Automate order confirmation, invoice generation,
        and shipment notifications, ensuring a smooth and timely experience for your customers and partners.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>Email Marketing: </span>Craft and schedule personalized email campaigns based
        on customer behavior and purchase history, nurturing leads and boosting engagement.
      </>
    ),
    para3: (
      <>
        <span class='text-white'>Social Media Scheduling: </span>Create and schedule engaging social media posts
        across multiple platforms in advance, saving time and maintaining consistent brand presence.

      </>
    ),
    para4: (
      <>
        <span class='text-white'>Inventory Management: </span>Implement automatic alerts for low stock levels and
        inventory updates, optimizing stock levels and preventing overselling.
      </>
    )
  },
  {
    id: 2,
    title: 'CRM Integration',
    para: ``,
    para1: (
      <>
        <span class='text-white'>Centralize Customer Data: </span>All customer information, from purchase history
        to interactions, is consolidated in one place, giving you a 360-degree view of each customer.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>Personalized Marketing: </span>Leverage rich customer data to personalize marketing
        campaigns, product recommendations, and promotional offers, driving higher engagement and conversion rates.
      </>
    ),
    para3: (
      <>
        <span class='text-white'>Improved Customer Service: </span>Equip your support team with detailed customer
        profiles and purchase history, enabling them to resolve issues faster and provide more tailored solutions.
      </>
    ),
    para4: (
      <>
        <span class='text-white'>Sales & Marketing Alignment: </span>Bridge the gap between sales and marketing efforts
        by sharing key customer insights and optimizing lead generation and nurturing strategies.
      </>
    )
  },
  {
    id: 3,
    title: 'Marketing Automation',
    para: ``,
    para1: (
      <>
        <span class='text-white'>Lead Nurturing: </span>Identify potential customers and engage them with automated
        emails, educational content, and personalized offers, gradually converting them into loyal customers.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>Customer Segmentation:</span> Group customers based on their demographics, purchase
        history, and behavior, delivering targeted campaigns and content for each segment.
      </>
    ),
    para3: (
      <>
        <span class='text-white'>Customer Retention: </span>Keep your existing customers engaged with automated loyalty
        programs, birthday greetings, and special offers, reducing churn and boosting customers.
      </>
    ),
    para4: (
      <>
        <span class='text-white'>Real-time Marketing:</span> Trigger personalized messages and offers based on customer
        actions or website visits, creating a dynamic and relevant customer experience.
      </>
    )
  },
  {
    id: 4,
    title: 'Data Visualization Tools',
    para: ``,
    para1: (
      <>
        <span class='text-white'>Track Key Metrics: </span>Monitor vital performance indicators like website traffic,
        conversion rates, sales figures, and customer engagement, all in one centralized location.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>Identify Trends: </span>Gain valuable insights into customer behavior, marketing campaign
        effectiveness, and product performance, allowing you to make data-driven decisions.
      </>
    ),
    para3: (
      <>
        <span class='text-white'>Interactive Exploration: </span>Drill down into specific data points and segments with
        interactive dashboards, uncovering hidden trends and opportunities for improvement.
      </>
    ),
    para4: (
      <>
        <span class='text-white'>Visual Communication: </span>Share data insights with stakeholders and team members
        easily through customizable reports and presentations, fostering informed decision-making.
      </>
    )
  },
  {
    id: 5,
    title: ' Data Analytics and Reporting ',
    para: ``,
    para1: (
      <>
        <span class='text-white'>Insightful Dashboards:</span> Visualize key performance indicators and metrics in
        real-time with customizable dashboards, helping you monitor business health at a glance.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>Advanced Reporting:</span> Generate detailed reports on sales, customer behavior,
        and campaign performance, enabling effective data-driven strategies for growth.
      </>
    ),
    para3: (
      <>
        <span class='text-white'>Predictive Analytics:</span> Utilize AI-driven predictive models to accurately
        forecast emerging trends, customer behavior, and significant market shifts, ensuring a competitive edge.
      </>
    ),
    para4: (
      <>
        <span class='text-white'>Data Integration:</span> Seamlessly combine data from various sources for a
        comprehensive, holistic view, ensuring accuracy, reliability, and consistency across your analytics.
      </>
    )
  },
  {
    id: 6,
    title: 'Content Management & Optimization ',
    para: ``,
    para1: (
      <>
        <span class='text-white'>SEO Tools:</span> Enhance and improve your website’s visibility with built-in
        SEO tools that suggest smart optimizations and accurately track keyword performance metrics.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>Content Scheduling:</span> Strategically plan and schedule content releases
        across various channels, ensuring a consistent, steady stream of engaging fresh content.
      </>
    ),
    para3: (
      <>
        <span class='text-white'>A/B Testing:</span> Experiment with different content versions to determine what resonates best with
        your audience, optimizing for engagement and conversion.
      </>
    ),
    para4: (
      <>
        <span class='text-white'>Content Personalization:</span> Deliver personalized content experiences to different user segments
        based on their behavior and preferences, increasing relevance and retention.
      </>
    )
  }
]
const ecommerce13a = [
  {
    id: 1,
    title: `E-commerce Growth with Zai Systems: Your Gateway to Unparalleled Benefits`,
    para: `Choosing the right partner for your e-commerce journey is a crucial decision, and Zai Systems stands ready
     to be your trusted guide to exceptional growth. We don't just offer services and tools; we offer a comprehensive
      and transformative solution designed to unlock tangible benefits across your entire e-commerce operation.`

  }

]
const ecommerce13 = [
  {
    id: 1,
    title: 'Supercharged Sales & Conversions',
    para: ``,
    para1: (
      <>
        <span class='text-white'>Targeted Traffic Acquisition: </span>Our data-driven digital marketing strategies drive qualified
        leads to your website through SEO, PPC, and social media marketing, ensuring you reach the right audience eager to convert.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>Seamless Conversion Optimization: </span>We optimize your website and user experience to guide
        customers through the purchase journey, reducing friction and conversions.
      </>
    ),
    para3: (
      <>
        <span class='text-white'>Personalized Product Recommendations: </span> Leverage the power of data to recommend relevant
        products to each customer, increasing cart value and boosting average order value.
      </>
    ),
    para4: (
      <>
        <span class='text-white'>Loyalty Programs & Incentives: </span>Reward your loyal customers and incentivize repeat business

        with customized programs and offers, fostering lasting relationships and driving growth.
      </>
    )
  },
  {
    id: 2,
    title: 'Efficiency & Productivity Unbound',
    para: ` `,
    para1: (
      <>
        <span class='text-white'>E-commerce Automation: </span>Let automated workflows handle repetitive tasks like order processing,
        email marketing, and social media scheduling, freeing your team to focus on strategic initiatives.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>CRM Integration: </span>Streamline customer management and communication with seamless CRM integration, saving
        time and improving customer service efficiency and satisfaction.
      </>
    ),
    para3: (
      <>
        <span class='text-white'>Inventory Management: </span>Automate inventory updates and alerts, preventing stockouts and optimizing
        purchasing decisions, allowing you to focus on other critical areas.
      </>
    ),
    para4: (
      <>
        <span class='text-white'>Data Visualization & Reporting: </span>Gain instant insights into key performance indicators through
        interactive dashboards and reports, enabling data-driven decisions.
      </>
    )
  },
  {
    id: 3,
    title: 'Customer Experience Elevated',
    para: ``,
    para1: (
      <>
        <span class='text-white'>Personalization at Every Touchpoint: </span> Tailor marketing messages, product recommendations,
        and even website content based on customer preferences, creating a sense of personalized value.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>Seamless Shopping Journey: </span>Design intuitive and user-friendly website experiences that
        guide customers from browsing to purchase, minimizing friction and boosting satisfaction.
      </>
    ),
    para3: (
      <>
        <span class='text-white'>Proactive Customer Support:</span> Equip your team with real-time data and insights to offer
        proactive support, anticipate customer needs, and build long-term trust.
      </>
    ),
    para4: (
      <>
        <span class='text-white'>Post-Purchase Engagement: </span>Keep the conversation going with personalized post-purchase
        communication, encouraging repeat business and fostering loyal customers.
      </>

    )
  },
  {
    id: 4,
    title: 'Data-Driven Decisions, Success',
    para: ``,
    para1: (
      <>
        <span class='text-white'>Real-time Performance Tracking: </span>Monitor key metrics like website traffic, conversion rates,
        and customer engagement in real-time, allowing you to make immediate adjustments and optimize your strategy.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>Campaign Performance Analysis: </span>Evaluate your marketing campaigns and
        identify areas for improvement, maximizing your ROI and ensuring every dollar spent delivers results.
      </>
    ),
    para3: (
      <>
        <span class='text-white'>Customer Behavior Insights: </span>Understand your customers on a deeper level by analyzing their
        purchase history, interactions, and preferences to personalize offerings and cater to needs.
      </>
    ),
    para4: (
      <>
        <span class='text-white'>Predictive Analytics: </span>Leverage advanced data models to anticipate future trends and customer
        behavior, allowing you to stay ahead of the curve and make proactive decisions.
      </>
    )
  },
  {
    id: 5,
    title: 'Reduced Costs & Mitigated Risks',
    para: ``,
    para1: (
      <>
        <span class='text-white'>Automated Security Measures: </span>Employ robust security tools and protocols to safeguard customer data
        and protect your website from cyberattacks, minimizing reputational damage and financial losses.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>Compliance Guidance: </span>Navigate the complexities of e-commerce regulations with expert guidance and
        ensure your business operates securely and within legal boundaries.
      </>
    ),
    para3: (
      <>
        <span class='text-white'>Operational Efficiency: </span>Automate processes and streamline workflows to reduce operational costs and
        optimize resource allocation, maximizing your return on investment.
      </>
    ),
    para4: (
      <>
        <span class='text-white'>Expert Partnership: </span>Leverage Zai Systems' e-commerce experience and expertise to avoid
        costly mistakes and make informed decisions that minimize risk and maximize returns.
      </>
    )
  },
  {
    id: 6,
    title: 'Security & Fraud Prevention ',
    para: ``,
    para1: (
      <>
        <span class='text-white'>Real-Time Threat Detection:</span> Implement robust systems that diligently monitor, analyze, validate,
        and authenticate transactions in real-time to effectively detect, prevent, and mitigate fraudulent activities.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>Data Encryption & Protection:</span> Utilize advanced encryption protocols to secure sensitive customer
        data and ensure compliance with privacy regulations.
      </>
    ),
    para3: (
      <>
        <span class='text-white'>Risk Assessment Tools:</span> Employ AI-driven tools to assess, predict, and quantify the risk levels of
        transactions, customizing proactive security measures based on potential threats.
      </>
    ),
    para4: (
      <>
        <span class='text-white'>Security Compliance Audits:</span> Regularly conduct comprehensive security audits to maintain high
        standards and adapt to the evolving cyber threat landscape.
      </>
    )
  }
]
const SeoDataEcommerce = [
  {
    id: 1,
    title: `E-commerce Services- E-commerce  Solution Providers - Zai Systems`,
    content: `Ready to build and develop your dream e-commerce store? Zai Systems is here to help. Contact us now for you business boost!`,
    link: `https://www.zaisystems.com/services/e-commerce-solution-providers`
  }
]

//bespoke 
const bespoke1 = [
  {
    id: 1,
    title: `Bespoke IT Solutions`,
    para: `In today’s fast-paced digital landscape, businesses require tailored IT solutions to stay competitive, innovative, and future-ready. At Zai Systems, we specialize in delivering bespoke IT solutions designed to meet the unique challenges and goals of your organization. From streamlining workflows to enhancing security and scalability, our services are crafted to empower businesses across industries. With a client-centric approach, cutting-edge 
    technologies, and unparalleled expertise, Zai Systems is your ideal partner for IT excellence.`,
    img: beSpoke,
  }
]
const bespoke4a = [
  {
    id: 1,
    title: `Our Bespoke IT Solutions Services`,
  },

]
const bespoke4 = [
  {
    id: 1,
    title: "Custom Software Development",
    para: `In a world where one-size-fits-all doesn’t cut it, we design and develop custom software solutions tailored to your specific business needs. Our team of experienced developers works closely with you to understand your requirements and build scalable, secure, and user-friendly applications that drive efficiency and innovation.`,
    para1: (
      <>
        <span class='text-white'>• Tailored Applications: </span>Solutions designed specifically for your industry and business model.<br /><span class='text-white'>• Scalability: </span>Software that grows with your business.<br /><span class='text-white'>• Integration: </span>Seamless compatibility with existing systems.
      </>
    ),
    img: contentOne
  }
  ,
  {
    id: 2,
    title: 'Cloud Computing Solutions',
    para: `Leverage the power of the cloud with our comprehensive cloud computing services. We help businesses transition to cloud-based platforms, offering scalable storage, enhanced security, and increased accessibility.`,
    para1: (
      <>
        <span class='text-white'>• Cloud Migration: </span>Smooth transition from on-premise to cloud.<br /> <span class='text-white'>• Cloud Management: </span>Ongoing support and optimization.<br /><span class='text-white'>• Data Security: </span>Advanced measures to keep your data safe.
      </>
    ),
    img: contentTwo
  },
  {
    id: 3,
    title: 'IT Infrastructure Development',
    para: `A robust IT infrastructure is the backbone of any successful business. Zai Systems offers end-to-end infrastructure services, including design, implementation, and management, ensuring your systems are reliable, secure, and optimized for performance.`,
    para1: (
      <>
        <span class='text-white'>• Network Design and Setup: </span>Reliable and secure networking solutions.<br /><span class='text-white'>• Server Management: </span>Ensuring high availability and performance.<br /><span class='text-white'>• Hardware Solutions: </span>Tailored recommendations and installations.
      </>
    ),
    img: contentFour
  },
  {
    id: 4,
    title: 'Cybersecurity Services',
    para: `Protect your business from evolving cyber threats with our top-notch cybersecurity services. We identify vulnerabilities, implement robust security measures, and provide continuous monitoring to safeguard your digital assets.`,
    para1: (
      <>
        <span class='text-white'>• Risk Assessments:  </span>Comprehensive evaluations of potential risks.<br /> <span class='text-white'>• Incident Response:  </span>Rapid action to mitigate breaches.<br /><span class='text-white'>• Compliance: </span>Ensure adherence to industry regulations and standards.

      </>
    ),
    img: contentThree
  },

  {
    id: 5,
    title: 'Managed IT Services',
    para: `Let us handle your IT needs while you focus on your core business. Our managed IT services provide 24/7 monitoring, support, and maintenance to ensure uninterrupted operations.`,
    para1: (
      <>
        <span class='text-white'>• Proactive Monitoring: </span>Identifying and resolving issues before they escalate.<br /><span class='text-white'>• Help Desk Support: </span>Prompt assistance from our skilled technicians.<br /> <span class='text-white'>• Cost Efficiency: </span>Optimized IT spending with predictable costs.
      </>
    ),
    img: contentNine
  },
  {
    id: 6,
    title: 'Product Description Writing',
    para: `Turn data into actionable insights with our business intelligence and analytics services. We help you harness the power of data to make informed decisions, improve operations, and achieve strategic goals.`,
    para1: (
      <>
        <span class='text-white'>• Data Visualization: </span>Interactive dashboards for clear insights.<br /><span class='text-white'>• Predictive Analytics: </span>Anticipate trends and plan ahead.<br /><span class='text-white'>• Customized Reporting: </span>Tailored reports to suit your needs.
      </>
    ),
    img: contentSix
  },
]
const bespoke5a = [
  {
    id: 1,
    title: `Our Comprehensive Bespoke IT Solutions for Your Businesses`,
    para: `We begin by understanding your business inside and out. We listen to your challenges, analyze your workflows,
     and explore your aspirations. Then, we leverage our expertise in leading-edge technologies to design and build
      custom solutions that address your specific needs, streamline your operations, and propel your growth.`
  }

]
const bespoke5 = [
  {
    id: 1,
    title: 'IT Strategy & Consulting',
    para: `Tailored strategic planning and expert advice to align your technology initiatives with business goals,
     ensuring a clear path to digital transformation and operational excellence.`
  },
  {
    id: 2,
    title: 'Custom Software Development',
    para: `Design and development of unique software solutions crafted to meet the specific needs of your business,
     enhancing functionality, user experience, and competitive edge.`
  },
  {
    id: 6,
    title: 'Analytics & Intelligence',
    para: ` Advanced data analysis techniques and business intelligence tools to transform data into actionable insights,
     driving informed decision-making and strategic business growth.`
  },
  {
    id: 3,
    title: 'Cloud Migration & Management',
    para: `Seamless transition of business operations to the cloud, providing scalable, secure, and cost-effective
     management of your IT infrastructure.`
  },
  {
    id: 4,
    title: 'Cybersecurity & Compliance',
    para: `Robust protection against cyber threats, paired with compliance assurance to safeguard your business data
     and meet regulatory standards.`
  },
  {
    id: 5,
    title: 'Managed IT Services',
    para: `Comprehensive IT support and management services, offer monitoring, maintenance, and
     optimization of your IT systems.`
  }

]
const bespoke6a = [
  {
    id: 1,
    title: `Why Choose Zai Systems?`,
    para: `Choosing the right IT partner can make all the difference. While many companies offer similar services, 
    Zai Systems stands out through our unwavering commitment to your success. Here's what sets us apart:`

  }

]
const bespoke6 = [
  {
    id: 1,
    title: 'Unwavering Partnership',
    para: (
      <>
        <span class='text-white'>More than just vendors: </span>We see ourselves as an extension of your team, deeply
        invested in your growth and prosperity. We establish open communication channels and foster transparency,
        ensuring you are always a part of the decision-making process.

      </>
    ),
    para1: (
      <>
        <span class='text-white'>Your success is our success: </span>We don't just meet your expectations; we strive
        to exceed them. We actively seek to understand your business goals, challenges, and unique needs, tailoring
        our solutions to perfectly fit your reality.

      </>
    ),
    para2: (
      <>
        <span class='text-white'>Long-term commitment: </span>We believe in building lasting relationships. We're
        not here for a quick fix; we're here to be your trusted IT partner for the long haul, supporting you through
        every stage of your digital journey.

      </>
    )
  },
  {
    id: 2,
    title: 'Unmatched Expertise',
    para: (
      <>
        <span class='text-white'>Tech wizards at your service: </span>Our team comprises highly skilled and experienced
        professionals with diverse expertise in various technology domains. We stay ahead of the curve with continuous
        learning and upskilling, ensuring access to the latest technologies.

      </>
    ),
    para1: (
      <>
        <span class='text-white'>Problem-solving powerhouses: </span>No challenge is too big or too small for our team.
        We tackle complex technical issues and find creative solutions, ensuring your operations run
        smoothly even in the face of unforeseen obstacles.

      </>
    ),
    para2: (
      <>
        <span class='text-white'>Industry-specific understanding: </span>We delve deep into your specific industry,
        gaining profound insights into challenges and opportunities. This helps us create solutions that
        are both effective and suitable, adding real value to your business.

      </>
    )
  },
  {
    id: 3,
    title: 'Unparalleled Agility',
    para: (
      <>
        <span class='text-white'>Scaling with your success: </span>As your business grows, so should your IT infrastructure.
        We provide scalable solutions that can seamlessly expand and adapt to accommodate your increasing demands, ensuring
        you don't experience technological roadblocks.

      </>
    ),
    para1: (
      <>
        <span class='text-white'>Adapting to your changing needs: </span>The business landscape is dynamic, and your IT
        needs need to be too. We understand that rigidity can stifle growth, so we are incredibly agile in adapting our
        solutions to your evolving requirements.

      </>
    ),

    para2: (
      <>
        <span class='text-white'>Embracing innovation: </span>We're not afraid to explore new technologies and methodologies.
        We actively research and implement cutting-edge solutions that can give you a competitive edge and future-proof your
        business against disruptions.

      </>
    )
  },
  {
    id: 4,
    title: 'Unprecedented ROI',
    para: (
      <>
        <span class='text-white'>Every investment maximized: </span>We understand that your resources are valuable, and we are
        committed to ensuring every tech investment delivers a quantifiable return on investment (ROI). We conduct thorough
        cost-benefit analyses and prioritize solutions that generate tangible returns and contribute directly to your bottom line.

      </>
    ),
    para1: (
      <>
        <span class='text-white'>Cost-effective approach: </span>We offer transparent pricing and flexible engagement models to
        suit your budget and business needs. We help you optimize your IT spending, eliminating unnecessary costs and maximizing
        the value you derive from your technology investments.

      </>
    ),
    para2: (
      <>
        <span class='text-white'>Measurable results: </span>We set clear and realistic benchmarks and track progress against
        defined and relevant metrics,
        ensuring you can see and measure the real impact of our solutions on your business performance.

      </>
    )
  },
  {
    id: 5,
    title: 'Solid Security',
    para: (
      <>
        <span class='text-white'>Compliance guaranteed: </span>We stay abreast of evolving data privacy regulations and compliance
        requirements. We help you navigate the complex legal landscape and ensure your business operates within the established
        framework, mitigating risk and building trust with your customers.

      </>
    ),
    para1: (
      <>
        <span class='text-white'>Data security at the core: </span>We understand that your data is your lifeblood, and we treat
        its security with the utmost vigilance. We implement robust security measures and best-in-class technologies to protect
        your data from unauthorized access, malware, and cyber threats.

      </>
    ),

    para2: (
      <>
        <span class='text-white'>Proactive approach: </span>We don't simply wait for threats to emerge; We proactively find
        and prevent security risks. We take precautions and check security regularly to keep your systems strong and safe.

      </>
    )
  },
  {
    id: 6,
    title: `Innovative Solutions`,
    para: (
      <>
        <span class='text-white'>Cutting-edge Technology: </span>We harness the latest IT advancements to provide cutting-edge,
        innovative solutions that ensure you stay ahead of the competition. Our unwavering commitment to innovation signifies
        our continuous exploration of novel ways to strategically leverage technology for your enhanced benefit
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Customized Strategies:</span> Every business is unique, and so are our tailored solutions. We meticulously craft personalized strategies that align with your specific
        objectives, ensuring our technology solutions integrate seamlessly with your business processes.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>Future-Ready Platforms:</span> We build platforms that not only meet today’s needs but are also designed to accommodate
        future growth and changes in technology, keeping you prepared for whatever comes next.
      </>
    )

  }

]
const SeoDataBespoke = [
  {
    id: 1,
    title: `Bestspoke IT Solutions for Business - Zai Systems`,
    content: `Stop settling for generic IT. Zai Systems builds custom solutions that fit your business like a glove. Get started today!`,
    link: `https://www.zaisystems.com/services/bestspoke-it-solutions-for-business`
  }
]
//data analytics
const dataAnalytics1 = [
  {
    id: 1,
    title: `Data Analytics Services `,
    para: `Welcome to Zai Systems, your trusted partner in leveraging the power of data analytics to transform your business. In today’s data-driven world, businesses that harness the power of analytics outperform their competitors and achieve remarkable success. At Zai Systems, we specialize in providing cutting-edge data analytics services tailored to your unique needs. Our mission is to empower businesses with actionable insights, enabling smarter decisions and driving growth.`,
    img: dataAnaly,
  }
]
const dataAnalytics5a = [
  {
    id: 1,
    title: `Our Comprehensive Data Analytics Services`
  }

]
const dataAnalytics5 = [
  {
    id: 1,
    title: 'Data Analytics & Consulting',
    para: (
      <>
        <span class='text-white'>Dive Deep: </span>Our data exploration goes beyond surface-level insights. We delve
        into the depths of your data, unearthing hidden patterns and correlations that traditional methods miss.

      </>
    ),
    para1: (
      <>
        <span class='text-white'>Customized Roadmap:</span> We don't believe in one-size-fits-all solutions. We work
        closely with you to understand your unique challenges and tailor a data strategy that aligns perfectly with
        your business goals.

      </>
    ),
    para2: (
      <>
        <span class='text-white'>Bridge the Gap: </span>We translate complex data jargon into clear, actionable insights.
        You'll receive not just reports, but practical recommendations and implementation plans to turn data into
        tangible results.

      </>
    )
  },
  {
    id: 2,
    title: 'Business Intelligence & Reporting',
    para: (
      <>
        <span class='text-white'>Consolidate & Conquer: </span>We break down data silos, unifying information from disparate
        sources into a single, cohesive view. No more jumping platforms or inconsistent metrics.

      </>
    ),
    para1: (
      <>
        <span class='text-white'>Real-Time Visibility: </span>Get a live pulse of your business with dynamic dashboards and
        reports that update in real-time. Monitor key performance indicators (KPIs) as they happen, enabling agile
        decision-making.

      </>
    ),
    para2: (
      <>
        <span class='text-white'>Empower All Levels: </span>From executives to frontline employees, everyone
        accesses data they need for informed choices. We create interactive reports that
        engage users and foster a data-driven culture.

      </>
    )

  },
  {
    id: 3,
    title: 'Data Visualization & Storytelling',
    para: (
      <>
        <span class='text-white'>Transform Numbers into Narratives: </span>We animate data, creating visuals that resonate.
        Infographics, charts, and dashboards tell the story, making insights digestible and impactful.

      </>
    ),
    para1: (
      <>
        <span class='text-white'>Engage All Stakeholders: </span>From investors to customers to employees, we tailor data visualizations
        to resonate. Capture attention, inspire action, and drive decisions with clear communication.

      </>
    ),
    para2: (
      <>
        <span class='text-white'>Go Beyond the Surface: </span>We don't simply present data; we explain its meaning and implications.
        Our experts weave data into engaging narratives, ensuring everyone understands the "why" behind the numbers.

      </>
    )
  },
  {
    id: 4,
    title: 'Predictive Machine Learning',
    para: (
      <>
        <span class='text-white'>Peer into the Future: </span>Uncover hidden trends and anticipate future events with
        the power of AI and machine learning.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Unlock Hidden Opportunities: </span>Discover previously unseen patterns and correlations
        that can lead to new product lines, market expansions, and innovative business models.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>Smarter Operations: </span>Automate tasks and optimize workflows using machine
        learning. Improve resource allocation, boost efficiency, and free up your team to focus on strategic initiatives.

      </>
    )
  },
  {
    id: 5,
    title: `Enhanced Customer Profiling `,
    para: (
      <>
        <span class='text-white'>Behavioral Analysis: </span>Track and analyze customer actions to build detailed profiles
        that inform targeted marketing and product development.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Segmentation Models:</span> Use statistical models to segment customers into meaningful groups
        based on their behavior, preferences, and value to your business.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>Personalization Engines:</span> Implement dynamic personalization engines that adapt content,
        recommendations, and offers in real-time to match individual customer profiles.
      </>
    )

  },
  {
    id: 6,
    title: `Efficiency & Automation `,
    para: (
      <>
        <span class='text-white'>Workflow Optimization: </span>Identify bottlenecks and streamline workflows using data-driven
        insights to improve operational efficiency.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Automated Decision-Making:</span> Integrate rule-based systems and machine learning to automate
        routine decisions, reducing manual intervention and speeding up processes.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>Resource Allocation:</span> Utilize predictive models to optimize resource allocation, ensuring
        the right assets are in place to meet demand and maximize productivity.
      </>
    )

  }
]
const dataAnalytics6a = [
  {
    id: 1,
    title: `Diving Deeper into Data Science Tools and Technologies`,
    para: `We understand that the right tools are crucial for unlocking the true value of your data. That's why we 
    leverage a robust technology stack encompassing industry-leading platforms and solutions, tailored to tackle 
    your specific data challenges:`

  }

]
const dataAnalytics6 = [
  {
    id: 1,
    title: 'Big Data Platforms',
    para: (
      <>
        <span class='text-white'>Hadoop: </span>For massive datasets demanding distributed processing power, Hadoop's
        ecosystem provides a reliable and scalable foundation, ensuring your data never becomes a bottleneck.

      </>
    ),
    para1: (
      <>
        <span class='text-white'>Spark: </span>When real-time analytics and fast insights are paramount, Spark
        ignites your data processing with its blazing speed and in-memory computing capabilities.

      </>
    ),
    para2: (
      <>
        <span class='text-white'>Snowflake: </span>Need unparalleled cloud-based agility and elasticity?
        Snowflake's cloud-native data warehouse lets you scale seamlessly, storing and analyzing data without limitations.

      </>
    )
  },
  {
    id: 2,
    title: 'Business Intelligence Tools',
    para: (
      <>
        <span class='text-white'>Tableau: </span>Visualize data like never before with
        Tableau's drag-and-drop interface. Create stunning dashboards and reports
        that bring KPIs and insights to life, facilitating data-driven decision-making
        across all levels.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Power BI: </span>Leverage Microsoft's powerful BI platform to connect with
        Azure infrastructure and access interactive reports, drill-down capabilities, and
        real-time data monitoring.

      </>
    ),
    para2: (
      <>
        <span class='text-white'>Qlik: </span>Qlik's associative engine reveal hidden connections
        and patterns leap off the screen. Quickly discover insights and opportunities in complex datasets.
      </>
    )
  },
  {
    id: 3,
    title: ' Data Visualization Tools',
    para: (
      <>
        <span class='text-white'>D3.js: </span>Craft bespoke interactive visualizations with D3.js.
        It offers unparalleled flexibility and control, allowing you to create stunningly
        unique dashboards and narratives that resonate with your audience.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>R:</span> Use R statistical programming language for advanced data analysis and visualizations.
        Build models, do statistical tests, and create visualizations that inform and impress.

      </>
    ),
    para2: (
      <>
        <span class='text-white'>Python:</span> Python's versatility, with libraries like Matplotlib, Seaborn,
        and Plotly, lets you create clear, informative charts, graphs, and maps that effectively communicate your insights.

      </>
    )
  },
  {
    id: 4,
    title: 'Machine Learning Algorithms',
    para: (
      <>
        <span class='text-white'>TensorFlow: </span>When cutting-edge deep learning solutions are
        required, TensorFlow provides the robust framework to build and train complex AI models,
        unlocking predictive capabilities that push the boundaries of data analysis.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>PyTorch: </span>Leverage the flexibility and dynamic nature of PyTorch for rapid prototyping and
        experimentation with machine learning models. Fine-tune your models with ease and unlock the
        power of AI to optimize processes and anticipate future trends.

      </>
    ),
    para2: (
      <>
        <span class='text-white'>XGBoost: </span>For tackling challenging regression and classification problems, XGBoost's fast and
        accurate gradient boosting algorithms deliver superior performance even with large datasets,
        helping you make the most informed decisions.
      </>
    )
  },
  {
    id: 5,
    title: 'Cloud Computing',
    para: (
      <>
        <span class='text-white'>AWS: </span>Use AWS’s cloud services for unparalleled scalability, security, and flexibility. Host your data
        analysis platforms, dashboards, and AI models with confidence, knowing you have the power
        and reach of AWS at your fingertips.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Azure: </span>Microsoft Azure provides a robust cloud platform tailored for your business needs. Seamlessly
        integrate your data solutions with existing Azure infrastructure and enjoy the benefits of global
        reach, reliability, and advanced analytics tools.

      </>
    ),
    para2: (
      <>
        <span class='text-white'>GCP: </span>Google Cloud Platform offers a unique blend of cutting-edge AI and machine learning capabilities
        with robust data storage and analytics solutions. Empower your data insights with the scalability and
        innovation of GCP.

      </>
    )
  },
  {
    id: 6,
    title: 'Data Integration and Management',
    para: (
      <>
        <span class='text-white'>Informatica:</span> Informatica provides a comprehensive suite of tools for data integration, quality, and governance.
        Its platform ensures that your data is accurate, consistent, and ready to use for analytics and decision-making.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Talend:</span> Talend’s suite of apps offers robust data integration and transformation capabilities. With its open-source roots, Talend makes it easier to connect,
        transform, and manage data across various systems, ensuring smooth data flows for analytics.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>Apache NiFi:</span> Apache NiFi supports scalable data routing, transformation, and system mediation logic. It’s designed for data-in-motion and can handle data of
        any size and format, making it ideal for real-time data streaming.
      </>
    )
  }

]
const dataAnalytics13a = [
  {
    id: 1,
    title: `How We Rise Above the Data Analytics Crowd?`,
    para: `We know your business is unique, and so are your data challenges. That's why we stand out from the crowd with
     a differentiated approach, ensuring your journey from data chaos to clarity is tailored for maximum impact:`

  }

]
const dataAnalytics13 = [
  {
    id: 1,
    title: 'A Customer-Centric Compass',
    para: `Forget cookie-cutter solutions. We embark on a deep dive, immersing ourselves in your specific goals, obstacles,
     and industry nuances. Your challenges become our mission. We listen, collaborate, and craft personalized data strategies
      that align seamlessly with your unique vision.`

  },

  {
    id: 3,
    title: `Agile Collaboration, Hand in Hand`,
    para: `We believe in transparency and partnership. Our team doesn't operate in a silo. We work closely with yours, 
    sharing progress, addressing concerns, and integrating data solutions into your workflow. You're 
    not just clients; you're collaborators, and your success is our victory.`
  },
  {
    id: 4,
    title: `A Team of Data-Driven Champions`,
    para: `Behind every innovative solution is a team of experts. Our data scientists, analysts, and engineers possess
     a wealth of experience across diverse industries. We bring real-world knowledge, proven methodologies, and cutting-edge
      skills to tackle data challenges head-on.`
  },
  {
    id: 2,
    title: `Actionable Insights, Not Just Pretty Numbers`,
    para: `Data analysis is just the beginning. We are passionate about action. We translate insights into concrete recommendations, 
    implementation plans, and actionable steps. With Zai Systems, data isn't just a story; it's your roadmap to success.`
  },
  {
    id: 5,
    title: `A Data-Driven Mindset, Not Just a Skillset`,
    para: ` We don’t just crunch numbers; we solve problems. We use data as a
     powerful tool to understand your business, your customers, and your market. We apply analytical thinking, creativity,
      and curiosity to 
    uncover hidden opportunities.`
  },
  {
    id: 6,
    title: `A Holistic Approach, Not Just a Project`,
    para: ` We don’t just deliver data solutions; we create data ecosystems. We integrate 
    data from multiple sources, platforms, and systems to provide a comprehensive view of your business. We ensure 
    data quality, security, and governance throughout the data lifecycle.`
  }
]
const SeoDatadataAnalytics = [
  {
    id: 1,
    title: `Data Analytics Consulting Services - Zai Systems`,
    content: `Make data-driven decisions with confidence. Zai Systems' Data Analytics Consulting Services help you analyze, predict, and optimize, leading to increased efficiency, revenue, and customer satisfaction.`,
    link: `https://www.zaisystems.com/services/data-analytics`
  }
]
//business intelligence
const business1 = [
  {
    id: 1,
    title: `Business Intelligence Solutions`,
    para: `Extracting valuable information from vast amounts of data can be overwhelming, but
     Zai Systems is here to help. We are a leading provider of Business Intelligence (BI) solutions,
      empowering organizations in Pakistan and USA to transform data into actionable intelligence.`,
    para1: `We bridge the gap between information and impact, empowering businesses to make informed
     decisions, optimize operations, and unlock profitable growth.`,
    img: businnessIntelli,
  }
]
const business5a = [
  {
    id: 1,
    title: `Dive Deeper into Zai Systems' Comprehensive BI Services`,
    para: `We are a team of passionate BI experts dedicated to empowering businesses like yours to transform raw data into
     actionable insights, optimize operations, and gain a competitive edge.`
  }

]
const business5 = [
  {
    id: 1,
    title: `Data Warehousing and Modeling`,
    para: `Transform data silos into a unified wellspring of knowledge. That's the
     power of our data warehousing and modeling expertise. We:
    `,
    para1: (
      <>
        <span class='text-white'>Conduct in-depth data assessment: </span>We map your information landscape,
        identifying diverse data sources and flow.

      </>
    ),
    para2: (
      <>
        <span class='text-white'>Design a robust data warehouse: </span>We architect a secure, scalable data
        repository that caters to your specific needs and future growth.

      </>
    ),
    para3: (
      <>
        <span class='text-white'>Extract, Transform, and Load (ETL): </span>We integrate data from various
        sources, ensuring accuracy and consistency throughout the warehouse.

      </>
    ),
    para4: (
      <>
        <span class='text-white'>Model for actionable insights: </span>We create multi-dimensional data models that
        facilitate intuitive analysis and uncover hidden relationships.

      </>
    ),
    para5: (
      <>
        <span class='text-white'>Standardize and govern data: </span>We establish data governance practices, ensuring quality,
        security, and accessibility.

      </>
    )
  },
  {
    id: 2,
    title: `Data Visualization and Reporting`,
    para: `
    Raw data alone can be overwhelming. We make it sing with captivating visuals and user-friendly reports:
    `,
    para1: (
      <>
        <span class='text-white'>Interactive dashboards: </span>We design intuitive dashboards that tell a story
        with engaging charts, graphs, and KPIs.

      </>
    ),
    para2: (
      <>
        <span class='text-white'>Drill-down capabilities: </span>Dive deeper into data with interactive features,
        uncovering underlying trends and granular details.

      </>
    ),
    para3: (
      <>
        <span class='text-white'>Tailored reports: </span>We create custom reports aligned with your specific needs
        and decision-making processes.

      </>
    ),
    para4: (
      <>
        <span class='text-white'>Real-time data monitoring: </span>Stay ahead of the curve with real-time dashboards
        that keep you informed on critical metrics.

      </>
    ),
    para5: (
      <>
        <span class='text-white'>Mobile access: </span>Access insights from anywhere, anytime, on any device, fostering
        agility and informed decision-making.

      </>
    )

  },
  {
    id: 3,
    title: `Data Analysis and Modeling`,
    para: `Our team of data scientists are detectives of the digital world, unearthing valuable patterns and 
    relationships within your data:`,
    para1: (
      <>
        <span class='text-white'>Advanced data mining techniques: </span>We leverage statistical models and
        machine learning algorithms to uncover hidden trends.

      </>
    ),
    para2: (
      <>
        <span class='text-white'>Statistical analysis: </span>We analyze complex data sets to identify correlations,
        assess risks, and optimize business processes.

      </>
    ),
    para3: (
      <>
        <span class='text-white'>Scenario modeling: </span>We build predictive models to test different strategies
        and make informed decisions based on potential outcomes.

      </>
    ),
    para4: (
      <>
        <span class='text-white'>Prescriptive analytics: </span>We go beyond insights, delivering actionable
        recommendations to maximize efficiency and performance.

      </>
    ),
    para5: (
      <>
        <span class='text-white'>Continuous improvement: </span>We monitor and refine models over time, ensuring
        they remain relevant and valuable as your business evolves.

      </>
    )
  },
  {
    id: 4,
    title: `Business Performance Management`,
    para: `Stay in the driver's seat with our comprehensive BPM solutions:`,
    para1: (
      <>
        <span class='text-white'>Define key performance indicators (KPIs): </span>We collaborate with you to
        identify the metrics that matter most to your business.

      </>
    ),
    para2: (
      <>
        <span class='text-white'>Monitor performance with real-time dashboards: </span> Track progress towards
        your goals and identify areas for improvement in real-time.

      </>
    ),
    para3: (
      <>
        <span class='text-white'>Alert and notification systems: </span>Stay informed about critical performance
        deviations and take proactive action before issues escalate.

      </>
    ),
    para4: (
      <>
        <span class='text-white'>Budgeting and forecasting: </span>Develop accurate budgets and forecasts based
        on data-driven insights.

      </>
    ),
    para5: (
      <>
        <span class='text-white'>Strategic planning and execution: </span>Align your operations with your strategic
        goals, ensuring effective and efficient execution.

      </>
    )
  },
  {
    id: 5,
    title: `Custom BI Solutions`,
    para: `No two businesses are alike. That’s we believe customized BI solutions fit needs challenges:`,
    para1: (
      <>
        <span class='text-white'>We listen to you:</span> We take the time to understand your unique business
        environment, challenges, and objectives.

      </>
    ),
    para2: (
      <>
        <span class='text-white'>We collaborate with you: </span>We work hand-in-hand with your team to tailor a
        solution that aligns with your existing infrastructure and processes.

      </>
    ),
    para3: (
      <>
        <span class='text-white'>We integrate seamlessly: </span>We integrate our BI solutions with your existing
        systems and tools, ensuring a smooth and efficient workflow.

      </>
    ),
    para4: (
      <>
        <span class='text-white'>We adapt and evolve: </span>We stay agile and responsive, adapting our solutions
        to your evolving needs and technological advancements.

      </>
    )
  }
]
const business6a = [
  {
    id: 1,
    title: `A Deep Dive into Our Business Intelliegence Solutions Benefits`,
    para: `
    We work closely with you, fostering long-term relationships built on trust and transparency.
    `

  }

]
const business6 = [
  {
    id: 1,
    title: `Actionable Insights at Your Fingertips`,
    para: `Say goodbye to data paralysis and hello to clear, actionable intelligence that drives informed
     decisions across every level of your organization. Zai Systems transforms your data into:
    `,
    para1: (
      <>
        <span class='text-white'>Intuitive dashboards and reports: </span>Identify trends, track performance,
        and uncover hidden patterns at a glance. No more deciphering complex spreadsheets!

      </>
    ),
    para2: (
      <>
        <span class='text-white'>Predictive insights: </span>Anticipate future outcomes and make informed
        strategic decisions based on data-driven forecasts. Stay ahead of the curve, not behind it.

      </>
    ),
    para3: (
      <>
        <span class='text-white'>Data-backed recommendations: </span>Our analytics provide
        actionable suggestions for optimizing processes, maximizing efficiency, & boosting ROI.

      </>
    ),
    para4: (
      <>
        <span class='text-white'>Democratization of data: </span>Empower all departments to access and
        analyze data relevant to their roles, fostering a data-driven culture and collective decision-making.

      </>
    )
  },
  {
    id: 2,
    title: `Operational Efficiency Transformed`,
    para: `Boost productivity, eliminate waste, and unlock significant cost savings with Zai Systems' expertise
     in optimizing your operations. We help you:`,
    para1: (
      <>
        <span class='text-white'>Identify bottlenecks and inefficiencies: </span>Our data analysis pinpoints
        areas for improvement, revealing hidden inefficiencies and cost drivers.

      </>
    ),
    para2: (
      <>
        <span class='text-white'>Streamline processes: </span>We identify redundant tasks and optimize workflows,
        reducing operational friction and time wasted on manual processes.

      </>
    ),
    para3: (
      <>
        <span class='text-white'>Resource allocation optimization: </span>Allocate resources strategically based
        on data-driven insights, ensuring maximum utilization and ROI.

      </>
    ),
    para4: (
      <>
        <span class='text-white'>Predictive maintenance: </span>Prevent unexpected disruptions with data-driven
        maintenance schedules, minimizing downtime and associated costs.

      </>
    ),
    para5: (
      <>
        <span class='text-white'>Continuous improvement: </span>We provide ongoing monitoring and analysis,
        allowing you to continuously refine your operations for peak efficiency.

      </>
    )

  },
  {
    id: 3,
    title: `Dominate the Competition with a Deeper Understanding`,
    para: `Gain an unparalleled advantage in your market with Zai Systems' insights into your customers 
    and competitive landscape. We help you:`,
    para1: (
      <>
        <span class='text-white'>Understand your customers: </span>Uncover hidden customer patterns,
        preferences, and buying behaviors, enabling you to personalize your offerings and deliver
        exceptional experiences.

      </>
    ),
    para2: (
      <>
        <span class='text-white'>Identify market trends: </span>Anticipate upcoming trends and shifts
        in your industry, allowing you to adapt your strategies and stay ahead of the competition.

      </>
    ),
    para3: (
      <>
        <span class='text-white'>Benchmark against competitors: </span>Analyze your performance against
        industry leaders, pinpoint areas for improvement, and develop strategies.

      </>
    ),
    para4: (
      <>
        <span class='text-white'>Optimize pricing and marketing strategies: </span> Price your products
        competitively and target your marketing efforts effectively based on data-driven insights.

      </>
    ),
    para5: (
      <>
        <span class='text-white'>Develop winning value propositions: </span> Differentiate yourself
        from the competition by creating unique value propositions informed by customer needs and market trends.

      </>
    )
  },
  {
    id: 4,
    title: `Agility and Responsiveness in a Dynamic World`,
    para: `
    Make lightning-fast, data-driven decisions in real-time with Zai Systems' agile BI solutions. We help you:
    `,
    para1: (
      <>
        <span class='text-white'>Monitor key performance indicators (KPIs) in real-time: </span>React instantly
        to changing market conditions and opportunities with up-to-date data at your fingertips.

      </>
    ),
    para2: (
      <>
        <span class='text-white'>Adapt your strategies on the fly: </span>Analyze data in real-time to adapt
        your pricing, marketing, and operational strategies based on immediate feedback and market shifts.

      </>
    ),
    para3: (
      <>
        <span class='text-white'>Seize fleeting opportunities: </span>Identify and capitalize on new opportunities
        before your competitors even notice, thanks to real-time data insights.

      </>
    ),
    para4: (
      <>
        <span class='text-white'>Mitigate risks proactively: </span>Detect and address potential issues before
        they escalate, safeguarding your business from unexpected disruptions.

      </>
    ),
    para5: (
      <>
        <span class='text-white'>Maintain a competitive edge: </span>Remain agile and responsive in a dynamic market,
        ensuring you can outpace your competitors who are bogged down by slow data processes.

      </>
    )
  }
]
const business13a = [
  {
    id: 1,
    title: `Zai Systems: A Powerhouse of BI Tools and Technologies`,
    para: `We believe the right tools are essential for unlocking the full potential of your data.
     That’s why we leverage a diverse and ever-evolving arsenal of industry-leading BI solutions, 
     customized to empower your business with precise insights and actionable intelligence.`

  }

]
const business13 = [
  {
    id: 1,
    title: `Data Warehousing`,
    para: (
      <>
        <span class='text-white'>Microsoft Azure Synapse Analytics: </span>
        This cloud-based data warehouse offers unparalleled scalability, performance, and flexibility.
        We utilize its advanced features to integrate disparate data sources, handle massive datasets,
        and deliver lightning-fast queries for real-time analysis.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Amazon Redshift: </span>Another cloud-based powerhouse, Redshift
        delivers exceptional performance and cost-efficiency for large-scale data warehousing. Its
        columnar storage and parallel processing capabilities make it ideal for complex queries
        and rapid analytical workloads.

      </>
    )

  },
  {
    id: 2,
    title: `Analytics & Visualization`,
    para: (
      <>
        <span class='text-white'>Power BI: </span>
        This Microsoft powerhouse combines intuitive data exploration with stunning visuals. We
        use Power BI to create interactive dashboards, reports, and ad-hoc analyses, empowering you
        to explore your data with ease and unlock hidden insights.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Tableau: </span>Renowned for its user-friendly interface and captivating
        visualizations, Tableau allows us to craft compelling stories from your data. We leverage its
        drag-and-drop functionality and rich visual palette to communicate complex trends and metrics
        in a clear and engaging way.

      </>
    ),
    para2: (
      <>
        <span class='text-white'>Qlik: </span>Offering exceptional agility and associative search capabilities,
        Qlik helps us uncover unexpected connections and hidden patterns within your data. We utilize its unique
        approach to navigate through vast datasets and identify previously unseen relationships, fostering deeper
        understanding and informed decision-making.

      </>
    )
  },
  {
    id: 3,
    title: `Data Integration`,
    para: (
      <>
        <span class='text-white'>Informatica PowerCenter:</span>
        This robust ETL (Extract, Transform, Load) tool integrates
        data from diverse sources, ensuring consistency and accuracy. We use PowerCenter's powerful
        transformations and data quality capabilities to prepare your data for analysis, and insights.


      </>
    ),
    para1: (
      <>
        <span class='text-white'>IBM DataStage: </span>Offering an equally potent blend of data extraction,
        transformation, and loading capabilities, DataStage provides a flexible and scalable solution
        for complex data pipelines. We automate data movement and
        ensure the integrity of your information throughout the journey.

      </>
    )
  },
  {
    id: 4,
    title: `Reporting & Dashboards`
    ,
    para: (
      <>
        <span class='text-white'>Tableau Server: </span>Offering similar functionality, Tableau Server empowers
        us to share interactive dashboards and reports with ease. Its robust security features and user management
        capabilities allow you to control access and ensure data governance.

      </>
    ),
    para1: (
      <>
        <span class='text-white'>Microsoft Power BI Report Server: </span>
        This server platform securely publishes and distributes
        Power BI reports and dashboards across your organization. We utilize its centralized platform
        to ensure everyone has access to the latest insights, driving data-driven decision-making at all levels.


      </>
    )
  },
  {
    id: 5,
    title: `Data Governance & Security`,
    para: (
      <>
        <span class='text-white'>IBM Guardium: </span>
        This comprehensive data security solution safeguards your valuable information with real-time
        activity monitoring, data masking, and access controls. We use Guardium to ensure compliance with
        regulations and protect your data from unauthorized access or misuse.


      </>
    ),
    para1: (
      <>
        <span class='text-white'>Cloudera Sentry: </span>Offering a robust authorization framework for Hadoop and
        related ecosystems, Sentry allows us to define fine-grained access controls for your data. We leverage its
        centralized management system to secure your sensitive information and empower responsible data governance.

      </>
    )
  }

]
const SeoDatabusiness = [
  {
    id: 1,
    title: `Business Intelligence Services - Zai Systems`,
    content: `Business Intelligence Services by Business Intelligence Services`,
    link: `https://www.zaisystems.com/services/business-intelligence`
  }
]
//information strategy
const info1 = [
  {
    id: 1,
    title: `Information Strategy Services`,
    para: `Information is power. But harnessing that power requires the right strategy. Zai Systems steps in as your trusted partner, transforming raw data into actionable insights that fuel intelligent decision-making. We don't just crunch numbers; we craft customized 
    information strategy services that empower you to navigate the ever-evolving landscape of your industry.`,
    img: infostrate,
  }
]
const info4a = [
  {
    id: 1,
    title: `Our Information Strategy Services`,
    para: `Zai Systems offers a broad spectrum of Information Strategy Services to ensure your business leverages its data to the fullest potential. `
  },

]
const info4 = [
  {
    id: 1,
    title: "Data Management and Governance",
    para: `Effective data management forms the backbone of any successful information strategy. We help you establish robust frameworks for data collection, storage, and governance, ensuring that your data is accurate, secure, and accessible. `,
    para1: (
      <>
        <span class='text-white'>• Data quality assessment and enhancement </span><br />•	Data lifecycle management<br />•	Implementation of data privacy and compliance protocols (GDPR, CCPA, etc.) <br />•	Creation of data governance policies
      </>
    ),
    img: contentOne
  }
  ,
  {
    id: 2,
    title: 'Business Intelligence and Analytics',
    para: `Unlock the power of your data with our business intelligence and analytics services. Zai Systems specializes in developing data visualization dashboards, predictive analytics models, and reporting tools that deliver actionable insights.`,
    para1: (
      <>
        <span class='text-white'>•	Custom analytics dashboards<br />•	Advanced data visualization <br />•	Predictive and prescriptive analytics<br />•	Real-time reporting solutions
        </span>
      </>
    ),
    img: contentTwo
  },
  {
    id: 3,
    title: 'Cloud Data Strategy',
    para: `Moving your data to the cloud? Our cloud data strategy services are designed to help you navigate the complexities of cloud adoption while maximizing the scalability, security, and cost-effectiveness of your solutions. `,
    para1: (
      <>
        <span class='text-white'>•	Cloud migration planning and execution<br />•	Multi-cloud and hybrid cloud strategies<br />•	Cloud data storage optimization<br />•	Security and compliance management
        </span>
      </>
    ),
    img: contentFour
  },
  {
    id: 4,
    title: 'Artificial Intelligence and Machine Learning Integration',
    para: `Artificial Intelligence (AI) and Machine Learning (ML) are revolutionizing how businesses operate. Zai Systems helps you integrate AI and ML into your information strategy to automate processes, predict trends, and enhance decision-making. `,
    para1: (
      <>
        <span class='text-white'>•	Algorithm development and deployment<br />•	Natural language processing (NLP) solutions<br />•	Customer behavior analysis<br />•	AI-driven automation
        </span>
      </>
    ),
    img: contentThree
  },

  {
    id: 5,
    title: 'Strategic Data Consulting',
    para: `Our team of expert consultants works closely with your organization to develop a customized information strategy aligned with your business goals. From identifying pain points to implementing solutions, our consulting services are here.`,
    para1: (
      <>
        <span class='text-white'>•	Strategic roadmap development<br />•	Competitive benchmarking<br />•	Risk assessment and mitigation<br />•	KPI definition and performance tracking
        </span>
      </>
    ),
    img: contentNine
  },
  {
    id: 6,
    title: 'Big Data Solutions',
    para: `For businesses dealing with massive volumes of data, we provide big data solutions that ensure seamless processing, storage, and analysis.`,
    para1: (
      <>
        <span class='text-white'>•	Big data architecture design<br />•	Hadoop and Spark implementation<br />•	Data lake creation<br />•	Advanced analytics on big data
        </span>
      </>
    ),
    img: contentSix
  },
]
const info5a = [
  {
    id: 1,
    title: `Dive Deeper into Zai Systems' Information Strategy Services`,
    para: `We don't just deliver data; we partner with you to understand your unique needs 
    and translate data into actionable strategies.`
  }

]
const info5 = [
  {
    id: 1,
    title: `Data Management`,
    para: (
      <>
        <span class='text-white'>Data Integration: </span>We break down data silos, stitching together sources
        from across your organization into a unified, accessible whole. Picture fragmented pieces of a mosaic
        seamlessly glued together to reveal a stunning masterpiece.

      </>
    ),
    para1: (
      <>
        <span class='text-white'>Data Organization: </span>We categorize, classify, and standardize your
        data for consistent usage and efficient analysis. Think file systems that sing, databases that dance,
        and spreadsheets that do acrobatics.

      </>
    ),

    para2: (
      <>
        <span class='text-white'>Data Quality: </span>We scrub your data clean, eliminating errors and
        inconsistencies to ensure reliable and trustworthy insights. Imagine polishing a cloudy gem to
        reveal its sparkling brilliance.

      </>
    ),
    para3: (
      <>
        <span class='text-white'>Data Governance: </span>We establish clear policies and procedures for data
        access, usage, and security, keeping your information safe. Think of it as erecting a
        sturdy data fortress.
      </>
    )
  },
  {
    id: 2,
    title: `Business Intelligence`,
    para: (
      <>
        <span class='text-white'>Data Exploration: </span>We delve into your data, uncovering hidden patterns,
        trends, and correlations that inform strategic decisions. Picture Indiana Jones navigating a data temple,
        unearthing valuable treasures of knowledge.

      </>
    ),
    para1: (
      <>
        <span class='text-white'>Performance Measurement: </span>We track key performance indicators (KPIs) and
        create custom dashboards to monitor your progress and identify areas for improvement. Imagine a real-time
        map, guiding you towards your business goals.

      </>
    ),
    para2: (
      <>
        <span class='text-white'>Scenario Modeling: </span>We simulate different business scenarios based on your data,
        allowing you to test assumptions and make informed choices. Think of it as a crystal ball for your business,
        showing potential futures.

      </>
    ),
    para3: (
      <>
        <span class='text-white'>Actionable Insights: </span>We turn data into clear recommendations,
        empowering decisive action from newfound knowledge like an alchemist turning
        insights into opportunities.
      </>
    )

  },
  {
    id: 3,
    title: `Data Visualization`,
    para: (
      <>
        <span class='text-white'>Charting Magic: </span>We transform dry data into captivating charts, graphs, and
        infographics that tell a compelling story and captivate your audience. Imagine your data dancing in a visual
        ballet, mesmerizing viewers with its beauty and clarity.

      </>
    ),
    para1: (
      <>
        <span class='text-white'>Interactive Dashboards: </span>We craft interactive dashboards with real-time
        insights, allowing you to drill down into details and explore data from multiple angles.
        Picture a data cockpit, giving you complete control over your information journey.

      </>
    ),
    para2: (
      <>
        <span class='text-white'>Storytelling with Data: </span>We don't just show you the numbers; we weave them into a
        clear and concise narrative that resonates with your audience and drives action. Imagine data as
        a captivating epic, guiding business decisions with purpose.

      </>
    ),
    para3: (
      <>
        <span class='text-white'>Accessibility for All: </span>We design data visualizations that are accessible to everyone,
        regardless of technical expertise, ensuring everyone can glean insights from your information.

      </>

    )
  }
]
const info6a = [
  {
    id: 1,
    title: `Reap the Benefits of Zai Systems' Info Strategy`,
    para: `We have a successful history of helping businesses achieve their goals through intelligent information strategies.`

  }

]
const info6 = [
  {
    id: 1,
    title: `Improved Decision-Making`,
    para: (
      <>
        <span class='text-white'>Confidently navigate uncertainty:</span>
        Say goodbye to gut feelings and hello to data-driven decisions. Our insights illuminate the path
        forward, allowing you to confidently allocate resources, launch new initiatives, and adapt to market shifts.


      </>
    ),
    para1: (
      <>
        <span class='text-white'>Turn data into dollars: </span>Our actionable recommendations go beyond the
        "what" to the "how." We translate complex data into concrete steps you can take to unlock new revenue
        streams, optimize pricing strategies, and maximize profitability.



      </>
    ),
    para2: (
      <>
        <span class='text-white'>Ditch the guesswork, embrace foresight: </span>No more crystal balls needed.
        Our predictive analytics anticipate future trends and potential roadblocks, empowering you to stay
        ahead of the curve and make proactive decisions that secure your success.

      </>
    )
  },
  {
    id: 2,
    title: `Increased Efficiency`,
    para: (
      <>
        <span class='text-white'>Boost your operational engine: </span>Identify hidden inefficiencies in your
        workflow and eliminate bottlenecks with laser precision. Our data analysis pinpoints areas for streamlining
        processes, reducing waste, and optimizing resource allocation.

      </>
    ),
    para1: (
      <>
        <span class='text-white'>Empower your team with data-driven insights:</span> Equip your employees with
        actionable dashboards and reports that keep them informed and on track. Data transparency fosters accountability,
        drives collaboration, and fuels higher productivity.

      </>
    ),
    para2: (
      <>
        <span class='text-white'>Turn data into a performance catalyst: </span> Transform every department from cost
        center to profit center. Our data-driven insights empower marketing teams to target the right audience, sales
        teams to close more deals, and operations teams to deliver on time.

      </>
    )

  },
  {
    id: 3,
    title: `Enhanced Competitive Advantage`,
    para: (
      <>
        <span class='text-white'>Unmask your competitors' strategies: </span>Gain a deep understanding of your market
        landscape, from competitor strengths and weaknesses to emerging trends and disruptive technologies.
        Partner with us, and you’re always one strategic step ahead of the game.

      </>
    ),
    para1: (
      <>
        <span class='text-white'>Differentiate yourself in the crowded marketplace: </span>Our data-driven insights
        identify unique selling propositions, refine your brand messaging, and tailor your offerings to meet the specific
        needs of your target audience. Stand out from the crowd and attract loyal customers.

      </>
    ),
    para2: (
      <>
        <span class='text-white'>Predict and counter their next move: </span>Stay proactive with our industry-specific
        competitive intelligence. We anticipate your competitors' actions and equip you with the knowledge and agility to
        outmaneuver them and claim market share.

      </>
    )
  },
  {
    id: 4,
    title: `Reduced Risk`,
    para: (
      <>
        <span class='text-white'>Mitigate potential threats before they materialize: </span> Our risk assessments and
        predictive analytics identify potential problems before they erupt into crisis. Be prepared for market downturns,
        supply chain disruptions, and operational failures with proactive risk mitigation strategies.

      </>
    ),
    para1: (
      <>
        <span class='text-white'>Make informed decisions in uncertain times: </span> Navigate volatility with confidence.
        Our data-driven insights offer clarity in turbulent markets, allowing you to make decisions that protect
        your financial health and future.

      </>
    ),
    para2: (
      <>
        <span class='text-white'>Turn risk into opportunity: </span>We help you identify hidden opportunities within
        potential threats. Turn market shifts into competitive advantages, adapt to regulatory changes with ease, and
        emerge stronger from unexpected challenges.

      </>
    )
  },
  {
    id: 5,
    title: `Improved ROI`,
    para: (
      <>
        <span class='text-white'>Scale your business with data-driven growth: </span> Turn your information into a
        strategic asset. Our insights fuel expansion into new markets, product development, and efficient resource
        allocation, propelling your business towards sustainable growth.

      </>
    ),
    para1: (
      <>
        <span class='text-white'>Measure the impact of every decision: </span>Our data-driven reporting tracks the
        impact of your initiatives, allowing you to quantify the benefits and optimize your investments for even greater ROI.

      </>
    ),
    para2: (
      <>
        <span class='text-white'>Maximize your data investment: </span>Don't let your data infrastructure gather dust.
        We help you extract maximum value from every byte, ensuring your investment in data tools and technology yields
        tangible returns.

      </>
    )
  },
  {
    id: 6,
    title: `Streamlined Collaboration`,
    para: (
      <>
        <span class='text-white'>Synchronized Workflows: </span>By integrating advanced collaboration tools, we ensure that your teams can work
        together seamlessly, regardless of location. This synchronization leads to a more cohesive, streamlined,
        and efficient work environment, fostering innovative solutions.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Real-Time Communication:</span> Our systems facilitate instant communication, allowing for real-time updates
        and discussions. This ensures that everyone is on the same page and can make informed decisions quickly.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>Shared Knowledge Base:</span> We create a centralized repository of information that is accessible to
        all team members. This shared knowledge base encourages collective problem-solving and innovation.
      </>
    )

  }
]
const info13a = [
  {
    id: 1,
    title: `Zai Systems: Your Tech Toolbox for Data Domination`,
    para: `We adapt to your evolving needs and embrace new technologies to ensure your information strategy remains future-proof. `

  }

]
const info13 = [
  {
    id: 1,
    title: `Big Data Platforms`,
    para: (
      <>
        <span class='text-white'>Taming the Data Giants: </span>We utilize industry-leading platforms like Hadoop,
        Spark, and Snowflake to effortlessly manage and analyze even the most colossal datasets. Imagine petabytes
        of information dancing on our fingertips, ready to reveal their secrets.

      </>
    ),
    para1: (
      <>
        <span class='text-white'>Scalability Unleashed: </span>As your data grows, so does our capacity. Our agile
        infrastructure adapts seamlessly, ensuring you're never burdened by information overload. Think of it as an
        ever-expanding data warehouse, always ready to accommodate your evolving needs.

      </>
    ),
    para2: (
      <>
        <span class='text-white'>Distributed Processing Power: </span>We harness the power of distributed computing
        to crunch through your data with lightning speed. No more waiting for sluggish analyses; get real-time insights
        that inform immediate action. Picture a team of data analysts working in perfect harmony, extracting valuable
        nuggets from your information mountain.

      </>
    )
  },
  {
    id: 2,
    title: `Business Intelligence Tools`,
    para: (
      <>
        <span class='text-white'>Demystifying Data: </span>We leverage BI tools like Power BI, Tableau, and QlikView
        to transform raw data into clear, actionable dashboards and reports. Think of it as a data translator, turning
        complex numbers into user-friendly visuals that everyone can understand.

      </>
    ),
    para1: (
      <>
        <span class='text-white'>Self-Service Insights: </span>Empower your teams with interactive dashboards and
        self-service analytics. No more dependence on data experts; everyone can explore, analyze, and uncover insights.
        Imagine a data playground where your organization discovers hidden gems.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>Mobile Access, Untethered Intelligence: </span>Get real-time insights anytime, anywhere
        with our mobile-friendly dashboards. Stay informed, make data-driven decisions, and adapt on the fly, regardless
        of your location. Picture your data insights dancing on your smartphone screen, guiding you to success even while on the go.

      </>
    )

  },
  {
    id: 3,
    title: `Data Visualization Tools`,
    para: (
      <>
        <span class='text-white'>Storytelling with Data: </span>We weave your data into captivating charts, graphs,
        and infographics that tell a compelling story and engage your audience. Forget boring spreadsheets; our visuals
        turn data into mesmerizing art, grabbing attention and driving impactful action.

      </>
    ),
    para1: (
      <>
        <span class='text-white'>Interactive Exploration: </span>Dive deeper into your data with interactive visualizations.
        Zoom in, drill down, and explore hidden correlations with just a few clicks. Think of it as a data detective kit,
        empowering you to uncover the truth behind the numbers.

      </>
    ),
    para2: (
      <>
        <span class='text-white'>Accessibility for All: </span>We design data visualizations that are clear, concise, and
        accessible to everyone, regardless of technical expertise. Everyone in your organization, can glean valuable insights
        from your data. Picture a data bridge connecting all levels with understanding and shared knowledge.

      </>
    )
  },
  {
    id: 4,
    title: `Machine Learning and AI`,
    para: (
      <>
        <span class='text-white'>Automating the Mundane: </span>Let AI handle the heavy lifting. We automate repetitive
        tasks like data cleansing and analysis, freeing your team to focus on strategic initiatives.

      </>
    ),
    para1: (
      <>
        <span class='text-white'>Deeper Insights, Unexpected Discoveries: </span>AI elevates data analysis. We uncover
        hidden patterns, predict future trends, and extract insights that human minds might miss.

      </>
    ),
    para2: (
      <>
        <span class='text-white'>Predictive Powerhouse: </span>Our AI models anticipate market shifts, customer behavior,
        and potential risks with uncanny accuracy. Be prepared with foresight that puts you ahead of the game.


      </>
    )
  },
  {
    id: 5,
    title: `Optimized Resource Management`,
    para: (
      <>
        <span class='text-white'>Maximized Resource Utilization: </span>We implement strategies that ensure every bit of data and
        processing time is used to its fullest potential, eliminating waste and boosting efficiency.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Intelligent Resource Allocation:</span> Our systems dynamically allocate
        resources where they’re needed most, ensuring optimal performance across all your operations.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>Cost-Effective Resource Planning:</span> We provide insights that help you plan your
        resource usage effectively, reducing costs and increasing your return on investment.
      </>
    )

  },
  {
    id: 6,
    title: `Seamless Integration`,
    para: (
      <>
        <span class='text-white'>Plug-and-Play Compatibility: </span>Our solutions
        are designed to integrate smoothly with your existing systems, minimizing disruption and maximizing synergy.
      </>
    ),
    para1: (
      <>
        <span class='text-white'>Unified Data Ecosystem:</span> We create a cohesive environment where data from
        various sources is harmonized, providing a single source of truth for your organization.
      </>
    ),
    para2: (
      <>
        <span class='text-white'>Streamlined Data Migration:</span> Transitioning to our systems is a breeze, thanks
        to our streamlined data migration processes that ensure data integrity and continuity.
      </>
    )

  }
]
const SeoDatainfo = [
  {
    id: 1,
    title: `Information Technology Strategy - IT Strategy Services - Zai Systems`,
    content: `Boost efficiency, innovate faster, & gain a competitive edge. Zai Systems' IT strategy services will boost your business potential.`,
    link: `https://www.zaisystems.com/services/information-strategy`
  }
]

const MobileServiceFAQs = [
  { questions: 'What types of mobile apps do you develop at Zai Systems?', answers: 'We specialize in developing various types of mobile apps, including Android and iOS apps, cross-platform apps using Flutter and React Native, hybrid apps, custom mobile apps tailored to specific business needs, and enterprise mobile apps for streamlining operations.' },
  { questions: 'What technologies do you use for mobile app development?', answers: 'We excel in several technologies including Python, Angular, Flutter, React Native, Swift, and Objective-C. This diverse expertise allows us to create robust, high-performance apps across different platforms.' },
  { questions: 'Do you offer post-launch support and maintenance?', answers: 'Yes, we provide ongoing maintenance and support services to ensure your app remains up-to-date, secure, and performs optimally. This includes updates, bug fixes, and enhancements based on user feedback.' },
  { questions: 'How do you ensure the quality of the mobile apps you develop?', answers: 'Quality is a top priority at Zai Systems. We conduct rigorous testing throughout the development process to ensure the app is free of bugs and performs well across different devices and platforms. Our quality assurance team ensures that the app meets the highest standards.' },
  { questions: 'How much does it cost to develop a mobile app with Zai Systems?', answers: 'The cost of mobile app development varies based on the complexity and specific requirements of the project. We offer flexible pricing models to suit different budgets and requirements. Contact us for a detailed quote tailored to your needs.' },
  { questions: 'Why should I choose Zai Systems for my mobile app development needs?', answers: 'Zai Systems stands out due to our experienced team, customer-centric approach, comprehensive services, innovative solutions, transparent communication, and competitive pricing. We are committed to delivering solutions that add real value to your business and exceed your expectations.' },
];
const MainFAQs = [
  { questions: 'What is your experience?', answers: 'We have more than 4 years of experience. Our company has worked with different multidimensional local and international companies including retails, technology, telecommunication companies and many more. Our clients were satisfied with our best services and customer support.' },
  { questions: 'What technologies do you use?', answers: 'We use different technology for our services. For back-end projects we use Python, Django and Node.js. and for front-end projects, we use Angular.js, and React.js.' },
  { questions: 'What are the benefits of managed services?', answers: 'One of the biggest advantages of managed IT services is cost savings that businesses experience by making business IT support costs more predictable and manageable. A managed IT service approach also provides your businesses with comprehensive security, customized backup and recovery solutions, and state-of-the-art cloud storage and hosting.' },
  { questions: 'Do the Zai Systems provide afterhours emergency support?', answers: 'Yes, our customer support is available 24x7' },
  { questions: 'What differentiats The Zai Systems? Why Should I Choose You?', answers: 'Zai Systems is a reliable IT partner with a strong focus on service. We pride ourselves on having the ability to understand your business and your customers’ needs so that we can customize our service to you. Here we ensure you provide that same commitment to your customers. We as a dedicated partner always willing to provide predictability of service, cost certainty, and responsiveness. Our organizational expertise, extensive senior talent, professional knowledge, and effective software systems will help you achieve your business goals with us.' },
];
export const ServiceData = [
  {
    id: 1,
    service: 'smart-contract-development',
    list: smartContract1,
    // listAud: smartContract2,
    // listTemp: smartContract3,
    listService: smartContract4a,
    listRoute: smartContract4,
    listWhy: smartContract5a,
    listTools: smartContract5,
    listCourse: smartContract6a,
    listBenefit: smartContract6,


    // listCertificate: smartContract7,
    listcard: smartContract8a,
    listcarddetails: smartContract8,
    // listnftExtra: smartContract13a,
    // listnftExtraDetails: smartContract13,
    listForSeo: SeoDataSmart,
    FAQs: ContractFAQs,
  }
  , {
    service: 'wallet-development',
    list: wallet1,
    listAud: wallet2,
    listTemp: wallet3,
    listService: wallet4a,
    listRoute: wallet4,
    listWhy: wallet5a,
    listTools: wallet5,

    listCourse: wallet6a,
    listBenefit: wallet6,
    listCertificate: wallet7a,
    // listadvantage: wallet7,
    listForSeo: SeoDataWallet

  },
  {
    service: 'defi-development',
    list: defi1,
    // listAud: defi2,
    // listTemp: defi3,
    listService: defi4a,
    listRoute: defi4,
    // listWhy: defi5a,
    // listTools: defi5,
    listCourse: defi6a,
    listBenefit: defi6,
    // listCertificate: defi7a,
    // listadvantage: defi7,
    // listcard: defi8a,
    // listcarddetails:defi8,
    listImages: defi12a,
    listImagesDetails: defi12,
    listnftExtra: defi13a,
    listnftExtraDetails: defi13,
    listForSeo: SeoDataDefi

  }
  , {
    service: 'white-paper-development',
    list: white1,
    listAud: white2a,
    // listTemp: white3,
    // listService: white4a,
    listRoute: white4,
    listcard: white8a,
    listcarddetails: white8,
    listWhy: white5a,
    listTools: white5,
    listCourse: white6a,
    listBenefit: white6,
    // listCertificate: white7a,
    // listadvantage: white7,
    // listnftExtra: white13a,
    // listnftExtraDetails: white13,
    listForSeo: SeoDataWhite,
    FAQs: WhiteFAQs,
  },
  {
    service: 'exchange-development',
    list: exchange1,
    // listAud: exchange2,
    // listTemp: exchange3,
    listService: exchange4a,
    listRoute: exchange4,
    listWhy: exchange5a,
    listTools: exchange5,
    listCourse: exchange6a,
    listBenefit: exchange6,

    //   listCertificate: exchange7,
    listForSeo: SeoDataExchange
  },
  {
    service: 'hyperledger-development',
    list: hyperledger1,
    listAud: hyperledger2,
    // listTemp: hyperledger3,
    // listService: hyperledger4a,
    listRoute: hyperledger4,
    // listWhy: hyperledger5a,
    // listTools: hyperledger5,
    listCourse: hyperledger6a,
    listBenefit: hyperledger6,

    //   listCertificate: hyperledger7,
    // listcard: hyperledger8a,
    // listcarddetails: hyperledger8,
    listnftExtra: hyperledger13a,
    listnftExtraDetails: hyperledger13,
    listForSeo: SeoDataHyperledger
  }, {
    service: 'artificial-intelligence',
    list: artificial1,
    // listAud: artificial2,
    listTemp: artificial3,
    listService: artificial4a,
    listRoute: artificial4,
    // listWhy: artificial5a,
    // listTools: artificial5,
    listCourse: artificial6a,
    listBenefit: artificial6,
    ndustrieshead: enterprise13a,
    industrieshead: enterprise13a,
    industrieslist1: enterprise13b,
    industrieslist2: enterprise13c,
    listTestimonials: artificialestimonials,
    FAQs: artificialFAQs,
    //   listCertificate: hyperledger7,
    listForSeo: SeoDataArtificial
  },
  {
    service: 'robotic-process-automation',
    list: robotic1,
    listAud: robotic2,
    listTemp: robotic3,
    listService: robotic4a,
    listRoute: robotic4,
    listWhy: robotic5a,
    listTools: robotic5,
    // listCourse: robotic6a,
    // listBenefit: robotic6,

    //   listCertificate: robotic7,
    listForSeo: SeoDataRobotic,
    FAQs: RoboticsFAQs,
  },
  {
    service: 'big-data',
    list: bigData1,
    // listAud: bigData2,
    listTemp: bigData3,
    listService: bigData4a,
    listRoute: bigData4,
    listWhy: bigData5a,
    listTools: bigData5,
    listCourse: bigData6a,
    listBenefit: bigData6,

    //   listCertificate: bigData7,
    listForSeo: SeoDataBig
  },
  {
    service: 'natural-language-processing',
    list: natural1,
    // listAud: natural2,
    // listTemp: natural3,
    listService: natural4a,
    listRoute: natural4,
    // listWhy: natural5a,
    // listTools: natural5,
    listCourse: natural6a,
    listBenefit: natural6,

    //   listCertificate: robotic7,
    listForSeo: SeoDataNatural
  },
  {
    service: 'staff-augmentation',
    list: staff1,
    // listAud: staff2,
    // listTemp: staff3,
    listService: staff4a,
    listRoute: staff4,
    listWhy: staff5a,
    listTools: staff5,
    // listCourse: staff6a,
    // listBenefit: staff6,
    // listCertificate: staff7a,
    // listadvantage: staff7,
    listcard: staff8a,
    listcarddetails: staff8,
    listdata: staff9a,
    // listdatadetails: staff9,
    listForSeo: SeoDataStaff
  },
  {
    service: 'blockchain-developers',
    list: blockchain1,
    // listAud: blockchain2,
    // listTemp: blockchain3,
    listService: blockchain4a,
    listRoute: blockchain4,
    // listWhy: blockchain5a,
    // listTools: blockchain5,
    // listCourse: blockchain6a,
    // listBenefit: blockchain6,

    // listCertificate: blockchain7a,
    // listadvantage: blockchain7,
    listcard: blockchain8a,
    // listcarddetails:blockchain8,
    // listdata: blockchain9a,
    // listdatadetails: blockchain9,
    // listHiring: blockchain10a,
    // listHiringDetails: blockchain10,
    listImages: blockchain12a,
    listImagesDetails: blockchain12,
    listForSeo: SeoDataBlockchain
  },
  {
    service: 'full-stack-developers',
    list: fullstack1,
    // listAud: fullstack2,
    // listTemp: fullstack3,
    listService: fullstack4a,
    listRoute: fullstack4,
    listWhy: fullstack5a,
    listTools: fullstack5,
    // listCourse: fullstack6a,
    // listBenefit: fullstack6,

    // listCertificate: fullstack7a,
    // listadvantage: fullstack7,
    // listcard:fullstack8a,
    // listcarddetails:fullstack8,
    // listdata: fullstack9a,
    // listdatadetails: fullstack9,
    // listHiring: fullstack10a,
    // listHiringDetails: fullstack10,
    listForSeo: SeoDataFullstack
  },
  {
    service: 'dedicated-developers',
    list: dedicated1,
    // listAud: dedicated2,
    // listTemp: dedicated3,
    listService: dedicated4a,
    listRoute: dedicated4,
    listWhy: dedicated5a,
    listTools: dedicated5,
    listCourse: dedicated6a,
    listBenefit: dedicated6,

    // listCertificate: dedicated7a,
    // listadvantage: dedicated7,
    // listcard:dedicated8a,
    // listcarddetails:dedicated8,
    // listdata: dedicated9a,
    // listdatadetails: dedicated9,
    // listHiring: dedicated10a,
    // listHiringDetails: dedicated10,
    listForSeo: SeoDataDedicated
  },
  {
    service: 'offshore-developers',
    list: offshore1,
    // listAud: offshore2,
    // listTemp: offshore3,
    listService: offshore2a,
    listRoute: offshore4,
    listWhy: offshore5a,
    listTools: offshore5,
    // listCourse: offshore6a,
    // listBenefit: offshore6,
    // listCertificate: offshore7a,
    // listadvantage: offshore7,
    listcard: offshore8a,
    listcarddetails: offshore8,
    // listdata: offshore9a,
    // listdatadetails: offshore9,
    // listHiring: offshore10a,
    // listHiringDetails: offshore10,
    listForSeo: SeoDataOffshore,
    FAQs: offshoreFAQS
  },
  {
    service: 'devops-engineers',
    list: devops1,
    // listAud: devops2a,
    // listTemp: devops3,
    listService: devops2a,
    listRoute: devops4a,
    listWhy: devops5a,
    listTools: devops5,
    listCourse: devops6a,
    listBenefit: devops6,
    // listCertificate: devops7a,
    // listadvantage: devops7,
    // listcard:devops8a,
    // listcarddetails:devops8,
    // listdata: devops9a,
    // listdatadetails: devops9,
    // listHiring:devops10a,
    // listHiringDetails:devops10,
    listForSeo: SeoDataDevops
  },
  {
    service: 'nft-marketplace',
    list: nftMarketplace1,
    // listAud: nftMarketplace2,
    // listTemp: nftMarketplace3,
    listService: nftMarketplace4a,
    listRoute: nftMarketplace4,
    // listWhy: nftMarketplace5a,
    // listTools: nftMarketplace5,
    listCourse: nftMarketplace6a,
    listBenefit: nftMarketplace6,
    // listCertificate: nftMarketplace7a,
    // listadvantage: nftMarketplace7,
    // listcard: nftMarketplace8a,
    // listcarddetails: nftMarketplace8,
    // listdata: nftMarketplace9a,
    // listdatadetails: nftMarketplace9,
    // listHiring: nftMarketplace10a,
    // listHiringDetails: nftMarketplace10,
    listForSeo: SeoDataMarketplace
  },
  {
    service: 'nft-minting',
    list: nftMinting1,
    // listAud: nftMinting2,
    // listTemp: nftMinting3,
    listService: nftMinting4a,
    listRoute: nftMinting4,
    // listWhy: nftMinting5a,
    // listTools: nftMinting5,
    listCourse: nftMinting6a,
    listBenefit: nftMinting6,
    // listCertificate: nftMinting7a,
    // listadvantage: nftMinting7,
    // listcard:nftMinting8a,
    // listcarddetails:nftMinting8,
    // listdata: nftMinting9a,
    // listdatadetails: nftMinting9,
    // listHiring: nftMinting10a,
    // listHiringDetails: nftMinting10,
    listnftExtra: nftMinting13a,
    listnftExtraDetails: nftMinting13,
    listForSeo: SeoDataMinting
  },
  {
    service: 'nft-marketing',
    list: nftMarketing1,
    // listAud: nftMarketing2,
    // listTemp: nftMarketing3,
    // listService: nftMarketing4a,
    // listRoute: nftMarketing4,
    listWhy: nftMarketing5a,
    listTools: nftMarketing5,
    listCourse: nftMarketing6a,
    listBenefit: nftMarketing6,
    // listCertificate: nftMarketing7a,
    // listadvantage: nftMarketing7,
    // listcard:nftMarketing8a,
    // listcarddetails:nftMarketing8,
    listdata: nftMarketing9a,
    listdatadetails: nftMarketing9,
    // listHiring:nftMarketing10a,
    // listHiringDetails:nftMarketing10,
    listForSeo: SeoDataMarketing
  },
  {
    service: 'nft-design',
    list: nftDesign1,
    // listAud: nftDesign2,
    // listTemp: nftDesign3,
    listService: nftDesign4a,
    listRoute: nftDesign4,
    listWhy: nftDesign5a,
    listTools: nftDesign5,
    listCourse: nftDesign6a,
    listBenefit: nftDesign6,
    // listCertificate: nftDesign7a,
    // listadvantage: nftDesign7,
    // listcard: nftDesign8a,
    // listcarddetails: nftDesign8,
    // listdata: nftDesign9a,
    // listdatadetails: nftDesign9,
    // listHiring: nftDesign10a,
    // listHiringDetails: nftDesign10,

    listForSeo: SeoDataDesign
  },
  {
    service: 'community-building',
    list: community1,
    // listAud: community2,
    // listTemp: community3,
    listService: community4a,
    listRoute: community4,
    listWhy: community5a,
    listTools: community5,
    listCourse: community6a,
    listBenefit: community6,
    // listCertificate: community7a,
    // listadvantage: community7,
    listcard: community8a,
    listcarddetails: community8,
    // listdata: community9a,
    // listdatadetails: community9,
    // listHiring:community10a,
    // listHiringDetails:community10,
    // listnftExtra: community13a,
    // listnftExtraDetails: community13,
    listForSeo: SeoDataCommunity
  },
  {
    service: 'nft',
    list: nft1,
    // listAud: nft2,
    // listTemp: nft3,
    listService: nft4a,
    listRoute: nft4,
    listCourse: nft6a,
    listBenefit: nft6,
    // listCertificate: nft7a,
    // listadvantage: nft7,
    listcard: nft8a,
    listcarddetails: nft8,
    // listdata: nft9a,
    // listdatadetails: nft9,
    // listHiring: nft10a,
    // listHiringDetails: nft10,
    // listnftExtra: nft13a,
    // listnftExtraDetails: nft13,
    industrieshead: enterprise13a,
    industrieslist1: nft13b,
    industrieslist2: nft13c,
    listTestimonials: nfttestimonials,
    FAQs: nftServiceFAQs,
    listForSeo: SeoDataNft

  },
  {
    service: 'metaverse',
    list: metaverse1,
    // listAud: metaverse2,
    // listTemp: metaverse3,
    listService: metaverse4a,
    listRoute: metaverse4,
    listWhy: metaverse5a,
    listTools: metaverse5,
    // listCourse: metaverse6a,
    // listBenefit: metaverse6,
    // listCertificate: metaverse7a,
    // listadvantage: metaverse7,
    listcard: metaverse8a,
    listcarddetails: metaverse8,
    // listdata: metaverse9a,
    // listdatadetails: metaverse9,
    // listHiring:metaverse10a,
    // listHiringDetails:metaverse10,
    listForSeo: SeoDataMetaverse
  },
  {
    service: 'blockchain-development',
    list: blockchainDev1,
    listAud: blockchainDev2,
    // listTemp: blockchainDev3,
    listService: blockchainDev4a,
    listRouteb: blockchainDev4,
    listWhy: blockchainDev12a,
    listTools: blockchainDev12,
    listCourse: blockchainDev6a,
    listBenefit: blockchainDev6,
    // listCertificate: blockchainDev7a,
    // listadvantage: blockchainDev7,
    listcard: blockchainDev8a,
    listcarddetails: blockchainDev8,
    // listdata: blockchainDev9a,
    // listdatadetails: blockchainDev9,
    // listHiring: blockchainDev10a,
    // listHiringDetails: blockchainDev10,
    listForSeo: SeoDataBlockchaindev,
    listcard: blockchainDev11a,
    listcarddetails: blockchainDev11,
    listWeBestHead: blockchainDev13a,
    listWeBest: blockchainDev13,
    listWhyUsHead: blockchainDev14a,
    listWhyUsDis: blockchainDev14,
    // industrieshead: productDev111a,
    // industrieslist1: productDev111b,
    // industrieslist2: productDev111c,
    getStarted: productDev11,
    listTestimonials: testimonials,
    FAQs: BlockChainServiceFAQs,
  },
  {
    service: 'near-protocol-development',
    list: near1,
    // listAud: near2,
    // listTemp: near3,
    listService: near4a,
    listRoute: near4,
    // listWhy: near5a,
    // listTools: near5,
    listCourse: near6a,
    listBenefit: near6,
    // listCertificate: near7a,
    // listadvantage: near7,
    // listcard: near8a,
    // listcarddetails: near8,
    // listdata: near9a,
    // listdatadetails: near9,
    // listHiring: near10a,
    // listHiringDetails: near10,
    // listConclusion: near11,
    listForSeo: SeoDataNear
  },
  {
    service: 'web-app-development',
    list: webApp1,
    // listAud: webApp2,
    listTemp: webApp3,
    listService: webApp4a,
    listRoute: webApp4,
    // listWhy: webApp5a,
    // listTools: webApp5,
    listCourse: webApp6a,
    listBenefit: webApp6,
    // listCertificate: webApp7a,
    // listadvantage: webApp7,
    listcard: webApp8a,
    listcarddetails: webApp8,
    // listdata: webApp9a,
    // listdatadetails: webApp9,
    // listHiring: webApp10a,
    // listHiringDetails: webApp10,
    // listConclusion:webApp11,

    listWeBestHead: webApp12a,
    listWeBest: webApp12,
    listWhyUsHead: webApp13a,
    listWhyUsDis: webApp13,
    listSuccessStoriesHead: webApp14a,
    listSuccessStoriesDis: webApp14,
    getStarted: webApp15,
    listTestimonials: testimonials,
    FAQs: WebServiceFAQs,

    listForSeo: SeoDataWebapp
  },
  {
    service: 'mobile-app-development',
    list: mobileApp1,
    // listAud: mobileApp2,
    listTemp: mobileApp3,
    listService: mobileApp4a,
    listRoute: mobileApp4,
    listWhy: mobileApp5a,
    listTools: mobileApp5,
    // listCourse: mobileApp6a,
    // listBenefit: mobileApp6,
    // listCertificate: mobileApp7a,
    // listadvantage: mobileApp7,
    listcard: mobileApp8a,
    listcarddetails: mobileApp8,
    // listdata: mobileApp9a,
    // listdatadetails: mobileApp9,
    // listHiring: mobileApp10a,
    // listHiringDetails: mobileApp10,
    // listConclusion:mobileApp11,
    listWeBestHead: mobileApp9a,
    listWeBest: mobileApp9,
    listWhyUsHead: mobileApp10a,
    listWhyUsDis: mobileApp10,
    listTestimonials: testimonials,
    FAQs: MobileServiceFAQs,
    listForSeo: SeoDataMobile
  },
  {
    service: 'ui-ux-design',
    list: ui1,
    listAud: ui2,
    // listTemp: ui3,
    listService: ui4a,
    listRoute: ui4,
    // listWhy: ui5a,
    // listTools: ui5,
    // listCourse: ui6a,
    // listBenefit: ui6,
    // listCertificate: ui7a,
    // listadvantage: ui7,
    listcard: ui8a,
    listcarddetails: ui8,
    // listdata: ui9a,
    // listdatadetails: ui9,
    // listHiring: ui10a,
    // listHiringDetails: ui10,
    // listConclusion:ui11,
    // listImages: ui12a,
    // listImagesDetails: ui12,
    industrieshead: enterprise13a,
    industrieslist1: enterprise13b,
    industrieslist2: enterprise13c,
    listForSeo: SeoDataUi

  },
  {
    service: 'enterprise-application-development',
    list: enterprise1,
    // listAud: enterprise2,
    // listTemp: enterprise3,
    listService: enterprise4a,
    listRoute: enterprise4,
    // listWhy: enterprise5a,
    // listTools: enterprise5,
    listCourse: enterprise6a,
    listBenefit: enterprise6,
    // listCertificate: enterprise7a,
    // listadvantage: enterprise7,
    listcard: enterprise8a,
    listcarddetails: enterprise8,
    // listWhy: enterprise12a,
    // listTools: enterprise12,
    industrieshead: enterprise13a,
    industrieslist1: enterprise13b,
    industrieslist2: enterprise13c,
    listTestimonials: enterprisetestimonials,
    // getStarted: enterprise14,
    FAQs: enterpriseFAQs,
    // listdata: enterprise9a,
    // listdatadetails: enterprise9,
    // listHiring: enterprise10a,
    // listHiringDetails: enterprise10,
    // listConclusion:enterprise11,
    listForSeo: SeoDataEnterprise
  },
  {
    service: 'custom-software-development',
    list: customSoftware1,
    // listAud: customSoftware2,
    listTemp: customSoftware3,
    listService: customSoftware4a,
    listRoute: customSoftware4,
    listWhy: customSoftware5a,
    listTools: customSoftware5,
    listCourse: customSoftware6a,
    listBenefit: customSoftware6,
    // listCertificate: customSoftware7a,
    // listadvantage: customSoftware7,
    listcard: customSoftware8a,
    listcarddetails: customSoftware8,
    // listdata: customSoftware9a,
    // listdatadetails: customSoftware9,
    // listHiring: customSoftware10a,
    // listHiringDetails: customSoftware10,
    // listConclusion:customSoftware11,

    listForSeo: SeoDataCustom
  },
  {
    service: 'product-development',
    list: productDev1,
    // listAud: productDev2,
    // listTemp: productDev3,
    listService: productDev4a,
    servicehead: productDev4b,
    listRoute: productDev4,
    // listWhy: productDev5a,
    // listTools: productDev5,
    listCourse: productDev6a,
    listBenefit: productDev6,
    // listCertificate: productDev7a,
    // listadvantage: productDev7,
    // listcard: productDev8a,
    // listcarddetails: productDev8,
    // listdata: productDev9a,
    // listdatadetails: productDev9,
    // listHiring: productDev10a,
    // listHiringDetails: productDev10,
    // listConclusion:productDev11,

    listcard: productDev10a,
    listcarddetails: productDev10,
    industrieshead: productDev111a,
    industrieslist1: productDev111b,
    industrieslist2: productDev111c,
    getStarted: productDev11,
    visitServiceshead: productDev12a,
    visitServiceslist: productDev12,
    listTestimonials: testimonials,
    FAQs: ProductServiceFAQs,

    listForSeo: SeoDataProduct
  },
  {
    service: 'iot-development',
    list: iot1,
    listAud: iot2,
    // listTemp: iot3,
    listService: iot4a,
    listRoute: iot4,
    // listWhy: iot5a,
    listcard: iot8a,
    listcarddetails: iot8,
    // listImages: iot12a,
    // listImagesDetails: iot12,
    industrieshead: productDev111a,
    industrieslist1: productDev111b,
    industrieslist2: productDev111c,
    visitServiceshead: productDev12a,
    visitServiceslist: productDev12,
    listTestimonials: iottestimonials,
    listWeBestHead: iot9a,
    listWeBest: iot9,
    FAQs: IotServiceFAQs,
    listForSeo: SeoDataIot
  },
  {
    service: 'software-quality-assurance',
    list: QualityAssaurance1,
    // listAud: QualityAssaurance2,
    // listTemp: QualityAssaurance3,
    listService: QualityAssaurance4a,
    listRoute: QualityAssaurance4,
    listWhy: QualityAssaurance5a,
    listTools: QualityAssaurance5,
    // listCourse:QualityAssaurance6a,
    // listBenefit:QualityAssaurance6,
    // listCertificate:QualityAssaurance7a,
    // listadvantage:QualityAssaurance7,
    listcard: QualityAssaurance8a,
    listcarddetails: QualityAssaurance8,
    // listdata:QualityAssaurance9a,
    // listdatadetails:QualityAssaurance9,
    // listHiring:QualityAssaurance10a,
    // listHiringDetails:QualityAssaurance10,
    // listConclusion:QualityAssaurance11,
    //listImages: QualityAssaurance12a,
    //listImagesDetails: QualityAssaurance12,
    listForSeo: SeoDataSQA,
    FAQs: SoftareQualitFAQs,
  },
  {
    service: 'software-project-management',
    list: projectMan1,
    listAud: projectMan2,
    // listTemp:projectMan3,
    listService: projectMan4a,
    listRoute: projectMan4,
    // listWhy:projectMan5a,
    // listTools:projectMan5,
    // listCourse:projectMan6a,
    // listBenefit:projectMan6,
    // listCertificate:projectMan7a,
    // listadvantage:projectMan7,
    listcard: projectMan8a,
    listcarddetails: projectMan8,
    // listdata:projectMan9a,
    // listdatadetails:projectMan9,
    // listHiring:projectMan10a,
    // listHiringDetails:projectMan10,
    // listConclusion:projectMan11,
    //listImages: projectMan12a,
    //listImagesDetails: projectMan12,
    listForSeo: SeoDataSPM,
    FAQs: ProjectMangementFAQs,
  },
  {
    service: 'digital-marketing-services',
    list: digitalMarketing1,
    // listAud:digitalMarketing2,
    // listTemp:digitalMarketing3,
    listService: digitalMarketing4a,
    listRoute: digitalMarketing4,
    // listWhy: digitalMarketing5a,
    // listTools: digitalMarketing5,
    listCourse: digitalMarketing6a,
    listBenefit: digitalMarketing6,
    // listCertificate:digitalMarketing7a,
    // listadvantage:digitalMarketing7,
    listcard: digitalMarketing8a,
    listcarddetails: digitalMarketing8,
    // listdata:digitalMarketing9a,
    // listdatadetails:digitalMarketing9,
    // listHiring:digitalMarketing10a,
    // listHiringDetails:digitalMarketing10,
    // listConclusion:digitalMarketing11
    industrieshead: enterprise13a,
    industrieslist1: nft13b,
    industrieslist2: nft13c,
    listTestimonials: iottestimonials,
    FAQs: digitalMarketingFAQs,
    listForSeo: SeoDataDigital
  },
  {
    service: 'graphic-designing',
    list: graphicDesigning1,
    // listAud:graphicDesigning2,
    // listTemp:graphicDesigning3,
    listService: graphicDesigning4a,
    listRoute: graphicDesigning4,
    // listTools:graphicDesigning5,
    // listcard: graphicDesigning6a,
    // listcarddetails: graphicDesigning6,
    getStarted: graphicDesigning7,
    listTestimonials: graphicDesigningTestimonials,
    FAQs: graphicDesigningFAQs,
    // listCertificate:graphicDesigning7a,
    // listadvantage:graphicDesigning7,
    // listcard:graphicDesigning8a,
    // listcarddetails:graphicDesigning8,
    // listdata:graphicDesigning9a,
    // listdatadetails:graphicDesigning9,
    // listHiring:graphicDesigning10a,
    // listHiringDetails:graphicDesigning10,
    // listConclusion:graphicDesigning11
    listForSeo: SeoDataGraphic
  },
  {
    service: 'content-writing',
    list: contentWriting1,
    listService: contentWriting4a,
    listRoute: contentWriting4,
    listWhy: contentWriting5a,
    listTools: contentWriting5,
    listCourse: contentWriting6a,
    listBenefit: contentWriting6,
    listcard: contentWriting8a,
    listcarddetails: contentWriting8,
    listTestimonials: Contenttestimonials,
    FAQs: ContentServiceFAQs,

    listForSeo: SeoDataContent
  },
  {
    service: 'managed-cloud-services',
    list: cloud1,
    // listAud:cloud2,
    // listTemp:cloud3,
    // listService: cloud4a,
    // listRoute: cloud4,
    listWhy: cloud5a,
    listTools: cloud5,
    listCourse: cloud6a,
    listBenefit: cloud6,
    // listCertificate:cloud7a,
    // listadvantage:cloud7,
    // listcard: cloud8a,
    // listcarddetails: cloud8,
    // listdata:cloud9a,
    // listdatadetails:cloud9,
    // listHiring:cloud10a,
    // listHiringDetails:cloud10,
    // listConclusion:cloud11,
    listForSeo: SeoDataCloud
  },
  {
    service: 'e-commerce-solution-providers',
    list: ecommerce1,
    // listAud:ecommerce2,
    // listTemp:ecommerce3,
    // listService: ecommerce4a,
    // listRoute: ecommerce4,
    listWhy: ecommerce5a,
    listTools: ecommerce5,
    listCourse: ecommerce6a,
    listBenefit: ecommerce6,
    // listCertificate:ecommerce7a,
    // listadvantage:ecommerce7,
    // listcard: ecommerce8a,
    // listcarddetails: ecommerce8,
    // listdata:ecommerce9a,
    // listdatadetails:ecommerce9,
    // listHiring:ecommerce10a,
    // listHiringDetails:ecommerce10,
    // listConclusion:ecommerce11,
    listnftExtra: ecommerce13a,
    listnftExtraDetails: ecommerce13,
    listForSeo: SeoDataEcommerce
  },
  {
    service: 'bestspoke-it-solutions-for-business',
    list: bespoke1,
    // listAud:bespoke2,
    // listTemp:bespoke3,
    listService: bespoke4a,
    listRoute: bespoke4,
    listWhy: bespoke5a,
    listTools: bespoke5,
    listCourse: bespoke6a,
    listBenefit: bespoke6,
    // listCertificate:bespoke7a,
    // listadvantage:bespoke7,
    // listcard: bespoke8a,
    // listcarddetails: bespoke8,
    // listdata:bespoke9a,
    // listdatadetails:bespoke9,
    // listHiring:bespoke10a,
    // listHiringDetails:bespoke10,
    // listConclusion:bespoke11,
    // listnftExtra: bespoke13a,
    // listnftExtraDetails: bespoke13,
    listForSeo: SeoDataBespoke
  },
  {
    service: 'data-analytics',
    list: dataAnalytics1,
    // listAud:dataAnalytics2,
    // listTemp:dataAnalytics3,
    // listService: dataAnalytics4a,
    // listRoute: dataAnalytics4,
    listWhy: dataAnalytics5a,
    listTools: dataAnalytics5,
    listCourse: dataAnalytics6a,
    listBenefit: dataAnalytics6,
    // listCertificate:dataAnalytics7a,
    // listadvantage:dataAnalytics7,
    // listcard: dataAnalytics8a,
    // listcarddetails: dataAnalytics8,
    // listdata:dataAnalytics9a,
    // listdatadetails:dataAnalytics9,
    // listHiring:dataAnalytics10a,
    // listHiringDetails:dataAnalytics10,
    // listConclusion:dataAnalytics11,
    listnftExtra: dataAnalytics13a,
    listnftExtraDetails: dataAnalytics13,
    listForSeo: SeoDatadataAnalytics
  },
  {
    service: 'business-intelligence',
    list: business1,
    // listAud:business2,
    // listTemp:business3,
    // listService: business4a,
    // listRoute: business4,
    listWhy: business5a,
    listTools: business5,
    listCourse: business6a,
    listBenefit: business6,
    // listCertificate:business7a,
    // listadvantage:business7,
    // listcard: business8a,
    // listcarddetails: business8,
    // listdata:business9a,
    // listdatadetails:business9,
    // listHiring:business10a,
    // listHiringDetails:business10,
    // listConclusion:business11,
    listnftExtra: business13a,
    listnftExtraDetails: business13,
    listForSeo: SeoDatabusiness
  },
  {
    service: 'information-strategy',
    list: info1,
    // listAud:info2,
    // listTemp:info3,
    listService: info4a,
    listRoute: info4,
    listWhy: info5a,
    listTools: info5,
    listCourse: info6a,
    listBenefit: info6,
    // listCertificate:info7a,
    // listadvantage:info7,
    // listcard: info8a,
    // listcarddetails: info8,
    // listdata:info9a,
    // listdatadetails:info9,
    // listHiring:info10a,
    // listHiringDetails:info10,
    // listConclusion:info11,
    listnftExtra: info13a,
    listnftExtraDetails: info13,
    listForSeo: SeoDatainfo
  }

]
