import React from 'react';
import { Link } from 'react-router-dom';
import Layout from '../../common/Layout';
import SEO from '../../common/SEO';
import SectionTitle from '../sectionTitle/SectionTitle';
import Separator from '../separator/Separator';
import ServiceFour from './ServiceFour';
import ServiceOne from './ServiceOne';
import ServiceSix from './ServiceSix';

const Service = () => {
  return (
    <>
      <SEO>
        <title>Best IT Services for Small Business- Zai Systems</title>
        <meta
          name='description'
          content='Supercharge your small business with award-winning IT services. Secure data, boost productivity, and scale growth with Zai Systems customized solutions. '
        />
        <meta
          name='robots'
          content='index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1'
        />
        <link rel='canonical' href='https://www.zaisystems.com/services' />
      </SEO>
      <Layout>
        {/* Start Slider area  */}
        <div
          className='slider-area slider-style-5 bg-overlay-solid height-600 bg_image'
          data-black-overlay='3'
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-image-13.jpg)`
          }}
        >
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <div className='inner text-start'>
                  <h4 className='subtitle'>About Services</h4>
                  <h1
                    className='title display-one'
                    style={{ fontSize: '45px' }}
                  >
                    Delivering the Best IT Services <br /> for Your Business Needs
                  </h1>
                  <p>
                    Choose Zai Systems, a reliable IT services provider for your business growth.
                  </p>
                  <div className='button-group mt--40 mt_sm--20'>
                    <Link className='btn-default' to='/contact'>
                      Contact Us
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Slider area  */}
        <div className='main-content'>
          {/* Start Service Area  */}
          <div className='rn-service-area rn-section-gap'>
            <div className='container'>
              <div className='row'>
                <div className='col-lg-12'>
                  <SectionTitle
                    textAlign='text-center'
                    radiusRounded=''
                    subtitle='Services'
                    title='A Sneak Peek at Our Services'
                    description='We are a full-service IT company that provides customized solutions for your business needs. Whether you need software development, web design, cloud computing, or IT consulting, we have the expertise and experience to deliver high-quality results.'
                  />
                </div>
              </div>
              <ServiceOne
                serviceStyle='service__style--1 bg-color-blackest radius mt--25'
                textAlign='text-center'
              />
            </div>
          </div>
          {/* End Service Area  */}

          <Separator />
          {/* Start Service Area  */}
          <div className='rn-service-area rn-section-gap'>
            <div className='container'>
              <div className='row'>
                <div className='col-lg-12'>
                  <SectionTitle
                    textAlign='text-center'
                    radiusRounded=''
                    subtitle='Services'
                    title='Our Services Approach'
                    description='Our customized approach is to help your business find the right service, so you can achieve your goals faster and more efficiently.'
                  />
                </div>
              </div>
              <ServiceFour
                serviceStyle='service__style--1 icon-circle-style with-working-process'
                textAlign='text-center'
              />
            </div>
          </div>
          {/* End Service Area  */}

          <Separator />

          {/* Start Service Area  */}
          <div className='rn-service-area rn-section-gap'>
            <div className='container'>
              <div className='row'>
                <div className='col-lg-12'>
                  <SectionTitle
                    textAlign='text-center'
                    radiusRounded=''
                    subtitle='What we can do for you?'
                    title='Why SMBs, E-commerce & Web Agencies choose us?'
                  />
                </div>
              </div>
              <ServiceSix
                serviceStyle='service__style--1 icon-circle-style'
                textAlign='text-center'
              />
            </div>
          </div>
          {/* End Service Area  */}
        </div>
      </Layout>
    </>
  );
};

export default Service;
