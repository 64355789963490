import React from "react";
// import { slugify } from "../../../utils";
import PropTypes from "prop-types";
import Lazyload from "react-lazyload";
import { Link } from "react-router-dom";
const BlogListTwo = ({ data, StyleVar }) => {
  return (
    <>
      <div className={`rn-card ${StyleVar}`}>
        <div className="flex flex-col" style={{ height: "280px", alignItems: "center" }}>
          <div className="thumbnail">
            <Link
              to={process.env.PUBLIC_URL + `/portfolio/${data.portfolio}`}
              className="image"
            >
              <Lazyload><img
                src={`${process.env.PUBLIC_URL}/${data.image}`}
                alt="Blog"
                style={{ height: "200px", width: "100%" }}
              />
              </Lazyload>
            </Link>
          </div>
          <div className="content">
            <h4 className="title" style={{ textAlign: 'center', marginTop: "20px", marginBottom: "20px" }}>
              <Link to={process.env.PUBLIC_URL + `/portfolio/${data.portfolio}`}>
                {data.title}
              </Link>
            </h4>

          </div>
        </div>
      </div>
    </>
  );
};
BlogListTwo.propTypes = {
  data: PropTypes.object,
};
export default BlogListTwo;
