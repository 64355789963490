import { easeQuadInOut } from 'd3-ease';
import React from 'react';
import {
  CircularProgressbar
  //, CircularProgressbarWithChildren,
  // buildStyles
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import TrackVisibility from 'react-on-screen';
import AnimatedProgressProvider from './AnimatedProgressProvider';
const progressData = [
  {
    id: 1,
    title: 'Custom Software Solutions',
    percantage: 95,
    subtitle: 'Presentation your skill',
    strokeColor: '#0f0f11',
    strokePath: '#800000',
    strokeLinecap: 'round',
    strokeWidth: 2
  },

  {
    id: 2,
    title: 'IT Training and Development',
    percantage: 90,
    subtitle: 'Presentation your skill',
    strokeColor: '#0f0f11',
    strokePath: '#800000',
    strokeLinecap: 'round',
    strokeWidth: 2
  },


  {
    id: 3,
    title: 'Creative and Technical Services',
    percantage: 95,
    subtitle: 'Presentation your skill',
    strokeColor: '#0f0f11',
    strokePath: '#800000',
    strokeLinecap: 'round',
    strokeWidth: 2
  }, {
    id: 4,
    title: 'Digital Transformation',
    percantage: 80,
    subtitle: 'Presentation your skill',
    strokeColor: '#0f0f11',
    strokePath: '#800000',
    strokeLinecap: 'round',
    strokeWidth: 2
  },
  {
    id: 5,
    title: 'HCM System',
    percantage: 90,
    subtitle: 'Presentation your skill',
    strokeColor: '#0f0f11',
    strokePath: '#800000',
    strokeLinecap: 'round',
    strokeWidth: 2
  }
];

const CircleProgress = () => {
  return (
    <>
      <div className='row circle_main  mb-5'>
        {progressData.map((progress, index) => (
          <div className='col-lg-4 col-md-6 col-sm-12 circle_bar' key={index}>
            <div className='rwt-circle '>
              <TrackVisibility once className='cirCles'>
                {({ isVisible }) =>
                  isVisible && (
                    <AnimatedProgressProvider
                      valueStart={0}
                      valueEnd={progress.percantage}
                      duration={1.4}
                      easingFunction={easeQuadInOut}
                      repeat={false}
                    >
                      {(value) => {
                        const roundedValue = Math.round(value);
                        return (
                          <CircularProgressbar
                            value={value}
                            text={`${roundedValue}%`}
                            strokeWidth={progress.strokeWidth}
                            styles={{
                              trail: {
                                stroke: progress.strokeColor,
                                transition: 'stroke-dashoffset 0.5s ease 0s'
                              },
                              text: {
                                fill: '#800000',
                                fontSize: '16px',
                                dominantBaseline: 'central',
                                textAnchor: 'middle'
                              },
                              path: {
                                stroke: progress.strokePath,
                                strokeLinecap: progress.strokeLinecap
                              }
                            }}
                          />
                        );
                      }}
                    </AnimatedProgressProvider>
                  )
                }
              </TrackVisibility>
              <div className='circle-info'>
                <h3 className='title' style={{ textAlign: 'center' }}>{progress.title}</h3>
              </div>
            </div>

          </div>
        ))}
      </div>
    </>
  );
};
export default CircleProgress;
