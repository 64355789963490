
import one from './../assets/images/CareerLogos/on demand IT support.webp';
import two from './../assets/images/CareerLogos/online training courses.webp';
import three from './../assets/images/CareerLogos/Flexible work arrangements.webp';
import four from './../assets/images/CareerLogos/team meetings.webp';
import five from './../assets/images/CareerLogos/Exclusive discounts.webp';
import six from './../assets/images/CareerLogos/Remote work stipend.webp';
import seven from './../assets/images/CareerLogos/Generous paid time off.webp';
import eight from './../assets/images/CareerLogos/Mentorship program.webp';
import nine from './../assets/images/CareerLogos/Recognition and rewards.webp';
import ten from './../assets/images/CareerLogos/regular wiy managers.webp';
import eleven from './../assets/images/CareerLogos/Conferences and workshops.webp';
import twelve from './../assets/images/CareerLogos/Mental health support.webp';
import thirteen from './../assets/images/CareerLogos/Professional development and career growth.webp';
import fourteen from './../assets/images/CareerLogos/Remote work technology.webp';
import fifteen from './../assets/images/CareerLogos/Global opportunities.webp';
import sixteen from './../assets/images/CareerLogos/IT training and certification programs.webp';
import seventeen from './../assets/images/CareerLogos/accessibility-and-inclusion.webp';
import eighteen from './../assets/images/CareerLogos/feedback-and-surveys.webp';



const career2 = [
    {
        id: 1,
        title: `Why Zai Systems is Your Dream Career Destination?`,
        para: `Zai Systems isn't just offering employment; it's presenting an exceptional opportunity to collaboratively 
        shape the future of digital and technology. We actively seek talented professionals like you, regardless of your
         background as a developer, designer, marketer, or analyst.`,
        para1: `Resonates with You? Here's Why Zai Systems Stands Out:`,
        text: (
            <>
                <span class='text-white'>Diversity and inclusion: </span>We are dedicated to fostering a culture of equality
                and belonging, both within our teams and across the broader tech ecosystem.
            </>
        ),
        text1: (
            <>
                <span class='text-white'>Continuous learning: </span>We provide opportunities for continuous learning
                and growth, ensuring you stay ahead of the curve.
            </>
        ),
        text2: (
            <>
                <span class='text-white'>Personal development: </span>Your success is our priority. We offer enticing
                perks and programs to support your professional and personal growth.
            </>
        ),
        text3: (
            <>
                <span class='text-white'>Remote work flexibility: </span>Enjoy the benefits of regional flexibility
                with streamlined remote work options, allowing you to work from anywhere in the world.
            </>
        ),
    }
]

const career3a = [
    {
        id: 1,
        title: `Our Offered Perks and Benefits`,
        para: `
        Culture. Collaboration. Innovation! We're here to support you in taking significant strides, exploring new opportunities, 
        and fostering avenues for career advancement.`,
    }

]
const career3 = [
    {
        id: 1,
        title: 'On-demand IT support',
        para: `Our dedicated IT team is available to assist our clients with any technical issues they may encounter. `,
        img: one
    },
    {
        id: 2,
        title: 'Online training courses',
        para: `We provide access to a wide range of online courses on various topics, from technical skills to soft skills. `,
        img: two
    },
    {
        id: 3,
        title: 'Flexible work arrangements',
        para: `We offer flexible work arrangements to help remote employees achieve a healthy work-life balance. `,
        img: three
    },

    {
        id: 4,
        title: 'Virtual team meetings',
        para: `Teams hold regular virtual meetings to collaborate on projects and stay connected. `,
        img: four
    },
    {
        id: 5,
        title: 'Exclusive discounts',
        para: `Take advantage of exclusive discounts on various high-quality products and services of your choice. `,
        img: five
    },
    {
        id: 6,
        title: 'Remote work stipend',
        para: `We offer a monthly stipend to help remote employees offset the costs of working from home. `,
        img: six
    }
    ,

    {
        id: 7,
        title: 'Generous paid time off',
        para: `We offer employees generous paid time off, including vacation time, sick leave, and personal days. `,
        img: seven
    },
    {
        id: 8,
        title: 'Mentorship program',
        para: `Get paired with an experienced mentor who can provide guidance and support your career growth. `,
        img: eight
    },

    {
        id: 9,
        title: 'Recognition and rewards',
        para: `We celebrate your achievements and contributions with meaningful recognition programs and rewards. `,
        img: nine
    },

    {
        id: 10,
        title: 'Manager One-on-Ones',
        para: `Managers meet regularly with their remote employees to discuss their progress, goals, and any concerns they may have. `,
        img: ten
    },
    {
        id: 11,
        title: 'Conferences and workshops',
        para: `Attend industry-specific conferences and workshops to expand 
        your knowledge network and stay up-to-date on the latest trends. `,
        img: eleven
    },
    {
        id: 12,
        title: 'Mental health support',
        para: `We prioritize your mental well-being. We provide workshops, resources, and support to help
        you manage stress and maintain a healthy balance.
        `,
        img: twelve
    },

    {
        id: 14,
        title: 'Remote work technology ',
        para: `We provide you with the tools and resources you need to work effectively remotely. `,
        img: fourteen
    },
    {
        id: 15,
        title: 'Global opportunities',
        para: `Expand your horizons and work with colleagues from different countries and cultures. `,
        img: fifteen
    },
    {
        id: 16,
        title: 'Tech Certification Programs',
        para: `Upskill and reskill with training 
        programs and certifications, staying ahead of the curve.`,
        img: sixteen
    },
    {
        id: 13,
        title: 'Professional development and career growth',
        para: `Zai Systems invests in its employees’ learning and development by providing them with access to various online courses, certifications, and workshops. `,
        img: thirteen
    },
    {
        id: 14,
        title: 'Accessibility and Inclusion',
        para: `We ensure that our products and services are accessible and inclusive for all users, creating a positive user experience, regardless of their abilities, preferences, or backgrounds.`,
        img: seventeen
    },
    {
        id: 15,
        title: 'Feedback and Surveys',
        para: `We collect and analyze feedback and surveys from our users to understand their needs, expectations, and satisfaction levels, and improve our offerings accordingly.`,
        img: eighteen
    }

]
const career4 = [
    {
        id: 1,
        title: `Fullstack WordPress Engineer`,
        para: `As a Full Stack WordPress Engineer, you will lead the end-to-end development of cutting-edge WordPress websites and applications. This role combines creativity and technical expertise, requiring proficiency in frontend technologies (HTML, CSS, JavaScript) and backend frameworks (PHP, MySQL). Your responsibilities include:`,
        responsibilities: [
            "Designing and developing user-centric websites.",
            "Customizing themes and plugins.",
            "Ensuring optimal performance, security, and scalability.",
            "Collaborating with cross-functional teams to deliver high-quality solutions."
        ]
    },

    {
        id: 2,
        title: `Human Resource Manager`,
        para: `Join us as a Human Resource Manager and become the driving force behind our people strategies. You’ll oversee all HR functions, ensuring seamless operations and fostering a positive work environment. Key responsibilities include:`,
        responsibilities: [
            "Talent acquisition and onboarding strategies.",
            "Employee relations and performance management.",
            "Training and development programs.",
            "Ensuring compliance with HR policies and labor laws."
        ]
    },

    {
        id: 3,
        title: `Technical Recruiter`,
        para: `As a Technical Recruiter, your expertise will shape our team by sourcing, evaluating, and onboarding top talent. You'll work closely with hiring managers and leverage recruitment strategies to meet technical staffing needs. Key responsibilities:`,
        responsibilities: [
            "Developing job descriptions and recruitment strategies.",
            "Screening and interviewing candidates.",
            "Building a talent pipeline for current and future needs."
        ]
    },

    {
        id: 4,
        title: `Senior Full Stack Shopify Engineer `,
        para: `Step into the role of a Senior Full Stack Shopify Engineer and lead the development of high-performing e-commerce platforms. Utilize your skills in Shopify, Liquid, JavaScript, and backend integrations to craft exceptional digital shopping experiences. Your responsibilities include:`,
        responsibilities: [
            "Building custom Shopify themes and apps.",
            "Optimizing store performance and scalability.",
            "Ensuring seamless API integrations and third-party tools.",
            "Collaborating with design and marketing teams for cohesive projects."
        ]
    },

    {
        id: 5,
        title: `Senior Email Marketer`,
        para: `We’re seeking a Senior Email Marketer to design and execute impactful email campaigns that drive engagement and conversions. Your role involves:`,
        responsibilities: [
            "Crafting targeted email content and visuals.",
            "Analyzing campaign performance and optimizing strategies.",
            "Managing email automation tools for personalized experiences."
        ]
    },

    {
        id: 6,
        title: `Senior Frontend Engineer`,
        para: `As a Senior Frontend Engineer, you’ll lead the development of visually stunning, responsive web interfaces. Proficient in React.js, Bootstrap, and Tailwind, you’ll:`,
        responsibilities: [
            "Translating UI/UX designs into functional code.",
            "Optimizing applications for speed and scalability.",
            "Collaborating with backend engineers and designers."
        ]
    },

    {
        id: 7,
        title: `Business Development Executive (Sales Navigator)`,
        para: `Elevate our growth as a Business Development Executive with a deep understanding of LinkedIn Sales Navigator. You will:`,
        responsibilities: [
            "Identifying and engaging potential clients using advanced LinkedIn tools.",
            "Building long-term relationships to drive sales and revenue.",
            "Developing tailored business strategies to capture market opportunities."
        ]
    },

    {
        id: 8,
        title: "abcd",
        para: `Take charge as a Business Development Executive specializing in Upwork and play a key role in Zai Systems’ success. This role demands expertise in identifying and securing projects on the Upwork platform while building strong client relationships. Your responsibilities include:`,
        responsibilities: [
            "Actively searching and bidding on potential Upwork projects.",
            "Building and maintaining long-term client relationships.",
            "Analyzing client requirements and delivering tailored solutions.",
            "Meeting and exceeding revenue targets consistently."
        ]
    },

    {
        id: 9,
        title: `Graphic Designer`,
        para: `Join our creative team as a Graphic Designer and craft compelling visuals that drive engagement. Your role will focus on designing for digital and print platforms, including social media, websites, and marketing materials. Key responsibilities:`,
        responsibilities: [
            "Developing captivating designs for branding and campaigns.",
            "Collaborating with marketing and development teams for cohesive visuals.",
            "Ensuring consistency in design language across platforms.",
            "Staying updated with design trends to deliver cutting-edge work."
        ]
    },

    {
        id: 10,
        title: `UI/UX Designer`,
        para: `We’re looking for a talented UI/UX Designer to enhance user experiences and craft intuitive interfaces. Your role will focus on research-driven design and customer-centric solutions. Key responsibilities:`,
        responsibilities: [
            "Creating wireframes, prototypes, and user flows.",
            "Conducting user research to inform design decisions.",
            "Ensuring designs align with brand and usability standards.",
            "Collaborating with developers for seamless implementation."
        ]
    },

    {
        id: 11,
        title: `ROR Developer`,
        para: `Become a vital part of our team as an ROR Developer and create scalable web applications using Ruby on Rails. You’ll work on end-to-end development while ensuring performance and security. Responsibilities include:`,
        responsibilities: [
            "Designing and maintaining robust, reusable code.",
            "Developing APIs and integrating third-party tools.",
            "Optimizing applications for scalability and performance.",
            "Collaborating with cross-functional teams for seamless execution."
        ]
    },

    {
        id: 12,
        title: `Business Analyst`,
        para: `Join us as a Business Analyst and bridge the gap between technical teams and business objectives. Your role involves understanding requirements, analyzing data, and delivering actionable insights. Key responsibilities:`,
        responsibilities: [
            "Conducting requirement-gathering sessions with stakeholders.",
            "Documenting processes and suggesting improvements.",
            "Collaborating with teams to deliver successful projects.",
            "Identifying trends and providing strategic recommendations."
        ]
    },

    {
        id: 13,
        title: `Business Development Manager`,
        para: `Drive growth as a Business Development Manager by identifying new opportunities and fostering relationships. Your role includes:`,
        responsibilities: [
            "Strategizing and implementing business development initiatives.",
            "Building a network of long-term clients and partners.",
            "Conducting market research to explore new avenues.",
            "Achieving revenue goals through innovative strategies."
        ]
    },

    {
        id: 14,
        title: `Senior .NET Developer`,
        para: `Lead innovative projects as a Senior .NET Developer, contributing to the development of scalable applications. Your role will involve:`,
        responsibilities: [
            "Designing and implementing .NET applications.",
            "Writing clean, efficient, and maintainable code.",
            "Collaborating with team members for project success.",
            "Ensuring compliance with coding and security standards."
        ]
    },

    {
        id: 15,
        title: `Senior React Native Developer`,
        para: `Take the lead as a Senior React Native Developer, crafting cross-platform mobile applications that redefine user experiences. Your responsibilities include:`,
        responsibilities: [
            "Developing high-quality React Native apps for Android and iOS.",
            "Integrating APIs and third-party tools for seamless functionality.",
            "Debugging and optimizing apps for performance.",
            "Leading and mentoring junior developers."
        ]
    },

    {
        id: 16,
        title: `Android Developer`,
        para: `As an Android Developer, you will design, develop, and maintain cutting-edge applications for the Android platform. Your role involves:`,
        responsibilities: [
            "Crafting intuitive and engaging mobile applications.",
            "Collaborating with designers and backend developers to ensure seamless integration.",
            "Debugging and optimizing apps for performance and scalability.",
            "Staying updated with the latest Android development trends."
        ]
    },

    {
        id: 17,
        title: `Unreal Environment Artist`,
        para: `Step into the world of creativity as an Unreal Environment Artist. You’ll be responsible for designing visually stunning environments using Unreal Engine. Key responsibilities include:`,
        responsibilities: [
            "Creating immersive 3D environments for games and applications.",
            "Collaborating with developers and designers to bring concepts to life.",
            "Optimizing assets for performance without compromising quality."
        ]
    },

    {
        id: 18,
        title: `Email Marketing Manager`,
        para: `As an Email Marketing Manager, you’ll drive customer engagement through impactful email campaigns. Your responsibilities include:`,
        responsibilities: [
            "Planning and executing email marketing strategies.",
            "Analyzing metrics to optimize performance and improve deliverability.",
            "Personalizing campaigns to enhance customer connections and conversions."
        ]
    },

    {
        id: 19,
        title: `Email Copywriter`,
        para: `As an Email Copywriter, you’ll craft persuasive content to boost engagement and drive revenue growth. Your role involves:`,
        responsibilities: [
            "Writing compelling email copy for campaigns and newsletters.",
            "Collaborating with marketing teams to align content with strategies.",
            "Testing and optimizing email performance for better results."
        ]
    },

    {
        id: 20,
        title: `Python Django Developer`,
        para: `Join our team as a Python Django Developer, where you’ll build scalable web applications. Your role includes:`,
        responsibilities: [
            "Designing and implementing robust backend solutions.",
            "Writing reusable and maintainable code using Django.",
            "Collaborating with frontend developers for seamless integration."
        ]
    },

    {
        id: 21,
        title: `Elixir Developer`,
        para: `As an Elixir Developer, you’ll work on innovative projects leveraging Elixir’s power. Your responsibilities include:`,
        responsibilities: [
            "Writing clean and efficient Elixir code.",
            "Building scalable applications for diverse industries.",
            "Debugging and optimizing for high-performance results."
        ]
    },

    {
        id: 22,
        title: `Take on the challenge as a C++ Developer with Blockchain Experience, where you’ll design and develop blockchain solutions. Your responsibilities include:`,
        responsibilities: [
            "Writing secure and efficient C++ code.",
            "Developing blockchain protocols and smart contracts.",
            "Collaborating with teams to innovate blockchain applications."
        ]
    },

    {
        id: 23,
        title: `Unreal Engine Developer`,
        para: `As an Unreal Engine Developer, you’ll create interactive applications and games. Your role includes:`,
        responsibilities: [
            "Designing and developing projects using Unreal Engine.",
            "Ensuring optimal performance and user experience.",
            "Collaborating with cross-functional teams for seamless delivery."
        ]
    },

    {
        id: 24,
        title: `Golang Developer with Blockchain Experience`,
        para: `Join us as a Golang Developer with Blockchain Experience and create scalable blockchain applications. Your responsibilities include:`,
        responsibilities: [
            "Writing efficient Golang code for blockchain systems.",
            "Collaborating with teams to develop and optimize protocols.",
            "Solving complex problems in decentralized ecosystems."
        ]
    },

    {
        id: 25,
        title: `As a Global Sales Representative, you’ll expand our reach and grow revenue. Your role involves:`,
        responsibilities: [
            "Identifying and engaging potential clients worldwide.",
            "Building long-term relationships and closing deals.",
            "Developing strategies to achieve global sales targets."
        ]
    },

    {
        id: 26,
        title: `DevOps Engineer`,
        para: `Become a pivotal part of our team as a DevOps Engineer, ensuring smooth deployment and operations. Your responsibilities include:`,
        responsibilities: [
            "Managing CI/CD pipelines for seamless delivery.",
            "Automating infrastructure and optimizing systems.",
            "Ensuring high availability and scalability of applications."
        ]
    },

    {
        id: 27,
        title: `Unity Developer`,
        para: `Join our team as a Unity Developer, creating captivating games and applications. Your role involves:`,
        responsibilities: [
            "Designing and coding Unity-based projects.",
            "Optimizing performance for multiple platforms.",
            "Collaborating with teams to enhance user experiences."
        ]
    },

    {
        id: 28,
        title: `Solution Architect`,
        para: `As a Solution Architect, you’ll design technical solutions for complex business challenges. Your responsibilities include:`,
        responsibilities: [
            "Defining architectural solutions and system designs.",
            "Collaborating with stakeholders to meet project requirements.",
            "Ensuring scalability, security, and efficiency of solutions."
        ]
    },

    {
        id: 29,
        title: `Senior Java Developer`,
        para: `Step into a leadership role as a Senior Java Developer, creating robust applications. Your role involves:`,
        responsibilities: [
            "Designing and coding Java-based solutions.",
            "Ensuring application performance and reliability.",
            "Mentoring junior developers for team growth."
        ]
    },

    {
        id: 30,
        title: `As a Blockchain Research Analyst, you’ll explore and develop blockchain applications. Your responsibilities include:`,
        responsibilities: [
            "Researching blockchain trends and technologies.",
            "Developing insights for blockchain adoption and strategies.",
            "Collaborating with teams to implement blockchain solutions."
        ]
    }
];


export const CareerData = [
    {
        id: 1,
        career: 'career',
        //   list: career1,
        listAud: career2,
        listService: career3a,
        listRoute: career3,
        listTemp: career4,
        //   listWhy: career5a,
        //   listTools: career5,
        //   listCourse: career6a,
        //   listBenefit: career6,
        // listCertificate: career7
    }
]
