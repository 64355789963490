import React from 'react';
import AboutImage from '../assets/images/about/contact-image.jpg';
import SEO from '../common/SEO';
import Copyright from '../common/footer/Copyright';
import FooterTwo from '../common/footer/FooterTwo';
import HeaderOne from '../common/header/HeaderOne';
import AboutFour from '../elements/about/AboutFour';
import AboutOne from '../elements/about/AboutOne';
import CalltoActionFive from '../elements/calltoaction/CalltoActionFive';
import CounterUpFour from '../elements/counterup/CounterUpFour';
import SectionTitle from '../elements/sectionTitle/SectionTitle';
import Separator from '../elements/separator/Separator';
import ServiceThree from '../elements/service/ServiceThree';
import TabTwo from '../elements/tab/TabTwo.js';
import TimelineOne from '../elements/timeline/TimelineOne';
import TimelineTwo from '../elements/timeline/TimelineTwo';

const AboutUs = () => {
  return (
    <>
      <SEO>
        <title>About ZAI SYSTEMS: Corporate Business Agency</title>
        <meta
          name='robots'
          content='index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1'
        />

        <link rel='canonical' href='https://www.zaisystems.com/about-us' />
        <meta
          name='description'
          content='Our certification courses appreciate and encourage professionals to access advanced training, information and knowledge on upcoming strategies.'
        />
      </SEO>
      <main className='page-wrapper'>
        <HeaderOne
          btnStyle='btn-small round btn-icon'
          HeaderSTyle='header-transparent'
        />

        {/* Start Slider Area  */}

        <div
          className='slider-area slider-style-1 height-600 bg_image'
          data-black-overlay='5'
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/aboutTwo.jpg)`
          }}
        >
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <div className='inner pt--80 text-center'>
                  <div>
                    <h3 className='rn-sub-badge'>
                      <span className='theme-gradient' >About Zai Systems</span>
                    </h3>
                  </div>
                  <h1 className='title display-one'>
                    We are a Corporate <br /> Business Agency
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Slider Area  */}

        {/* Start Service Area  */}
        <div className='rwt-tab-area rn-section-gap'>
          <div className='container'>
            <AboutOne />
          </div>
        </div>
        <Separator />

        {/* Start Timeline Area  */}
        <div className='rwt-timeline-area rn-section-gap'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <SectionTitle
                  textAlign='text-center'
                  radiusRounded=''
                  subtitle='Timeline'
                  title='Working Process'
                  description='ZAI SYSTEMS (SMC-PVT) LTD maintains a network of strategic partnerships with top-tier manufacturers, technology companies, and system integrators on a worldwide scale.'
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-lg-12'>
                <TimelineOne />
              </div>
            </div>
          </div>
        </div>
        {/* End Timeline Area  */}

        <Separator />

        {/* Start Service Area  */}
        <div className='rn-service-area rn-section-gap '>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <SectionTitle
                  textAlign='text-center'
                  radiusRounded=''
                  subtitle='About'
                  title='Core Values that Drive Us'
                  description='By concentrating on the requirements of our customers and developing long-term, mutually beneficial partnerships, we grow as a successful, sustainable company that supports and mentors our customers throughout the whole lifespan of their solutions and products.'
                />
              </div>
            </div>
            <ServiceThree
              serviceStyle='service__style--2'
              textAlign='text-center'
            />
          </div>
        </div>
        {/* End Service Area  */}
        <Separator />

        <div className='rwt-timeline-area rn-section-gap'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <SectionTitle
                  textAlign='text-center'
                  radiusRounded=''
                  subtitle='Working Process'
                  title='Why Choose Zai Systems ?'
                  description='We have highly skilled engineers with excellent technical knowledge and experience in using the latest software standards, tools, platforms, frameworks and technologies and we invest continuously in training and education, to be able to respond to any new technology challenges and demands from our clients.'
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-lg-10 offset-lg-1 mt--20'>
                <TimelineTwo classVar='no-gradient' />
              </div>
            </div>
          </div>
        </div>

        <Separator />

        <AboutFour image={AboutImage} />

        {/* Start Elements Area  */}
        <div className='rwt-counterup-area pb--20'>
          <div className='container mt_dec--30'>
            <CounterUpFour
              column='col-lg-2 col-md-6 col-sm-6 col-12'
              counterStyle='counter-style-4'
              textALign='text-center'
            />
          </div>
        </div>  <Separator />
        {/* End Elements Area  */}
        {/* Start Elements Area  */}
        {/* <div className='rwt-callto-action-area rn-section-gap'>
          <div className='wrapper'>
            <CalltoActionOne />
          </div>
        </div> */}
        {/* End Elements Area  */}

        {/* Start Elements Area  */}
        <div className='rwt-tab-area rn-section-gap'>
          <div className='container'>
            <div className='row mb--40'>
              <div className='col-lg-12'>
                <SectionTitle
                  textAlign='text-center'
                  radiusRounded=''
                  subtitle='BUSINESS PHILOSOPHY'
                  title=' Our Strategic Business Approach'
                  description=''
                />
              </div>
            </div>
            <TabTwo />
          </div>
        </div>

        {/* End Elements Area  */}
        {/* Start Elements Area  */}
        <div className='rwt-callto-action-area rn-section-gapBottom'>
          <div className='wrapper'>
            <CalltoActionFive />
          </div>
        </div>
        {/* End Elements Area  */}

        <FooterTwo />
        <Copyright />
      </main>
    </>
  );
};

export default AboutUs;
