import React from "react";
import { Link } from "react-router-dom";
import '../../assets/css/Podcast.css';


const videos = [
    {
        link: "https://youtu.be/KU3E895Cll4",
        videoId: "KU3E895Cll4",
        title: "Video Title 1",
    },
    {
        link: "https://youtu.be/8CwhVYMVfeU",
        videoId: "8CwhVYMVfeU",
        title: "Video Title 2",
    },
    {
        link: "https://youtu.be/_iatU4wbwrA",
        videoId: "_iatU4wbwrA",
        title: "Video Title 5",
    },
    {
        link: "https://youtu.be/jfB1K1bqfnY",
        videoId: "jfB1K1bqfnY",
        title: "Video Title 6",
    },
    {
        link: "https://youtu.be/dyX6XU0cpWI",
        videoId: "dyX6XU0cpWI",
        title: "Video Title 7",
    },
    {
        link: "https://youtu.be/N1i5cabbZJM",
        videoId: "N1i5cabbZJM",
        title: "Video Title 8",
    },
    {
        link: "https://youtu.be/sO_YCYUsXhI",
        videoId: "sO_YCYUsXhI",
        title: "Video Title 9",
    },
    {
        link: "https://youtu.be/zwRg2NcCmds?si=hpC_UdpAsu8LOFWK",
        videoId: "zwRg2NcCmds",
        title: "New Video Title 1",
    },
    {
        link: "https://youtu.be/11RnzRk_ark?si=OU78SObJWul4dRuN",
        videoId: "11RnzRk_ark",
        title: "New Video Title 2",
    },
    {
        link: "https://youtu.be/vp5YwlHq__Y?si=y176xwxmVaYvljDC",
        videoId: "vp5YwlHq__Y",
        title: "New Video Title 3",
    },
    {
        link: "https://youtu.be/CeEAtWEzRUM?si=VazYB0-4N1oVotMR",
        videoId: "CeEAtWEzRUM",
        title: "New Video Title 4",
    },
    {
        link: "https://youtu.be/Fv75dp7h5qw?si=Ss3rFq-5-es60gef",
        videoId: "Fv75dp7h5qw",
        title: "New Video Title 5",
    },
    {
        link: "https://youtu.be/fN-_0hrSxPM?si=-futJXS9oS9E_ztf",
        videoId: "fN-_0hrSxPM",
        title: "New Video Title 6",
    },
    {
        link: "https://youtu.be/mZBE3qOnfU8?si=6LCA0PEGa35P4dSP",
        videoId: "mZBE3qOnfU8",
        title: "New Video Title 7",
    },
    {
        link: "https://youtu.be/uXEDSlz111M?si=9QU10PMm_OL_vve8",
        videoId: "uXEDSlz111M",
        title: "New Video Title 8",
    },
    {
        link: "https://youtu.be/LshS6mrXAi0?si=ylcsdPYZdP6NIfzh",
        videoId: "LshS6mrXAi0",
        title: "New Video Title 9",
    },
    {
        link: "https://youtu.be/aAw08wIt0uw?si=8wQdYVcBdGZyP8_X",
        videoId: "aAw08wIt0uw",
        title: "New Video Title 10",
    },
    {
        link: "https://youtu.be/PwTkmpX9_O8?si=faNxccOcxXZO0SCb",
        videoId: "PwTkmpX9_O8",
        title: "New Video Title 11",
    },
    {
        link: "https://youtu.be/ZHGyPRutf7o?si=oKAWXqVfHfUpy8Fr",
        videoId: "ZHGyPRutf7o",
        title: "New Video Title 12",
    },
    {
        link: "https://youtu.be/Jf2O4o5l8ik?si=Eg7rNSDYe0DL8RG7",
        videoId: "Jf2O4o5l8ik",
        title: "New Video Title 13",
    },
    {
        link: "https://youtu.be/3DeyBEyOGws?si=39e_mUPPlfSN7LQt",
        videoId: "3DeyBEyOGws",
        title: "New Video Title 14",
    },
    {
        link: "https://youtu.be/nAdlAKuLELo?si=VRq_HxAaB_qKpY6n",
        videoId: "nAdlAKuLELo",
        title: "New Video Title 15",
    },
    {
        link: "https://youtu.be/ohYxPt2MmMs?si=4WVXU6TUetUdIYPR",
        videoId: "ohYxPt2MmMs",
        title: "New Video Title 16",
    },
    {
        link: "https://youtu.be/oWEWNSNVWjo?si=7HwzoikbSkKRcjpS",
        videoId: "oWEWNSNVWjo",
        title: "New Video Title 17",
    },
    {
        link: "https://youtu.be/BaHV_pDNwAE?si=xc5TTUGWItkLyZSa",
        videoId: "BaHV_pDNwAE",
        title: "New Video Title 18",
    },
    {
        link: "https://youtu.be/QoYFCX7ZQgU?si=-L6e2-ny0VNykzfz",
        videoId: "QoYFCX7ZQgU",
        title: "New Video Title 19",
    },
    {
        link: "https://youtu.be/b24bTjKkQVY?si=IRHMe8ojfYAXDnBz",
        videoId: "b24bTjKkQVY",
        title: "New Video Title 20",
    },
    {
        link: "https://youtu.be/KU3E895Cll4?si=vTwcxXp3EydKcfUG",
        videoId: "KU3E895Cll4",
        title: "Duplicate Video Title 1",
    },
    {
        link: "https://youtu.be/8CwhVYMVfeU?si=yd7-VTLwlCNQKk6Q",
        videoId: "8CwhVYMVfeU",
        title: "Duplicate Video Title 2",
    },
    {
        link: "https://youtu.be/_iatU4wbwrA?si=P2Pe-P287rRZqgFz",
        videoId: "_iatU4wbwrA",
        title: "Duplicate Video Title 3",
    },
    {
        link: "https://youtu.be/jfB1K1bqfnY?si=C8ccwAoopWVLRsMB",
        videoId: "jfB1K1bqfnY",
        title: "Duplicate Video Title 4",
    },
    {
        link: "https://youtu.be/dyX6XU0cpWI?si=LmR5SesOtPh3ExXy",
        videoId: "dyX6XU0cpWI",
        title: "Duplicate Video Title 5",
    },
    {
        link: "https://youtu.be/N1i5cabbZJM?si=-3gejq3e2sy7IEi1",
        videoId: "N1i5cabbZJM",
        title: "Duplicate Video Title 6",
    }
];


const Podcast = () => {
    return (
        <div className="bg-transparent mt-16" id="product">
            <section id="features" className="relative block py-2 md:py-2">
                <h2 className="mainHead" data-aos="slide-right"> Podcast</h2>

                {/* Video Grid */}
                <div className="video-grid pt-4" data-aos="fade-up">
                    {videos.map((video, index) => (
                        <article key={index} className="video-card" data-aos="fade-up">
                            <Link
                                to={video.link} // Use Link for internal navigation (if applicable)
                                target="_blank"  // This ensures that the link opens in a new tab for external URLs
                                rel="noopener noreferrer"
                                className="absolute inset-0"
                            >
                                <img
                                    src={`https://img.youtube.com/vi/${video.videoId}/hqdefault.jpg`}
                                    alt={video.title}
                                    className="h-full w-full object-cover rounded-md"
                                />
                                <div className="overlay">
                                    <button className="play-button">
                                        ▶
                                    </button>
                                </div>
                            </Link>
                        </article>
                    ))}
                </div>
            </section>
        </div>

    );
};

export default Podcast;