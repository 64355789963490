 
import React from 'react'
import Copyright from '../common/footer/Copyright'
import FooterTwo from '../common/footer/FooterTwo'
import SocialFooter from '../common/footer/SocialFooter.'
import HeaderOne from '../common/header/HeaderOne'
import Podcast from '../elements/podcast/Podcast'
import PodcastHero from '../elements/podcast/PodcastHero'

function PodcastPage() {
    return (
        <>
            <HeaderOne
                btnStyle='btn-small round btn-icon'
                HeaderSTyle='header-transparent'
            />
            <PodcastHero />
            <Podcast />

            <SocialFooter />
            <FooterTwo />
            <Copyright />
        </>
    )
}

export default PodcastPage