import React from 'react';
import { FiArrowRight } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import '../assets/css/solutionsslider.css';
import SEO from '../common/SEO';
import Copyright from '../common/footer/Copyright';
import FooterTwo from '../common/footer/FooterTwo';
import HeaderOne from '../common/header/HeaderOne';
import AboutOne from '../elements/about/AboutOne';
import AdvanceTabOne from '../elements/advancetab/AdvanceTabOne';
import CalltoActionFive from '../elements/calltoaction/CalltoActionFive';
import CircleProgress from '../elements/progressbar/CircleProgress';
import SectionTitle from '../elements/sectionTitle/SectionTitle';
import Separator from '../elements/separator/Separator';
import ServiceFive from '../elements/service/ServiceFive';
import TeamOne from '../elements/team/TeamOne';
import { BannerActivation } from '../utils/script';
import TravelAgency from './TravelAgency';
import video2 from '../assets/images/bg/WebVideo.mp4'
import HcmSection from './HcmSection';
import SocialFooter from '../common/footer/SocialFooter.';


const BannerData = [
  {
    video: video2,
    title: 'Software Development and Consultation',
    description: `We are a global IT company that provides software development and project solutions for your business needs. Our flagship product, HCM 360°, is a human capital management system that simplifies, integrates, and organizes your HR processes. We also offer professional training courses to help you advance your career.`,
    show: true
  }
];

const Home = () => {


  return (
    <>
      <SEO>
        <title>
          ZAI SYSTEMS: Best IT Services Company and Training Institute
        </title>
        <meta
          name='description'
          content='We are a Software Development Company and an IT Training Institute. At Zai Systems we provide multi-dimensional IT services for business clients worldwide..'
        />
        <link rel='canonical' href='https://www.zaisystems.com/' />
        <meta
          name='robots'
          content='index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1'
        />
      </SEO>

      <main className='page-wrapper'>
        <HeaderOne
          btnStyle='btn-small round btn-icon'
          HeaderSTyle='header-transparent'
        />

        {/* Start Slider Area  */}
        <Slider
          className='slider-area slider-style-4 '
          {...BannerActivation}
        >

          {BannerData.map((data, index) => (
            <div key={index} >
              <div className='height-600-video '>

                <video className='video-container' autoPlay loop muted style={{ width: '100%', height: 'auto', border: 'red' }}>
                  <source src={data.video} type='video/mp4' />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>))}
        </Slider>
        {/* End Slider Area  */}

        {/* Start Service Area  */}
        <div className='rwt-tab-area rn-section-gap'>
          <div className='container'>
            <div className='row mb--40'>
              <div className='col-lg-12'>
                <SectionTitle
                  textAlign='text-center'
                  radiusRounded=''

                  title1='Software Development Solutions'
                  title2='IT Services & Certified Trainings'

                //  title='Certified IT Trainings'
                // description='Customer satisfaction is our guiding principle, we listen, understand, and respond with solutions critically. With personalized support and prompt assistance at hand, we ensure a smooth journey, aligned with your business demand. Our strategic consultation services are  designed in a way to provide the guidance and expertise you need to achieve sustainable growth.'
                />
              </div>
            </div>
            <AboutOne />
          </div>
        </div>

        {/* End Service Area  */}

        <Separator />
        <HcmSection />
        <Separator />
        {/* Start Elements Area  */}
        <div className='rwt-advance-tab-area rn-section-gap'>
          <div className='container'>
            <div className='row mb--20'>
              <div className='col-lg-12'>
                <SectionTitle
                  textAlign='text-center'
                  radiusRounded=''
                  subtitle='COURSES'
                  title='Learn & Grow with our Online IT Courses'
                  description='Our training courses offer a wide range of professional skills that you can learn from our experts. '
                />
              </div>
            </div>
            <AdvanceTabOne />
            <div className='mt-5 text-center'>
              <Link
                className='btn-default btn-medium btn-border round btn-icon'
                to='/courses'
              >
                Explore Courses
                <i className='icon'>
                  <FiArrowRight />
                </i>
              </Link>
            </div>
          </div>
        </div>
        {/* End Elements Area  */}
        <Separator />

        {/* Start Service Area  */}
        <div className='rn-service-area rn-section-gap'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <SectionTitle
                  textAlign='text-center'
                  radiusRounded=''
                  subtitle='IT Services'
                  title='Explore Our IT Services'
                />
              </div>
            </div>
            <ServiceFive serviceStyle='gallery-style' textAlign='text-start' />
            <div className='  mt-5  text-center'>
              <Link
                className='btn-default btn-medium btn-border round btn-icon'
                to='/services'
              >
                View More
                <i className='icon'>
                  <FiArrowRight />
                </i>
              </Link>
            </div>
          </div>
        </div>
        {/* End Service Area  */}

        <Separator />
        <div className='rwt-elements-area rn-section-gap-top'>
          <div className='container'>
            <div className='row mb--5'>
              <div className='col-lg-12 '>
                <SectionTitle
                  textAlign='text-center'
                  radiusRounded=''
                  subtitle='Expertise'
                  title='Area of Expertise '
                  description=''
                />
              </div>
            </div>
            <div className='col-lg-10 col-sm-12 offset-lg-1 mt-0'>
              <CircleProgress />
            </div>
          </div>
        </div>

        {/* Start Brand Area  */}
        <TravelAgency />

        {/* End Brand Area  */}

        {/* Start Elements Area  */}
        <div className='rwt-team-area rn-section-gap'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <SectionTitle
                  textAlign='text-center'
                  radiusRounded=''
                  subtitle='Our Experts'
                  title='Our Team of Software Experts'
                  description='Let our experienced designers and developers find out the perfect solution for your brand. We have over 10 years of experience building successful software solutions for businesses of all sizes. Schedule a consultation to discuss your ideas.'
                />
              </div>
            </div>
            <TeamOne
              column='col-lg-4 col-md-6 col-12 mt--10'
              teamStyle='team-style-default'
            />
          </div>
        </div>
        {/* End Elements Area  */}

        {/* Start Elements Area  */}
        <div className='rwt-callto-action-area rn-section-gapBottom'>
          <div className='wrapper'>
            <CalltoActionFive />
          </div>
        </div>
        {/* End Elements Area  */}

        <SocialFooter />
        <FooterTwo />
        <Copyright />
      </main>
    </>
  );
};

export default Home;
