import React, { useEffect, useState } from "react";
import BlogClassicData from '../../../data/blog/BlogList.json';
import BlogListTwo from './BlogListTwo';
// import { FaSpinner } from "react-icons/fa";
import filters from "./Filters";

import LazyLoad from "react-lazyload";

const alldataBLog = BlogClassicData;
const BlogPropTwo = ({ column, StyleVarProp }) => {
    const [getAllItems] = useState(alldataBLog);
    const [dataVisibleCount, setDataVisibleCount] = useState(10);
    const [dataIncrement] = useState(8);
    const [noMorePost, setNoMorePost] = useState(false);
    const [activeFilter, setActiveFilter] = useState("");
    const [visibleItems, setVisibleItems] = useState([]);

    useEffect(() => {
        setActiveFilter(filters[0].text.toLowerCase());
        setVisibleItems(getAllItems.filter((item) => item.id <= dataVisibleCount));
    }, [dataVisibleCount, getAllItems]);

    const [textVisible, setTextVisible] = useState(true);

    const handleLoadmore = async (e) => {
        e.preventDefault();

        // Set the state to hide the text when the button is clicked
        setTextVisible(false);

        // Show the GIF when the button is clicked
        const gifElement = document.getElementById('gif');
        if (gifElement) {
            gifElement.style.display = 'inline';
        }

        // Simulate loading for 2 seconds
        await new Promise(resolve => setTimeout(resolve, 1000));


        let tempCount = dataVisibleCount + dataIncrement;
        if (dataVisibleCount >= getAllItems.length) {
            setNoMorePost(true);
        } else {
            setDataVisibleCount(tempCount);
            if (activeFilter === filters[0].text.toLowerCase()) {
                setVisibleItems(getAllItems.filter((data) => data.id <= tempCount));
            } else {
                setVisibleItems(
                    getAllItems.filter(
                        (data) => data.text === activeFilter && data.id <= tempCount
                    )
                );
            }
        }

        // After loading, hide the GIF
        if (gifElement) {
            gifElement.style.display = 'none';
        }

        // Set the state to show the text again
        setTextVisible(true);
    };
    // const gifUrl = 'https://i.gifer.com/ZKZg.gif';
    const gifUrl='https://www.superiorlawncareusa.com/wp-content/uploads/2020/05/loading-gif-png-5.gif'

    // const containerStyle = {
    //     display: 'flex',
    //     alignItems: 'center',
    //     color: 'white',
    //     cursor: 'pointer',
    // };

    const gifStyle = {
        width: '30px',
        height: '30px',
        // marginLeft: '10px',
        display: 'none', // Initially set to 'none'
    };
    return (
        <>

            <div className="col-lg-12">
                <div className="row row--15">
                    {visibleItems.map((item) => (
                        <div key={item.id} className={column}>
                            <BlogListTwo StyleVar={StyleVarProp} data={item} />
                        </div>
                    ))}
                </div>
            </div>
            <div className="col-lg-12">
                <div className="rwt-load-more text-center mt--60">
                     <button
                        className="btn btn-default btn-icon"
                        onClick={handleLoadmore}
                        disabled={noMorePost ? "disabled" : null}
                    >
                        {noMorePost ? (
                            "No Post Here"
                        ) : (
                            <span onClick={handleLoadmore}>
                                {textVisible && "View More Projects"}
                                {/* <span className="icon"><FaSpinner /></span>  */}
                                <span className="icon">

                                    <LazyLoad><img id="gif" src={gifUrl} alt="GIF" style={gifStyle} /></LazyLoad>
                                </span>
                            </span>
                        )}
                    </button> 
                </div>
            </div>
        </>
    )
}
export default BlogPropTwo;