import React from 'react';
// import Lazyload from 'react-lazyload';
import { Link } from 'react-router-dom';
import Typed from 'react-typed';

import About from '../../assets/images/about/about.jpeg';

const AboutOne = () => {
  return (
    <div className='rwt-about-area rn-section-gap_2'>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-lg-5'>
            <div className='px-8'>
              {/* <Lazyload> */}
              <img
                src={About}
                alt='ZAI SYSTEMS: IT services for business clients worldwide'
                className='welcome-image'
                loading='lazy'
              />
              {/* </Lazyload>  */}
            </div>
          </div>

          <div className='col-lg-7 mt_md--40 mt_sm--20'>
            <div className='content'>
              <div className='section-title_about'>
                <h2 className='title'>
                  Welcome To <br />
                  <h2 className='typed_title'>
                    <Typed
                      className='theme-gradient'
                      strings={['ZAI SYSTEMS (SMC-PRIVATE) LIMITED']}
                      typeSpeed={80}
                      backSpeed={5}
                      backDelay={1000}
                      loop
                    // style={{ fontSize: '26px' }}
                    />
                  </h2>
                </h2>
                <p style={{ textAlign: 'justify' }}>
                  Zai Systems (SMC-PVT) Ltd is a multi-dimensional IT company specializing in product and project-based
                  solutions for business clients worldwide. We provide comprehensive set of IT Trainings and development solutions,
                  including, Customer Relationship Management System (CRMS), Human Resource Management System (HRMS),
                  Enterprise Resource Planning Management System (ERPMS), Data Center solutions, Mobile & Web App Development,
                  UI/UX Design, Custom Development, Digital Marketing, Content Writing, Graphic Designing, Blockchain Development,
                  and E-commerce Consultation. <br />
                  We are proud to be one of the Top Leading Desktop and Web-Based Software Solution Providers; we design software
                  solutions that assist our local and international clients in outperforming the competition and remaining
                  ahead in today's competitive business marketplace.
                </p>
                <p style={{ textAlign: 'justify' }}>
                  {/* <br/> */}
                  <h2
                    style={{

                      fontSize: '16px',
                      lineHeight: '15px'
                    }}
                  >
                    Founder & CEO of Zai Systems
                  </h2>

                  Owais Ahmad Khan has an entrepreneurial mindset and exceptional management skills,
                  with over 18 years of experience in the IT industry. He has worked with renowned
                  clients and has received several awards for his innovative solutions.
                </p>
                <div className='read-more-btn mt--0'>
                  <Link className='btn-default' to='/about-us'>
                    <span>More About Us</span>
                  </Link>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

export default AboutOne;
