import React, { useEffect } from 'react';
import { FiX } from "react-icons/fi";
import Logo from "../../elements/logo/Logo";
import Nav from './Nav';

const MobileMenu = ({ show, onClose }) => {
    useEffect(() => {
        // Handle dropdowns with React-friendly logic
        const handleDropdownClick = (event) => {
            const parent = event.target.closest('.has-droupdown');
            if (parent) {
                const submenu = parent.querySelector('.submenu');
                if (submenu) submenu.classList.toggle('active');
                event.target.classList.toggle('open');
            }
        };

        const handleMegamenuClick = (event) => {
            const parent = event.target.closest('.with-megamenu');
            if (parent) {
                const megamenu = parent.querySelector('.rn-megamenu');
                if (megamenu) megamenu.classList.toggle('active');
                event.target.classList.toggle('open');
            }
        };

        // Attach event listeners
        const dropdownElements = document.querySelectorAll('.popup-mobile-menu .has-droupdown > a');
        const megamenuElements = document.querySelectorAll('.popup-mobile-menu .with-megamenu > a');

        dropdownElements.forEach((el) => el.addEventListener('click', handleDropdownClick));
        megamenuElements.forEach((el) => el.addEventListener('click', handleMegamenuClick));

        // Cleanup event listeners on component unmount
        return () => {
            dropdownElements.forEach((el) => el.removeEventListener('click', handleDropdownClick));
            megamenuElements.forEach((el) => el.removeEventListener('click', handleMegamenuClick));
        };
    }, []);

    return (
        <div className={`popup-mobile-menu ${show ? "active" : ""}`}>
            <div className="inner">
                <div className="header-top">
                    <Logo
                        image={`${process.env.PUBLIC_URL}/images/logo/logo.webp`}
                        image2={`${process.env.PUBLIC_URL}/images/logo/logo-dark.webp`}
                    />
                    <div className="close-menu">
                        <span className="close-button" onClick={onClose}><FiX /></span>
                    </div>
                </div>
                <Nav />
            </div>
        </div>
    );
};

export default MobileMenu;
