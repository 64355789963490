import React from 'react';
import SEO from '../common/SEO';
import Copyright from '../common/footer/Copyright';
import FooterTwo from '../common/footer/FooterTwo';
import HeaderOne from '../common/header/HeaderOne';
import BlogPropTwo from '../components/blog/itemProp/BlogPropTwo';
import CalltoActionFive from '../elements/calltoaction/CalltoActionFive';
import Typed from 'react-typed';

const PersonalPortfolio = () => {
  const GOOGLE_DRIVE_LINK =
    'https://drive.google.com/file/d/18pRLetRUD_i2fSEaBaKiAnFum07hh_u3/view?usp=drive_link';

  const handleLinkClick = () => {
    window.open(GOOGLE_DRIVE_LINK, '_blank');
  };

  return (
    <>
      <SEO>
        <title>Zai Systems | Our Services and Clients' Feedback</title>
        <meta
          name="description"
          content="Discover Zai Systems' versatile portfolio, featuring expert IT services and courses. Get free consultation now!"
        />
        <meta
          name="robots"
          content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        />
        <link rel="canonical" href="https://www.zaisystems.com/portfolio" />
      </SEO>

      <main className="page-wrapper">
        <HeaderOne
          btnStyle="btn-small round btn-icon"
          HeaderSTyle="header-transparent"
        />

        {/* Slider Area */}
        <div
          className="slider-area slider-style-6_2 inheader-not-transparent height-500"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/NewPortfolio.png)`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <div className="container">
            <div className="row row--30 align-items-center">
              <div className="order-2 order-lg-1 col-lg-6 mt_md--50 mt_sm--50 mt_lg--30">
                <div className="inner text-start">
                  <h1 className="title color-white">
                    Our Work Portfolio
                    <Typed
                      showCursor={false}
                      className="portfolio-typed"
                      strings={['A Gallery of Our Expertise']}
                      typeSpeed={80}
                      backSpeed={5}
                      backDelay={1000}
                      loop
                    />
                  </h1>
                  <button
                    className="btn-default port_btn"
                    onClick={handleLinkClick}
                    aria-label="View Portfolio on Google Drive"
                  >
                    Check Portfolio
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Blog Area */}
        <div className="main-content">
          <div className="rn-blog-area rn-section-gap">
            <div className="container">
              <div className="row mt_dec--30">
                <BlogPropTwo
                  column="col-lg-6 mt--30"
                  StyleVarProp="box-card-style-default card-list-view"
                />
              </div>
            </div>
          </div>
        </div>

        {/* Call to Action Section */}
        <div className="rwt-callto-action-area rn-section-gapBottom">
          <div className="wrapper">
            <CalltoActionFive />
          </div>
        </div>

        {/* Footer */}
        <FooterTwo />
        <Copyright />
      </main>
    </>
  );
};

export default PersonalPortfolio;
