import React from 'react';
import PropTypes from "prop-types";

const Testimonials = ({ data, StyleVar }) => {
    return (
        <div
            className={`rn-card ${StyleVar} testimonial-card`}
            style={{
                backgroundColor: "#0f0f11",
                border: "1px solid #e0e0e0",
                borderRadius: "8px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                overflow: "hidden",
                transition: "transform 0.3s, box-shadow 0.3s",
                padding: "20px",
                width: "350px",
                height: "400px",
            }}
        >
            <div className="inner">
                <div
                    style={{
                        paddingLeft: "20px",
                        paddingRight: "20px",
                        textAlign: "start",
                    }}
                >
                    <div className="description">
                        <p
                            style={{
                                fontSize: "12px",
                                color: "#9E9E9E", // Light gray color for the date
                                marginBottom: "10px",
                            }}
                        >
                            {data.date}
                        </p>
                        <div
                            style={{
                                fontSize: "18px",
                                lineHeight: "1.5",
                                color: "#fff", // Standard readable text color
                            }}
                        >
                            <span
                                style={{
                                    fontSize: "25px",
                                    color: "#333333",
                                    fontWeight: "bold",
                                }}
                            >
                                "
                            </span>{" "}
                            {data.para}{" "}
                            <span
                                style={{
                                    fontSize: "25px",
                                    color: "#333333",
                                    fontWeight: "bold",
                                }}
                            >
                                "
                            </span>
                        </div>
                        <div style={{ marginTop: "15px" }}>
                            <span
                                style={{
                                    fontSize: "14px",
                                    color: "#fff",
                                    fontWeight: "bold",
                                    display: "block",
                                }}
                            >
                                {data.name}
                            </span>
                            <span
                                style={{
                                    fontSize: "12px",
                                    color: "#9E9E9E", // Lighter gray for the title
                                    display: "block",
                                    marginTop: "5px",
                                }}
                            >
                                {data.title}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    );
};

// Validate the shape of the data prop
Testimonials.propTypes = {
    data: PropTypes.shape({
        date: PropTypes.string.isRequired, // The `date` field must be a string and required
        para: PropTypes.string.isRequired, // The `para` field must be a string and required
    }).isRequired,
    StyleVar: PropTypes.string, // Optional string for style variations
};

// Default props in case `StyleVar` is not provided
Testimonials.defaultProps = {
    StyleVar: "",
};

export default Testimonials;
