import emailjs, { init } from "@emailjs/browser";
import { useRef } from "react";
import LazyLoad from "react-lazyload";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import "../assets/css/requestADemo.css";
import contactImage from "../assets/images/contactMailsIcons/contact.webp";
import ZaiLogo from "../assets/images/logo/logo.webp";

const Child3Section = () => (
  <div className="container" >
    <div className="row mt-5">
      <span className="col-md-6 mt-5">Zaisystems &copy; 2024</span>
      <span className="col-md-6 mt-5" style={{ display: 'flex' }}>

        <span> <Link to="/">Home</Link></span>

        <span style={{ margin: '0 15px' }}>•</span>

        <span>Terms and conditions</span>

      </span>
    </div>
  </div>
);

const RequestADemo = () => {
  const form = useRef();
  init("8r6iUINi3pC_HOCoL");

  const handleSubmit = (e) => {
    
    e.preventDefault();
    emailjs
      .sendForm(
        " service_5hujz4g",
        "template_60dlxzd",
        form.current,
        "8r6iUINi3pC_HOCoL"
      )
      .then(
        (result) => {
          form.current.reset();
          toast.success("Message sent Successfully");
        },
        (error) => {
        }
      );
  };

  return (
    <>
  
      <div className=" theme-shape col-md-12">
        <div className="row">
          <div className="col-md-6" >
            <div>
            <LazyLoad><img src={ZaiLogo} alt="company logo" className="mt-4 demoLogo" /></LazyLoad>
            </div>
            <div  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <LazyLoad><img
                src={contactImage}
                alt="Banner Images"
                className="desk demoImage"
              /></LazyLoad>
            </div>
            <Child3Section />
          </div>
          <div className="col-md-6 mt-0 d-flex justify-content-center align-items-center" >
            <form className="child-1" onSubmit={handleSubmit} ref={form}>
                <input
                  type="text"
                  name="name"
                  autoComplete="off"
                  required
                  placeholder="Name *"
                />
                <input
                  type="text"
                  name="company_name"
                  autoComplete="off"
                  required
                  placeholder="Company Name *"
                />
                <input
                  type="number"
                  name="employees"
                  autoComplete="off"
                  required
                  placeholder="Number of Employees *"
                />
                <input
                  type="email"
                  name="email"
                  autoComplete="off"
                  required
                  placeholder="Email *"
                />
                <input
                  type="tel"
                  name="mobile"
                  autoComplete="off"
                  required
                  placeholder="Mobile *"
                />
              <hr />
            <button class="btn-default mt-3" type="submit">Submit Order</button>                             
            </form>   
      <ToastContainer />
          </div>
        </div>
      </div>
      {/* </div> */}


    </>

  );
};

export default RequestADemo;
