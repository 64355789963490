
import payroll1 from './../assets/images/HCM-pages/payroll-with-submodule.webp';
import payroll2 from './../assets/images/HCM-pages/payroll.webp';
import payroll3 from './../assets/images/HCM-pages/payroll3.webp';
import payroll4 from './../assets/images/HCM-pages/payroll4.webp';


import attendance1 from './../assets/images/HCM-pages/attendance.webp';
import attendance2 from './../assets/images/HCM-pages/attendance2.webp';
import attendance3 from './../assets/images/HCM-pages/attendance-submodules-2.webp';
import attendance4 from './../assets/images/HCM-pages/attendance-fingerprint-records.webp';
import attendance5 from './../assets/images/HCM-pages/attendance-fingerprint-records-2.webp';
import attendance6 from './../assets/images/HCM-pages/attendance-settings1.webp';

import leave1 from './../assets/images/HCM-pages/leaves1.webp';
import leave2 from './../assets/images/HCM-pages/leaves2.webp';
import leave3 from './../assets/images/HCM-pages/leaves3.webp';
import leave4 from './../assets/images/HCM-pages/leaves4.webp';

import employee1 from './../assets/images/HCM-pages/emplyee-list.webp';
import employee2 from './../assets/images/HCM-pages/employee-list2.webp';
import employee3 from './../assets/images/HCM-pages/employee-warning-1.webp';
import employee4 from './../assets/images/HCM-pages/employee-warning-2.webp';

import job1 from './../assets/images/HCM-pages/job-contracts1.webp';
import job2 from './../assets/images/HCM-pages/job-contracts2.webp';

import loan1 from './../assets/images/HCM-pages/loans-management-1.webp';
import loan2 from './../assets/images/HCM-pages/loans-management-2.webp';
import loan3 from './../assets/images/HCM-pages/loans-management-3.webp';
import loan4 from './../assets/images/HCM-pages/loans-management-4.webp';


import benefits1 from './../assets/images/HCM-pages/benefits.webp';
import benefits2 from './../assets/images/HCM-pages/expense-submodules.webp';
import benefits3 from './../assets/images/HCM-pages/expenses1.webp';
import benefits4 from './../assets/images/HCM-pages/expenses2.webp';

import memo1 from './../assets/images/HCM-pages/memo1.webp';
import memo2 from './../assets/images/HCM-pages/memo2.webp';

import decision1 from './../assets/images/HCM-pages/decisions1.webp';
import decision2 from './../assets/images/HCM-pages/decisions2.webp';

import endService1 from './../assets/images/HCM-pages/end-of-service1.webp';
import endService2 from './../assets/images/HCM-pages/end-of-service2.webp';
import endService3 from './../assets/images/HCM-pages/end-of-service3.webp';
import endService4 from './../assets/images/HCM-pages/end-of-service4.webp';

import insightful1 from './../assets/images/HCM-pages/insightful-dashboard1.webp';
import insightful2 from './../assets/images/HCM-pages/insightful-dashboard2.webp';
import insightful3 from './../assets/images/HCM-pages/dashboard.webp';
import insightful4 from './../assets/images/HCM-pages/dashboard2.webp';

import letter1 from '../assets/images/HCM-pages/papers.webp';
import letter2 from '../assets/images/HCM-pages/3.webp';
import letter3 from '../assets/images/HCM-pages/4.webp';
import letter4 from '../assets/images/HCM-pages/5.webp';



import Image1 from './../assets/images/timeline/timeline-01.webp';
import Image2 from './../assets/images/timeline/timeline-02.webp';


// payment management
import EmployeeSelf from "../assets/images/HCM-pages/Payroll Management System/Employee Self-Service Portal.png"
import integration from "../assets/images/HCM-pages/Payroll Management System/Integration with Time and Attendance Systems.png"
import dataSecurity from "../assets/images/HCM-pages/Payroll Management System/Data Security and Compliance.png"
import configAlert from "../assets/images/HCM-pages/Payroll Management System/Configurable Alerts and Notifications.png"

// for task manag
import automated from "../assets/images/HCM-pages/Task Management/Automated Reminders and Notifications.png"
import Dependency from "../assets/images/HCM-pages/Task Management/Dependency Management.png"
import fileshare from "../assets/images/HCM-pages/Task Management/File Sharing and Attachment Capabilities.png"
import accessibility from "../assets/images/HCM-pages/Task Management/Mobile Accessibility.png"
import interfaceimg from "../assets/images/HCM-pages/Task Management/Intuitive Interface.jpg"

// for memo
// import automated from "../assets/images/HCM-pages/Memo Communication/Automated Reminders and Notifications.png"
import brandcust from "../assets/images/HCM-pages/Memo Communication/Branding and Customization.png"
import empdirec from "../assets/images/HCM-pages/Memo Communication/Integration with Employee Directory.png"
import prebuiltmemo from "../assets/images/HCM-pages/Memo Communication/Pre-Built Memo Templates.png"
import rolebase from "../assets/images/HCM-pages/Memo Communication/Role-Based Access Control.png"

// for attendance
import autoalert from "../assets/images/HCM-pages/Attendance Management Systems/Automated Alerts and Notifications.png"
import custreport from "../assets/images/HCM-pages/Attendance Management Systems/Customizable Reports and Analytics.png"
import geolocationTrack from "../assets/images/HCM-pages/Attendance Management Systems/Geolocation Tracking.png"
import mobInte from "../assets/images/HCM-pages/Attendance Management Systems/Mobile App Integration.png"
import multipleTrack from "../assets/images/HCM-pages/Attendance Management Systems/Multiple Time Tracking Options.png"

// for letterform
import elecSig from "../assets/images/HCM-pages/LettersForms/Electronic Signature Capabilities.png"
import tempBuild from "../assets/images/HCM-pages/LettersForms/Intuitive Template Builder.png"
import roleBase from "../assets/images/HCM-pages/LettersForms/Role-Based Access Control.png"
import seamlessInt from "../assets/images/HCM-pages/LettersForms/Seamless Integration.jpg"
import versionCtrl from "../assets/images/HCM-pages/LettersForms/Version Control and Audit Trails.png"

// for Decision making
import decOne from "../assets/images/HCM-pages/Decision Management/Integration with HR Data Sources.png"
import decTwo from "../assets/images/HCM-pages/Decision Management/Intuitive Rule Builder.png"
import decThree from "../assets/images/HCM-pages/Decision Management/Machine Learning Integration.png"
import decFour from "../assets/images/HCM-pages/Decision Management/Scenario Modeling and Simulation.png"
import decFive from "../assets/images/HCM-pages/Decision Management/Version Control and Audit Trails.png"

// for customized level
import cusOne from "../assets/images/HCM-pages/Customizable Leave Management/Automated Workflows.jpg"
import cusTwo from "../assets/images/HCM-pages/Customizable Leave Management/Compliance Management.png"
import cusThree from "../assets/images/HCM-pages/Customizable Leave Management/Configurability.png"
import cusFour from "../assets/images/HCM-pages/Customizable Leave Management/Employee Self-Service.png"
import cusFive from "../assets/images/HCM-pages/Customizable Leave Management/Real-Time Reporting.png"

// for loan management
import loanOne from "../assets/images/HCM-pages/Loans Management/Configurable Loan Programs.jpg"
import loanTwo from "../assets/images/HCM-pages/Loans Management/Detailed Audit Trails and Reporting.png"
import loanThree from "../assets/images/HCM-pages/Loans Management/Employee Self-Service.png"
import loanFour from "../assets/images/HCM-pages/Loans Management/Integration with Payroll Systems.png"
import loanFive from "../assets/images/HCM-pages/Loans Management/Secure Data Storage and Access Control.jpg"

// for End-of-Service
import endOne from "../assets/images/HCM-pages/End-of-Service/Automated Benefit Termination Notifications.png"
import endTwwo from "../assets/images/HCM-pages/End-of-Service/Configurable EOS Checklists.png"
import endThree from "../assets/images/HCM-pages/End-of-Service/Electronic Signature Capture.png"
import endFour from "../assets/images/HCM-pages/End-of-Service/Integration with Payroll Systems.png"
import endFive from "../assets/images/HCM-pages/End-of-Service/Secure Data Storage and Access Control.jpg"


// for benefits expanses
import beneOne from "../assets/images/HCM-pages/Benefits & Expenses/Automated Reminders and Notifications.png"
import beneTwo from "../assets/images/HCM-pages/Benefits & Expenses/Configurable Benefits Plans.png"
import beneThree from "../assets/images/HCM-pages/Benefits & Expenses/Data Security and Encryption.png"
import beneFour from "../assets/images/HCM-pages/Benefits & Expenses/Flexible Expense Management Tools.png"
import beneFive from "../assets/images/HCM-pages/Benefits & Expenses/Mobile Accessibility.png"

// for dashboard
import dashOne from "../assets/images/HCM-pages/Insightful Dashboard/Alerts and Notifications.png"
import dashTwo from "../assets/images/HCM-pages/Insightful Dashboard/Data Filtering and Segmentation.png"
import dashThree from "../assets/images/HCM-pages/Insightful Dashboard/Drag-and-Drop Functionality.png"
import dashFour from "../assets/images/HCM-pages/Insightful Dashboard/Historical Data Comparison.png"
import dashFive from "../assets/images/HCM-pages/Insightful Dashboard/Wide Range of Data Connectors.png"

// for performance
import perOne from "../assets/images/HCM-pages/Performance Evaluation/360-Degree Feedback Tools.png"
import perTwo from "../assets/images/HCM-pages/Performance Evaluation/Configurable Performance Templates.png"
import perThree from "../assets/images/HCM-pages/Performance Evaluation/Goal Management and Tracking.png"
import perFour from "../assets/images/HCM-pages/Performance Evaluation/Mobile Accessibility.png"
import perFive from "../assets/images/HCM-pages/Performance Evaluation/Performance Reporting and Analytics.png"


// for Custody Management
import custOne from "../assets/images/HCM-pages/Custody Management/Audit Trail and Reporting Functionality.png"
import custTwo from "../assets/images/HCM-pages/Custody Management/Automated Alerts and Notifications.png"
import custThree from "../assets/images/HCM-pages/Custody Management/Configurable Asset Categories.png"
import custFour from "../assets/images/HCM-pages/Custody Management/Integration with Inventory Management Systems.png"
import custFive from "../assets/images/HCM-pages/Custody Management/Mobile Accessibility.png"

// for Automated Rules & Regulations
import autoOne from "../assets/images/HCM-pages/Automated Rules & Regulations/Configurable Alerts and Notifications.png"
import autoTwo from "../assets/images/HCM-pages/Automated Rules & Regulations/Integration with HR Modules.png"
import autoThree from "../assets/images/HCM-pages/Automated Rules & Regulations/Intuitive Rule Builder.png"
import autoFour from "../assets/images/HCM-pages/Automated Rules & Regulations/Regulatory Updates and Compliance Resources.png"
import autoFive from "../assets/images/HCM-pages/Automated Rules & Regulations/Version Control and Audit Trails.png"

//Job Portal
import jobOne from "../assets/images/HCM-pages/Job Portal/Automated Screening and Matching.png"
import jobTwwo from "../assets/images/HCM-pages/Job Portal/Branding and Career Page Customization.png"
import jobThree from "../assets/images/HCM-pages/Job Portal/Candidate Communication Tools.png"
import jobFour from "../assets/images/HCM-pages/Job Portal/Detailed Analytics and Reporting.png"
import jobFive from "../assets/images/HCM-pages/Job Portal/Targeted Job Distribution.png"

//Workflow & Approvals
import workOne from "../assets/images/HCM-pages/Workflow & Approvals/Conditional Routing Rules.png"
import workTwwo from "../assets/images/HCM-pages/Workflow & Approvals/Customizable Forms and Fields.png"
import workThree from "../assets/images/HCM-pages/Workflow & Approvals/Drag-and-Drop Workflow Builder.png"
import workFour from "../assets/images/HCM-pages/Workflow & Approvals/Integration with Other HCM Modules.png"
import workFive from "../assets/images/HCM-pages/Workflow & Approvals/Mobile Accessibility.png"



// payroll-management
const BreadCrumbpayroll = [
    {
        title: 'Payroll Management System',
    }
]
const payrollTwo = [

    {
        title: `Streamline Payroll and Empower Efficiency with Impulse HCM 360°'s Payroll Management System`,
        para: `Ensuring accurate and timely payroll processing is crucial for employee satisfaction, financial
         health, and legal compliance. Impulse HCM 360°'s Payroll Management System empowers businesses to automate
          payroll tasks, eliminate errors, and gain valuable insights into workforce costs.`,
        img: payroll1
    }
];
const payrollThird = [

    {
        head: 'Revolutionize Payroll Processing and Boost Efficiency',
        para: `Move beyond spreadsheets and manual calculations. Our comprehensive
         Payroll Management System offers a robust suite of functionalities to:`,

    }
];
const payrollFeatures = [

    {

        img: payroll2,
        title: 'Automated Payroll Processing',
        body: `Automate routine payroll tasks, such as calculating salaries, taxes, and deductions, for improved efficiency and reduced errors.`,
        reverse: false,
    },
    {
        img: payroll3,
        title: 'Flexible Pay Rules and Calculations',
        body: 'Configure the system to handle various pay structures, overtime calculations, bonuses, and deductions based on your specific needs.',
        reverse: true,
    },
    {
        img: payroll4,
        title: `Multiple Payment Options`,
        body: `Offer employees convenient payment options, including direct deposit, checks, or prepaid cards, with automated disbursement.`,
        reverse: false,
    },
    {
        img: payroll2,
        title: `Integrated Tax Filing and Reporting`,
        body: `Seamlessly handle tax calculations, deductions, and electronic filing for federal, state, and local taxes.`,
        reverse: true,
    },
    {
        img: payroll3,
        title: `Real-Time Reporting and Analytics`,
        body: `Gain real-time insights into payroll costs, employee earnings, and tax liabilities for informed decision-making.`,
        reverse: false,
    },
];
const payrollKeyFeatures = [
    {
        id: '1',
        title: `User-Friendly Interface`,
        description: `Navigate the system with ease using an intuitive interface designed for both HR professionals and employees.`,
        image: Image1
    },
    {
        id: '2',
        title: `Employee Self-Service Portal`,
        description: `Empower employees to access paystubs, W-2s, tax information, and request time off directly through the self-service portal.`,
        image: EmployeeSelf
    },
    {
        id: '3',
        title: `Integration with Time and Attendance Systems`,
        description: `Seamlessly integrate with time and attendance systems to ensure accurate payroll calculations based on actual hours worked.`,
        image: integration
    },
    {
        id: '4',
        title: `Data Security and Compliance`,
        description: `Maintain the highest level of data security with robust encryption and ensure adherence to all relevant payroll regulations.`,
        image: dataSecurity
    },
    {
        id: '5',
        title: `Configurable Alerts and Notifications`,
        description: `Set up automated alerts for critical events such as missed punches or upcoming tax deadlines.`,
        image: configAlert
    }
];
const payrollTitles = [
    {
        title: "Key Features of Impulse HCM 360°'s Payroll Management System",
    },
];
const payrollbutton = [
    {
        title: "Take Control of Your Payroll Today",
    },
];
const SeoPayroll = [
    {
        id: 1,
        title: `Best HR and Payroll Management Software, simple, cheap and easy.`,
        content: ` Impulse-HCM 360° is one of the best HR and employee Payroll Management Software. Find an all-in-one HR management solution, and User-Friendly Interface.`,
        link: ` https://www.zaisystems.com/impulse-hcm-360/payroll-management`
    }
]
// attendance-management
const BreadCrumbattendance = [
    {
        title: `Attendance Management System`,
    }
]
const attendanceTwo = [

    {
        title: `Ensure Accurate Time Tracking and Boost Productivity with Impulse HCM 360°'s Attendance Management System`,
        para: `Managing employee attendance effectively is crucial for optimizing labor costs, ensuring fair scheduling, and fostering a culture of accountability. Impulse HCM 360°'s Attendance Management System empowers businesses
         to streamline time tracking, gain real-time insights into workforce productivity, and enforce consistent attendance policies.`,
        img: attendance1
    }
];
const attendanceThird = [

    {
        head: `Revolutionize Time Tracking and Gain Control Over Labor Costs`,
        para: `Move beyond manual time sheets and buddy punching. Our Attendance Management System offers a comprehensive solution to:`,

    }
];
const attendanceFeatures = [

    {
        img: attendance2,
        title: `Automated Time Tracking`,
        body: `Implement various time tracking methods, including fingerprint scanners, facial recognition, or integration with mobile apps, for accurate and
          effortless time recording.`,
        reverse: false,
    },
    {
        img: attendance3,
        title: `Flexible Scheduling and Overtime Management`,
        body: `Facilitate flexible scheduling options and automate overtime calculations to ensure compliance with labor laws.`,
        reverse: true,
    },
    {
        img: attendance4,
        title: `Real-Time Attendance Monitoring`,
        body: `Gain real-time visibility into employee attendance data, including clock-in/out times, breaks, and absences, for informed decision-making.`,
        reverse: false,
    },
    {
        img: attendance5,
        title: `Leave Management Integration`,
        body: `Seamlessly integrate with leave management modules to track leave requests, approvals, and remaining leave balances for each employee.`,
        reverse: true,
    },
    {
        img: attendance6,
        title: `Data-Driven Workforce Insights`,
        body: `Gain valuable insights into workforce trends, identify areas for improvement, and optimize scheduling for better resource allocation.`,
        reverse: false,
    },
];
const attendanceKeyFeatures = [
    {
        id: '1',
        title: `Multiple Time Tracking Options`,
        description: `Choose from a variety of time tracking methods to suit your specific needs and workplace environment.`,
        image: multipleTrack
    },
    {
        id: '2',
        title: `Geolocation Tracking `,
        description: `Enable optional features like geofencing to track employee location during working hours if relevant to your business.`,
        image: geolocationTrack
    },
    {
        id: '3',
        title: `Automated Alerts and Notifications`,
        description: `Set up automated alerts for missed punches, late arrivals, or overtime to address potential issues promptly.`,
        image: autoalert
    },
    {
        id: '4',
        title: `Customizable Reports and Analytics`,
        description: `Generate customizable reports to analyze attendance trends, identify patterns, and track project-specific time allocation.`,
        image: custreport
    },
    {
        id: '5',
        title: `Mobile App Integration`,
        description: `Empower employees to track their time, request leave, and view schedules conveniently through a mobile app.`,
        image: mobInte
    }
];
const attendanceTitles = [
    {
        title: "Key Features of Impulse HCM 360°'s Attendance Management System",
    },
];
const attendancebutton = [
    {
        title: "Take Control of Your Workforce Time Tracking Today",
    },
];
const Seoattendance = [
    {
        id: 1,
        title: `Best Attendance Management System for employee, Automated Tracking`,
        content: `Track employee attendance and time with Impulse-HCM 360° software, Real-time automated reporting, and customizable solutions.`,
        link: `https://www.zaisystems.com/impulse-hcm-360/attendance-management`
    }
]
// customizable-leave-management
const BreadCrumbcustomizable = [
    {
        title: `Customizable Leave Management `,
    }
]
const customizableTwo = [

    {
        title: `Conquer Work-Life Balance with Impulse HCM 360°'s Customizable Leave Management System`,
        para: `Fostering a healthy work-life balance for employees is crucial. A key element in achieving this balance is a streamlined and customizable leave management system. Impulse HCM 360° offers a comprehensive solution that empowers businesses of all sizes
         to streamline leave requests, approvals, and tracking, ensuring a smooth and efficient experience for both employees and HR teams.`,
        img: leave1
    }
];
const customizableThird = [

    {
        head: `Streamlined Leave Management for the Modern Workplace`,
        para: `
        Impulse HCM 360°'s leave management system goes beyond just vacation tracking.
         It provides a centralized platform for managing a wide range of leave types, including:`,

    }
];
const customizableFeatures = [

    {
        img: leave2,
        title: `Vacation and Sick Leave`,
        body: `Easily track employee vacation days and sick leave accruals, ensuring compliance with company policies.`,
        reverse: false,
    },
    {
        img: leave3,
        title: `Personal Leave`,
        body: `Empower employees to request time off for personal reasons with a streamlined process.`,
        reverse: true,
    },
    {
        img: leave4,
        title: `Medical Leave`,
        body: `Manage medical leave requests efficiently, ensuring employees receive the support they need during challenging times.`,
        reverse: false,
    },
    {
        img: leave2,
        title: `Bereavement Leave`,
        body: `Provide a compassionate and streamlined process for employees grieving the loss of a loved one.`,
        reverse: true,
    },
    {
        img: leave3,
        title: `Maternity/Paternity Leave`,
        body: `Simplify the management of maternity and paternity leave requests, ensuring a smooth transition for new parents.`,
        reverse: false,
    },
];
const customizableKeyFeatures = [
    {
        id: '1',
        title: `Configurability`,
        description: `Tailor leave policies to your specific business needs, including accrual rates, carry-over rules, and eligibility criteria.`,
        image: cusThree
    },
    {
        id: '2',
        title: `Automated Workflows`,
        description: `Automate leave request routing and approvals for faster processing times and reduced administrative burden.`,
        image: cusOne
    },
    {
        id: '3',
        title: `Employee Self-Service`,
        description: `Empower employees to submit leave requests, view their leave balances, and track the status of their requests through a user-friendly portal.`,
        image: cusFour
    },
    {
        id: '4',
        title: `Real-Time Reporting`,
        description: `Gain valuable insights into leave trends and patterns with comprehensive reports and dashboards.`,
        image: cusFive
    },
    {
        id: '5',
        title: `Compliance Management`,
        description: `Ensure compliance with local labor laws and regulations regarding leave entitlements.`,
        image: cusTwo
    }
];
const customizableTitles = [
    {
        title: "Key Features of Impulse HCM 360°'s Customizable Leave Management System",
    },
];
const customizablebutton = [
    {
        title: "Empower Your Workforce and Simplify Leave Management Today",
    },
];
const Seocustomizable = [
    {
        id: 1,
        title: `Modern Leave Management software | Impulse HCM 360°`,
        content: `Impulse HCM 360° is the complete solution of employee Leave Management System, modern and comprehensive software.`,
        link: `https://www.zaisystems.com/impulse-hcm-360/leave-management`

    }
]
// employee-self-service
const BreadCrumbemployee = [
    {
        title: `Employee Self-Service `,
    }
]
const employeeTwo = [

    {
        title: `Empower Your Workforce with Impulse HCM 360°'s Employee Self-Service Portal`,
        para: `Employees expect convenient and anytime access to their HR information and tasks. Impulse HCM 360°'s Employee Self-Service (ESS) portal empowers your workforce with a centralized
         platform to manage various HR functions, fostering a sense of autonomy and streamlining HR processes for your organization.`,
        img: employee1
    }
];
const employeeThird = [

    {
        head: `Revolutionize HR with a Feature-Rich Employee Self-Service Portal`,
        para: `Impulse HCM 360°'s ESS portal goes beyond basic paystub access. It provides
         a comprehensive suite of features that empower employees to take charge of their HR experience, including:`,

    }
];
const employeeFeatures = [

    {
        img: employee2,
        title: `Personal Information Management`,
        body: `Employees can view and update their contact details, 
        emergency contacts, and other personal information, ensuring data accuracy within the HR system.`,
        reverse: false,
    },
    {
        img: employee3,
        title: `Paystub Access`,
        body: `Gain convenient 24/7 access to paystubs, tax documents, and other earnings information.`,
        reverse: true,
    },
    {
        img: employee4,
        title: `Leave Management`,
        body: `Submit leave requests, view leave balances, and track the status of their requests,
         promoting transparency and reducing reliance on HR for basic inquiries.`,
        reverse: false,
    },
    {
        img: employee2,
        title: `Time Tracking`,
        body: `Employees can submit timesheets or view their logged hours, ensuring accurate payroll processing.`,
        reverse: true,
    },
    {
        img: employee3,
        title: `Benefits Enrollment`,
        body: `Manage benefit selections and update beneficiary information during open enrollment periods or as needed.`,
        reverse: false,
    },
    {
        img: employee4,
        title: `Document Management`,
        body: `Access important company documents, handbooks, and policies in a centralized location.`,
        reverse: true,
    }, {
        img: employee2,
        title: `Company Directory`,
        body: `Easily search for and connect with colleagues across departments.`,
        reverse: false,
    }, {
        img: employee3,
        title: `Manager Communication`,
        body: `Facilitate secure communication with managers regarding basic inquiries or feedback.`,
        reverse: true,
    },
];

const employeebutton = [
    {
        title: "Invest in a Self-Service Solution for a More Efficient Workplace",
    },
];
const Seoemployee = [
    {
        id: 1,
        title: `Best Employee Self-service management software (ESS)`,
        content: `One of the best employee self-service management systems is Impulse HCM 360°, which streamlines the HR processes of any organization.`,
        link: `https://www.zaisystems.com/impulse-hcm-360/self-service-management`

    }
]
// performance-evaluation
const BreadCrumbperformance = [
    {
        title: `Performance Evaluation `,
    }
]
const performanceTwo = [

    {
        title: `Empower Growth and Development with Impulse HCM 360°'s Performance Evaluation System`,
        para: `
        Fostering a culture of continuous improvement is essential for organizational success. Performance evaluation plays
         a critical role in this process by providing valuable insights into employee strengths, weaknesses, and development opportunities. Impulse HCM 360°'s comprehensive performance evaluation
         system empowers businesses to streamline the evaluation process, drive employee engagement, and achieve strategic objectives.`,
        img: attendance1
    }
];
const performanceThird = [

    {
        head: `Revolutionize Performance Management with Impulse HCM 360°`,
        para: `Move beyond outdated annual reviews. Our performance evaluation system offers a robust and flexible solution to:`,

    }
];
const performanceFeatures = [

    {
        img: attendance2,
        title: `Establish Clear Performance Goals`,
        body: `Set SMART goals (Specific, Measurable, Achievable, Relevant, and Time-bound) that align with individual and organizational objectives.`,
        reverse: false,
    },
    {
        img: attendance3,
        title: `Conduct Regular Performance Reviews`,
        body: `Facilitate regular feedback sessions throughout the year, fostering ongoing communication and development.`,
        reverse: true,
    },
    {
        img: attendance4,
        title: `Provide Multi-Source Feedback`,
        body: `Incorporate feedback from managers, peers, and even self-evaluations for a well-rounded perspective on employee performance.`,
        reverse: false,
    },
    {
        img: attendance5,
        title: `Track Progress and Development`,
        body: `Monitor employee progress towards goals and identify areas for improvement through ongoing feedback and coaching conversations.`,
        reverse: true,
    },
    {
        img: attendance6,
        title: `Align Performance with Rewards and Recognition`,
        body: ` Link performance evaluations to compensation decisions, promotions, and recognition programs to motivate employees and reinforce desired behaviors.`,
        reverse: false,
    },
];
const performanceKeyFeatures = [
    {
        id: '1',
        title: `Configurable Performance Templates`,
        description: `Design custom evaluation templates with relevant criteria specific to different roles and departments.`,
        image: perTwo
    },
    {
        id: '2',
        title: `360-Degree Feedback Tools`,
        description: `Facilitate the collection of multi-source feedback through user-friendly surveys and questionnaires.`,
        image: perOne
    },
    {
        id: '3',
        title: `Goal Management and Tracking`,
        description: `Set, track, and measure progress towards individual and team goals with intuitive goal management tools.`,
        image: perThree
    },
    {
        id: '4',
        title: `Performance Reporting and Analytics`,
        description: `Gain valuable insights into workforce performance trends and identify areas for improvement through comprehensive reports and dashboards.`,
        image: perFive
    },
    {
        id: '5',
        title: `Mobile Accessibility`,
        description: `Conduct performance reviews and provide feedback on the go with our mobile-friendly platform.`,
        image: perFour
    }
];
const performanceTitles = [
    {
        title: "Key Features of Impulse HCM 360°'s Performance Evaluation System",
    },
];
const performancebutton = [
    {
        title: "Invest in Your Workforce and Reap the Rewards of Effective Performance Management",
    },
];
const Seoperformance = [
    {
        id: 1,
        title: `Best Employee Evaluation System Software 2024 | Impulse HCM 360°`,
        content: `Impulse HCM 360° is one of the best employee evolution software, providing multiple-source feedback, and progress, development, and reward systems.`,
        link: `https://www.zaisystems.com/impulse-hcm-360/employee-evaluation-management`

    }
]
// job-portal
const BreadCrumbjob = [
    {
        title: `Job Portal `,
    }
]
const jobTwo = [

    {
        title: `Attract Top Talent with Impulse HCM 360°'s Powerful Job Portal`,
        para: `In today's competitive job market, attracting and retaining qualified talent is crucial for business success. A well-designed job portal is an essential tool for streamlining your recruitment process and showcasing your employer brand to a wider audience. Impulse HCM 360°'s comprehensive job portal empowers
         businesses to efficiently manage recruitment efforts, attract high-potential candidates, and build a strong employer brand.`,
        img: job1
    }
];
const jobThird = [

    {
        head: `Revolutionize Your Recruitment Strategy with Impulse HCM 360°`,
        para: `Our feature-rich job portal goes beyond simply posting job openings. It offers a comprehensive suite 
        of functionalities to help you:`,

    }
];
const jobFeatures = [

    {
        img: job2,
        title: `Create Compelling Job Postings`,
        body: `Craft engaging job descriptions that highlight your company culture, values, and career opportunities to attract the right candidates.`,
        reverse: false,
    },
    {
        img: job2,
        title: `Simplify Candidate Application Process`,
        body: `Provide a user-friendly platform for candidates to submit applications and resumes with ease, minimizing application abandonment.`,
        reverse: true,
    },
    {
        img: job2,
        title: `Manage Candidate Pool Efficiently`,
        body: `Organize and track applications through various stages of the recruitment process with an intuitive applicant tracking system (ATS).`,
        reverse: false,
    },
    {
        img: job2,
        title: `Leverage Social Media Integration`,
        body: `Seamlessly share job openings across popular social media platforms to expand your reach and attract a wider pool of talent.`,
        reverse: true,
    },
    {
        img: job2,
        title: `Mobile-Friendly Application`,
        body: `Ensure a smooth application experience for candidates on any device, maximizing accessibility and attracting tech-savvy talent.`,
        reverse: false,
    },
];
const jobKeyFeatures = [
    {
        id: '1',
        title: `Targeted Job Distribution`,
        description: `Publish job postings on major job boards and niche platforms relevant to your industry and target audience.`,
        image: jobFive
    },
    {
        id: '2',
        title: `Branding and Career Page Customization`,
        description: `Showcase your company culture and employer brand through a visually appealing careers page that resonates with potential candidates.`,
        image: jobTwwo
    },
    {
        id: '3',
        title: `Automated Screening and Matching`,
        description: ` Utilize customizable screening questions and skills assessments to shortlist qualified candidates efficiently.`,
        image: jobOne
    },
    {
        id: '4',
        title: `Candidate Communication Tools`,
        description: `Communicate effectively with candidates throughout the recruitment process with automated email notifications and interview scheduling functionalities.`,
        image: jobThree
    },
    {
        id: '5',
        title: `Detailed Analytics and Reporting`,
        description: ` Gain valuable insights into job posting performance, candidate demographics, and source effectiveness to optimize your recruitment strategy.`,
        image: jobFour
    }
];
const jobTitles = [
    {
        title: "Key Features of Impulse HCM 360°'s Job Portal",
    },
];
const jobbutton = [
    {
        title: "Build a Talent Pipeline and Fuel Your Business Growth",
    },
];
const Seojob = [
    {
        id: 1,
        title: `Best Job board software for recruitment | impulse HCM 360°`,
        content: `Revolutionize Recruitment Strategy with Impulse HCM 360°, attract top talent, create compelling job postings, and simplify candidate’s application process.`,
        link: `https://www.zaisystems.com/impulse-hcm-360/job-portal-management`

    }
]
// task-management
const BreadCrumbtask = [
    {
        title: `Task Management `,
    }
]
const taskTwo = [

    {
        title: `Boost Productivity and Streamline Workflows with Impulse HCM 360°'s Task Management System`,
        para: `Staying organized and on top of tasks is essential for individual and team success. Impulse HCM 360°'s robust task management
         system empowers businesses to streamline workflows, improve accountability, and achieve organizational goals more efficiently.`,
        img: employee1
    }
];
const taskThird = [

    {
        head: `Unleash Peak Productivity with a Powerful Task Management Solution`,
        para: `Move beyond overwhelming to-do lists and missed deadlines. Impulse HCM 360° offers a comprehensive solution to:`,

    }
];
const taskFeatures = [

    {
        img: employee2,
        title: `Assign Tasks and Track Progress`,
        body: `Clearly assign tasks to individuals or teams, set deadlines, and monitor progress in real-time for enhanced accountability.`,
        reverse: false,
    },
    {
        img: employee3,
        title: `Prioritize Work Effectively`,
        body: `Utilize prioritization tools and Kanban boards to visualize workflows, identify critical tasks, and ensure focus on high-impact activities.`,
        reverse: true,
    },
    {
        img: employee4,
        title: `Promote Collaboration and Communication`,
        body: `Facilitate seamless collaboration on projects through integrated communication tools and shared task lists.`,
        reverse: false,
    },
    {
        img: employee2,
        title: `Improve Transparency and Visibility`,
        body: ` Provide clear visibility into individual and team workloads for better resource allocation and project management.`,
        reverse: true,
    },
    {
        img: employee3,
        title: `Enhance Employee Engagement`,
        body: `Empower employees to take ownership of their tasks and track their progress towards goals, fostering a sense of accomplishment.`,
        reverse: false,
    },
];
const taskKeyFeatures = [
    {
        id: '1',
        title: `Intuitive Interface`,
        description: `A user-friendly platform allows for easy task creation, assignment, and progress tracking.`,
        image: interfaceimg
    },
    {
        id: '2',
        title: `Dependency Management`,
        description: ` Establish task dependencies to ensure a logical workflow and avoid bottlenecks.`,
        image: Dependency
    },
    {
        id: '3',
        title: `Automated Reminders and Notifications`,
        description: `Stay on top of deadlines with automated reminders and notifications for assigned tasks.`,
        image: accessibility
    },
    {
        id: '4',
        title: `File Sharing and Attachment Capabilities`,
        description: `Attach relevant documents and files to tasks for easy access and collaboration.`,
        image: fileshare
    },
    {
        id: '5',
        title: `Mobile Accessibility`,
        description: `Manage tasks and collaborate with team members on the go with our mobile-friendly platform.`,
        image: automated
    }
];
const taskTitles = [
    {
        title: "Key Features of Impulse HCM 360°'s Task Management System",
    },
];
const taskbutton = [
    {
        title: "Empower Your Teams and Achieve More Today",
    },
];
const Seotask = [
    {
        id: 1,
        title: `Best Tasks Management Software for teams | Impulse HCM 360°`,
        content: `Plan and manage your team’s tasks with impulse HCM 360°. Assign tasks and track progress, improve transparency and visibility.`,
        link: `https://www.zaisystems.com/impulse-hcm-360/tasks-management`

    }
]
// letter-and-forms
const BreadCrumbletters = [
    {
        title: `Letters & Forms `,
    }
]
const lettersTwo = [

    {
        title: `Simplify Document Management with Impulse HCM 360°'s Letters & Forms Solution`,
        para: `
        Managing essential HR documents can be a time-consuming and cumbersome process. Impulse HCM 360°'s Letters & Forms solution empowers businesses to streamline document creation,
         distribution, and storage, saving valuable time and ensuring all HR documents are readily accessible.`,
        img: letter1
    }
];
const lettersThird = [

    {
        head: `Revolutionize Document Management for a Paperless HR Experience`,
        para: `Move beyond overflowing filing cabinets and lost paperwork. Our Letters & Forms solution offers a comprehensive suite of functionalities to:`,

    }
];
const lettersFeatures = [

    {
        img: letter2,
        title: `Create Standardized Letters`,
        body: `Utilize pre-built templates for common HR 
        documents such as offer letters, performance reviews, and termination notices, ensuring consistency and compliance.`,
        reverse: false,
    },
    {
        img: letter3,
        title: `Customize Templates`,
        body: `Easily modify existing templates or create custom letters to meet your specific needs and company branding.`,
        reverse: true,
    },
    {
        img: letter4,
        title: `Merge Data with Ease`,
        body: `Utilize mail merge functionality to automatically populate letters with employee information, saving time and minimizing errors.`,
        reverse: false,
    },
    {
        img: letter2,
        title: `Streamline Form Management`,
        body: `Create, distribute, and collect electronic forms for various HR purposes, such as benefit enrollment or time-off requests.`,
        reverse: true,
    },
    {
        img: letter3,
        title: `Secure Document Storage and Access`,
        body: `Store all HR documents securely in a centralized, cloud-based repository for easy access by authorized personnel.`,
        reverse: false,
    },
];
const lettersKeyFeatures = [
    {
        id: '1',
        title: `Intuitive Template Builder`,
        description: `A user-friendly interface allows for easy creation and customization of letters and forms.`,
        image: tempBuild
    },
    {
        id: '2',
        title: `Electronic Signature Capabilities`,
        description: `Facilitate electronic signing of documents for a faster and more efficient approval process.`,
        image: elecSig
    },
    {
        id: '3',
        title: `Version Control and Audit Trails`,
        description: `Maintain a clear audit trail of all document revisions and ensure access to the most up-to-date versions.`,
        image: versionCtrl
    },
    {
        id: '4',
        title: `Role-Based Access Control`,
        description: `Enforce data security by granting access to documents based on user roles and permissions within the organization.`,
        image: roleBase
    },
    {
        id: '5',
        title: `Seamless Integration`,
        description: `Integrate seamlessly with Impulse HCM 360°'s other HR modules for a unified and efficient experience.`,
        image: seamlessInt
    }
];
const lettersTitles = [
    {
        title: "Key Features of Impulse HCM 360°'s Letters & Forms Solution",
    },
];
const lettersbutton = [
    {
        title: "Simplify Your Paperwork and Embrace a Digital HR Future Today",
    },
];
const Seoletters = [
    {
        id: 1,
        title: `Letter and Forms software for HR management | Impulse HCM 360°`,
        content: `Create standardized letter and form for HR with customized templates of Impulse HCM 360° software, having role-based access control.`,
        link: `https://www.zaisystems.com/impulse-hcm-360/letters-forms-management`

    }
]
// loans-management
const BreadCrumbloans = [
    {
        title: `Loans Management `,
    }
]
const loansTwo = [

    {
        title: `Empower Your Workforce with Streamlined Loan Management through Impulse HCM 360°`,
        para: `Offering employee benefits like company-sponsored loans can be a significant differentiator in attracting and retaining top talent. However, managing loan applications, approvals, and repayments can be a complex and time-consuming process. Impulse HCM 360°'s Loan Management system 
        empowers businesses to streamline the entire loan lifecycle, improve administrative efficiency, and enhance the employee experience.`,
        img: loan1
    }
];
const loansThird = [

    {
        head: `Simplify Loan Management and Empower Your People`,
        para: `Move beyond manual spreadsheets and tedious paperwork. Our Loan Management system offers a comprehensive solution to:`,

    }
];
const loansFeatures = [

    {
        img: loan2,
        title: `Offer Diverse Loan Programs`,
        body: `Design and manage various loan programs such as emergency loans, 
        education loans, and home improvement loans to cater to your employees' unique needs.`,
        reverse: false,
    },
    {
        img: loan3,
        title: `Streamline Application Process`,
        body: `Facilitate a user-friendly online application process for employees to request loans efficiently.`,
        reverse: true,
    },
    {
        img: loan4,
        title: `Automate Workflows`,
        body: `Automate loan application routing, approval processes, and notification systems to minimize administrative burden.`,
        reverse: false,
    },
    {
        img: loan2,
        title: `Manage Repayments Effectively`,
        body: `Deduct loan repayments conveniently through payroll integration, ensuring timely repayments and reducing delinquency rates.`,
        reverse: true,
    },
    {
        img: loan3,
        title: `Generate Detailed Reports and Track Performance`,
        body: `Gain valuable insights into loan program performance through comprehensive reports and data analysis.`,
        reverse: false,
    },
];
const loansKeyFeatures = [
    {
        id: '1',
        title: `Configurable Loan Programs`,
        description: ` Define eligibility criteria, interest rates, repayment terms, and maximum loan amounts for each loan program.`,
        image: loanOne
    },
    {
        id: '2',
        title: `Employee Self-Service Portal`,
        description: `Empower employees to view their loan history, track repayment progress, and access loan-related documents through a secure self-service portal.`,
        image: loanThree
    },
    {
        id: '3',
        title: `Secure Data Storage and Access Control`,
        description: `Ensure the confidentiality and security of employee loan data with robust data encryption and role-based access control.`,
        image: loanFive
    },
    {
        id: '4',
        title: `Integration with Payroll Systems`,
        description: `Seamlessly integrate with existing payroll systems to automate loan repayments and maintain accurate financial records.`,
        image: loanFour
    },
    {
        id: '5',
        title: `Detailed Audit Trails and Reporting`,
        description: ` Maintain a clear audit trail of all loan transactions and generate comprehensive reports for informed decision-making.`,
        image: loanTwo
    }
];
const loansTitles = [
    {
        title: "Key Features of Impulse HCM 360°'s Loan Management System",
    },
];
const loansbutton = [
    {
        title: "Invest in Your Workforce's Financial Wellbeing Today",
    },
];
const Seoloans = [
    {
        id: 1,
        title: `Employee loan management software, advances & bonuses`,
        content: `Simplify the Loan Management System and empower the employee with Impulse HCM 360° software, streamline application & manage repayments.`,
        link: `https://www.zaisystems.com/impulse-hcm-360/loan-management`

    }
]
// benefits-and-expenses-tracking
const BreadCrumbbenefits = [
    {
        title: `Benefits & Expenses `,
    }
]
const benefitsTwo = [

    {
        title: `Simplify Benefits Administration and Expense Management with Impulse HCM 360°`,
        para: `
        Offering a comprehensive benefits package and managing employee expenses effectively are crucial for attracting and retaining top talent. However, juggling complex benefits administration and manual expense reports can be a time-consuming burden for both HR teams and employees. Impulse HCM 360°'s integrated Benefits
         & Expenses solution empowers businesses to streamline these processes, improve accuracy, and enhance the employee experience.`,
        img: benefits1
    }
];
const benefitsThird = [

    {
        head: `Revolutionize Benefits & Expense Management with a Unified Platform`,
        para: `
        Move beyond disconnected systems and cumbersome paper trails. Our Benefits & Expenses solution offers a powerful suite of functionalities to:`,

    }
];
const benefitsFeatures = [

    {

        img: benefits2,
        title: `Centralized Benefits Management`,
        body: `
         Simplify enrollment, communication, and ongoing administration of all employee benefit programs from a single platform.`,
        reverse: false,
    },
    {
        img: benefits3,
        title: `Benefit Cost Management`,
        body: `
        Gain valuable insights into benefit costs and utilization patterns to make informed decisions about future offerings.`,
        reverse: true,
    },
    {
        img: benefits4,
        title: `Employee Self-Service Portal`,
        body: `
         Empower employees to access benefit information, make enrollment changes, and view plan details through a secure self-service portal.`,
        reverse: false,
    },
    {
        img: benefits2,
        title: `Effortless Expense Reporting`,
        body: `
        Facilitate a user-friendly platform for employees to submit expense reports with ease, including receipt capture through mobile apps.`,
        reverse: true,
    },
    {
        img: benefits3,
        title: `Real-Time Visibility and Reporting`,
        body: `
        Track expense trends, monitor budgets, and gain valuable insights into spending patterns with comprehensive reports and dashboards.`,
        reverse: false,
    },
];
const benefitsKeyFeatures = [
    {
        id: '1',
        title: `Configurable Benefits Plans`,
        description: `Design and manage custom benefits plans that cater to your organization's specific needs and budget.`,
        image: beneTwo
    },
    {
        id: '2',
        title: `Flexible Expense Management Tools`,
        description: `Set customizable expense categories, per diem rates, and approval workflows to suit your business policies.`,
        image: beneFour
    },
    {
        id: '3',
        title: `Data Security and Encryption`,
        description: `Ensure the confidentiality and security of sensitive employee benefit and expense data with robust security measures.`,
        image: beneThree
    },
    {
        id: '4',
        title: `Mobile Accessibility`,
        description: `Enable employees to submit expense reports and access benefits information on the go with our mobile-friendly platform.`,
        image: beneFive
    },
    {
        id: '5',
        title: `Automated Reminders and Notifications`,
        description: `Streamline communication by sending automated reminders for upcoming deadlines and benefit enrollment periods.`,
        image: beneOne
    }
];
const benefitsTitles = [
    {
        title: "Key Features of Impulse HCM 360°'s Benefits & Expenses Solution",
    },
];
const benefitsbutton = [
    {
        title: "Empower Your Employees and Optimize Your HR Processes Today",
    },
];
const Seobenefits = [
    {
        id: 1,
        title: `Employee Benefits and expenses administration software 2024`,
        content: `Impulse HCM 360° a comprehensive benefits and managing employee expenses software, compliance management & integration with payroll system.`,
        link: `https://www.zaisystems.com/impulse-hcm-360/benefits-expenses-management`
    }
]
// custody-management
const BreadCrumbcustody = [
    {
        title: `Custody Management `,
    }
]
const custodyTwo = [

    {
        title: `Ensure Transparency and Accountability with Impulse HCM 360°'s Custody Management System`,
        para: `
        Managing company assets entrusted to employees is crucial for maintaining operational efficiency and minimizing loss. Impulse HCM 360's Custody Management System empowers businesses to streamline the process of 
        requesting, tracking, and managing company property, ensuring transparency and accountability throughout the lifecycle of an asset.`,
        img: leave1
    }
];
const custodyThird = [

    {
        head: `Revolutionize Asset Management and Foster Responsibility`,
        para: `
        Move beyond disorganized spreadsheets and lost equipment. Our Custody Management System offers a comprehensive solution to:`,

    }
];
const custodyFeatures = [

    {
        img: leave2,
        title: `Centralized Asset Tracking`,
        body: `Maintain a centralized repository of all company assets, including detailed descriptions, serial numbers, and assigned custodians.`,
        reverse: false,
    },
    {
        img: leave3,
        title: `Streamlined Request and Approval Process`,
        body: ` Facilitate a user-friendly platform for employees to request company property and managers to review and approve requests electronically.`,
        reverse: true,
    },
    {
        img: leave4,
        title: `Automated Check-In/Check-Out`,
        body: `Simplify asset tracking with automated check-in and check-out procedures, ensuring clear accountability for assigned equipment.`,
        reverse: false,
    },
    {
        img: leave2,
        title: `Real-Time Visibility and Reporting`,
        body: `Gain real-time insights into asset location, usage patterns, and upcoming renewal dates through comprehensive reports and dashboards.`,
        reverse: true,
    },
    {
        img: leave3,
        title: `Improved Security and Compliance`,
        body: ` Enhance security measures and ensure compliance with company policies for asset usage and maintenance.`,
        reverse: false,
    },
];
const custodyKeyFeatures = [
    {
        id: '1',
        title: `Configurable Asset Categories`,
        description: `Define different categories of assets, such as laptops, tools, or company vehicles, with specific tracking requirements.`,
        image: cusThree
    },
    {
        id: '2',
        title: `Automated Reminders and Notifications`,
        description: `Send automated reminders to employees for upcoming asset check-ins, renewals, or maintenance schedules.`,
        image: custTwo
    },
    {
        id: '3',
        title: `Mobile Accessibility`,
        description: `Enable employees to request and manage assigned assets on the go with our mobile-friendly platform.`,
        image: custFive
    },
    {
        id: '4',
        title: `Integration with Inventory Management Systems`,
        description: ` Seamlessly integrate with existing inventory management systems for comprehensive asset lifecycle management.`,
        image: custFour
    },
    {
        id: '5',
        title: `Audit Trail and Reporting Functionality`,
        description: ` Maintain a clear audit trail of all asset movements and generate detailed reports for enhanced decision-making.`,
        image: custThree
    }
];
const custodyTitles = [
    {
        title: "Key Features of Impulse HCM 360°'s Custody Management System",
    },
];
const custodybutton = [
    {
        title: "Take Control of Your Company Assets Today",
    },
];
const Seocustody = [
    {
        id: 1,
        title: `Impulse HCM 360 HR Custody Management System`,
        content: `Ensure Accountability with Impulse HCM 360°'s Custody Management System, Centralized Asset Tracking, Real-Time Visibility and Reporting.`,
        link: `https://www.zaisystems.com/impulse-hcm-360/custody-management`

    }
]
// workflow-and-approvals
const BreadCrumbworkflow = [
    {
        title: `Workflow & Approvals `,
    }
]
const workflowTwo = [

    {
        title: `Streamline Operations and Boost Efficiency with Impulse HCM 360°'s Workflow & Approvals`,
        para: `
        Streamlining workflows and expediting approvals are crucial for maximizing productivity and achieving
         organizational goals. Impulse HCM 360°'s Workflow & Approvals solution empowers businesses to automate
          manual processes, establish clear approval chains, and gain real-time visibility into the progress of critical tasks.`,
        img: employee1
    }
];
const workflowThird = [

    {
        head: `Revolutionize Workflows and Empower Faster Decision-Making`,
        para: `
        Move beyond bottlenecks and email chains. Our Workflow & Approvals solution offers a comprehensive suite of functionalities to:`,

    }
];
const workflowFeatures = [

    {
        img: employee2,
        title: `Visual Workflow Mapping`,
        body: `Design and visualize clear workflows for various HR processes, such as leave requests, expense reports, or new hire onboarding.`,
        reverse: false,
    },
    {
        img: employee3,
        title: `Automated Routing and Approvals`,
        body: `Automate the routing of tasks and documents to designated approvers based on pre-defined rules and escalation procedures.`,
        reverse: true,
    },
    {
        img: employee4,
        title: `Configurable Approval Chains`,
        body: `Establish multi-level approval workflows with sequential or parallel approval requirements for different types of requests.`,
        reverse: false,
    },
    {
        img: employee2,
        title: `Real-Time Status Tracking and Notifications`,
        body: `Gain real-time insights into the status of workflows and receive automated notifications for pending approvals, ensuring timely action.`,
        reverse: true,
    },
    {
        img: employee3,
        title: `Improved Collaboration and Communication`,
        body: `Facilitate seamless communication between employees and approvers within the workflow platform.`,
        reverse: false,
    },
];
const workflowKeyFeatures = [
    {
        id: '1',
        title: `Drag-and-Drop Workflow Builder`,
        description: `Design intuitive workflows with a user-friendly drag-and-drop interface, eliminating the need for complex coding.`,
        image: workThree
    },
    {
        id: '2',
        title: `Customizable Forms and Fields`,
        description: `Create custom forms and define specific fields to gather necessary information for efficient decision-making within workflows.`,
        image: workTwwo
    },
    {
        id: '3',
        title: `Conditional Routing Rules`,
        description: `Set up dynamic routing rules based on specific criteria, automatically directing tasks to the appropriate approvers for faster turnaround times.`,
        image: workOne
    },
    {
        id: '4',
        title: `Mobile Accessibility`,
        description: `Enable employees and managers to submit requests, track approvals, and participate in workflows on the go with our mobile-friendly platform.`,
        image: workFive
    },
    {
        id: '5',
        title: `Integration with Other HCM Modules`,
        description: `Seamlessly integrate with other Impulse HCM 360° modules, such as leave management or expense management, for a unified HR experience.`,
        image: workFour
    }
];
const workflowTitles = [
    {
        title: "Key Features of Impulse HCM 360°'s Workflow & Approvals Solution",
    },
];
const workflowbutton = [
    {
        title: "Automate Your Workflows and Achieve Operational Excellence Today",
    },
];
const Seoworkflow = [
    {
        id: 1,
        title: `HCM 360° Best approval software for Workflow Management`,
        content: `Streamline Operations with Impulse HCM 360°'s Workflow & Approvals Management, visual workflow mapping, automated routing and approvals.`,
        link: `https://www.zaisystems.com/impulse-hcm-360/workflow-management`

    }
]
// memo-communication
const BreadCrumbmemo = [
    {
        title: `Memo Communication `,
    }
]
const memoTwo = [

    {
        title: `Simplify Internal Communication with Impulse HCM 360°'s Streamlined Memo System`,
        para: `
        Clear and efficient internal communication is essential for organizational alignment and employee engagement. Impulse HCM 360°'s Memo Communication system empowers businesses to streamline the creation,
         distribution, and tracking of internal memos, ensuring important information reaches the right people at the right time.`,
        img: memo1
    }
];
const memoThird = [

    {
        head: `Revolutionize Internal Communication and Foster Transparency`,
        para: `
        Move beyond cluttered email inboxes and outdated communication methods. Our Memo Communication system offers a comprehensive solution to:`,

    }
];
const memoFeatures = [

    {
        img: memo2,
        title: `Effortless Memo Creation`,
        body: `Utilize user-friendly templates and formatting options to create professional and visually appealing memos.`,
        reverse: false,
    },
    {
        img: memo2,
        title: `Targeted Distribution`,
        body: `Distribute memos to specific groups of employees, departments, or individuals based on pre-defined criteria.`,
        reverse: true,
    },
    {
        img: memo2,
        title: `Delivery Confirmation and Read Receipts`,
        body: ` Gain confirmation of successful memo delivery and track whether recipients have opened and read the content.`,
        reverse: false,
    },
    {
        img: memo2,
        title: `Centralized Repository and Archival`,
        body: ` Maintain a central repository for all internal memos, ensuring easy access and retrieval of past communications.`,
        reverse: true,
    },
    {
        img: memo2,
        title: `Enhanced Search Functionality`,
        body: ` Empower employees to easily search and locate relevant memos based on keywords, dates, or authors.`,
        reverse: false,
    },
];
const memoKeyFeatures = [
    {
        id: '1',
        title: `Pre-Built Memo Templates`,
        description: ` Utilize pre-designed templates for common memo types such as policy announcements,
         meeting agendas, or project updates, saving time and ensuring consistency.`,
        image: prebuiltmemo
    },
    {
        id: '2',
        title: `Branding and Customization`,
        description: ` Align memo templates with your company branding for a professional look and feel.`,
        image: brandcust
    },
    {
        id: '3',
        title: `Integration with Employee Directory`,
        description: `Seamlessly integrate with Impulse HCM 360°'s employee directory for effortless and accurate recipient selection.`,
        image: empdirec
    },
    {
        id: '4',
        title: `Role-Based Access Control`,
        description: `Enforce data security by providing access to specific memos based on user roles and permissions within the organization.`,
        image: rolebase
    },
    {
        id: '5',
        title: `Automated Reminders and Notifications`,
        description: ` Schedule automated reminders for employees to review important memos or deadlines.`,
        image: automated
    }
];
const memoTitles = [
    {
        title: "Key Features of Impulse HCM 360°'s Memo Communication System",
    },
];
const memobutton = [
    {
        title: "Boost Internal Communication and Drive Organizational Alignment Today",
    },
];
const Seomemo = [
    {
        id: 1,
        title: `Develop successful HR Memo Communication strategy | HCM 360°`,
        content: `Revolutionize Internal Communication with Impulse HCM 360° software, Targeted distribution, Pre-built memo templates.`,
        link: `https://www.zaisystems.com/impulse-hcm-360/memo-communication`

    }
]
// decision-management
const BreadCrumbdecision = [
    {
        title: `Decision Management `,
    }
]
const decisionTwo = [

    {
        title: `Harness the Power of Data-Driven Decisions with Impulse HCM 360°'s Decision Management`,
        para: `Making informed decisions based on accurate data is crucial for optimizing your workforce strategy and achieving business goals. Impulse HCM 360°'s Decision Management system empowers businesses to leverage the power of
         data and business rules to automate routine decisions, improve consistency, and gain valuable insights for strategic planning.`,
        img: decision1
    }
];
const decisionThird = [

    {
        head: `Revolutionize HR Decision-Making with Automated Logic and Data Insights`,
        para: `Move beyond guesswork and intuition-based decisions. Our Decision Management system offers a comprehensive solution to:`,

    }
];
const decisionFeatures = [

    {
        img: decision2,
        title: `Automated Decision Making`,
        body: ` Define clear business rules and configure the system to automate
         routine HR decisions, such as leave request approvals based on pre-defined criteria.`,
        reverse: false,
    },
    {
        img: decision2,
        title: `Improved Consistency and Fairness`,
        body: ` Ensure consistent application of company policies and eliminate the risk of bias in decision-making.`,
        reverse: true,
    },
    {
        img: decision2,
        title: `Data-Driven Insights and Reporting`,
        body: `Gain valuable insights into HR data and decision trends through comprehensive reports and dashboards.`,
        reverse: false,
    },
    {
        img: decision2,
        title: `Enhanced Transparency and Explainability`,
        body: `Provide clear explanations for automated decisions, fostering trust and understanding among employees.`,
        reverse: true,
    },
    {
        img: decision2,
        title: `Increased Efficiency and Reduced Costs`,
        body: ` Free up HR teams from manual decision-making tasks and streamline overall HR processes.`,
        reverse: false,
    },
];
const decisionKeyFeatures = [
    {
        id: '1',
        title: `Intuitive Rule Builder`,
        description: `Utilize a user-friendly interface to define and manage complex decision rules without requiring coding expertise.`,
        image: decTwo
    },
    {
        id: '2',
        title: `Integration with HR Data Sources`,
        description: ` Seamlessly integrate with existing HR data sources for a holistic view of employee information and decision factors.`,
        image: decOne
    },
    {
        id: '3',
        title: `Scenario Modeling and Simulation`,
        description: `Test and refine decision rules through scenario modeling to ensure accurate and efficient outcomes.`,
        image: decFour
    },
    {
        id: '4',
        title: `Version Control and Audit Trails`,
        description: ` Maintain clear version control of decision rules and track all changes made to the system for audit purposes.`,
        image: decFive
    },
    {
        id: '5',
        title: `Machine Learning Integration`,
        description: `Integrate with machine learning capabilities to gain deeper insights and automate more complex decisions as your data grows.`,
        image: decThree
    }
];
const decisionTitles = [
    {
        title: "Key Features of Impulse HCM 360°'s Decision Management System",
    },
];
const decisionbutton = [
    {
        title: "Empower Your HR Team to Make Smarter Decisions Today",
    },
];
const Seodecision = [
    {
        id: 1,
        title: `Best Decision Management System Software | Impulse HCM 360°`,
        content: `Automate your decision making with Impulse HCM 360° software; improve consistency and fairness, Intuitive Rule Builder.`,
        link: `https://www.zaisystems.com/impulse-hcm-360/decision-management`

    }
]
// end-of-service
const BreadCrumbend = [
    {
        title: `End-of-Service `,
    }
]
const endTwo = [

    {
        title: `Ensure a Smooth Transition with Impulse HCM 360°'s End-of-Service Management`,
        para: `Employee departures are an inevitable part of the business cycle. Managing the end-of-service (EOS) process efficiently and respectfully is crucial for maintaining positive employee relations, minimizing legal risks, and ensuring a smooth handover. Impulse HCM 360°'s End-of-Service Management system empowers businesses to streamline
         the EOS process, automate repetitive tasks, and guarantee a compliant and positive experience for departing employees.`,
        img: endService1
    }
];
const endThird = [

    {
        head: `Navigate Employee Departures with Efficiency and Care`,
        para: `Move beyond scattered spreadsheets and manual paperwork. Our End-of-Service Management system offers a comprehensive solution to:`,

    }
];
const endFeatures = [

    {
        img: endService2,
        title: `Automated Workflows`,
        body: `Automate key EOS tasks, such as final paycheck calculations,
         benefit termination, and equipment collection, for improved efficiency and reduced errors.`,
        reverse: false,
    },
    {
        img: endService3,
        title: `Centralized Exit Interview Management`,
        body: `Facilitate standardized exit interviews with departing employees to gather valuable feedback and improve future retention efforts.`,
        reverse: true,
    },
    {
        img: endService4,
        title: `Seamless Document Management`,
        body: ` Maintain a centralized repository for all EOS documents, including resignation letters, exit interview notes, and final paystubs, ensuring easy access and secure storage.`,
        reverse: false,
    },
    {
        img: endService2,
        title: `Compliance Management`,
        body: `Ensure adherence to company policies and legal regulations regarding employee terminations and final settlements.`,
        reverse: true,
    },
    {
        img: endService3,
        title: `Improved Communication and Transparency`,
        body: ` Facilitate clear communication with departing employees throughout the EOS process, fostering a positive experience.`,
        reverse: false,
    },
];
const endKeyFeatures = [
    {
        id: '1',
        title: `Configurable EOS Checklists`,
        description: `Create and customize EOS checklists to ensure all essential tasks are completed efficiently during the exit process.`,
        image: endTwwo
    },
    {
        id: '2',
        title: `Integration with Payroll Systems`,
        description: ` Seamlessly integrate with existing payroll systems for accurate final pay calculations and timely disbursements.`,
        image: endFour
    },
    {
        id: '3',
        title: `Automated Benefit Termination Notifications`,
        description: `Automate notifications to benefit providers to ensure the timely termination of employee benefits.`,
        image: endOne
    },
    {
        id: '4',
        title: `Electronic Signature Capture`,
        description: ` Enable electronic signatures for resignation letters and other EOS documents to streamline the process and improve recordkeeping.`,
        image: endThree
    },
    {
        id: '5',
        title: `Data Security and Access Control`,
        description: `Maintain data security with role-based access control to ensure only authorized personnel can access sensitive employee information.`,
        image: endFive
    }
];
const endTitles = [
    {
        title: "Key Features of Impulse HCM 360°'s End-of-Service Management System",
    },
];
const endbutton = [
    {
        title: "Ensure a Smooth Transition for Departing Employees Today",
    },
];
const Seoend = [
    {
        id: 1,
        title: `Impulse HCM 360°'s End-of-Service Management`,
        content: `Navigate Employee Departures with Efficiency and Care with impulse HCM 360° software, Seamless Document Management, Compliance Management.`,
        link: `https://www.zaisystems.com/impulse-hcm-360/end-of-service-management`

    }
]
// insightful-dashboard
const BreadCrumbinsightful = [
    {
        title: ` Insightful Dashboard`,
    }
]
const insightfulTwo = [

    {
        title: `Gain Actionable Insights with Impulse HCM 360°'s Insightful Dashboards`,
        para: `HR leaders need real-time access to actionable insights to make informed decisions and optimize workforce strategies. Impulse HCM 360°'s Insightful Dashboards empower businesses to transform raw HR data into clear visualizations,
         enabling HR teams to monitor key metrics, identify trends, and gain a deeper understanding of their workforce.`,
        img: insightful1
    }
];
const insightfulThird = [

    {
        head: `Unleash the Power of Data and Make Data-Driven HR Decisions`,
        para: `Move beyond static reports and cumbersome data analysis. Our Insightful Dashboards offer a comprehensive solution to:`,

    }
];
const insightfulFeatures = [

    {
        img: insightful2,
        title: `Real-Time Data Visualization`,
        body: `Access interactive dashboards that display key HR metrics in real-time,
         providing a clear view of workforce performance, trends, and potential areas for improvement.`,
        reverse: false,
    },
    {
        img: insightful3,
        title: `Configurable Dashboards`,
        body: `Customize dashboards to suit your specific needs and priorities. Select from a wide range of pre-built
         widgets or create custom visualizations to focus on the metrics that matter most to your organization.`,
        reverse: true,
    },
    {
        img: insightful4,
        title: `Drill-Down Capabilities`,
        body: `Explore data further by easily drilling down into specific metrics for deeper analysis and root cause identification.`,
        reverse: false,
    },
    {
        img: insightful2,
        title: `Actionable Insights and Trends`,
        body: `Identify emerging trends and patterns within your HR data to inform strategic decision-making and proactive HR initiatives.`,
        reverse: true,
    },
    {
        img: insightful3,
        title: `Improved Communication and Collaboration`,
        body: `Share insightful dashboards with stakeholders across the organization to foster data-driven discussions and alignment.`,
        reverse: false,
    },
];
const insightfulKeyFeatures = [
    {
        id: '1',
        title: `Drag-and-Drop Functionality`,
        description: ` Easily design and customize dashboards with a user-friendly drag-and-drop interface, requiring no coding expertise.`,
        image: dashThree
    },
    {
        id: '2',
        title: `Wide Range of Data Connectors`,
        description: ` Seamlessly integrate with various HR data sources, including payroll systems, applicant tracking systems,
         and employee performance management modules within Impulse HCM 360°.`,
        image: dashFive
    },
    {
        id: '3',
        title: `Data Filtering and Segmentation`,
        description: ` Apply filters and segment data based on specific criteria to gain a more granular view of your workforce.`,
        image: dashTwo
    },
    {
        id: '4',
        title: `Alerts and Notifications`,
        description: ` Set up automated alerts and notifications to be informed of critical changes or potential issues within your HR data.`,
        image: dashOne
    },
    {
        id: '5',
        title: `Historical Data Comparison`,
        description: ` Compare current metrics with historical data to track progress and measure the effectiveness of HR initiatives over time.`,
        image: dashFour
    }
];
const insightfulTitles = [
    {
        title: "Key Features of Impulse HCM 360°'s Insightful Dashboards",
    },
];
const insightfulbutton = [
    {
        title: "Unlock the Power of Your HR Data and Make Smarter Decisions Today",
    },
];
const Seoinsightful = [
    {
        id: 1,
        title: `Actionable Insights with Impulse HCM 360°'s Insightful Dashboards`,
        content: `Unleash the Power of Data and Make Data-Driven HR Decisions, Improved Communication and Collaboration, Historical Data Comparison.`,
        link: `https://www.zaisystems.com/impulse-hcm-360/insightful-dashboards`

    }
]
// automated-rules-and-regulations
const BreadCrumbautomated = [
    {
        title: `Automated Rules & Regulations `,
    }
]
const automatedTwo = [

    {
        title: `Ensure Consistent Compliance with Impulse HCM 360°'s Automated Rules & Regulations`,
        para: `
        HR professionals face the challenge of maintaining compliance with a complex web of rules and regulations. Impulse HCM 360°'s Automated Rules & Regulations system empowers businesses
         to streamline compliance processes, automate enforcement of company policies, and minimize the risk of errors or violations.`,
        img: leave1
    }
];
const automatedThird = [

    {
        head: `Simplify Compliance and Reduce Administrative Burden`,
        para: `
        Move beyond manual policy checks and tedious audits. Our Automated Rules & Regulations system offers a comprehensive solution to:`,

    }
];
const automatedFeatures = [

    {
        img: leave2,
        title: `Automated Policy Enforcement`,
        body: `Define and configure rules to automatically flag potential policy violations during HR processes,
         such as leave request approvals or expense report submissions.`,
        reverse: false,
    },
    {
        img: leave3,
        title: `Real-Time Compliance Monitoring`,
        body: `Gain real-time insights into compliance adherence across various HR areas, enabling proactive risk identification and mitigation.`,
        reverse: true,
    },
    {
        img: leave4,
        title: `Streamlined Audit Processes`,
        body: ` Simplify compliance audits by maintaining a centralized repository of all company policies, rules, and associated audit trails.`,
        reverse: false,
    },
    {
        img: leave2,
        title: `Improved Employee Awareness`,
        body: `Promote employee awareness of company policies and regulations through automated notifications and reminders.`,
        reverse: true,
    },
    {
        img: leave3,
        title: `Reduced Administrative Burden`,
        body: `Free up HR teams from manual compliance checks and focus on strategic HR initiatives.`,
        reverse: false,
    },
];
const automatedKeyFeatures = [
    {
        id: '1',
        title: `Intuitive Rule Builder`,
        description: ` Utilize an easy-to-use interface to define and manage complex compliance rules without requiring coding expertise.`,
        image: autoThree
    },
    {
        id: '2',
        title: `Integration with HR Modules`,
        description: ` Seamlessly integrate with other Impulse HCM 360° modules, such as payroll, benefits, and performance management, to enforce consistent compliance across various HR processes.`,
        image: autoTwo
    },
    {
        id: '3',
        title: `Configurable Alert System`,
        description: ` Set up automated alerts for potential policy violations, notifying relevant HR personnel for further review or corrective action.`,
        image: autoOne
    },
    {
        id: '4',
        title: `Version Control and Audit Trails`,
        description: ` Maintain clear version control of all company policies and rules, and track all changes made to the system for audit purposes.`,
        image: autoFive
    },
    {
        id: '5',
        title: `Regulatory Updates and Compliance Resources`,
        description: ` Stay informed about relevant regulatory changes with access to compliance resources and updates within the system.`,
        image: autoFour
    }
];
const automatedTitles = [
    {
        title: "Key Features of Impulse HCM 360°'s Automated Rules & Regulations System",
    },
];
const automatedbutton = [
    {
        title: "Navigate Compliance with Confidence Today",
    },
];
const Seoautomated = [
    {
        id: 1,
        title: `Impulse HCM 360°'s Automated Rules & Regulations management`,
        content: `Impulse HCM 360°'s Automated Rules & Regulations system empowers businesses to streamline compliance processes, Automated Policy Enforcement`,
        link: `https://www.zaisystems.com/impulse-hcm-360/automated-rules-regulations`

    }
]
// Work Days’ Notice and Warning Management 
const BreadCrumbworkDays = [
    {
        title: 'Work Days’ Notice and Warning Management ',
    }
]
const workDaysTwo = [

    {
        title: `Ensure Smooth Transitions and Legal Compliance with Impulse HCM 360°'s Work Days' Notice & Warning Management`,
        para: `
        Managing employee performance issues and terminations effectively is essential for maintaining a healthy work environment and minimizing legal risks. Impulse HCM 360°'s Work Days' Notice & Warning Management system empowers businesses to streamline the
         process of issuing performance warnings, documenting workdays' notice, and ensuring compliance with company policies and labor laws.`,
        img: attendance1
    }
];
const workDaysThird = [

    {
        head: 'Navigate Performance Issues Efficiently and Maintain Legal Compliance',
        para: `
        Move beyond outdated paper trails and informal communication. Our Work Days' Notice & Warning Management system offers a comprehensive solution to:`,

    }
];
const workDaysFeatures = [

    {
        img: attendance2,
        title: `Standardized Warning Process`,
        body: `Establish a consistent and documented process for issuing verbal and written warnings, ensuring fairness and transparency.`,
        reverse: false,
    },
    {
        img: attendance3,
        title: `Configurable Warning Templates`,
        body: `Create and customize warning templates with pre-defined escalation levels and corrective action plans to address specific performance issues.`,
        reverse: true,
    },
    {
        img: attendance4,
        title: `Automated Workflows`,
        body: `Automate the warning issuance process, including notifications to employees and managers, streamlining communication and recordkeeping.`,
        reverse: false,
    },
    {
        img: attendance5,
        title: `Work Days' Notice Tracking`,
        body: ` Accurately track and document employee workdays' notice periods in accordance with company policies and legal requirements.`,
        reverse: true,
    },
    {
        img: attendance6,
        title: `Detailed Audit Trails and Reporting`,
        body: `Maintain a clear audit trail of all performance warnings and workdays' notice documentation for future reference and compliance purposes.`,
        reverse: false,
    },
];
const workDaysKeyFeatures = [
    {
        id: '1',
        title: `Configurable Warning Levels`,
        description: `Define different warning levels with escalating consequences to address minor or repeated performance issues.`,
        image: Image1
    },
    {
        id: '2',
        title: `Electronic Signature Capabilities`,
        description: `Facilitate electronic signing of warnings by employees and managers for improved accountability and recordkeeping.`,
        image: Image2
    },
    {
        id: '3',
        title: `Integration with Performance Management`,
        description: ` Seamlessly integrate with Impulse HCM 360°'s performance management system for a holistic view of employee performance.`,
        image: Image2
    },
    {
        id: '4',
        title: `Role-Based Access Control`,
        description: `Enforce data security by granting access to warning documentation based on user roles and permissions within the organization.`,
        image: Image2
    },
    {
        id: '5',
        title: `Customizable Reporting Options`,
        description: ` Generate reports on warning trends, workdays' notice compliance, and other key metrics to inform HR decisions.`,
        image: Image2
    }
];
const workDaysTitles = [
    {
        title: "Key Features of Impulse HCM 360°'s Work Days' Notice & Warning Management System",
    },
];
const workDaysbutton = [
    {
        title: "Navigate Performance Issues with Confidence and Uphold Legal Compliance",
    },
];
const SeoworkDays = [
    {

        id: 1,
        title: `HCM 360°  Work Days’ Notice and Warning Letter Management`,
        content: `Standardized the work days’ notice and Warning Management of employees with Impulse HCM 360° Software, navigated performance, and maintained compliance.`,
        link: `https://www.zaisystems.com/impulse-hcm-360/workdays-notice-letter-management`
    }
]
export const HcmData = [
    {
        hcm: 'payroll-management',
        para: BreadCrumbpayroll,
        list: payrollTwo,
        listThird: payrollThird,
        listFeatures: payrollFeatures,
        listCertificate: payrollTitles,
        listTools: payrollKeyFeatures,
        listbutton: payrollbutton,
        listForSeo: SeoPayroll
    },
    {
        hcm: 'attendance-management',
        para: BreadCrumbattendance,
        list: attendanceTwo,
        listThird: attendanceThird,
        listFeatures: attendanceFeatures,
        listCertificate: attendanceTitles,
        listTools: attendanceKeyFeatures,
        listbutton: attendancebutton,
        listForSeo: Seoattendance
    },
    {
        hcm: 'customizable-leave-management',
        para: BreadCrumbcustomizable,
        list: customizableTwo,
        listThird: customizableThird,
        listFeatures: customizableFeatures,
        listCertificate: customizableTitles,
        listTools: customizableKeyFeatures,
        listbutton: customizablebutton,
        listForSeo: Seocustomizable
    },
    {
        hcm: 'employee-self-service',
        para: BreadCrumbemployee,
        list: employeeTwo,
        listThird: employeeThird,
        listFeatures: employeeFeatures,
        // listCertificate: employeeTitles,
        // listTools: employeeKeyFeatures,
        listbutton: employeebutton,
        listForSeo: Seoemployee
    },
    {
        hcm: 'performance-evaluation',
        para: BreadCrumbperformance,
        list: performanceTwo,
        listThird: performanceThird,
        listFeatures: performanceFeatures,
        listCertificate: performanceTitles,
        listTools: performanceKeyFeatures,
        listbutton: performancebutton,
        listForSeo: Seoperformance
    },
    {
        hcm: 'job-portal',
        para: BreadCrumbjob,
        list: jobTwo,
        listThird: jobThird,
        listFeatures: jobFeatures,
        listCertificate: jobTitles,
        listTools: jobKeyFeatures,
        listbutton: jobbutton,
        listForSeo: Seojob
    },
    {
        hcm: 'task-management',
        para: BreadCrumbtask,
        list: taskTwo,
        listThird: taskThird,
        listFeatures: taskFeatures,
        listCertificate: taskTitles,
        listTools: taskKeyFeatures,
        listbutton: taskbutton,
        listForSeo: Seotask
    },
    {
        hcm: 'letter-and-forms',
        para: BreadCrumbletters,
        list: lettersTwo,
        listThird: lettersThird,
        listFeatures: lettersFeatures,
        listCertificate: lettersTitles,
        listTools: lettersKeyFeatures,
        listbutton: lettersbutton,
        listForSeo: Seoletters
    },
    {
        hcm: 'loans-management',
        para: BreadCrumbloans,
        list: loansTwo,
        listThird: loansThird,
        listFeatures: loansFeatures,
        listCertificate: loansTitles,
        listTools: loansKeyFeatures,
        listbutton: loansbutton,
        listForSeo: Seoloans
    },
    {
        hcm: 'benefits-and-expenses-tracking',
        para: BreadCrumbbenefits,
        list: benefitsTwo,
        listThird: benefitsThird,
        listFeatures: benefitsFeatures,
        listCertificate: benefitsTitles,
        listTools: benefitsKeyFeatures,
        listbutton: benefitsbutton,
        listForSeo: Seobenefits
    },
    {
        hcm: 'custody-management',
        para: BreadCrumbcustody,
        list: custodyTwo,
        listThird: custodyThird,
        listFeatures: custodyFeatures,
        listCertificate: custodyTitles,
        listTools: custodyKeyFeatures,
        listbutton: custodybutton,
        listForSeo: Seocustody
    },
    {
        hcm: 'workflow-and-approvals',
        para: BreadCrumbworkflow,
        list: workflowTwo,
        listThird: workflowThird,
        listFeatures: workflowFeatures,
        listCertificate: workflowTitles,
        listTools: workflowKeyFeatures,
        listbutton: workflowbutton,
        listForSeo: Seoworkflow
    },
    {
        hcm: 'memo-communication',
        para: BreadCrumbmemo,
        list: memoTwo,
        listThird: memoThird,
        listFeatures: memoFeatures,
        listCertificate: memoTitles,
        listTools: memoKeyFeatures,
        listbutton: memobutton,
        listForSeo: Seomemo
    },
    {
        hcm: 'decision-management',
        para: BreadCrumbdecision,
        list: decisionTwo,
        listThird: decisionThird,
        listFeatures: decisionFeatures,
        listCertificate: decisionTitles,
        listTools: decisionKeyFeatures,
        listbutton: decisionbutton,
        listForSeo: Seodecision
    },
    {
        hcm: 'end-of-service',
        para: BreadCrumbend,
        list: endTwo,
        listThird: endThird,
        listFeatures: endFeatures,
        listCertificate: endTitles,
        listTools: endKeyFeatures,
        listbutton: endbutton,
        listForSeo: Seoend
    },
    {
        hcm: 'insightful-dashboard',
        para: BreadCrumbinsightful,
        list: insightfulTwo,
        listThird: insightfulThird,
        listFeatures: insightfulFeatures,
        listCertificate: insightfulTitles,
        listTools: insightfulKeyFeatures,
        listbutton: insightfulbutton,
        listForSeo: Seoinsightful
    },
    {
        hcm: 'automated-rules-and-regulations',
        para: BreadCrumbautomated,
        list: automatedTwo,
        listThird: automatedThird,
        listFeatures: automatedFeatures,
        listCertificate: automatedTitles,
        listTools: automatedKeyFeatures,
        listbutton: automatedbutton,
        listForSeo: Seoautomated
    },
    {
        hcm: 'work-days-notice-and-warning-management',
        para: BreadCrumbworkDays,
        list: workDaysTwo,
        listThird: workDaysThird,
        listFeatures: workDaysFeatures,
        listCertificate: workDaysTitles,
        listTools: workDaysKeyFeatures,
        listbutton: workDaysbutton,
        listForSeo: SeoworkDays
    }
]