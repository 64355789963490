import React from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import './assets/scss/style.scss';
// import BlogListView from './components/blog/BlogListView';
import PageScrollTop from './components/pageToTop/PageScrollTop.js';
import Contact from './elements/contact/Contact.js';
// import Portfolio from './elements/portfolio/Portfolio';
import Service from './elements/service/Service.js';
import Team from './elements/team/Team';
import AboutUs from './pages/AboutUs';
import Appreciation from './pages/Appreciation';
import BlogDetails from './pages/BlogDetails';
import Business from './pages/Business';
import Home from './pages/Home';
import InternationalConsulting from './pages/InternationalConsulting';
import PersonalPortfolio from './pages/PersonalPortfolio';
import PortfolioDetails from './pages/PortfolioDetails';

// import AboutImpulse from './pages/AboutImpulse';
import AllSolutions from './pages/AllSolutions.jsx';
import Error from './pages/Error';
import RequestADemo from './pages/RequestADemo';
import SCD from './elements/service/servicePages/SCD';
import Career from './elements/career/Career';
import BLOGS from './elements/Blogs/BLOGS';
import BlogMain from './elements/Blogs/BlogMain';
import HcmPages from './pages/HcmPages';
import PodcastPage from './pages/PodcastPage.js';

const App = () => {
  return (
    <Router>
      <PageScrollTop>
        <Switch>

          <Route path='/' exact component={Home} />
          <Route path='/faq-page' exact component={Business} />
          <Route path='/impulse-hcm-360' exact component={AllSolutions} />
          <Route
            path='/portfolio'
            exact
            component={PersonalPortfolio}
          />
          <Route path='/courses' exact component={InternationalConsulting} />
          <Route path='/courses/:category' component={PortfolioDetails} />

          {/* <Route path='/blog-list-view' exact component={BlogListView} /> */}
          <Route path='/services' exact component={Service} />
          <Route path='/team' exact component={Team} />
          <Route path='/contact' exact component={Contact} />
          <Route path='/about-us' exact component={AboutUs} />
          {/* <Route path='/impulse-hcm' exact component={AboutImpulse} /> */}
          <Route path='/appreciation' exact component={Appreciation} />
          <Route path='/req-a-demo' exact component={RequestADemo} />
          {/* <Route path='/portfolio' exact component={Portfolio} /> */}
          <Route path='/portfolio/:portfolio' exact component={BlogDetails} />

          <Route path='/services/:service' exact component={SCD} />
          <Route path='/impulse-hcm-360/:hcm' exact component={HcmPages} />

          <Route path='/career' exact component={Career} />
          <Route path='/podcast' exact component={PodcastPage} />

          <Route path='/blogs/:blog' exact component={BLOGS} />

          {/* <Route path='/blogs' exact component={BLOGS} /> */}
          <Route path='/blogs' exact component={BlogMain} />

          <Route path='/*' exact component={Error} />
        </Switch>
      </PageScrollTop>
    </Router>
  );
};

export default App;
