import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { FiMail } from 'react-icons/fi';
import contact from '../../assets/images/contact-focus.webp';
import call from '../../assets/images/call.webp';
import { Link } from "react-router-dom";

// import LazyLoad from "react-lazyload";

const CalltoActionFive = () => {
  return (
    <div className='text-center'>

      <Link to="/contact">
        <button class="mt-4 bg-transparent btn-border">

          <div className='rn-callto-action clltoaction-style-default style-5'>
            <div className='container'>
              <div className='row row--0 align-items-center content-wrapper theme-shape'>
                <div className='col-lg-4 col-md-4'>
                  <div className='inner'>
                    <div className='content'>
                      <h3 className='h_3'>Need Help? Let's Talk!</h3>
                      <p className='w-100'>
                        We're here to answer your questions and discuss your needs. Contact us today for a free consultation.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='col-lg-4 col-md-4'>
                  <div className='inner'>
                    <div className='content text-left d-block wrap-content'>
                      <ScrollAnimation
                        animateIn='fadeInUp'
                        animateOut='fadeInOut'
                        animateOnce={true}
                      >
                        <div
                          className='rn-address bg-transparent'
                          style={{ padding: 0 }}
                        >
                          <div className='icon' style={{ fontSize: '30px' }}>
                            <FiMail className='mt-1' />
                          </div>
                          <div className='inner'>
                            <p className='mt-1'>
                              <a href='mailto:zaisystems@gmail.com' target="_blank" rel="noopener noreferrer nofollow">
                                zaisystems@gmail.com
                              </a>
                            </p>
                          </div>
                        </div>
                      </ScrollAnimation>

                      <ScrollAnimation
                        animateIn='fadeInUp'
                        animateOut='fadeInOut'
                        animateOnce={true}
                      >
                        <div
                          className='rn-address bg-transparent'
                          style={{ padding: 0, marginTop: '3px' }}
                        >
                          <div className='icon'>
                            {/* <LazyLoad> */}
                            <img src={call} alt='Call' style={{ width: '30px', height: '30px' }} className='mt-1' />
                            {/* </LazyLoad>   */}
                            {/* <FiHeadphones /> */}
                          </div>
                          <div className='inner'>
                            <p className='mt-2'>
                              <a href='tel:+92 334-6906960' target="_blank" rel="noopener noreferrer nofollow">+92 334 6906960</a>
                            </p>
                          </div>
                        </div>
                      </ScrollAnimation>
                    </div>
                  </div>
                </div>
                <div className='col-lg-4 col-md-4 arrow-image'>
                  {/* <LazyLoad> */}
                  <img src={contact} alt='Contact' />
                  {/* </LazyLoad>  */}
                </div>
              </div>
            </div>
          </div>
        </button>
      </Link>
    </div>
  );
};
export default CalltoActionFive;
