import React from 'react';
import COO from '../../assets/images/about/Rafay-khan.webp';
import CTO from '../../assets/images/team/huzaifa.webp';
import SEOCompany from '../../assets/images/team/owaisahmad.webp';
import Layout from '../../common/Layout';
import SEO from '../../common/SEO';
import BreadcrumbOne from '../breadcrumb/BreadcrumbOne';
import SectionTitle from '../sectionTitle/SectionTitle';

import TeamThree from './TeamThree';
import LazyLoad from "react-lazyload";

const Team = () => {
  return (
    <>
      <SEO>
        <title>ZAI SYSTEMS | Expert Team</title>
        <meta
          name='description'
          content="ZAI SYSTEMS expert team try to execute its skillfulness and specialties to provide best services according to clients' requirements.  "
        />
        <meta
          name='robots'
          content='index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1'
        />
        <link rel='canonical' href='https://www.zaisystems.com/team' />
      </SEO>
      <Layout>
        <BreadcrumbOne
          title='Our Team of Experts'
          rootUrl='/'
          parentUrl='Home'
          currentUrl='Team'
        />
        <div className='main-content'>
          {/* Start Elements Area  */}
          <div className='rwt-team-area rn-section-gap'>
            <div className='container'>
              <div className='row'>
                <div className='col-lg-12 mb--15'>
                  <SectionTitle
                    textAlign='text-center'
                    radiusRounded=''
                    subtitle='Our Experts'
                    // title="Team Style Three"
                    description="We have put together a team of qualified professionals and specialists to offer complete IT services and solutions in accordance with each client's specific needs."
                  />
                </div>
                <div className='col-lg-4'>
                  <div style={{ background: '#0f0f11', borderRadius: '12px' }}>
                    <LazyLoad> <img src={SEOCompany} alt='CEO' height={300} style={{ borderTopLeftRadius: '12px', borderTopRightRadius: '12px', }} />
                    </LazyLoad>
                    <h5 className='p-4  text-center' style={{ color: 'white' }}>
                      Owais Ahmad Khan (CEO)
                    </h5>
                  </div>
                </div>
                <div className='col-lg-4 '>
                  <div style={{ background: '#0f0f11', borderRadius: '12px' }}>
                    <LazyLoad>
                      <img src={COO} alt='COO' height={300} style={{ borderTopLeftRadius: '12px', borderTopRightRadius: '12px', }} />
                    </LazyLoad>
                    <h5 className='p-4 text-center ' style={{ color: 'white' }}>
                      Abdul Rafay Khan (COO)
                    </h5>
                  </div>
                </div>
                <div className='col-lg-4 '>
                  <div style={{ background: '#0f0f11', borderRadius: '12px' }}>
                    <LazyLoad><img src={CTO} alt='CTO' style={{ borderTopLeftRadius: '12px', borderTopRightRadius: '12px', }} />
                    </LazyLoad>
                    <h5 className='p-4  text-center ' style={{ color: 'white' }}>
                      Huzaifa Ali (CTO)
                    </h5>
                  </div>
                </div>
              </div>
              <TeamThree
                column='col-lg-4 col-md-6 col-12 mt--30'
                teamStyle='team-style-two'
              />
            </div>
          </div>
          {/* End Elements Area  */}
        </div>
      </Layout>
    </>
  );
};
export default Team;
