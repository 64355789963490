import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { FiActivity, FiCast, FiMap } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import LazyLoad from "react-lazyload";
import service06 from '../../assets/images/servicesIcons/Bespoke.webp';
import service08 from '../../assets/images/servicesIcons/Business.webp';
import service07 from '../../assets/images/servicesIcons/DataAnalytics.webp';
// import service03 from '../../assets/images/servicesIcons/DevOps.webp';
import service09 from '../../assets/images/servicesIcons/Information.webp';
import service02 from '../../assets/images/servicesIcons/ManagedCloudServices.webp';
// import service04 from '../../assets/images/servicesIcons/WebMobile.webp';
import service05 from '../../assets/images/servicesIcons/ecommerce.webp';
// import service01 from '../../assets/images/servicesIcons/userExperienceDevelopment.webp';
import bigdata from '../../assets/images/servicesIcons/new slugs/big data.webp';
import blockchain from '../../assets/images/servicesIcons/new slugs/blockchain dev.webp';
import community from '../../assets/images/servicesIcons/new slugs/community.webp';
import defi from '../../assets/images/servicesIcons/new slugs/defi.webp';
import dedicated from '../../assets/images/servicesIcons/new slugs/dedicated dev.webp';
import devops from '../../assets/images/servicesIcons/new slugs/devops engineers.webp';
import exchange from '../../assets/images/servicesIcons/new slugs/cryptocurrency exchange dev.webp';
import fullstack from '../../assets/images/servicesIcons/new slugs/full stack dev.webp';
import hyperledger from '../../assets/images/servicesIcons/new slugs/hyper ledger blockchain development.webp';
import natural from '../../assets/images/servicesIcons/new slugs/natural language.webp';
import near from '../../assets/images/servicesIcons/new slugs/near protocol development.webp';
import design from '../../assets/images/servicesIcons/new slugs/nft design.webp';
import marketplace from '../../assets/images/servicesIcons/new slugs/nft market place.webp';
import marketing from '../../assets/images/servicesIcons/new slugs/nft marketing.webp';
import minting from '../../assets/images/servicesIcons/new slugs/nft minting.webp';
import offshore from '../../assets/images/servicesIcons/new slugs/offshore dev.webp';
import robotic from '../../assets/images/servicesIcons/new slugs/robotic process.webp';
import smart from '../../assets/images/servicesIcons/new slugs/smart contract.webp';
import wallet from '../../assets/images/servicesIcons/new slugs/wallet.webp';
import white from '../../assets/images/servicesIcons/new slugs/white paper.webp';
import graphicThree from '../../assets/images/servicePagesLogos/graphic designing/illustration & infographic.webp';
const ServiceList = [
  {
    img: robotic,
    link: '/services/robotic-process-automation',
    icon: <FiActivity />,
    title: 'Robotic Process Automation',
    description: 'Automate business processes with software robots for faster and more accurate execution of repetitive tasks.'
  },
  {
    img: bigdata,
    link: '/services/big-data',
    icon: <FiActivity />,
    title: 'Big Data Solution',
    description:
      'Harness big data to gain insights, optimize performance, and drive innovation across your organization.'
  },
  {
    img: white,
    link: '/services/white-paper-development',
    icon: <FiActivity />,
    title: 'White Paper Development',
    description:
      'Craft clear and compelling white papers outlining project, product, or service benefits, features, and roadmap.'
  },
  {
    img: smart,
    link: '/services/smart-contract-development',
    icon: <FiActivity />,
    title: 'Smart Contract Development',
    description:
      'Develop and deploy blockchain-based smart contracts to execute transactions and enforce agreements without intermediaries.'
  },
  {
    img: wallet,
    link: '/services/wallet-development',
    icon: <FiActivity />,
    title: 'Cryptocurrency Wallet Development Solutions',
    description:
      'Create secure and user-friendly wallets for storing, sending, and receiving various cryptocurrencies and tokens.'
  },
  {
    img: defi,
    link: '/services/defi-development',
    icon: <FiActivity />,
    title: 'Decentralized Finance Development ',
    description:
      'Build decentralized finance applications for enhanced control, transparency, and efficiency in financial activities.'
  },
  {
    img: natural,
    link: '/services/natural-language-processing',
    icon: <FiActivity />,
    title: 'Natural Language Processing',
    description:
      'Interact naturally with customers, employees, and data using advanced techniques like sentiment analysis, text summarization, and chatbots.'
  },
  {
    img: exchange,
    link: '/services/exchange-development',
    icon: <FiActivity />,
    title: 'Cryptocurrency Exchange Development',
    description:
      'Design and develop cryptocurrency exchanges facilitating fast and secure trading of digital assets.'
  },
  {
    img: hyperledger,
    link: '/services/hyperledger-development',
    icon: <FiActivity />,
    title: 'Hyperledger Blockchain Development',
    description:
      'Leverage Hyperledger for enterprise blockchain solutions, enhancing trust, transparency, and efficiency.'
  },
  {
    img: near,
    link: '/services/near-protocol-development',
    icon: <FiActivity />,
    title: 'Near Protocol Development',
    description:
      ' A scalable and user-friendly blockchain platform with low-cost and high-speed transactions.'
  },
  {
    img: blockchain,
    link: '/services/blockchain-developers',
    icon: <FiActivity />,
    title: 'Blockchain Development',
    description:
      'Expert and proficient developers creating custom solutions with the newest technologies.'
  },
  {
    img: fullstack,
    link: '/services/full-stack-development',
    icon: <FiActivity />,
    title: 'Full Stack Development',
    description:
      ' Front-end and back-end development of web and mobile apps using advanced frameworks.'
  },
  {
    img: dedicated,
    link: '/services/dedicated-developers',
    icon: <FiActivity />,
    title: 'Dedicated Developers',
    description:
      'Hire dedicated developers who work exclusively on your project and ensure quality results.'
  },
  {
    img: offshore,
    link: '/services/offshore-developers',
    icon: <FiActivity />,
    title: 'Offshore Developers',
    description:
      ' Get offshore developers who are cost-effective, flexible, reliable, and communicate well.'
  },
  {
    img: devops,
    link: '/services/devops-engineers',
    icon: <FiActivity />,
    title: 'DevOps Services ',
    description:
      'Improve software development and delivery with DevOps tools and practices.'
  },
  {
    img: marketplace,
    link: '/services/nft-marketplace',
    icon: <FiActivity />,
    title: 'NFT Marketplace',
    description:
      'Build NFT marketplaces for trading unique and verifiable digital assets using blockchain technology.'
  },
  {
    img: minting,
    link: '/services/nft-minting',
    icon: <FiActivity />,
    title: 'NFT Minting',
    description:
      'Create, manage, and issue non-fungible tokens using various platforms and standards.'
  },
  {
    img: marketing,
    link: '/services/nft-marketing',
    icon: <FiActivity />,
    title: 'NFTS Marketing',
    description:
      'Promote and sell non-fungible tokens using diverse effective channels and proven strategies.'
  },
  {
    img: design,
    link: '/services/nft-design',
    icon: <FiActivity />,
    title: 'NFT Design Creation',
    description:
      'Create and produce unique tokens with tools like 3D modeling and digital art as a talented NFT designer and collector in the crypto space.'
  },
  {
    img: community,
    link: '/services/community-building',
    icon: <FiActivity />,
    title: 'Web3 Community Building',
    description:
      'Engage and interact with the target audience as a Web3 community builder and help them grow and learn about decentralized technologies.'
  },
  // {
  //   img: service01,
  //   link:'#services',
  //   icon: <FiActivity />,
  //   title: 'User Experience Design',
  //   description:
  //     'We focus on our users needs and combine it with technologically feasible and economically viable services that best suits for their business.'
  // },
  {
    img: service02,
    link: '/services/managed-cloud-services',
    icon: <FiCast />,
    title: 'Managed Cloud Services',
    description:
      'Use top providers like AWS, Azure, and Google Cloud to move and improve cloud infrastructure with managed cloud services.'
  },
  // {
  //   img: service03,
  //   link:'#services',
  //   icon: <FiMap />,
  //   title: 'DevOps',
  //   description:
  //     'We know what it takes to deliver robust, secure, high-performance, and enterprise-grade applications and products and expertise in the cloud services domain.'
  // },
  // {
  //   img: service04,
  //   link:'#services',
  //   icon: <FiActivity />,
  //   title: 'Web and Mobile',
  //   description:
  //     'We develop customized and unique web and mobile applications. We have a creative team capable of adopting different styles for various business...'
  // },
  {
    img: service05,
    link: '/services/e-commerce-solution-providers',
    icon: <FiCast />,
    title: 'E-commerce',
    description:
      `Launch and grow online stores using top e-commerce
      platforms like Shopify, WooCommerce, and Magento.`
  },

  {
    img: service07,
    link: '/services/data-analytics',
    icon: <FiActivity />,
    title: 'Data Analytics',
    description:
      'Collect, process, analyze, and visualize data using the best tools and techniques like Python, R, SQL, and Tableau.'
  },
  {
    img: service08,
    link: '/services/business-intelligence',
    icon: <FiCast />,
    title: 'Business Intelligence',
    description:
      'Transform data into actionable insights with business intelligence platforms and solutions like Power BI, Qlik, and SAS.'
  },
  {
    img: service06,
    link: '/services/bestspoke-it-solutions-for-business',
    icon: <FiMap />,
    title: 'Bespoke IT Solutions for Business',
    description:
      ` Provide custom-made IT solutions
     for business challenges and opportunities using expertise across
     various domains and technologies.`
  },
  {
    img: service09,
    link: '/services/information-strategy',
    icon: <FiMap />,
    title: 'Information Strategy',
    description:
      'Define and implement comprehensive information strategies that guide the effective management, use, and leverage of data and information assets for achieving business goals.'
  },
  {
    img: graphicThree,
    link: '/services/graphic-designing',
    icon: <FiMap />,
    title: 'Graphic Designing',
    description:
      'Ditch design bottlenecks and fuel your marketing engine with Zai Systems. Our platform empowers fast-growing companies like yours to get stunning graphic design.'
  }
];

const ServiceOne = ({ textAlign, serviceStyle }) => {
  return (
    <div className='row row--15 service-wrapper'>
      {ServiceList.map((val, i) => (
        <div className='col-lg-4 col-md-6 col-sm-6 col-12' key={i}>
          <ScrollAnimation
            animateIn='fadeInUp'
            animateOut='fadeInOut'
            animateOnce={true}
          >
            <div className={`service ${serviceStyle} ${textAlign}`}>
              <div className='icon w-25 m-auto p-3'>
                <LazyLoad><img src={val.img} className='w-100' alt={val.title} /></LazyLoad>
              </div>
              <div className='content'>
                <h3
                  className='title w-600'
                  style={{ fontSize: '20px', fontWeight: '600' }}
                >
                  <Link
                    to={val.link}
                    dangerouslySetInnerHTML={{ __html: val.title }}
                  ></Link>
                </h3>
                <p
                  className='description b1 color-gray mb--0'
                  dangerouslySetInnerHTML={{ __html: val.description }}
                  style={{ textAlign: 'center' }}
                ></p>
              </div>
            </div>
          </ScrollAnimation>
        </div>
      ))}
    </div>
  );
};
export default ServiceOne;
