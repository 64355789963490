import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import LazyLoad from 'react-lazyload';
import { Link } from 'react-router-dom';
import galaryImageTwo from '../../assets/images/service/ecom.jpg';
import galaryImageOne from '../../assets/images/service/graphic.jpg';
import galaryImageThree from '../../assets/images/service/web.jpg';

const ServiceList = [
  {
    image: galaryImageThree,
    title: 'Web and App Development',
    description:
      'We create websites and apps for any business and industry, from simple to complex, with high-quality and custom solutions.'
  },
  {
    image: galaryImageTwo,
    title: 'E-commerce',
    description:
      'We build and optimize online stores for your products and services, with payment, shipping, SEO, and marketing features.'
  },
  {
    image: galaryImageOne,
    title: 'UI/UX Design',
    description:
      'We design user interfaces and experiences that are attractive, intuitive, and user-friendly, with user research, testing, and feedback.'
  }
];
const ServiceFive = ({ textAlign, serviceStyle }) => {
  return (
    <div className='row row--15 service-wrapper'>
      {ServiceList.map((val, i) => (
        <div className='col-lg-4 col-md-6 col-sm-12 col-12' key={i}>
          <ScrollAnimation
            animateIn='fadeInUp'
            animateOut='fadeInOut'
            animateOnce={true}
          >
            <div className={`service ${serviceStyle} ${textAlign}`}>
              <div className='inner'>
                <div className='content'>
                  <h3 className='title text-center'>
                    <Link
                      to='/services'
                      dangerouslySetInnerHTML={{ __html: val.title }}
                    ></Link>
                  </h3>
                  <p
                    className='description'
                    dangerouslySetInnerHTML={{ __html: val.description }}
                    style={{ textAlign: 'center' }}
                  ></p>
                </div>
                <div className='image'>
                  <LazyLoad><img
                    src={`${val.image}`}
                    alt={val.title}
                    style={{ borderRadius: '15px' }}
                    className='mt-3'
                  />
                  </LazyLoad>
                </div>
              </div>
            </div>
          </ScrollAnimation>
        </div>
      ))}
    </div>
  );
};
export default ServiceFive;
