import PropTypes from "prop-types"
import React from "react"
import ScrollAnimation from "react-animate-on-scroll"
import LazyLoad from "react-lazyload";
const BlogList = ({ data, StyleVar, certicate }) => {
  const data_image = data.img.split("/")
  const parts_image = data_image[3].split(".")
  const altName = parts_image[0]
  return (
    <div className={`rn-card ${StyleVar}`}>
      <ScrollAnimation
        animateIn="fadeInUp"
        animateOut="fadeInOut"
        animateOnce={true}
      >
        <div className="inner">
          <div className="thumbnail">

            <LazyLoad><img src={data.img} alt={altName} /></LazyLoad>
          </div>
          {!certicate && (
            <div className="content">
              <h5 className="title text-center mt-0">
                <a href="https://forms.gle/isUuXbdjQtW1posu8" target="_blank" rel="noreferrer" >
                  Register Now
                </a>
              </h5>
              {/* <h6>{data.ref}</h6> */}
            </div>
          )}
        </div>
      </ScrollAnimation>
    </div>
  )
}
BlogList.propTypes = {
  data: PropTypes.object
}
export default BlogList
