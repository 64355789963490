import React from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom';
//  import LazyLoad from "react-lazyload";
import ScrollAnimation from 'react-animate-on-scroll';
const ServiceList = [
    {
 
      link:'blogs/fintech-ways-in-Pakistan',
      title: 'Fintech Ways in Pakistan: Path for Financial Inclusion',
      description:`In recent years, Pakistan has witnessed a significant transformation in its financial sector, with technology
      playing a pivotal role in shaping new ways of conducting financial transactions.`
    },
    {
    
      link:'blogs/online-graphic-designing-jobs-and-careers',
      title: 'Exploring Graphic Design Jobs, Salaries, and Opportunities',
      description:`Graphic design is a dynamic field that combines creativity, technology, and visual
       communication. Whether you’re a budding designer or someone interested in hiring one.`
    }
  ];
const BlogsOne = ({ textAlign, serviceStyle }) => {
  return (
    <div className='row row--15 service-wrapper d-flex justify-content-center align-items-center'>
    {ServiceList.map((val, i) => (
      <div className='col-lg-5 col-md-6 col-sm-6 col-12 ' key={i}>
        <ScrollAnimation
          animateIn='fadeInUp'
          animateOut='fadeInOut'
          animateOnce={true}
        >
          <div className={`service ${serviceStyle} ${textAlign}`}>
            {/* <div className='icon w-25 m-auto p-3'>
              
 <LazyLoad><img src={val.img} className='w-100' alt={val.title} /></LazyLoad>
            </div> */}
            <div className='content'>
              <h3
                className='title w-600 mb-0'
                style={{ fontSize: '20px', fontWeight: '600' }}
              >
                <Link
                  to={val.link}
                  dangerouslySetInnerHTML={{ __html: val.title }}
                ></Link>
              </h3>
              <p
                className='description b1 color-gray mb--0 mt-2'
                dangerouslySetInnerHTML={{ __html: val.description }}
                style={{textAlign:'center'}}
              ></p>
            </div>
          </div>
        </ScrollAnimation>
      </div>
    ))}
  </div>
  )
}

export default BlogsOne