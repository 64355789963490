import React from "react";
import LazyLoad from "react-lazyload";

import { Link } from "react-router-dom";
// import Separator from "../../elements/separator/Separator";
import ai from './../../../src/assets/images/ServiceNavbarLogos/services/ai.webp';
import blockchainDev from './../../../src/assets/images/ServiceNavbarLogos/services/blockchain dev.webp';
import content from './../../../src/assets/images/ServiceNavbarLogos/services/content.webp';
import custom from './../../../src/assets/images/ServiceNavbarLogos/services/custom soft dev.webp';
import digitalMarketing from './../../../src/assets/images/ServiceNavbarLogos/services/digital marketing.webp';
import enterprise from './../../../src/assets/images/ServiceNavbarLogos/services/enterprise app dev.webp';
import graphic from './../../../src/assets/images/ServiceNavbarLogos/services/graphic.webp';
import iot from './../../../src/assets/images/ServiceNavbarLogos/services/iot dev.webp';
import metaverse from './../../../src/assets/images/ServiceNavbarLogos/services/mataverse.webp';
import mobileApp from './../../../src/assets/images/ServiceNavbarLogos/services/mobile app dev.webp';
import nft from './../../../src/assets/images/ServiceNavbarLogos/services/nft.webp';
import productDev from './../../../src/assets/images/ServiceNavbarLogos/services/product dev.webp';
import spm from './../../../src/assets/images/ServiceNavbarLogos/services/soft project management.webp';
import sqa from './../../../src/assets/images/ServiceNavbarLogos/services/software quality assurance.webp';
import staff from './../../../src/assets/images/ServiceNavbarLogos/services/staff augmentation.webp';
import ui from './../../../src/assets/images/ServiceNavbarLogos/services/ui ux design.webp';
import webApp from './../../../src/assets/images/ServiceNavbarLogos/services/web app dev.webp';


import angular from './../../../src/assets/images/ServiceNavbarLogos/courses/angular.webp';
import asp from './../../../src/assets/images/ServiceNavbarLogos/courses/asp.net.webp';
import blockchain from './../../../src/assets/images/ServiceNavbarLogos/courses/blockchain.webp';
import bootstrap from './../../../src/assets/images/ServiceNavbarLogos/courses/bootstrap.webp';
import contentC from './../../../src/assets/images/ServiceNavbarLogos/courses/content writing.webp';
import digitalMarketingC from './../../../src/assets/images/ServiceNavbarLogos/courses/digital marketing.webp';
import graphicC from './../../../src/assets/images/ServiceNavbarLogos/courses/graphic designing.webp';
import java from './../../../src/assets/images/ServiceNavbarLogos/courses/java.webp';
import javascript from './../../../src/assets/images/ServiceNavbarLogos/courses/javascript.webp';
import node from './../../../src/assets/images/ServiceNavbarLogos/courses/node js.webp';
import python from './../../../src/assets/images/ServiceNavbarLogos/courses/python.webp';
import react from './../../../src/assets/images/ServiceNavbarLogos/courses/react js.webp';
import seo from './../../../src/assets/images/ServiceNavbarLogos/courses/seo.webp';
import vue from './../../../src/assets/images/ServiceNavbarLogos/courses/vuejs.webp';

import attendence from './../../../src/assets/images/ServiceNavbarLogos/hcm360/attendence.webp';
import automated from './../../../src/assets/images/ServiceNavbarLogos/hcm360/automated rules.webp';
import benefits from './../../../src/assets/images/ServiceNavbarLogos/hcm360/benefits.webp';
import custody from './../../../src/assets/images/ServiceNavbarLogos/hcm360/custody.webp';
import decision from './../../../src/assets/images/ServiceNavbarLogos/hcm360/decision.webp';
import employeeSelfService from './../../../src/assets/images/ServiceNavbarLogos/hcm360/employee self service.webp';
import endService from './../../../src/assets/images/ServiceNavbarLogos/hcm360/end of service.webp';
import insightfulDashboard from './../../../src/assets/images/ServiceNavbarLogos/hcm360/insightful dashboard.webp';
import job from './../../../src/assets/images/ServiceNavbarLogos/hcm360/job portal.webp';
import leaveManage from './../../../src/assets/images/ServiceNavbarLogos/hcm360/leave management.webp';
import letters from './../../../src/assets/images/ServiceNavbarLogos/hcm360/letters.webp';
import loans from './../../../src/assets/images/ServiceNavbarLogos/hcm360/loans.webp';
import memo from './../../../src/assets/images/ServiceNavbarLogos/hcm360/memo.webp';
import payroll from './../../../src/assets/images/ServiceNavbarLogos/hcm360/payroll.webp';
import performance from './../../../src/assets/images/ServiceNavbarLogos/hcm360/performance.webp';
import task from './../../../src/assets/images/ServiceNavbarLogos/hcm360/task.webp';
// import workdaysNotice from './../../../src/assets/images/ServiceNavbarLogos/hcm360/work days notice.webp';
import workflow from './../../../src/assets/images/ServiceNavbarLogos/hcm360/workflow and approvals.webp';

const hcmOne = [
  {
    link: '/impulse-hcm-360/payroll-management',
    image: payroll,
    alt: 'Payroll Management',
    title: 'Payroll Management'
  },
  {
    link: '/impulse-hcm-360/attendance-management',
    image: attendence,
    alt: 'Attendance Tracking',
    title: 'Attendance Tracking'
  },
  {
    link: '/impulse-hcm-360/customizable-leave-management',
    image: leaveManage,
    alt: 'Customizable Leave Management',
    title: 'Customizable Leave Management'
  },
  {
    link: '/impulse-hcm-360/employee-self-service',
    image: employeeSelfService,
    alt: 'Employee Self-Service',
    title: 'Employee Self-Service'
  },
  {
    link: '/impulse-hcm-360/performance-evaluation',
    image: performance,
    alt: 'Performance Evaluation',
    title: 'Performance Evaluation'
  }

];
const hcmTwo = [

  {
    link: '/impulse-hcm-360/task-management',
    image: task,
    alt: 'Task Management',
    title: 'Task Management'
  },
  {
    link: '/impulse-hcm-360/letter-and-forms',
    image: letters,
    alt: 'Letters & Forms',
    title: 'Letters & Forms'
  },
  {
    link: '/impulse-hcm-360/loans-management',
    image: loans,
    alt: 'Loans Management',
    title: 'Loans Management'
  },
  {
    link: '/impulse-hcm-360/benefits-and-expenses-tracking',
    image: benefits,
    alt: 'Benefits & Expenses Tracking',
    title: 'Benefits & Expenses Tracking'
  },
  {
    link: '/impulse-hcm-360/custody-management',
    image: custody,
    alt: 'Custody Management',
    title: 'Custody Management'
  }

];
const hcmThree = [

  {
    link: '/impulse-hcm-360/memo-communication',
    image: memo,
    alt: 'Memo Communication',
    title: 'Memo Communication'
  },
  {
    link: '/impulse-hcm-360/decision-management',
    image: decision,
    alt: 'Decision Management',
    title: 'Decision Management'
  },
  {
    link: '/impulse-hcm-360/end-of-service',
    image: endService,
    alt: 'End-of-Service',
    title: 'End-of-Service'
  },
  {
    link: '/impulse-hcm-360/insightful-dashboard',
    image: insightfulDashboard,
    alt: 'Insightful Dashboard',
    title: 'Insightful Dashboard'
  }

];
const serviceOne = [
  {
    link: "/services/web-app-development",
    image: webApp,
    alt: "Web Development",
    title: "Custom Web App Development"
  },
  {
    link: "/services/mobile-app-development",
    image: mobileApp,
    alt: "Mobile App Development",
    title: "Mobile App Development"
  },
  {
    link: "/services/product-development",
    image: productDev,
    alt: "Product Development",
    title: "Software Product Development"
  },
  {
    link: "/services/enterprise-application-development",
    image: enterprise,
    alt: "Enterprise App Development",
    title: "Enterprise Software Development"
  },
  {
    link: "/services/custom-software-development",
    image: custom,
    alt: "Custom Software Development",
    title: "Custom Software Development"
  }
];
const serviceTwo = [
  {
    link: "/services/artificial-intelligence",
    image: ai,
    alt: "Artificial Intelligence",
    title: "Artificial Intelligence"
  },
  {
    link: "/services/ui-ux-design",
    image: ui,
    alt: "UI/UX Design",
    title: "UI/UX Design"
  },
  {
    link: "/services/software-quality-assurance",
    image: sqa,
    alt: "Software Quality Assurance",
    title: "Software Quality Assurance"
  },
  {
    link: "/services/software-project-management",
    image: spm,
    alt: "Software Project Management",
    title: "Software Project Management"
  },
  {
    link: "/services/blockchain-development",
    image: blockchainDev,
    alt: "Blockchain Development",
    title: "Blockchain Development"
  }
];
const serviceThree = [
  {
    link: "/services/graphic-designing",
    image: graphic,
    alt: "Graphic Designing",
    title: "Graphic Designing"
  },
  {
    link: "/services/digital-marketing-services",
    image: digitalMarketing,
    alt: "Digital Marketing",
    title: "Digital Marketing"
  },
  {
    link: "/services/content-writing",
    image: content,
    alt: "Content Writing",
    title: "Content Writing"
  },
  {
    link: "/services/metaverse",
    image: metaverse,
    alt: "Metaverse",
    title: "Metaverse"
  }
];

const courseOne = [
  {
    link: "/courses/javascript",
    image: javascript,
    alt: "JavaScript",
    title: "JavaScript"
  },
  {
    link: "/courses/react-js",
    image: react,
    alt: "React Js",
    title: "React Js"
  },
  {
    link: "/courses/angular",
    image: angular,
    alt: "Angular",
    title: "Angular"
  },
  {
    link: "/courses/vuejs",
    image: vue,
    alt: "Vue Js",
    title: "Vue Js"
  }
];
const courseTwo = [
  {
    link: "/courses/python",
    image: python,
    alt: "Python (Django)",
    title: "Python (Django)"
  },
  {
    link: "/courses/asp.net",
    image: asp,
    alt: "ASP .Net",
    title: "ASP .Net"
  },
  {
    link: "/courses/java",
    image: java,
    alt: "Java",
    title: "Java"
  },
  {
    link: "/courses/blockchain",
    image: blockchain,
    alt: "Blockchain",
    title: "Blockchain"
  }
];
const courseThree = [
  {
    link: "/courses/graphic-designing",
    image: graphicC,
    alt: "Graphic Designing",
    title: "Graphic Designing"
  },
  {
    link: "/courses/online-digital-marketing-course",
    image: digitalMarketingC,
    alt: "Digital Marketing",
    title: "Digital Marketing"
  },
  {
    link: "/courses/content-writing-course",
    image: contentC,
    alt: "Content Writing",
    title: "Content Writing"
  }
];
const Nav = () => {

  return (
    <ul className="mainmenu">
      <li className="has-droupdown">
        <Link to="/">Home</Link>
      </li>

      <li className="has-droupdown">
        <Link to="/about-us">
          About
        </Link>
        <ul className="submenu">
          <li>
            <Link to="/about-us">About us</Link>
          </li>
          <li>
            <Link to="/team">Team</Link>
          </li>
          <li>
            <Link to="/appreciation">Appreciation</Link>
          </li>

        </ul>
      </li>

      {/* hcm navbar */}
      {/* <AiFillCaretDown /> */}
      <li className="with-megamenu">
        <Link to="/impulse-hcm-360">
          Impulse HCM 360&#176;
        </Link>
        <div className="container rn-megamenu">
          <div className="wrapper">
            <div className="row row--0">
              <div className="col-lg-4 single-mega-item">
                {/* row 1 */}
                <ul className="mega-menu-item">
                  {hcmOne.map((service, index) => (
                    <li key={index} className="service-navbar-border">
                      <Link to={service.link}>
                        <p className="service-navbar-title mt-3">
                          <span>
                            <LazyLoad><img src={service.image} alt={service.alt} className="service-navbar-image" /></LazyLoad>
                          </span>
                          <span>{service.title}</span>
                        </p>
                      </Link>
                    </li>
                  ))}
                  <li>
                    <Link to="/impulse-hcm-360/job-portal">
                      <p className="service-navbar-title mt-3">
                        <span>
                          <LazyLoad><img src={job} alt='Job Portal' className="service-navbar-image" /></LazyLoad>
                        </span>
                        <span>Job Portal </span> </p>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg-4 single-mega-item">
                <ul className="mega-menu-item">
                  {/* row 2 */}
                  {hcmTwo.map((service, index) => (
                    <li key={index} className="service-navbar-border">
                      <Link to={service.link}>
                        <p className="service-navbar-title mt-3">
                          <span> <LazyLoad><img src={service.image} alt={service.alt} className="service-navbar-image" /></LazyLoad></span>
                          <span>{service.title}</span>
                        </p>
                      </Link>
                    </li>
                  ))}
                  <li>
                    <Link to="/impulse-hcm-360/workflow-and-approvals">
                      <p className="service-navbar-title mt-3" >
                        <span> <LazyLoad><img src={workflow} alt='Workflow & Approvals' className="service-navbar-image" /></LazyLoad></span>
                        <span> Workflow & Approvals</span>
                      </p>
                    </Link>
                  </li>
                </ul>

              </div>
              <div className="col-lg-4 single-mega-item">
                <ul className="mega-menu-item">
                  {/* row 3 */}
                  {hcmThree.map((service, index) => (
                    <li key={index} className="service-navbar-border">
                      <Link to={service.link}>
                        <p className="service-navbar-title mt-3">
                          <span> <LazyLoad><img src={service.image} alt={service.alt} className="service-navbar-image" /></LazyLoad></span>
                          <span>{service.title}</span>
                        </p>
                      </Link>
                    </li>
                  ))}
                  <li>
                    <Link to="/impulse-hcm-360/automated-rules-and-regulations">
                      <p className="service-navbar-title mt-3">
                        <span>  <LazyLoad><img src={automated} alt='Automated Rules & Regulations' className="service-navbar-image" /></LazyLoad></span>
                        <span> Automated Rules & Regulations</span>
                      </p>
                    </Link>
                  </li>
                  <Link to="/impulse-hcm-360">
                    <button className="btn-default talk mt-3">
                      All Solutions
                    </button>
                  </Link>

                </ul>
              </div>
            </div>
          </div>
        </div>
      </li>
      {/* services navbar */}
      <li className="with-megamenu">
        <Link to="/services">
          Services
        </Link>
        <div className="container rn-megamenu">
          <div className="wrapper">
            <div className="row row--0">
              <div className="col-lg-4 single-mega-item">
                <ul className="mega-menu-item">
                  {/* row 1 */}
                  {serviceOne.map((menuItem, index) => (
                    <li className="service-navbar-border" key={index}>
                      <Link to={menuItem.link}>
                        <p className="service-navbar-title mt-3">
                          <span> <LazyLoad><img src={menuItem.image} alt={menuItem.alt} className="service-navbar-image" /></LazyLoad></span>
                          <span>{menuItem.title}</span>
                        </p>
                      </Link>
                    </li>
                  ))}
                  <li>
                    <Link to="/services/iot-development">
                      <p className="service-navbar-title mt-3">
                        <span> <LazyLoad><img src={iot} alt='IoT Development' className="service-navbar-image" /></LazyLoad></span>
                        <span> Internet of Things Development</span></p>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg-4 single-mega-item">
                <ul className="mega-menu-item">
                  {/* row 2 */}
                  {serviceTwo.map((menuItem, index) => (
                    <li className="service-navbar-border" key={index}>
                      <Link to={menuItem.link}>
                        <p className="mt-3 service-navbar-title">
                          <span> <LazyLoad><img src={menuItem.image} alt={menuItem.alt} className="service-navbar-image" /></LazyLoad></span>
                          <span>{menuItem.title}</span>
                        </p>
                      </Link>
                    </li>
                  ))}
                  <li>
                    <Link to="/services/staff-augmentation">
                      <p className="service-navbar-title mt-3">
                        <span>  <LazyLoad><img src={staff} alt='Staff Augmentation' className="service-navbar-image" /></LazyLoad></span>
                        <span> Staff Augmentation</span>
                      </p>
                    </Link>
                  </li>
                </ul>

              </div>
              <div className="col-lg-4 single-mega-item">
                <ul className="mega-menu-item">

                  {/* row 3 */}
                  {serviceThree.map((menuItem, index) => (
                    <li className="service-navbar-border" key={index}>
                      <Link to={menuItem.link}>
                        <p className="mt-3 service-navbar-title">
                          <span> <LazyLoad><img src={menuItem.image} alt={menuItem.alt} className="service-navbar-image" /></LazyLoad></span>
                          <span>{menuItem.title}</span>
                        </p>
                      </Link>
                    </li>
                  ))}
                  <li>
                    <Link to="/services/nft">
                      <p className="service-navbar-title mt-3">
                        <span>  <LazyLoad><img src={nft} alt='NFT' className="service-navbar-image" /></LazyLoad></span>
                        <span> NFT'S (Non Fungible Token)</span>
                      </p>
                    </Link>
                  </li>
                  <Link to="/services">
                    <button className="btn-default talk mt-3">
                      Explore More
                    </button>
                  </Link>

                </ul>
              </div>
            </div>
          </div>
        </div>
      </li>
      {/* courses navbar */}
      <li className="with-megamenu">
        <Link to='/courses'>
          Courses
        </Link>
        <div className="container rn-megamenu">
          <div className="wrapper">
            <div className="row row--0">
              <div className="col-lg-4 single-mega-item">
                <ul className="mega-menu-item">
                  {/* row 1 */}
                  <h5 style={{ color: "#700000", marginLeft: "2.3rem" }}>
                    Frontend Development
                  </h5>
                  {courseOne.map((menuItem, index) => (
                    <li className="service-navbar-border" key={index}>
                      <Link to={menuItem.link}>
                        <p className="mt-3 service-navbar-title">
                          <span> <LazyLoad><img src={menuItem.image} alt={menuItem.alt} className="service-navbar-image" /></LazyLoad></span>
                          <span>{menuItem.title}</span>
                        </p>
                      </Link>
                    </li>
                  ))}
                  <li>
                    <Link to="/courses/bootstrap-online-course">
                      <p className="mt-3 service-navbar-title">
                        <span>  <LazyLoad><img src={bootstrap} alt='Bootstrap' className="service-navbar-image" /></LazyLoad></span>

                        <span> Bootstrap</span>
                      </p></Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg-4 single-mega-item">
                <ul className="mega-menu-item">
                  {/* row 2 */}
                  <h5 style={{ color: "#700000", marginLeft: "2.3rem" }}>
                    Backend Development
                  </h5>
                  {courseTwo.map((menuItem, index) => (
                    <li className="service-navbar-border" key={index}>
                      <Link to={menuItem.link}>
                        <p className="mt-3 service-navbar-title">
                          <span> <LazyLoad><img src={menuItem.image} alt={menuItem.alt} className="service-navbar-image" /></LazyLoad></span>
                          <span>{menuItem.title}</span>
                        </p>
                      </Link>
                    </li>
                  ))}
                  <li>
                    <Link to="/courses/nodejs">
                      <p className="mt-3 service-navbar-title">
                        <span>  <LazyLoad><img src={node} alt='Node js' className="service-navbar-image" /></LazyLoad></span>
                        <span>  Node js</span>
                      </p></Link>
                  </li>

                </ul>
              </div>
              <div className="col-lg-4 single-mega-item">
                <ul className="mega-menu-item">
                  {/* row 3 */}
                  <h5 style={{ color: "#800000", marginLeft: "2.3rem" }}>
                    All Courses
                  </h5>
                  {courseThree.map((menuItem, index) => (
                    <li className="service-navbar-border" key={index}>
                      <Link to={menuItem.link}>
                        <p className="mt-3 service-navbar-title">
                          <span> <LazyLoad><img src={menuItem.image} alt={menuItem.alt} className="service-navbar-image" /></LazyLoad></span>
                          <span>{menuItem.title}</span>
                        </p>
                      </Link>
                    </li>
                  ))}
                  <li >
                    <Link to="/courses/seo-course">
                      <p className="mt-3 service-navbar-title">
                        <span>  <LazyLoad><img src={seo} alt='SEO' className="service-navbar-image" /></LazyLoad></span>
                        <span> SEO</span>
                      </p></Link>
                  </li>

                  <Link to="/courses">
                    <button className="btn-default talk mt-3">
                      All Courses
                    </button>
                  </Link>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </li>

      <li className="has-droupdown">
        <Link to="/about-us">
          Explore
        </Link>
        <ul className="submenu">

          <li className="has-droupdown">
            <Link to="/blogs">Blogs</Link>
          </li>
          {/* <li className="has-droupdown">
            <Link to="/impulse-hcm-360/payroll">HCM</Link>
          </li> */}
          {/* <li className="has-droupdown">
            <Link to="/blogs/fintech-ways-in-pakistan">Blogs First</Link>
          </li> */}
          <li className="has-droupdown">
            <Link to="/career">Career</Link>
          </li>
          <li className="has-droupdown">
            <Link to="/portfolio">Portfolio</Link>
          </li>
          <li className="has-droupdown">
            <Link to="/faq-page">FAQs</Link>
          </li>
        </ul>
      </li>
      <li className="has-droupdown">
        <Link to="/podcast">Podcast</Link>
      </li>
      <li className="has-droupdown">
        <Link to="/contact">Contact us</Link>
      </li>

    </ul>
  );
};
export default Nav;
