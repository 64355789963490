import React from 'react';
import Slider from 'react-slick';
import four from '../assets/images/bg/call-miner.webp';
import three from '../assets/images/bg/process.webp';
import two from '../assets/images/bg/development.webp';
import one from '../assets/images/bg/legacy.webp';
import five from '../assets/images/bg/legacyTwo.webp';

import SEO from '../common/SEO';
import { BannerActivation } from '../utils/script';

const BannerData = [
  {
    image: one,
    title: 'Quadient Inspire Services',
    heading:` Your Trusted Partner in Building Powerful Customer Communications Management (CCM) Solutions`,
    description:` Increase engagement and streamline communication workflows with our expertise in Robotic
     Process Automation (RPA), Process Mining, Web Scraping, and Document Processing.`
  },
  {
    image: two,
    title: 'Development Teams',
    heading: `Staff Up Quickly with Our Cost-Effective IT Staffing Solutions`,
    description: `Zai Systems provides flexible, pre-vetted developers for both onsite and virtual needs.
     We build strong partnerships to connect you with the right talent, maximizing your project's success.`
  },
  {
    image: three,
    title: 'Process Automation',
    heading: `Innovative Process Automation Solutions`,
    description:` Our team of automation specialists leverages leading technologies like RPA and Process
     Mining to optimize your workflows, improve accuracy, and free up valuable resources.`
  },
  {
    image:four,
    title: 'CallMiner Solutions',
    heading: `Your Certified CallMiner Partner for Maximized Value`,
    description: `Zai Systems empowers you to leverage the full spectrum of CallMiner's capabilities,
     unlocking actionable insights to enhance customer experience and drive business growth.`
  },
  {
    image:five,
    title: 'Legacy Migration',
    heading: `Modernize Your Legacy Applications with .NET Migration`,
    description: `Breathe new life into your outdated VFP, VB6, and Classic ASP applications. Zai Systems
     offers expert migration services to .NET, ensuring improved security, performance, and a competitive edge for your business.`
  }
];

const TravelAgency = () => {
  return (
    <>
      <SEO title='xs' />
      <main className='page-wrapper'>
        {/*
                <HeaderOne btnStyle="btn-small" HeaderSTyle="header-transparent" /> */}

        {/* Start Slider Area  */}
        <Slider
          className='slider-area slider-style-4 slider-dot rn-slick-dot rn-slick-arrow'
          {...BannerActivation}
        >
          {BannerData.map((data, index) => (
            <div key={index} className='single-slide'>
              <div
                className='height-750 bg_image'
                data-black-overlay='8'
                style={{
                  backgroundImage: `url(${data.image})`
                }}
              >
                <div className='container'>
                  <div className='row row--30 align-items-center'>
                    <div className='col-12'>
                      <div className='inner text-center'>
                        <h4
                          className='title'
                          dangerouslySetInnerHTML={{ __html: data.title }}
                        ></h4>
                        <h5>{data.heading}</h5>
                        <p
                          className='description text-center'
                          dangerouslySetInnerHTML={{ __html: data.description }}
                        ></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
        {/* End Slider Area  */}
      </main>
    </>
  );
};

export default TravelAgency;
