import React, { useEffect, useState } from 'react'
import HeaderOne from '../../../common/header/HeaderOne'
import FooterTwo from '../../../common/footer/FooterTwo'
import Copyright from '../../../common/footer/Copyright';
import { useParams } from 'react-router-dom'
import { ServiceData } from '../../../data/servicesdata'
import SEO from '../../../common/SEO';
import { Link } from "react-router-dom";
import CalltoActionFive from '../../calltoaction/CalltoActionFive';
import tick from './../../../assets/images/servicePagesLogos/logos/tick final.webp'
import LazyLoad from "react-lazyload";
import Carousel from './Carousel';
import ContactForm from '../../../common/contactForm/ContactSection';
import AboutThree from '../../../elements/about/AboutThree';
import ContactOne from '../../../elements/contact/ContactOne';
import TeamOne from '../../../elements/team/TeamOne';
import ScrollSection from './ScrollSection';
import BlogClassicData from '../../../data/blog/BlogList.json';
import BlogListTwo from '../../../components/blog/itemProp/BlogListTwo';
import Testimonials from './Testimonials';
import { Box, Container, Divider, Grid, List, ListItem, ListItemText } from '@mui/material';


const items = [
  { img: 'path/to/image1.jpg', title: 'Item 1', description: 'Description 1' },
  { img: 'path/to/image2.jpg', title: 'Item 2', description: 'Description 2' },
  { img: 'path/to/image3.jpg', title: 'Item 3', description: 'Description 3' },
  { img: 'path/to/image4.jpg', title: 'Item 4', description: 'Description 4' },
  { img: 'path/to/image5.jpg', title: 'Item 5', description: 'Description 5' },
  // Add more items as needed
];

const SCD = ({ textAlign, serviceStyle }) => {
  const { service } = useParams()
  const newList = ServiceData.filter((v) => v.service === service);
  console.log();
  let getSection;
  let sectionSize;

  const [visibleItems, setVisibleItems] = useState([]);
  const [dataVisibleCount, setDataVisibleCount] = useState(10);
  useEffect(() => {
    setVisibleItems(BlogClassicData.filter((item) => item.id <= dataVisibleCount));
  }, [dataVisibleCount, BlogClassicData]);
  return (

    <>
      <HeaderOne />
      <div className='rwt-portfolio-details rn-section-gap '>
        {/* <div className='container services-main-div'>     old */}
        <div className=' services-main-div'>
          <div className='row'>
            {newList.map(
              ({

                list,
                servicehead,
                listAud,
                listTemp,
                listService,
                listRoute,
                listRouteb,
                listTools,
                listBenefit,
                listWhy,
                listCourse,
                listCertificate,
                listadvantage,
                listcard,
                listcarddetails,
                listdata,
                listdatadetails,
                listHiring,
                listHiringDetails,
                listConclusion,
                listImages,
                listImagesDetails,
                listnftExtra,
                listnftExtraDetails,
                listWeBestHead,
                listWeBest,
                listWhyUsHead,
                listWhyUsDis,
                industrieshead,
                industrieslist1,
                industrieslist2,
                getStarted,
                visitServiceshead,
                visitServiceslist,
                listSuccessStoriesHead,
                listSuccessStoriesDis,
                listTestimonials,
                FAQs,
                listForSeo
              }) => (
                <>
                  {getSection = (start, end) => listRoute.slice(start, end)}

                  <SEO>
                    {listForSeo.map(({ id, title, content, link }) => (
                      <React.Fragment key={id}>
                        <title>{title}</title>
                        <meta name='description' content={content} />
                        <meta name='robots' content='index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1' />
                        <link rel='canonical' href={link} />
                      </React.Fragment>
                    ))}
                  </SEO>
                  {/* ============== Header*/}
                  <div className='col-lg-12 '>
                    {/* service first */}
                    <div className="container">
                      {list !== undefined &&
                        list.map(({ id, title, para, para1, img }) => (
                          <div className=" row">{/* service-one */}
                            <div className="top2 col-md-6 align-content-center" key={id}>
                              {title && (
                                <h1 className="service-heading-one">
                                  {title}
                                </h1>
                              )}

                              <p className='para-one  fs-3'>{para}</p>
                              <p className='para-one'>{para1}</p>
                              <div className="mt-6">

                                <Link to="/contact">
                                  <button class="btn-default mt-4">Get Free Consultation </button>
                                </Link>
                              </div>
                            </div>
                            <div className="col-md-6 d-flex  justify-content-center align-items-center">
                              <LazyLoad>
                                <img alt={title} className="w-75 my-auto mx-auto d-block" src={img} />
                              </LazyLoad>
                            </div>

                          </div>
                        ))}
                    </div>

                    {/* service second */}
                    {/* 
                    <div className=" service-two ">
                      {listAud !== undefined &&
                        listAud.map((i) => (
                          <div key={i.id}>
                            <h2 className="service-heading-two">
                              {i.title}
                            </h2>
                            <p className="para-two">
                              {i.para}
                            </p>
                            <p className="para-two">
                              {i.para1}
                            </p>
                            <p className="para-two">
                              {i.para2}
                            </p>
                          </div>
                        ))}

                    </div> */}
                    <div className="service-two w-75 ms-auto me-auto">
                      {listAud !== undefined &&
                        listAud.map((i) => (
                          <div key={i.id}>
                            {i.title && <h2 className="service-heading-two">{i.title}</h2>}
                            {i.para && <p className="para-two">{i.para}</p>}
                            {i.para1 && <p className="para-two">{i.para1}</p>}
                            {i.para2 && <p className="para-two">{i.para2}</p>}
                          </div>
                        ))}
                    </div>
                    {/* for extra data */}
                    <div className="row mt-0">
                      {listdata !== undefined &&
                        listdata.map((i) => (
                          <>
                            <h2 key={i.id} className='extra-heading'> {i?.title1}</h2>
                            {/* <div className="col-md-10 mx-auto text-center"> */}
                            <p key={i.id} className='extra-text'> {i?.para}</p>
                            <p key={i.id}> {i?.para1}</p>
                            {/* </div> */}

                          </>
                        ))}

                      {listdatadetails !== undefined &&
                        listdatadetails.map((service) => {
                          return (
                            < div className='col-md-11 mx-auto text-center' >
                              <p className='extra-text2'>
                                {/* <i class="fa-solid fa-angle-right "></i> &nbsp; */}
                                <span>{service?.para}</span>
                              </p>
                            </div>
                          )
                        })}


                    </div>
                    {/* service third */}

                    {/* {listTemp !== undefined &&
                    listTemp.map((i) => (
                      <div className='service-three'> <div key={i.id}>
                        <div>
                          <h1 className=" service-three-head mt-5">{i.title}</h1>
                        </div>
                        <div>
                          <button class="btn-default talk">
                            Talk to Our Experts
                          </button>
                        </div>

                      </div>
                      </div>
                    ))} */}
                    {/* service cards fourth  */}
                    <div className='row '>
                      <div className='in-Contrast-to-Black-Color py-5 px-5'>
                        {/* old h2 main section heading */}
                        {listService !== undefined &&
                          listService.map((i) => (
                            <>
                              <h2 key={i.id} className='card-heading text-white my-5'>
                                {i?.title}
                              </h2>
                              {i.para && <p className="card-text w-75 mx-auto fs-3">{i.para}</p>}
                              {i.para1 && <p className="card-text">{i.para1}</p>}
                            </>
                          ))}
                      </div>
                      {servicehead !== undefined &&
                        servicehead.map((data, key) => (
                          <div className='text-center h2 pt-5 mt-5 mb-5 w-50 ms-auto me-auto'>
                            {data.para}
                          </div>
                        ))
                      }

                      {/* ============== */}
                      <div className="upper">
                        {listRouteb !== undefined &&
                          listRouteb.map((service, index) => {
                            const isEven = index % 2 === 0;

                            return (
                              <React.Fragment key={index}>
                                <div
                                  // className={`upper-one ${isEven ? 'bg-navy text-left' : 'bg-white text-right'}`}
                                  className={`upper-one section-height ${isEven ? '' : 'in-Contrast-to-Black-Color'}`}
                                >
                                  <div className="container">
                                    <div className="row">
                                      {isEven ? (
                                        <>
                                          <div className="col-lg-6 my-auto">
                                            <h3 className='card-head-two'>{service?.title}</h3>
                                            {service?.para && <p className='para-twoo'>{service?.para}</p>}
                                            {service?.para1 && <p>{service?.para1}</p>}
                                            {service?.para2 && <p>{service?.para2}</p>}
                                            {/* <Link to="/contact">
                                              <button className="btn-default mt-4">Learn More</button>
                                            </Link> */}
                                          </div>
                                          <div className="col-lg-6">
                                            {service.list !== undefined &&
                                              <nav aria-label="secondary mailbox folders">
                                                <List>
                                                  {service.list.map((item, key) => (
                                                    <>
                                                      <ListItem disablePadding key='key'>
                                                        <div>
                                                          <h4>{item.title}</h4>
                                                          {item.para}
                                                        </div>
                                                      </ListItem>
                                                    </>
                                                  ))}
                                                </List>
                                              </nav>
                                            }
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <div className="col-lg-6">
                                            {/* <LazyLoad><img className='w-75 mx-auto d-block' src={service?.img} width={'auto'} height={'auto'} alt={service?.title} /></LazyLoad> */}
                                            {service.list !== undefined &&
                                              <nav aria-label="secondary mailbox folders">
                                                <List>
                                                  {service.list.map((item, key) => (
                                                    <>
                                                      <ListItem disablePadding key='key'>
                                                        <div>
                                                          <h4>{item.title}</h4>
                                                          {item.para}
                                                        </div>
                                                      </ListItem>
                                                    </>
                                                  ))}
                                                </List>
                                              </nav>
                                            }
                                          </div>
                                          <div className="col-lg-6 my-auto">
                                            <h3 className='card-head-two text-white'>{service?.title}</h3>
                                            {service?.para && <p className='para-twoo'>{service?.para}</p>}
                                            {service?.para1 && <p>{service?.para1}</p>}
                                            {service?.para2 && <p>{service?.para2}</p>}
                                            {/* <Link to="/contact">
                                              <button className="btn-default mt-4">Learn More</button>
                                            </Link> */}
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </div>

                              </React.Fragment>
                            );
                          })}
                      </div>
                      {/* ============== Mojority */}
                      <div className="upper">
                        {listRoute !== undefined &&
                          listRoute.map((service, index) => {
                            const isEven = index % 2 === 0;

                            return (
                              <div
                                className={`upper-one section-height ${isEven ? '' : 'in-Contrast-to-Black-Color'}`}
                                key={index}
                              >
                                <div className="container">
                                  <div className="row">
                                    {isEven ? (
                                      <>
                                        <div className="col-lg-6 my-auto justify">
                                          <h3 className='card-head-two'>{service?.title}</h3>
                                          {service?.para && <p className='para-twoo'>{service?.para}</p>}
                                          {service?.para1 && <p>{service?.para1}</p>}
                                          {service?.para2 && <p>{service?.para2}</p>}
                                          {service.list !== undefined &&
                                            <nav aria-label="secondary mailbox folders">
                                              <List>
                                                {service.list.map((listitem, key) => (
                                                  <ListItem disablePadding key={key}>
                                                    <div className='box-lists'>{listitem.para}</div>
                                                  </ListItem>
                                                ))}
                                              </List>
                                            </nav>
                                          }
                                        </div>
                                        <div className="col-md-6 d-flex justify-content-center align-items-center">
                                          <LazyLoad>
                                            <img className='w-75 ms-auto d-block' src={service?.img} width={'auto'} height={'auto'} alt={service?.title} />
                                          </LazyLoad>
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <div className="col-md-6 d-flex justify-content-center align-items-center">
                                          <LazyLoad>
                                            <img className='w-75 me-auto d-block' src={service?.img} width={'auto'} height={'auto'} alt={service?.title} />
                                          </LazyLoad>
                                        </div>
                                        <div className="col-lg-6 my-auto">
                                          <h3 className='card-head-two text-white'>{service?.title}</h3>
                                          {service?.para && <p className='para-twoo'>{service?.para}</p>}
                                          {service?.para1 && <p>{service?.para1}</p>}
                                          {service?.para2 && <p>{service?.para2}</p>}
                                          {service.list !== undefined &&
                                            <nav aria-label="secondary mailbox folders">
                                              <List>
                                                {service.list.map((listitem, key) => (
                                                  <ListItem disablePadding key={key}>
                                                    <div className='box-lists'>{listitem.para}</div>
                                                  </ListItem>
                                                ))}
                                              </List>
                                            </nav>
                                          }
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                      {/* ============== */}
                      <div className=''>
                        <Carousel
                          items={items}
                          heading2={'Our Portfolio'}
                          headingColor={'text-white'}
                          carouselContainerbg={'carousel-container-background-color1'}
                          data={visibleItems}
                          btnText={"Place your Order"}
                          bgColor='bg-black'
                        />
                      </div>
                      {/* ============== */}
                      <ScrollSection title={listcard} listcarddetails={listcarddetails} />
                      <ScrollSection bgColor={'in-Contrast-to-Black-Color'} textColor={'text-white'} title={listWhy} listcarddetails={listTools} />
                      {/* ============== Industries*/}
                      {industrieshead !== undefined &&
                        <Box sx={{ bgcolor: '#000', color: "white", pb: '6%', pt: '3%' }}>
                          <Container maxWidth={'lg'}>
                            {industrieshead.map((data, key) => (
                              <div className='h2 text-center ms-auto me-auto mb-5'>{data.title}</div>
                            ))}
                            <Box sx={{
                              width: '100%', bgcolor: 'transparent'
                            }}>
                              <Grid container spacing={'40'}>
                                <Grid item xs={'12'} md={'6'} lg='6'>
                                  <nav aria-label="secondary mailbox folders">
                                    <List>
                                      {industrieslist1.map((data, key) => (
                                        <>
                                          <ListItem disablePadding key='key'>
                                            {data.title}
                                          </ListItem>
                                          <Divider />
                                        </>
                                      ))}
                                    </List>
                                  </nav>
                                </Grid>
                                <Grid item xs={'12'} md={'6'}>
                                  <nav aria-label="secondary mailbox folders">
                                    <List>
                                      {industrieslist2.map((data, key) => (
                                        <>
                                          <ListItem disablePadding key='key'>
                                            {data.title}
                                          </ListItem>
                                          <Divider />
                                        </>
                                      ))}
                                    </List>
                                  </nav>
                                </Grid>
                              </Grid>
                            </Box>
                          </Container>
                        </Box>
                      }
                      {/* ============== Success Stories*/}
                      {listSuccessStoriesHead !== undefined &&
                        <Box sx={{ py: '6%' }}>
                          <Container maxWidth={'lg'}>
                            {listSuccessStoriesHead.map((data, key) => (
                              <div className='h2 text-center ms-auto me-auto mb-5'>{data.title}</div>
                            ))}

                            <Grid container spacing={5} mt={'3%'}>
                              {
                                listSuccessStoriesDis.map((data, key) => (
                                  <Grid item xs={12} md={4} lg={4} key={key}>
                                    <Link to={`/portfolio/${data.portfolio}`} className="rn-card">
                                      <div className="flex flex-col" style={{ height: '280px', alignItems: 'center' }}>
                                        <div className="thumbnail">
                                          <LazyLoad>
                                            <img
                                              src={`${process.env.PUBLIC_URL}/${data.image}`}
                                              alt="Portfolio"
                                              style={{ height: '200px', width: '100%' }}
                                            />
                                          </LazyLoad>
                                        </div>
                                        <div className="content">
                                          <h4 className="title" style={{ textAlign: 'center', marginTop: '20px', marginBottom: '20px' }}>
                                            {data.title}
                                          </h4>
                                        </div>
                                      </div>
                                    </Link>
                                  </Grid>
                                ))
                              }

                            </Grid>
                          </Container>
                        </Box>
                      }

                      {visitServiceshead !== undefined &&
                        <Box sx={{ py: '6%' }}>
                          <Container maxWidth={'lg'}>
                            {visitServiceshead.map((data, key) => (
                              <div className='h2 text-center ms-auto me-auto mb-5'>{data.title}</div>
                            ))}

                            <Grid container spacing={5} mt={'3%'}>
                              {
                                visitServiceslist.map((data, key) => (
                                  <Grid item xs='12' md='4' lg='4'>
                                    <Link to='/services/mobile-app-development' key={key}>
                                      <Box sx={{ width: '100%', height: '150px', bgcolor: '#1A1A1A', borderRadius: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        {data.title}
                                      </Box>
                                    </Link>
                                  </Grid>
                                ))
                              }
                            </Grid>
                          </Container>
                        </Box>
                      }
                      {/* ============== */}
                      <div className='upper'>
                        <Carousel
                          items={items}
                          heading2={'Testimonials'}
                          carouselContainerbg={'carousel-container-background-color1'}
                          data={listTestimonials}
                          comp={Testimonials}
                          btnText={"Book Consultancy"}
                          bgColor='bg-black'
                        />
                      </div>

                      {/* old */}
                      {/* <div className="row upper">
                        {listRoute !== undefined &&
                          listRoute.map((service) => {
                            return (
                              <>
                                <div className="col-md-4 ">
                                  < div className='upper-one bg-color-blackest mt-5 radius   '>
                                  <LazyLoad><img src={service?.img} width={'auto'} height={'auto'} alt={service?.title} /></LazyLoad>
                                    <h3 className='card-head-two'> {service?.title}</h3>

                                    {service?.para && <p>{service?.para}</p>}
                                    {service?.para1 && <p>{service?.para1}</p>}
                                    {service?.para2 && <p>{service?.para2}</p>}
                                  </div>
                                </div>
                              </>
                            )
                          })}
                      </div> */}
                    </div >

                    {/* service extra cards   */}
                    {/* < div className='row mt-5' >
                      {listcard !== undefined &&
                        listcard.map((i) => (
                          <>
                            <h2 key={i.id} className='card-heading'>
                              {i?.title}
                            </h2>
                            {i.para && <p className="card-text">{i.para}</p>}
                            {i.para1 && <p className="card-text">{i.para1}</p>}
                            {i.para2 && <p className="card-text">{i.para2}</p>}
                          </>
                        ))
                      }
                      < div className="row upper">
                        {listcarddetails !== undefined &&
                          listcarddetails.map((service) => {
                            return (

                              <div className="col-md-4 ">
                                < div className='upper-one  bg-color-blackest mt-5 radius'>
                                  <LazyLoad><img src={service?.img} width={'auto'} height={'auto'} alt={service?.title} /></LazyLoad>
                                  <h3> {service?.title}</h3>
                                  //<p>{service?.para}</p>
                                //<p>{service?.para1}</p>
                                //<p>{service?.para2}</p>
                                  {service?.para && <p>{service?.para}</p>}
                                  {service?.para1 && <p>{service?.para1}</p>}
                                  {service?.para2 && <p>{service?.para2}</p>}

                                </div>
                              </div>
                            )
                          })}
                      </div>
                    </div> */}

                    {/* for points extra */}
                    < div className="row extra-points" >
                      {listCertificate !== undefined &&
                        listCertificate.map((i) => (
                          <>
                            <h2 key={i.id} className='extra-heading'> {i?.title}</h2>
                            <p key={i.id} className='extra-text'> {i?.para}</p>
                            <p key={i.id} className='extra-text'> {i?.para1}</p>

                          </>
                        ))}


                      {listadvantage !== undefined &&
                        listadvantage.map((service) => {
                          return (
                            < div className='col-md-12 ' >
                              <p className='extra-text2'><i class="fa-solid fa-angle-right arrow-head "></i>
                                &nbsp;<span>{service?.para}</span></p>
                            </div>
                          )
                        })}


                    </div>

                    {/* for extra slugs */}
                    <div className='row images-and-heading'>
                      {listImages !== undefined &&
                        listImages.map((i) => (
                          <>
                            <h2 key={i.id} className='card-heading'>
                              {i?.title}
                            </h2>
                            {i.para && <p className="card-text">{i.para}</p>}
                            {i.para1 && <p className="card-text">{i.para1}</p>}
                          </>
                        ))}
                      <div className="row upper">
                        {listImagesDetails !== undefined &&
                          listImagesDetails.map((service) => {
                            return (
                              <>
                                <div className="col-md-4 ">
                                  < div className='upper-one bg-color-blackest mt-5 radius   '>
                                    <LazyLoad> <img src={service?.img} width={'auto'} height={'auto'} alt={service?.title} /></LazyLoad>
                                    <h3 > {service?.title}</h3>
                                    <p>{service?.para}</p>
                                  </div>
                                </div>
                              </>
                            )
                          })}
                      </div>
                      {/* ============== */}
                      <ScrollSection title={listWeBestHead} listcarddetails={listWeBest} />
                      <ScrollSection bgColor={'in-Contrast-to-Black-Color'} textColor={'text-white'} title={listWhyUsHead} listcarddetails={listWhyUsDis} />
                    </div>
                    {/* ============== Get Started*/}
                    {getStarted !== undefined &&
                      getStarted.map((data, key) => (
                        <div className='text-center w-75 ms-auto me-auto mt-4 mb-4 pt-5 pb-5'>
                          <div className='h2 text-white mt-5'>{data.title}</div>
                          <div className='text-white mb-5'>{data.para}</div>
                          <div className='text-white mb-5'>{data.para2}</div>
                        </div>
                      ))}
                    {/* ============== Our Team*/}
                    <div className="container">
                      <h2 className='text-center mt-5 mb-5'>Our Team</h2>
                      <TeamOne
                        column='col-lg-4 col-md-6 col-12 mt--10'
                        teamStyle='team-style-default'
                      />

                    </div>


                    {/* service-fifth */}
                    {/* <div className="row">
                      {listWhy !== undefined &&
                        listWhy.map((i) => (
                          <>
                            <h2 key={i.id} className='heading-fifth'> {i?.title}</h2>
                            //<p key={i.id} className='fifth-para'> {i?.para}</p>
                            //<p key={i.id} className='fifth-para'> {i?.para1}</p>
                            //<p key={i.id} className='fifth-para'> {i?.para2}</p>
                            {i.para && <p className="fifth-para">{i.para}</p>}
                            {i.para1 && <p className="fifth-para">{i.para1}</p>}
                            {i.para2 && <p className="fifth-para">{i.para2}</p>}

                          </>
                        ))} */}


                    {/* {listTools !== undefined &&
                        listTools.map((service) => {
                          return (
                            < div className='col-md-4 mt-3' >

                              <h3 className='heading-fifth2'>
                                //<i className="fa fa-check-circle tick-check" ></i>&nbsp; &nbsp;
                                <LazyLoad><img src={tick} alt='tick-icon' style={{ width: '21px', height: '21px', marginTop: '2px', marginRight: '2px' }} /></LazyLoad> &nbsp;
                                //<span className='icon-service'><FiCheck /></span>

                                <span> {service?.title}</span>
                              </h3>

                              //<p>{service?.para}</p>
                              //<p>{service?.para1}</p>
                              //<p>{service?.para2}</p>
                              //<p>{service?.para3}</p>
                              //<p>{service?.para4}</p>
                              //<p>{service?.para5}</p>
                              {service?.para && <p className='fifth-para2'>{service.para}</p>}
                              {service?.para1 && <p className='fifth-para2'>{service.para1}</p>}
                              {service?.para2 && <p className='fifth-para2'>{service.para2}</p>}
                              {service?.para3 && <p className='fifth-para2'>{service.para3}</p>}
                              {service?.para4 && <p className='fifth-para2'>{service.para4}</p>}
                              {service?.para5 && <p className='fifth-para2'>{service.para5}</p>}
                              {service?.para6 && <p className='fifth-para2'>{service.para6}</p>}

                            </div>
                          )
                        })} 


                    </div>*/}
                    {/* service-six */}
                    {/* <div className='row'>

                      {listCourse !== undefined &&
                        listCourse.map((i) => (
                          <>
                            <h2 key={i.id} className='sheading-fifth'> {i?.title}</h2>
                            {i.para && <p className="fifth-para">{i.para}</p>}
                            {i.para1 && <p className="fifth-para">{i.para1}</p>}
                            {i.para2 && <p className="fifth-para">{i.para2}</p>}

                          </>
                        ))}
                      {listBenefit !== undefined &&
                        listBenefit.map((service) => {
                          return (
                            < div className='col-md-4 mt-0' >

                              <h3 className='heading-fifth2 mt-2'>
                                <i className="fa fa-check-circle tick-check" ></i>&nbsp; &nbsp;
                                <LazyLoad><img src={tick} alt='tick-icon' style={{ width: '21px', height: '21px', marginTop: '2px', marginRight: '2px' }} /></LazyLoad>&nbsp;
                                <span> {service?.title}</span>
                              </h3>
                              {service?.para && <p className='fifth-para2'>{service.para}</p>}
                              {service?.para1 && <p className='fifth-para2'>{service.para1}</p>}
                              {service?.para2 && <p className='fifth-para2'>{service.para2}</p>}
                              {service?.para3 && <p className='fifth-para2'>{service.para3}</p>}
                              {service?.para4 && <p className='fifth-para2'>{service.para4}</p>}
                              {service?.para5 && <p className='fifth-para2'>{service.para5}</p>}
                              {service?.para6 && <p className='fifth-para2'>{service.para6}</p>}

                            </div>
                          )
                        })}

                    </div> */}
                    {/* service-listnftExtra*/}
                    <div className='row my-4 px-4 py-4'>

                      {listnftExtra !== undefined &&
                        listnftExtra.map((i) => (
                          <>
                            <h2 key={i.id} className='heading-fifth'> {i?.title}</h2>
                            {i.para && <p className="fifth-para">{i.para}</p>}
                            {i.para1 && <p className="fifth-para">{i.para1}</p>}
                            {i.para2 && <p className="fifth-para">{i.para2}</p>}
                          </>
                        ))}
                      {listnftExtraDetails !== undefined &&
                        listnftExtraDetails.map((service) => {
                          return (
                            < div className='col-md-4 mt-0 ' >

                              <h3 className='heading-fifth2 mt-2 '>
                                {/* <i className="fa fa-check-circle tick-check" ></i>&nbsp; &nbsp; */}
                                <LazyLoad><img src={tick} alt='tick-icon' style={{ width: '21px', height: '21px', marginTop: '2px', marginRight: '2px' }} /></LazyLoad> &nbsp;
                                <span> {service?.title}</span>
                              </h3>
                              {service?.para && <p className='fifth-para2'>{service.para}</p>}
                              {service?.para1 && <p className='fifth-para2'>{service.para1}</p>}
                              {service?.para2 && <p className='fifth-para2'>{service.para2}</p>}
                              {service?.para3 && <p className='fifth-para2'>{service.para3}</p>}
                              {service?.para4 && <p className='fifth-para2'>{service.para4}</p>}
                              {service?.para5 && <p className='fifth-para2'>{service.para5}</p>}
                            </div>
                          )
                        })}

                    </div>
                    {/* hiring */}

                    <div className="row">
                      {listHiring !== undefined &&
                        listHiring.map((i) => (
                          <>
                            <h2 key={i.id} className='card-heading'> {i?.title}</h2>
                            <p key={i.id} className='card-text'> {i?.para}</p>

                          </>
                        ))}
                      {listHiringDetails !== undefined &&
                        listHiringDetails.map((process) => (
                          <div className="col-md-4" key={process.id}>
                            <h3 className='hiring-head '> {process.head}</h3>
                            <LazyLoad><img src={process?.imageTwo} className="hiring-img" alt={process.head} /></LazyLoad>

                            <p className='hiring-text mt-3'> {process.title}</p>
                            <p className='hiring-text'> {process.title1}</p>
                            <p className='hiring-text'> {process.title2}</p>
                            <p className='hiring-text'> {process.title3}</p>
                            <p className='hiring-text'> {process.title4}</p>
                            <p className='hiring-text'> {process.title5}</p>

                          </div>
                        ))}
                    </div>
                    {/* coclusion */}
                    {/* <div className=" service-two">
                      {listConclusion !== undefined &&
                        listConclusion.map((i) => (
                          <div key={i.id}>
                            <h2 className="service-heading-two ">
                              {i.title}
                            </h2>
                            <p className="para-two">
                              {i.para}
                            </p>

                          </div>
                        ))}

                    </div> */}
                  </div>
                  < CalltoActionFive />
                  {/* ============== FAQs*/}
                  <AboutThree
                    Faq_Class="Faq-Img"
                    style={{ background: 'linear-gradient(127deg, rgba(0,0,0,1) 70%, rgba(51,7,7,1) 100%)' }}
                    color="text-white"
                    FAQs={FAQs}
                  />

                </>
              )
            )}
          </div > {/* row */}
        </div > {/* container */}
      </div >
      {/* ============== Contact Us*/}
      <ContactForm />
      {/* ============== */}
      <div className="in-Contrast-to-Black-Color">
        <div className="container py-5">
          <ContactOne txt_color={'text-white'} heading1={"Mail / Phone / Address"} heading2={""} />
        </div>
      </div>


      <FooterTwo />
      <Copyright />
    </>
  )
}

export default SCD