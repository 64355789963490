import React from 'react';
import about from '../../assets/images/about/faq.webp';
import AccordionOne from '../accordion/AccordionOne';
import LazyLoad from "react-lazyload";

const AbouThree = ({ Faq_Class, bgColor, color, FAQs }) => {
  return (
    <div className='rn-about-area about-style-3 rn-section-gap' style={{ backgroundColor: "bg-black" }}>
      <div className='container'>
        <div className='row row--30'>
          {/* <div className='col-lg-5'>
            <div className='thumbnail'>
              <LazyLoad>
                <img className={Faq_Class} src={about} alt='About' />
              </LazyLoad>
            </div>
          </div> */}
          {/* <div className='col-lg-6 mt_md--40 mt_sm--40'> */}
          <div className='inner'>
            <div className='section-title'>
              <h4 className='subtitle'>
                <span className='theme-gradient'>Questions</span>
              </h4>
              <h2 className={`title mb--40 ${color}`}>Frequently Asked Questions (FAQs)</h2>
            </div>
            <AccordionOne customStyle='' bgColor={"0f0f11"} color={color} FAQs={FAQs} />
          </div>
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default AbouThree;
