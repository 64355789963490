// import React, { useEffect, useState } from 'react';
// import Testimonials from './Testimonials';
// // import BlogClassicData from '../../../data/blog/BlogList.json';
// import BlogListTwo from '../../../components/blog/itemProp/BlogListTwo';

// const Carousel = ({ items, heading2, headingColor, carouselContainerbg, data, comp, btnText, bgColor }) => {
//   // const [visibleItems, setVisibleItems] = useState([]);
//   // const [dataVisibleCount, setDataVisibleCount] = useState(10);
//   //   useEffect(() => {
//   //     setVisibleItems(BlogClassicData.filter((item) => item.id <= dataVisibleCount));
//   // }, [dataVisibleCount, BlogClassicData]);


//   const [currentIndex, setCurrentIndex] = useState(0);
//   const itemsToShow = 3;
//   const totalItems = items.length;

//   const handleNext = () => {
//     setCurrentIndex((prevIndex) => (prevIndex + 1) % totalItems);
//   };

//   const handlePrevious = () => {
//     setCurrentIndex((prevIndex) => (prevIndex - 1 + totalItems) % totalItems);
//   };

//   const translateX = -((currentIndex % totalItems) * (100 / itemsToShow));

//   return (
//     <>
//       {data !== undefined &&
//         <div className={`carousel-container ${carouselContainerbg}`}>
//           <h2 className={`text-center ${headingColor}`}>{heading2}</h2>
//           <button className="carousel-button prev" onClick={handlePrevious}>&lt;</button>
//           <div className="carousel-slider">
//             <div className="carousel-wrapper" style={{ transform: `translateX(${translateX}%)` }}>
//               {data.map((item) => (
//                 <div key={item.id} className="col-lg-4 w-25 me-5 ms-5 mt--30">
//                   {heading2 === 'Our Portfolio'
//                     ?
//                     <BlogListTwo StyleVar={'box-card-style-default card-list-view'} data={item} />
//                     :
//                     <Testimonials StyleVar={'box-card-style-default card-list-view'} data={item} />}
//                 </div>
//               ))}
//             </div>
//           </div>
//           <button className="carousel-button next" onClick={handleNext}>&gt;</button>
//           <div className='text-center'>
//             <button className={`mt-5 border border-dark btn-round text-white py-4 px-4 text-sm ${bgColor}`}>{btnText}</button>
//           </div>
//         </div>
//       }
//     </>
//   );
// };

// export default Carousel;



import React from 'react';
import Slider from 'react-slick';
import Testimonials from './Testimonials';
import BlogListTwo from '../../../components/blog/itemProp/BlogListTwo';

const Carousel = ({ items, heading2, headingColor, carouselContainerbg, data, comp, btnText, bgColor }) => {
  // Settings for the Slick carousel
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ],
    nextArrow: <button className="carousel-button next">&gt;</button>,
    prevArrow: <button className="carousel-button prev">&lt;</button>
  };

  return (
    <>
      {data !== undefined && (
        <div className={`carousel-container ${carouselContainerbg} `}>
          <h2 className={`text-center ${headingColor}`}>{heading2}</h2>
          <Slider {...settings}>
            {data.map((item) => (
              <div key={item.id} className="col-lg-4 w-75 me-5 ms-5 mt--30 ">
                {heading2 === 'Our Portfolio'
                  ? <BlogListTwo StyleVar={'box-card-style-default card-list-view bg-black text-white w-full h-full'} data={item} />
                  : <Testimonials StyleVar={'box-card-style-default card-list-view  text-white text-gray-700'} data={item} />}
              </div>
            ))}
          </Slider>
          <div className='text-center'>
            <button className={`mt-5 border border-dark btn-round text-white py-4 px-4 text-sm ${bgColor}`}>{btnText}</button>
          </div>
        </div>
      )}
    </>
  );
};

export default Carousel;
